const Checkbox = {
    baseStyle: {
        control: {
            _focus: {
                borderColor: 'surface-brand-primary',
            },
            _disabled: {
                borderColor: 'blackAlpha-50',
            },
        },
    },
};

export { Checkbox };
