import * as React from 'react';
function SvgPriorityHigh(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <mask
                id='priority-high_svg__a'
                style={{
                    maskType: 'alpha',
                }}
                maskUnits='userSpaceOnUse'
                x={0}
                y={0}
                width={20}
                height={20}
            >
                <path fill='#D9D9D9' d='M0 0h20v20H0z' />
            </mask>
            <g mask='url(#priority-high_svg__a)'>
                <path
                    d='M9.995 17.269c-.456 0-.845-.163-1.167-.488a1.607 1.607 0 01-.482-1.172c0-.456.162-.845.487-1.167a1.607 1.607 0 011.172-.482c.457 0 .846.163 1.167.488.322.325.483.715.483 1.172 0 .456-.163.845-.488 1.167a1.607 1.607 0 01-1.172.482zm.003-5.309a1.51 1.51 0 01-1.113-.463 1.522 1.522 0 01-.46-1.113V4.34c0-.434.154-.804.462-1.11.308-.306.68-.46 1.115-.46.435 0 .806.155 1.113.464.307.308.46.68.46 1.113v6.044c0 .434-.154.803-.462 1.11-.308.306-.68.46-1.115.46z'
                    fill='#C91D25'
                />
            </g>
        </svg>
    );
}
export default SvgPriorityHigh;
