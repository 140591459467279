import { IconButton } from '@chakra-ui/react';
import SvgPencil from '@Icon/Pencil';
import { ComponentProps, FC } from 'react';
import { useTranslation } from 'react-i18next';

type EditIconProps = ComponentProps<typeof IconButton>;

const EditIcon: FC<EditIconProps> = (props) => {
    const { t } = useTranslation();
    return <IconButton bg={'surface-primary'} title={t('EditIcon.title')} icon={<SvgPencil />} {...props} />;
};

export default EditIcon;
