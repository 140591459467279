import { FC } from 'react';
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Flex, Text } from '@chakra-ui/react';
import ListItem from '@Components/ListItem';
import ListHeader from '@Components/ListHeader';
import { formatRelative } from 'date-fns';
import { Schedule } from '@Icon';
import parse from 'html-react-parser';
import { Trans } from 'react-i18next';
import { Activity } from '@Redux/services/commonTypes';

interface IProps {
    activities: Activity[];
}

const ActivitiesTab: FC<IProps> = ({ activities }) => {
    if (activities.length === 0) {
        return <Trans i18nKey='ActivitiesTab.emptyList' />;
    }

    return (
        <>
            {activities && (
                <Accordion allowToggle allowMultiple={false}>
                    <Box borderRadius={{ base: '0', md: 'lg' }} borderWidth='1px'>
                        {activities?.map((activity, index) => (
                            <AccordionItem
                                borderWidth='0px'
                                borderBottomWidth='0px !important'
                                id={index.toString()}
                                key={index.toString()}
                            >
                                {({ isExpanded }) => (
                                    <ListItem
                                        header={
                                            <AccordionButton
                                                m={0}
                                                p={0}
                                                _hover={{
                                                    bg: 'transparent',
                                                }}
                                                _active={{
                                                    border: 'none',
                                                }}
                                                _focus={{
                                                    border: 'none',
                                                }}
                                            >
                                                <ListHeader
                                                    subtitle={
                                                        <Flex alignItems='center'>
                                                            <Schedule />
                                                            <Text ml={'8px'}>
                                                                {formatRelative(new Date(activity.sentOn), new Date())}
                                                            </Text>
                                                        </Flex>
                                                    }
                                                    title={activity.subject}
                                                    content={
                                                        !isExpanded && activity.template?.trim().replace(/<[^>]+>/g, '')
                                                    }
                                                />
                                            </AccordionButton>
                                        }
                                        description={
                                            <AccordionPanel pb={2} pl={0}>
                                                {parse(activity.template ?? '')}
                                            </AccordionPanel>
                                        }
                                    />
                                )}
                            </AccordionItem>
                        ))}
                    </Box>
                </Accordion>
            )}
        </>
    );
};

export default ActivitiesTab;
