import * as React from 'react';
function SvgSummary(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M9.048 18.018h5.91c.295 0 .543-.1.742-.301.2-.201.3-.45.3-.747 0-.295-.1-.543-.3-.742-.2-.2-.447-.3-.742-.3H9.042c-.295 0-.543.1-.743.3-.2.2-.3.447-.3.742s.101.544.302.745c.201.202.45.303.747.303zm0-4h5.91c.295 0 .543-.1.742-.301.2-.201.3-.45.3-.747 0-.295-.1-.543-.3-.742-.2-.2-.447-.3-.742-.3H9.042c-.295 0-.543.1-.743.3-.2.2-.3.447-.3.742s.101.544.302.745c.201.202.45.303.747.303zm-2.976 8.185c-.63 0-1.167-.221-1.61-.665a2.192 2.192 0 01-.665-1.61V4.072c0-.63.221-1.167.665-1.61.443-.444.98-.665 1.61-.665h7.073a2.246 2.246 0 011.597.658l4.802 4.803a2.23 2.23 0 01.66 1.597v11.073c0 .63-.223 1.167-.666 1.61-.444.444-.98.665-1.61.665H6.072zm6.856-14.269V4.072H6.072v15.856h11.856V9.072h-3.862c-.32 0-.589-.11-.809-.33-.22-.22-.329-.489-.329-.808z'
                fill='#171923'
            />
        </svg>
    );
}
export default SvgSummary;
