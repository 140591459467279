import { Accessor } from '@Utilities';
import { editableFieldsForPhoneCall } from './types';
import { TFunction } from 'react-i18next';

interface ValidatorType {
    accessorPath: Accessor;
    validator: (value: string | Date | boolean | number) => boolean;
    message: string;
    required: boolean;
    editableStatus: number[];
    validateSave?: boolean;
    dependantPath?: Accessor;
}

export function Validator(t: TFunction): ValidatorType[] {
    return [
        {
            accessorPath: ['duration'],
            validator: Boolean,
            message: t('PhoneCallDetails.fieldRequired'),
            ...editableFieldsForPhoneCall.duration,
        },
        {
            accessorPath: ['absenceReason'],
            validator: Boolean,
            message: t('PhoneCallDetails.fieldRequired'),
            ...editableFieldsForPhoneCall.absenceReason,
        },
        {
            accessorPath: ['action'],
            validator: Boolean,
            message: t('PhoneCallDetails.fieldRequired'),
            ...editableFieldsForPhoneCall.action,
        },
        {
            accessorPath: ['fromId'],
            validator: Boolean,
            message: t('PhoneCallDetails.fieldRequired'),
            ...editableFieldsForPhoneCall.from,
        },
        {
            accessorPath: ['toId'],
            validator: Boolean,
            message: t('PhoneCallDetails.fieldRequired'),
            ...editableFieldsForPhoneCall.to,
        },
    ];
}
