import { RegistrationStatus } from 'src/constants';

export const links = [
    'all',
    'submittedAndDocumentsRequired',
    'submitted',
    'documentsRequired',
    'approvedAndArchived',
    'rejected',
    'deleted',
] as const;

export type RegistrationStatusParam = (typeof links)[number];

export type SortByProps = { id: string; desc: boolean };

export const getStatusCode = (e: RegistrationStatusParam) => {
    switch (e) {
        case 'all':
            return [];
        case 'submittedAndDocumentsRequired':
            return [RegistrationStatus.Submitted, RegistrationStatus.DocumentsRequired];
        case 'submitted':
            return [RegistrationStatus.Submitted];
        case 'documentsRequired':
            return [RegistrationStatus.DocumentsRequired];
        case 'approvedAndArchived':
            return [RegistrationStatus.Approved, RegistrationStatus.Archived];
        case 'rejected':
            return [RegistrationStatus.Rejected];
        case 'deleted':
            return [RegistrationStatus.Deleted];
    }
};

export const getStatusByCode = (e: number) => {
    switch (e) {
        case RegistrationStatus.Submitted:
            return 'submitted';
        case RegistrationStatus.DocumentsRequired:
            return 'documentsRequired';
        case RegistrationStatus.Approved:
            return 'approved';
        case RegistrationStatus.Archived:
            return 'archived';
        case RegistrationStatus.Rejected:
            return 'rejected';
        case RegistrationStatus.Expired:
            return 'expired';
        case RegistrationStatus.Deleted:
            return 'deleted';
    }
};

export const getSortByField = (s: SortByProps) => {
    let sBy = s;
    switch (s?.id) {
        case 'RegistrationStatus':
            sBy = { ...s, id: 'status' };
            break;
        case 'StudentName':
            sBy = { ...s, id: 'studentName' };
            break;
        case 'GuardianName':
            sBy = { ...s, id: 'guardianName' };
            break;
        case 'GuardianPhoneNumber':
            sBy = { ...s, id: 'guardianPhoneNumber' };
            break;
        case 'GuardianEmail':
            sBy = { ...s, id: 'guardianEmail' };
            break;
        case 'CreatedOn':
            sBy = { ...s, id: 'createdOn' };
            break;
        case 'ModifiedOn':
            sBy = { ...s, id: 'modifiedOn' };
            break;
    }
    return sBy;
};
