import { AlertError, Cycle } from '@Icon';
import { jobs_processing_status } from '@Redux/services/Reports/types';

interface IProps {
    value: number;
}

const JobProcessingStatusCell = ({ value }: IProps) => {
    return value === jobs_processing_status.Failed || value === jobs_processing_status.ValidationFailed ? (
        <AlertError />
    ) : value === jobs_processing_status.Splitting || value === jobs_processing_status.Validating ? (
        <Cycle />
    ) : (
        <></>
    );
};

export default JobProcessingStatusCell;
