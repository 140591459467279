import { FC, FormEvent } from 'react';
import Drawer from '@Components/Drawer';
import { useTranslation } from 'react-i18next';
import { AuditInfo } from '@Components/AuditInfo';
import { CommonMembersResponse } from '@Redux/services/Participants/types';
import { Summary } from './components/Summary';
import { useCustomList } from '@Hooks/useCustomList';
import { Members } from './components/Members';
import { useDisclosure } from '@chakra-ui/react';
import { BaseResponse } from '@Redux/services/commonTypes';
import ActivityLog from './components/ActivityLog';
import { EventStatus } from 'src/constants';
import { DrawerFooterActions } from '@Components/DrawerFooterActions';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    eventName: string;
    eventStatus: EventStatus;
    listId?: string;
}
export const EventParticipantsDrawer: FC<IProps> = ({ isOpen, onClose, eventName, eventStatus, listId }) => {
    const { t } = useTranslation();
    const { isOpen: isAddMemberOpen, onClose: onCloseAddMemberModal, onOpen: onOpenAddMemberModal } = useDisclosure();
    const {
        participantDetails,
        manageFieldPropFactory,
        textFieldPropFactory,
        textAreaPropFactory,
        setFormData: setParticipantDetails,
        isDirty,
        isLoading,
        getCustomList,
        handleSave,
        pagedResult,
        isUpdating,
        setPagedResult,
    } = useCustomList(listId);

    const handleCloseAll = () => {
        onClose();
        onCloseAddMemberModal();
    };
    return (
        <Drawer
            size={'xl'}
            title={t('EventParticipantsDrawer.eventName', { name: eventName })}
            isOpen={isOpen}
            onClose={onClose}
            onOverlayClick={onClose}
            onSubmit={(event: FormEvent<HTMLFormElement>) => {
                event.preventDefault();
                handleSave();
            }}
            isForm={true}
            footer={
                <DrawerFooterActions
                    isEditable={true}
                    closeButtonProps={{
                        onClick: onClose,
                        isDisabled: isUpdating,
                        label: t('EventParticipantsDrawer.closeDrawer'),
                    }}
                    saveButtonProps={{
                        type: 'submit',
                        isDisabled: isUpdating || !isDirty,
                        label: t('EventParticipantsDrawer.save'),
                    }}
                />
            }
        >
            <Summary
                manageFieldPropFactory={manageFieldPropFactory}
                textFieldPropFactory={textFieldPropFactory}
                textAreaPropFactory={textAreaPropFactory}
                participantDetails={participantDetails}
                setParticipantDetails={setParticipantDetails}
            />
            <Members
                isEditable={eventStatus === EventStatus.Proposed || eventStatus === EventStatus.Validated}
                eventName={eventName}
                isOpen={isAddMemberOpen}
                onAddMemberClose={onCloseAddMemberModal}
                onAddMemberOpen={onOpenAddMemberModal}
                onCloseAll={handleCloseAll}
                getMembers={getCustomList}
                onChange={(response: BaseResponse<CommonMembersResponse>) => {
                    setParticipantDetails(response.data);
                    setPagedResult(response.pagedResult);
                }}
                participantsData={participantDetails}
                pagedResult={pagedResult}
                isLoading={isLoading}
                isDrawer={true}
                listId={listId}
            />
            <ActivityLog
                manageFieldPropFactory={manageFieldPropFactory}
                setParticipantDetails={setParticipantDetails}
                participantDetails={participantDetails}
                textFieldPropFactory={textFieldPropFactory}
                textAreaPropFactory={textAreaPropFactory}
            />
            <AuditInfo
                modifiedBy={participantDetails.modifiedByFullName}
                modifiedOn={participantDetails.modifiedOn}
                createdBy={participantDetails.createdByFullName}
                createdOn={participantDetails.createdOn}
            />
        </Drawer>
    );
};
