const radii = {
    sm: '4px',
    md: '8px',
    lg: '16px',
    xl: '32px',
    '2xl': '64px',
    '3xl': '96px',
};

export default radii;
