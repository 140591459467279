import { Box, Flex, Skeleton, SkeletonCircle } from '@chakra-ui/react';
import { times } from 'lodash';
import { FC } from 'react';

export const DetailPageSkeleton = () => {
    return (
        <Flex flex={1} gap={4} height='90vh' position='relative'>
            <Box flexBasis='20%' p={'6'}>
                <Box flex={1}>
                    <Flex flex={1}>
                        <SkeletonCircle mr={3} size='60px' />
                        <Box flex={1}>
                            <Skeleton flex={1} mb={1} height='6' />
                            <Skeleton flex={1} height='6' />
                        </Box>
                    </Flex>
                    {times(3).map(() => (
                        <Flex ml={1} mt={4} alignItems='center'>
                            <SkeletonCircle size='20px' />
                            <Box flex={1} ml={3}>
                                <Skeleton flex={1} mb={1} height='4' />
                            </Box>
                        </Flex>
                    ))}
                </Box>
                <Box
                    position='absolute'
                    // height: 200px;
                    m={'2'}
                    p={'6'}
                    bottom={0}
                    left={0}
                    width='18%'
                    alignSelf='flex-end'
                    bg='surface-secondary'
                >
                    <Skeleton mb={1} height='6' flex={1} />
                    <Skeleton mb={1} height='6' flex={1} />
                    <Skeleton mb={1} height='6' flex={1} />
                    <Flex ml={2} mt={4} alignItems='center'>
                        <SkeletonCircle size='15px' startColor='white' endColor='white' />
                        <Box flex={1} ml={3}>
                            <Skeleton flex={1} mb={1} startColor='white' endColor='white' height='4' />
                        </Box>
                    </Flex>
                </Box>
            </Box>
            <Flex mt={2} p={'4'} bg='surface-secondary' borderTopLeftRadius={'lg'} flexBasis='80%'>
                <Box w='100%' gap={2}>
                    <Flex flex={1} mb={4}>
                        <SkeletonCircle mr={3} size='20px' />
                        <Box flex={1}>
                            <Skeleton flex={1} mb={1} height='6' width='50%' />
                        </Box>
                    </Flex>
                    <DetailsBodySkeleton rows={3} />
                    <Flex flex={1}>
                        <SkeletonCircle mr={3} size='20px' />

                        <Box flex={1}>
                            <Skeleton flex={1} mb={1} height='6' width='50%' />
                        </Box>
                    </Flex>
                    <Box
                        p='16px'
                        mt={8}
                        borderRadius={5}
                        borderWidth={2}
                        borderStyle='solid'
                        borderColor='border-primary'
                        w='100%'
                        pos='relative'
                    >
                        <Box position='absolute' flex='1' width='35%' top='-9px'>
                            <Skeleton flex={1} mb={1} height='4' width='10%' />
                        </Box>
                        <DetailsBodySkeleton rows={4} />
                    </Box>
                </Box>
            </Flex>
        </Flex>
    );
};

interface IProps {
    rows: number;
}
const DetailsBodySkeleton: FC<IProps> = ({ rows }) => {
    return (
        <>
            {times(rows).map((i) => (
                <Flex flex={1} gap={'50px'} mb={'12px'} alignItems='center'>
                    <Box flex={1} mb={1} maxW='15%' mr='8'>
                        <Skeleton height='6' />
                    </Box>
                    <Flex
                        maxW='85%'
                        padding='8px'
                        borderRadius='4px'
                        border='2px'
                        borderStyle='solid'
                        borderColor='#E2E8F0'
                        background='#FFF'
                        flex='1'
                        alignItems='center'
                    >
                        <Skeleton flex={1} height='6' />
                        {i % 2 === 0 && <SkeletonCircle ml='12px' size='20px' />}
                    </Flex>
                </Flex>
            ))}
        </>
    );
};
