import { Header } from '@Components/Header';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToSection = () => {
    const { hash } = useLocation();

    const scrollIntoView = (id) => {
        const element = document.querySelector(id) as HTMLElement;
        const header = document.querySelector('header') as HTMLElement;

        if (element && header) {
            element.scrollIntoView(true);
            window.scroll({ top: window.scrollY - (header.clientHeight + 20), behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (hash) {
            scrollIntoView(hash);
        }
    }, [hash]);

    return null;
};

const HeaderWithSectionRouter = () => {
    return (
        <>
            <ScrollToSection />
            <Header />
        </>
    );
};

export { HeaderWithSectionRouter, ScrollToSection };
