import * as React from 'react';
function SvgNotes(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g clipPath='url(#notes_svg__clip0_565_77985)'>
                <mask
                    id='notes_svg__a'
                    style={{
                        maskType: 'alpha',
                    }}
                    maskUnits='userSpaceOnUse'
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                >
                    <path fill='#D9D9D9' d='M0 0h20v20H0z' />
                </mask>
                <g mask='url(#notes_svg__a)'>
                    <path
                        d='M6.667 15.19H3.333a.916.916 0 01-.673-.275.916.916 0 01-.275-.673c0-.267.092-.491.275-.674a.916.916 0 01.673-.274h3.334c.266 0 .49.091.673.274a.916.916 0 01.275.674c0 .265-.092.49-.275.673a.916.916 0 01-.673.274zm10-8.483H3.333a.916.916 0 01-.673-.275.916.916 0 01-.275-.673c0-.266.092-.49.275-.674a.916.916 0 01.673-.274h13.334c.266 0 .49.091.673.274a.916.916 0 01.275.674c0 .266-.092.49-.275.673a.915.915 0 01-.673.275zm-5 4.241H3.333a.916.916 0 01-.673-.274.916.916 0 01-.275-.674c0-.266.092-.49.275-.673a.916.916 0 01.673-.275h8.334c.266 0 .49.092.673.275a.916.916 0 01.275.673c0 .266-.092.49-.275.674a.916.916 0 01-.673.274z'
                        fill='#171923'
                    />
                </g>
            </g>
            <defs>
                <clipPath id='notes_svg__clip0_565_77985'>
                    <path fill='#fff' d='M0 0h20v20H0z' />
                </clipPath>
            </defs>
        </svg>
    );
}
export default SvgNotes;
