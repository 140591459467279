import { Stack, useDisclosure } from '@chakra-ui/react';
import FormHeader from '@Components/FormHeader';
import SelectInput from '@Components/SelectInput';
import Switch from '@Components/Switch';
import TextInput from '@Components/TextInput';
import TypeaheadInput from '@Components/TypeaheadInput';
import { StepProps } from '@Pages/EventDetails/types';
import { useCreateListMutation, useGetListQuery } from '@Redux/services/Participants';
import { useGetInvitedGradesQuery, useGetParticipantTypesQuery } from '@Redux/services/LookupApi';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventStatus, TARGET_TYPE_CLASS, TARGET_TYPE_CUSTOM_LIST, TARGET_TYPE_GRADE } from 'src/constants';
import { useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { AlertDialog } from '@Components';
import { Plus } from '@Icon';
import Textarea from '@Components/Textarea';
import { useToast } from '@Hooks/useToast';
import { parseError, parseWarning } from '@Utilities';
import { ManageSelectInputProps, ManageSwitchInputProps, ManageTextProps } from '@Hooks';
import { EventParticipantsDrawer } from '@Components/EventParticipantsDrawer';

const Participants: FC<StepProps> = ({
    manageFieldPropFactory,
    selectFieldPropFactory,
    eventDetails,
    setEventDetails,
    setDirtyData,
    dirtyData,
    setErrors,
    typeaheadPropFactory,
    textFieldPropFactory,
    switchFieldPropFactory,
    handleManualSave,
}) => {
    const { t } = useTranslation();
    const { isOpen, onClose, onOpen } = useDisclosure();

    const { data: { data: invitedGrades = [] } = {} } = useGetInvitedGradesQuery();
    const { data: { data: participantTypes = [] } = {} } = useGetParticipantTypesQuery();
    const { data: { data: customList = [] } = {}, refetch: refetchCustomList } = useGetListQuery();

    const { id } = useParams();

    const toast = useToast();

    const isGrade = useMemo(() => eventDetails.participants?.targetType === TARGET_TYPE_GRADE, [eventDetails]);
    const isProposed = useMemo(() => eventDetails.status === EventStatus.Proposed, [eventDetails.status]);
    const isSchool = useMemo(() => eventDetails.participants?.targetType === TARGET_TYPE_CUSTOM_LIST, [eventDetails]);
    const isClass = useMemo(() => eventDetails.participants?.targetType === TARGET_TYPE_CLASS, [eventDetails]);
    const isCustomList = useMemo(
        () => eventDetails.participants?.targetType === TARGET_TYPE_CUSTOM_LIST,
        [eventDetails]
    );
    const cList = useMemo(() => {
        const filtredList = [];
        for (const item of customList) {
            if (isCustomList && !item.isPreviewlist) {
                filtredList.push({ key: item.listId, value: item.listName });
            } else if (!isCustomList && item.isPreviewlist) {
                filtredList.push({ key: item.listId, value: item.listName });
            }
        }
        return filtredList;
    }, [isCustomList, eventDetails.participants.listId]);

    const [createCustomList] = useCreateListMutation();
    const [showCustomListAlert, setShowCustomListAlert] = useState(false);
    const [newCustomListName, setNewCustomListName] = useState('');
    const [newCustomListDescription, setNewCustomListDescription] = useState('');

    useEffect(() => {
        if (!isCustomList) {
            setEventDetails({
                ...eventDetails,
                participants: {
                    ...eventDetails.participants,
                    listId: null,
                },
            });
            if (dirtyData && 'participants' in dirtyData && dirtyData.participants?.listId) {
                dirtyData['participants']['listId'] = null;
                setDirtyData(cloneDeep(dirtyData));
            }
        }

        if (!isClass) {
            setEventDetails({
                ...eventDetails,
                participants: {
                    ...eventDetails.participants,
                    invitedClass: null,
                },
            });
            if (dirtyData && 'participants' in dirtyData && dirtyData.participants?.invitedClass) {
                dirtyData['participants']['invitedClass'] = null;
                setDirtyData(cloneDeep(dirtyData));
            }
        }

        if (!isGrade) {
            setEventDetails({
                ...eventDetails,
                participants: {
                    ...eventDetails.participants,
                    invitedGrade: null,
                },
            });
            if (dirtyData && 'participants' in dirtyData && dirtyData.participants?.invitedGrade) {
                dirtyData['participants']['invitedGrade'] = null;
                setDirtyData(cloneDeep(dirtyData));
            }
        }
    }, [isCustomList, isClass, isGrade]);

    const handleAddNewParticipantList = () => {
        dirtyData && handleManualSave();
        setShowCustomListAlert(true);
    };

    const createList = async () => {
        await createCustomList({
            listName: newCustomListName,
            eventId: id,
            description: newCustomListDescription,
        })
            .unwrap()
            .then(async (response) => {
                if (response?.warningResult?.showAlert) {
                    parseWarning(toast, response);
                } else {
                    toast({
                        status: 'success',
                        description: t('EventParticipant.listCreated'),
                        isClosable: true,
                    });
                }
                await refetchCustomList();
                setEventDetails({
                    ...eventDetails,
                    participants: {
                        ...eventDetails.participants,
                        listId: response.data.listId,
                    },
                });
                onOpen();
                setShowCustomListAlert(false);
            })
            .catch((e) => {
                setErrors(e.data.errors);
                parseError(toast, e);
            });
    };

    return (
        <>
            <AlertDialog
                title={t('Participants.createList.title')}
                isOpen={showCustomListAlert}
                alertType='brand'
                message={t('Participants.createList.message')}
                submitLabel={t('Participants.createList.submit')}
                cancelButtonLabel={t('Participants.createList.cancel')}
                submitHandle={createList}
                submitLeftIcon={
                    <div style={{ marginRight: 4 }}>
                        <Plus fill='white' width={20} height={20} />
                    </div>
                }
                onClose={() => setShowCustomListAlert(false)}
                cancelButtonProps={{
                    variant: 'outline',
                }}
            >
                <Stack spacing={4}>
                    <TextInput
                        label={t('Information.name')}
                        isLocked={false}
                        mt={2}
                        value={newCustomListName}
                        onChangeText={setNewCustomListName}
                    />
                    <Textarea
                        label={t('Information.description')}
                        isLocked={false}
                        value={newCustomListDescription}
                        onChangeText={setNewCustomListDescription}
                    />
                </Stack>
            </AlertDialog>
            <FormHeader label={t('Participants.label')}>
                <Stack spacing={4}>
                    <SelectInput
                        {...selectFieldPropFactory(t('Participants.targetParticipants'), [
                            'participants',
                            'targetType',
                        ])}
                        {...manageFieldPropFactory<ManageSelectInputProps>(
                            ['participants', 'targetType'],
                            'onChangeValue',
                            'value'
                        )}
                        options={participantTypes}
                        col={1}
                    />
                    {isGrade && (
                        <SelectInput
                            {...selectFieldPropFactory(t('Participants.grade'), ['participants', 'invitedGrade'])}
                            {...manageFieldPropFactory<ManageSelectInputProps>(
                                ['participants', 'invitedGrade'],
                                'onChangeValue',
                                'value'
                            )}
                            options={invitedGrades}
                            col={1}
                        />
                    )}

                    {isClass && (
                        <TextInput
                            {...textFieldPropFactory(t('Participants.invitedClassroom'), [
                                'participants',
                                'invitedClass',
                            ])}
                            {...manageFieldPropFactory<ManageSelectInputProps>(
                                ['participants', 'invitedClass'],
                                'onChangeText',
                                'value'
                            )}
                            col={1}
                        />
                    )}
                    {(isCustomList || !isProposed) && (
                        <TypeaheadInput
                            {...typeaheadPropFactory(t('Participants.participantsPreview'), ['participants', 'listId'])}
                            {...manageFieldPropFactory<ManageTextProps>(
                                ['participants', 'listId'],
                                'onSelection',
                                'value'
                            )}
                            options={cList}
                            placeholder={'--'}
                            onLinkClick={onOpen}
                            onAddNewRecord={handleAddNewParticipantList}
                            col={1}
                        />
                    )}

                    {isSchool && (
                        <Switch
                            {...switchFieldPropFactory(t('Participants.schoolValidation'), [
                                'participants',
                                'schoolValidation',
                            ])}
                            {...manageFieldPropFactory<ManageSwitchInputProps>(
                                ['participants', 'schoolValidation'],
                                'onChangeSwitch',
                                'isChecked'
                            )}
                            col={1}
                        />
                    )}
                </Stack>
            </FormHeader>
            {isOpen && (
                <EventParticipantsDrawer
                    isOpen={isOpen}
                    onClose={onClose}
                    listId={eventDetails?.participants?.listId}
                    eventName={eventDetails?.name}
                    eventStatus={eventDetails?.status}
                />
            )}
        </>
    );
};

export default Participants;
