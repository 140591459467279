import type { BoxProps } from '@chakra-ui/react';
import { Box, Button, Text } from '@chakra-ui/react';
import { isNumber } from 'lodash';
import React, { forwardRef, useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends BoxProps {
    children: React.ReactNode;
    noOfLines: number;
    expandableWidth?: string;
}

export const ExpandableText = forwardRef<HTMLDivElement, Props>(({ children, noOfLines, expandableWidth, ...rest }) => {
    const { t } = useTranslation();

    const [expandedCount, setExpandedCount] = useState<number | undefined>(noOfLines);

    const [isTextClamped, setIsTextClamped] = useState(false);
    const [textWidth, setTextWidth] = useState('');

    const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setExpandedCount(expandedCount ? undefined : noOfLines);
    };

    const isPercentWidth = expandableWidth?.includes('%');

    const widthInPixes = !isPercentWidth && expandableWidth ? parseInt(expandableWidth) : undefined;

    const inputRef = React.useRef<HTMLDivElement>(null);

    let effectiveWidth = widthInPixes ?? 180;
    if (isPercentWidth) {
        effectiveWidth = 700 * (parseInt(expandableWidth) / 100);
    }

    useLayoutEffect(() => {
        // let textClamped = false;
        // if (inputRef.current && inputRef.current.clientHeight) {
        //     textClamped =
        //         (inputRef.current?.scrollHeight as number) > (inputRef.current?.clientHeight as number) || isClicked;
        //     setIsTextClamped(textClamped);
        // }

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        context.font = getComputedStyle(document.body).font;
        const width = context.measureText(inputRef.current?.textContent).width;

        if (width < effectiveWidth) {
            setTextWidth('max-content');
            setIsTextClamped(false);
            return;
        }
        setIsTextClamped(true);
        if (!isPercentWidth) {
            setTextWidth(effectiveWidth + 'px');
        } else {
            setTextWidth(expandableWidth);
        }
    }, [setIsTextClamped, inputRef, children]);

    return (
        <>
            <Box display='inline-block' width={textWidth} ref={inputRef} noOfLines={expandedCount} {...rest}>
                {children}
            </Box>
            {isTextClamped && (
                <Button display={isTextClamped ? 'block' : 'none'} size='sm' variant='link' onClick={handleToggle}>
                    <Text textStyle='xs-normal'>
                        {!expandedCount ? t('ExpandableText.collapse') : t('ExpandableText.expand')}
                    </Text>
                </Button>
            )}
        </>
    );
});
