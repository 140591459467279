import { Button } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { AspectRatio, Box } from '@chakra-ui/layout';
import Drawer from '@Components/Drawer';
import { MFR_BASE_URL } from '@Redux/services/base';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
    eventCode: string;
}
export const PreviewButton: FC<IProps> = ({ eventCode }) => {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { t } = useTranslation();
    return (
        <>
            <Button onClick={onOpen} textStyle={'md-semibold'} mr={3}>
                {t('PreviewButton.buttonTitle')}
            </Button>
            {isOpen && (
                <Drawer isOpen={isOpen} onClose={onClose} title='Event Preview'>
                    <AspectRatio maxW='100%' ratio={1}>
                        <Box
                            as='iframe'
                            title='naruto'
                            src={`${MFR_BASE_URL}/EventOffer/Preview/${eventCode}`}
                            allowFullScreen
                        ></Box>
                    </AspectRatio>
                </Drawer>
            )}
        </>
    );
};
