import { Box } from '@chakra-ui/react';

import Modal from '@Components/Modal';
import TypeaheadInput from '@Components/TypeaheadInput';
import { useToast } from '@Hooks/useToast';
import { Plus } from '@Icon';
import { useCreateEventResponseMutation } from '@Redux/services/Event';
import { useLazyGetAdditionalParticipantsQuery } from '@Redux/services/Participants';
import { Member } from '@Redux/services/Participants/types';
import { parseError, parseWarning } from '@Utilities';
import { FC, useMemo, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import type { PartialDeep } from 'type-fest';
import { Event } from '@Redux/services/Event/types';
import { Loading } from '../Loading';
import { DrawerFooterActions } from '@Components/DrawerFooterActions';

interface IProps {
    eventData: PartialDeep<Event>;
    isOpen: boolean;
    onClose: () => void;
    setEventDetails: Dispatch<SetStateAction<PartialDeep<Event>>>;
}
export const AddResponseModal: FC<IProps> = ({ eventData, isOpen, setEventDetails, onClose }) => {
    const { t } = useTranslation();
    const toast = useToast();
    const [participantId, setParticipantId] = useState<string | number>();
    const [participants, setParticipants] = useState<Member[]>();
    const [getAdditionalParticipants, { isLoading }] = useLazyGetAdditionalParticipantsQuery();
    const [createEventResponse, { isLoading: isCreating }] = useCreateEventResponseMutation();
    const [error, setError] = useState('');
    useEffect(() => {
        getAdditionalParticipants({ id: eventData.eventId })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                setParticipants(response.data);
            })
            .catch((err) => {
                onClose();
                parseError(toast, err);
            });
    }, [eventData, isOpen]);

    const participantsOptions = useMemo(
        () =>
            participants?.map((participant) => ({
                key: participant.contactId,
                value: participant.fullName,
            })),
        [participants]
    );

    const handleSubmit = async () => {
        if (!participantId) {
            setError(t('AddResponseModal.studentRequired'));
            return;
        } else {
            setError('');
        }
        if (isCreating) return;
        await createEventResponse({
            eventId: eventData.eventId,
            participantId: participantId.toString(),
        })
            .unwrap()
            .then((response) => {
                setEventDetails({
                    ...eventData,
                    eventResponses: response.data.eventResponses,
                    costItemResponses: response.data.costItemResponses,
                    eventAnswers: response.data.eventAnswers,
                });
                onClose();
                if (response?.warningResult?.showAlert) {
                    parseWarning(toast, response);
                } else {
                    toast({
                        status: 'success',
                        description: t('AddResponseModal.toastAddSuccess'),
                    });
                }
            })
            .catch((err) => {
                parseError(toast, err);
            });
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={t('AddResponseModal.title')}
            isCentered
            footer={
                <DrawerFooterActions
                    isEditable={true}
                    closeButtonProps={{
                        isDisabled: isCreating,
                        label: t('AddResponseModal.cancelButtonLabel'),
                        onClick: onClose,
                    }}
                    saveButtonProps={{
                        leftIcon: <Plus />,
                        onClick: handleSubmit,
                        label: t(isCreating ? 'AddResponseModal.buttonLoading' : 'AddResponseModal.addButtonLabel'),
                    }}
                />
            }
        >
            {isLoading ? (
                <Loading />
            ) : (
                <Box mb={8}>
                    <TypeaheadInput
                        label={t('AddResponseModal.studentLabel')}
                        isLocked={false}
                        options={participantsOptions}
                        value={participantId}
                        onSelection={setParticipantId}
                        error={error}
                        isInvalid={Boolean(error)}
                    />
                </Box>
            )}
        </Modal>
    );
};
