import { FC, ReactNode, useState } from 'react';
import { Box, Collapse, Flex } from '@chakra-ui/react';
import ListHeader, { ListHeaderProps } from '@Components/ListHeader';
import { Button } from '@Components';
import { Eye, Pencil } from '@Icon';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

interface ListItemProps extends ListHeaderProps {
    itemContent?: ReactNode;
    itemDetail?: ReactNode;
    onSubmit?: () => void;
    onView?: () => void;
    isLoading?: boolean;
}

const ListItem: FC<ListItemProps> = ({
    title,
    subtitle,
    avatar,
    itemContent,
    itemDetail,
    onSubmit,
    onView,
    isLoading,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Box borderColor={'border-primary'} borderRadius='8px' borderWidth='1px' borderStyle='solid' p={4} my={3}>
            <Flex flexDirection={'row'}>
                <Box flexBasis={'50%'} cursor={'pointer'} onClick={() => !isLoading && onView()}>
                    <ListHeader title={title} subtitle={subtitle} avatar={avatar} />
                </Box>
                <Box flexGrow={1} pr={4}>
                    {itemContent}
                </Box>
                <Flex flexDirection={'column'}>
                    <Button
                        leftIcon={<Pencil width={20} height={20} />}
                        variant={'light'}
                        onClick={onSubmit}
                        mb={2}
                        h={8}
                        disabled={isLoading}
                    >
                        Submit
                    </Button>
                    <Button
                        leftIcon={<Eye width={20} height={20} />}
                        variant={'light'}
                        onClick={onView}
                        mb={2}
                        h={8}
                        disabled={isLoading}
                    >
                        View
                    </Button>
                    <Button
                        leftIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        variant={'ghost'}
                        color={'text-primary'}
                        onClick={() => setIsOpen(!isOpen)}
                        mb={2}
                        h={8}
                    >
                        {isOpen ? 'Less' : 'More'}
                    </Button>
                </Flex>
            </Flex>
            <Collapse in={isOpen}>{itemDetail}</Collapse>
        </Box>
    );
};

export default ListItem;
