import {
    InputGroup,
    FormControl,
    FormHelperText,
    FormLabel,
    InputLeftElement,
    Text,
    Box,
    InputRightElement,
    useOutsideClick,
} from '@chakra-ui/react';
import { ComponentProps, FC, useState, useMemo, useRef } from 'react';
import { RangeDatepicker as RD } from 'chakra-dayzed-datepicker';
import { Calendar, Lock, Trash } from '@Icon';
import { isArray } from 'lodash';

interface RangeDatePickerProps extends ComponentProps<typeof RD> {
    isLocked: boolean;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    label?: string;
    error?: string;
    isInvalid?: boolean;
    onChangeDate?: (date: Date[]) => void;
    onResetClick?: () => void;
    col?: number;
    placeHolder?: string;
    size?: 'sm' | 'md';
}

const RangeDatepicker: FC<RangeDatePickerProps> = ({
    label,
    error,
    isLocked,
    isInvalid,
    onChangeDate,
    onResetClick,
    col,
    placeHolder,
    size = 'md',
    selectedDates,
    ...rest
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef();
    const calendarRef = useRef<HTMLSpanElement>();

    useOutsideClick({
        ref: ref,
        handler: () => !isOpen && setIsOpen(false),
    });

    const isEmpty = useMemo(
        () => (selectedDates && isArray(selectedDates) && !selectedDates?.length) || !selectedDates,
        [selectedDates]
    );

    const handleCalendarIconClick = () => {
        if (calendarRef && calendarRef?.current.childNodes.length > 0) {
            (calendarRef?.current.childNodes[0] as HTMLInputElement).click();
        }
    };

    const isSm = useMemo(() => size === 'sm', [size]);

    return (
        <FormControl onClick={() => setIsOpen(true)} display={{ base: 'block', lg: 'flex' }}>
            {label && (
                <FormLabel
                    color={(rest.disabled || isLocked) && 'text-disabled'}
                    flexBasis={col === 1 ? '12.5%' : '30%'}
                    textStyle={'sm-medium'}
                >
                    {label}
                </FormLabel>
            )}
            <Box>
                <InputGroup alignItems={'center'}>
                    {isEmpty && (
                        <InputLeftElement
                            w='72.5%'
                            bg='white'
                            justifyContent={'start'}
                            left={'5'}
                            onClick={() => setIsOpen(!isOpen)}
                            top={!isSm ? '10px' : '5px'}
                            height='6'
                            alignSelf='center'
                            zIndex={-1}
                        >
                            <Text textStyle={'sm-medium'}>{placeHolder}</Text>
                        </InputLeftElement>
                    )}

                    <span ref={calendarRef}>
                        <RD
                            key={isOpen.toString()}
                            selectedDates={selectedDates}
                            onDateChange={onChangeDate}
                            {...rest}
                            propsConfigs={{
                                dateNavBtnProps: {
                                    boxShadow: 'none',
                                    _hover: {
                                        background: 'ghost.icon.bg.hover',
                                        color: 'ghost.icon.text.hover',
                                        boxShadow: 'none',
                                    },
                                    _active: {
                                        background: 'ghost.icon.bg.hover',
                                        color: 'ghost.icon.text.hover',
                                        boxShadow: 'none',
                                    },
                                    _focus: {
                                        background: 'ghost.icon.bg.hover',
                                        color: 'ghost.icon.text.hover',
                                        boxShadow: 'none',
                                    },
                                },
                                inputProps: {
                                    minW: 290,
                                    height: isSm ? 8 : 10,
                                    borderWidth: 2,
                                    _active: {
                                        borderColor: 'border-brand',
                                    },
                                    _focus: {
                                        borderColor: 'border-brand',
                                    },
                                    _focusVisible: {
                                        borderColor: 'border-brand',
                                    },
                                    _focusWithin: {
                                        borderColor: 'border-brand',
                                    },
                                    _disabled: {
                                        color: 'text-disabled',
                                    },
                                },
                                dayOfMonthBtnProps: {
                                    defaultBtnProps: {
                                        borderColor: 'red.300',
                                        _hover: {
                                            background: 'ghost.icon.bg.hover',
                                            color: 'ghost.icon.text.hover',
                                            boxShadow: 'none',
                                        },
                                        _focus: {
                                            background: 'ghost.icon.bg.active',
                                            color: 'ghost.icon.text.active',
                                            boxShadow: 'none',
                                        },
                                        _active: {
                                            background: 'ghost.icon.bg.active',
                                            color: 'ghost.icon.text.active',
                                            boxShadow: 'none',
                                        },
                                    },
                                    isInRangeBtnProps: {
                                        background: 'ghost.icon.bg.active',
                                        color: 'ghost.icon.text.active',
                                    },
                                    selectedBtnProps: {
                                        background: 'ghost.icon.bg.active',
                                        color: 'ghost.icon.text.active',
                                    },
                                },
                            }}
                            configs={{
                                dateFormat: 'MMM d yyyy',
                            }}
                            disabled={isLocked}
                            usePortal
                        />
                    </span>
                    {selectedDates?.length && onResetClick && !isEmpty && (
                        <InputRightElement
                            onClick={onResetClick}
                            cursor={!isLocked && 'pointer'}
                            right={6}
                            zIndex={1}
                            top={isSm ? '-3px' : '0px'}
                        >
                            <Trash />
                        </InputRightElement>
                    )}

                    <InputRightElement
                        top={isSm ? '-3px' : '0px'}
                        onClick={handleCalendarIconClick}
                        cursor={!isLocked && 'pointer'}
                    >
                        {isLocked ? <Lock /> : <Calendar />}
                    </InputRightElement>
                </InputGroup>
                {error && (
                    <FormHelperText textStyle='sm-normal' color={isInvalid && 'text-error'}>
                        {error}
                    </FormHelperText>
                )}
            </Box>
        </FormControl>
    );
};

export { RangeDatepicker };
