import * as React from 'react';
function SvgEye(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g clipPath='url(#eye_svg__clip0_6515_41214)'>
                <mask
                    id='eye_svg__a'
                    style={{
                        maskType: 'alpha',
                    }}
                    maskUnits='userSpaceOnUse'
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                >
                    <path fill='#D9D9D9' d='M0 0h20v20H0z' />
                </mask>
                <g mask='url(#eye_svg__a)'>
                    <path
                        d='M10.039 13.26c1.041 0 1.927-.365 2.656-1.094.73-.729 1.094-1.614 1.094-2.656s-.365-1.927-1.094-2.656c-.73-.73-1.615-1.094-2.656-1.094-1.042 0-1.927.365-2.656 1.094-.73.729-1.094 1.614-1.094 2.656s.364 1.927 1.094 2.656c.729.73 1.614 1.094 2.656 1.094zm0-1.605a2.07 2.07 0 01-1.52-.625 2.067 2.067 0 01-.626-1.52c0-.595.209-1.101.626-1.519a2.067 2.067 0 011.519-.626c.596 0 1.102.208 1.52.625.417.417.626.923.626 1.52 0 .595-.208 1.101-.625 1.519a2.066 2.066 0 01-1.52.626zm0 4.26c-1.954 0-3.74-.52-5.36-1.56a9.98 9.98 0 01-3.681-4.192.76.76 0 01-.096-.295 2.68 2.68 0 010-.716.76.76 0 01.096-.295A9.98 9.98 0 014.68 4.664c1.619-1.039 3.405-1.558 5.359-1.558 1.954 0 3.74.52 5.359 1.558a9.98 9.98 0 013.682 4.193c.048.079.08.177.095.295a2.695 2.695 0 010 .716.761.761 0 01-.095.295 9.98 9.98 0 01-3.682 4.193c-1.62 1.039-3.405 1.558-5.36 1.558zm0-1.822a7.908 7.908 0 004.313-1.242 8.178 8.178 0 003.005-3.341 8.179 8.179 0 00-3.006-3.341 7.91 7.91 0 00-4.313-1.242 7.908 7.908 0 00-4.312 1.242A8.18 8.18 0 002.72 9.51a8.178 8.178 0 003.006 3.341 7.91 7.91 0 004.313 1.242z'
                        fill='#171923'
                    />
                </g>
            </g>
            <defs>
                <clipPath id='eye_svg__clip0_6515_41214'>
                    <path fill='#fff' d='M0 0h20v20H0z' />
                </clipPath>
            </defs>
        </svg>
    );
}
export default SvgEye;
