import { Text } from '@chakra-ui/react';
import { jobs_processing_status, UploadedJob } from '@Redux/services/Reports/types';
import { useTranslation } from 'react-i18next';

const JobProcessingStatusCell = (job: UploadedJob) => {
    const { t } = useTranslation();

    return job.jobProcessingStatus === jobs_processing_status.ValidationFailed
        ? JSON.parse(job.validation ?? '{}')?.Errors?.map((err) => (
              <Text color={'red'}>{`${t('Records.files.Failed')} ${err.ErrorMessage}`}</Text>
          ))
        : job.jobProcessingStatus === 1
        ? 'Processing'
        : job.jobProcessingStatusDescription;
};

export default JobProcessingStatusCell;
