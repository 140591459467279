import { SearchIcon } from '@chakra-ui/icons';
import { Input, InputGroup, InputLeftElement, InputProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type SearchProps = InputProps & {
    query: string;
    iconPosition?: 'left' | 'right';
};

const Search: React.FC<SearchProps> = ({
    query,
    onChange,
    isDisabled,
    size = 'sm',
    iconPosition,
    type,
    pr = '4rem',
    placeholder,
    border,
    borderRadius,
    ...rest
}) => {
    const { t } = useTranslation();

    return (
        <InputGroup width='auto' size={size} {...rest}>
            {iconPosition === 'left' && (
                <InputLeftElement pointerEvents='none'>
                    <SearchIcon color={isDisabled ? 'search.text.disabled' : 'search.text.primary'} />
                </InputLeftElement>
            )}
            <Input
                type={type}
                _focus={!isDisabled && { border: '2px solid', borderColor: 'search.borderColor.active' }}
                _active={!isDisabled && { border: '2px solid', borderColor: 'search.borderColor.active' }}
                _hover={!isDisabled && { border: '2px solid', borderColor: 'search.borderColor.hover' }}
                _disabled={{ border: '1px solid', borderColor: 'search.borderColor.base' }}
                border={border ?? '2px solid'}
                isDisabled={isDisabled}
                cursor={isDisabled && 'not-allowed'}
                borderColor='search.borderColor.base'
                color='search.text.primary'
                placeholder={placeholder ?? t('EventsList.searchPlaceholder')}
                value={query}
                onChange={onChange}
                pr={pr}
                borderRadius={borderRadius ?? 'base'}
            />
            {/* {iconPosition === 'right' && (
                <InputRightElement pointerEvents='none'>
                    <SearchIcon color={isDisabled ? 'search.text.disabled' : 'search.text.primary'} />
                </InputRightElement>
            )} */}
        </InputGroup>
    );
};

export { Search };
