import * as React from 'react';
function SvgPayment(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M11.737 10.512c-.65 0-1.2-.227-1.652-.682a2.263 2.263 0 01-.677-1.656c0-.65.227-1.2.682-1.651a2.263 2.263 0 011.656-.677c.65 0 1.2.227 1.652.682.451.454.677 1.006.677 1.656 0 .65-.227 1.2-.682 1.651a2.263 2.263 0 01-1.656.677zm-5.424 2.655c-.522 0-.982-.2-1.381-.599-.4-.399-.599-.859-.599-1.38v-6.18c0-.52.2-.98.599-1.38.399-.399.86-.598 1.38-.598h11.03c.521 0 .982.2 1.38.598.4.4.6.86.6 1.38v6.18c0 .521-.2.981-.6 1.38-.398.4-.859.599-1.38.599H6.312zm1.591-1.905h7.846c0-.458.163-.85.49-1.177.326-.326.718-.49 1.177-.49v-3c-.459 0-.851-.163-1.177-.489a1.605 1.605 0 01-.49-1.177H7.904c0 .458-.163.85-.49 1.177-.325.326-.718.49-1.176.49v3c.458 0 .85.163 1.177.49.326.326.49.718.49 1.176zm-5.246 5.56c-.544 0-1.01-.195-1.398-.582a1.906 1.906 0 01-.581-1.398V6.818c0-.263.098-.492.295-.69a.95.95 0 01.695-.295.95.95 0 01.694.296.945.945 0 01.295.689v8.024H15.68c.264 0 .495.098.691.295a.95.95 0 01.296.695.95.95 0 01-.296.694.946.946 0 01-.69.295H2.657z'
                fill='#407F01'
            />
        </svg>
    );
}
export default SvgPayment;
