import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import React from 'react';
import ReactIcon from '@Icon/ReactIcon';
import Header from '@Pages/EventDetails/components/Summary/components/Header';
import type { StudentStepProps } from '@Pages/StudentDetails/types';
import FormHeader from '@Components/FormHeader';
import TextInput from '@Components/TextInput';
import SelectInput from '@Components/SelectInput';
import { ManageTextProps } from '@Hooks';

const canadaProvinces = [
    { value: 'Alberta', key: 'AB' },
    { value: 'British Columbia', key: 'BC' },
    { value: 'Manitoba', key: 'MB' },
    { value: 'New Brunswick', key: 'NB' },
    { value: 'Newfoundland and Labrador', key: 'NL' },
    { value: 'Northwest Territories', key: 'NT' },
    { value: 'Nova Scotia', key: 'NS' },
    { value: 'Nunavut', key: 'NU' },
    { value: 'Ontario', key: 'ON' },
    { value: 'Prince Edward Island', key: 'PE' },
    { value: 'Quebec', key: 'QC' },
    { value: 'Saskatchewan', key: 'SK' },
    { value: 'Yukon', key: 'YT' },
];

const Address: React.FC<StudentStepProps> = ({
    id,
    manageFieldPropFactory,
    textFieldPropFactory,
    selectFieldPropFactory,
}) => {
    const { t } = useTranslation();

    return (
        <Box id={id} w={'full'}>
            <Header divider={true} mb={4} title={t('Address')} icon={<ReactIcon />} />
            <Flex direction={'column'} gap={4}>
                <FormHeader id={id} label={t('Address.homeAddress')}>
                    <Box pb={4}>
                        <TextInput
                            {...textFieldPropFactory(t('Address.street'), ['homeAddress', 'street'])}
                            {...manageFieldPropFactory<ManageTextProps>(
                                ['homeAddress', 'street'],
                                'onChangeText',
                                'value'
                            )}
                        />
                    </Box>
                    <Grid
                        columnGap={10}
                        gridAutoFlow='dense'
                        templateColumns={{
                            base: 'repeat(1, 1fr)',
                            md: 'repeat(2, 1fr)',
                            lg: 'repeat(3, 1fr)',
                        }}
                        rowGap={4}
                    >
                        <GridItem>
                            <TextInput
                                {...textFieldPropFactory(t('Address.ruralRoute'), ['homeAddress', 'ruralRoute'])}
                                {...manageFieldPropFactory<ManageTextProps>(
                                    ['homeAddress', 'ruralRoute'],
                                    'onChangeText',
                                    'value'
                                )}
                                stacked
                            />
                        </GridItem>

                        <GridItem>
                            <TextInput
                                {...manageFieldPropFactory<ManageTextProps>(
                                    ['homeAddress', 'apartment'],
                                    'onChangeText',
                                    'value'
                                )}
                                {...textFieldPropFactory(t('Address.apartment'), ['homeAddress', 'apartment'])}
                                stacked
                            />
                        </GridItem>

                        <GridItem>
                            <TextInput
                                {...textFieldPropFactory(t(''), ['homeAddress', 'country'])}
                                {...manageFieldPropFactory<ManageTextProps>(
                                    ['homeAddress', 'country'],
                                    'onChangeText',
                                    'value'
                                )}
                                label={t('Address.country')}
                                stacked
                            />
                        </GridItem>
                        <GridItem>
                            <SelectInput
                                {...selectFieldPropFactory(t('Address.province'), ['homeAddress', 'province'])}
                                {...manageFieldPropFactory<ManageTextProps>(
                                    ['homeAddress', 'province'],
                                    'onChangeValue',
                                    'value'
                                )}
                                options={canadaProvinces as any}
                                stacked
                            />
                        </GridItem>
                        <GridItem>
                            <TextInput
                                {...textFieldPropFactory(t('Address.municipality'), ['homeAddress', 'municipality'])}
                                {...manageFieldPropFactory<ManageTextProps>(
                                    ['homeAddress', 'municipality'],
                                    'onChangeText',
                                    'value'
                                )}
                                stacked
                            />
                        </GridItem>

                        <GridItem>
                            <TextInput
                                {...textFieldPropFactory(t('Address.postalCode'), ['homeAddress', 'postalCode'])}
                                {...manageFieldPropFactory<ManageTextProps>(
                                    ['homeAddress', 'postalCode'],
                                    'onChangeText',
                                    'value'
                                )}
                                stacked
                            />
                        </GridItem>
                    </Grid>
                </FormHeader>
                <FormHeader id={id} label={t('Address.mailingAddress')}>
                    <Box pb={4}>
                        <TextInput
                            {...textFieldPropFactory(t('Address.street'), ['mailingAddress', 'street'])}
                            {...manageFieldPropFactory<ManageTextProps>(
                                ['mailingAddress', 'street'],
                                'onChangeText',
                                'value'
                            )}
                        />
                    </Box>
                    <Grid
                        columnGap={10}
                        gridAutoFlow='dense'
                        templateColumns={{
                            base: 'repeat(1, 1fr)',
                            md: 'repeat(2, 1fr)',
                            lg: 'repeat(3, 1fr)',
                        }}
                        rowGap={4}
                    >
                        <GridItem>
                            <TextInput
                                {...textFieldPropFactory(t('Address.ruralRoute'), ['mailingAddress', 'ruralRoute'])}
                                {...manageFieldPropFactory<ManageTextProps>(
                                    ['mailingAddress', 'ruralRoute'],
                                    'onChangeText',
                                    'value'
                                )}
                                stacked
                            />
                        </GridItem>

                        <GridItem>
                            <TextInput
                                {...manageFieldPropFactory<ManageTextProps>(
                                    ['mailingAddress', 'apartment'],
                                    'onChangeText',
                                    'value'
                                )}
                                {...textFieldPropFactory(t('Address.apartment'), ['mailingAddress', 'apartment'])}
                                stacked
                            />
                        </GridItem>

                        <GridItem>
                            <TextInput
                                {...textFieldPropFactory(t('Address.municipality'), ['mailingAddress', 'municipality'])}
                                {...manageFieldPropFactory<ManageTextProps>(
                                    ['mailingAddress', 'municipality'],
                                    'onChangeText',
                                    'value'
                                )}
                                stacked
                            />
                        </GridItem>

                        <GridItem>
                            <SelectInput
                                {...selectFieldPropFactory(t('Address.province'), ['mailingAddress', 'province'])}
                                {...manageFieldPropFactory<ManageTextProps>(
                                    ['mailingAddress', 'province'],
                                    'onChangeValue',
                                    'value'
                                )}
                                options={canadaProvinces as any}
                                stacked
                            />
                        </GridItem>

                        <GridItem>
                            <TextInput
                                {...textFieldPropFactory(t('Address.country'), ['mailingAddress', 'country'])}
                                {...manageFieldPropFactory<ManageTextProps>(
                                    ['mailingAddress', 'country'],
                                    'onChangeText',
                                    'value'
                                )}
                                stacked
                            />
                        </GridItem>

                        <GridItem>
                            <TextInput
                                {...textFieldPropFactory(t('Address.postalCode'), ['mailingAddress', 'postalCode'])}
                                {...manageFieldPropFactory<ManageTextProps>(
                                    ['mailingAddress', 'postalCode'],
                                    'onChangeText',
                                    'value'
                                )}
                                stacked
                            />
                        </GridItem>
                    </Grid>
                </FormHeader>
            </Flex>
        </Box>
    );
};

export default Address;
