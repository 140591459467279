import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

const commonStyle = {
    fontSize: 'x-small',
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: 10,
    padding: '2px 12px',
    fontWeight: 'medium',
    textTransform: 'none',
    textStyle: 'xs-medium',
};

const success = defineStyle({
    ...commonStyle,
    background: 'surface-brand-invert',
    color: 'text-brand-primary',
});

const warning = defineStyle({
    ...commonStyle,
    background: '#FAE8E9',
    color: '#C91D25',
});

const error = defineStyle({
    ...commonStyle,
    background: 'surface-error-invert',
    color: 'surface-error',
});

const subtle = defineStyle({
    ...commonStyle,
    background: '#FAEDD6',
    color: '#9B5800',
});

const primary = defineStyle({
    ...commonStyle,
    background: 'surface-brand-invert',
    color: 'text-brand-primary',
});

const neutral = defineStyle({
    ...commonStyle,
    background: '#CFEAFA',
    color: '#2C476A',
});

const pink = defineStyle({
    ...commonStyle,
    background: '#FEDBE5',
    color: '#6F2458',
});

const grey = defineStyle({
    ...commonStyle,
    background: '#EDF2F7',
    color: '#1A202C',
});

const Badge = defineStyleConfig({
    variants: {
        success,
        warning,
        error,
        subtle,
        primary,
        neutral,
        grey,
        pink,
    },
});

export default Badge;
