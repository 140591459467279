import * as React from 'react';
function SvgContact(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <mask
                id='contact_svg__a'
                style={{
                    maskType: 'alpha',
                }}
                maskUnits='userSpaceOnUse'
                x={0}
                y={0}
                width={24}
                height={24}
            >
                <path fill='#D9D9D9' d='M0 0h24v24H0z' />
            </mask>
            <g mask='url(#contact_svg__a)'>
                <path
                    d='M12.002 13.96a2.05 2.05 0 001.51-.633 2.08 2.08 0 00.628-1.516c0-.59-.21-1.092-.63-1.51a2.068 2.068 0 00-1.512-.626c-.59 0-1.093.21-1.51.63-.419.419-.628.923-.628 1.512 0 .59.21 1.094.63 1.514.419.42.923.63 1.512.63zM7.86 18.14h8.28v-.715c0-.422-.119-.811-.356-1.168a2.29 2.29 0 00-.953-.816 7.46 7.46 0 00-1.364-.43c-.468-.1-.958-.15-1.47-.15-.51 0-1 .05-1.469.15a7.46 7.46 0 00-1.364.43c-.395.188-.711.46-.948.816a2.067 2.067 0 00-.356 1.168v.715zm9.968 4.063H6.172a2.289 2.289 0 01-1.68-.694 2.289 2.289 0 01-.695-1.68V4.171c0-.657.231-1.217.694-1.68a2.29 2.29 0 011.68-.695h6.95c.32 0 .629.062.924.187.294.124.552.298.772.522l4.678 4.677c.223.22.397.478.521.772.125.295.187.603.187.925v10.948a2.29 2.29 0 01-.694 1.68 2.289 2.289 0 01-1.68.695zm0-2.375V8.93L13.07 4.172H6.172v15.656h11.656z'
                    fill='#171923'
                />
            </g>
        </svg>
    );
}
export default SvgContact;
