import { Avatar, Box, Flex, Spacer, Text, useDisclosure } from '@chakra-ui/react';
import { GhostIcon } from '@Components/Button';
import { useToast } from '@Hooks/useToast';
import { PictureAsPdf } from '@Icon';
import { useLazyGetReportCardQuery } from '@Redux/services/Reports';
import { StudentRecord } from '@Redux/services/Reports/types';
import { downloadFile, ErrorResponse, parseError } from '@Utilities';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { PDF_FILE_CONTENT_TYPE, report_analytics_types, report_status } from 'src/constants';

interface IProps extends CellProps<object> {
    handleViewPdf?: (studentId: string, reportId: string) => void;
    isAnalytics?: boolean;
    filterStatus?: number;
}

const FullnameCell: React.FC<IProps> = ({ value, row, isAnalytics, filterStatus }) => {
    const { t } = useTranslation();
    const { isOpen: isDownloading, onClose, onOpen } = useDisclosure();
    const [getReportCardPdf] = useLazyGetReportCardQuery();
    const toast = useToast();
    const rowData = row.original as StudentRecord;
    const name = isAnalytics && filterStatus === report_analytics_types.UnknownOENs ? rowData.fullNameRC : value;

    const handleViewPdf = async (newStudentId: string, newReportId: string) => {
        onOpen();
        await getReportCardPdf({ studentId: newStudentId, reportId: newReportId })
            .unwrap()
            .then(async (response) => {
                await downloadFile(PDF_FILE_CONTENT_TYPE, response.data.fileContents, response.data.fileName);
                onClose();
            })
            .catch((error) => {
                parseError(toast, error as ErrorResponse);
                onClose();
            });
    };

    return (
        <Flex alignItems={'center'} gap={4} _hover={{ cursor: rowData.reportId && 'pointer' }}>
            <Flex alignItems={'center'} gap={0} pl={0}>
                <Flex alignItems={'center'} gap={4} pl={4}>
                    <Avatar
                        name={name}
                        src={
                            rowData.studentPhoto
                                ? `data:${rowData.studentPhoto.mimeType};base64,${rowData.studentPhoto.base64}`
                                : ''
                        }
                        size='md'
                    />
                    <Box w={'full'}>
                        <Text
                            _disabled={{ textColor: '#FFFFFF' }}
                            textColor={'black'}
                            textAlign={'left'}
                            textStyle='sm-medium'
                            sx={{
                                _first: {
                                    textTransform: 'Capitalize',
                                },
                            }}
                        >
                            {name}
                        </Text>
                    </Box>
                </Flex>
            </Flex>
            <Spacer />
            {isAnalytics || rowData.reportStatus === report_status.Missing || (
                <GhostIcon
                    justifySelf='flex-end'
                    aria-label='View PDF'
                    size={'sm'}
                    borderRadius='sm'
                    title={t('Reports.viewPdf')}
                    isLoading={isDownloading}
                    isDisabled={isDownloading}
                    icon={<PictureAsPdf width={20} height={20} fill='text-primary' />}
                    onClick={() => handleViewPdf(rowData.contactId, rowData.reportId)}
                />
            )}
        </Flex>
    );
};

export default FullnameCell;
