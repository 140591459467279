import { Button } from '@chakra-ui/button';
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { format } from 'date-fns';
import { Save } from '@Icon';
import { DATE_TIME_FORMAT } from 'src/constants';

type FooterOption = {
    title: string;
    name: string;
    date: Date;
};

type FooterProps = {
    footerOptions: FooterOption[];
    onSave?: () => void;
    disabled?: boolean;
};

const Footer: React.FC<FooterProps> = ({ footerOptions, onSave, disabled }) => {
    const { t } = useTranslation();

    const { id } = useParams();

    const [showMore, setShowMore] = useState(false);

    return (
        <Box
            sx={{
                display: id ? 'block' : 'none',
            }} // hiding the footer section for list pages (example: events, students)
            bg='surface-secondary'
            zIndex='100'
            position='fixed'
            bottom={0}
            left={1}
            px={6}
            py={2}
            width={{ base: '210px', lg: '320px' }}
        >
            <Flex justifyContent={'space-between'} w={'100%'}>
                <motion.div
                    initial={{ height: showMore ? 0 : 0 }}
                    animate={{ height: showMore ? 'auto' : onSave ? 125 : 80 }}
                    transition={{ duration: 0.3 }}
                >
                    <Flex direction='column' rowGap={2}>
                        {footerOptions.slice(0, showMore ? footerOptions.length : 1).map((f, i) => (
                            <Box key={i}>
                                <Text textStyle={'sm-bold'}>{f?.title}</Text>
                                {f?.name && f?.date ? (
                                    <>
                                        <Text textStyle={'sm-medium'}>{f?.name}</Text>
                                        <Text textStyle={'sm-medium'}>{format(f?.date, DATE_TIME_FORMAT)}</Text>
                                    </>
                                ) : (
                                    <Text textStyle={'sm-medium'}>-</Text>
                                )}
                            </Box>
                        ))}
                        {onSave && (
                            <Button
                                isDisabled={disabled}
                                size='sm'
                                colorScheme='teal'
                                rounded={'md'}
                                onClick={() => onSave()}
                                leftIcon={<Save color='text-secondary' />}
                            >
                                {t('Sidebar.save')}
                            </Button>
                        )}
                    </Flex>
                </motion.div>
                <Box onClick={() => setShowMore(!showMore)}>
                    {showMore ? (
                        <ChevronUpIcon w={5} h={5} cursor={'pointer'} />
                    ) : (
                        <ChevronDownIcon w={5} h={5} cursor={'pointer'} />
                    )}
                </Box>
            </Flex>
        </Box>
    );
};

export { Footer };
