import { IconProps } from '@chakra-ui/icons';
import { Box, Text, Flex, BoxProps, Progress, Tag, TagLabel, TagLeftIcon, ComponentWithAs } from '@chakra-ui/react';
import { FC } from 'react';

interface IProps extends BoxProps {
    statLabel: string;
    isLoading: boolean;
    isPlainStat: boolean;
    plainStat?: string;
    progress?: number;
    total?: number;
    hasPill: boolean;
    pillColor?: string;
    pillBgColor?: string;
    pillIcon?: ComponentWithAs<'svg', IconProps>;
    pillLabel?: string;
    hasProgressBar: boolean;
    pbVariant?: string;
    header?: string;
    totalCountLabel?: string;
}
export const StatsCard: FC<IProps> = ({
    statLabel,
    isPlainStat,
    plainStat,
    progress,
    total,
    hasPill,
    pillColor,
    pillBgColor,
    pillIcon,
    pillLabel,
    hasProgressBar,
    pbVariant,
    isLoading,
    header,
    totalCountLabel,
    ...rest
}) => {
    return (
        <Box
            borderColor='border-primary'
            borderStyle='solid'
            borderWidth='2px'
            flexGrow={1}
            overflow={'hidden'}
            position={'relative'}
            {...rest}
        >
            {isLoading || (
                <Box w='100%'>
                    <Text pt={6} px={6} textStyle={'xl-semibold'}>
                        {header}
                    </Text>
                    <Flex direction={'row'} alignItems={'baseline'}>
                        <Text px={6}>{totalCountLabel}</Text>
                        <Text textStyle='3xl-semibold' visibility='hidden'>
                            {isPlainStat && plainStat
                                ? plainStat
                                : !isPlainStat && progress != null
                                ? total - progress
                                : ''}
                        </Text>
                        {!isPlainStat && total != null && (
                            <Text as='span' textStyle='3xl-semibold'>
                                &nbsp;{+total}
                            </Text>
                        )}
                    </Flex>
                    <Flex direction={'row'} alignItems={'baseline'} mt={3} mb={5}>
                        <Text px={6}>{statLabel}</Text>
                        <Text textStyle='3xl-semibold'>
                            {isPlainStat && plainStat
                                ? plainStat
                                : !isPlainStat && progress != null
                                ? total - progress
                                : ''}
                        </Text>
                        {!isPlainStat && total != null && (
                            <Text as='span' textStyle='3xl-semibold'>
                                /{total}
                            </Text>
                        )}

                        {hasPill && pillLabel && (
                            <Tag
                                size={'md'}
                                borderRadius={'full'}
                                backgroundColor={pillBgColor}
                                color={pillColor}
                                px={3}
                                py={1.5}
                                ml={3}
                            >
                                {pillIcon && <TagLeftIcon fill={pillColor} boxSize='12px' as={pillIcon} />}
                                <TagLabel>{pillLabel}</TagLabel>
                            </Tag>
                        )}
                    </Flex>
                    {hasProgressBar && (
                        <Progress
                            w={'100%'}
                            position={'absolute'}
                            bottom={0}
                            value={(progress / total) * 100}
                            variant={pbVariant}
                        />
                    )}
                </Box>
            )}
        </Box>
    );
};
