import * as React from 'react';
function SvgContacts(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <mask
                id='contacts_svg__a'
                style={{
                    maskType: 'alpha',
                }}
                maskUnits='userSpaceOnUse'
                x={0}
                y={0}
                width={20}
                height={20}
            >
                <path fill='#D9D9D9' d='M0 0h20v20H0z' />
            </mask>
            <g mask='url(#contacts_svg__a)'>
                <path
                    d='M3.375 11.253l2.196-2.196-2.196-2.196-2.196 2.196 2.196 2.196zm11.003-.396l2.247-3.6 2.252 3.6h-4.5zM.91 15.08a.95.95 0 01-.694-.296.95.95 0 01-.296-.694v-.486c0-.649.325-1.175.973-1.579.649-.403 1.49-.605 2.524-.605.124 0 .25.002.378.006.127.003.255.016.384.037A3.691 3.691 0 003.66 12.5c-.116.37-.174.759-.174 1.166v1.413H.91zm5 0a.95.95 0 01-.694-.296.95.95 0 01-.296-.694v-.423c0-.927.469-1.679 1.406-2.256.937-.577 2.161-.866 3.674-.866 1.524 0 2.752.289 3.683.866s1.397 1.33 1.397 2.256v.423a.95.95 0 01-.296.694.95.95 0 01-.694.296H5.91zm10.603 0v-1.413c0-.42-.058-.812-.172-1.178a3.52 3.52 0 00-.52-1.026c.129-.021.257-.034.385-.037.127-.004.253-.006.377-.006 1.034 0 1.875.202 2.524.605.648.404.973.93.973 1.58v.485a.95.95 0 01-.296.694.95.95 0 01-.694.296h-2.577zM10 12.43c-.645 0-1.239.074-1.78.223-.542.149-.928.33-1.159.542h5.878c-.228-.21-.613-.39-1.156-.54A6.665 6.665 0 0010 12.43zm-.001-2.505c-.717 0-1.326-.25-1.827-.752a2.49 2.49 0 01-.752-1.829c0-.717.251-1.325.753-1.827a2.491 2.491 0 011.828-.751c.717 0 1.326.25 1.827.753a2.49 2.49 0 01.752 1.828c0 .717-.251 1.326-.753 1.827a2.491 2.491 0 01-1.828.751zm0-3.28a.673.673 0 00-.495.205.677.677 0 00-.204.496c0 .195.068.36.204.496a.677.677 0 00.497.204c.194 0 .36-.068.495-.205a.678.678 0 00.204-.496.673.673 0 00-.204-.496.677.677 0 00-.497-.204z'
                    fill='#171923'
                />
            </g>
        </svg>
    );
}
export default SvgContacts;
