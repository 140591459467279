export const DEFAULT_PAGE_SIZE = 5;
export const PAGE_SIZE_50 = 50;

export enum EventStatus {
    Proposed = 0,
    Validated = 1,
    Published = 2,
    Closed = 951030001,
    Cancelled = 951030000,
}

//TODO: convert below conts to enum
export const ACTIVITY_TYPE_FIELD_TRIP = 951030005;
export const ACTIVITY_TYPE_ONGOING_CONSENT = 951030015;
export const ACTIVITY_TYPE_SPORTS_EVENT = 951030010;

export const TARGET_TYPE_SCHOOL = 951030000;
export const TARGET_TYPE_GRADE = 951030005;
export const TARGET_TYPE_CLASS = 951030010;
export const TARGET_TYPE_CUSTOM_LIST = 951030015;

export const EXCLUDE_DEFAULT_SCHOOL_NAME = 'Upper Canada District School Board';

export enum PaymentTransactionTypes {
    PayPalEventPayment = 951030000,
    PayPalEventRefund = 951030005,
    PayPalLoadFunds = 951030010,
    ChequeCashOut = 951030015,
    DonationCashOut = 951030020,
    PayPalCashOut = 951030025,
    EventPaymentFee = 951030030,
    PayPalCashOutFee = 951030035,
    DonationCashOutFee = 951030040,
    CashEventPayment = 951030045,
    CashEventRefund = 951030055,
    ChequeEventRefund = 951030060,
    ChequeEventPayment = 951030050,
    PayPalGroupTransaction = 951030001,
}

export const EVENT_RESPONSE_CANCELLED = 951030001;
export const EVENT_RESPONSE_CANCELLED_AND_CLOSED = 951030003;
export const EVENT_RESPONSE_CLOSED = 2;
export const EVENT_RESPONSE_CONFIRMED = 951030000;
export const EVENT_RESPONSE_CONFIRMED_AND_CLOSED = 951030004;
export const EVENT_RESPONSE_DRAFT = 951030002;
export const EVENT_RESPONSE_PENDING = 1;

export const EVENT_ANSWERS_MEASUREMENTS = {
    0: 'Yes',
    1: 'No',
    2: 'Blank',
};

export const PAYMENT_METHOD_EWALLET = 951030000;
export const PAYMENT_METHOD_CASH = 951030005;
export const PAYMENT_METHOD_CHEQUE = 951030010;

export const EXCEL_FILE_CONTENT_TYPE = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64';
export const PDF_FILE_CONTENT_TYPE = 'data:application/pdf;base64';

export const COST_ITEM_GROUP1 = 951030001;
export const COST_ITEM_GROUP2 = 951030002;
export const COST_ITEM_GROUP3 = 0;

export const MAX_COST_ITEM_OPTIONS = 15;

export const COST_ITEM_REQUIRED = 951030000;
export const COST_ITEM_REQUIRED_SELECT_ONE = 951030005;
export const COST_ITEM_OPTIONAL = 951030010;

export const DATE_TIME_FORMAT = 'MMM dd yyyy h:mma';
export const DATE_FORMAT = 'MMM dd yyyy';
export const TIME_FORMAT = 'h:mma';
export const PENDING_CALLS_DATE_TIME_FORMAT = 'MMM dd, h:mma';
export const DATE_FORMAT_E_MMM_YY = 'E MMM yy';

export enum StudentActivityPriority {
    Low = 0,
    Normal = 1,
    High = 2,
}

export const FIVE_MINUTES_MS = 300000;

export enum phoneCallStatusCodeEnum {
    Open = 1,
    Made = 2,
    Received = 4,
    Canceled = 3,
    AutomaticallyCanceled = 951030000,
}

export enum MFRStatusCodeEnum {
    New = 1,
    Expired = 2,
    Matched = 951030000,
    Deleted = 951030001,
}

export enum jobs_status {
    New = 1,
    Successful = 951030000,
    Failed = 951030003,
}

export enum report_status {
    Published = 951030000,
    Removed = 951030001,
    Pending = 951030002,
    Missing = 951030003,
}

export enum report_analytics_types {
    MissingReportCards = 1,
    MismatchedStudentInformation = 2,
    UnknownOENs = 3,
    OpenRatesOfReportCards = 4,
}
export enum UpdateStatus {
    Submitted = 951030000,
    Approved = 951030001,
    Deleted = 951030004,
    Rejected = 951030002,
}

export enum RegistrationStatus {
    Submitted = 951030001,
    DocumentsRequired = 951030003,
    Approved = 951030004,
    Archived = 951030007,
    Rejected = 951030002,
    Expired = 951030006,
    Deleted = 951030005,
}

export enum ParentPosition {
    Parent1 = 951030000,
    Parent2 = 951030001,
    Parent3 = 951030002,
    Parent4 = 951030003,
}
