import {
    InputGroup,
    FormControl,
    FormHelperText,
    FormLabel,
    BackgroundProps,
    Box,
    HStack,
    InputRightElement,
    InputLeftElement,
    Text,
} from '@chakra-ui/react';
import { FC, useEffect, useState, useRef, useMemo, ComponentProps } from 'react';
import TimePicker from 'react-time-picker';
import { Calendar, Lock, Trash } from '@Icon';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { format } from 'date-fns';
import { isDate, isNull } from 'lodash';
import './style.css';
export interface DatePickerProps {
    isLocked: boolean;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    label?: string;
    error?: string;
    date?: Date;
    isRequired?: boolean;
    isInvalid?: boolean;
    onDateChange?: (date?: Date) => void;
    bg?: BackgroundProps['bg'];
    stacked?: boolean;
    col?: number;
    disabled?: boolean;
    hasTimeComponent?: boolean;
    placeHolder?: string;
    size: 'sm' | 'md';
    usePortal?: boolean;
    portalRef?: React.MutableRefObject<null>;
    labelProps?: ComponentProps<typeof FormLabel>;
}

const DatePickerInput: FC<DatePickerProps> = ({
    label,
    isInvalid,
    error,
    isLocked,
    onDateChange,
    bg = 'transparent',
    size = 'md',
    date,
    stacked = false,
    col,
    placeHolder,
    hasTimeComponent,
    labelProps,
    ...rest
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currTimeString, setCurrTimeString] = useState<string>('');
    const ref = useRef<HTMLSpanElement>();

    useEffect(() => {
        if (!hasTimeComponent) return;
        setCurrTimeString(date ? format(new Date(date), 'HH:mm a') : null);
    }, [date]);

    const handleTimeChange = (value: string) => {
        const newDate: Date = date ? new Date(date) : null;
        if (date && value) {
            newDate.setHours(Number(value.substring(0, 2)), Number(value.substring(3, 5)));
            setCurrTimeString(value);
        } else if (isNull(value)) {
            newDate?.setHours(0, 0);
            setCurrTimeString(null);
        } else {
            setCurrTimeString(value);
        }
        onDateChange(newDate);
    };

    const handleDateChange = (value: Date) => {
        if (isDate(value) && currTimeString) {
            value.setHours(Number(currTimeString.substring(0, 2)), Number(currTimeString.substring(3, 5)));
        } else {
            setCurrTimeString('');
        }
        onDateChange(value);
    };

    const handleCalendarIconClick = () => {
        if (ref && ref?.current.childNodes.length > 0) {
            (ref?.current.childNodes[0] as HTMLInputElement).click();
        }
    };

    const isSm = useMemo(() => size === 'sm', [size]);

    return (
        <FormControl
            isInvalid={isInvalid}
            display={{ base: 'block', lg: 'flex' }}
            alignItems={stacked ? 'start' : 'baseline'}
            flexDirection={stacked ? 'column' : 'row'}
        >
            {label && (
                <FormLabel
                    color={(rest.disabled || isLocked) && 'text-disabled'}
                    flexBasis={col === 1 ? '12.5%' : '30%'}
                    textStyle={'sm-medium'}
                    {...labelProps}
                >
                    {label}
                    {rest.isRequired && '*'}
                </FormLabel>
            )}
            <Box w={'100%'}>
                <HStack w='100%'>
                    <InputGroup bg={bg}>
                        {!date && (
                            <InputLeftElement
                                w='72.5%'
                                bg='white'
                                justifyContent={'start'}
                                left={'5'}
                                onClick={() => setIsOpen(true)}
                                top={!isSm ? '10px' : '5px'}
                                height='6'
                                alignSelf='center'
                                zIndex={0}
                            >
                                <Text textStyle={'sm-medium'}>{placeHolder}</Text>
                            </InputLeftElement>
                        )}
                        <span ref={ref} style={{ width: '100%' }}>
                            <SingleDatepicker
                                defaultIsOpen={isOpen}
                                onDateChange={handleDateChange}
                                disabled={isLocked}
                                date={(date && new Date(date)) || undefined}
                                propsConfigs={{
                                    inputProps: {
                                        height: isSm ? 8 : 10,
                                        minW: '200px',
                                        _disabled: {
                                            color: 'text-disabled',
                                        },
                                    },
                                }}
                                {...rest}
                            />
                        </span>
                        {date && !(isLocked || rest.disabled) && (
                            <InputRightElement
                                onClick={() => onDateChange(null)}
                                cursor={!isLocked && 'pointer'}
                                right={6}
                                zIndex={1}
                                top={isSm ? '-3px' : '0px'}
                            >
                                <Trash />
                            </InputRightElement>
                        )}
                        <InputRightElement top={isSm ? '-3px' : '0px'} onClick={handleCalendarIconClick}>
                            {isLocked ? <Lock /> : <Calendar />}
                        </InputRightElement>
                    </InputGroup>
                    {hasTimeComponent && (
                        <Box w={'fit-content'}>
                            <TimePicker
                                clearIcon={isLocked ? <Lock /> : <Trash />}
                                clockIcon={null}
                                value={currTimeString}
                                disabled={isLocked}
                                onChange={handleTimeChange}
                                format='hh:mm a'
                                className={isInvalid && 'react-time-picker-invalid'}
                            />
                        </Box>
                    )}
                </HStack>
                {error && (
                    <FormHelperText p={2} textStyle='sm-normal' color={isInvalid && 'text-error'}>
                        {error}
                    </FormHelperText>
                )}
            </Box>
        </FormControl>
    );
};

export default DatePickerInput;
