import { ToastId, UseToastOptions } from '@chakra-ui/react';
import { BaseResponse } from '@Redux/services/commonTypes';
import { BasicLookup } from '@Redux/services/LookupApi/types';
import { TFunction } from 'react-i18next';

export const charsum = (chars: string): number => [...chars].reduce((memo, char) => memo + char.charCodeAt(0), 0);

type AccessorProperty = string | number;
export type Accessor = AccessorProperty[];

export const getMutableForAccessor = (mutable: Record<string | number, any>, accessorPath: Accessor) => {
    let finalMutable = mutable;
    accessorPath.slice(0, -1).forEach((segment) => {
        finalMutable[segment] ??= {};
        finalMutable = finalMutable[segment];
    });
    return finalMutable;
};

type ErrorData = {
    title: string;
    status: number;
    detail?: string;
    errors: {
        'UserContext.Identifier': string[];
        Status: string[];
    };
};

export type Error = { status: number; data?: ErrorData } | ErrorData;

export const getErrorDetails = (t: TFunction, error: Error) => {
    let title = '';
    let description = '';
    const status = error?.status;
    switch (true) {
        case status >= 500 && status <= 599:
            description = 'Server Error';
            break;
        case status >= 400 && status <= 499:
            if ('data' in error) {
                title = error?.data?.title;
                description = error?.data?.detail || error?.data?.errors?.[0];
            } else if ('title' in error && 'errors' in error) {
                title = error?.title;
                description = error?.errors?.[0];
            }
            break;
        default:
            break;
    }
    return {
        title: title || t('EventsList.errorDescription'),
        description,
    };
};

export const fixDate = (d: Date) => {
    if (d) {
        const ud = new Date(d);
        const nd = new Date();
        nd.setUTCDate(ud.getDate());
        nd.setUTCFullYear(ud.getFullYear());
        nd.setUTCMonth(ud.getMonth());
        nd.setUTCHours(0);
        nd.setUTCMinutes(0);
        nd.setUTCSeconds(0);
        nd.setUTCMilliseconds(0);
        return nd;
    }
    return d;
};

export const fixDateEnd = (d: Date) => {
    if (d) {
        const ud = new Date(d);
        const nd = new Date();
        nd.setUTCDate(ud.getDate());
        nd.setUTCFullYear(ud.getFullYear());
        nd.setUTCMonth(ud.getMonth());
        nd.setUTCHours(23);
        nd.setUTCMinutes(59);
        nd.setUTCSeconds(59);
        nd.setUTCMilliseconds(0);
        return nd;
    }
    return d;
};

export interface ErrorResponse {
    data: { title: string; errors: Record<string, string> };
}
export const parseError = (toast: (options?: UseToastOptions) => ToastId, error: ErrorResponse) => {
    if (!error.data) return;
    for (const err of Object.values(error.data.errors)) {
        toast({
            status: 'error',
            description: err,
            title: error.data.title,
        });
    }
};

export const parseWarning = (toast: (options?: UseToastOptions) => ToastId, response: BaseResponse<any>) => {
    if (!response?.warningResult?.showAlert) return;
    toast({
        status: 'warning',
        description: response.warningResult.message,
    });
};

export const createLookupMap = function <Type extends BasicLookup>(lookupApiResponse?: Type[]) {
    const map = {} as Record<number, Pick<Type, Exclude<keyof Type, 'key'>>>;
    lookupApiResponse?.forEach(({ key, ...rest }: Type) => {
        map[key] = rest;
    });
    return map;
};

export const downloadFile = async (contentType: string, content: string, fileName: string) => {
    const linkSource = `${contentType},${content}`;
    await fetch(linkSource).then(async (res) => {
        const blob = await res.blob();
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.setAttribute('download', fileName);
        downloadLink.target = '_blank';
        downloadLink.click();
    });
};

export interface FileContent {
    contentType?: string;
    fileContents: string;
    fileName: string;
}

export const parseCurrency = (num: number) => {
    return '$' + num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1');
};

export const checkIfValidUUID = (value: string) =>
    /^\{?[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\}?$/.test(value);

export interface ValidatorType {
    accessorPath: Accessor;
    validator: (value: string | Date | boolean | number) => boolean;
    message: string;
    required: boolean;
    editableStatus?: number[];
    validateSave?: boolean;
    dependantPath?: Accessor;
}

export type Dictionary = Record<string, string>;

export const roundUpValue = (value: number, precision: number) => {
    const newPrecision = Math.pow(10, precision);
    return Math.ceil(value * newPrecision) / newPrecision;
};

export const getErrorsAssociatedWithFields = (errors: Dictionary, fields: string[]) => {
    const newErrors: Dictionary = {};
    for (const field of fields) {
        if (field in errors) {
            newErrors[field] = errors[field];
        }
    }
    return newErrors;
};

/**
 * Move an element in an array from one index to another
 * @param array Array of elements
 * @param from from index
 * @param to to Index
 */
export const move = (array, from: number, to: number) => {
    array.splice(to, 0, array.splice(from, 1)[0]);
};

export const byteConverter = (bytes, decimals, only) => {
    const K_UNIT = 1024;
    const SIZES = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

    if (bytes == 0) return '0 Byte';

    if (only === 'MB') return (bytes / (K_UNIT * K_UNIT)).toFixed(decimals) + ' MB';

    const i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
    const resp = parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) + ' ' + SIZES[i];

    return resp;
};

export const convertUTCDateToLocalDate = (dateString: string) => {
    if (dateString.endsWith('Z')) {
        return new Date(dateString.replace('Z', ''));
    }
    const date = new Date(dateString);
    const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    const offset = date.getTimezoneOffset() / 60;
    const hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
};
