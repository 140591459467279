import * as React from 'react';
function SvgDelete(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M5.831 17.73c-.525 0-.972-.184-1.341-.554a1.827 1.827 0 01-.555-1.341V5.114a.916.916 0 01-.673-.274.916.916 0 01-.275-.673c0-.266.092-.49.275-.674a.916.916 0 01.673-.274h3.503c0-.266.091-.49.274-.674a.916.916 0 01.674-.274h3.323c.266 0 .49.091.674.274a.916.916 0 01.274.674h3.513c.266 0 .49.091.673.274a.916.916 0 01.275.674c0 .266-.092.49-.275.673a.916.916 0 01-.673.275v10.719c0 .524-.185.971-.555 1.341-.37.37-.816.554-1.341.554H5.831zm8.443-12.614H5.831v10.719h8.443V5.114zm-6.811 9.107h1.781v-7.5H7.463v7.5zm3.398 0h1.781v-7.5h-1.781v7.5z'
                fill='currentColor'
            />
        </svg>
    );
}
export default SvgDelete;
