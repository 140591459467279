import { Box, Flex, Grid, Stack } from '@chakra-ui/react';
import AvatarLabel from '@Components/AvatarLabel';
import DateTimeInput from '@Components/DateTimeInput';
import { DetailPageSkeleton } from '@Components/DetailPageSkeleton';
import Header from '@Components/DetailsHeader';
import NotesTab from '@Components/Notes/components/NotesTab';
import Sidebar from '@Components/Sidebar';
import TextInput from '@Components/TextInput';
import TypeaheadInput from '@Components/TypeaheadInput';
import { Forum, Summary } from '@Icon';
import { useGetStudentActivityQuery } from '@Redux/services/Absence';
import { useGetStudentActivityStatusCodesQuery } from '@Redux/services/LookupApi';
import { round } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE, StudentActivityPriority } from 'src/constants';

type IRouteParams = {
    id: string;
};

const StudentActivityDetails = () => {
    const { id } = useParams<IRouteParams>();
    const { t } = useTranslation();
    const { data: { data: studentActivity } = {}, refetch, isLoading } = useGetStudentActivityQuery({ id });
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const { data: { data: studentActivityCodes } = {} } = useGetStudentActivityStatusCodesQuery();

    const activityCodes = useMemo(
        () => studentActivityCodes?.map((code) => ({ key: code.key.toString(), value: code.value })),
        [studentActivityCodes]
    );

    useEffect(() => {
        refetch();
    }, [id]);

    const sideBarItems = useMemo(
        () => ({
            header: (
                <AvatarLabel name={studentActivity?.type} subTitle={t('StudentActivityDetails.sideBar.subtitle')} />
            ),
            groups: [
                {
                    items: [
                        {
                            label: t('StudentActivityDetails.summary'),
                            id: '#summary',
                            icon: <Summary />,
                            active: false,
                        },
                        {
                            label: t('StudentActivityDetails.notes'),
                            id: '#notes',
                            icon: <Forum />,
                            active: false,
                        },
                    ],
                },
            ],
        }),
        [studentActivity]
    );

    const handleNotesNextPage = async (currentPage: number, pageSize: number) => {
        setPage(currentPage);
        setPageSize(pageSize);
    };

    if (isLoading) {
        return <DetailPageSkeleton />;
    }

    return (
        <Flex>
            <Sidebar
                {...sideBarItems}
                footerDetails={{
                    modifiedByFullName: studentActivity?.modifiedBy,
                    modifiedOn: studentActivity?.modifiedOn,
                    createdOn: studentActivity?.createdOn,
                    createdByFullName: studentActivity?.createdBy,
                }}
            />
            <Box w='100%' pt={8} pr={4}>
                <Flex gap={3} mb={8}>
                    <TypeaheadInput
                        label={t('StudentActivityDetails.status')}
                        options={activityCodes}
                        value={studentActivity?.status.toString()}
                        isLocked
                    />
                    <TextInput
                        label={t('StudentActivityDetails.priority')}
                        value={StudentActivityPriority[studentActivity?.priority]}
                        isLocked
                    />
                    <TypeaheadInput
                        label={t('StudentActivityDetails.student')}
                        value={studentActivity?.studentId}
                        options={[
                            {
                                key: studentActivity?.studentId,
                                value: studentActivity?.studentName,
                            },
                        ]}
                        baseUrl='/records/student'
                        isLocked
                    />
                </Flex>
                <Stack
                    borderRadius='20px 0 0 0'
                    borderWidth='2px 0px 2px 2px'
                    borderStyle='solid'
                    borderColor='border-primary'
                    w={'full'}
                    p={8}
                    bg={'surface-secondary'}
                >
                    <Box id='summary'>
                        <Header
                            divider={true}
                            mb={4}
                            title={t('StudentActivityDetails.formHeader.general')}
                            icon={<Summary />}
                        />
                        <Grid
                            column={{ md: 2, sm: 1 }}
                            columnGap={10}
                            gridAutoFlow='dense'
                            templateColumns={{
                                base: 'repeat(1, 1fr)',
                                md: 'repeat(2, 1fr)',
                            }}
                            rowGap={4}
                            mt={3}
                        >
                            <TextInput
                                bg='surface-primary'
                                label={t('StudentActivityDetails.type')}
                                value={studentActivity?.type}
                                isLocked
                            />
                            <TypeaheadInput
                                bg='surface-primary'
                                label={t('StudentActivityDetails.relatedAbsence')}
                                value={studentActivity?.relatedAbsenceId}
                                options={[
                                    {
                                        key: studentActivity?.relatedAbsenceId,
                                        value: studentActivity?.relatedAbsenceName,
                                    },
                                ]}
                                isLocked
                            />
                            <DateTimeInput
                                bg='surface-primary'
                                hasTimeComponent
                                label={t('StudentActivityDetails.createdOn')}
                                date={studentActivity?.createdOn ? new Date(studentActivity?.createdOn) : null}
                                size='md'
                                isLocked
                            />
                            <TextInput
                                bg='surface-primary'
                                label={t('StudentActivityDetails.respondingGaurdian')}
                                value={studentActivity?.respondingGuardian}
                                isLocked
                            />
                        </Grid>
                    </Box>

                    <Box id='notes' width={'100%'}>
                        <Header divider={true} mb={4} title={t('StudentActivityDetails.notes')} icon={<Forum />} />
                        <NotesTab
                            notes={studentActivity?.notes?.slice((page - 1) * pageSize, page * pageSize)}
                            onNextPage={handleNotesNextPage}
                            pagedResult={{
                                currentPage: page,
                                pageCount:
                                    studentActivity?.notes?.length > pageSize
                                        ? round(studentActivity?.notes?.length / pageSize, 1)
                                        : 1,
                                pageSize: pageSize,
                                rowCount: studentActivity?.notes?.length,
                            }}
                        />
                    </Box>
                </Stack>
            </Box>
        </Flex>
    );
};

export default StudentActivityDetails;
