import { Text, useToken } from '@chakra-ui/react';
import { isNumber } from 'lodash';
import { ComponentProps, FC, useMemo } from 'react';
import { Bar, BarChart as ReBarChart, Cell, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

interface IProps extends ComponentProps<typeof ReBarChart> {
    barDataKey?: string;
    labelDataKey?: string;
    emptyMessage: string;
    xAxisProps?: ComponentProps<typeof XAxis>;
    yAxisProps?: ComponentProps<typeof YAxis>;
}

export const BarChart: FC<IProps> = ({
    data,
    emptyMessage,
    barDataKey,
    labelDataKey,
    xAxisProps,
    yAxisProps,
    ...rest
}) => {
    const [cyan500, orange500, purple500, teal500, green500, blue500, gray500, white] = useToken('colors', [
        'cyan.500',
        'orange.500',
        'purple.500',
        'teal.500',
        'green.500',
        'blue.500',
        'gray.500',
        'surface-primary',
    ]);

    const barColors = useMemo(() => [cyan500, orange500, purple500, teal500, green500, blue500, gray500], []);
    const isEmpty = useMemo(() => data.every((item) => isNumber(item.value) && item.value === 0), [data]);
    if (isEmpty) {
        return (
            <Text textAlign='center' textStyle={'sm-medium'}>
                {emptyMessage}
            </Text>
        );
    }
    return (
        <ResponsiveContainer width={'100%'} height={300} debounce={300}>
            <ReBarChart data={data} {...rest}>
                <XAxis {...xAxisProps} />
                <YAxis {...yAxisProps} />
                <Bar isAnimationActive={false} dataKey={barDataKey} barSize={30}>
                    <LabelList
                        dataKey={labelDataKey}
                        position='center'
                        fill={white}
                        style={{ fontWeight: 'bold', fontSize: '12px' }}
                    />
                    {data.map((_, index) => (
                        <Cell key={`cell-${index}`} fill={barColors[index]} />
                    ))}
                </Bar>
                <Tooltip cursor={{ fill: 'transparent' }} />
            </ReBarChart>
        </ResponsiveContainer>
    );
};
