import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

const getStyle = (filledTrackBg: string) => {
    return defineStyle({
        track: {
            bg: 'border-primary',
        },
        filledTrack: {
            bg: filledTrackBg,
        },
    });
};

const success = getStyle('surface-success');
const brandPrimary = getStyle('surface-brand-primary');
const warning = getStyle('surface-warning');
const error = getStyle('surface-error');

const Progress = defineStyleConfig({
    variants: { success, 'brand-primary': brandPrimary, warning, error },
});

export default Progress;
