import { Text } from '@chakra-ui/react';
import { BasicLookup } from '@Redux/services/LookupApi/types';
import { StudentRecord } from '@Redux/services/Reports/types';
import { createLookupMap } from '@Utilities';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';

interface IProps extends CellProps<object> {
    grades?: BasicLookup[];
}

const DiscrepancyCell: React.FC<IProps> = ({ row, grades }) => {
    const { t } = useTranslation();
    const rowData = row.original as StudentRecord;
    const gradesMap = useMemo(() => createLookupMap<BasicLookup>(grades), [grades]);
    const discrepancies = [];

    const getDiscrepancy = (field: string, reportCardData: string, s2dData: string) => {
        return `${field}: ${reportCardData} (${t('Records.students.reportCard')}) / ${s2dData} (${t(
            'Records.students.schoolToDo'
        )})`;
    };

    if (rowData.fullName !== rowData.fullNameRC) {
        discrepancies.push(getDiscrepancy(t('Records.students.fullName'), rowData.fullNameRC, rowData.fullName));
    }
    if (rowData.grade !== rowData.gradeRC) {
        discrepancies.push(
            getDiscrepancy(
                t('Records.students.grade'),
                gradesMap[rowData.gradeRC] ? gradesMap[rowData.gradeRC].value : '--',
                gradesMap[rowData.grade] ? gradesMap[rowData.grade].value : '--'
            )
        );
    }

    return (
        <>
            {discrepancies.map((discrepancy) => (
                <Text py={1}>{discrepancy}</Text>
            ))}
        </>
    );
};

export default DiscrepancyCell;
