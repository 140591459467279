import { Info } from '@Icon';
import { Box, Tag, TagLabel, Tooltip } from '@chakra-ui/react';

export const TagItem = ({ backgroundColor, color, label, tooltip = '' }) => {
    return (
        <Tooltip label={tooltip}>
            <Tag
                size={'sm'}
                borderRadius={'full'}
                backgroundColor={backgroundColor}
                color={color}
                ml={2}
                verticalAlign={'middle'}
            >
                <TagLabel textStyle={'xs-medium'} fontSize={'10px'}>
                    {label}{' '}
                    {Boolean(tooltip) && (
                        <Box display={'inline-block'} verticalAlign='top'>
                            <Info width={12} height={12} fill={color} />
                        </Box>
                    )}
                </TagLabel>
            </Tag>
        </Tooltip>
    );
};
