import Table from '@Components/Table';
import { Event, EventReschedule } from '@Redux/services/Event/types';
import { FC, useMemo, useState } from 'react';
import { Column } from 'react-table';
import type { PartialDeep } from 'type-fest';
import { useTranslation } from 'react-i18next';
import DateCell from '@Components/DateCell';
import RescheduleForm from './components/RescheduleForm';
import TableActions from '@Components/TableActions';
import { ACTIVITY_TYPE_ONGOING_CONSENT, DATE_FORMAT, DATE_TIME_FORMAT } from 'src/constants';
import { Pencil } from '@Icon';
import { Button } from '@chakra-ui/react';
import { SmallAddIcon } from '@chakra-ui/icons';

interface IProps {
    eventDetails: PartialDeep<Event>;
    onSubmit: () => void;
}

const RescheduleTable: FC<IProps> = ({ eventDetails, onSubmit }) => {
    if (eventDetails.activityTypeId === ACTIVITY_TYPE_ONGOING_CONSENT) {
        return;
    }
    const [initialEventReschedule, setInitialEventReschedule] = useState<Partial<EventReschedule>>();
    const [open, setOpen] = useState(false);
    const [locked, setLocked] = useState(false);
    const { t } = useTranslation();

    const handleOpen = (event: Partial<EventReschedule>) => {
        setInitialEventReschedule(event);
        setOpen(true);
        setLocked(true);
    };

    const handleSubmit = () => {
        setOpen(false);
        onSubmit();
    };

    const handleAddReschedule = () => {
        // TOOD: type mismatch - datestring of type string is defined as type Date
        const initialData = {
            eventId: eventDetails.eventId,
            title: '',
            startDate: eventDetails.startDate,
            endDate: eventDetails.endDate,
            dueDate: eventDetails.dueDate,
            followUpDate: eventDetails.followUpDate,
            reasonToShare: '',
            createdBy: '',
        } as any;
        setInitialEventReschedule(initialData);
        setOpen(true);
        setLocked(false);
    };

    const columns = useMemo<Column[]>(
        () => [
            {
                Header: t('RescheduleTable.name'),
                accessor: 'title',
                headerAlign: 'left',
            },
            {
                Header: t('RescheduleTable.newStartDate'),
                accessor: 'startDate',
                Cell: DateCell(DATE_TIME_FORMAT),
                headerAlign: 'left',
            },
            {
                Header: t('RescheduleTable.newEndDate'),
                accessor: 'endDate',
                Cell: DateCell(DATE_TIME_FORMAT),
                headerAlign: 'left',
            },
            {
                Header: t('RescheduleTable.newDueDate'),
                accessor: 'dueDate',
                Cell: DateCell(DATE_FORMAT),
                headerAlign: 'left',
            },
            {
                Header: t('RescheduleTable.newFollowUpDate'),
                accessor: 'followUpDate',
                Cell: DateCell(DATE_FORMAT),
                headerAlign: 'left',
            },
            {
                Header: t('RescheduleTable.createdOn'),
                accessor: 'createdOn',
                Cell: DateCell(DATE_TIME_FORMAT),
                headerAlign: 'left',
            },
            {
                Header: <>&nbsp;</>,
                accessor: 'actions',
                disableSortBy: true,
                Cell: (event: any) => <Pencil onClick={() => handleOpen(event.row.original)} />,
            },
        ],
        []
    );

    return (
        <>
            <Table
                stickyHeader
                action={<TableActions label={t('RescheduleTable.header')} />}
                manualSortBy={false}
                columns={columns}
                rawData={eventDetails.eventReschedules.reschedules ?? []}
                emptyMessage={t('RescheduleTable.emptyTable')}
                addRowButton={
                    <Button leftIcon={<SmallAddIcon />} onClick={handleAddReschedule}>
                        {t('RescheduleForm.addButtonLabel')}
                    </Button>
                }
            />
            <RescheduleForm
                initialData={initialEventReschedule}
                open={open}
                onClose={() => setOpen(false)}
                onSubmit={handleSubmit}
                locked={locked}
            />
        </>
    );
};

export default RescheduleTable;
