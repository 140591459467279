import * as React from 'react';
function SvgAlertInfo(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g clipPath='url(#alert-info_svg__clip0_3002_68077)'>
                <mask
                    id='alert-info_svg__a'
                    style={{
                        maskType: 'alpha',
                    }}
                    maskUnits='userSpaceOnUse'
                    x={0}
                    y={0}
                    width={24}
                    height={24}
                >
                    <path fill='#D9D9D9' d='M0 0h24v24H0z' />
                </mask>
                <g mask='url(#alert-info_svg__a)'>
                    <path
                        d='M12.063 17.163c.309 0 .568-.105.776-.314.21-.208.314-.467.314-.776v-3.91c0-.309-.105-.568-.314-.777a1.054 1.054 0 00-.776-.313c-.309 0-.567.104-.776.313a1.055 1.055 0 00-.314.777v3.91c0 .309.105.568.314.776.209.21.467.314.776.314zm0-7.97c.317 0 .583-.108.798-.322.214-.215.322-.48.322-.798 0-.317-.108-.583-.322-.798a1.083 1.083 0 00-.798-.322c-.317 0-.583.108-.798.322-.214.215-.321.48-.321.798 0 .317.107.583.321.798.215.214.48.322.798.322zm0 13.083a9.948 9.948 0 01-3.983-.803 10.296 10.296 0 01-3.238-2.18 10.296 10.296 0 01-2.18-3.236 9.947 9.947 0 01-.802-3.984c0-1.415.268-2.743.803-3.984a10.297 10.297 0 012.18-3.237 10.297 10.297 0 013.237-2.18 9.946 9.946 0 013.983-.802c1.415 0 2.743.267 3.984.803 1.24.535 2.32 1.261 3.237 2.18a10.296 10.296 0 012.18 3.236 9.946 9.946 0 01.802 3.984 9.947 9.947 0 01-.802 3.984 10.295 10.295 0 01-2.18 3.237 10.295 10.295 0 01-3.237 2.18 9.948 9.948 0 01-3.984.802zm0-2.275c2.217 0 4.093-.767 5.627-2.3 1.534-1.535 2.301-3.41 2.301-5.628 0-2.217-.767-4.093-2.3-5.627-1.535-1.534-3.41-2.301-5.628-2.301-2.217 0-4.093.767-5.627 2.3-1.534 1.535-2.301 3.41-2.301 5.628 0 2.217.767 4.093 2.301 5.627 1.534 1.534 3.41 2.301 5.627 2.301z'
                        fill='#2C7A7B'
                    />
                </g>
            </g>
            <defs>
                <clipPath id='alert-info_svg__clip0_3002_68077'>
                    <path fill='#fff' d='M0 0h24v24H0z' />
                </clipPath>
            </defs>
        </svg>
    );
}
export default SvgAlertInfo;
