export const primitives = {
    black: '#171717',
    white: '#FFFFFF',
    gray: {
        100: '#EDF2F7',
        200: '#E2E8F0',
        600: '#4A5568',
        900: '#171923',
    },
    teal: {
        50: '#E6FFFA',
        500: '#319795',
        600: '#2C7A7B',
    },
    green: {
        50: '#F1F7EB',
        500: '#73B234',
        600: '#407F01',
    },
    red: {
        50: '#FAE8E9',
        500: '#C91D25',
    },
    yellow: {
        100: '#FAEDD6',
        500: '#E8A530',
        700: '#9B5800',
    },
    blue: {
        100: '#CFEAFA',
        800: '#2C476A',
    },
};

export const colors = {
    text: {
        primary: '#171923',
        secondary: '#2D3748',
        brandPrimary: primitives.teal[600],
        success: primitives.green[600],
        error: primitives.red[500],
        warning: primitives.yellow[700],
        header: '#4A5568',
    },
    surface: {
        primary: primitives.white,
        secondary: primitives.gray[100],
        tertiary: primitives.gray[200],
        brandPrimary: primitives.teal[500],
        brandInvert: primitives.teal[50],
        success: primitives.green[500],
        successInvert: primitives.green[50],
        error: primitives.red[500],
        errorInvert: primitives.red[50],
        warning: primitives.yellow[500],
        warningInvert: primitives.yellow[100],
        info: '#2C7A7B',
    },
    border: {
        primary: '#CBD5E0',
    },
    lineColor: '#E2E8F0',
    accordion: '#F7FAFC',
    sidebarBtn: '#EDF2F7',
    ghost: {
        bg: {
            base: 'rgba(0, 0, 0, 0.00)',
            hover: '#F7FAFC',
            active: '#EDF2F7',
            disabled: 'rgba(0, 0, 0, 0.00)',
        },
        text: {
            base: '#4A5568',
            hover: '#4A5568',
            active: '#171923',
            disabled: '#A0AEC0',
        },
        icon: {
            bg: {
                base: 'rgba(0, 0, 0, 0.00)',
                hover: '#2C7A7B',
                active: '#319795',
                disabled: 'linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))',
            },
            text: {
                base: '#171923',
                hover: '#E6FFFA',
                active: '#E6FFFA',
                disabled: '#A0AEC0',
            },
        },
    },
    search: {
        bg: '#FFF',
        borderColor: {
            base: '#E2E8F0',
            hover: '#4A5568',
            active: '#319795',
        },
        text: {
            primary: '#171923',
            disabled: '#A0AEC0',
        },
    },
    theme: {
        900: '#319795',
        500: '#319795',
    },
    headerBg: {
        primary: '#F7FAFC',
        500: '#F7FAFC',
    },
    badge: {
        text: '#C91D25',
        bg: '#FAE8E9',
    },
    brand: {
        900: '#1a365d',
        800: '#153e75',
        700: '#2a69ac',
        500: '#319795',
    },
    header: {
        100: '#20232a',
    },
    main: {
        100: '#282c34',
    },
};

export default colors;
