import * as React from 'react';
function SvgDragHandle(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M4.934 15.275c-.319 0-.588-.11-.808-.33-.22-.219-.33-.488-.33-.807 0-.32.11-.589.33-.809.22-.22.49-.329.808-.329h14.132c.32 0 .588.11.808.33.22.219.33.488.33.808 0 .319-.11.588-.33.808-.22.22-.489.33-.808.33H4.934zm0-4.275c-.319 0-.588-.11-.808-.33-.22-.219-.33-.488-.33-.807 0-.32.11-.589.33-.809.22-.22.49-.329.808-.329h14.132c.32 0 .588.11.808.33.22.219.33.488.33.808 0 .319-.11.588-.33.808-.22.22-.489.33-.808.33H4.934z'
                fill='#171923'
            />
        </svg>
    );
}
export default SvgDragHandle;
