import { BasicLookup } from '@Redux/services/LookupApi/types';
import { StudentRecord } from '@Redux/services/Reports/types';
import { createLookupMap } from '@Utilities';
import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import { report_analytics_types } from 'src/constants';

interface IProps extends CellProps<object> {
    grades?: BasicLookup[];
    isAnalytics?: boolean;
    filterStatus?: number;
}

const GradeCell: React.FC<IProps> = ({ value, row, grades, isAnalytics, filterStatus }) => {
    const rowData = row.original as StudentRecord;
    const gradesMap = useMemo(() => createLookupMap<BasicLookup>(grades), [grades]);
    const grade = isAnalytics && filterStatus === report_analytics_types.UnknownOENs ? rowData.gradeRC : value;
    return <>{gradesMap[grade] ? gradesMap[grade].value : '--'}</>;
};

export default GradeCell;
