const Table = {
    parts: ['thead', 'th', 'td'],
    baseStyle: {
        thead: {
            backgroundColor: 'surface-secondary',
        },
        th: {
            textTransform: 'none',
        },
        td: {
            fontFamily: 'Inter',
            fontSize: 'sm',
            fontStyle: ' normal',
            fontWeight: 400,
            lineHeight: '20px',
        },
    },
};

export default Table;
