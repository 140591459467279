import * as React from 'react';
function SvgCancel(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M10 11.117l2.337 2.337c.163.163.35.24.56.231a.818.818 0 00.558-.257.769.769 0 00.244-.558.766.766 0 00-.244-.558L11.118 9.99l2.337-2.328a.766.766 0 00.244-.558.769.769 0 00-.244-.558.769.769 0 00-.559-.244.769.769 0 00-.559.244l-2.336 2.337-2.338-2.337a.755.755 0 00-.545-.244.755.755 0 00-.546.244.769.769 0 00-.244.558c0 .21.08.396.242.559L8.883 10l-2.337 2.337a.71.71 0 00-.231.546.807.807 0 00.257.545.769.769 0 00.559.244c.21 0 .395-.08.557-.242L10 11.117zm.006 7.052a7.99 7.99 0 01-3.18-.637 8.225 8.225 0 01-2.604-1.754 8.227 8.227 0 01-1.753-2.603A7.998 7.998 0 011.83 9.99c0-1.13.213-2.19.638-3.174a8.227 8.227 0 014.357-4.348 7.997 7.997 0 013.184-.637 7.93 7.93 0 013.175.637 8.242 8.242 0 014.348 4.352c.424.989.637 2.047.637 3.174a7.99 7.99 0 01-.638 3.18 8.225 8.225 0 01-1.753 2.604 8.243 8.243 0 01-2.599 1.754 7.948 7.948 0 01-3.174.637zm-.005-1.729c1.792 0 3.313-.625 4.564-1.876 1.25-1.25 1.876-2.772 1.876-4.564 0-1.792-.626-3.314-1.876-4.564S11.793 3.56 10 3.56c-1.793 0-3.314.625-4.565 1.876C4.186 6.686 3.56 8.208 3.56 10c0 1.792.626 3.314 1.876 4.564s2.772 1.876 4.565 1.876z'
                fill='currentColor'
            />
        </svg>
    );
}
export default SvgCancel;
