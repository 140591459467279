import * as React from 'react';
function SvgArrowDown(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M12 16.08l3.805-3.786a1.1 1.1 0 01.786-.326c.313-.007.58.102.805.326.211.211.317.476.317.796 0 .319-.106.584-.317.795l-4.594 4.6a1.049 1.049 0 01-.373.246c-.137.051-.28.077-.429.077-.15 0-.292-.026-.429-.077a1.049 1.049 0 01-.373-.246l-4.594-4.6a1.101 1.101 0 01-.326-.786 1.066 1.066 0 01.326-.805 1.08 1.08 0 01.796-.317c.32 0 .585.106.796.317L12 16.079zm0-6.186l3.805-3.785a1.1 1.1 0 01.786-.327c.313-.006.58.105.805.333.211.21.317.476.317.795 0 .32-.106.585-.317.796L12.802 12.3a1.05 1.05 0 01-.373.245c-.137.052-.28.078-.429.078-.15 0-.292-.026-.429-.078a1.05 1.05 0 01-.373-.245L6.604 7.706a1.097 1.097 0 01-.326-.783c-.006-.311.103-.58.326-.808.212-.216.477-.323.796-.323.32 0 .587.107.802.323L12 9.894z'
                fill='#171923'
            />
        </svg>
    );
}
export default SvgArrowDown;
