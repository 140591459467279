import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LinkRequestInformation } from '@Redux/services/Update/types';
import { useForms } from '@Hooks';
import { PartialDeep } from 'type-fest';
import {
    useGetLinkRequestMutation,
    useUpdateLinkRequestMutation,
    useUpdateLinkRequestStatusMutation,
    useCreateLinkRequestNoteMutation,
    useGetLinkRequestNotesMutation,
    useDeleteLinkRequestNoteMutation,
} from '@Redux/services/Update';
import { useParams } from 'react-router-dom';
import { Box, Button, Flex, Stack, useDisclosure } from '@chakra-ui/react';
import { useToast } from '@Hooks/useToast';
import Sidebar from '@Components/Sidebar';
import { Trans, useTranslation } from 'react-i18next';
import { sideBarData } from './components/Sidebar';
import { LinkRequestSidebarItem, UpdateStatuses } from './types';
import Cycle from '@Icon/Cycle';
import { UpdateStatus } from 'src/constants';
import Steps from '@Components/Steps';
import { ArrowForwardIcon } from '@Icon';
import FormHeader from '@Components/FormHeader';
import InnerFormHeader from './components/InnerFormHeader';
import SvgAboutStudent from '@Icon/AboutStudent';
import {
    getErrorsAssociatedWithFields,
    getMutableForAccessor,
    parseError,
    parseWarning,
    ValidatorType,
} from '@Utilities';
import { DetailPageSkeleton } from '@Components/DetailPageSkeleton';
import { Validator } from './validator';
import { Note, PagedResult } from '@Redux/services/commonTypes';
import LinkingRequest from './components/LinkingRequest';
import StatusChangesDialog from './components/StatusChangeDialog';
import Notes from '@Components/Notes';
import { useMsal } from '@azure/msal-react';

const UpdateDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [t] = useTranslation();
    const sideBarItems = useMemo(() => sideBarData(LinkRequestSidebarItem.LinkingRequest, t), []);
    const [nextButtonLoading, setNextButtonLoading] = useState(false);
    const [rejected, setRejected] = useState(false);
    const [saveValidationError, setSaveValidationError] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = React.useState(false);
    const toast = useToast();

    const { accounts } = useMsal();
    const account = accounts[0];

    const linkRequestRequiredFields = (rules: ValidatorType[]) => {
        if (!linkRequestInformation) return;
        const newLinkRequestRequiredFields = {};
        rules.map((validaton) => {
            newLinkRequestRequiredFields[validaton.accessorPath.join('.')] = validaton;
        });
        return newLinkRequestRequiredFields;
    };

    const isPageReadOnly = () =>
        linkRequestInformation?.ucdsbStatus === UpdateStatus.Deleted ||
        linkRequestInformation?.ucdsbStatus === UpdateStatus.Approved ||
        linkRequestInformation?.ucdsbStatus === UpdateStatus.Rejected;

    const getCommonProps = () => {
        return {
            isLocked: isPageReadOnly(),
        };
    };

    const {
        formData: linkRequestInformation,
        errors,
        textFieldPropFactory,
        selectFieldPropFactory,
        setErrors,
        manageFieldPropFactory,
        setFormData,
        setIsDirty,
        isDirty,
        setDirtyData,
        dirtyAccessors,
        setDirtyAccessor,
    } = useForms<PartialDeep<LinkRequestInformation>>({}, getCommonProps);

    const componentProps = {
        manageFieldPropFactory,
        selectFieldPropFactory,
        textFieldPropFactory,
    };

    const [updateLinkRequestStatus] = useUpdateLinkRequestStatusMutation();
    const [getLinkRequest] = useGetLinkRequestMutation();
    const [updateLinkRequest] = useUpdateLinkRequestMutation();
    const [createLinkRequestNote] = useCreateLinkRequestNoteMutation();
    const [getLinkRequestNotes, { isLoading: isNotesLoading }] = useGetLinkRequestNotesMutation();
    const [deleteLinkRequestNote] = useDeleteLinkRequestNoteMutation();

    const steps = useMemo(() => {
        return [
            {
                active: UpdateStatus.Submitted === linkRequestInformation?.ucdsbStatus,
                header: t('LinkingRequestDetails.step1'),
                subHeader: t('LinkingRequestDetails.step1Subheader'),
            },
            {
                active: UpdateStatus.Approved === linkRequestInformation?.ucdsbStatus,
                header: t('LinkingRequestDetails.step2'),
                subHeader: t('LinkingRequestDetails.step2Subheader'),
            },
        ];
    }, [linkRequestInformation]);

    const getNextStatus = (status: number) => {
        switch (status) {
            case UpdateStatus.Submitted:
                return UpdateStatus.Approved;
            default:
                return UpdateStatus.Approved;
        }
    };

    const validate = (validationRules: ValidatorType[]) => {
        const newErrors = { ...errors };
        const nextErrors: Record<string, string> = {};

        for (const rule of validationRules) {
            const { accessorPath, validator, message, required } = rule;

            const finalMutable = getMutableForAccessor(linkRequestInformation, accessorPath);
            const finalProperty = accessorPath[accessorPath.length - 1];

            if (
                required &&
                (rule.editableStatus == null ||
                    rule.editableStatus?.indexOf(linkRequestInformation.ucdsbStatus) > -1) &&
                !validator(finalMutable[finalProperty])
            ) {
                nextErrors[accessorPath.join('.')] = message;
            } else {
                newErrors[accessorPath.join('.')] && delete newErrors[accessorPath.join('.')];
            }
        }
        setErrors({ ...newErrors, ...nextErrors });
        if (Object.keys(nextErrors).length) {
            toast({
                status: 'error',
                position: 'top',
                description: t('RegistrationDetails.toastErrorDescription'),
            });
            return false;
        }
        setSaveValidationError(false);
        return true;
    };

    useEffect(() => {
        setIsLoading(true);
        getLinkRequest({ linkRequestId: id })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                let data = response.data as LinkRequestInformation;
                const notesObject = { ...data.notes };
                const notes = notesObject.studentLinkingNotesInfo.map((note) => {
                    return {
                        ...note,
                        canDelete: account.username === note.modifiedByEmailAddress,
                        canEdit: false,
                    };
                });
                notesObject.studentLinkingNotesInfo = notes;
                data = {
                    ...data,
                    notes: notesObject,
                };
                setFormData(data);
            })
            .catch((error) => {
                parseError(toast, error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const handleNext = useCallback(
        (statusComment: string) => {
            handleSave().then(() => {
                setNextButtonLoading(true);

                const status = getNextStatus(linkRequestInformation.ucdsbStatus);
                updateLinkRequestStatus({
                    linkingId: linkRequestInformation.id,
                    status: status,
                    statusComments: statusComment,
                })
                    .unwrap()
                    .then(() => {
                        getLinkRequestNotes({ objectId: id, currentPage: 1, pageSize: 10 })
                            .unwrap()
                            .then((response) => {
                                const notesObject = { ...response };
                                const notes = notesObject.data.map((note) => {
                                    return {
                                        ...note,
                                        canDelete: account.username === note.modifiedByEmailAddress,
                                        canEdit: false,
                                    };
                                });
                                notesObject.data = notes;

                                setFormData({
                                    ...linkRequestInformation,
                                    ucdsbStatus: status,
                                    notes: {
                                        studentLinkingNotesInfo: notesObject.data,
                                        pagedResult: notesObject.pagedResult,
                                    },
                                });
                            })
                            .catch((error) => {
                                parseError(toast, error);
                            });

                        toast({
                            status: 'success',
                            description: t('LinkingRequestDetails.toastApprovedSuccess'),
                        });
                    })
                    .catch((error) => {
                        setErrors(
                            getErrorsAssociatedWithFields(error.data.errors, Object.keys(linkRequestRequiredFields))
                        );
                        parseError(toast, error);
                    })
                    .finally(() => {
                        setNextButtonLoading(false);
                    });
            });
        },
        [linkRequestInformation, setErrors, nextButtonLoading]
    );

    const handleSaveDlgOpen = async () => {
        setRejected(false);
        const rules = Validator(linkRequestInformation, t);
        if (!validate(rules) || saveValidationError || !linkRequestInformation || isPageReadOnly()) return;
        onOpen();
    };

    const handleSave = async () => {
        try {
            const rules = Validator(linkRequestInformation, t);
            if (!validate(rules) || saveValidationError || !linkRequestInformation || isPageReadOnly()) return;

            const response = await updateLinkRequest(linkRequestInformation).unwrap();

            parseWarning(toast, response);
            setDirtyData({});
            setIsDirty(false);
            setDirtyAccessor([]);

            const message = t('LinkingRequestDetails.savedSuccessfully');

            toast({
                status: 'success',
                description: message,
            });
        } catch (error) {
            const nextErrors: Record<string, string> = {};
            for (const dirtyAccessor of dirtyAccessors) {
                nextErrors[dirtyAccessor.join('.')] = t('RegistrationDetails.fieldNotSaved');
            }
            setErrors(getErrorsAssociatedWithFields(error.data.errors, Object.keys(linkRequestRequiredFields)));
            parseError(toast, error);
        }
    };

    const handleRejectDlgOpen = async () => {
        setRejected(true);
        onOpen();
    };

    const handleReject = useCallback(
        async (statusComment: string) => {
            const status = UpdateStatus.Rejected;

            await updateLinkRequestStatus({
                linkingId: linkRequestInformation.id,
                status: status,
                statusComments: statusComment,
            })
                .unwrap()
                .then(async () => {
                    getLinkRequestNotes({ objectId: id, currentPage: 1, pageSize: 10 })
                        .unwrap()
                        .then((response) => {
                            const notesObject = { ...response };
                            const notes = notesObject.data.map((note) => {
                                return {
                                    ...note,
                                    canDelete: account.username === note.modifiedByEmailAddress,
                                    canEdit: false,
                                };
                            });
                            notesObject.data = notes;

                            setFormData({
                                ...linkRequestInformation,
                                ucdsbStatus: status,
                                notes: {
                                    studentLinkingNotesInfo: notesObject.data,
                                    pagedResult: notesObject.pagedResult,
                                },
                            });
                        })
                        .catch((error) => {
                            parseError(toast, error);
                        });

                    toast({
                        status: 'success',
                        description: t('LinkingRequestDetails.toastRejected'),
                    });
                })
                .catch((error) => {
                    setErrors(getErrorsAssociatedWithFields(error.data.errors, Object.keys(linkRequestRequiredFields)));
                    parseError(toast, error);
                });
        },
        [linkRequestInformation, setErrors]
    );

    const handleNotePageChange = async (pageNumber: number, pageSize: number) => {
        await getLinkRequestNotes({
            objectId: linkRequestInformation?.id,
            pageSize,
            currentPage: pageNumber,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                const notesObject = { ...response };
                const notes = notesObject.data.map((note) => {
                    return {
                        ...note,
                        canDelete: account.username === note.modifiedByEmailAddress,
                        canEdit: false,
                    };
                });
                notesObject.data = notes;

                setFormData({
                    ...linkRequestInformation,
                    notes: { studentLinkingNotesInfo: notesObject.data, pagedResult: notesObject.pagedResult },
                });
            })
            .catch((e) => parseError(toast, e));
    };

    const handleAddNote = async (noteText: string) => {
        await createLinkRequestNote({
            objectId: linkRequestInformation?.id,
            noteText,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                const notesObject = { ...response };
                const notes = notesObject.data.map((note) => {
                    return {
                        ...note,
                        canDelete: account.username === note.modifiedByEmailAddress,
                        canEdit: false,
                    };
                });
                notesObject.data = notes;

                setFormData({
                    ...linkRequestInformation,
                    notes: { studentLinkingNotesInfo: notesObject.data, pagedResult: notesObject.pagedResult },
                });
            })
            .catch((e) => parseError(toast, e));
    };

    const handleDeleteNote = async (annotationId: string) => {
        await deleteLinkRequestNote({ annotationId })
            .unwrap()
            .then((response) => {
                const notesObject = { ...response };
                const notes = notesObject.data.map((note) => {
                    return {
                        ...note,
                        canDelete: account.username === note.modifiedByEmailAddress,
                        canEdit: false,
                    };
                });
                notesObject.data = notes;

                setFormData({
                    ...linkRequestInformation,
                    notes: { studentLinkingNotesInfo: notesObject.data, pagedResult: notesObject.pagedResult },
                });
                if (response?.warningResult?.showAlert) {
                    parseWarning(toast, response);
                } else {
                    toast({
                        status: 'success',
                        description: t('LinkingRequestDetails.toastSuccessNoteDelete'),
                    });
                }
            })
            .catch((e) => parseError(toast, e));
    };

    if (isLoading) {
        return <DetailPageSkeleton />;
    }

    return (
        <>
            <Flex>
                <Sidebar
                    {...sideBarItems}
                    footerDetails={{
                        createdOn: linkRequestInformation?.createdOn,
                        createdByFullName: linkRequestInformation?.createdByFullName,
                        modifiedByFullName: linkRequestInformation?.modifiedByFullName,
                        modifiedOn: linkRequestInformation?.modifiedOn,
                        onSave: handleSave,
                        disabled: !isDirty,
                    }}
                />
                <form style={{ width: '100%' }}>
                    <Box pt={8} maxWidth='100%'>
                        <Box pr={4}>
                            <Flex justifyContent='space-between' direction={{ base: 'column', md: 'row' }}>
                                <Steps steps={steps} />
                                <div>
                                    <Button
                                        isDisabled={isPageReadOnly()}
                                        onClick={handleRejectDlgOpen}
                                        textStyle={'md-semibold'}
                                        variant={
                                            linkRequestInformation.ucdsbStatus === UpdateStatus.Rejected
                                                ? 'primary'
                                                : null
                                        }
                                        mr={3}
                                        disabled={isPageReadOnly()}
                                        title={t('LinkingRequestDetails.reject')}
                                    >
                                        {t('LinkingRequestDetails.reject')}
                                    </Button>

                                    <Button
                                        isDisabled={isPageReadOnly()}
                                        onClick={handleSaveDlgOpen}
                                        textStyle={'md-semibold'}
                                        variant={
                                            linkRequestInformation.ucdsbStatus === UpdateStatus.Rejected
                                                ? null
                                                : nextButtonLoading
                                                ? 'brand-inverted'
                                                : 'primary'
                                        }
                                        rightIcon={
                                            !isPageReadOnly() ? (
                                                nextButtonLoading ? (
                                                    <Cycle className='spin-loading' width='20px' height='20px' />
                                                ) : (
                                                    <ArrowForwardIcon width='20px' height='20px' />
                                                )
                                            ) : null
                                        }
                                        mr={2}
                                    >
                                        {!isPageReadOnly() ? (
                                            <Trans
                                                i18nKey={
                                                    nextButtonLoading
                                                        ? 'LinkingRequestDetails.nextStepLoading'
                                                        : 'LinkingRequestDetails.nextStep'
                                                }
                                                values={{
                                                    seperator: ':',
                                                    sepLabel: t('LinkingRequestDetails.nextStep'),
                                                    stepLabel:
                                                        UpdateStatuses[
                                                            getNextStatus(linkRequestInformation.ucdsbStatus)
                                                        ],
                                                }}
                                            />
                                        ) : (
                                            UpdateStatuses[getNextStatus(linkRequestInformation.ucdsbStatus)]
                                        )}
                                    </Button>
                                </div>
                            </Flex>
                        </Box>
                        <Stack bg='surface-secondary' p={3} mt={5} spacing={8} borderLeftRadius={15}>
                            <FormHeader
                                header={
                                    <InnerFormHeader
                                        divider={true}
                                        title={t('LinkingRequestDetails.linkingrequest')}
                                        icon={<SvgAboutStudent />}
                                    />
                                }
                                label={t('LinkingRequestDetails.linkingrequest')}
                            >
                                <LinkingRequest {...componentProps} />
                            </FormHeader>
                            <Notes
                                id='notes'
                                {...componentProps}
                                notes={linkRequestInformation?.notes?.studentLinkingNotesInfo as Note[]}
                                onAddNote={async (noteText: string) => {
                                    handleAddNote(noteText);
                                }}
                                onDeleteNote={(annotationId: string) => handleDeleteNote(annotationId)}
                                notePagination={linkRequestInformation?.notes?.pagedResult as PagedResult}
                                isNotesLoading={isNotesLoading}
                                onNextPage={handleNotePageChange}
                                isNoteEditable={!isPageReadOnly()}
                                hideActivities={true}
                                defaultPageSize={linkRequestInformation?.notes?.pagedResult.pageSize}
                            />
                        </Stack>
                    </Box>
                </form>
            </Flex>
            {isOpen && (
                <StatusChangesDialog
                    isOpen={isOpen}
                    onSave={rejected === false ? handleNext : handleReject}
                    onClose={onClose}
                    status={
                        rejected === false
                            ? UpdateStatuses[getNextStatus(linkRequestInformation?.ucdsbStatus)]
                            : UpdateStatuses[UpdateStatus.Rejected]
                    }
                />
            )}
        </>
    );
};

export default UpdateDetails;
