import * as React from 'react';
function SvgAddress(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g clipPath='url(#address_svg__clip0_565_77942)'>
                <mask
                    id='address_svg__a'
                    style={{
                        maskType: 'alpha',
                    }}
                    maskUnits='userSpaceOnUse'
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                >
                    <path fill='#D9D9D9' d='M0 0h20v20H0z' />
                </mask>
                <g mask='url(#address_svg__a)'>
                    <path
                        d='M3.543 16.464c-.525 0-.972-.185-1.342-.554a1.828 1.828 0 01-.554-1.342v-9.88c0-.525.185-.972.554-1.342.37-.37.817-.554 1.342-.554h13.213c.525 0 .972.185 1.342.554.37.37.554.817.554 1.342v9.88c0 .525-.185.972-.554 1.342-.37.37-.817.554-1.342.554H3.543zM16.756 6.51l-6.104 3.851a.97.97 0 01-.503.145.97.97 0 01-.502-.145L3.543 6.509v8.06h13.213v-8.06zM10.15 8.855l6.607-4.167H3.543l6.606 4.167zM3.543 6.737V5.405v.023-.74.741-.018 1.326z'
                        fill='#171923'
                    />
                </g>
            </g>
            <defs>
                <clipPath id='address_svg__clip0_565_77942'>
                    <path fill='#fff' d='M0 0h20v20H0z' />
                </clipPath>
            </defs>
        </svg>
    );
}
export default SvgAddress;
