import {
    BackgroundProps,
    Box,
    Flex,
    FormControl,
    FormHelperText,
    FormLabel,
    IconButton,
    Input as ChakraInput,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    List,
    ListItem,
    Text,
    useDisclosure,
    useOutsideClick,
    InputProps,
} from '@chakra-ui/react';
import TrashIconButton from '@Components/TrashIconButton';
import { useToast } from '@Hooks/useToast';
import { Lock, Plus, NewTab } from '@Icon';
import ArrowForwardIcon from '@Icon/ArrowForwardIcon';
import { BaseResponse } from '@Redux/services/commonTypes';
import { BasicLookup, SearchTextParams } from '@Redux/services/LookupApi/types';
import {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
    MutationDefinition,
} from '@reduxjs/toolkit/dist/query';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { parseError, parseWarning } from '@Utilities';
import { debounce, lowerCase } from 'lodash';
import { FC, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export interface TypeaheadInputProps extends InputProps {
    isLocked: boolean;
    label: string;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    error?: string;
    onSelection?: (id: string | number) => void;
    options?: BasicLookup[];
    bg?: BackgroundProps['bg'];
    stacked?: boolean;
    baseUrl?: string;
    onAddNewRecord?: () => void;
    onLinkClick?: () => void;
    col?: number;
    scrolltoView?: boolean;
    openInNewTab?: boolean;
    online?: boolean;
    getData?: MutationTrigger<
        MutationDefinition<
            SearchTextParams,
            BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, any, FetchBaseQueryMeta>,
            never,
            BaseResponse<BasicLookup[]>,
            'baseApi'
        >
    >;
    getDataExtraParams?: any;
    initialValue?: string;
    initialId?: string;
}
const TypeaheadInput: FC<TypeaheadInputProps> = ({
    label,
    leftIcon,
    rightIcon,
    isInvalid,
    options,
    isLocked,
    error,
    onSelection,
    baseUrl,
    bg = 'transparent',
    stacked = false,
    value,
    onAddNewRecord,
    col,
    onLinkClick,
    scrolltoView = false,
    openInNewTab = false,
    online = false,
    getData,
    getDataExtraParams,
    initialValue,
    initialId,
    ...rest
}) => {
    const ref = useRef();
    const listRef = useRef<HTMLDivElement>();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [isSelected, setIsSelected] = useState(false);
    const [tempName, setTempName] = useState<string>(initialValue);
    const [name, setName] = useState<string>(initialValue);
    const { t } = useTranslation();
    const toast = useToast();

    useEffect(() => {
        if (!initialId) {
            return;
        }
        onSelection(initialId);
        setIsSelected(true);
    }, []);

    const [data, setData] = useState<BasicLookup[]>([]);

    const executeGetData = (input: string) => {
        getData({ searchText: input, ...getDataExtraParams })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                setData(response.data);
            })
            .catch((err) => parseError(toast, err));
    };

    const getSystemUserDebounced = useMemo(() => debounce(executeGetData, 1000), []);

    const handleFromSearch = (input: string) => {
        getSystemUserDebounced(input);
    };

    const getName = () => {
        if (!data && !options) return;
        const values = online ? data : options ?? [];
        let found = false;
        for (const option of values) {
            if (option.key.toString() === value) {
                setName(option.value);
                if (online) {
                    setTempName(option.value);
                    setData([]);
                }
                found = true;
                break;
            }
        }
        !found && !online && setName('');
        !found && online && setName(tempName);
    };

    useEffect(() => {
        getName();
        if (isOpen && scrolltoView) {
            listRef?.current?.scrollIntoView();
        }
    }, [isSelected, isOpen, options]);

    const handleOutsideClick = useCallback(() => {
        setIsSelected(false);
        onClose();
    }, []);

    useOutsideClick({
        ref: ref,
        handler: handleOutsideClick,
    });

    const handleSelection = (option: BasicLookup) => {
        onSelection(option.key);
        setIsSelected(true);
        onClose();
    };

    const navigate = useNavigate();

    const handleChange = (name: string) => {
        setName(name);
        if (online) {
            handleFromSearch(name);
        }
    };

    useEffect(() => {
        getName();
    }, [value]);

    return (
        <>
            <FormControl ref={ref} isInvalid={isInvalid} position='relative'>
                <Box
                    w='100%'
                    display={{ base: 'block', lg: 'flex' }}
                    alignItems={stacked ? 'start' : 'baseline'}
                    flexDirection={stacked ? 'column' : 'row'}
                >
                    {label && (
                        <FormLabel
                            color={(rest.isDisabled || isLocked) && 'text-disabled'}
                            flexBasis={col === 1 ? '12.5%' : '30%'}
                            textStyle={'sm-medium'}
                        >
                            {label}
                            {rest.isRequired && '*'}
                        </FormLabel>
                    )}
                    <Flex alignItems={'end'} w='100%'>
                        <Box w='100%'>
                            <InputGroup bg={bg}>
                                {leftIcon && <InputLeftElement>{leftIcon}</InputLeftElement>}
                                <Box as='span' w='100%'>
                                    <ChakraInput
                                        bgColor='surface-primary'
                                        textStyle={'md-normal'}
                                        fontFamily='Inter'
                                        onChange={(event) => handleChange(event.target.value)}
                                        disabled={isLocked}
                                        onFocus={onOpen}
                                        _disabled={{
                                            color: 'text-disabled',
                                        }}
                                        title={(rest.isRequired && t('InputFields.requiredTitle')) || ''}
                                        value={name}
                                        _focusVisible={{ borderColor: 'surface-brand-primary' }}
                                        autoComplete='123'
                                    />
                                    {error && (
                                        <FormHelperText p={2} textStyle='sm-normal' color={isInvalid && 'text-error'}>
                                            {error}
                                        </FormHelperText>
                                    )}
                                </Box>
                                {isOpen && (options || data) && (
                                    <Box
                                        ref={listRef}
                                        position='absolute'
                                        top='10'
                                        bg='surface-primary'
                                        zIndex={100}
                                        width='100%'
                                    >
                                        <List
                                            maxH='220px'
                                            borderTopRadius='2px'
                                            borderBottomRadius={'4px'}
                                            border='2px solid'
                                            borderColor='border-brand'
                                            p='0'
                                            overflowY='auto'
                                        >
                                            {online
                                                ? data?.map((option, i) => (
                                                      <ListItem
                                                          key={i}
                                                          _hover={{ bg: 'surface-tertiary' }}
                                                          my={1}
                                                          p={2}
                                                          cursor='pointer'
                                                          borderTop='1px solid'
                                                          borderColor='border-primary'
                                                          onClick={() => handleSelection(option)}
                                                      >
                                                          <Text textStyle={'sm-medium'}>{option.value}</Text>
                                                      </ListItem>
                                                  ))
                                                : options
                                                      ?.filter(
                                                          (option) =>
                                                              lowerCase(option.value).indexOf(lowerCase(name)) > -1
                                                      )
                                                      ?.map((option, i) => (
                                                          <ListItem
                                                              key={i}
                                                              _hover={{ bg: 'surface-tertiary' }}
                                                              my={1}
                                                              p={2}
                                                              cursor='pointer'
                                                              borderTop='1px solid'
                                                              borderColor='border-primary'
                                                              onClick={() => handleSelection(option)}
                                                          >
                                                              <Text textStyle={'sm-medium'}>{option.value}</Text>
                                                          </ListItem>
                                                      ))}
                                            {onAddNewRecord && (
                                                <ListItem
                                                    _hover={{ bg: 'surface-tertiary' }}
                                                    my={1}
                                                    p={2}
                                                    justifyContent={'center'}
                                                    cursor='pointer'
                                                    borderTop='1px solid'
                                                    borderColor='border-primary'
                                                    onClick={() => onAddNewRecord()}
                                                    position='sticky'
                                                    bottom={0}
                                                    background='white'
                                                >
                                                    <Flex justifyContent={'center'} align={'center'} gap={2}>
                                                        <Plus width={20} height={20} />{' '}
                                                        <span>{t('Typeahead.add')}</span>
                                                    </Flex>
                                                </ListItem>
                                            )}
                                        </List>
                                    </Box>
                                )}

                                {!(rightIcon || isLocked) && Boolean(value) && (
                                    <InputRightElement>
                                        <TrashIconButton
                                            onClick={() => {
                                                if (online) {
                                                    setTempName('');
                                                    setName('');
                                                }
                                                onSelection(null);
                                                if (isOpen && scrolltoView) {
                                                    setTimeout(() => listRef?.current?.scrollIntoView(), 300);
                                                }
                                            }}
                                        />
                                    </InputRightElement>
                                )}
                                {(rightIcon || isLocked) && (
                                    <InputRightElement>{isLocked ? <Lock /> : rightIcon}</InputRightElement>
                                )}
                            </InputGroup>
                        </Box>
                        {(baseUrl || onLinkClick) &&
                            (options?.find((option) => option.value == name) || (online && value)) && (
                                <IconButton
                                    aria-label='arrow forward'
                                    ml={'1'}
                                    variant={'outline'}
                                    borderRadius={'var(--chakra-radii-sm)'}
                                    border={'1px solid'}
                                    borderColor='border-primary'
                                    icon={
                                        openInNewTab ? (
                                            <NewTab width={18} height={18} />
                                        ) : (
                                            <ArrowForwardIcon width={24} height={24} />
                                        )
                                    }
                                    onClick={
                                        onLinkClick
                                            ? onLinkClick
                                            : () =>
                                                  baseUrl &&
                                                  (openInNewTab
                                                      ? window.open(`${baseUrl}/${value}`, '_blank')
                                                      : navigate(`${baseUrl}/${value}`))
                                    }
                                />
                            )}
                    </Flex>
                </Box>
            </FormControl>
        </>
    );
};

export default TypeaheadInput;
