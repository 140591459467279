import { Flex, Stack, useDisclosure } from '@chakra-ui/react';
import AvatarLabel from '@Components/AvatarLabel';
import Sidebar from '@Components/Sidebar';

import { useMemo, useRef } from 'react';
import { ReactIcon } from '@Icon';
import ParticipantActivities from './components/ParticipantActivities';

import { useTranslation } from 'react-i18next';
import Information from './components/Information';
import { Members } from '@Components/EventParticipantsDrawer/components/Members';
import { useParams } from 'react-router-dom';
import { DetailPageSkeleton } from '@Components/DetailPageSkeleton';
import { useCustomList } from '@Hooks/useCustomList';
import ActivityLog from '@Components/EventParticipantsDrawer/components/ActivityLog';

const EventParticipantsPage = () => {
    const containerRef = useRef<HTMLDivElement>(null);
    const sectionRefs = useRef(new Array(5));
    const { isOpen: isAddMemberOpen, onClose: onCloseAddMemberModal, onOpen: onOpenAddMemberModal } = useDisclosure();
    const { t } = useTranslation();

    const { listId } = useParams();
    const {
        participantDetails,
        errors,
        manageFieldPropFactory,
        selectFieldPropFactory,
        textFieldPropFactory,
        typeaheadPropFactory,
        textAreaPropFactory,
        setFormData: setParticipantDetails,
        handleManualSave,
        hasPendingChange,
        isLoading,
        getCustomList,
        pagedResult,
        setPagedResult,
    } = useCustomList(listId);

    const handleMenuItemClick = (href: string) => {
        for (const current of sectionRefs.current) {
            if (current && current.id === href) {
                current.scrollIntoView(true);
                window.scroll({ top: window.scrollY - 145, behavior: 'smooth' });
            }
        }
    };

    const sideBarItems = useMemo(
        () => ({
            header: (
                <AvatarLabel
                    name={participantDetails?.listName}
                    subTitle={t('EventParticipantsPage.sideBarSubTitle')}
                />
            ),
            groups: [
                {
                    items: [
                        {
                            label: t('EventParticipant.summary'),
                            id: '#information',
                            icon: <ReactIcon />,
                            onClick: handleMenuItemClick,
                            active: false,
                        },
                        {
                            label: t('EventParticipant.activityLog'),
                            id: '#activity-log',
                            icon: <ReactIcon />,
                            onClick: handleMenuItemClick,
                            active: false,
                        }, // for future sprint
                    ],
                },
            ],
        }),
        [participantDetails]
    );

    if (!participantDetails) {
        return <DetailPageSkeleton />;
    }
    return (
        participantDetails && (
            <Flex>
                <Sidebar
                    {...sideBarItems}
                    footerDetails={{
                        disabled: !hasPendingChange,
                        onSave: handleManualSave,
                        createdOn: participantDetails?.createdOn,
                        createdByFullName: participantDetails?.createdByFullName,
                        modifiedOn: participantDetails?.modifiedOn,
                        modifiedByFullName: participantDetails?.modifiedByFullName,
                    }}
                />

                <Flex ref={containerRef} overflow={'hidden'} width='100%'>
                    <form style={{ width: '100%' }}>
                        <Flex
                            align={'flex-start'}
                            direction={'column'}
                            ref={containerRef}
                            overflow={'hidden'}
                            width='100%'
                            bg='surface-primary'
                            p={3}
                            gap={8}
                            pl={20}
                        >
                            <ParticipantActivities
                                errors={errors}
                                manageFieldPropFactory={manageFieldPropFactory}
                                setParticipantDetails={setParticipantDetails}
                                participantDetails={participantDetails}
                                textFieldPropFactory={textFieldPropFactory}
                                selectFieldPropFactory={selectFieldPropFactory}
                                typeaheadPropFactory={typeaheadPropFactory}
                            />

                            <Stack
                                borderRadius='20px 0 0 0'
                                borderWidth='2px 0px 2px 2px'
                                borderStyle='solid'
                                borderColor='border-primary'
                                w={'full'}
                                p={8}
                                bg={'surface-secondary'}
                                gap={8}
                            >
                                <Information
                                    id='information'
                                    ref={(el) => (sectionRefs.current[0] = el)}
                                    errors={errors}
                                    manageFieldPropFactory={manageFieldPropFactory}
                                    setParticipantDetails={setParticipantDetails}
                                    participantDetails={participantDetails}
                                    textFieldPropFactory={textFieldPropFactory}
                                    typeaheadPropFactory={typeaheadPropFactory}
                                    textAreaPropFactory={textAreaPropFactory}
                                />

                                <Members
                                    isOpen={isAddMemberOpen}
                                    isEditable={true}
                                    onAddMemberClose={onCloseAddMemberModal}
                                    onAddMemberOpen={onOpenAddMemberModal}
                                    onCloseAll={onCloseAddMemberModal}
                                    getMembers={getCustomList}
                                    onChange={(response) => {
                                        setParticipantDetails(response.data);
                                        setPagedResult(response.pagedResult);
                                    }}
                                    participantsData={participantDetails}
                                    pagedResult={pagedResult}
                                    isLoading={isLoading}
                                    listId={listId}
                                />

                                <ActivityLog
                                    id='activity-log'
                                    ref={(el) => (sectionRefs.current[1] = el)}
                                    errors={errors}
                                    manageFieldPropFactory={manageFieldPropFactory}
                                    setParticipantDetails={setParticipantDetails}
                                    participantDetails={participantDetails}
                                    textFieldPropFactory={textFieldPropFactory}
                                    typeaheadPropFactory={typeaheadPropFactory}
                                    textAreaPropFactory={textAreaPropFactory}
                                />
                            </Stack>
                        </Flex>
                    </form>
                </Flex>
            </Flex>
        )
    );
};

export default EventParticipantsPage;
