const Form = {
    parts: ['container', 'requiredIndicator', 'helperText'],
    baseStyle: {
        container: {
            label: {
                fontSize: 'sm',
            },
        },
        helperText: {
            marginTop: 0,
        },
    },
};

export default Form;
