import { TFunction } from 'react-i18next';
import { isNumber } from 'lodash';
import { costItemEditableFieldsByStatus } from './types';

export const Validator = (t: TFunction) => {
    return [
        {
            accessorPath: ['title'],
            validator: Boolean,
            message: t('CostItemForm.titleMessage'),
            ...costItemEditableFieldsByStatus.title,
        },
        {
            accessorPath: ['required'],
            validator: Boolean,
            message: t('CostItemForm.requiredMessage'),
            ...costItemEditableFieldsByStatus.required,
        },
        {
            accessorPath: ['unitCost'],
            validator: (uni) => {
                if (isNaN(Number(uni)) || !isNumber(Number(uni))) {
                    return false;
                }
                return true;
            },
            message: t('CostItemForm.unitCostMessage'),
            ...costItemEditableFieldsByStatus.unitCost,
        },
        {
            accessorPath: ['maxQuantityAllowed'],
            validator: (max) => {
                if (!max) return false;
                if (isNaN(Number(max)) || Number(max) <= 0) {
                    return false;
                }
                return true;
            },
            message: t('CostItemForm.maxQuantityAllowedMessage'),
            ...costItemEditableFieldsByStatus.maxQuantityAllowed,
        },
    ];
};
