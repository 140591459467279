import { useGetPhoneCallStatsQuery } from '@Redux/services/Absence';
import { PersonCelebrate, ComedyMask, ElectricBolt, ModeHeat } from '@Icon';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ComponentWithAs, Flex } from '@chakra-ui/react';
import { StatsCard } from '@Components/StatsCard';
import { IconProps } from '@chakra-ui/icons';
import { FIVE_MINUTES_MS } from 'src/constants';

export const Stats: FC = () => {
    const { t } = useTranslation();
    const [lastRefreshed, setLastRefreshed] = useState<Date>(new Date());

    const {
        refetch: getGetPhoneCallStats,
        data: { data: phoneCallStatsData } = {},
        isLoading: isPhoneCallStatsLoading,
    } = useGetPhoneCallStatsQuery(null);

    const handleRefresh = () => {
        getGetPhoneCallStats();
        setLastRefreshed(new Date());
    };

    useEffect(() => {
        const checkNewStatsInterval = setInterval(handleRefresh, FIVE_MINUTES_MS, [lastRefreshed]);

        return () => clearInterval(checkNewStatsInterval);
    }, [lastRefreshed]);

    useEffect(() => {
        handleRefresh();
    }, []);

    const getPillColor = (progress?: number, total?: number) => {
        const percentage = progress / total;
        if (percentage < 0.33) {
            return 'text-error';
        } else if (percentage < 0.66) {
            return 'text-warning';
        } else if (percentage < 1) {
            return 'text-brand-primary';
        } else {
            return 'text-success';
        }
    };
    const getPillBgColor = (progress?: number, total?: number) => {
        const percentage = progress / total;
        if (percentage < 0.33) {
            return 'surface-error-invert';
        } else if (percentage < 0.66) {
            return 'surface-warning-invert';
        } else if (percentage < 1) {
            return 'surface-brand-invert';
        } else {
            return 'surface-success-invert';
        }
    };
    const getPbVariant = (progress?: number, total?: number) => {
        const percentage = progress / total;
        if (percentage < 0.33) {
            return 'error';
        } else if (percentage < 0.66) {
            return 'warning';
        } else if (percentage < 1) {
            return 'brand-primary';
        } else {
            return 'success';
        }
    };
    const getPillLabel = (progress?: number, total?: number) => {
        const percentage = progress / total;
        if (percentage < 0.33) {
            return t('PhoneCall.statsCard.pill.lowProgress');
        } else if (percentage < 0.66) {
            return t('PhoneCall.statsCard.pill.inProgress');
        } else if (percentage < 1) {
            return t('PhoneCall.statsCard.pill.finishing');
        } else {
            return t('PhoneCall.statsCard.pill.complete');
        }
    };
    const getPillIcon = (progress?: number, total?: number) => {
        const percentage = progress / total;
        if (percentage < 0.33) {
            return ModeHeat as ComponentWithAs<'svg', IconProps>;
        } else if (percentage < 0.66) {
            return ElectricBolt as ComponentWithAs<'svg', IconProps>;
        } else if (percentage < 1) {
            return ComedyMask as ComponentWithAs<'svg', IconProps>;
        } else {
            return PersonCelebrate as ComponentWithAs<'svg', IconProps>;
        }
    };

    return (
        <Flex ml={8} mr={2}>
            <StatsCard
                statLabel={t('PhoneCall.statsCard.callsProgress')}
                header={t('PhoneCall.statsCard.header')}
                totalCountLabel={t('PhoneCall.statsCard.labelTotal')}
                isLoading={isPhoneCallStatsLoading}
                isPlainStat={false}
                progress={phoneCallStatsData?.callProgress?.completedCount}
                total={phoneCallStatsData?.callProgress?.totalCount}
                hasPill={true}
                pillColor={getPillColor(
                    phoneCallStatsData?.callProgress?.completedCount,
                    phoneCallStatsData?.callProgress?.totalCount
                )}
                pillBgColor={getPillBgColor(
                    phoneCallStatsData?.callProgress?.completedCount,
                    phoneCallStatsData?.callProgress?.totalCount
                )}
                pillIcon={getPillIcon(
                    phoneCallStatsData?.callProgress?.completedCount,
                    phoneCallStatsData?.callProgress?.totalCount
                )}
                pillLabel={getPillLabel(
                    phoneCallStatsData?.callProgress?.completedCount,
                    phoneCallStatsData?.callProgress?.totalCount
                )}
                hasProgressBar={true}
                pbVariant={getPbVariant(
                    phoneCallStatsData?.callProgress?.completedCount,
                    phoneCallStatsData?.callProgress?.totalCount
                )}
                borderRadius='8px'
                mr={2}
                width='50%'
            />
        </Flex>
    );
};
