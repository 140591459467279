import * as React from 'react';
function SvgClose(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <mask
                id='close_svg__a'
                style={{
                    maskType: 'alpha',
                }}
                maskUnits='userSpaceOnUse'
                x={0}
                y={0}
                width={20}
                height={20}
            >
                <path fill='#D9D9D9' d='M0 0h20v20H0z' />
            </mask>
            <g mask='url(#close_svg__a)'>
                <path
                    d='M10 11.222l-3.337 3.337a.79.79 0 01-.6.254.867.867 0 01-.601-.275.839.839 0 010-1.222L8.778 10 5.441 6.663a.802.802 0 01-.254-.61.878.878 0 01.275-.612.839.839 0 011.222 0L10 8.778l3.337-3.337a.839.839 0 011.222 0 .839.839 0 010 1.222L11.222 10l3.337 3.337a.828.828 0 01.264.6.828.828 0 01-.264.601.839.839 0 01-1.222 0L10 11.222z'
                    fill='#2D3748'
                />
            </g>
        </svg>
    );
}
export default SvgClose;
