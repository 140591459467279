import { AnyAction, combineReducers } from 'redux';

import auth from './slices/auth';
import killSwitch from './slices/killSwitch';
import counter from './slices/counter';

import { store } from '.';
import storage from 'redux-persist/lib/storage';
import { baseApi } from './services/base';
import ucdsbApi from './services/ucdsbApi';

const appReducer = combineReducers({
    [baseApi.reducerPath]: baseApi.reducer,
    [ucdsbApi.reducerPath]: ucdsbApi.reducer,
    auth,
    killSwitch,
    counter,
});

type AppRootState = ReturnType<typeof appReducer>;

const rootReducer = (state: AppRootState | undefined, action: AnyAction) => {
    if (action.type === 'auth/logout') {
        if (state?.auth?.token) {
            storage.removeItem('persist:main');
            state = { auth: { token: '' } } as AppRootState;
        }
    }
    return appReducer(state, action);
};

combineReducers({ counter, auth, killSwitch });

export type RootState = ReturnType<typeof store.getState>;

export default rootReducer;
