import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { ParticipantStepProps } from '@Pages/EventParticipants/types';
import { ReactIcon } from '@Icon';
import Header from '@Pages/EventDetails/components/Summary/components/Header';

const ActivityLog = React.forwardRef<HTMLDivElement, ParticipantStepProps>(({ id }, ref) => {
    const { t } = useTranslation();

    return (
        <Box id={id} ref={ref} mt='16px'>
            <Header divider={false} mb={4} title={t('EventParticipant.activityLog')} icon={<ReactIcon />} />
            {/* <Notes
                    errors={errors}
                    manageFieldPropFactory={manageFieldPropFactory}
                    textFieldPropFactory={textFieldPropFactory}
                /> */}
        </Box>
    );
});

export default ActivityLog;
