import { FC } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { FitzSittingFloorStretching } from '@Svg';
import { Trans } from 'react-i18next';
import { Button } from '@Components/Button';

interface IProps {
    isOpen: boolean;
    onLogoutClick: () => void;
    onExtendClick: () => void;
}

export const IdealSessionModal: FC<IProps> = ({ isOpen, onExtendClick, onLogoutClick }) =>
    isOpen && (
        <Box position='fixed' top={0} bottom={0} h='100%' w='full' bg='white' zIndex={100}>
            <Flex alignItems={'center'} justifyContent='center' h='100%'>
                <Flex flexDirection='column' gap='24px' w='384px' alignItems='center'>
                    <FitzSittingFloorStretching />
                    <Flex flexDirection='column' alignItems='center' w='100%' gap='16px' alignSelf='stretch'>
                        <Text textStyle='3xl-semibold'>
                            <Trans i18nKey={'IdealSessionModal.header'} />
                        </Text>
                        <Text noOfLines={2} textStyle='md-normal' textAlign='center'>
                            <Trans i18nKey='IdealSessionModal.subHeader' />
                        </Text>
                        <Button variant='primary' w='100%' onClick={onExtendClick}>
                            <Trans i18nKey='IdealSessionModal.keepButtonLabel' />
                        </Button>
                        <Button w='100%' onClick={onLogoutClick}>
                            <Trans i18nKey='IdealSessionModal.noButtonLabel' />
                        </Button>
                        <Text textStyle='sm-normal'>
                            <Trans i18nKey='IdealSessionModal.issue' />
                            <Button
                                as={'a'}
                                onClick={() => window.open('mailto:askucit@ucdsb.on.ca', '_blank').focus()}
                                variant='ghost'
                                ml='1'
                            >
                                <Trans i18nKey='IdealSessionModal.contactUs' />
                            </Button>
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    );
