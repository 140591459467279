import * as React from 'react';
function SvgAlarm(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <mask
                id='alarm_svg__a'
                style={{
                    maskType: 'alpha',
                }}
                maskUnits='userSpaceOnUse'
                x={0}
                y={0}
                width={28}
                height={28}
            >
                <path fill='#D9D9D9' d='M0 0h28v28H0z' />
            </mask>
            <g mask='url(#alarm_svg__a)'>
                <path
                    d='M15.223 14.686v-4.13c0-.345-.118-.635-.353-.87a1.182 1.182 0 00-.87-.353c-.345 0-.634.118-.87.353a1.182 1.182 0 00-.353.87v4.575a1.31 1.31 0 00.392.95l3.183 3.183c.228.228.515.34.862.338a1.18 1.18 0 00.862-.345c.228-.228.342-.515.342-.862s-.114-.634-.342-.862l-2.854-2.847zM14 25.904c-1.486 0-2.881-.283-4.185-.849a10.898 10.898 0 01-3.405-2.298 10.899 10.899 0 01-2.298-3.405 10.399 10.399 0 01-.85-4.185c0-1.487.284-2.882.85-4.185A10.898 10.898 0 016.41 7.576a10.898 10.898 0 013.405-2.298A10.399 10.399 0 0114 4.43c1.486 0 2.881.282 4.185.848a10.899 10.899 0 013.405 2.298 10.898 10.898 0 012.299 3.406c.565 1.303.848 2.698.848 4.185 0 1.486-.283 2.88-.848 4.185a10.9 10.9 0 01-2.299 3.405 10.899 10.899 0 01-3.405 2.298 10.399 10.399 0 01-4.185.849zM2.134 8.454a1.153 1.153 0 01-.339-.859c.003-.344.118-.63.346-.858L5.577 3.3a1.16 1.16 0 01.855-.341 1.164 1.164 0 011.197 1.2c0 .344-.114.63-.342.858L3.851 8.454a1.164 1.164 0 01-.859.342c-.344 0-.63-.114-.858-.342zm23.732 0a1.164 1.164 0 01-.858.342c-.345 0-.63-.114-.859-.342l-3.436-3.437a1.164 1.164 0 01-.342-.858 1.164 1.164 0 011.2-1.197c.345.002.631.117.859.345l3.436 3.437c.228.228.342.513.342.855 0 .342-.114.627-.342.855zM14 23.25c2.252 0 4.162-.785 5.73-2.353 1.569-1.569 2.353-3.479 2.353-5.73 0-2.252-.784-4.162-2.353-5.73-1.568-1.57-3.478-2.353-5.73-2.353-2.252 0-4.162.784-5.73 2.352-1.569 1.569-2.353 3.479-2.353 5.73 0 2.252.784 4.162 2.353 5.731 1.568 1.568 3.478 2.353 5.73 2.353z'
                    fill='#fff'
                />
            </g>
        </svg>
    );
}
export default SvgAlarm;
