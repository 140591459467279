import { Checkbox, FormControl, Stack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IFilterProps {
    onChange: (val: boolean | null) => void;
    value: boolean | null;
}

enum NoteTypes {
    late,
    absence,
}

export const CheckboxGroup = ({ onChange, value }: IFilterProps) => {
    const { t } = useTranslation();

    const [checkboxOptions, setCheckboxOptions] = useState([
        { key: NoteTypes.late, selected: true },
        { key: NoteTypes.absence, selected: false },
    ]);

    useEffect(() => {
        setCheckboxOptions([
            { key: NoteTypes.late, selected: value === true || value === null },
            { key: NoteTypes.absence, selected: value === false || value === null },
        ]);
    }, [value]);

    const changeHappened = (key: NoteTypes, value: boolean) => {
        if (!value) {
            const current = checkboxOptions.filter((x) => x.key !== key).map((x) => x.key);
            onChange(current[0] === NoteTypes.late);
        } else {
            const current = checkboxOptions.filter((x) => x.key === key || x.selected).map((x) => x.key);
            onChange(current.length === 2 ? null : current[0] === NoteTypes.late);
        }
    };
    return (
        <FormControl padding={'3px 8px'} border={'1px solid #E2E8F0'}>
            <Stack direction={['column', 'row']}>
                <Checkbox
                    onChange={(e) => changeHappened(NoteTypes.late, e.target.checked)}
                    isChecked={checkboxOptions.filter((x) => x.key === NoteTypes.late)[0].selected}
                >
                    {t('LateAbsenceNotes.filters.late')}
                </Checkbox>
                <Checkbox
                    onChange={(e) => changeHappened(NoteTypes.absence, e.target.checked)}
                    isChecked={checkboxOptions.filter((x) => x.key === NoteTypes.absence)[0].selected}
                >
                    {t('LateAbsenceNotes.filters.absence')}
                </Checkbox>
            </Stack>
        </FormControl>
    );
};
