import { useEffect, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { loginRequest } from '@Config';
import {
    useMsal,
    useIsAuthenticated,
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useAccount,
} from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { RouterProvider } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setToken } from '@Redux/slices/auth';
import { useIdleTimer } from 'react-idle-timer';
import { AlertContext } from './contexts/AlertContext';
import { router } from './router';
import { IdealSessionModal } from '@Components/IdealSessionModal';
import { KillSwitchTakeOverScreen } from '@Components/KillSwitchTakeOverScreen';
import './styles/global.css';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-date-picker/dist/DatePicker.css';
import '@I18n/i18n';

function App(): JSX.Element {
    const { instance, inProgress, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const account = useAccount(accounts[0] || {});

    const dispatch = useDispatch();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [showAlert, setShowAlert] = useState(false);
    const [triggerAlert, setTriggerAlert] = useState(false);

    useEffect(() => {
        if (accounts.length == 0 && inProgress === InteractionStatus.None && !isAuthenticated) {
            instance.loginRedirect(loginRequest).catch((e) => {
                console.log(e);
            });
        }
    }, [inProgress, isAuthenticated]);

    const timeoutInterval = 1000 * 60 * parseInt(process.env.REACT_APP_APPLICATION_IDLE_TIMEOUT || '55');

    const leftTime = 300;

    const [timeout, setTimeoutValue] = useState(leftTime);

    useEffect(() => {
        if (isOpen) {
            const interval = setInterval(() => {
                setTimeoutValue(timeout - 1);
            }, 1000);

            return () => clearInterval(interval);
        }
    });

    const handleIdle = () => {
        onOpen();
    };

    const handleRefreshToken = () => {
        onClose();
        setTimeoutValue(leftTime);
        requestProfileData(true);
    };

    useEffect(() => {
        if (timeout === 0) {
            onClose();
            handleLogout();
        }
    }, [timeout]);

    const handleLogout = () => {
        instance.logout({ account: accounts[0] }).catch((e) => {
            console.error(e);
        });
    };

    useIdleTimer({
        timeout: timeoutInterval,
        onIdle: handleIdle,
        debounce: 500,
    });

    function requestProfileData(forceRefresh?: boolean) {
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
                forceRefresh,
            })
            .then((response) => {
                dispatch(setToken(response?.idToken));
            })
            .catch(() =>
                instance
                    .loginRedirect(loginRequest)
                    .then(() => '')
                    .catch((e) => {
                        console.error(e);
                    })
            );
    }

    useEffect(() => {
        if (account) {
            requestProfileData();
        }
    }, [account]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (account) {
                requestProfileData();
            }
        }, leftTime * 1000);

        return () => clearInterval(intervalId);
    });

    return (
        <>
            <AuthenticatedTemplate>
                <AlertContext.Provider value={{ showAlert, setShowAlert, triggerAlert, setTriggerAlert }}>
                    <RouterProvider router={router} />
                    <IdealSessionModal
                        isOpen={isOpen}
                        onLogoutClick={handleLogout}
                        onExtendClick={handleRefreshToken}
                    />
                    <KillSwitchTakeOverScreen />
                </AlertContext.Provider>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <h5 className='card-title'>Please sign-in</h5>
            </UnauthenticatedTemplate>
        </>
    );
}

export default App;
