import * as React from 'react';
function SvgCalendar(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M5.135 22.276c-.63 0-1.166-.221-1.61-.665a2.193 2.193 0 01-.665-1.61V6.145c0-.63.222-1.167.665-1.61.444-.444.98-.665 1.61-.665h.928v-.934c0-.3.104-.552.312-.758a1.04 1.04 0 01.76-.308c.3 0 .552.103.757.308.206.206.309.458.309.758v.934h7.725v-.934c0-.3.103-.552.311-.758a1.04 1.04 0 01.76-.308c.3 0 .552.103.758.308.205.206.308.458.308.758v.934h.928c.63 0 1.167.221 1.61.665.444.443.665.98.665 1.61V20c0 .63-.221 1.167-.665 1.61-.443.444-.98.665-1.61.665H5.135zm0-2.275H18.99v-9.928H5.135v9.928zm0-11.928H18.99V6.145H5.135v1.928zm2.97 6.054c-.295 0-.543-.1-.742-.301-.2-.201-.3-.45-.3-.747 0-.295.1-.543.3-.742.2-.2.447-.3.742-.3h7.916c.296 0 .543.1.743.3.2.2.3.447.3.742s-.1.544-.3.745c-.2.202-.447.303-.743.303H8.105zm0 3.982c-.295 0-.543-.1-.742-.301-.2-.201-.3-.45-.3-.747 0-.295.1-.543.3-.742.2-.2.447-.3.742-.3h4.916c.295 0 .543.1.743.3.2.2.3.447.3.742s-.1.544-.3.745c-.2.202-.447.303-.743.303H8.105z'
                fill='#171923'
            />
        </svg>
    );
}
export default SvgCalendar;
