import * as React from 'react';
function SvgCitizenship(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <mask
                id='citizenship_svg__a'
                style={{
                    maskType: 'alpha',
                }}
                maskUnits='userSpaceOnUse'
                x={0}
                y={0}
                width={20}
                height={20}
            >
                <path fill='#D9D9D9' d='M0 0h20v20H0z' />
            </mask>
            <g mask='url(#citizenship_svg__a)'>
                <path
                    d='M5 15.06h5v-.48a1.25 1.25 0 00-.198-.68 1.323 1.323 0 00-.552-.486 5.369 5.369 0 00-.844-.26 4.219 4.219 0 00-.906-.094c-.32 0-.622.031-.906.093a4.803 4.803 0 00-.844.27A1.263 1.263 0 005 14.58v.48zm7.75-1h1.5a.724.724 0 00.531-.219.723.723 0 00.219-.531.723.723 0 00-.219-.532.723.723 0 00-.531-.218h-1.5a.723.723 0 00-.531.218.723.723 0 00-.219.532c0 .208.073.385.219.531a.723.723 0 00.531.219zm-5.25-1.5c.347 0 .642-.122.885-.365.244-.243.365-.538.365-.885 0-.348-.121-.643-.365-.886a1.206 1.206 0 00-.885-.364c-.347 0-.642.121-.885.364a1.206 1.206 0 00-.365.886c0 .347.122.642.365.885s.538.365.885.365zm5.25-1h1.5a.724.724 0 00.531-.219.723.723 0 00.219-.531.723.723 0 00-.219-.532.723.723 0 00-.531-.218h-1.5a.723.723 0 00-.531.218.723.723 0 00-.219.532c0 .208.073.385.219.531a.723.723 0 00.531.219zm-9.19 6.61c-.48 0-.887-.17-1.224-.506a1.667 1.667 0 01-.505-1.224V7.56c0-.48.168-.887.505-1.224a1.667 1.667 0 011.224-.505h4.27V3.45c0-.46.156-.845.466-1.155.31-.31.695-.465 1.154-.465h1.1c.459 0 .843.155 1.154.465.31.31.465.695.465 1.154v2.38h4.271c.48 0 .887.169 1.224.506.337.337.505.745.505 1.224v8.88c0 .48-.168.887-.505 1.224a1.667 1.667 0 01-1.224.505H3.56zm0-1.73h12.88V7.56h-4.28c-.017.423-.185.779-.504 1.067a1.6 1.6 0 01-1.106.433h-1.1c-.419 0-.788-.145-1.106-.436A1.47 1.47 0 017.84 7.56H3.56v8.88zm5.95-9.06h.98V3.51h-.98v3.87z'
                    fill='#171923'
                />
            </g>
        </svg>
    );
}
export default SvgCitizenship;
