import * as React from 'react';
function SvgGuardianContact(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 20 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M.679 11.408c0-.503.13-.965.39-1.387.26-.422.607-.744 1.038-.966.871-.434 1.758-.76 2.66-.978a11.756 11.756 0 015.507-.002 12.31 12.31 0 012.649.975c.431.222.777.543 1.038.964.26.42.39.885.39 1.394v.617c0 .525-.185.972-.554 1.341-.37.37-.817.555-1.342.555h-9.88c-.525 0-.972-.185-1.342-.554a1.827 1.827 0 01-.554-1.342v-.617zm16.746 2.513h-1.97c.189-.28.33-.581.423-.904.093-.323.14-.654.14-.992v-.744c0-.634-.17-1.255-.51-1.862-.341-.608-.847-1.128-1.518-1.56.685.086 1.344.235 1.977.444.633.21 1.22.459 1.758.747.513.278.907.606 1.183.984.275.378.413.79.413 1.237v.754c0 .525-.185.972-.554 1.341-.37.37-.817.555-1.342.555zm-9.91-7.006a3.293 3.293 0 01-2.416-1.001 3.293 3.293 0 01-1.002-2.417c0-.943.334-1.748 1.002-2.416A3.293 3.293 0 017.515.079c.943 0 1.749.334 2.416 1.002a3.293 3.293 0 011.002 2.416c0 .944-.334 1.75-1.002 2.417a3.293 3.293 0 01-2.416 1.001zm8.577-3.418c0 .94-.334 1.745-1.003 2.414a3.29 3.29 0 01-2.414 1.004c-.156 0-.351-.018-.584-.054a4.854 4.854 0 01-.598-.127 4.983 4.983 0 001.182-3.236A4.99 4.99 0 0011.492.261c.197-.073.394-.121.588-.145.194-.025.392-.037.591-.037.94 0 1.746.335 2.416 1.004.67.67 1.004 1.474 1.004 2.414zM2.575 12.025h9.88v-.592a.755.755 0 00-.114-.408.803.803 0 00-.302-.285 10.756 10.756 0 00-2.226-.82 9.729 9.729 0 00-4.595 0c-.761.185-1.503.458-2.227.82a.803.803 0 00-.302.285.755.755 0 00-.114.408v.592zm4.94-6.93a1.54 1.54 0 001.127-.47c.313-.312.47-.688.47-1.127a1.54 1.54 0 00-.469-1.128 1.536 1.536 0 00-1.127-.47 1.54 1.54 0 00-1.128.47c-.313.312-.47.688-.47 1.127 0 .439.156.815.469 1.128.312.313.688.47 1.127.47z'
                fill='#171923'
            />
        </svg>
    );
}
export default SvgGuardianContact;
