import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
const overrideStyle = defineStyle({
    borderRadius: 'sm',
    bgColor: 'surface-primary',
    fontFamily: 'Inter',
    fontSize: 'md',
});

const Input = defineStyleConfig({
    sizes: {
        lg: {
            field: overrideStyle,
        },
        md: {
            field: overrideStyle,
        },
        sm: {
            field: overrideStyle,
        },
        xs: {
            field: overrideStyle,
        },
    },
});

export default Input;
