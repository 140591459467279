//TODO: Change Input disable colors in theme
//TODO: Switch Theme along wth invalid color
const semanticTokens = {
    colors: {
        'text-primary': {
            default: 'gray.900',
        },
        'text-secondary': {
            default: 'gray.700',
        },
        'text-placeholder': {
            default: 'gray.500',
        },
        'text-brand-primary': {
            default: 'teal.600',
        },
        'text-disabled': {
            default: 'gray.500',
        },
        'text-invert': {
            default: 'gray.100',
        },
        'text-success': {
            default: 'green.600',
        },
        'text-error': {
            default: 'red.500',
        },
        'text-warning': {
            default: 'yellow.700',
        },
        'surface-primary': {
            default: 'white',
        },
        'surface-secondary': {
            default: 'gray.100',
        },
        'surface-tertiary': {
            default: 'gray.200',
        },
        'surface-brand-primary': {
            default: 'teal.500',
        },
        'surface-brand-invert': {
            default: 'teal.50',
        },
        'surface-invert': {
            default: 'gray.900',
        },
        'surface-success': {
            default: 'green.500',
        },
        'surface-success-invert': {
            default: 'green.50',
        },
        'surface-success-hover': {
            default: 'green.600',
        },
        'surface-error': {
            default: 'red.500',
        },
        'surface-error-invert': {
            default: 'red.50',
        },
        'surface-error-hover': {
            default: 'red.600',
        },
        'surface-warning': {
            default: 'yellow.500',
        },
        'surface-warning-invert': {
            default: 'yellow.100',
        },
        'surface-warning-hover': {
            default: 'yellow.600',
        },
        'border-primary': {
            default: 'gray.300',
        },
        'border-hover': {
            default: 'gray.600',
        },
        'border-brand': {
            default: 'surface-brand-primary',
        },
        'border-success': {
            default: 'surface-success',
        },
        'border-error': {
            default: 'surface-error',
        },
        'border-warning': {
            default: 'surface-warning',
        },
    },
};

export default semanticTokens;
