import { Box, Button, Flex, Stack, useDisclosure } from '@chakra-ui/react';
import { sideBarData } from './components/Sidebar';
import Sidebar from '@Components/Sidebar';
import { Trans, useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RegistrationSidebarItem, RegistrationStatuses } from './types';
import AboutStudent from './components/AboutStudent';
import { ArrowForwardIcon, Cycle, KebabMenu } from '@Icon';
import Steps from '@Components/Steps';
import GuardianContactDetails from './components/GuardianContactDetails';
import HomeAddress from './components/HomeAddress';
import OfficeAdministration from './components/OfficeAdministration';
import PreviousSchool from './components/PreviousSchool';
import School from './components/School';
import Citizenship from './components/Citizenship';
import BusNeeds from './components/BusNeeds';
import { useRegistrationDetail } from '@Hooks/useRegistrationDetail';
import { useParams } from 'react-router-dom';
import {
    useCreateRegistrationNoteMutation,
    useDeleteRegistrationNoteMutation,
    useLazyGetRegistrationDetailsQuery,
    useUpdateRegistrationMutation,
    useUpdateRegistrationNoteMutation,
    useUpdateRegistrationStatusMutation,
    useGetRegistrationNotesMutation,
} from '@Redux/services/Registration';
import { getErrorsAssociatedWithFields, parseError, parseWarning, ValidatorType } from '@Utilities';
import { useToast } from '@Hooks/useToast';
import { DetailPageSkeleton } from '@Components/DetailPageSkeleton';
import Contacts from './components/Contacts';
import { Validator } from './validator';
import { RegistrationStatus } from 'src/constants';
import {
    populateEmergencyContacts,
    getNextStatus,
    isAnyAddressPropertySet,
    populateEmergencyContactsArray,
} from './helpers';
import { RegistrationInformation } from '@Redux/services/Registration/types';
import { Note, PagedResult } from '@Redux/services/commonTypes';
import StatusChangesDialog from './components/StatusChangeDialog';
import Notes from '@Components/Notes';
import { useMsal } from '@azure/msal-react';
import { useGetSchoolsByGradeMutation } from '@Redux/services/LookupApi';
import { Menu, MenuItem } from '@Components/Menu';

const RegistrationDetails = () => {
    const { id } = useParams<{ id: string }>();

    const [t] = useTranslation();
    const sideBarItems = useMemo(() => sideBarData(RegistrationSidebarItem.None, t), []);
    const printRef = useRef(null);
    const toast = useToast();
    const [nextButtonLoading, setNextButtonLoading] = useState(false);
    const [statusOverride, setStatusOverride] = useState<RegistrationStatus>(undefined);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [updateRegistration] = useUpdateRegistrationMutation();
    const [updateRegistrationStatus] = useUpdateRegistrationStatusMutation();
    const [createRegistrationNote] = useCreateRegistrationNoteMutation();
    const { accounts } = useMsal();
    const account = accounts[0];
    const [getSchoolsByGrade] = useGetSchoolsByGradeMutation();
    const [schools, setSchools] = useState([]);

    const registrationDetailsRequiredFields = (rules: ValidatorType[]) => {
        if (!registrationInformation) return;
        const newRegistrationRequiredFields = {};
        rules.map((validaton) => {
            newRegistrationRequiredFields[validaton.accessorPath.join('.')] = validaton;
        });
        return newRegistrationRequiredFields;
    };
    const [deleteRegistrationNote] = useDeleteRegistrationNoteMutation();
    const [updateRegistrationNote] = useUpdateRegistrationNoteMutation();
    const [getRegistrationNotes, { isLoading: isNotesLoading }] = useGetRegistrationNotesMutation();

    const {
        registrationInformation,
        textFieldPropFactory,
        selectFieldPropFactory,
        setErrors,
        manageFieldPropFactory,
        switchFieldPropFactory,
        setFormData,
        setIsDirty,
        setDirtyData,
        dirtyAccessors,
        setDirtyAccessor,
        steps,
        isDirty,
        validate,
        saveValidationError,
        registrationRequiredFields,
        isPageReadOnly,
    } = useRegistrationDetail();

    const [getRegistrationDetails, { isLoading }] = useLazyGetRegistrationDetailsQuery();

    const componentProps = {
        textFieldPropFactory,
        selectFieldPropFactory,
        manageFieldPropFactory,
        switchFieldPropFactory,
    };

    useEffect(() => {
        getRegistrationDetails({ id: id })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                let data = response.data as RegistrationInformation;

                const newEmergencyContactInfo = populateEmergencyContacts(data.emergencyContactInformation);

                const newAddressInformation = {
                    ...data.addressInformation,
                    isMailingAddressSameAsHomeAddress: !data?.addressInformation?.isMailingAddressDifferent,
                };

                const notesObject = { ...data.notes };
                const notes = notesObject.studentRegistrationNotesInfo.map((note) => {
                    return {
                        ...note,
                        canDelete: account.username === note.modifiedByEmailAddress,
                        canEdit: false,
                    };
                });
                notesObject.studentRegistrationNotesInfo = notes;

                data = {
                    ...data,
                    notes: notesObject,
                    emergencyContactInformation: newEmergencyContactInfo,
                    addressInformation: newAddressInformation,
                };
                handleGradeChange(data.childInformation?.grade);
                setFormData(data);
            })
            .catch((error) => {
                parseError(toast, error);
            });
    }, [id]);

    const handleNext = useCallback(
        (statusComment: string) => {
            handleSave().then(() => {
                setNextButtonLoading(true);

                const status =
                    statusOverride === undefined ? getNextStatus(registrationInformation.status) : statusOverride;
                updateRegistrationStatus({
                    prospectiveStudentId: registrationInformation.id,
                    status: status,
                    sourceDocument: registrationInformation.sourceDocument,
                    statusComment: statusComment,
                })
                    .unwrap()
                    .then(() => {
                        registrationInformation.status = status;
                        getRegistrationNotes({ objectId: id, currentPage: 1, pageSize: 10 })
                            .unwrap()
                            .then((response) => {
                                const notesObject = { ...response };
                                const notes = notesObject.data.map((note) => {
                                    return {
                                        ...note,
                                        canDelete: account.username === note.modifiedByEmailAddress,
                                        canEdit: false,
                                    };
                                });
                                notesObject.data = notes;

                                setFormData({
                                    ...registrationInformation,
                                    notes: {
                                        studentRegistrationNotesInfo: notesObject.data,
                                        pagedResult: notesObject.pagedResult,
                                    },
                                });
                            })
                            .catch((error) => {
                                parseError(toast, error);
                            });

                        let message = '';
                        if (status === RegistrationStatus.DocumentsRequired) {
                            message = t('RegistrationDetails.toastDocumentRequiredSuccess');
                        } else if (status === RegistrationStatus.Approved) {
                            message = t('RegistrationDetails.toastApprovedSuccess');
                        }

                        toast({
                            status: 'success',
                            description: message,
                        });
                    })
                    .catch((error) => {
                        setErrors(
                            getErrorsAssociatedWithFields(
                                error.data.errors,
                                Object.keys(registrationDetailsRequiredFields)
                            )
                        );
                        parseError(toast, error);
                    })
                    .finally(() => {
                        setNextButtonLoading(false);
                    });
            });
        },
        [registrationInformation, setErrors, nextButtonLoading, statusOverride]
    );

    const handleReject = useCallback(
        (statusComment: string) => {
            setNextButtonLoading(true);

            const status = RegistrationStatus.Rejected;
            updateRegistrationStatus({
                prospectiveStudentId: registrationInformation.id,
                status: status,
                sourceDocument: registrationInformation.sourceDocument,
                statusComment: statusComment,
            })
                .unwrap()
                .then(() => {
                    registrationInformation.status = status;
                    getRegistrationNotes({ objectId: id, currentPage: 1, pageSize: 10 })
                        .unwrap()
                        .then((response) => {
                            const notesObject = { ...response };
                            const notes = notesObject.data.map((note) => {
                                return {
                                    ...note,
                                    canDelete: account.username === note.modifiedByEmailAddress,
                                    canEdit: false,
                                };
                            });
                            notesObject.data = notes;

                            setFormData({
                                ...registrationInformation,
                                notes: {
                                    studentRegistrationNotesInfo: notesObject.data,
                                    pagedResult: notesObject.pagedResult,
                                },
                            });
                        })
                        .catch((error) => {
                            parseError(toast, error);
                        });

                    const message = t('RegistrationDetails.toastRejectedSuccess');

                    toast({
                        status: 'success',
                        description: message,
                    });
                })
                .catch((error) => {
                    setErrors(
                        getErrorsAssociatedWithFields(error.data.errors, Object.keys(registrationDetailsRequiredFields))
                    );
                    parseError(toast, error);
                })
                .finally(() => {
                    setNextButtonLoading(false);
                });
        },
        [registrationInformation, setErrors, nextButtonLoading]
    );

    const handleApproveDirectlyDlgOpen = async () => {
        setStatusOverride(RegistrationStatus.Approved);
        const rules = Validator(registrationInformation, t);
        if (
            isPageReadOnly() ||
            !validate(rules, RegistrationStatus.DocumentsRequired) ||
            !validate(rules, RegistrationStatus.Approved) ||
            saveValidationError ||
            !registrationInformation
        )
            return;
        onOpen();
    };

    const handleNextStatusDlgOpen = async () => {
        setStatusOverride(undefined);
        const rules = Validator(registrationInformation, t);
        const nextStatus = getNextStatus(registrationInformation.status);
        if (isPageReadOnly() || !validate(rules, nextStatus) || saveValidationError || !registrationInformation) return;
        onOpen();
    };

    const handleRejectDlgOpen = async () => {
        setStatusOverride(RegistrationStatus.Rejected);
        onOpen();
    };

    const handleSave = useCallback(async () => {
        const rules = Validator(registrationInformation, t);
        if (
            isPageReadOnly() ||
            !validate(rules, registrationInformation.status) ||
            saveValidationError ||
            !registrationInformation
        )
            return;

        try {
            const newSchoolInformation = {
                ...registrationInformation.schoolInformation,
                id:
                    registrationInformation.schoolInformation?.id === ''
                        ? null
                        : registrationInformation.schoolInformation?.id,
            };

            //const cityFreeForm = cities.find((c)=> c.key === registrationInformation.addressInformation.parentAddress.city)?.value;

            const newAddressInformation = {
                ...registrationInformation.addressInformation,
                isMailingAddressDifferent:
                    !registrationInformation.addressInformation.isMailingAddressSameAsHomeAddress,
            };

            const newTransportationInformation = {
                ...registrationInformation.transportationInformation,
                isPickupNeeded: isAnyAddressPropertySet(
                    registrationInformation.transportationInformation.pickUpAddress
                ),
                isDropOffNeeded: isAnyAddressPropertySet(
                    registrationInformation.transportationInformation.dropOffAddress
                ),
            };

            const newEmergencyContactInformation = populateEmergencyContactsArray(
                registrationInformation.emergencyContactInformation
            );

            const updatedRegistrationInformation = {
                ...registrationInformation,
                addressInformation: newAddressInformation,
                transportationInformation: newTransportationInformation,
                emergencyContactInformation: newEmergencyContactInformation,
                schoolInformation: newSchoolInformation,
            };

            updatedRegistrationInformation.emergencyContactInformation = populateEmergencyContactsArray(
                updatedRegistrationInformation.emergencyContactInformation
            );

            const response = await updateRegistration(updatedRegistrationInformation).unwrap();
            parseWarning(toast, response);
            setDirtyData({});
            setIsDirty(false);
            setDirtyAccessor([]);

            const message = t('RegistrationDetails.savedSuccessfully');

            toast({
                status: 'success',
                description: message,
            });
        } catch (error) {
            const nextErrors: Record<string, string> = {};
            for (const dirtyAccessor of dirtyAccessors) {
                nextErrors[dirtyAccessor.join('.')] = t('RegistrationDetails.fieldNotSaved');
            }
            setErrors(getErrorsAssociatedWithFields(error.data.errors, Object.keys(registrationRequiredFields)));
            parseError(toast, error);
        }
    }, [registrationInformation]);

    const handlePrint = () => {
        if (printRef.current) {
            const printContents = printRef.current.innerHTML;
            const originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            window.print();
            document.body.innerHTML = originalContents;
            window.location.reload(); // Reload to restore the original content
        }
    };

    const handleNotePageChange = async (pageNumber: number, pageSize: number) => {
        await getRegistrationNotes({
            objectId: registrationInformation?.id,
            pageSize,
            currentPage: pageNumber,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                const notesObject = { ...response };
                const notes = notesObject.data.map((note) => {
                    return {
                        ...note,
                        canDelete: account.username === note.modifiedByEmailAddress,
                        canEdit: false,
                    };
                });
                notesObject.data = notes;

                setFormData({
                    ...registrationInformation,
                    notes: { studentRegistrationNotesInfo: notesObject.data, pagedResult: notesObject.pagedResult },
                });
            })
            .catch((e) => parseError(toast, e));
    };

    const handleAddNote = async (noteText: string) => {
        await createRegistrationNote({
            objectId: registrationInformation?.id,
            noteText,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                const notesObject = { ...response };
                const notes = notesObject.data.map((note) => {
                    return {
                        ...note,
                        canDelete: account.username === note.modifiedByEmailAddress,
                        canEdit: false,
                    };
                });
                notesObject.data = notes;

                setFormData({
                    ...registrationInformation,
                    notes: { studentRegistrationNotesInfo: notesObject.data, pagedResult: notesObject.pagedResult },
                });
            })
            .catch((e) => parseError(toast, e));
    };

    const handleDeleteNote = async (annotationId: string) => {
        await deleteRegistrationNote({ annotationId })
            .unwrap()
            .then((response) => {
                const notesObject = { ...response };
                const notes = notesObject.data.map((note) => {
                    return {
                        ...note,
                        canDelete: account.username === note.modifiedByEmailAddress,
                        canEdit: false,
                    };
                });
                notesObject.data = notes;

                setFormData({
                    ...registrationInformation,
                    notes: { studentRegistrationNotesInfo: notesObject.data, pagedResult: notesObject.pagedResult },
                });
                if (response?.warningResult?.showAlert) {
                    parseWarning(toast, response);
                } else {
                    toast({
                        status: 'success',
                        description: t('RegistrationDetails.toastSuccessNoteDelete'),
                    });
                }
            })
            .catch((e) => parseError(toast, e));
    };

    const handleGradeChange = async (grade: number) => {
        getSchoolsByGrade({
            grade: grade,
            searchText: '',
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                setSchools(response.data);
            })
            .catch((e) => parseError(toast, e));
        setFormData((registrationInformation) => ({
            ...registrationInformation,
            childInformation: {
                ...registrationInformation.childInformation,
                grade: grade,
            },
        }));
    };

    const actionMenuItems = useMemo<MenuItem[]>(
        () => [
            {
                label: t('RegistrationDetails.approveDirectly'),
                menuItemProps: {
                    onClick: handleApproveDirectlyDlgOpen,
                    isDisabled: registrationInformation?.status !== RegistrationStatus.Submitted,
                },
            },
        ],
        [registrationInformation?.status]
    );

    if (isLoading) {
        return <DetailPageSkeleton />;
    }

    return (
        <>
            <Flex>
                <Sidebar
                    {...sideBarItems}
                    footerDetails={{
                        createdOn: registrationInformation?.createdOn,
                        createdByFullName: registrationInformation?.createdByFullName,
                        modifiedByFullName: registrationInformation?.modifiedByFullName,
                        modifiedOn: registrationInformation?.modifiedOn,
                        onSave: handleSave,
                        disabled: !isDirty || isPageReadOnly(),
                    }}
                />
                <Flex overflow={'hidden'} width='100%'>
                    <form style={{ width: '100%' }}>
                        <Box ref={printRef} pt={8} maxWidth='100%'>
                            <Box pr={4}>
                                <Flex justifyContent='space-between' direction={{ base: 'column', md: 'row' }}>
                                    <Steps steps={steps} />
                                    <div>
                                        <Button
                                            onClick={handlePrint}
                                            textStyle={'md-semibold'}
                                            mr={3}
                                            title={t('RegistrationDetails.print')}
                                        >
                                            {t('RegistrationDetails.print')}
                                        </Button>
                                        <Button
                                            isDisabled={isPageReadOnly()}
                                            onClick={handleRejectDlgOpen}
                                            textStyle={'md-semibold'}
                                            variant={
                                                registrationInformation.status === RegistrationStatus.Rejected
                                                    ? 'primary'
                                                    : null
                                            }
                                            mr={3}
                                            title={t('RegistrationDetails.reject')}
                                        >
                                            {t('RegistrationDetails.reject')}
                                        </Button>

                                        <Button
                                            isDisabled={isPageReadOnly()}
                                            onClick={handleNextStatusDlgOpen}
                                            textStyle={'md-semibold'}
                                            variant={
                                                registrationInformation.status === RegistrationStatus.Rejected
                                                    ? null
                                                    : nextButtonLoading
                                                    ? 'brand-inverted'
                                                    : 'primary'
                                            }
                                            rightIcon={
                                                !isPageReadOnly() ? (
                                                    nextButtonLoading ? (
                                                        <Cycle className='spin-loading' width='20px' height='20px' />
                                                    ) : (
                                                        <ArrowForwardIcon width='20px' height='20px' />
                                                    )
                                                ) : null
                                            }
                                            mr={2}
                                        >
                                            {!isPageReadOnly() ? (
                                                <Trans
                                                    i18nKey={
                                                        nextButtonLoading
                                                            ? 'RegistrationDetails.nextStepLoading'
                                                            : 'RegistrationDetails.nextStep'
                                                    }
                                                    values={{
                                                        seperator: ':',
                                                        stepLabel:
                                                            RegistrationStatuses[
                                                                getNextStatus(registrationInformation?.status)
                                                            ],
                                                    }}
                                                />
                                            ) : (
                                                RegistrationStatuses[getNextStatus(registrationInformation?.status)]
                                            )}
                                        </Button>
                                        <Menu
                                            items={actionMenuItems}
                                            buttonIcon={<KebabMenu width={24} height={24} />}
                                        />
                                    </div>
                                </Flex>
                            </Box>
                            <Stack bg='surface-secondary' p={3} mt={5} spacing={8} borderLeftRadius={15}>
                                <OfficeAdministration id={'officeadministration'} {...componentProps} />
                                <AboutStudent
                                    id={'aboutstudent'}
                                    {...componentProps}
                                    onGradeChange={handleGradeChange}
                                />
                                <HomeAddress
                                    id={'homeaddress'}
                                    {...{
                                        ...componentProps,
                                        registrationInformation: registrationInformation as RegistrationInformation,
                                    }}
                                />
                                <BusNeeds id={'busneeds'} {...componentProps} />
                                <PreviousSchool id={'previousschool'} {...componentProps} />
                                <Citizenship id={'citizenship'} {...componentProps} />
                                <School id={'school'} {...componentProps} schools={schools} />
                                <GuardianContactDetails id={'guardiancontactdetails'} {...componentProps} />
                                <Contacts id={'contacts'} {...componentProps} />
                                <Notes
                                    id={'notes'}
                                    notes={registrationInformation?.notes?.studentRegistrationNotesInfo as Note[]}
                                    onAddNote={async (noteText: string) => {
                                        handleAddNote(noteText);
                                    }}
                                    onDeleteNote={(annotationId: string) => handleDeleteNote(annotationId)}
                                    notePagination={registrationInformation?.notes?.pagedResult as PagedResult}
                                    isNotesLoading={isNotesLoading}
                                    onNextPage={handleNotePageChange}
                                    isNoteEditable={!isPageReadOnly()}
                                    hideActivities={true}
                                    defaultPageSize={registrationInformation?.notes?.pagedResult.pageSize}
                                />
                            </Stack>
                        </Box>
                    </form>
                </Flex>
            </Flex>
            {isOpen && (
                <StatusChangesDialog
                    isOpen={isOpen}
                    onSave={statusOverride === RegistrationStatus.Rejected ? handleReject : handleNext}
                    onClose={onClose}
                    status={
                        statusOverride === undefined
                            ? RegistrationStatuses[getNextStatus(registrationInformation?.status)]
                            : RegistrationStatuses[statusOverride]
                    }
                />
            )}
        </>
    );
};
export default RegistrationDetails;
