import { Flex, Text, HStack, Button, FlexProps, Select, IconButton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { DEFAULT_PAGE_SIZE } from 'src/constants';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';

type ViewMode = 'Numbered' | 'ArrowButton' | 'TextButton';

interface PaginationProps extends FlexProps {
    totalResults: number;
    currentPage: number;
    totalPages: number;
    pageSize: number;
    onPageChange: (pageNum: number) => void;
    onPageSizeChange?: (pageSize: number) => void;
    disablePageSizeChange?: boolean;
    viewMode?: ViewMode;
}

const Pagination: React.FC<PaginationProps> = ({
    totalResults,
    currentPage,
    totalPages,
    onPageChange,
    onPageSizeChange,
    disablePageSizeChange = false,
    viewMode = 'Numbered',
    pageSize = DEFAULT_PAGE_SIZE,
    ...rest
}) => {
    const { t } = useTranslation();
    const pageSizes = useMemo(() => [0, 1, 3, 9].map((i) => (i + 1) * DEFAULT_PAGE_SIZE), []);
    const getPageNumbers = () => {
        if (totalPages <= 7) {
            return Array.from({ length: totalPages }, (_, i) => i + 1);
        }

        const pageNumbers = [];

        if (currentPage <= 4) {
            for (let i = 1; i <= 5; i++) {
                pageNumbers.push(i);
            }
            pageNumbers.push('...', totalPages);
        } else if (currentPage >= totalPages - 3) {
            pageNumbers.push(1, '...');
            for (let i = totalPages - 4; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            pageNumbers.push(1, '...');
            for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                pageNumbers.push(i);
            }
            pageNumbers.push('...', totalPages);
        }

        return pageNumbers;
    };

    const goTo = (e) => {
        const numList = getPageNumbers();
        let index = 0;
        if (numList.filter((f) => f === '...').length === 2) {
            if (numList[Number(e.target.id) - 1] === 1) {
                index = numList[Number(e.target.id) - 1] + 1;
            } else {
                index = numList[Number(e.target.id) + 1] - 1;
            }
        } else if (numList[Number(e.target.id) - 1] === 1) {
            index = numList[Number(e.target.id) - 1] + 1;
        } else {
            index = numList[Number(e.target.id) + 1] - 1;
        }
        return index;
    };

    return (
        <Flex align='center' justify='space-between' p={4} {...rest}>
            <Flex gap={'8px'} alignItems='center'>
                <Text mb={0} textStyle='sm-normal'>
                    {t('EventsList.paginationDescription', {
                        start: Math.min(totalResults, (currentPage - 1) * pageSize + 1),
                        end: Math.min(totalResults, currentPage * pageSize),
                        total: totalResults,
                    })}
                </Text>

                {!disablePageSizeChange && (
                    <Select
                        value={pageSize}
                        size={'sm'}
                        w='100px'
                        onChange={(event) => onPageSizeChange(parseInt(event.target.value, 10))}
                    >
                        {pageSizes.map((pageSize, i) => (
                            <option key={`page-size-${i}`} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </Select>
                )}
            </Flex>
            <HStack gap={2}>
                {viewMode === 'Numbered' &&
                    getPageNumbers().map((pageNumber, index) => (
                        <Text
                            textStyle={'sm-bold'}
                            id={index.toString()}
                            key={index}
                            color={pageNumber === currentPage ? 'theme.900' : 'black'}
                            cursor={'pointer'}
                            onClick={(e) => {
                                onPageChange(typeof pageNumber === 'number' ? pageNumber : goTo(e));
                            }}
                        >
                            {pageNumber}
                        </Text>
                    ))}
                {viewMode === 'TextButton' && (
                    <Flex gap={4}>
                        <Button
                            variant='secondary'
                            onClick={() => onPageChange(currentPage - 1)}
                            isDisabled={1 === currentPage}
                        >
                            {t('Pagination.previous')}
                        </Button>
                        <Button
                            variant='secondary'
                            onClick={() => onPageChange(currentPage + 1)}
                            isDisabled={totalPages === currentPage || totalPages === 0}
                        >
                            {t('Pagination.next')}
                        </Button>
                    </Flex>
                )}
                {viewMode === 'ArrowButton' && (
                    <Flex gap={4}>
                        <IconButton
                            variant='secondary'
                            onClick={() => onPageChange(currentPage - 1)}
                            isDisabled={1 === currentPage}
                            icon={<ArrowBackIcon />}
                            aria-label={t('Pagination.previous')}
                        />
                        <IconButton
                            variant='secondary'
                            onClick={() => onPageChange(currentPage + 1)}
                            isDisabled={totalPages === currentPage || totalPages === 0}
                            icon={<ArrowForwardIcon />}
                            aria-label={t('Pagination.next')}
                        />
                    </Flex>
                )}
            </HStack>
        </Flex>
    );
};

export { Pagination };
