import { instance, loginRequest } from '@Config';
import { RootState, store } from '@Redux';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { camelCase } from 'lodash';
import { setKillSwitch } from '@Redux/slices/killSwitch';
import { setToken } from '@Redux/slices/auth';

export const GET = 'GET';
export const POST = 'POST';
export const PATCH = 'PATCH';
export const RESPONSE_STATUS_404 = 404;
export const RESPONSE_STATUS_500 = 500;
export const RESPONSE_STATUS_422 = 422;
export const MFR_BASE_URL = process.env.REACT_APP_MFR_API_BASEURL;

export const baseApi = createApi({
    reducerPath: 'baseApi',
    baseQuery: fetchBaseQuery({
        baseUrl:
            process.env.NODE_ENV === 'production' && window.location.host.indexOf('staging') > -1
                ? process.env.REACT_APP_API_STAGING_BASE_URL
                : process.env.REACT_APP_API_BASE_URL,
        prepareHeaders: async (headers, { getState }) => {
            let token = (getState() as RootState)?.auth?.token;
            const decodedJwt = token && JSON.parse(atob(token.split('.')[1]));
            if (!token || decodedJwt.exp * 1000 <= Date.now()) {
                await instance
                    .acquireTokenSilent({
                        ...loginRequest,
                        account: instance.getAllAccounts()[0],
                        forceRefresh: true,
                    })
                    .then((response) => {
                        token = response?.idToken;
                        setToken(token);
                    })
                    .catch(() => {
                        instance.logoutRedirect();
                    });
            }
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
        responseHandler: async (response: Response): Promise<any> => {
            if (response.status === 304) {
                console.log(response.url, response);
            }
            if (response.status === RESPONSE_STATUS_422) {
                store.dispatch(setKillSwitch(true));
            } else {
                store.dispatch(setKillSwitch(false));
            }
            const responseData = await response.json();
            if ('errors' in responseData) {
                const errors = {};
                Object.keys(responseData.errors as Record<string, string[]>[]).map((key) => {
                    const newKey = key
                        .split('.')
                        .map((x) => camelCase(x))
                        .join('.');
                    errors[newKey] = responseData.errors[key][0];
                });
                return {
                    title: responseData.title,
                    errors: errors,
                };
            }
            if ('title' in responseData && 'detail' in responseData) {
                return {
                    title: responseData.title,
                    errors: [responseData.detail],
                };
            }
            return responseData;
        },
    }),
    endpoints: () => ({}),
});
