import { Box, Divider, Flex, Link, useDisclosure } from '@chakra-ui/react';
import { RangeDatepicker } from '@Components';
import { Alert } from '@Components/Alert';
import { Select } from '@Components/Select';
import { useGetAbsenceStatusCodesQuery, useGetAbsenceReasonCodesQuery } from '@Redux/services/LookupApi';
import { format } from 'date-fns';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DATE_FORMAT_E_MMM_YY } from 'src/constants';

export interface PowerSchoolAbsencesFilters {
    dateRange: Date[];
    statusReason?: string;
    reasonCode?: string;
}

interface IProps {
    onChange: (key: string) => (value: string | number | Date[]) => void;
    onResetField: (key: string, value: []) => void;
    onReset: () => void;
    filters: PowerSchoolAbsencesFilters;
}

export const TableFilters: FC<IProps> = ({ filters, onChange, onReset, onResetField }) => {
    const { data: { data: statusReason = [] } = {} } = useGetAbsenceStatusCodesQuery();
    const { data: { data: reasonCode = [] } = {} } = useGetAbsenceReasonCodesQuery();
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const { t } = useTranslation();

    const handleChange = (key) => (event) => {
        onChange(key)(parseInt(event.target.value, 10));
    };

    const handleReasonCodeChange = (event) => {
        onChange('reasonCode')(event.target.value);
    };
    return (
        <>
            <Alert
                isOpen={isOpen}
                onClose={onClose}
                alertProps={{
                    status: 'warning',
                }}
                description={
                    <Trans
                        i18nKey={'PowerSchoolAbsences.TableFilters.description'}
                        values={{
                            startDate: format(new Date(), DATE_FORMAT_E_MMM_YY),
                            endDate: format(new Date(), DATE_FORMAT_E_MMM_YY),
                        }}
                        components={[
                            <Link
                                onClick={() => {
                                    onReset();
                                    onClose();
                                }}
                                color={'text-warning'}
                            />,
                        ]}
                    />
                }
            />
            <Divider />
            <Flex display={{ base: 'block', md: 'flex' }} p={6} gap='16px'>
                <Box maxW={300}>
                    <RangeDatepicker
                        onDateChange={onChange('dateRange')}
                        size='sm'
                        isLocked={false}
                        selectedDates={filters.dateRange}
                        onResetClick={() => onResetField('dateRange', [])}
                        placeHolder={t('PowerSchoolAbsences.filters.dateRange')}
                    />
                </Box>
                <Select
                    onChange={handleChange('statusReason')}
                    value={filters.statusReason}
                    size='sm'
                    options={statusReason}
                    bg='surface-primary'
                    placeholder={t('PowerSchoolAbsences.filters.statusReason')}
                    maxW='180px'
                />
                <Select
                    onChange={handleReasonCodeChange}
                    value={filters.reasonCode}
                    size='sm'
                    options={reasonCode}
                    bg='surface-primary'
                    placeholder={t('PowerSchoolAbsences.filters.reasonCode')}
                    maxW='180px'
                />
            </Flex>
            <Divider />
        </>
    );
};
