import { Box, Grid } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from './components/Header';
import SvgSummary from '@Icon/Summary';
import Textarea from '@Components/Textarea';
import { ManageDatePickerProps, TextareaPropFactory } from '@Hooks';
import NumberInput from '@Components/NumberInput/Index';
import DatePicker from '@Components/DateTimeInput';
import { StepProps } from '@Pages/EventResponses/types';
import TypeaheadInput from '@Components/TypeaheadInput';

type csProps = {
    isDrawer?: boolean;
} & StepProps;

const CancellationAndSubsidy = React.forwardRef<HTMLDivElement, csProps>(
    (
        { id, eventResponse, manageFieldPropFactory, textAreaPropFactory, textFieldPropFactory, isDrawer = false },
        ref
    ) => {
        const { t } = useTranslation();

        return (
            <Box id={id} ref={ref}>
                <Header
                    divider={true}
                    mb={4}
                    title={t('EventResponseDetail.CancellationAndSubsidy.header')}
                    icon={<SvgSummary />}
                />
                <Grid
                    column={{ md: 2, sm: 1 }}
                    columnGap={10}
                    gridAutoFlow='dense'
                    templateColumns={
                        isDrawer
                            ? { base: 'repeat(1, 1fr)' }
                            : {
                                  base: 'repeat(1, 1fr)',
                                  md: 'repeat(2, 1fr)',
                              }
                    }
                    rowGap={4}
                >
                    <DatePicker
                        {...textFieldPropFactory(t('EventResponseDetail.CancellationAndSubsidy.cancelledOn'), [
                            'cancellation',
                            'cancelledOn',
                        ])}
                        {...manageFieldPropFactory<ManageDatePickerProps>(
                            ['cancellation', 'cancelledOn'],
                            'onDateChange',
                            'date'
                        )}
                        size='md'
                    />
                    <DatePicker
                        {...textFieldPropFactory(t('EventResponseDetail.CancellationAndSubsidy.confirmedOn'), [
                            'subsidy',
                            'confirmedOn',
                        ])}
                        {...manageFieldPropFactory<ManageDatePickerProps>(
                            ['subsidy', 'confirmedOn'],
                            'onDateChange',
                            'date'
                        )}
                        size='md'
                    />

                    <NumberInput
                        {...manageFieldPropFactory(['cancellation', 'refundPercentage'], 'onChangeValue', 'value')}
                        {...textFieldPropFactory(t('EventResponseDetail.CancellationAndSubsidy.refundPercentage'), [
                            'cancellation',
                            'refundPercentage',
                        ])}
                        precision={0}
                        step={25}
                        max={100}
                        min={0}
                    />
                    <TypeaheadInput
                        {...manageFieldPropFactory(['cancellation', 'refundTransaction'], 'onChangeValue', 'value')}
                        {...textFieldPropFactory(t('EventResponseDetail.CancellationAndSubsidy.refundTransaction'), [
                            'cancellation',
                            'refundTransaction',
                        ])}
                        options={
                            eventResponse?.cancellation?.refundTransaction
                                ? [
                                      {
                                          key: eventResponse?.cancellation?.refundTransaction,
                                          value: eventResponse?.cancellation?.refundTransactionName,
                                      },
                                  ]
                                : []
                        }
                    />
                    <NumberInput
                        {...manageFieldPropFactory(['subsidy', 'subsidyAmount'], 'onChangeValue', 'value')}
                        {...textFieldPropFactory(t('EventResponseDetail.CancellationAndSubsidy.subsidyAmount'), [
                            'subsidy',
                            'subsidyAmount',
                        ])}
                        leftIcon={'$'}
                        precision={2}
                        step={0.5}
                        min={0}
                        isDisabled={true}
                        isLocked={true}
                    />
                    <NumberInput
                        {...manageFieldPropFactory(['subsidy', 'maxSubsidyAmount'], 'onChangeValue', 'value')}
                        {...textFieldPropFactory(t('EventResponseDetail.CancellationAndSubsidy.maxSubsidyAmount'), [
                            'subsidy',
                            'maxSubsidyAmount',
                        ])}
                        leftIcon={'$'}
                        precision={2}
                        step={0.5}
                        min={0}
                        isDisabled={true}
                        isLocked={true}
                    />
                </Grid>
                <Box pt={4}>
                    <Textarea
                        {...textAreaPropFactory(t('EventResponseDetail.CancellationAndSubsidy.reason'), [
                            'cancellation',
                            'reason',
                        ])}
                        {...manageFieldPropFactory<TextareaPropFactory>(
                            ['cancellation', 'reason'],
                            'onChangeText',
                            'value'
                        )}
                        col={1}
                    />
                </Box>
            </Box>
        );
    }
);

export default CancellationAndSubsidy;
