import { Question } from '@Redux/services/Event/types';
import { TFunction } from 'react-i18next';
import { isBoolean } from 'lodash';
import type { PartialDeep } from 'type-fest';

export const Validator = (question: PartialDeep<Question>, t: TFunction) => {
    return [
        {
            accessorPath: ['title'],
            validator: Boolean,
            message: t('QuestionForm.titleRequired'),
        },
        {
            accessorPath: ['text'],
            validator: Boolean,
            message: t('QuestionForm.textRequired'),
        },
        {
            accessorPath: ['isRequired'],
            validator: isBoolean,
            message: t('QuestionForm.requiredMessage'),
        },
        {
            accessorPath: ['yesNoTypeOfQuestion'],
            validator: isBoolean,
            message: t('QuestionForm.yesNoQuestionRequired'),
        },
    ];
};
