import { TFunction } from 'react-i18next';
import { Row } from 'react-table';

const tableData = (t: TFunction, onEditHandler: (row: Row) => void, onDeleteHandler: (row: Row) => void) => {
    const header = [
        { Header: t('StudentList.name'), accessor: 'fullName', headerAlign: 'left' },
        {
            Header: t('StudentList.legalFirstName'),
            accessor: 'legalFirstName',
            headerAlign: 'left',
        },
        { Header: t('StudentList.legalLastName'), accessor: 'legalLastName', headerAlign: 'left' },
        { Header: t('StudentList.age'), accessor: 'age', headerAlign: 'right' },
        { Header: t('StudentList.gender'), accessor: 'gender', headerAlign: 'left' },
        {
            Header: t('StudentList.homePhone'),
            accessor: 'homePhone',
            headerAlign: 'left',
        },
        { Header: t('StudentList.email'), accessor: 'emailAddress', headerAlign: 'left' },
    ];

    return header;
};

export { tableData };
