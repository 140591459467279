import * as React from 'react';
function SvgSchedule(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M10.825 9.335V5.85a.8.8 0 00-.236-.588.791.791 0 00-.585-.237.806.806 0 00-.589.237.793.793 0 00-.24.588v3.823a.82.82 0 00.07.344c.047.102.11.193.19.272l2.708 2.709a.768.768 0 00.584.247.857.857 0 00.586-.265.798.798 0 00.26-.586.821.821 0 00-.267-.596l-2.481-2.463zM10 18.17a7.963 7.963 0 01-3.178-.637 8.241 8.241 0 01-2.6-1.754 8.232 8.232 0 01-1.754-2.603 7.993 7.993 0 01-.637-3.185c0-1.13.212-2.193.637-3.185a8.106 8.106 0 011.754-2.593 8.32 8.32 0 012.603-1.744 7.992 7.992 0 013.184-.637c1.132 0 2.194.214 3.186.642a8.235 8.235 0 012.589 1.745 8.243 8.243 0 011.743 2.592A7.97 7.97 0 0118.17 10a7.963 7.963 0 01-.637 3.178 8.331 8.331 0 01-1.744 2.6A8.097 8.097 0 0110 18.169zm.01-1.729c1.782 0 3.299-.629 4.551-1.886 1.253-1.258 1.88-2.779 1.88-4.564 0-1.785-.627-3.303-1.88-4.554-1.252-1.25-2.77-1.876-4.551-1.876-1.782 0-3.302.625-4.561 1.876-1.26 1.251-1.89 2.769-1.89 4.554 0 1.785.63 3.306 1.89 4.564 1.259 1.257 2.78 1.886 4.56 1.886z'
                fill='#171923'
            />
        </svg>
    );
}
export default SvgSchedule;
