import * as React from 'react';
function SvgAcademicHat(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 22 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M4.116 14.9a2.256 2.256 0 01-1.2-2.02V8.248L.648 7a1.105 1.105 0 01-.455-.426 1.147 1.147 0 01-.142-.568c0-.208.048-.399.143-.573.095-.174.247-.317.454-.429L8.918.501a2.148 2.148 0 011.083-.28 2.151 2.151 0 011.08.28l9.496 5.182c.189.096.336.235.44.417.104.182.156.376.156.584V13c0 .309-.104.568-.313.776a1.055 1.055 0 01-.776.314c-.308 0-.567-.105-.779-.314a1.047 1.047 0 01-.317-.776V7.202l-1.904 1.046v4.632a2.256 2.256 0 01-1.2 2.02l-4.809 2.587a2.151 2.151 0 01-1.075.28 2.153 2.153 0 01-1.076-.28L4.116 14.9zM10 9.58L16.629 6l-6.63-3.58L3.372 6 10 9.58zm0 6.02l4.898-2.647V9.405L11.101 11.5c-.17.095-.349.166-.535.211a2.37 2.37 0 01-1.132 0A2.116 2.116 0 018.9 11.5L5.102 9.405v3.548L10 15.6z'
                fill='#171923'
            />
        </svg>
    );
}
export default SvgAcademicHat;
