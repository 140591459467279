import { useMemo } from 'react';
import { Box, HStack, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { NavigationItem } from '@Components/Header';

type SubHeaderProps = {
    navigation?: NavigationItem;
};

const SubHeader: React.FC<SubHeaderProps> = ({ navigation }) => {
    const location = useLocation();
    const subNavUrl = useMemo(() => location.pathname.split('/')[2], [location.pathname]);
    const { t } = useTranslation();

    return (
        navigation && (
            <Box bg={'surface-primary'} px={8} py={3} display={{ base: 'none', md: 'flex' }}>
                <HStack as={'nav'} gap={4}>
                    {navigation.subNavigations.map((subNavigation, i) => {
                        const url = `${navigation.href}/${subNavigation.href}`;
                        return (
                            <Link to={url} key={`subheader-link-${i}`}>
                                <Button
                                    variant='ghost'
                                    key={i}
                                    id={subNavigation.href}
                                    isActive={subNavigation.subPath === subNavUrl}
                                    isDisabled={subNavigation.disabled}
                                >
                                    {t(subNavigation.label)}
                                </Button>
                            </Link>
                        );
                    })}
                </HStack>
            </Box>
        )
    );
};

export { SubHeader };
