import type { PartialDeep } from 'type-fest';
import { Event } from '@Redux/services/Event/types';
import { Dispatch, SetStateAction } from 'react';
import { FormComponentProps } from '@Hooks/useForms';
import { Accessor } from '@Utilities';
import { PagedResult, Activity, Note } from '@Redux/services/commonTypes';
import { EventStatus } from 'src/constants';
import { BasicLookup } from '@Redux/services/LookupApi/types';

export interface StepProps extends FormComponentProps {
    eventDetails?: PartialDeep<Event>;
    setEventDetails?: Dispatch<SetStateAction<PartialDeep<Event>>>;
    onSubmit?: () => void;
    setDirtyData?: Dispatch<SetStateAction<PartialDeep<Event>>>;
    setDirtyAccessor?: Dispatch<SetStateAction<Accessor[]>>;
    dirtyData?: PartialDeep<Event>;
    dirtyAccessors?: Accessor[];
    handleManualSave?: () => void;
    organizedBy?: BasicLookup[];
    categories?: BasicLookup[];
}

export interface NotesProps extends FormComponentProps {
    notes: Note[];
    activities?: Activity[];
    notePagination?: PagedResult;
    isNotesLoading?: boolean;
    hideActivities?: boolean;
    hidePagination?: boolean;
    onAddNote?: (noteText: string) => Promise<void>;
    onDeleteNote?: (annotationId: string) => Promise<void>;
    onUpdateNote?: (noteText: string, annotationId: string) => Promise<void>;
    onNextPage?: (currentPage: number, pageSize: number) => Promise<void>;
    isNoteEditable?: boolean;
    hideFormHeader?: boolean;
    defaultPageSize?: number;
    hideNoteAction?: boolean;
}

export const EventStatuses = {
    [EventStatus.Proposed]: 'Propose',
    [EventStatus.Validated]: 'Validate',
    [EventStatus.Published]: 'Publish',
    [EventStatus.Closed]: 'Close',
    [EventStatus.Cancelled]: 'Cancel',
};

export const Proposed = [EventStatus.Proposed];
export const Validated = [EventStatus.Validated];
export const Published = [EventStatus.Published];
export const ProposedAndValidated = [...Proposed, ...Validated];
export const ProposedValidatedAndPublished = [...Proposed, ...Validated, ...Published];

export const editableFieldsByStatus = {
    name: {
        required: true,
        editableStatus: ProposedAndValidated,
        validateSave: true,
    },
    teamId: {
        required: true,
        editableStatus: ProposedAndValidated,
        validateSave: true,
    },
    organizedById: {
        required: true,
        editableStatus: ProposedAndValidated,
        validateSave: true,
    },
    organizingTeachers: {
        required: false,
        editableStatus: ProposedValidatedAndPublished,
        validateSave: false,
    },
    volunteers: {
        required: false,
        editableStatus: ProposedValidatedAndPublished,
        validateSave: false,
    },
    readyToPublish: {
        required: true,
        editableStatus: Validated,
        validateSave: true,
    },
    readyToClose: {
        required: true,
        editableStatus: Published,
        validateSave: true,
    },
    offerDescription: {
        required: false,
        editableStatus: ProposedAndValidated,
        validateSave: false,
    },
    'activity.activityNameId': {
        required: true,
        editableStatus: ProposedAndValidated,
        validateSave: false,
    },
    activityTypeId: {
        required: false,
        editableStatus: [],
        validateSave: true,
    },
    'activity.activityNamePossibleInjuries': {
        required: true,
        editableStatus: [],
        validateSave: false,
    },
    'activity.modeOfTransportId': {
        required: true,
        editableStatus: ProposedAndValidated,
        validateSave: false,
    },
    'activity.modeOfTransportPossibleInjuries': {
        required: true,
        editableStatus: [],
        validateSave: false,
    },
    'activity.location': {
        required: true,
        editableStatus: ProposedAndValidated,
        validateSave: false,
    },
    'activity.teacherInCharge': {
        required: true,
        editableStatus: ProposedAndValidated,
        validateSave: false,
    },
    'activity.emergencyContact': {
        required: false,
        editableStatus: ProposedAndValidated,
        validateSave: false,
    },
    'activity.fieldTripType': {
        required: false,
        editableStatus: ProposedAndValidated,
        validateSave: false,
    },
    'activity.isApproved': {
        required: true,
        editableStatus: ProposedAndValidated,
        validateSave: false,
    },
    'activity.approvedOnDate': {
        required: true,
        editableStatus: ProposedAndValidated,
        validateSave: false,
    },
    followUpDate: {
        required: false,
        editableStatus: ProposedAndValidated,
        validateSave: false,
    },
    dueDate: {
        required: true,
        editableStatus: ProposedAndValidated,
        validateSave: false,
    },
    startDate: {
        required: true,
        editableStatus: ProposedAndValidated,
        validateSave: false,
    },
    endDate: {
        required: true,
        editableStatus: ProposedAndValidated,
        validateSave: false,
    },
    formsDistributedOnDate: {
        required: false,
        editableStatus: ProposedValidatedAndPublished,
        validateSave: false,
    },
    'participants.targetType': {
        required: true,
        editableStatus: Proposed,
        validateSave: false,
    },
    'participants.schoolValidation': {
        required: false,
        editableStatus: ProposedValidatedAndPublished,
        validateSave: false,
    },
    'participants.invitedGrade': {
        required: true,
        editableStatus: Proposed,
        validateSave: false,
    },
    'participants.invitedClass': {
        required: true,
        editableStatus: Proposed,
        validateSave: false,
    },
    'participants.listId': {
        required: true,
        editableStatus: Proposed,
    },
    categoryId: {
        required: true,
        editableStatus: ProposedAndValidated,
        validateSave: false,
    },
    subCategoryId: {
        required: true,
        editableStatus: ProposedAndValidated,
        validateSave: false,
    },
    isRefundable: {
        required: false,
        editableStatus: ProposedAndValidated,
        validateSave: false,
    },
};
