import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Button, Flex } from '@chakra-ui/react';
import AvatarLabel from '@Components/AvatarLabel';
import Sidebar from '@Components/Sidebar';
import Steps from '@Components/Steps';

import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Status from './components/Status';
import { EVENT_RESPONSE_STATUSES } from './types';
import SvgSummary from '@Icon/Summary';
import SvgCostItems from '@Icon/CostItems';

import {
    EVENT_RESPONSE_CANCELLED,
    EVENT_RESPONSE_CANCELLED_AND_CLOSED,
    EVENT_RESPONSE_CONFIRMED,
    EVENT_RESPONSE_DRAFT,
} from 'src/constants';
import { Menu, MenuItem } from '@Components/Menu';
import { Cycle, KebabMenu, ReactIcon, ScanDelete } from '@Icon';
import { useEventResponse } from '@Hooks/useEventResponse';
import { DetailPageSkeleton } from '@Components/DetailPageSkeleton';
import { Prompt } from '@Components/Prompt';
import { useParams } from 'react-router-dom';
import EventResponseDetailForm from '@Components/EventResponseDetailDrawer/components/EventResponseDetail';
import { AlertDialog } from '@Components/AlertDialog';

type IRouteParams = {
    eventId: string;
    id: string;
};

const EventResponsesPage = () => {
    const { t } = useTranslation();

    const params = useParams<IRouteParams>();

    const eventResponseObject = useEventResponse({ id: params.id });

    const {
        eventResponse,
        showCancelAlert,
        showResetAlert,
        alertProps,
        containerRef,
        handleActionClick,
        handleCloseAlert,
        handleManualSave,
        handleNext,
        handleStatusUpdate,
        steps,
        stepsProps,
        isClosedOrCanceled,
        hasPendingChange,
        isSaving,
        isEventPublished,
        isResponseDraftOrPending,
        getNextStatus,
        isLoading,
        isDirty,
        setIsDirty,
    } = eventResponseObject;

    const actionMenuItems = useMemo<MenuItem[]>(
        () => [
            {
                label: t('EventResponses.reset'),
                menuItemProps: {
                    icon: <ReactIcon width={'16px'} height={'16px'} />,
                    onClick: handleActionClick(false),
                    isDisabled: eventResponse?.status !== EVENT_RESPONSE_DRAFT || !isEventPublished,
                },
            },
            {
                label: t('EventResponses.cancel'),
                menuItemProps: {
                    icon: <ScanDelete width={'16px'} height={'16px'} />,
                    onClick: handleActionClick(true),
                    isDisabled: eventResponse?.status !== EVENT_RESPONSE_CONFIRMED || !isEventPublished,
                },
            },
        ],
        [eventResponse, isEventPublished]
    );

    const sideBarItems = useMemo(
        () => ({
            header: (
                <AvatarLabel
                    name={eventResponse?.detail?.fullName}
                    subTitle={t('EventResponseDetail.sidebarSubtitle')}
                />
            ),
            groups: [
                {
                    items: [
                        {
                            label: t('EventResponseDetail.summary'),
                            id: '#summary',
                            icon: <SvgSummary />,
                            active: false,
                        },
                        {
                            label: t('EventResponseDetail.cancellationAndSubsidy'),
                            id: '#cancellation-and-subsidy',
                            icon: <SvgCostItems />,
                            active: false,
                        },
                    ],
                },
            ],
        }),
        [eventResponse]
    );

    if (isLoading || !eventResponse) {
        return <DetailPageSkeleton />;
    }
    return (
        eventResponse && (
            <>
                <AlertDialog
                    isOpen={showCancelAlert || showResetAlert}
                    submitHandle={() => handleStatusUpdate(showCancelAlert)}
                    alertType='error'
                    onClose={handleCloseAlert}
                    submitButtonProps={{
                        variant: 'error',
                    }}
                    cancelButtonProps={{
                        variant: 'outline',
                        onClick: handleCloseAlert,
                    }}
                    {...alertProps}
                />

                <Flex>
                    <Sidebar
                        {...sideBarItems}
                        footerDetails={{
                            modifiedByFullName: eventResponse?.modifiedByFullName,
                            modifiedOn: eventResponse?.modifiedOn,
                            disabled: !hasPendingChange,
                            createdOn: eventResponse?.createdOn,
                            createdByFullName: eventResponse?.createdByFullName,
                            onSave: !isClosedOrCanceled ? handleManualSave : null,
                        }}
                    />

                    <Flex ref={containerRef} overflow={'hidden'} width='100%'>
                        <form style={{ width: '100%' }}>
                            <Box pt={8} maxWidth='100%'>
                                <Box pr={4}>
                                    <Flex justifyContent='space-between' direction={{ base: 'column', md: 'row' }}>
                                        <Steps
                                            steps={steps}
                                            disabled={[
                                                EVENT_RESPONSE_CANCELLED,
                                                EVENT_RESPONSE_CANCELLED_AND_CLOSED,
                                            ].includes(eventResponse?.status)}
                                        />
                                        <Box>
                                            <Button
                                                onClick={handleNext}
                                                textStyle={'md-semibold'}
                                                variant={isSaving ? 'brand-inverted' : 'primary'}
                                                rightIcon={
                                                    isSaving ? (
                                                        <Cycle className='spin-loading' width='20px' height='20px' />
                                                    ) : (
                                                        <ArrowForwardIcon width='20px' height='20px' />
                                                    )
                                                }
                                                isDisabled={
                                                    hasPendingChange ||
                                                    isClosedOrCanceled ||
                                                    !isResponseDraftOrPending ||
                                                    !isEventPublished
                                                }
                                                mr={2}
                                            >
                                                <Trans
                                                    i18nKey={
                                                        isSaving
                                                            ? 'EventResponses.nextStepLoading'
                                                            : 'EventResponses.nextStep'
                                                    }
                                                    values={{
                                                        seperator: isResponseDraftOrPending ? ':' : '',
                                                        nextStep:
                                                            getNextStatus(eventResponse?.status) !== 0
                                                                ? EVENT_RESPONSE_STATUSES[
                                                                      getNextStatus(eventResponse?.status)
                                                                  ]
                                                                : '',
                                                    }}
                                                />
                                            </Button>
                                            <Menu
                                                items={actionMenuItems}
                                                buttonIcon={<KebabMenu width={24} height={24} />}
                                            />
                                        </Box>
                                    </Flex>
                                    <Status
                                        status={EVENT_RESPONSE_STATUSES[eventResponse.status]}
                                        textFieldPropFactory={stepsProps.textFieldPropFactory}
                                        manageFieldPropFactory={stepsProps.manageFieldPropFactory}
                                        eventId={eventResponse.event.eventId}
                                        eventName={eventResponse.event.name}
                                        isCancelled={[
                                            EVENT_RESPONSE_CANCELLED,
                                            EVENT_RESPONSE_CANCELLED_AND_CLOSED,
                                        ].includes(eventResponse?.status)}
                                    />
                                </Box>
                                <EventResponseDetailForm {...eventResponseObject} />
                                {/* <Stack bg='surface-secondary' p={3} mt={5} spacing={8} borderLeftRadius={15}>
                                    <Details id='summary' {...stepsProps} />
                                    <Payment id='payment' {...stepsProps} />
                                    <CostItemTable
                                        id='cost-items'
                                        eventResponse={eventResponse as EventResponseDetail}
                                        setEventResponse={stepsProps.setEventResponse}
                                        isEditable={isEventPublished && isResponseDraftOrPending}
                                    />
                                    <QuestionsTable
                                        id='answers'
                                        {...stepsProps}
                                        setEventResponse={stepsProps.setEventResponse}
                                        eventResponse={eventResponse as EventResponseDetail}
                                        isEditable={isEventPublished && isResponseDraftOrPending}
                                    />
                                    <Notes id='notes' {...stepsProps} />
                                    <CancellationAndSubsidy id='cancellation-and-subsidy' {...stepsProps} />
                                </Stack> */}
                            </Box>
                        </form>
                    </Flex>
                </Flex>
                {isDirty && (
                    <Prompt
                        title={t('EventResponsesPage.title')}
                        message={t('EventResponsesPage.message')}
                        submitLabel={t('EventResponsesPage.leave')}
                        cancelLabel={t('EventResponsesPage.cancelButtonLabel')}
                        dirty={isDirty}
                        onLeave={() => setIsDirty(false)}
                    />
                )}
            </>
        )
    );
};

export default EventResponsesPage;
