import React, { useEffect, useRef, useState } from 'react';
import { Box, Flex, HStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';

import Table from '@Components/Table';
import { Search } from '@Components/Search';
import StudentsSidebar from '@Components/StudentsSidebar';
import { Pagination } from '@Components';
import { parseError } from '@Utilities';
import { tableData } from './components/TableHeader';
import { useGetParticipantsMutation } from '@Redux/services/Student';
import { useDebounce } from '@Hooks';
import { useToast } from '@Hooks/useToast';
import { DEFAULT_PAGE_SIZE } from 'src/constants';

export type SortByProps = { id: string; desc: boolean };

const getGender = (g: number) => {
    if (g === 1) {
        return 'Male';
    } else if (g == 2) {
        return 'Female';
    } else {
        return 'Non-Binary';
    }
};

const Students: React.FC = () => {
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

    const { t } = useTranslation();

    const toast = useToast();

    const [getParticipants, { isLoading, data: studentsList }] = useGetParticipantsMutation();

    const { data: d = [], pagedResult } = studentsList || { data: [], pagedResult: {} };

    const data = d.map((d) => ({ ...d, gender: getGender(d.gender) }));

    const mainCotainerRef = useRef<HTMLDivElement>(null);

    const onEditHandler = (row) => {
        // setSelectedRow(row.original);
        navigate(`/records/student/${row.original.contactId}`);
    };

    const onDeleteHandler = () => {
        // setSelectedRow(row.original);
    };

    const headers = tableData(t, onEditHandler, onDeleteHandler);

    const navigate = useNavigate();

    const columns = headers as Column[];

    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [sortBy, setSortBy] = useState<SortByProps>({ id: 'fullName', desc: false });

    const [initialized, setInitialized] = useState(false);

    const getStudents = (sortBy, currentPage, pageSize, searchQuery) => {
        const obj = {
            sortBy: sortBy?.id,
            isDesc: sortBy?.desc,
            currentPage: currentPage,
            pageSize: pageSize,
            searchText: searchQuery,
        };
        getParticipants(obj)
            .unwrap()
            .catch((err) => {
                parseError(toast, err);
            });
    };

    const debouncedResults = useDebounce(() => getStudents(sortBy, currentPage, pageSize, searchQuery));

    useEffect(() => {
        debouncedResults();
    }, [searchQuery]);

    useEffect(() => {
        if (!initialized) return;
        getStudents(sortBy, currentPage, pageSize, searchQuery);
    }, [currentPage, sortBy, pageSize]);

    useEffect(() => {
        setTimeout(() => {
            setInitialized(true);
        }, 500);
    }, []);

    const onSortChange = (sortBy) => {
        if (sortBy[0]) {
            setSortBy(sortBy[0]);
        }
    };

    // Resetting pagination and filtering when searching
    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const handlePageSizeChange = (newPageSize: number) => {
        setCurrentPage(1);
        setPageSize(newPageSize);
    };
    return (
        <Flex id='st' align={'stretch'} h='inherit'>
            <StudentsSidebar activeItem='student-listview' />
            <Flex ref={mainCotainerRef} py={0} pl={2} pr={2} overflowX={'hidden'} gap={4} w={'full'} h='inherit'>
                <Flex
                    overflowX={'hidden'}
                    borderRadius='20px 0 0 0'
                    borderWidth='2px 2px 2px 2px'
                    borderStyle='solid'
                    borderColor='border-primary'
                    height={'full'}
                    w={'full'}
                    align={'stretch'}
                    direction={'column'}
                >
                    <Box>
                        <Flex justify={'space-between'} align={'center'} p={6}>
                            <HStack spacing={5}>
                                <Box maxW={224} minW={224}>
                                    <Search query={searchQuery} onChange={handleSearch} />
                                </Box>
                            </HStack>
                        </Flex>
                    </Box>

                    <Flex position='relative' flex='auto'>
                        <Table
                            manualPagination={true}
                            manualSortBy={true}
                            columns={columns}
                            rawData={isLoading ? [] : data || []}
                            emptyMessage={t('StudentList.noData')}
                            isLoading={isLoading}
                            onSortChange={onSortChange}
                            onEditHandler={onEditHandler}
                            containerStyle={{ borderLeftWidth: 0, borderRightWidth: 0, borderRadius: 0 }}
                            stickyHeader
                        />
                    </Flex>

                    {!pagedResult?.pageCount ? null : (
                        <Box p={2}>
                            <Pagination
                                currentPage={currentPage}
                                onPageChange={setCurrentPage}
                                pageSize={pageSize}
                                onPageSizeChange={handlePageSizeChange}
                                totalPages={pagedResult?.pageCount}
                                totalResults={pagedResult?.rowCount}
                            />
                        </Box>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};

export { Students };
