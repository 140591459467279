import { Button, Flex, Spacer, Stack, Text, useDisclosure, useToast } from '@chakra-ui/react';
import AvatarLabel from '@Components/AvatarLabel';
import Sidebar from '@Components/Sidebar';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SvgSummary from '@Icon/Summary';
import SvgQuestions from '@Icon/Questions';
import SvgResponses from '@Icon/Responses';
import { DetailPageSkeleton } from '@Components/DetailPageSkeleton';
import { parseError, parseWarning } from '@Utilities';
import {
    jobs_processing_status,
    ReportingPeriodDetails,
    ReportingPeriodNotes,
    UploadedJob,
} from '@Redux/services/Reports/types';
import { useParams } from 'react-router';
import {
    useCreateReportingPeriodNoteMutation,
    useGetReportingPeriodNotesMutation,
    useGetSchoolReportingPeriodJobsMutation,
    useGetSchoolReportingPeriodMutation,
} from '@Redux/services/Reports';
import { Summary } from './components/Summary';
import { Students } from './components/Students';
import { UploadJobs } from './components/UploadJobs';
import { Upload } from './components/Upload';
import Notes from '@Components/Notes';
import { Note, PagedResult } from '@Redux/services/commonTypes';
import SvgNotes from '@Icon/Notes';
import Modal from '@Components/Modal';
import { useNavigate } from 'react-router-dom';

type IRouteParams = {
    id: string;
    schoolId: string;
    schoolYearId: string;
};

const ReportPeriodDetails = () => {
    const { t } = useTranslation();
    const containerRef = useRef<HTMLDivElement>(null);
    const params = useParams<IRouteParams>();
    const toast = useToast();
    const navigate = useNavigate();

    const [reportPeriodDetails, setReportPeriodDetails] = useState<ReportingPeriodDetails>();
    const [notReadyError, setNotReadyError] = useState<string>();
    const [notes, setNotes] = useState<ReportingPeriodNotes>(undefined);
    const [notesPageNumber, setNotesPageNumber] = useState<number>(1);
    const [notesPageSize, setNotesPageSize] = useState<number>(5);
    const [newJobRunning, setNewJobRunning] = useState<boolean>(false);
    const [latestJob, setLatestJob] = useState<UploadedJob>();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [getSchoolReportingPeriod, { isLoading }] = useGetSchoolReportingPeriodMutation();
    const [getReportingPeriodNotes, { isLoading: isNotesLoading }] = useGetReportingPeriodNotesMutation();
    const [getUploadJobs] = useGetSchoolReportingPeriodJobsMutation();
    const [createReportingPeriodNote] = useCreateReportingPeriodNoteMutation();

    const handleNotReady = () => {
        onClose();
        navigate('/records/reports-cards');
    };

    const handleNotePageChange = async (pageNumber: number, pageSize: number) => {
        await getReportingPeriodNotes({
            reportingPeriodId: params.id,
            schoolId: params.schoolId,
            schoolYearId: params.schoolYearId,
            pageSize,
            currentPage: pageNumber,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                setNotesPageNumber(pageNumber);
                setNotesPageSize(pageSize);
                setNotes({ reportingPeriodNotesInfo: response.data, pagedResult: response.pagedResult });
            })
            .catch((e) => parseError(toast, e));
    };

    const handleAddNote = async (noteText: string) => {
        await createReportingPeriodNote({
            reportingPeriodId: params.id,
            schoolId: params.schoolId,
            schoolYearId: params.schoolYearId,
            noteText,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                setNotes({ reportingPeriodNotesInfo: response.data, pagedResult: response.pagedResult });
            })
            .catch((e) => parseError(toast, e));
    };

    const getSchoolReportingPeriodData = async () => {
        if (!params.id) return;
        await getSchoolReportingPeriod({
            reportingPeriodId: params.id,
            schoolId: params.schoolId,
            schoolYearId: params.schoolYearId,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                setReportPeriodDetails(response.data);
                if (!notes)
                    setNotes({
                        reportingPeriodNotesInfo: response.data?.notes?.reportingPeriodNotesInfo,
                        pagedResult: response.data?.notes?.pagedResult,
                    });
                setLatestJob(response.data.latestJob);
                setNewJobRunning(
                    response.data.latestJob.jobProcessingStatus !== jobs_processing_status.Completed &&
                        response.data.latestJob.jobProcessingStatus !== jobs_processing_status.Failed
                );
            })
            .catch((error) => {
                if (error.data?.errors?.studentSnapshotCreationDate) {
                    setNotReadyError(error.data?.errors?.studentSnapshotCreationDate);
                    onOpen();
                } else parseError(toast, error);
            });
    };

    const refreshPage = () => {
        getSchoolReportingPeriodData();
        handleNotePageChange(notesPageNumber, notesPageSize);
    };

    const loadLatestJob = async () => {
        await getUploadJobs({
            currentPage: 1,
            pageSize: 1,
            sortBy: 'createdOn',
            isDesc: true,
            reportingPeriodId: params.id,
            schoolId: params.schoolId,
            schoolYearId: params.schoolYearId,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                setNewJobRunning(
                    response.data[0]?.jobId !== latestJob?.jobId ||
                        (response.data[0]?.jobProcessingStatus !== jobs_processing_status.Completed &&
                            response.data[0]?.jobProcessingStatus !== jobs_processing_status.Failed)
                );
                if (JSON.stringify(response.data[0]) !== JSON.stringify(latestJob)) setLatestJob(response.data[0]);
            })
            .catch((err) => parseError(toast, err));
    };

    useEffect(() => {
        refreshPage();
    }, [params.id]);

    useEffect(() => {
        const interval = setInterval(loadLatestJob, 10000);
        return () => clearInterval(interval);
    }, [latestJob]);

    useEffect(() => {
        if (!newJobRunning) refreshPage();
    }, [newJobRunning]);

    const sideBarItems = useMemo(
        () => ({
            header: <AvatarLabel name={reportPeriodDetails?.reportingPeriod} subTitle={reportPeriodDetails?.school} />,
            groups: [
                {
                    items: [
                        {
                            label: t('ReportPeriod.reportingPeriod'),
                            id: '#summary',
                            icon: <SvgSummary />,
                            active: false,
                        },
                        {
                            label: t('ReportPeriod.files'),
                            id: '#uploadJobs',
                            icon: <SvgResponses />,
                            active: false,
                        },
                        {
                            label: t('ReportPeriod.students'),
                            id: '#students',
                            icon: <SvgQuestions />,
                            active: false,
                        },
                        {
                            label: t('ReportPeriod.notes'),
                            id: '#notesContainer',
                            icon: <SvgNotes />,
                            active: false,
                        },
                    ],
                },
            ],
        }),
        [reportPeriodDetails]
    );

    return (
        <>
            {!reportPeriodDetails && isLoading && (
                <>
                    {isOpen && (
                        <Modal
                            noCloseButton
                            isCentered
                            isOpen={isOpen}
                            onClose={handleNotReady}
                            footer={
                                <Flex w='100%' bg={'white'} gap={4} justifyContent='space-between'>
                                    <Spacer />
                                    <Button variant={'primary'} onClick={handleNotReady}>
                                        {t('ReportPeriod.confirmNotReady')}
                                    </Button>
                                </Flex>
                            }
                        >
                            <Text mt={4} mx={1} textStyle={'md-bold'} color='secondary'>
                                {notReadyError}
                            </Text>
                        </Modal>
                    )}
                    <DetailPageSkeleton />
                </>
            )}
            {reportPeriodDetails && (
                <Flex>
                    <Sidebar
                        {...sideBarItems}
                        defaultCollapse={true}
                        footerDetails={{
                            createdOn: reportPeriodDetails.createdOn,
                            createdByFullName: reportPeriodDetails.createdByFullName,
                            modifiedByFullName: reportPeriodDetails.modifiedByFullName,
                            modifiedOn: reportPeriodDetails.modifiedOn,
                        }}
                    />

                    <Flex ref={containerRef} overflow={'hidden'} width='100%'>
                        <Stack
                            bg='surface-secondary'
                            p={3}
                            mt={2}
                            spacing={8}
                            borderLeftRadius={15}
                            borderWidth={'1px'}
                            borderColor={'border-primary'}
                            w={'full'}
                        >
                            <Summary id='summary' details={reportPeriodDetails} />
                            <Upload
                                reportingPeriodId={params.id}
                                schoolReportingDayId={reportPeriodDetails.schoolReportingDayId}
                                pendingReports={reportPeriodDetails.pendingReports}
                                school={reportPeriodDetails.school}
                                latestJob={latestJob}
                                onRefresh={getSchoolReportingPeriodData}
                            />
                            <UploadJobs
                                id='uploadJobs'
                                uploads={reportPeriodDetails.uploads}
                                reportingPeriodId={params.id}
                                schoolYearId={params.schoolYearId}
                                schoolId={params.schoolId}
                                latestJob={latestJob}
                            />
                            <Students
                                onRefresh={getSchoolReportingPeriodData}
                                id='students'
                                details={reportPeriodDetails}
                            />
                            <section id='notesContainer'>
                                <Notes
                                    id={'notes'}
                                    notes={notes?.reportingPeriodNotesInfo as Note[]}
                                    onAddNote={async (noteText: string) => {
                                        handleAddNote(noteText);
                                    }}
                                    notePagination={notes?.pagedResult as PagedResult}
                                    isNotesLoading={isNotesLoading && !notes}
                                    onNextPage={handleNotePageChange}
                                    isNoteEditable={false}
                                    hideActivities={true}
                                    defaultPageSize={notes?.pagedResult.pageSize}
                                    hideNoteAction={true}
                                />
                            </section>
                        </Stack>
                    </Flex>
                </Flex>
            )}
        </>
    );
};

export default ReportPeriodDetails;
