import { FC, ReactNode, useRef, useState } from 'react';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import NavButton from './components/NavButton';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { Footer } from './components/Footer';
import { useNavigate } from 'react-router-dom';

interface Footer {
    createdOn?: string;
    createdByFullName?: string;
    modifiedOn?: string;
    modifiedByFullName?: string;
    cancelledOn?: string;
    closedOn?: string;
    onSave?: () => void;
    disabled?: boolean;
}

export interface SidebarItem {
    label: ReactNode;
    id: string;
    icon?: ReactNode;
    active: boolean;
}

interface GroupItem {
    title?: string;
    items: SidebarItem[];
}
export interface SidebarProps {
    header?: ReactNode;
    groups?: GroupItem[];
    footerDetails?: Footer;
    defaultCollapse?: boolean;
}

const Sidebar: FC<SidebarProps> = ({ header, groups, footerDetails, defaultCollapse }) => {
    const [collapse, setCollapse] = useState(defaultCollapse);
    const [showCollapseButton, setShowCollapseButtonollapse] = useState(false);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const Icon = collapse ? ChevronRightIcon : ChevronLeftIcon;

    function getFooter() {
        // Assumes that cancelled and closed event can no longer be modified, hence safe to use modified name
        if (footerDetails?.cancelledOn) {
            return {
                title: t('Sidebar.cancelled'),
                name: footerDetails?.modifiedByFullName,
                date: footerDetails?.cancelledOn && new Date(footerDetails.cancelledOn),
            };
        } else if (footerDetails?.closedOn) {
            return {
                title: t('Sidebar.closed'),
                name: footerDetails?.modifiedByFullName,
                date: footerDetails?.closedOn && new Date(footerDetails.closedOn),
            };
        } else {
            return {
                title: t('Sidebar.lastModified'),
                name: footerDetails?.modifiedByFullName,
                date: footerDetails?.modifiedOn && new Date(footerDetails.modifiedOn),
            };
        }
    }

    const footerOptions = [
        getFooter(),
        {
            title: t('Sidebar.createdOn'),
            name: footerDetails?.createdByFullName,
            date: footerDetails?.createdOn && new Date(footerDetails.createdOn),
        },
    ];

    const handleCollapse = () => {
        setCollapse(!collapse);
    };

    const boxRef = useRef<HTMLDivElement>();
    const bcr = boxRef.current?.getBoundingClientRect();

    return (
        <Box width={!collapse ? { base: '210px', lg: '320px' } : '32px'} flexGrow={0} flexShrink={0}>
            <Flex
                bg='surface-primary'
                as='section'
                minW='32px'
                position={'fixed'}
                left='0'
                height={`calc(100% - ${bcr?.top || 0}px)`}
                zIndex={10}
                width={!collapse ? { base: '210px', lg: '320px' } : '32px'}
                ref={boxRef}
                onMouseEnter={() => setShowCollapseButtonollapse(true)}
                onMouseLeave={() => setShowCollapseButtonollapse(false)}
            >
                <Flex
                    flex='1'
                    bg='bg.surface'
                    maxW={{ base: 'full', sm: 'xs' }}
                    p={3}
                    position='relative'
                    w={!collapse ? '100%' : 2}
                >
                    {(collapse || showCollapseButton) && (
                        <Box
                            background='surface-primary'
                            position='absolute'
                            flex='1'
                            right={0}
                            top='15%'
                            bg={!collapse ? 'text-brand-primary' : 'border-primary'}
                            _hover={{ bg: 'text-brand-primary' }}
                            borderRadius={4}
                            w={6}
                            h={6}
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            cursor={'pointer'}
                            zIndex={10}
                        >
                            <Icon color='surface-primary' w={6} h={6} onClick={handleCollapse} />
                        </Box>
                    )}
                    {!collapse && (
                        <Stack justify='space-between' spacing='1' width='full' overflowY='auto' h='100%' mb='140px'>
                            <Stack spacing='4' shouldWrapChildren id='red' h={'full'}>
                                {header && (
                                    <Box bg='surface-primary' mt={4} ml={2}>
                                        {header}
                                    </Box>
                                )}
                                <Box>
                                    {groups?.map((group, i) => (
                                        <Stack key={i}>
                                            {group.title && (
                                                <Text textStyle='sm' color='fg.subtle' fontWeight='medium'>
                                                    {group.title}
                                                </Text>
                                            )}
                                            <Stack spacing='1' mx={2}>
                                                {group.items.map((item, idx) => (
                                                    <NavButton
                                                        key={idx}
                                                        id={item.id}
                                                        active={item.active}
                                                        icon={item.icon}
                                                        label={item.label}
                                                        onItemClick={() => navigate(item.id)}
                                                    />
                                                ))}
                                            </Stack>
                                        </Stack>
                                    ))}
                                </Box>

                                <Footer
                                    footerOptions={footerOptions}
                                    onSave={footerDetails?.onSave}
                                    disabled={footerDetails?.disabled}
                                />
                            </Stack>
                        </Stack>
                    )}
                </Flex>
            </Flex>
        </Box>
    );
};

export default Sidebar;
