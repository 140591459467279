import { Box, Flex, Text } from '@chakra-ui/layout';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { DATE_TIME_FORMAT } from 'src/constants';

type StatusOption = {
    name?: string;
    date?: Date;
};

const RescheduleStatus: React.FC<StatusOption> = ({ name, date }) => {
    const { t } = useTranslation();
    if (!(name || date)) {
        return <></>;
    }
    return (
        <Box sx={{ display: 'block' }} bg='surface-secondary' zIndex='100' w={'100%'} px={6} py={2}>
            <Flex direction='row' rowGap={2}>
                {(name || date) && (
                    <Box width={'100%'}>
                        <Text textStyle={'sm-bold'}>{t('RescheduleForm.statusCreatedOn')}</Text>
                        <Text textStyle={'sm-medium'}>{name || '-'}</Text>
                        <Text textStyle={'sm-medium'}>{date ? format(date, DATE_TIME_FORMAT) : '-'}</Text>
                    </Box>
                )}
            </Flex>
        </Box>
    );
};

export default RescheduleStatus;
