import { Box, Button, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Header from '../Summary/components/Header';
import React, { useMemo, useState } from 'react';
import { ProposedAndValidated, StepProps } from '@Pages/EventDetails/types';
import Table, { Column } from '@Components/Table';
import BooleanCell from '@Components/BooleanCell';
import ActionCell from './components/ActionCell';
import TableActions from '@Components/TableActions';
import SvgQuestions from '@Icon/Questions';

import { Question, ShuffleQuestion } from '@Redux/services/Event/types';
import { DropResult } from 'react-beautiful-dnd';
import { cloneDeep } from 'lodash';
import { useShuffleOrderQuestionMutation } from '@Redux/services/Event';
import { useToast } from '@Hooks/useToast';
import { move, parseError } from '@Utilities';
import { EventStatus } from 'src/constants';
import QuestionForm from '@Components/QuestionForm';

const Questions = React.forwardRef<HTMLDivElement, StepProps>(({ id, eventDetails, setEventDetails }, ref) => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedQuestion, setSelectedQuestion] = useState<Question>();
    const [shuffleOrderQuestion, { isLoading }] = useShuffleOrderQuestionMutation();
    const toast = useToast();

    const isEditable = useMemo(() => ProposedAndValidated.includes(eventDetails.status), [eventDetails]);
    const columns = useMemo<Column[]>(() => {
        const columns: Column[] = [
            {
                Header: t('Questions.title'),
                accessor: 'title',
                disableSortBy: true,
                headerAlign: 'left',
            },
            {
                Header: t('Questions.text'),
                accessor: 'text',
                disableSortBy: true,
                headerAlign: 'left',
            },
            {
                Header: t('Questions.required'),
                accessor: 'isRequired',
                disableSortBy: true,
                Cell: BooleanCell,
                headerAlign: 'center',
            },
            {
                Header: t('Questions.yesNoQuestion'),
                accessor: 'yesNoTypeOfQuestion',
                disableSortBy: true,
                Cell: BooleanCell,
                headerAlign: 'center',
            },
            {
                Header: t('Questions.commentRequiredIfYes'),
                accessor: 'isCommentRequired',
                disableSortBy: true,
                Cell: BooleanCell,
                headerAlign: 'center',
            },
            {
                Header: t('Questions.commentRequiredIfNo'),
                accessor: 'isCommentRequiredNo',
                disableSortBy: true,
                Cell: BooleanCell,
                headerAlign: 'center',
            },
            {
                Header: <>&nbsp;</>,
                accessor: 'actions',
                disableSortBy: true,
                Cell: ActionCell,
            },
        ];

        return columns;
    }, []);

    const handleClose = () => {
        setSelectedQuestion(undefined);
        onClose();
    };

    const handleEditClick = (question: Question) => {
        setSelectedQuestion(question);
        onOpen();
    };

    const handleSubmit = (questions: Question[]) => {
        setEventDetails({
            ...eventDetails,
            eventQuestions: {
                questions: questions,
            },
        });
    };

    const handleDragEnd = async (result: DropResult) => {
        if (eventDetails.eventQuestions.questions.length <= 1 || result.source.index === result.destination.index)
            return;
        const questions = cloneDeep(eventDetails.eventQuestions.questions);
        move(questions, result?.source?.index, result?.destination?.index);
        const body: ShuffleQuestion[] = [];
        for (let i = 0; i < questions.length; i++) {
            questions[i].displayOrder = i + 1;
            body.push({
                eventQuestionId: questions[i].eventQuestionId,
                displayOrder: i + 1,
            });
        }
        await shuffleOrderQuestion({ orderList: body })
            .unwrap()
            .then(() => {
                setEventDetails({
                    ...eventDetails,
                    eventQuestions: { questions },
                });
                toast({
                    title: t('Questions.toastShuffelSuccessTitle'),
                    description: t('Questions.toastShuffelSuccessDescription'),
                    status: 'success',
                });
            })
            .catch((error) => {
                parseError(toast, error);
            });
    };

    return (
        <Box id={id} ref={ref} mt={8}>
            <Header divider={true} mb={4} title={t('Header.questions')} icon={<SvgQuestions />} />

            <Table
                action={<TableActions onAddClick={isEditable && onOpen} />}
                onDragEnd={handleDragEnd}
                manualSortBy={false}
                isDragDisabled={![EventStatus.Proposed, EventStatus.Validated].includes(eventDetails?.status)}
                emptyMessage={t('Questions.emptyTable')}
                rawData={eventDetails?.eventQuestions?.questions}
                columns={columns}
                isLoading={isLoading}
                userProps={{
                    onEdit: handleEditClick,
                    onSubmit: handleSubmit,
                    isEditable: isEditable,
                }}
                addRowButton={isEditable && <Button onClick={onOpen}>{t('Questions.addLabel')}</Button>}
            />
            {isOpen && (
                <QuestionForm
                    questionId={selectedQuestion?.eventQuestionId}
                    isOpen={isOpen}
                    onClose={handleClose}
                    onCancel={handleClose}
                    eventId={eventDetails.eventId}
                    isEditable={isEditable}
                    onSubmit={(questions: Question[]) => {
                        handleSubmit(questions);
                        handleClose();
                    }}
                />
            )}
        </Box>
    );
});

export default Questions;
