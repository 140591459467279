import {
    Select as ChakraSelect,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    FormControl,
    FormHelperText,
    FormLabel,
    SelectProps,
    FormControlProps,
    Box,
} from '@chakra-ui/react';
import { Lock } from '@Icon';
import { BasicLookup } from '@Redux/services/LookupApi/types';
import { isNull, isNumber, isUndefined } from 'lodash';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface SelectInputProps extends SelectProps {
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    label?: string;
    error?: string;
    options?: BasicLookup[];
    stacked?: boolean;
    isLocked?: boolean;
    onChangeValue?: (value: string | number) => void;
    col?: number;
    defaultOption?: boolean;
    defaultValue?: string | number;
    disabledOptions?: (string | number)[];
    readOnlyOptions?: (string | number)[];
    formProps?: FormControlProps;
    defaultOptionLabel?: string;
}

const SelectInput: FC<SelectInputProps> = ({
    label,
    error,
    isLocked,
    rightIcon,
    leftIcon,
    options,
    stacked,
    onChangeValue,
    value,
    col,
    defaultOption = true,
    defaultOptionLabel,
    defaultValue = null,
    disabledOptions = [],
    readOnlyOptions = [],
    formProps,
    ...rest
}) => {
    const { t } = useTranslation();

    const iconStyle = !isLocked
        ? {
              border: '1px solid',
              borderColor: 'gray.300',
              borderRightRadius: 5,
          }
        : {};

    const isKeyNumeric = useMemo(() => options?.length > 0 && isNumber(options[0].key), [options]);

    const newValue = useMemo(() => {
        if (!isNull(value) && !isUndefined(value)) {
            return value;
        } else if (!isUndefined(defaultValue) && !isNull(defaultValue)) {
            return defaultValue;
        }
        return '';
    }, [value, defaultValue]);

    return (
        <FormControl
            isInvalid={rest.isInvalid}
            display={{ base: 'block', lg: 'flex' }}
            alignItems={stacked ? 'start' : 'baseline'}
            flexDirection={stacked ? 'column' : 'row'}
            {...formProps}
        >
            {label && (
                <FormLabel
                    color={(rest.isDisabled || isLocked) && 'text-disabled'}
                    flexBasis={col === 1 ? '12.5%' : '30%'}
                    textStyle={'sm-medium'}
                >
                    {label}
                    {rest.isRequired && '*'}
                </FormLabel>
            )}
            <InputGroup>
                {leftIcon && <InputLeftElement>{leftIcon}</InputLeftElement>}
                <Box w='100%'>
                    <ChakraSelect
                        onChange={(event) =>
                            onChangeValue(isKeyNumeric ? parseInt(event.target.value, 10) : event.target.value)
                        }
                        disabled={isLocked || rest.isDisabled}
                        _disabled={{
                            color: 'text-disabled',
                        }}
                        w='100%'
                        placeholder={''}
                        value={newValue}
                        title={(rest.isRequired && t('InputFields.requiredTitle')) || ''}
                        _focusVisible={{ borderColor: 'surface-brand-primary' }}
                        {...rest}
                    >
                        {defaultOption && (
                            <option key={'defaultOption'} value={!isNull(defaultValue) ? defaultValue : ''}>
                                {defaultOptionLabel ? defaultOptionLabel : t('SelectInput.defaultOption')}
                            </option>
                        )}
                        {options?.map((option) => (
                            <option
                                hidden={readOnlyOptions.includes(option.key)}
                                value={option.key}
                                key={option.key}
                                disabled={disabledOptions.includes(option.key)}
                            >
                                {option.value}
                            </option>
                        ))}
                    </ChakraSelect>
                    {error && (
                        <FormHelperText p={2} textStyle='sm-normal' color={rest.isInvalid && 'text-error'}>
                            {error}
                        </FormHelperText>
                    )}
                </Box>
                {(rightIcon || isLocked) && (
                    <InputRightElement {...iconStyle}>{isLocked ? <Lock /> : rightIcon}</InputRightElement>
                )}
            </InputGroup>
        </FormControl>
    );
};

export default SelectInput;
