import { Button } from '@Components';
import { useToast } from '@Hooks/useToast';
import { useDeleteSchoolReportingPeriodStudentMutation } from '@Redux/services/Reports';
import { StudentRecord } from '@Redux/services/Reports/types';
import { parseError } from '@Utilities';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { report_status } from 'src/constants';

interface IProps extends CellProps<object> {
    onDelete: () => void;
}

export const RemoveStudentCell: React.FC<IProps> = ({ row, onDelete, schoolId, schoolYearId, reportingPeriodId }) => {
    const { t } = useTranslation();
    const toast = useToast();
    const [deleteSchoolReportingPeriodStudentMutation, { isLoading: isDeleting }] =
        useDeleteSchoolReportingPeriodStudentMutation();

    const handleRemoveStudentFromSnapshot = async () => {
        await deleteSchoolReportingPeriodStudentMutation({
            schoolId: schoolId,
            reportingPeriodId: reportingPeriodId,
            reportingStudentId: (row.original as StudentRecord).reportingStudentId,
            schoolYearId: schoolYearId,
        })
            .unwrap()
            .then(() => {
                toast({ status: 'success', title: t('StudentTable.removeStudentToastSuccess') });
                onDelete();
            })
            .catch((error) => {
                parseError(toast, error);
            });
    };
    if ((row.original as StudentRecord).reportStatus === report_status.Published) {
        return null;
    }
    return (
        <Button disabled={isDeleting} onClick={handleRemoveStudentFromSnapshot}>
            <Trans i18nKey='RemoveStudentCell.removeStudent' />
        </Button>
    );
};

export default RemoveStudentCell;
