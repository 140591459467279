import * as React from 'react';
function SvgQuestions(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M5.35 14.419a7.151 7.151 0 01-1.84-2.448 7.06 7.06 0 01-.66-2.992c0-1.98.698-3.663 2.091-5.05 1.394-1.385 3.087-2.078 5.078-2.078 1.549 0 2.94.417 4.175 1.25 1.235.835 2.052 1.921 2.45 3.26l1.386 4.69c.079.28.034.533-.134.759a.809.809 0 01-.684.34h-1.063v2.27c0 .48-.168.887-.505 1.224a1.667 1.667 0 01-1.224.505h-1.77v1.136a.837.837 0 01-.25.614.829.829 0 01-.61.25.843.843 0 01-.616-.25.83.83 0 01-.254-.614v-2c0-.242.084-.447.25-.614a.835.835 0 01.615-.25h2.635v-3.136c0-.242.084-.447.25-.614a.835.835 0 01.615-.25h.782L15.01 6.915c-.306-.977-.92-1.777-1.845-2.4-.924-.624-1.972-.936-3.143-.936-1.52 0-2.807.524-3.86 1.573C5.107 6.202 4.58 7.477 4.58 8.979c0 .805.17 1.573.513 2.303.342.73.823 1.352 1.441 1.867l.546.483v3.653a.837.837 0 01-.25.614.829.829 0 01-.61.25.843.843 0 01-.616-.25.83.83 0 01-.253-.614v-2.866zm4.6-1.26a.8.8 0 00.61-.245.853.853 0 00.232-.608.842.842 0 00-.234-.607.806.806 0 00-.609-.243c-.25 0-.452.08-.607.241a.83.83 0 00-.233.6c0 .243.077.448.233.614a.796.796 0 00.608.248zm.037-6.914c.287 0 .525.09.713.27.19.178.284.399.284.66 0 .184-.05.35-.152.496-.1.146-.344.398-.73.757a3.024 3.024 0 00-.562.688 2.045 2.045 0 00-.223.793c-.028.165.026.314.16.448a.66.66 0 00.483.201c.158 0 .304-.06.438-.182a.794.794 0 00.253-.451c.031-.195.103-.375.217-.54.114-.166.294-.359.542-.58.3-.264.525-.536.676-.813.151-.278.226-.575.226-.893 0-.588-.226-1.084-.68-1.49C11.178 5.203 10.63 5 9.992 5c-.442 0-.843.092-1.202.276-.359.184-.64.442-.845.772a.64.64 0 00-.092.543c.052.19.163.325.333.404.16.073.315.083.466.03a.973.973 0 00.416-.304c.117-.145.255-.26.412-.347.157-.086.326-.129.507-.129z'
                fill='#171923'
            />
        </svg>
    );
}
export default SvgQuestions;
