import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import React from 'react';
import ReactIcon from '@Icon/ReactIcon';
import Header from '@Pages/EventDetails/components/Summary/components/Header';
import type { StudentStepProps } from '@Pages/StudentDetails/types';
import FormHeader from '@Components/FormHeader';
import TextInput from '@Components/TextInput';
import TypeaheadInput from '@Components/TypeaheadInput';
import DatePicker from '@Components/DateTimeInput';
import { SearchIcon } from '@chakra-ui/icons';
import Textarea from '@Components/Textarea';
import { useGetGenderTypesQuery, useGetInvitedGradesQuery, useGetSchoolsQuery } from '@Redux/services/LookupApi';
import SelectInput from '@Components/SelectInput';
import { ManageDatePickerProps, ManageTextProps, TextareaPropFactory } from '@Hooks';

const StudentDetails: React.FC<StudentStepProps> = ({
    id,
    manageFieldPropFactory,
    textFieldPropFactory,
    typeaheadPropFactory,
    textAreaPropFactory,
    studentDetail,
    selectFieldPropFactory,
}) => {
    const { t } = useTranslation();

    const { data: { data: gradeOptions = [] } = {} } = useGetInvitedGradesQuery();
    const { data: { data: genderOptions = [] } = {} } = useGetGenderTypesQuery();

    const { data: { data: schoolOptions = [] } = {} } = useGetSchoolsQuery({});

    return (
        <Box id={id} w={'full'}>
            <Header divider={true} mb={4} title={t('StudentDetails')} icon={<ReactIcon />} />
            <Flex direction={'column'} gap={4}>
                <FormHeader id={id} label={t('StudentDetails.general')}>
                    <Flex direction={'column'} gap={4}>
                        <Grid
                            columnGap={10}
                            gridAutoFlow='dense'
                            templateColumns={{
                                base: 'repeat(1, 1fr)',
                                md: 'repeat(3, 1fr)',
                            }}
                            rowGap={4}
                            w={'full'}
                            mb={4}
                        >
                            <GridItem>
                                <SelectInput
                                    {...selectFieldPropFactory(t('StudentDetails.gender'), ['gender'])}
                                    {...manageFieldPropFactory<ManageTextProps>(['gender'], 'onChangeValue', 'value')}
                                    options={genderOptions}
                                    stacked
                                />
                            </GridItem>

                            <GridItem>
                                <DatePicker
                                    {...textFieldPropFactory(t('StudentDetails.birthday'), ['birthDate'])}
                                    {...manageFieldPropFactory<ManageDatePickerProps>(
                                        ['birthDate'],
                                        'onDateChange',
                                        'date'
                                    )}
                                    size='md'
                                    stacked
                                />
                            </GridItem>

                            <GridItem>
                                <TextInput
                                    {...textFieldPropFactory(t('StudentDetails.age'), ['age'])}
                                    {...manageFieldPropFactory<ManageTextProps>(['age'], 'onChangeText', 'value')}
                                    stacked
                                />
                            </GridItem>
                        </Grid>
                        <Grid
                            columnGap={10}
                            gridAutoFlow='dense'
                            templateColumns={{
                                base: 'repeat(1, 1fr)',
                                md: 'repeat(2, 1fr)',
                            }}
                            rowGap={4}
                            w={'full'}
                            mb={4}
                        >
                            <GridItem>
                                <SelectInput
                                    {...selectFieldPropFactory(t('StudentDetails.grade'), ['grade'])}
                                    {...manageFieldPropFactory<ManageTextProps>(['grade'], 'onChangeValue', 'value')}
                                    options={gradeOptions}
                                    stacked
                                    value={studentDetail?.grade}
                                />
                            </GridItem>

                            <GridItem>
                                <TypeaheadInput
                                    {...typeaheadPropFactory(t('StudentDetails.homeSchool'), ['ownerId'])}
                                    {...manageFieldPropFactory<ManageTextProps>(['ownerId'], 'onSelection', 'value')}
                                    options={schoolOptions}
                                    stacked
                                />
                            </GridItem>
                        </Grid>
                        <Grid
                            columnGap={10}
                            gridAutoFlow='dense'
                            templateColumns={{
                                base: 'repeat(1, 1fr)',
                                md: 'repeat(2, 1fr)',
                            }}
                            rowGap={4}
                            w={'full'}
                            mb={4}
                        >
                            <GridItem>
                                <TextInput
                                    {...textFieldPropFactory(t('StudentDetails.homeRoom'), ['homeRoom'])}
                                    {...manageFieldPropFactory<ManageTextProps>(['homeRoom'], 'onChangeText', 'value')}
                                    stacked
                                />
                            </GridItem>

                            <GridItem>
                                <TextInput
                                    {...manageFieldPropFactory<ManageTextProps>(
                                        ['homeRoomTeacher'],
                                        'onChangeText',
                                        'value'
                                    )}
                                    {...textFieldPropFactory(t('StudentDetails.homeRoomTeacher'), ['homeRoomTeacher'])}
                                    stacked
                                />
                            </GridItem>
                        </Grid>
                        <Grid
                            columnGap={10}
                            gridAutoFlow='dense'
                            templateColumns={{
                                base: 'repeat(1, 1fr)',
                                md: '0.3fr repeat(2, 1fr)',
                            }}
                            rowGap={4}
                            w={'full'}
                            alignItems={'center'}
                        >
                            <GridItem>
                                <Text textStyle={'sm-bold'}>{t('StudentDetails.crossEnrolledSchool')}</Text>
                            </GridItem>
                            <GridItem>
                                <TextInput
                                    maxWidth='100%'
                                    placeholder={'--'}
                                    isLocked={false}
                                    bg='surface-primary'
                                    rightIcon={
                                        <Flex
                                            borderLeft={'2px solid var(--chakra-colors-lineColor)'}
                                            height={'100%'}
                                            align={'center'}
                                        >
                                            <Box p={4}>
                                                <SearchIcon />
                                            </Box>
                                        </Flex>
                                    }
                                    onChangeText={() => {
                                        console.log();
                                    }}
                                ></TextInput>
                            </GridItem>

                            <GridItem>
                                <TextInput
                                    maxWidth='100%'
                                    placeholder={'--'}
                                    isLocked={false}
                                    bg='surface-primary'
                                    rightIcon={
                                        <Flex
                                            borderLeft={'2px solid var(--chakra-colors-lineColor)'}
                                            height={'100%'}
                                            align={'center'}
                                        >
                                            <Box p={4}>
                                                <SearchIcon />
                                            </Box>
                                        </Flex>
                                    }
                                    onChangeText={() => {
                                        console.log();
                                    }}
                                ></TextInput>
                            </GridItem>
                        </Grid>
                    </Flex>
                </FormHeader>
                <FormHeader id={id} label={t('StudentDetails.medical')}>
                    <Grid
                        columnGap={10}
                        gridAutoFlow='dense'
                        templateColumns={{
                            base: 'repeat(1, 1fr)',
                            md: 'repeat(2, 1fr)',
                        }}
                        rowGap={4}
                        w={'full'}
                        mb={4}
                    >
                        <GridItem>
                            <TextInput
                                {...textFieldPropFactory(t('StudentDetails.familyDoctorName'), ['doctorName'])}
                                {...manageFieldPropFactory<ManageTextProps>(['doctorName'], 'onChangeText', 'value')}
                                stacked
                            />
                        </GridItem>

                        <GridItem>
                            <TextInput
                                {...manageFieldPropFactory<ManageTextProps>(['doctorPhone'], 'onChangeText', 'value')}
                                {...textFieldPropFactory(t('StudentDetails.familyDoctorPhone'), ['doctorPhone'])}
                                stacked
                            />
                        </GridItem>
                    </Grid>
                    <Box pb={4}>
                        <Textarea
                            {...textAreaPropFactory(t('StudentDetails.lifeThreateningHealthAlerts'), ['healthAlert'])}
                            {...manageFieldPropFactory<TextareaPropFactory>(['healthAlert'], 'onChangeText', 'value')}
                        />
                    </Box>
                    <Box pb={4}>
                        <Textarea
                            {...textAreaPropFactory(t('StudentDetails.nonLifeThreateningMedicalDetails'), [
                                'noThreatMedDetails',
                            ])}
                            {...manageFieldPropFactory<TextareaPropFactory>(
                                ['noThreatMedDetails'],
                                'onChangeText',
                                'value'
                            )}
                        />
                    </Box>

                    <Box pb={4}>
                        <Textarea
                            {...textAreaPropFactory(t('StudentDetails.allergies'), ['allergies'])}
                            {...manageFieldPropFactory<TextareaPropFactory>(['allergies'], 'onChangeText', 'value')}
                        />
                    </Box>
                </FormHeader>
            </Flex>
        </Box>
    );
};

export default StudentDetails;
