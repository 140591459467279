import { Flex } from '@chakra-ui/react';

type SectionHeader = {
    icon?: JSX.Element;
    title: string;
};

export default function SectionHeader(props: SectionHeader) {
    const { icon, title } = props;

    return (
        <Flex align={'center'} gap={2}>
            {icon} {title}
        </Flex>
    );
}
