import Table, { Column } from '@Components/Table';
import { useEffect, useMemo, useState } from 'react';
import { CellProps } from 'react-table';
import { useTranslation } from 'react-i18next';
import { Button, Pagination } from '@Components';
import { Box, Checkbox, HStack } from '@chakra-ui/react';
import { Plus } from '@Icon';
import { SortByProps } from '@Pages/Students';
import Drawer from '@Components/Drawer';
import { Search } from '@Components/Search';
import { useToast } from '@Hooks/useToast';
import { createLookupMap, parseError } from '@Utilities';
import { IStudentListRecord } from '@Redux/services/Student/types';
import { useGetInvitedGradesQuery, useGetSchoolsQuery } from '@Redux/services/LookupApi';
import FilterTextInput from '@Components/FilterTextInput';
import FilterSelectInput from '@Components/FilterSelectInput';
import { PagedResult } from '@Redux/services/commonTypes';
import { DEFAULT_PAGE_SIZE } from 'src/constants';
import {
    useCreateSchoolReportingMissingStudentRecordsMutation,
    useGetSchoolReportingPeriodMissingStudentsMutation,
} from '@Redux/services/Reports';
import { SchoolReportingPeriodMissingStudentsData } from '@Redux/services/Reports/types';
import { FullNameCell } from './FullNameCell';

type Props = {
    onOpen: () => void;
    onClose: () => void;
    onAddStudents: () => void;
    isOpen: boolean;
    reportingPeriod: string;
    schoolName: string;
    schoolYear: string;
    schoolYearId: string;
    schoolId: string;
    reportingPeriodId: string;
};

const defaultSortBy = { id: 'fullName', desc: false };

const AddStudents: React.FC<Props> = ({
    isOpen,
    onClose,
    onAddStudents,
    reportingPeriod,
    schoolName,
    schoolYear,
    schoolId,
    schoolYearId,
    reportingPeriodId,
}) => {
    const { t } = useTranslation();

    const toast = useToast();
    const [students, setStudents] = useState<SchoolReportingPeriodMissingStudentsData[]>([]);
    const [getSchoolReportingPeriodMissingStudents, { isLoading: isStudentsLoading }] =
        useGetSchoolReportingPeriodMissingStudentsMutation();

    const [CreateSchoolReportingMissingStudent, { isLoading: isAddingStudents }] =
        useCreateSchoolReportingMissingStudentRecordsMutation();

    const { data: { data: invitedGrades = [] } = {} } = useGetInvitedGradesQuery();
    const { data: { data: schools = [] } = {} } = useGetSchoolsQuery({});
    const schoolsMap = useMemo(() => createLookupMap(schools), [schools]);

    const [grade, setGrade] = useState<string>();
    const [homeRoom, setHomeRoom] = useState<string>();
    const [homeRoomTeacher, setHomeRoomTeacher] = useState<string>();
    const [searchQuery, setSearchQuery] = useState<string>();
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
    const [pagedResult, setPagedResult] = useState<PagedResult>({
        currentPage: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        pageCount: 0,
        rowCount: 0,
    });
    const [sortBy, setSortBy] = useState<SortByProps>(defaultSortBy);

    const [selectedRows, setSelectedRows] = useState<string[]>([]);

    const handleSelectAll = (table, isChecked: boolean) => {
        if (isChecked) {
            setSelectedRows(table.data.map((r) => r.contactId));
        } else {
            setSelectedRows([]);
        }
    };

    const handleRowSelection = (dataRow, isChecked: boolean) => {
        let newSelectedRows = [...selectedRows];
        newSelectedRows = isChecked
            ? [...newSelectedRows, dataRow.row.original.contactId]
            : newSelectedRows.filter((r) => r !== dataRow.row.original.contactId);
        setSelectedRows(newSelectedRows);
    };

    const getStudents = async (
        newGrade?: string,
        newHomeRoomTeacher?: string,
        newHomeRoom?: string,
        searchText?: string,
        newSortBy?: string,
        newIsDesc?: boolean,
        currentPage?: number,
        pageSize?: number
    ) => {
        await getSchoolReportingPeriodMissingStudents({
            schoolYearId: schoolYearId,
            schoolId: schoolId,
            reportingPeriodId: reportingPeriodId,
            grade: parseInt(newGrade, 10),
            homeRoomTeacher: newHomeRoomTeacher,
            homeRoom: newHomeRoom,
            searchText,
            sortBy: newSortBy,
            isDesc: newIsDesc,
            currentPage,
            pageSize,
        })
            .unwrap()
            .then((response) => {
                setStudents(response.data);
                setPagedResult(response.pagedResult);
            })
            .catch((error) => {
                parseError(toast, error);
            });
    };

    useEffect(() => {
        if (isOpen) {
            getStudents(grade, homeRoomTeacher, homeRoom, searchQuery);
        }
    }, [isOpen]);

    const columns = useMemo<Column[]>(
        () =>
            [
                {
                    Header: (table) => {
                        return (
                            <Checkbox
                                colorScheme={'teal'}
                                isChecked={
                                    table?.data?.length > 0
                                        ? table.data.every((r) => selectedRows.includes(r.contactId))
                                        : false
                                }
                                onChange={(e) => handleSelectAll(table, e.target.checked)}
                            />
                        );
                    },
                    accessor: 'actions',
                    disableSortBy: true,
                    Cell: (row: CellProps<IStudentListRecord>) => {
                        return (
                            <Checkbox
                                colorScheme={'teal'}
                                isChecked={selectedRows.includes(row.row.original.contactId)}
                                onChange={(e) => handleRowSelection(row, e.target.checked)}
                            />
                        );
                    },
                },
                {
                    Header: t('AddStudents.preferredFullName'),
                    accessor: 'fullName',
                    Cell: FullNameCell,
                    headerAlign: 'left',
                },
                {
                    Header: t('AddStudents.oen'),
                    accessor: 'oen',
                    headerAlign: 'left',
                },
                {
                    Header: t('AddStudents.age'),
                    accessor: 'age',
                    headerAlign: 'left',
                },
                {
                    Header: t('AddStudents.schoolName'),
                    accessor: 'teamId',
                    Cell: (r) => <>{schoolsMap[r.value]?.value || ''}</>,
                    headerAlign: 'left',
                },
                {
                    Header: t('AddStudents.grade'),
                    accessor: 'currentGrade',
                    headerAlign: 'left',
                    Cell: (r) => <>{invitedGrades.find((g) => g.key === r.value)?.value || ''}</>,
                },
                {
                    Header: t('AddStudents.homeRoom'),
                    accessor: 'homeRoom',
                    headerAlign: 'left',
                },
                {
                    Header: t('AddStudents.homeRoomTeacher'),
                    accessor: 'homeTeacher',
                    headerAlign: 'left',
                },
            ] as Column[],
        [selectedRows, invitedGrades, schoolsMap]
    );

    const handleSubmit = async () => {
        await CreateSchoolReportingMissingStudent({
            contacts: selectedRows,
            schoolYearId: schoolYearId,
            schoolId: schoolId,
            reportingPeriodId: reportingPeriodId,
        })
            .unwrap()
            .then(() => {
                toast({
                    status: 'success',
                    description: t('AddStudents.createStudentSuccess'),
                    isClosable: true,
                });

                setSelectedRows([]);
                onAddStudents();
                onClose();
            })
            .catch((e) => {
                parseError(toast, e);
            });
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagedResult({ ...pagedResult, pageSize: pageSize, currentPage: 1 });
        getStudents(grade, homeRoomTeacher, homeRoom, searchQuery, sortBy.id, sortBy.desc, 1, pageSize);
    };
    const handlePageChange = (pageNumber: number) => {
        setPagedResult({ ...pagedResult, currentPage: pageNumber });
        getStudents(
            grade,
            homeRoomTeacher,
            homeRoom,
            searchQuery,
            sortBy.id,
            sortBy.desc,
            pageNumber,
            pagedResult.pageSize
        );
    };

    const handleSort = (newSortBy) => {
        if (newSortBy && newSortBy.length == 0) return;
        setSortBy(newSortBy);
        getStudents(
            grade,
            homeRoomTeacher,
            homeRoom,
            searchQuery,
            newSortBy[0].id,
            newSortBy[0].desc,
            1,
            pagedResult.pageSize
        );
    };
    const loading = isStudentsLoading || isAddingStudents;

    const handleSearch = (
        searchText: string = searchQuery,
        newGrade: string = grade,
        newHomeRoom: string = homeRoom,
        newHomeRoomTeacher: string = homeRoomTeacher
    ) => {
        clearTimeout(timeoutId);
        setPagedResult({ ...pagedResult, currentPage: 1 });
        setTimeoutId(
            setTimeout(
                () =>
                    getStudents(
                        newGrade,
                        newHomeRoomTeacher,
                        newHomeRoom,
                        searchText,
                        sortBy.id,
                        sortBy.desc,
                        1,
                        pagedResult.pageSize
                    ),
                500
            )
        );
    };
    return (
        <>
            <Drawer
                size={'xl'}
                isOpen={isOpen}
                onClose={onClose}
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSubmit();
                }}
                isForm={true}
                title={`${reportingPeriod} - ${schoolName} - ${schoolYear}`}
                subtitle={t('AddStudents.subTitle')}
                footer={
                    <HStack>
                        {selectedRows?.length > 0 && (
                            <Button isDisabled={loading} type='submit' leftIcon={<Plus />} variant='primary'>
                                {t('AddStudents.addSelected', { count: selectedRows?.length })}
                            </Button>
                        )}
                        <Button variant='light' isDisabled={loading} onClick={onClose}>
                            {t('AddStudents.closeDrawerLabel')}
                        </Button>
                    </HStack>
                }
            >
                <Box
                    overflow={'hidden'}
                    borderRadius={10}
                    borderWidth={2}
                    borderStyle='solid'
                    borderColor='border-primary'
                    p={4}
                    h='inherit'
                    display='flex'
                    flexDirection='column'
                >
                    <Box
                        display='flex'
                        flexDirection='row'
                        flexWrap='wrap'
                        rowGap={2}
                        columnGap={4}
                        justifyContent='space-between'
                        mb={5}
                    >
                        <Search
                            size={'md'}
                            query={searchQuery}
                            onChange={(e) => {
                                setSearchQuery(e.target.value);
                                handleSearch(e.target.value, grade, homeRoom, homeRoomTeacher);
                            }}
                            iconPosition={'left'}
                            width={'200px'}
                        />
                        <FilterSelectInput
                            placeholder={t('AddStudents.Filters.grade')}
                            options={invitedGrades}
                            value={grade}
                            onChangeValue={(e) => {
                                setGrade(e.toString());
                                handleSearch(searchQuery, e.toString(), homeRoom, homeRoomTeacher);
                            }}
                            defaultOption={false}
                        />

                        <FilterTextInput
                            placeholder={t('AddStudents.Filters.homeRoom')}
                            value={homeRoom}
                            onChangeText={(value) => {
                                setHomeRoom(value);
                                handleSearch(searchQuery, grade, value, homeRoomTeacher);
                            }}
                            isLocked={false}
                        />
                        <FilterTextInput
                            placeholder={t('AddStudents.Filters.homeRoomTeacher')}
                            value={homeRoomTeacher}
                            onChangeText={(value) => {
                                setHomeRoomTeacher(value);
                                handleSearch(searchQuery, grade, homeRoom, value);
                            }}
                            isLocked={false}
                        />
                    </Box>
                    <Table
                        manualPagination={true}
                        manualSortBy={true}
                        enableRowSelection
                        columns={columns}
                        rawData={students ?? []}
                        isLoading={isStudentsLoading}
                        emptyMessage={t('AddStudents.emptyTable')}
                        onSortChange={handleSort}
                        stickyHeader
                        stickyColumns={2}
                    />
                    {!pagedResult?.pageCount ? null : (
                        <Box p={2}>
                            <Pagination
                                currentPage={pagedResult?.currentPage}
                                onPageChange={handlePageChange}
                                pageSize={pagedResult?.pageSize}
                                onPageSizeChange={handlePageSizeChange}
                                totalPages={pagedResult?.pageCount}
                                totalResults={pagedResult?.rowCount}
                            />
                        </Box>
                    )}
                </Box>
            </Drawer>
        </>
    );
};

export default AddStudents;
