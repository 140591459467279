import { baseApi } from '../base';
import { BaseResponse } from '../commonTypes';
import { IStudentListRecord, IStudentParams, IStudentRecord, IStudentReqParams } from './types';

export const studentsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        GetParticipants: builder.mutation<BaseResponse<IStudentListRecord[]>, IStudentReqParams>({
            query: (body) => ({
                body,
                url: 'Participants/GetParticipants',
                method: 'POST',
            }),
        }),
        GetParticipantDetails: builder.query<BaseResponse<IStudentRecord>, IStudentParams>({
            query: (data) => ({
                url: `Participants/GetParticipant/${data.id}`,
                method: 'GET',
            }),
        }),
    }),
});

export const { useGetParticipantsMutation, useGetParticipantDetailsQuery } = studentsApi;
