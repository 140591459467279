import * as React from 'react';
function SvgHistory(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g clipPath='url(#history_svg__clip0_1923_10242)'>
                <mask
                    id='history_svg__a'
                    style={{
                        maskType: 'alpha',
                    }}
                    maskUnits='userSpaceOnUse'
                    x={0}
                    y={0}
                    width={24}
                    height={24}
                >
                    <path fill='#D9D9D9' d='M0 0h24v24H0z' />
                </mask>
                <g mask='url(#history_svg__a)'>
                    <path
                        d='M12.034 21.115c-2.128 0-4.014-.641-5.658-1.924-1.645-1.283-2.723-2.924-3.234-4.923a.817.817 0 01.168-.775c.19-.234.445-.372.765-.413.302-.041.579.014.83.165.25.151.423.374.518.669.42 1.452 1.24 2.636 2.46 3.552 1.22.916 2.604 1.374 4.151 1.374 1.93 0 3.568-.672 4.912-2.016 1.344-1.345 2.017-2.982 2.017-4.912 0-1.93-.673-3.567-2.017-4.912-1.344-1.344-2.982-2.016-4.912-2.016-1.102 0-2.137.25-3.105.749a7.193 7.193 0 00-2.46 2.077h1.535c.296 0 .544.101.746.303.201.201.302.45.302.745s-.1.545-.302.749c-.202.203-.45.305-.746.305H4.028c-.319 0-.588-.11-.808-.33-.22-.219-.33-.488-.33-.807v-3.97c0-.296.102-.543.303-.743.202-.2.45-.3.746-.3.295 0 .542.1.742.3.2.2.3.447.3.742v1.219a8.929 8.929 0 013.148-2.451 9.1 9.1 0 013.905-.863c1.274 0 2.47.242 3.588.727a9.34 9.34 0 012.918 1.97 9.341 9.341 0 011.97 2.919 8.914 8.914 0 01.728 3.587c0 1.274-.243 2.47-.728 3.587a9.342 9.342 0 01-1.97 2.919 9.338 9.338 0 01-2.918 1.97 8.913 8.913 0 01-3.588.727zm1.06-9.603l2.446 2.44c.196.196.293.44.293.736a.998.998 0 01-1.028 1.029.998.998 0 01-.736-.293l-2.729-2.728a1.124 1.124 0 01-.335-.815V7.954c0-.295.1-.543.302-.743.202-.2.45-.299.745-.299.296 0 .543.1.743.3.2.2.3.447.3.742v3.558z'
                        fill='#171923'
                    />
                </g>
            </g>
            <defs>
                <clipPath id='history_svg__clip0_1923_10242'>
                    <path fill='#fff' d='M0 0h24v24H0z' />
                </clipPath>
            </defs>
        </svg>
    );
}
export default SvgHistory;
