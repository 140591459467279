import * as React from 'react';
function SvgAlertSuccess(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g clipPath='url(#alert-success_svg__clip0_3002_68089)'>
                <mask
                    id='alert-success_svg__a'
                    style={{
                        maskType: 'alpha',
                    }}
                    maskUnits='userSpaceOnUse'
                    x={0}
                    y={0}
                    width={24}
                    height={24}
                >
                    <path fill='#D9D9D9' d='M0 0h24v24H0z' />
                </mask>
                <g mask='url(#alert-success_svg__a)'>
                    <path
                        d='M10.645 13.664l-1.994-1.995a1.063 1.063 0 00-.784-.31c-.315 0-.577.103-.784.31-.207.207-.31.469-.308.784.002.315.107.576.314.784l2.755 2.754c.229.228.497.342.802.342.306 0 .573-.114.8-.342l5.561-5.56c.207-.208.311-.468.311-.781a1.059 1.059 0 00-1.095-1.092c-.315 0-.576.104-.783.311l-4.795 4.795zm1.418 8.612a9.948 9.948 0 01-3.983-.803 10.296 10.296 0 01-3.238-2.18 10.296 10.296 0 01-2.18-3.236 9.947 9.947 0 01-.802-3.984c0-1.415.268-2.743.803-3.984a10.297 10.297 0 012.18-3.237 10.297 10.297 0 013.237-2.18 9.946 9.946 0 013.983-.802c1.415 0 2.743.267 3.984.803 1.24.535 2.32 1.261 3.237 2.18a10.296 10.296 0 012.18 3.236 9.946 9.946 0 01.802 3.984 9.947 9.947 0 01-.802 3.984 10.295 10.295 0 01-2.18 3.237 10.295 10.295 0 01-3.237 2.18 9.948 9.948 0 01-3.984.802zm0-2.275c2.217 0 4.093-.767 5.627-2.3 1.534-1.535 2.301-3.41 2.301-5.628 0-2.217-.767-4.093-2.3-5.627-1.535-1.534-3.41-2.301-5.628-2.301-2.217 0-4.093.767-5.627 2.3-1.534 1.535-2.301 3.41-2.301 5.628 0 2.217.767 4.093 2.301 5.627 1.534 1.534 3.41 2.301 5.627 2.301z'
                        fill='#73B234'
                    />
                </g>
            </g>
            <defs>
                <clipPath id='alert-success_svg__clip0_3002_68089'>
                    <path fill='#fff' d='M0 0h24v24H0z' />
                </clipPath>
            </defs>
        </svg>
    );
}
export default SvgAlertSuccess;
