export const HeaderColumn = ({ rows, i, column }) => {
    return (
        <div
            style={{
                minWidth: 'min-content',
                width: rows?.some((x) => x.cells[i]?.value?.toString().length > 24)
                    ? '240px'
                    : rows?.some((x) => x.cells[i]?.value?.toString().length > 16)
                    ? '160px'
                    : rows?.some((x) => x.cells[i]?.value?.toString().length > 12)
                    ? '120px'
                    : rows?.some((x) => x.cells[i]?.value?.toString().length > 8)
                    ? '80px'
                    : rows?.some((x) => x.cells[i]?.value?.toString().length > 6)
                    ? '60px'
                    : 'auto',
                lineBreak: 'auto',
                minHeight: '30px',
            }}
        >
            {column.render('Header')}
        </div>
    );
};
