import { FC, useMemo, useEffect, useState, useCallback } from 'react';
import Drawer from '@Components/Drawer';
import { useTranslation } from 'react-i18next';
import { Answer, Question } from '@Redux/services/Event/types';
import { VStack } from '@chakra-ui/react';
import Textarea from '@Components/Textarea';
import SelectInput from '@Components/SelectInput';
import { useForms } from '@Hooks';
import { useUpdateEventResponseAnswerMutation } from '@Redux/services/EventResponse';
import { parseError, parseWarning } from '@Utilities';
import { useToast } from '@Hooks/useToast';
import { isBoolean, isEmpty, isUndefined } from 'lodash';
import { AuditInfo } from '@Components/AuditInfo';
import TypeaheadInput from '@Components/TypeaheadInput';
import { DrawerFooterActions } from '@Components/DrawerFooterActions';

interface IProps {
    isOpen: boolean;
    answer: Answer;
    questions: Question[];
    onUpdate: (answers: Answer[]) => void;
    onClose: () => void;
    onCancel: () => void;
    onQuestionClick: () => void;
    isEditable: boolean;
    label?: string | React.ReactNode;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
}

export const QuestionResponseForm: FC<IProps> = ({
    isOpen,
    answer,
    size,
    isEditable,
    label,
    questions,
    onQuestionClick,
    onClose,
    onUpdate,
    onCancel,
}) => {
    const { t } = useTranslation();
    const [question, setQuestion] = useState<Question>();

    const [updateEventResponseAnswer, { isLoading: isUpdating }] = useUpdateEventResponseAnswerMutation();
    const toast = useToast();
    const options = useMemo(
        () => [
            { key: 'Yes', value: 'Yes' },
            { key: 'No', value: 'No' },
        ],
        []
    );

    useEffect(() => {
        if (questions && answer) {
            setQuestion(questions.find((x) => x.eventQuestionId == answer.eventQuestionId));
        }
    }, [questions, answer]);

    const {
        formData: answerData,
        setFormData: setAnswer,
        manageFieldPropFactory,
        selectFieldPropFactory,
        textAreaPropFactory,
        setErrors,
        errors,
    } = useForms<Answer>(answer, () => ({ isLocked: !isEditable }));

    const handleSubmit = async () => {
        const newErrors = { ...errors };
        if (question?.yesNoTypeOfQuestion) {
            if (
                ((question?.isCommentRequired && answerData.answer === true) ||
                    (question?.isCommentRequiredNo && answerData.answer === false)) &&
                (isUndefined(answerData.comment) || isEmpty(answerData.comment?.trim()))
            ) {
                newErrors['comment'] = t('QuestionResponseForm.commentRequired');
            } else {
                delete newErrors['comment'];
            }
            if (question.isRequired && !isBoolean(answerData.answer)) {
                newErrors['answer'] = t('QuestionResponseForm.answerRequired');
            } else delete newErrors['answer'];
        } else if ((question?.isRequired && isEmpty(answerData.comment)) || isUndefined(answerData.comment)) {
            newErrors['comment'] = t('QuestionResponseForm.commentRequired');
        } else {
            delete newErrors['comment'];
        }

        setErrors(newErrors);
        if (Object.keys(newErrors).length) {
            return;
        }

        await updateEventResponseAnswer({
            eventResponseAnswerId: answerData.eventResponseAnswerId,
            answer: answerData.answer === true ? 'Yes' : answerData.answer === false ? 'No' : 'Empty',
            comment: answerData.comment,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                onUpdate(response.data);
                onCancel();
            })
            .catch((e) => parseError(toast, e));
    };

    const getAnswer = useCallback(() => {
        switch (answerData.answer) {
            case true:
                return 'Yes';
            case false:
                return 'No';
            default:
                return null;
        }
    }, [answerData]);

    const handleAnswerChange = (value: string) => {
        setAnswer({ ...answerData, answer: value === 'Yes' ? true : value === 'No' ? false : null });
        const newErrors = { ...errors };
        delete newErrors['answer'];
        setErrors(newErrors);
    };

    return (
        <Drawer
            size={size}
            isOpen={isOpen}
            onClose={onClose}
            title={label ?? t('QuestionResponseForm.title')}
            subtitle={t('QuestionResponseForm.subTitle')}
            footer={
                <DrawerFooterActions
                    isEditable={isEditable}
                    closeButtonProps={{
                        isDisabled: isUpdating,
                        label: t('QuestionResponseForm.closeDrawer'),
                        onClick: onCancel,
                    }}
                    saveButtonProps={{
                        onClick: handleSubmit,
                        label: t(
                            isUpdating ? 'QuestionResponseForm.buttonLoading' : 'QuestionResponseForm.saveButtonLabel'
                        ),
                        isDisabled: isUpdating,
                    }}
                />
            }
        >
            <VStack gap='8px'>
                <TypeaheadInput
                    label={t('QuestionResponseForm.answerTitle')}
                    isLocked={true}
                    value={answer?.eventQuestionId}
                    onLinkClick={onQuestionClick}
                    options={[
                        {
                            key: answer?.eventQuestionId,
                            value: answer?.name,
                        },
                    ]}
                />
                <Textarea label={t('QuestionResponseForm.answerText')} isLocked={true} value={answer.text} />

                {answer.yesNoTypeOfQuestion && (
                    <SelectInput
                        {...selectFieldPropFactory(t('QuestionResponseForm.answer'), ['answer'])}
                        {...manageFieldPropFactory(['answer'], 'onChangeValue', 'value')}
                        onChangeValue={handleAnswerChange}
                        value={getAnswer()}
                        options={options}
                    />
                )}
                <Textarea
                    {...textAreaPropFactory(t('QuestionResponseForm.comment'), ['comment'])}
                    {...manageFieldPropFactory(['comment'], 'onChangeText', 'value')}
                />
            </VStack>
            <AuditInfo
                createdBy={answerData?.createdByFullName}
                createdOn={answerData?.createdOn}
                modifiedBy={answerData?.modifiedByFullName}
                modifiedOn={answerData?.modifiedOn}
            />
        </Drawer>
    );
};
