import FormHeader from '@Components/FormHeader';
import React from 'react';
import InnerFormHeader from '../InnerFormHeader';
import { FormComponentProps } from '@Hooks';
import { useTranslation } from 'react-i18next';
import PickUpDetails from './components/PickUp';
import DropOffDetails from './components/DropOff';
import { useGetCitiesRequestQuery } from '@Redux/services/LookupApi';
import SvgBusNeeds from '@Icon/BusNeeds';

const BusNeeds: React.FC<FormComponentProps> = ({
    id,
    manageFieldPropFactory,
    selectFieldPropFactory,
    textFieldPropFactory,
}) => {
    const [t] = useTranslation();
    const componentProps = {
        textFieldPropFactory,
        selectFieldPropFactory,
        manageFieldPropFactory,
    };

    const { data: { data: cities = [] } = {} } = useGetCitiesRequestQuery({ searchText: '' });

    return (
        <>
            <FormHeader
                id={id}
                header={
                    <InnerFormHeader divider={true} title={t('RegistrationDetails.busNeeds')} icon={<SvgBusNeeds />} />
                }
                label={t('RegistrationDetails.busNeeds.reqTransportationPickUp')}
            >
                <PickUpDetails id={'pickUpSection'} cities={cities} {...componentProps} />
            </FormHeader>
            <FormHeader id={id} label={t('RegistrationDetails.busNeeds.reqTrasportationDropOff')}>
                <DropOffDetails id={'dropOffSection'} cities={cities} {...componentProps} />
            </FormHeader>
        </>
    );
};

export default BusNeeds;
