import { Tab, TabList, Tabs as ChakraTabs, TabsProps as ChakraTabProps } from '@chakra-ui/react';
import { useState } from 'react';
import useScrollTabIntoView from 'src/hooks/Tabs';
interface TabProps extends ChakraTabProps {
    tabs: string[];
}

function Tabs({ tabs, onChange, children }: TabProps) {
    const [tabIndex, setTabIndex] = useState(0);

    const handleChange = (nextTabIndex: number) => {
        if (nextTabIndex !== tabIndex) {
            setTabIndex(nextTabIndex);
            onChange(nextTabIndex);
        }
    };

    const tabListRef = useScrollTabIntoView(tabIndex);

    return (
        <ChakraTabs index={tabIndex} variant='enclosed' onChange={handleChange} isLazy={true}>
            <TabList ref={tabListRef} overflow='auto' overflowY='hidden'>
                {tabs.map((tab, index) => (
                    <Tab key={index} mt={1} ml={index === 0 && 1} flexShrink={0}>
                        {tab}
                    </Tab>
                ))}
            </TabList>
            {children}
        </ChakraTabs>
    );
}

export default Tabs;
