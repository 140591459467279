import * as React from 'react';
function SvgSave(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M4.596 17.297c-.525 0-.972-.184-1.341-.554A1.827 1.827 0 012.7 15.4V3.854c0-.524.185-.972.555-1.341.37-.37.816-.554 1.341-.554h9.228a1.87 1.87 0 011.33.548l2.336 2.336a1.856 1.856 0 01.549 1.33v9.228c0 .525-.185.972-.554 1.342-.37.37-.817.554-1.342.554H4.596zM16.143 6.194l-2.34-2.34H4.596v11.547h11.547V6.194zm-5.773 8.374c.694 0 1.284-.243 1.77-.73.486-.485.73-1.075.73-1.77 0-.694-.243-1.285-.73-1.77a2.41 2.41 0 00-1.77-.73 2.41 2.41 0 00-1.771.73 2.41 2.41 0 00-.73 1.77c0 .694.244 1.285.73 1.77.486.487 1.076.73 1.77.73zM6.377 8.021h5.605c.266 0 .49-.091.673-.274a.916.916 0 00.274-.674V5.636a.916.916 0 00-.274-.674.915.915 0 00-.673-.274H6.377a.916.916 0 00-.673.274.916.916 0 00-.275.674v1.437c0 .266.092.49.275.674a.916.916 0 00.673.274z'
                fill='currentColor'
            />
        </svg>
    );
}
export default SvgSave;
