import {
    useCheckNewPendingCallsMutation,
    useGetPhoneCallsMutation,
    useLazyGetPhoneCallStatsQuery,
    useMarkCallAsCompletedMutation,
    useUpdatePhoneCallMutation,
} from '@Redux/services/Absence';
import { BasicLookup } from '@Redux/services/LookupApi/types';
import { format } from 'date-fns';
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    DATE_TIME_FORMAT,
    FIVE_MINUTES_MS,
    PENDING_CALLS_DATE_TIME_FORMAT,
    phoneCallStatusCodeEnum,
} from 'src/constants';
import { Text, Center, Box, Button, Tag, TagLabel, Avatar } from '@chakra-ui/react';
import colors from '@Theme/foundations/colors';
import { parseError, parseWarning } from '@Utilities';
import { useToast } from '@Hooks/useToast';
import { Refresh } from '@Icon';
import SelectInput from '@Components/SelectInput';
import { PhoneCallFormData } from '../../types';
import i18n from '@I18n/i18n';
import ListPanel from '@Components/ListPanel';
import ListItem from '../ListItem';
import { PagedResult } from '@Redux/services/commonTypes';
import { useDebounce } from '@Hooks/useDebounce';
import { AbsenceParams, PhoneCall } from '@Redux/services/Absence/types';
import { invitedGrades } from '../../types';

interface IProps {
    grades: BasicLookup[];
    absenceReasonCodes: BasicLookup[];
    onRowClick: (phoneCall: PhoneCall) => void;
    formState?: [PhoneCallFormData[], Dispatch<SetStateAction<PhoneCallFormData[]>>];
    lastRefreshedListPanel?: Date;
    setNewPendingCalls: (amount: number) => void;
    handleRefresh: () => void;
    phoneCallsListPanel: PhoneCall[];
    newPendingCalls: number;
    pagedResult: PagedResult;
    isPendingPhoneCallsLoading: boolean;
    getPendingPhoneCalls: (params: AbsenceParams) => void;
    setSearchText: (value: string) => void;
    searchText: string;
}

const durationValues = [
    {
        value: i18n.t('PhoneCall.duration.reportedPeriod'),
        key: 0,
    },
    {
        value: i18n.t('PhoneCall.duration.allDay'),
        key: 480,
    },
] as BasicLookup[];

export const PendingCalls: FC<IProps> = ({
    grades,
    absenceReasonCodes,
    onRowClick,
    formState,
    lastRefreshedListPanel,
    setNewPendingCalls,
    handleRefresh,
    phoneCallsListPanel,
    newPendingCalls,
    pagedResult,
    isPendingPhoneCallsLoading,
    getPendingPhoneCalls,
    searchText,
}) => {
    const { t } = useTranslation();
    const toast = useToast();

    const [checkNewPendingCalls, { data: { data: newPendingCallsData = undefined } = {} }] =
        useCheckNewPendingCallsMutation();
    const [checkPendingCallsCount] = useGetPhoneCallsMutation({
        fixedCacheKey: 'NavBarCount',
    });
    const [updatePhoneCall] = useUpdatePhoneCallMutation();
    const [markCallCompleted] = useMarkCallAsCompletedMutation();

    const [getGetPhoneCallStats] = useLazyGetPhoneCallStatsQuery();

    const [formData, setFormData] = formState;
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setInitialized(true);
        }, 500);
    }, []);

    useEffect(() => {
        setNewPendingCalls(newPendingCallsData?.amount);
    }, [newPendingCallsData]);

    useEffect(() => {
        const checkNewPendingCallsInterval = setInterval(
            () => {
                checkNewPendingCalls({ lastUpdatedDate: lastRefreshedListPanel ?? new Date() });
                checkPendingCallsCount({
                    sortBy: null,
                    isDesc: false,
                    searchText: null,
                    currentPage: 1,
                    pageSize: 1,
                    statusCode: phoneCallStatusCodeEnum.Open,
                });
            },
            FIVE_MINUTES_MS,
            [lastRefreshedListPanel]
        );

        return () => clearInterval(checkNewPendingCallsInterval);
    }, [lastRefreshedListPanel]);

    const handleUpdate = (id: string, val: string | number) => {
        let value: string | number;
        if (typeof val === 'number' && isNaN(val)) {
            value = 0;
        } else {
            value = val;
        }
        const data = formData.find((i) => i.activityId === id);
        if (data) {
            const newFormData = formData.map((i) => {
                if (i.activityId === id) {
                    return { ...i, absenceReason: value };
                }
                return i;
            });
            setFormData([...newFormData]);
        } else {
            setFormData([...formData, { activityId: id, absenceReason: value, absenceDuration: 0 }]);
        }
    };

    const handleDurationUpdate = (id: string, val: string | number) => {
        let value: string | number;
        if (typeof val === 'number' && isNaN(val)) {
            value = 0;
        } else {
            value = val;
        }

        const data = formData.find((i) => i.activityId === id);
        if (data) {
            const newFormData = formData.map((i) => {
                if (i.activityId === id) {
                    return { ...i, absenceDuration: value };
                }
                return i;
            });
            setFormData([...newFormData]);
        } else {
            setFormData([...formData, { activityId: id, absenceReason: 0, absenceDuration: value }]);
        }
    };

    const handleSubmit = (id: string) => {
        const data = formData.find((i) => i.activityId === id);
        if (!data?.absenceReason || data?.absenceReason === 0) {
            // Redundancy
            return;
        }
        const payload = {
            phoneCallId: id,
            actionType: 951030004, // value: Do Not Call Again, pending uat confirmation
            duration: Number(data?.absenceDuration || 0),
            callFrom: null,
            callTo: null,
            absenceReason: Number(data.absenceReason),
        };

        updatePhoneCall(payload)
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                markCallCompleted({
                    phoneCallId: id,
                })
                    .unwrap()
                    .then((response) => {
                        parseWarning(toast, response);
                        handleRefresh();
                        getGetPhoneCallStats(null);
                    })
                    .catch((err) => {
                        parseError(toast, err);
                    });
            })
            .catch((err) => parseError(toast, err));
    };

    const debouncedResults = useDebounce(() =>
        getPendingPhoneCalls({
            currentPage: 1,
            pageSize: 5,
            searchText: searchText,
            isDesc: false,
            sortBy: null,
        })
    );

    const getGradeLabelTextColor = (grade: number) => {
        if (grade <= invitedGrades.Grade5) {
            // Grade 5 and under
            return colors.text.error;
        }
        if (grade <= invitedGrades.Grade8) {
            // Grade 6-8
            return colors.text.warning;
        }
        // Grade 9-12
        return colors.text.brandPrimary;
    };
    const getGradeLabelBgColor = (grade: number) => {
        if (grade <= 951030006) {
            // Grade 5 and under
            return colors.surface.errorInvert;
        }
        if (grade <= 951030009) {
            // Grade 6-8
            return colors.surface.warningInvert;
        }
        // Grade 9-12
        return colors.surface.brandInvert;
    };

    useEffect(() => {
        if (!initialized) return;

        debouncedResults();
    }, [searchText]);

    useEffect(() => {
        setFormData(
            phoneCallsListPanel?.reduce((fd, p) => {
                if (p?.absenceReason || p?.duration || p?.action) {
                    return [
                        ...fd,
                        {
                            action: p?.action,
                            activityId: p.activityId,
                            absenceReason: p?.absenceReason ?? 0,
                            absenceDuration: p?.duration < 480 ? 0 : 480,
                        },
                    ];
                }
                return [...fd];
            }, [])
        );
    }, [phoneCallsListPanel]);

    const listPanelItems = useMemo(() => {
        return {
            headerItems: {
                title: t('AbsencesList.title.pending'),
                subTitle: lastRefreshedListPanel
                    ? `${t('AbsencesList.lastUpdatedOn')} ${format(lastRefreshedListPanel, DATE_TIME_FORMAT)}`
                    : '',
                newRecordAmount: newPendingCalls,
                handleRefresh,
            },
            pagedListItems: {
                listItems: isPendingPhoneCallsLoading
                    ? []
                    : phoneCallsListPanel?.map((c, i) => {
                          const data = formData.find((i) => i.activityId === c.activityId);
                          const item = {
                              title: (
                                  <Text noOfLines={1} textStyle={'md-bold'}>
                                      {c.studentName}
                                  </Text>
                              ),
                              subtitle: (
                                  <Box>
                                      <Text textStyle={'sm-medium'} noOfLines={1} pb={1}>
                                          {format(new Date(c.date), PENDING_CALLS_DATE_TIME_FORMAT)}
                                          {c.grade ? (
                                              <Tag
                                                  size={'sm'}
                                                  borderRadius={'full'}
                                                  backgroundColor={getGradeLabelBgColor(c.grade)}
                                                  color={getGradeLabelTextColor(c.grade)}
                                                  ml={2}
                                              >
                                                  <TagLabel>
                                                      {t('StudentDetails.grade')}{' '}
                                                      {grades.find((g) => g.key === c.grade)?.value}
                                                  </TagLabel>
                                              </Tag>
                                          ) : null}
                                          {c.action ? (
                                              <Tag
                                                  size={'sm'}
                                                  borderRadius={'full'}
                                                  backgroundColor={colors.surface.successInvert}
                                                  color={colors.text.success}
                                                  ml={2}
                                              >
                                                  <TagLabel>{t('AbsencesList.actioned')}</TagLabel>
                                              </Tag>
                                          ) : null}
                                      </Text>
                                  </Box>
                              ),
                              avatar: (
                                  <Avatar
                                      name={c.studentName}
                                      src={c.avatar ? `data:${c.avatar.mimeType};base64,${c.avatar.base64}` : ''}
                                      size='md'
                                  />
                              ),
                              phoneCallData: { ...c, action: data?.action?.toString() },
                              itemContent: (
                                  <>
                                      <SelectInput
                                          id={c.activityId}
                                          onChangeValue={(value) => handleUpdate(c.activityId, value)}
                                          label={t('AbsencesList.reason')}
                                          options={absenceReasonCodes}
                                          size={'xs'}
                                          value={data?.absenceReason}
                                          h={8}
                                          mb={2}
                                          backgroundColor={'white'}
                                          defaultOptionLabel={t('PhoneCall.absenceReason.defaultLabel')}
                                      />
                                      <SelectInput
                                          id={c.activityId}
                                          onChangeValue={(value) => handleDurationUpdate(c.activityId, value)}
                                          label={t('AbsencesList.duration')}
                                          options={durationValues}
                                          size={'xs'}
                                          value={data?.absenceDuration}
                                          defaultOption={false}
                                          h={8}
                                          mb={2}
                                          backgroundColor={'white'}
                                      />
                                  </>
                              ),
                              onView: () => onRowClick(c),
                              onSubmit: () => handleSubmit(c.activityId),
                              disableSubmit: Boolean(!data?.absenceReason),
                          };

                          return <ListItem {...item} key={i} />;
                      }),
                pagedResult: pagedResult,
            },
            paginated: false,
            emptyScreen: (
                <Center
                    flexDirection={'column'}
                    h={'full'}
                    bg={'surface-brand-invert'}
                    borderRadius='0px 0px 16px 16px'
                    px={6}
                >
                    <Text w={'full'} fontSize='4xl' lineHeight={1.22} as={'b'} color='text-brand-primary' pb={4}>
                        {t('AbsencesList.emptyPending.title')}
                    </Text>
                    <Text w={'full'} mb={6} whiteSpace='pre-line'>
                        {t('AbsencesList.emptyPending.text', {
                            timestamp: lastRefreshedListPanel ? format(lastRefreshedListPanel, DATE_TIME_FORMAT) : '',
                        })}
                    </Text>
                    <Box w={'full'}>
                        <Button
                            leftIcon={<Refresh fill={colors.surface.brandInvert} />}
                            onClick={handleRefresh}
                            color={'surface-brand-invert'}
                            colorScheme='teal'
                        >
                            {t('AbsencesList.emptyPending.refresh')}
                        </Button>
                    </Box>
                </Center>
            ),
        };
    }, [phoneCallsListPanel, newPendingCalls, formData, searchText]);

    return <ListPanel {...listPanelItems} />;
};
