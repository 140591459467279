import { createSlice } from '@reduxjs/toolkit';

//LOGGING IN/OUT
const initialAuthState = {
    token: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState: initialAuthState,
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
        logout: (state) => {
            state.token = '';
        },
    },
});

export const { setToken, logout } = authSlice.actions;

export default authSlice.reducer;
