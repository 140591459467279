import { Box } from '@chakra-ui/react';
import { Button, Pagination } from '@Components';
import DateCell from '@Components/DateCell';
import Table from '@Components/Table';
import TableActions from '@Components/TableActions';
import { StepProps } from '@Pages/EventDetails/types';
import { useExportEventResponsesMutation, useGetEventResponsesMutation } from '@Redux/services/Event';
import { TimeoutId } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, SortingRule } from 'react-table';
import { DATE_TIME_FORMAT, EXCEL_FILE_CONTENT_TYPE, DEFAULT_PAGE_SIZE, EventStatus } from 'src/constants';
import { ResponsesFilters, TableFilters } from '../TableFilters';
import { downloadFile, parseError, parseWarning } from '@Utilities';
import { useToast } from '@Hooks/useToast';
import { AddResponseModal } from './components/AddResponse';
import { EventResponse } from '@Redux/services/Event/types';
import CurrencyCell from '@Components/CurrencyCell';
import GradeCell from './components/GradeCell';
import ActionCell from './components/ActionCell';
import StatusCell from './components/StatusCell';
import Responses from '@Pages/EventDetails/components/EventStatus/components/EventResponses/components/Responses';
import { Plus } from '@Icon';
import EventResponseDetailDrawer from '@Components/EventResponseDetailDrawer';

type EventresponsesType = {
    showHeader?: boolean;
    openAddResponse?: boolean;
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
} & StepProps;

const EventResponses: FC<EventresponsesType> = (props) => {
    const { eventDetails, setEventDetails, showHeader, openAddResponse, isOpen, onOpen, onClose } = props;

    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [sortFields, setSortFields] = useState<SortingRule<EventResponse>[]>();
    const [filters, setFilters] = useState({
        grade: undefined,
        homeRoom: undefined,
        homeRoomTeacher: undefined,
        searchText: '',
        status: undefined,
    });
    const columns = useMemo<Column[]>(
        () => [
            {
                Header: <>&nbsp;</>,
                accessor: 'actions',
                headerAlign: 'center',
                disableSortBy: true,
                Cell: ActionCell,
            },
            {
                Header: t('EventResponses.fullName'),
                accessor: 'fullName',
                headerAlign: 'left',
            },
            {
                Header: t('EventResponses.statusReason'),
                accessor: 'status',
                Cell: StatusCell,
                headerAlign: 'center',
            },
            {
                Header: t('EventResponses.grade'),
                accessor: 'grade',
                headerAlign: 'left',
                Cell: GradeCell,
            },
            {
                Header: t('EventResponses.homeRoomTeacher'),
                accessor: 'homeRoomTeacher',
                headerAlign: 'left',
            },
            {
                Header: t('EventResponses.homeRoom'),
                accessor: 'homeRoomStudent',
                headerAlign: 'left',
            },
            {
                Header: t('EventResponses.amountPaid'),
                accessor: 'amountPaid',
                Cell: CurrencyCell,
                headerAlign: 'right',
            },
            {
                Header: t('EventResponses.confirmedOn'),
                accessor: 'confirmedOn',
                Cell: DateCell(DATE_TIME_FORMAT),
                headerAlign: 'left',
            },
        ],
        []
    );

    const [debounce, setDebounce] = useState<TimeoutId>();
    const [getEventResponses, { isLoading }] = useGetEventResponsesMutation();
    const [exportEventResponses] = useExportEventResponsesMutation();
    const toast = useToast();

    useEffect(() => {
        if (openAddResponse) {
            onOpen();
        }
    }, [openAddResponse]);

    const handlePageChange = useCallback(
        async (
            page: number,
            newPageSize: number = pageSize,
            newFilters: ResponsesFilters = filters,
            sortBy: SortingRule<EventResponse>[] = sortFields
        ) => {
            setCurrentPage(page);
            await getEventResponses({
                eventId: eventDetails.eventId,
                currentPage: page,
                pageSize: newPageSize,
                sortBy: sortBy && sortBy.length ? sortBy[0].id : undefined,
                isDesc: sortBy && sortBy.length ? sortBy[0].desc : undefined,
                ...newFilters,
            })
                .unwrap()
                .then((response) => {
                    parseWarning(toast, response);
                    setEventDetails({
                        ...eventDetails,
                        eventResponses: {
                            eventStatistics: eventDetails.eventResponses.eventStatistics,
                            eventRespondents: response.data,
                            pagedResult: response.pagedResult,
                        },
                    });
                })
                .catch((err) => parseError(toast, err));
        },
        [currentPage, filters]
    );

    const handleFilterChange = (key: string) => (value) => {
        setCurrentPage(1);
        const newFilters = { ...filters, [key]: value };
        setFilters(newFilters);
        clearTimeout(debounce);
        setDebounce(setTimeout(() => handlePageChange(1, pageSize, newFilters), 800));
    };

    const handleExport = async () => {
        await exportEventResponses({
            eventId: eventDetails.eventId,
            ...filters,
            sortBy: sortFields && sortFields.length ? sortFields[0].id : undefined,
            isDesc: sortFields && sortFields.length ? sortFields[0].desc : undefined,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                downloadFile(EXCEL_FILE_CONTENT_TYPE, response.data.fileContents, response.data.fileName);
            })
            .catch((err) => parseError(toast, err));
    };

    const [selectedEventResponse, setSelectedEventResponse] = useState<EventResponse>();
    const [isEventResponseDetailOpen, setIsEventResponseDetailOpen] = useState(false);

    const onEditHandler = (row) => {
        setSelectedEventResponse(row.original);
        setIsEventResponseDetailOpen(true);
    };

    const handleCloseEventResponseDrawer = () => {
        setIsEventResponseDetailOpen(false);
        setSelectedEventResponse(undefined);
    };

    const isPublished = useMemo(() => eventDetails.status === EventStatus.Published, [eventDetails]);
    const handleSortChange = (sortBy: SortingRule<EventResponse>[]) => {
        setCurrentPage(1);
        setSortFields(sortBy);
        handlePageChange(1, pageSize, filters, sortBy);
    };
    const handlePageSizeChange = (pageSize: number) => {
        setPageSize(pageSize);
        setCurrentPage(1);
        handlePageChange(1, pageSize);
    };

    return (
        <>
            <Box>
                <Responses id='responses' {...props} showHeader={showHeader} />
                {isOpen && (
                    <AddResponseModal
                        setEventDetails={setEventDetails}
                        eventData={eventDetails}
                        isOpen={isOpen}
                        onClose={onClose}
                    />
                )}
                {isEventResponseDetailOpen && (
                    <EventResponseDetailDrawer
                        onSubmit={() => handlePageChange(currentPage)}
                        id={selectedEventResponse.eventResponseId}
                        isOpen={isEventResponseDetailOpen}
                        onClose={handleCloseEventResponseDrawer}
                    />
                )}
                <Table
                    stickyColumns={2}
                    isLoading={isLoading}
                    action={
                        <TableActions
                            onExportClick={handleExport}
                            onSearch={handleFilterChange('searchText')}
                            searchText={filters.searchText}
                            onAddClick={isPublished && onOpen}
                        />
                    }
                    addRowButton={
                        isPublished && (
                            <Button leftIcon={<Plus fontSize={'20px'} />} variant={'light'} onClick={onOpen}>
                                {t('EventResponses.addResponse')}
                            </Button>
                        )
                    }
                    emptyMessage={t('EventResponses.emptyTable')}
                    manualSortBy={false}
                    columns={columns}
                    userProps={{ onOpen: onEditHandler }}
                    rawData={eventDetails.eventResponses?.eventRespondents}
                    filter={<TableFilters onChange={handleFilterChange} filters={filters} />}
                    onEditHandler={onEditHandler}
                    onSortChange={handleSortChange}
                />
                <Box
                    bg='surface-primary'
                    borderTop='none'
                    borderTopStyle='none'
                    borderLeftWidth={2}
                    borderRightWidth={2}
                    borderBottomWidth={2}
                    borderBottomRadius={5}
                    borderStyle='solid'
                    borderColor='border-primary'
                >
                    <Pagination
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        pageSize={pageSize}
                        onPageSizeChange={handlePageSizeChange}
                        totalPages={eventDetails.eventResponses.pagedResult?.pageCount}
                        totalResults={eventDetails.eventResponses.pagedResult?.rowCount}
                        viewMode={'TextButton'}
                    />
                </Box>
            </Box>
        </>
    );
};

export default EventResponses;
