import DateTimeInput from '@Components/DateTimeInput';
import Switch from '@Components/Switch';
import TextInput from '@Components/TextInput';
import { ManageDatePickerProps, ManageSwitchInputProps, ManageTextProps } from '@Hooks';
import { Document } from '@Icon';
import { LateAbsenceStepProps } from '@Pages/LateAbsenceDetails/types';
import { Box, Stack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '@Components/DetailsHeader';

const GeneralInfo = React.forwardRef<HTMLDivElement, LateAbsenceStepProps>(
    ({ id, manageFieldPropFactory, textFieldPropFactory, dateTimePickerPropFactory, switchFieldPropFactory }) => {
        const { t } = useTranslation();
        return (
            <Box id={id}>
                <Header divider={true} mb={4} title={t('LateAbsence.subheader.general')} icon={<Document />} />
                <Stack gap={4}>
                    <TextInput
                        bg='surface-primary'
                        {...textFieldPropFactory(t('LateAbsence.studentName'), ['student'])}
                        {...manageFieldPropFactory<ManageTextProps>(['student'], 'onChangeText', 'value')}
                    />

                    <TextInput
                        bg='surface-primary'
                        {...textFieldPropFactory(t('LateAbsence.reason'), ['absenceReason'])}
                        {...manageFieldPropFactory<ManageTextProps>(['absenceReason'], 'onChangeValue', 'value')}
                    />

                    <TextInput
                        bg='surface-primary'
                        {...textFieldPropFactory(t('LateAbsence.originatingSubmittedBy'), ['originatingSubmitter'])}
                        {...manageFieldPropFactory<ManageTextProps>(['originatingSubmitter'], 'onChangeText', 'value')}
                    />
                    <TextInput
                        bg='surface-primary'
                        {...textFieldPropFactory(t('LateAbsence.submittedBy'), ['originatingSubmitter'])}
                        {...manageFieldPropFactory<ManageTextProps>(['originatingSubmitter'], 'onChangeText', 'value')}
                    />

                    <DateTimeInput
                        bg='surface-primary'
                        hasTimeComponent
                        {...dateTimePickerPropFactory(t('LateAbsence.dateAbsenceSubmitted'), ['dateAbsenceSubmitted'])}
                        {...manageFieldPropFactory<ManageDatePickerProps>(['dateAbsenceSubmitted'], 'onChange', 'date')}
                        isLocked={true}
                        size='md'
                    />

                    <Switch
                        {...switchFieldPropFactory(t('LateAbsence.sendEmailConfirmation'), ['sendEmailConfirmation'])}
                        {...manageFieldPropFactory<ManageSwitchInputProps>(
                            ['sendEmailConfirmation'],
                            'onChangeSwitch',
                            'isChecked'
                        )}
                    />
                </Stack>
            </Box>
        );
    }
);

export default GeneralInfo;
