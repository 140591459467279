import { TFunction } from 'react-i18next';
import { EventStatusParam } from '../index';

const sideBarData = (eventStatus: EventStatusParam, t: TFunction) => ({
    groups: [
        {
            items: [
                {
                    label: t('EventsList.all'),
                    id: '/events/planning/all',
                    active: eventStatus === 'all',
                },
                {
                    label: t('EventsList.proposed'),
                    id: '/events/planning/proposed',
                    active: eventStatus === 'proposed',
                },
                {
                    label: t('EventsList.validated'),
                    id: '/events/planning/validated',
                    active: eventStatus === 'validated',
                },
                {
                    label: t('EventsList.published'),
                    id: '/events/planning/published',
                    active: eventStatus === 'published',
                },
                {
                    label: t('EventsList.closed'),
                    id: '/events/planning/closed',
                    active: eventStatus === 'closed',
                },
                {
                    label: t('EventsList.cancelled'),
                    id: '/events/planning/cancelled',
                    active: eventStatus === 'cancelled',
                },
            ],
        },
    ],
});

export { sideBarData };
