import { MemberPhoto, PagedResult } from '../commonTypes';

export type Job = {
    jobId: string;
    name: string;
    status: number;
    description: string;
    fileName: string;
    school: string;
    period: string;
    grade: string;
    reportType: string;
    createdBy: string;
    createdOn: Date;
};

export type PaginationProps = {
    sortBy?: string;
    isDesc?: boolean;
    currentPage?: number;
    pageSize?: number;
};

export type allJobsProps = {
    searchText: string;
} & PaginationProps;

export type ReportingPeriodsProps = {
    teamId?: string;
} & PaginationProps;

export type ReportingPeriodBySchoolYear = {
    schoolId: string;
    school: string;
    schoolYearId: string;
    schoolYearStart: Date;
    schoolYearEnd: Date;
    schoolYearStatus: number;
    periods: ReportingPeriod[];
};

export type ReportingPeriod = {
    isActive: boolean;
    reportingPeriodId: string;
    reportingPeriod: string;
    sendHomeDate: string;
    studentSnapshotCreationDate: string;
    reportTypes: ReportType[];
};

export type ReportType = {
    reportingTypeId: string;
    reportingType: string;
    sendHomeDate: string;
    pendingReports: number;
    latestJob?: UploadedJob;
    studentSnapshotCreationDate: string;
};

export type ReportingPeriodDetails = {
    reportingPeriodId: string;
    reportingPeriod: string;
    schoolId: string;
    school: string;
    schoolYearStart: string;
    schoolYearEnd: string;
    schoolYearId: string;
    principal: string;
    vicePrincipal: string;
    reportTypes: ReportType[];
    studentSnapshot: StudentSnapshotData;
    uploads: UploadData;
    schoolReportingDayId: string;
    createdByFullName: string;
    createdOn: string;
    modifiedByFullName: string;
    modifiedOn: string;
    latestJob: UploadedJob;
    pendingReports: number;
    notes?: ReportingPeriodNotes;
    publishedReports: number;
};

export type StudentSnapshotData = {
    students: StudentRecord[];
    pagedResult: PagedResult;
};

export type UploadData = {
    uploadedJobs: UploadedJob[];
    pagedResult: PagedResult;
};

export type StudentRecord = {
    oen: string;
    fullName: string;
    fullNameRC?: string;
    schoolName: string;
    age: number | null;
    currentGrade: number;
    grade: number;
    gradeRC?: number;
    gradeDescription: string;
    reportComment: string;
    homeRoom: string;
    homeTeacher: string;
    reportSplitterExecutionCount: number | null;
    reportingStudentId: string;
    reportStatus: number;
    reportStatusDescription: string;
    reportId: string;
    contactId: string;
    studentPhoto: MemberPhoto;
};

export type UploadedJob = {
    jobId: string;
    status: number;
    fileName: string;
    reportingPeriod: string;
    reportingType: string;
    createdByFullName: string;
    createdOn: Date;
    template: string;
    validation: string;
    jobProcessingStatus: number;
    jobProcessingStatusDescription: string;
    jobProcessingStatusField: number;
};

export enum jobs_processing_status {
    Uploaded = 1,
    Validating = 951030000,
    ValidatedSuccessfuly = 951030001,
    ValidationFailed = 951030002,
    Splitting = 951030003,
    Completed = 951030004,
    Failed = 951030005,
}

export type ReportingPeriodDetailsProps = {
    reportingPeriodId: string;
    schoolYearId: string;
    schoolId: string;
    reportStatus?: number;
    grade?: number;
    homeRoomTeacher?: string;
    searchText?: string;
} & PaginationProps;

export type ReportingPeriodJobsProps = {
    reportingPeriodId: string;
    schoolYearId: string;
    schoolId: string;
    status?: number;
    reportCardType?: number;
    searchText?: string;
} & PaginationProps;

export type SortByProps = { id: string; desc: boolean };

export type PublishReportCardParams = {
    schoolReportingDayId: string;
    ReportingPeriodId: string;
    reportCardIds: string[];
    suppressNotifications: boolean;
};

export type UnpublishReportCardParams = {
    schoolReportingDayId: string;
    ReportingPeriodId: string;
    reportCardIds: string[];
    reason: string;
    suppressNotifications: boolean;
};

export type ReportingStudentTeachersProps = {
    schoolYearId: string;
    schoolId: string;
    reportingPeriodId: string;
};

export type PublishReportCardResponse = {
    publishedReportCardsCount: number;
};

export type SchoolReportingPeriodReportCardsProps = {
    schoolReportingDayId: string;
    reportCardAnalyticsType: number;
    searchText?: string;
    reportingPeriodId: string;
} & PaginationProps;

export type SchoolReportingPeriodReportCardsData = {
    reportingPeriodId: string;
    reportingPeriod: string;
    schoolId: string;
    schoolYearId: string;
    reportCards: ReportCard[];
};

export type ReportCard = {
    oen: string;
    fullName: StudentMetaData<string>;
    grade: StudentMetaData<number>;
    schoolName: StudentMetaData<string>;
    reportingStudentId: string;
    homeRoom: string;
    homeTeacher: string;
    reportSplitterExecutionCount: number;
    studentPhoto: MemberPhoto;
    reportComment: string;
    reportStatus: number;
};

export type StudentMetaData<T> = {
    schoolToDo: T;
    reportCard: T;
    isMatch: boolean;
};

export type ExportReportingPeriodReportCardsParam = {
    schoolReportingDayId: string;
    reportCardAnalyticsType: number;
    reportingPeriodId: string;
};

export type ViewReportCardParam = {
    studentId: string;
    reportId: string;
};

export interface Note {
    objectId: string;
    annotationId: string;
    noteText: string;
    createdOn: string;
    modifiedOn: string;
    modifiedByEmailAddress: string;
    modifiedByFullName: string;
}

export interface ReportingPeriodNotes {
    reportingPeriodNotesInfo: Note[];
    pagedResult: PagedResult;
}

export interface NoteParams {
    reportingPeriodId: string;
    schoolId: string;
    schoolYearId: string;
    currentPage: number;
    pageSize: number;
}

export interface ReportingPeriodNoteParam {
    noteText: string;
    reportingPeriodId: string;
    schoolId: string;
    schoolYearId: string;
}

export interface SchoolReportingPeriodMissingStudentsParams {
    schoolYearId: string;
    schoolId: string;
    reportingPeriodId: string;
    grade: number;
    homeRoomTeacher: string;
    homeRoom: string;
    searchText: string;
    sortBy: string;
    isDesc: boolean;
    currentPage: number;
    pageSize: number;
}

export interface SchoolReportingPeriodMissingStudentsData {
    oen: string;
    fullName: string;
    schoolName: string;
    age: number;
    currentGrade: number;
    grade: number;
    gradeDescription: string;
    reportComment: string;
    homeRoom: string;
    homeTeacher: string;
    contactId: string;
    teamId: string;
    schoolYearId: string;
    reportingPeriodId: string;
    imageData: string;
    studentPhoto: {
        base64: string;
        mimeType: string;
    };
}

export interface CreateSchoolReportingMissingStudentRecordsParams {
    schoolYearId: string;
    schoolId: string;
    reportingPeriodId: string;
    contacts: string[];
}

export interface DeleteSchoolReportingPeriodStudentParams {
    schoolYearId: string;
    schoolId: string;
    reportingPeriodId: string;
    reportingStudentId: string;
}
