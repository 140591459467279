import * as React from 'react';
function SvgInfo(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                clipRule='evenodd'
                d='M2.667 20c0-9.573 7.76-17.333 17.333-17.333 9.573 0 17.334 7.76 17.334 17.333 0 9.573-7.76 17.333-17.334 17.333-9.573 0-17.333-7.76-17.333-17.333zM20 .667C9.323.667.667 9.322.667 20 .667 30.677 9.323 39.333 20 39.333c10.678 0 19.334-8.655 19.334-19.333C39.334 9.322 30.678.667 20 .667zm-.068 12.666a1.667 1.667 0 100-3.333 1.667 1.667 0 000 3.333zm1 4v12h-2v-12h2z'
            />
        </svg>
    );
}
export default SvgInfo;
