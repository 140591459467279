import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
const primary = defineStyle({
    background: 'surface-brand-primary',
    color: 'surface-brand-invert',
    textStyle: 'sm-semibold',
    _disabled: {
        background: 'surface-tertiary',
        color: 'text-placeholder',
        opacity: 1,
    },
});

const warning = defineStyle({
    background: '#C91D25',
    color: 'surface-brand-invert',
    textStyle: 'sm-semibold',
});

const warningSecondary = defineStyle({
    background: 'surface-error-invert',
    textStyle: 'sm-semibold',
    color: 'text-error',
});

const light = defineStyle({
    background: 'surface-primary',
    color: 'text-primary',
    fontSize: 'sm',
    border: '1px solid',
    borderColor: 'border-primary',
});

const tertiary = defineStyle({
    background: 'surface-tertiary',
    color: 'text-primary',
    textStyle: 'sm-semibold',
    border: '1px solid',
    borderColor: 'border-primary',
});

const ghost = defineStyle({
    px: 4,
    rounded: 'md',
    bg: 'ghost.bg.base',
    color: 'ghost.text.base',
    _active: {
        border: 'none',
        outline: 'none',
        color: 'ghost.text.active',
        bg: 'ghost.bg.active',
    },
});
const success = defineStyle({
    background: 'surface-success',
    color: 'surface-success-invert',
    _hover: {
        background: 'surface-success-hover',
    },
    _active: {
        background: 'surface-success-hover',
    },
});

const brand = defineStyle({
    background: 'surface-brand-primary',
    color: 'surface-brand-invert',
    _hover: {
        background: 'text-brand-primary',
    },
    _active: {
        background: 'text-brand-primary',
    },
});

const brandInverted = defineStyle({
    background: 'surface-brand-invert',
    color: 'surface-brand-primary',
    _hover: {
        background: 'surface-brand-primary',
        color: 'surface-brand-invert',
    },
    _active: {
        background: 'text-brand-primary',
    },
});

const warningAlert = defineStyle({
    background: 'surface-warning',
    color: 'surface-warning-invert',
    _hover: {
        background: 'surface-warning-hover',
    },
    _active: {
        background: 'surface-warning-hover',
    },
});

const error = defineStyle({
    background: 'text-error',
    color: 'surface-error-invert',
    _hover: {
        background: 'surface-error-hover',
    },
    _active: {
        background: 'surface-error-hover',
    },
});

const outline = defineStyle({
    borderRadius: '8px',
    border: '1px dashed',
    borderColor: 'border-primary',
});

const disabled = defineStyle({
    borderRadius: '8px',
    border: '1px',
    borderColor: 'border-primary',
});

const Button = defineStyleConfig({
    variants: {
        primary,
        light,
        warning,
        'warning-secondary': warningSecondary,
        tertiary,
        success,
        brand,
        'warning-alert': warningAlert,
        ghost,
        error,
        outline,
        disabled,
        'brand-inverted': brandInverted,
    },
});

export type ButtonVariant = keyof typeof Button.variants;

export default Button;
