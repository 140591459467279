import { Member } from '@Redux/services/Participants/types';
import { CellProps } from 'react-table';
import { DeleteButtonPopover } from '@Components/DeleteButton';
import { useTranslation } from 'react-i18next';

const ActionCell: React.FC<CellProps<Member>> = ({
    row: {
        original: { contactId },
    },
    onDelete,
}) => {
    const { t } = useTranslation();
    return (
        <DeleteButtonPopover
            aria-label='delete'
            onDelete={() => onDelete(contactId)}
            message={t('CustomListStudents.deleteMsg')}
            usePortal={false}
        />
    );
};

export default ActionCell;
