import * as React from 'react';
function SvgHelp(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12 3a9 9 0 100 18 9 9 0 000-18zM1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12z'
                fill='#2D3748'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12.258 8.024a2 2 0 00-2.224 1.308 1 1 0 01-1.887-.664 4 4 0 017.773 1.333c0 1.53-1.135 2.54-1.945 3.081a8.036 8.036 0 01-1.686.848l-.035.013-.011.003-.004.002h-.001s-.002 0-.318-.948l.316.949a1 1 0 01-.633-1.897h-.001l.017-.006.074-.027a6.046 6.046 0 001.172-.6c.69-.46 1.055-.95 1.055-1.419v-.001a2 2 0 00-1.662-1.975zM11 17a1 1 0 011-1h.01a1 1 0 110 2H12a1 1 0 01-1-1z'
                fill='#2D3748'
            />
        </svg>
    );
}
export default SvgHelp;
