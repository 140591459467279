import * as React from 'react';
function SvgCheck(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M7.958 12.455l6.973-6.972a.923.923 0 01.676-.285c.26 0 .486.095.676.285.19.19.285.415.285.676 0 .26-.095.486-.285.676L8.626 14.49a.915.915 0 01-.668.285.915.915 0 01-.668-.285l-3.573-3.573a.896.896 0 01-.277-.676.953.953 0 01.292-.676.924.924 0 01.676-.285c.261 0 .486.095.676.285l2.874 2.89z'
                fill='currentColor'
            />
        </svg>
    );
}
export default SvgCheck;
