import { Box, Divider, Flex, Link, useDisclosure } from '@chakra-ui/react';
import { useGetSchoolsQuery, useGetPhoneCallPriorityTypesQuery } from '@Redux/services/LookupApi';
import { orderBy } from 'lodash';
import { FC, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BasicLookup } from '@Redux/services/LookupApi/types';
import { Select } from '@Components/Select';
import DatePicker from '@Components/DateTimeInput';
import ListPopover from '@Components/ListPopover';
import { Alert } from '@Components/Alert';
import { format } from 'date-fns';
import { DATE_FORMAT_E_MMM_YY, phoneCallStatusCodeEnum } from 'src/constants';

export interface AbsencesFilters {
    teamId?: string;
    statusCode?: number;
    priorityCode?: string;
    grade?: string;
    date?: Date;
}

export interface LookUpOptions {
    grades?: BasicLookup[];
    statusCodes?: BasicLookup[];
}

interface IProps {
    onChange: (key: string) => (value: string | number | Date[] | Date | null) => void;
    filters: AbsencesFilters;
    lookups?: LookUpOptions;
    onReset: () => void;
}

export const TableFilters: FC<IProps> = ({ filters, onChange, lookups: { grades, statusCodes }, onReset }) => {
    const { data: { data: schools = [] } = {} } = useGetSchoolsQuery({});
    const { data: { data: priorityTypes = [] } = {} } = useGetPhoneCallPriorityTypesQuery();
    const { t } = useTranslation();
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const priorityTypesSorted = useMemo(() => orderBy(priorityTypes, ['key'], ['desc']), [priorityTypes]);

    const filterOptions = [
        { key: 'schools', value: t('AbsencesList.Filters.school') },
        { key: 'priorityCode', value: t('AbsencesList.Filters.priority') },
    ];
    const [addMoreFilters, setAddFilters] = useState([]);
    const renderFilters = (filtersToShow: string[]) => {
        const filterList = [];
        filtersToShow.forEach((f) => {
            switch (true) {
                case f === 'priorityCode':
                    filterList.push(
                        <Select
                            onChange={(event) => onChange('priorityCode')(parseInt(event.target.value, 10))}
                            value={filters.priorityCode?.toString()}
                            size='sm'
                            bg='surface-primary'
                            placeholder={t('AbsencesList.Filters.priority')}
                            maxW='180px'
                            options={priorityTypesSorted}
                        />
                    );
                    break;
                case f === 'schools':
                    filterList.push(
                        <Select
                            value={filters.teamId}
                            size='sm'
                            bg='surface-primary'
                            placeholder={t('AbsencesList.Filters.school')}
                            maxW='180px'
                            onChange={(event) => onChange('teamId')(event.target.value)}
                            options={schools}
                        />
                    );
                    break;
            }
        });
        return filterList;
    };

    const statusValue = useMemo(
        () => statusCodes?.find((status) => status.key == phoneCallStatusCodeEnum.Made)?.value,
        [statusCodes]
    );

    return (
        <>
            <Alert
                isOpen={isOpen}
                onClose={onClose}
                alertProps={{
                    status: 'warning',
                }}
                description={
                    <Trans
                        i18nKey={'PhoneCalls.TableFilters.description'}
                        values={{ date: format(new Date(), DATE_FORMAT_E_MMM_YY), status: statusValue }}
                        components={[
                            <Link
                                onClick={() => {
                                    onReset();
                                    onClose();
                                }}
                                color={'text-warning'}
                            />,
                        ]}
                    />
                }
            />
            <Divider />
            <Flex p={6} display={{ base: 'block', lg: 'flex' }} gap='16px'>
                <Select
                    defaultValue={undefined}
                    onChange={(event) => onChange('statusCode')(parseInt(event.target.value, 10))}
                    value={filters.statusCode}
                    size='sm'
                    bg='surface-primary'
                    placeholder={t('AbsencesList.Filters.phoneCallStatus')}
                    maxW='180px'
                    options={statusCodes}
                />
                <Box>
                    <DatePicker
                        onDateChange={onChange('date')}
                        date={filters.date}
                        isLocked={false}
                        size='sm'
                        placeHolder={t('AbsencesList.Filters.date')}
                        usePortal={true}
                    />
                </Box>
                <Select
                    onChange={(event) => onChange('grade')(parseInt(event.target.value, 10))}
                    value={filters.grade?.toString()}
                    size='sm'
                    bg='surface-primary'
                    w={'auto'}
                    placeholder={t('AbsencesList.Filters.grade')}
                    options={grades}
                />
                {renderFilters(addMoreFilters)}
                <Box>
                    <ListPopover
                        aria-label={t('AddMembers.more')}
                        options={filterOptions.filter((o) => !(addMoreFilters.indexOf(o.key) > -1))}
                        onSelectOption={(value) => {
                            setAddFilters((filters) => [...filters, value]);
                        }}
                    />
                </Box>
            </Flex>
            <Divider />
        </>
    );
};
