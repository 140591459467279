import * as React from 'react';
function SvgDollar(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M12.037 21.09c-.295 0-.544-.1-.745-.3a1.003 1.003 0 01-.303-.742v-1.126a5.212 5.212 0 01-2.002-.896c-.572-.427-1.036-1.017-1.39-1.771a.93.93 0 01-.003-.81 1.11 1.11 0 01.644-.604.944.944 0 01.782.016c.268.118.48.314.638.588.279.476.63.837 1.05 1.08.422.245.931.367 1.529.367.671 0 1.232-.145 1.68-.435.45-.29.674-.743.674-1.357 0-.555-.185-1.003-.556-1.343-.37-.34-1.218-.72-2.544-1.141-1.417-.442-2.396-.985-2.938-1.628-.541-.643-.812-1.422-.812-2.338 0-1.067.345-1.911 1.035-2.53.69-.62 1.428-.984 2.213-1.092V3.952c0-.295.101-.542.303-.742.201-.2.45-.3.745-.3s.543.1.742.3c.2.2.3.447.3.742v1.076a4.403 4.403 0 011.659.63c.472.31.863.692 1.17 1.15.167.237.198.501.094.794-.103.293-.31.503-.622.632-.241.104-.495.109-.761.015a2.184 2.184 0 01-.784-.514 2.146 2.146 0 00-.738-.502c-.284-.117-.63-.175-1.04-.175-.705 0-1.237.15-1.594.451-.358.301-.536.676-.536 1.123 0 .522.235.936.705 1.24.47.305 1.33.636 2.579.994 1.166.342 2.052.88 2.657 1.615.606.735.909 1.591.909 2.57 0 1.198-.354 2.112-1.062 2.74-.708.63-1.587 1.023-2.636 1.18v1.077c0 .295-.1.543-.3.742-.2.2-.447.3-.742.3z'
                fill='#171923'
            />
        </svg>
    );
}
export default SvgDollar;
