import { UpdateStatus } from 'src/constants';
import { FormComponentProps } from '@Hooks';
import { Note, PagedResult } from '@Redux/services/commonTypes';

export const enum LinkRequestSidebarItem {
    LinkingRequest = 0,
}

export const UpdateStatuses = {
    [UpdateStatus.Submitted]: 'Submitted',
    [UpdateStatus.Approved]: 'Approved',
    [UpdateStatus.Rejected]: 'Rejected',
    [UpdateStatus.Deleted]: 'Deleted',
};

export interface NotesProps extends FormComponentProps {
    notes: Note[];
    notePagination?: PagedResult;
    isNotesLoading?: boolean;
    hideActivities?: boolean;
    onAddNote?: (noteText: string) => Promise<void>;
    onDeleteNote?: (annotationId: string) => Promise<void>;
    onUpdateNote?: (noteText: string, annotationId: string) => Promise<void>;
    onNextPage?: (currentPage: number, pageSize: number) => Promise<void>;
    isNoteEditable?: boolean;
    hideFormHeader?: boolean;
}
