import { Button, Menu as ChakraMenu, MenuButton, MenuItem, MenuItemProps, MenuList } from '@chakra-ui/react';
import { FC, ComponentProps } from 'react';
import { MenuItemAlert } from './components/MenuItemAlert';

export interface MenuItem {
    label: string;
    menuItemProps?: MenuItemProps & { onClick: () => void };
    showConfirmation?: boolean;
    message?: string;
}
interface MenuProps extends ComponentProps<typeof ChakraMenu> {
    buttonIcon?: React.ReactElement;
    items: MenuItem[];
    childern?: React.ReactNode;
}

export const Menu: FC<Omit<MenuProps, 'children'>> = ({ buttonIcon, items, ...rest }) => {
    return (
        <>
            <ChakraMenu {...rest}>
                <Button
                    variant='ghost'
                    p={2}
                    _hover={{ bg: 'surface-brand-primary' }}
                    aria-label='menu-icon'
                    as={MenuButton}
                >
                    {buttonIcon}
                </Button>

                <MenuList zIndex={100}>
                    {items.map((item) =>
                        item.showConfirmation ? (
                            <MenuItemAlert {...item} />
                        ) : (
                            <MenuItem textStyle={'md-semibold'} {...item.menuItemProps}>
                                {item.label}
                            </MenuItem>
                        )
                    )}
                </MenuList>
            </ChakraMenu>
        </>
    );
};
