import { Box, Divider, Flex, Grid, Text } from '@chakra-ui/react';
import { AlertDialog } from '@Components';
import { SelectButtonGroup } from '@Components/SelectButtonGroup';
import TextInput from '@Components/TextInput';
import { useToast } from '@Hooks/useToast';
import { Dollar, Payment, Paypal, ScanDelete, Signature } from '@Icon';
import { useGetEventRefundStatisticsQuery, useUpdateEventMutation } from '@Redux/services/Event';
import { Event } from '@Redux/services/Event/types';
import { parseCurrency, parseError, parseWarning, roundUpValue } from '@Utilities';
import { round, times } from 'lodash';
import { Dispatch, FC, SetStateAction, useMemo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { EventStatus, PaymentTransactionTypes } from 'src/constants';
import type { PartialDeep } from 'type-fest';

const MAX = 100;
const MIN = 0;
interface IProps {
    show: boolean;
    event?: PartialDeep<Event>;
    onClose: () => void;
    setEventDetails: Dispatch<SetStateAction<PartialDeep<Event>>>;
    onCancel: (isCancelling: boolean) => void;
}

interface Step {
    step: number;
    title: string;
    subTitle: string;
    refundPercentage: number;
    overrideNsfCheck: boolean;
}

export const CancelAlert: FC<IProps> = ({ show, event, onCancel, onClose, setEventDetails }) => {
    if (!show) return;
    const { t } = useTranslation();
    const [refundPercentage, setRefundPercentage] = useState(MAX);
    const [previousRefundPercentage, setPreviousRefundPercentage] = useState<number>();
    const [step, setStep] = useState(0);
    const [overrideNsfCheck, setOverrideNsfCheck] = useState(false);
    const [title, setTitle] = useState<string>(
        event.isRefundable ? t('EventDetails.cancelAlertTitle') : t('EventDetails.cancelAlertTitle1')
    );
    const [subTitle, setSubTitle] = useState<string>(
        event.isRefundable ? t('EventDetails.cancelAlertSubTitle') : t('EventDetails.cancelAlertSubTitle1')
    );
    const [cancellationReason, setCancellationReason] = useState<string>();
    const toast = useToast();
    const [previousStep, setPreviousStep] = useState<Step[]>([
        {
            step: 0,
            title: t('EventDetails.cancelAlertTitle'),
            subTitle: t('EventDetails.cancelAlertSubTitle'),
            refundPercentage: MAX,
            overrideNsfCheck: false,
        },
    ]);
    const [error, setError] = useState('');

    const [updateEvent] = useUpdateEventMutation();
    const { data: { data: eventRefundStatistics } = {}, refetch: getRefundStats } = useGetEventRefundStatisticsQuery({
        id: event.eventId,
    });

    useEffect(() => {
        show && getRefundStats();
    }, [show]);

    const handleSubmit = async () => {
        const nextStep: Step = {
            step,
            title,
            subTitle,
            refundPercentage: previousRefundPercentage ?? 0,
            overrideNsfCheck,
        };
        /*
        TODO: See ticket 487 - need to fix balance discrepancy before re-enabling balance check

        const enoughFunds = !event.isRefundable || data[PaymentTransactionTypes.PayPalEventPayment] <= eventRefundStatistics.accountBalance;
        if (step === 0 && event.isRefundable) {
            //Refund percentage
            setStep(1);
            setTitle(t('CancelAlert.refundPercentageTitle'));
            const balance = parseCurrency(eventRefundStatistics.accountBalance);
            const asOfDate = format(parseISO(eventRefundStatistics.balanceDate), DATE_TIME_FORMAT);
            setSubTitle(t('CancelAlert.refundPercentageSubTitle', { balance, asOfDate }));
            setSubTitle(t('CancelAlert.refundPercentageSubTitle2'));
            setRefundPercentage(previousRefundPercentage ?? MIN);
            } else if (step === 1 && !enoughFunds) {
                //Confirm proceed with insufficient funds
                setStep(2);
                setTitle(t('CancelAlert.nsfCheckTitle', { date: format(parseISO(eventRefundStatistics.balanceDate), DATE_TIME_FORMAT) }));
                setSubTitle(t('CancelAlert.nsfCheckSubtitle'));
                setOverrideNsfCheck(true);
            } else if ((step === 0 && !event.isRefundable) || (step === 1 && enoughFunds) || step === 2) {
        */
        if (step === 0 && event.isRefundable) {
            //Refund percentage
            setStep(1);
            setTitle(t('CancelAlert.refundPercentageTitle'));
            setSubTitle(t('CancelAlert.refundPercentageSubTitle2'));
            setRefundPercentage(previousRefundPercentage ?? MIN);
            setOverrideNsfCheck(true);
        } else if ((step === 0 && !event.isRefundable) || step === 1 || step === 2) {
            //Reason
            setStep(3);
            setTitle(t('CancelAlert.reasonTitle', { refundPercentage: refundPercentage }));
            setSubTitle(t('CancelAlert.reasonSubTitle'));
        } else if (step === 3) {
            //Summary
            if (!cancellationReason) {
                setError(t('CancelAlert.reasonRequired'));
                return;
            }
            if (event.isRefundable) setError('');
            setStep(4);
            setTitle(t('CancelAlert.summaryTitle'));
            setSubTitle(
                t(`CancelAlert.summarySubTitle${event.isRefundable ? '' : '1'}`, { refundPercentage: refundPercentage })
            );
        }
        setPreviousStep([...previousStep, nextStep]);
        if (step === 4) {
            onCancel(true);
            onClose();
            updateEvent({
                eventId: event.eventId,
                status: EventStatus.Cancelled,
                cancellationReason,
                refundPercentage,
                overrideNsfCheck,
            })
                .unwrap()
                .then((response) => {
                    setEventDetails(response.data);
                    onClose();
                    if (response?.warningResult?.showAlert) {
                        parseWarning(toast, response);
                    } else {
                        toast({
                            status: 'success',
                            description:
                                event.isRefundable && refundPercentage > 0
                                    ? t('CancelAlert.toastRefundableCancelSuccess')
                                    : t('CancelAlert.toastCancelSuccess'),
                        });
                    }
                })
                .catch((err) => {
                    parseError(toast, err);
                })
                .finally(() => onCancel(false));
        }
    };

    const handleBackClick = () => {
        const prevStep = previousStep.pop();
        setPreviousStep([...previousStep]);
        if (!prevStep || (prevStep.step === 0 && step === 0)) {
            onClose();
        }
        if (prevStep.step === 0) {
            setPreviousRefundPercentage(refundPercentage);
            setRefundPercentage(MAX);
            setOverrideNsfCheck(false);
        }
        setStep(prevStep.step);
        setSubTitle(prevStep.subTitle);
        setTitle(prevStep.title);
        setOverrideNsfCheck(prevStep.overrideNsfCheck);
    };

    const labels = useMemo(
        () => ({
            [PaymentTransactionTypes.PayPalEventPayment]: t('CancelAlert.eWallet'),
            [PaymentTransactionTypes.CashEventPayment]: t('CancelAlert.cash'),
            [PaymentTransactionTypes.ChequeEventPayment]: t('CancelAlert.cheque'),
        }),
        []
    );

    const roundoffValue = useCallback(
        (value: number) => roundUpValue(round((value ?? 0) * (refundPercentage / MAX), 4), 2),
        [refundPercentage]
    );

    const icons = useMemo(
        () => ({
            [PaymentTransactionTypes.PayPalEventPayment]: <Paypal width='20px' height='20px' />,
            [PaymentTransactionTypes.CashEventPayment]: <Payment width='20px' height='20px' />,
            [PaymentTransactionTypes.ChequeEventPayment]: <Signature width='20px' height='20px' />,
        }),
        []
    );

    const data = useMemo(
        () => ({
            [PaymentTransactionTypes.PayPalEventPayment]: roundoffValue(
                eventRefundStatistics?.paymentStatistics.find(
                    (x) => x.measurement == PaymentTransactionTypes.PayPalEventPayment
                )?.value
            ),
            [PaymentTransactionTypes.CashEventPayment]: roundoffValue(
                eventRefundStatistics?.paymentStatistics.find(
                    (x) => x.measurement == PaymentTransactionTypes.CashEventPayment
                )?.value
            ),
            [PaymentTransactionTypes.ChequeEventPayment]: roundoffValue(
                eventRefundStatistics?.paymentStatistics.find(
                    (x) => x.measurement == PaymentTransactionTypes.ChequeEventPayment
                )?.value
            ),
        }),
        [eventRefundStatistics, refundPercentage]
    );

    const totalAmountPaid = useMemo(() => {
        let sum = 0.0;
        Object.keys(data).forEach((key) => {
            sum += data[key];
        });
        return parseFloat(sum.toFixed(2));
    }, [eventRefundStatistics?.paymentStatistics, refundPercentage]);

    return (
        <>
            <AlertDialog
                title={title}
                isOpen={show}
                alertType='error'
                message={subTitle}
                submitLabel={step == 4 ? t('CancelAlert.cancelEvent') : t('CancelAlert.proceed')}
                submitHandle={handleSubmit}
                submitButtonProps={{ variant: step === 4 ? 'error' : 'primary' }}
                cancelButtonLabel={step === 0 ? t('CancelAlert.back1') : t('CancelAlert.back')}
                cancelButtonProps={{
                    variant: 'outline',
                    onClick: handleBackClick,
                }}
                alertIcon={<ScanDelete width={'40px'} height='40px' fill='white' />}
                onClose={onClose}
            >
                {step === 1 && (
                    <SelectButtonGroup
                        groupProps={{
                            mt: '16px',
                            mb: '8px',
                        }}
                        value={refundPercentage}
                        onChange={(value: number) => setRefundPercentage(value)}
                        options={times(5).map((i) => ({
                            value: i * 25,
                            label: `${i * 25}%`,
                        }))}
                    />
                )}
                {(step === 0 || step === 1 || (step === 4 && event.isRefundable)) && (
                    <>
                        <Grid
                            mt={'16px'}
                            column={{ md: 2, sm: 1 }}
                            columnGap={10}
                            gridAutoFlow='dense'
                            templateColumns={{
                                base: 'repeat(1, 1fr)',
                                md: 'repeat(2, 1fr)',
                            }}
                            rowGap={4}
                            alignItems='center'
                        >
                            {Object.keys(data).map((measurement) => {
                                return (
                                    <>
                                        <Flex gap={'12px'} alignItems='center'>
                                            {icons[measurement]}
                                            <Text textStyle='sm-normal'>{labels[measurement]}</Text>
                                        </Flex>
                                        <Text textStyle='sm-normal' textAlign='end'>
                                            {parseCurrency(data[measurement])}
                                        </Text>
                                    </>
                                );
                            })}
                        </Grid>
                        <Divider mt={'12px'} />
                        <Grid
                            mt={'12px'}
                            column={{ md: 2, sm: 1 }}
                            columnGap={10}
                            gridAutoFlow='dense'
                            templateColumns={{
                                base: 'repeat(1, 1fr)',
                                md: 'repeat(2, 1fr)',
                            }}
                            rowGap={4}
                            alignItems='center'
                        >
                            <Box>
                                <Flex>
                                    <Dollar width='24px' height='24px' />
                                    <Text textStyle='md-bold'>{t('CancelAlert.total')}</Text>
                                </Flex>
                            </Box>
                            <Text textAlign='end' textStyle='md-bold'>
                                {parseCurrency(totalAmountPaid)}
                            </Text>
                        </Grid>
                    </>
                )}
                {step === 3 && (
                    <>
                        <TextInput
                            mt={'12px'}
                            value={cancellationReason}
                            isLocked={false}
                            onChangeText={(value) => {
                                setCancellationReason(value);
                                setError('');
                            }}
                            placeholder={t('CancelAlert.cancellationReason')}
                            error={error}
                            isInvalid={Boolean(error)}
                        />
                    </>
                )}
                {step == 4 && (
                    <Flex mt={'16px'}>
                        <Text textStyle='sm-semibold' as='span'>
                            {t('CancelAlert.reason')}
                        </Text>
                        <Text textStyle='sm-normal' ml={2} as='span'>
                            {cancellationReason}
                        </Text>
                    </Flex>
                )}
            </AlertDialog>
        </>
    );
};

interface IProps {
    show: boolean;
    onClose: () => void;
}
export const CancelEvent: FC<IProps> = ({ show, event, onClose, onCancel, setEventDetails }) => {
    if (!show) return;
    const { t } = useTranslation();
    const [updateEvent] = useUpdateEventMutation();
    const [step, setStep] = useState(0);
    const [cancellationReason, setCancellationReason] = useState<string>();
    const [error, setError] = useState('');
    const toast = useToast();

    const handleSubmit = () => {
        if (step === 0) {
            if (!cancellationReason) {
                setError(t('CancelAlert.reasonRequired'));
                return;
            }
            setStep(1);
        } else {
            onCancel(true);
            onClose();
            updateEvent({
                eventId: event.eventId,
                status: EventStatus.Cancelled,
                refundPercentage: 0,
            })
                .unwrap()
                .then((response) => {
                    setEventDetails(response.data);
                    onClose();
                    if (response?.warningResult?.showAlert) {
                        parseWarning(toast, response);
                    } else {
                        toast({
                            description: t('CancelAlert.toastCancelSuccess'),
                        });
                    }
                })
                .catch((err) => {
                    parseError(toast, err);
                })
                .finally(() => onCancel(false));
        }
    };

    const handleBackClick = () => {
        if (step === 1) {
            setStep(0);
        } else {
            onClose();
        }
    };

    return (
        <AlertDialog
            isOpen={show}
            title={step == 0 ? t('CancelEvent.reasonTitle') : t('CancelEvent.summaryTitle')}
            message={step === 0 ? t('CancelEvent.reasonSubTitle') : t('CancelEvent.summarySubTitle')}
            submitHandle={handleSubmit}
            alertType='error'
            onClose={handleBackClick}
            submitButtonProps={{
                variant: step === 1 ? 'error' : 'primary',
            }}
            cancelButtonProps={{
                variant: 'outline',
                onClick: handleBackClick,
            }}
            submitLabel={step === 1 ? t('CancelEvent.submitButtonLabel') : t('CancelEvent.submitButtonLabel1')}
            cancelButtonLabel={step === 0 ? t('CancelEvent.cancelButtonLabel') : t('CancelEvent.cancelButtonLabel1')}
            alertIcon={<ScanDelete width={'40px'} height='40px' fill='white' />}
        >
            {step === 0 && (
                <>
                    <TextInput
                        mt={'12px'}
                        value={cancellationReason}
                        isLocked={false}
                        onChangeText={(value) => {
                            setCancellationReason(value);
                            setError('');
                        }}
                        placeholder={t('CancelAlert.cancellationReason')}
                        error={error}
                        isInvalid={Boolean(error)}
                    />
                </>
            )}
            {step === 1 && (
                <Flex mt={'16px'}>
                    <Text textStyle='sm-semibold' as='span'>
                        {t('CancelAlert.reason')}
                    </Text>
                    <Text textStyle='sm-normal' ml={2} as='span'>
                        {cancellationReason}
                    </Text>
                </Flex>
            )}
        </AlertDialog>
    );
};
