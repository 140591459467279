import { FC, useState, useEffect } from 'react';

import { FormControl, FormLabel, Stack } from '@chakra-ui/react';
import DatePicker from '@Components/DateTimeInput';

import Drawer from '@Components/Drawer';
import Textarea from '@Components/Textarea';
import TextInput from '@Components/TextInput';
import { useTranslation } from 'react-i18next';
import { Accessor, getMutableForAccessor, parseError } from '@Utilities';
import { set } from 'lodash';
import Status from '../Status';
import { EventReschedule } from '@Redux/services/Event/types';
import { useToast } from '@Hooks/useToast';
import { useCreateEventRescheduleMutation } from '@Redux/services/Event';
import DateTimeInput from '@Components/DateTimeInput';
import { Validator } from './validator';
import { DrawerFooterActions } from '@Components/DrawerFooterActions';

export interface EventRescheduleForm {
    eventRescheduleId?: string;
    name: string;
    eventId: string;
    newDueDate: Date;
    newStartDate: Date;
    newEndDate: Date;
    newFollowUpDate: Date;
    reason: string;
    owner: string;
    createdOn: Date;
}

interface IProps {
    open: boolean;
    onClose: () => void;
    onSubmit: () => void;
    initialData?: Partial<EventReschedule>;
    locked?: boolean;
}

function dataToFormData(eventData: Partial<EventReschedule>): EventRescheduleForm {
    const dueDate = eventData?.dueDate ? new Date(eventData.dueDate) : null;
    const startDate = eventData?.startDate ? new Date(eventData.startDate) : null;
    const endDate = eventData?.endDate ? new Date(eventData.endDate) : null;
    const followUpDate = eventData?.followUpDate ? new Date(eventData.followUpDate) : null;
    const createdDate = eventData?.createdOn ? new Date(eventData.createdOn) : null;

    const formData = {
        eventRescheduleId: eventData?.eventRescheduleId,
        name: eventData?.title || '',
        eventId: eventData?.eventId,
        newDueDate: dueDate,
        newStartDate: startDate,
        newEndDate: endDate,
        newFollowUpDate: followUpDate,
        reason: eventData?.reasonToShare || '',
        owner: eventData?.createdBy || '',
        createdOn: createdDate,
    };
    return formData;
}

function formDataToData(formData: Partial<EventRescheduleForm>): EventReschedule {
    const dueDate = formData?.newDueDate ? new Date(formData.newDueDate).toISOString() : null;
    const startDate = formData.newStartDate ? new Date(formData.newStartDate).toISOString() : null;
    const endDate = formData.newEndDate ? new Date(formData.newEndDate).toISOString() : null;
    const followUpDate = formData?.newFollowUpDate ? new Date(formData.newFollowUpDate).toISOString() : null;

    const eventData = {
        eventId: formData?.eventId,
        title: formData?.name,
        startDate,
        endDate,
        dueDate,
        followUpDate,
        reasonToShare: formData?.reason,
    };
    return eventData;
}

const RescheduleForm: FC<IProps> = ({ open, onClose, onSubmit, initialData, locked }) => {
    const [eventReschedule, setEventReschedule] = useState<Partial<EventRescheduleForm>>({});
    const [errors, setErrors] = useState<Record<string, string>>({});
    const { t } = useTranslation();
    const toast = useToast();
    const [createReschedule, { isLoading }] = useCreateEventRescheduleMutation();

    useEffect(() => {
        if (!open) {
            return;
        }
        setEventReschedule(dataToFormData(initialData));
    }, [initialData, open]);

    const handleChange = (value: string | Date | boolean, property: Accessor) => {
        const newEventReschedule = { ...eventReschedule };
        set(newEventReschedule, property, value);
        setEventReschedule(newEventReschedule);
        const newErrors = { ...errors };
        delete newErrors[property.join('.')];
        setErrors(newErrors);
    };

    const validate = () => {
        const nextErrors: Record<string, string> = {};
        const validationRules = Validator(t);

        validationRules.forEach(({ accessorPath, validator, required, message }) => {
            const finalMutable = getMutableForAccessor(eventReschedule, accessorPath);
            const finalProperty = accessorPath[accessorPath.length - 1];
            if ((required || finalMutable[finalProperty]) && !validator(finalMutable[finalProperty])) {
                nextErrors[accessorPath.join('.')] = message;
            }
        });

        if (Object.keys(nextErrors).length) {
            setErrors(nextErrors);
            return false;
        }
        return true;
    };

    const handleSubmit = async () => {
        if (!validate()) return;
        setErrors({});
        const data = formDataToData(eventReschedule);
        if (!data.eventId) {
            toast({
                status: 'error',
                description: 'Invalid event',
                title: 'Error',
            });
            return;
        }
        try {
            await createReschedule(data).unwrap();
            toast({
                status: 'success',
                description: t('RescheduleForm.success'),
                isClosable: true,
            });
            onSubmit();
        } catch (error) {
            parseError(toast, error);
        }
    };

    const disabled = locked || isLoading;

    return (
        <Drawer
            isOpen={open}
            onClose={onClose}
            isForm={true}
            title={t('RescheduleForm.title')}
            subtitle={t('RescheduleForm.subTitle')}
            footer={
                <DrawerFooterActions
                    isEditable={true}
                    closeButtonProps={{
                        isDisabled: isLoading,
                        label: t('RescheduleForm.closeDrawer'),
                        onClick: onClose,
                    }}
                    saveButtonProps={{
                        onClick: handleSubmit,
                        label: t('RescheduleForm.save'),
                        isDisabled: disabled,
                    }}
                />
            }
        >
            <Stack gap={4} position='relative'>
                <Status
                    date={initialData?.createdOn && new Date(initialData?.createdOn)}
                    name={initialData?.createdBy}
                />
                <TextInput
                    label={t('RescheduleForm.name')}
                    isLocked={disabled}
                    onChangeText={(value) => handleChange(value, ['name'])}
                    value={eventReschedule.name}
                />
                <DatePicker
                    onDateChange={(value) => handleChange(value, ['newDueDate'])}
                    label={t('RescheduleForm.dueDate')}
                    isLocked={disabled}
                    date={eventReschedule.newDueDate}
                    size='md'
                    error={errors['newDueDate']}
                    isInvalid={Boolean(errors['newDueDate'])}
                />

                <FormControl style={{ display: 'flex', alignItems: 'center' }}>
                    <FormLabel textStyle={'sm-medium'} flexBasis='30%' color={disabled && 'text-disabled'}>
                        {t('RescheduleForm.startDate')}
                    </FormLabel>
                    <DateTimeInput
                        hasTimeComponent
                        onDateChange={(value) => handleChange(value, ['newStartDate'])}
                        isLocked={disabled}
                        date={eventReschedule.newStartDate && new Date(eventReschedule.newStartDate)}
                        size='md'
                        error={errors['newStartDate']}
                        isInvalid={Boolean(errors['newStartDate'])}
                    />
                </FormControl>
                <FormControl style={{ display: 'flex', alignItems: 'center' }}>
                    <FormLabel textStyle={'sm-medium'} flexBasis='30%' color={disabled && 'text-disabled'}>
                        {t('RescheduleForm.endDate')}
                    </FormLabel>
                    <DateTimeInput
                        hasTimeComponent
                        onDateChange={(value) => handleChange(value, ['newEndDate'])}
                        isLocked={disabled}
                        date={eventReschedule.newEndDate && new Date(eventReschedule.newEndDate)}
                        size='md'
                        error={errors['newEndDate']}
                        isInvalid={Boolean(errors['newEndDate'])}
                    />
                </FormControl>

                <DatePicker
                    onDateChange={(value) => handleChange(value, ['newFollowUpDate'])}
                    label={t('RescheduleForm.followUpDate')}
                    isLocked={disabled}
                    date={eventReschedule.newFollowUpDate}
                    size='md'
                />
                <Textarea
                    onChangeText={(value) => handleChange(value, ['reason'])}
                    label={t('RescheduleForm.reason')}
                    isLocked={disabled}
                    value={eventReschedule.reason}
                />
                <TextInput
                    onChangeText={(value) => handleChange(value, ['owner'])}
                    label={t('RescheduleForm.owner')}
                    isLocked={true}
                    value={eventReschedule.owner}
                />

                {eventReschedule?.eventRescheduleId && (
                    <FormControl style={{ display: 'flex', alignItems: 'center' }}>
                        <FormLabel textStyle={'sm-medium'} flexBasis='30%' color={'text-disabled'}>
                            {t('RescheduleForm.createdOn')}
                        </FormLabel>
                        <DateTimeInput
                            hasTimeComponent
                            onDateChange={(value) => handleChange(value, ['createdOn'])}
                            isLocked={disabled}
                            date={eventReschedule.createdOn && new Date(eventReschedule.createdOn)}
                            size='md'
                        />
                    </FormControl>
                )}
            </Stack>
        </Drawer>
    );
};

export default RescheduleForm;
