import { Menu, MenuItem } from '@Components/Menu';
import { KebabMenu, Save } from '@Icon';
import { Box, Button, ButtonProps, Flex } from '@chakra-ui/react';
import { FC } from 'react';

interface IProps {
    isEditable: boolean;
    closeButtonProps?: ButtonProps & { label: string };
    saveButtonProps?: ButtonProps & { label: string };
    nextButtonProps?: ButtonProps & { label: string };
    actionMenuItems?: MenuItem[];
}

export const DrawerFooterActions: FC<IProps> = ({
    isEditable,
    closeButtonProps,
    saveButtonProps,
    nextButtonProps,
    actionMenuItems,
}) => {
    return (
        <Flex w='100%' bg={'white'} gap={4} justifyContent='space-between'>
            <Button textStyle={'md-semibold'} {...closeButtonProps}>
                {closeButtonProps.label}
            </Button>
            <Box>
                {saveButtonProps && isEditable && (
                    <Button
                        mr={2}
                        variant={!nextButtonProps ? 'primary' : null}
                        leftIcon={<Save />}
                        {...saveButtonProps}
                    >
                        {saveButtonProps.label}
                    </Button>
                )}
                {nextButtonProps && isEditable && (
                    <Button textStyle={'md-semibold'} variant='primary' {...nextButtonProps}>
                        {nextButtonProps.label}
                    </Button>
                )}
                {actionMenuItems && <Menu items={actionMenuItems} buttonIcon={<KebabMenu width={24} height={24} />} />}
            </Box>
        </Flex>
    );
};
