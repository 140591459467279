import * as React from 'react';
function SvgPictureAsPdf(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <mask
                id='picture-as-pdf_svg__a'
                style={{
                    maskType: 'alpha',
                }}
                maskUnits='userSpaceOnUse'
                x={0}
                y={0}
                width={24}
                height={24}
            >
                <path fill='#D9D9D9' d='M0 0h24v24H0z' />
            </mask>
            <g mask='url(#picture-as-pdf_svg__a)'>
                <path
                    d='M9.173 12.363h1v-2h1c.284 0 .521-.096.713-.288a.968.968 0 00.287-.713v-1a.967.967 0 00-.287-.712.967.967 0 00-.713-.288h-2v5zm1-3v-1h1v1h-1zm2.947 3h2c.283 0 .52-.096.712-.288a.968.968 0 00.287-.712v-3a.967.967 0 00-.287-.713.968.968 0 00-.713-.288h-2v5zm1-1v-3h1v3h-1zm2.964 1h1v-2h1v-1h-1v-1h1v-1h-2v5zm-8.875 5.703c-.63 0-1.166-.222-1.61-.665a2.193 2.193 0 01-.665-1.61V3.934c0-.63.222-1.166.665-1.61.444-.443.98-.665 1.61-.665h11.857c.63 0 1.166.222 1.61.665.443.444.665.98.665 1.61v11.857c0 .63-.222 1.166-.666 1.61-.443.443-.98.665-1.61.665H8.21zm0-2.275h11.857V3.934H8.209v11.857zm-4.275 6.55c-.63 0-1.166-.222-1.61-.666a2.193 2.193 0 01-.665-1.61V5.936h2.275v14.13h14.132v2.276H3.934z'
                    fill='#1C1B1F'
                />
            </g>
        </svg>
    );
}
export default SvgPictureAsPdf;
