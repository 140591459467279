import { baseApi, POST, GET } from '../base';
import { BaseResponse } from '../commonTypes';
import {
    BasicLookup,
    GetSystemUsersParams,
    GradeIdParam,
    PlaceParams,
    SearchTextParams,
    StudentIdParam,
    StudentsFromSchoolParam,
    SubCategoriesParams,
} from './types';

const LookupApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getFieldTripTypes: builder.query<BaseResponse<BasicLookup[]>, void>({
            query: () => ({
                url: `Lookup/GetFieldTripTypes`,
                method: GET,
            }),
        }),
        getGenderTypes: builder.query<BaseResponse<BasicLookup[]>, void>({
            query: () => ({
                url: `Lookup/GetGenderTypes`,
                method: GET,
            }),
        }),
        getInvitedGrades: builder.query<BaseResponse<BasicLookup[]>, void>({
            query: () => ({
                url: `Lookup/GetInvitedGrades`,
                method: GET,
            }),
        }),
        getOrganizedBy: builder.query<BaseResponse<BasicLookup[]>, void>({
            query: () => ({
                url: `Lookup/GetOrganizedBy`,
                method: GET,
            }),
        }),
        getActivityTypes: builder.query<BaseResponse<BasicLookup[]>, void>({
            query: () => ({
                url: `Lookup/GetActivityTypes`,
                method: GET,
            }),
        }),
        getParticipantTypes: builder.query<BaseResponse<BasicLookup[]>, void>({
            query: () => ({
                url: `Lookup/GetParticipantTypes`,
                method: GET,
            }),
        }),
        getStatusCodes: builder.query<BaseResponse<BasicLookup[]>, void>({
            query: () => ({
                url: `Lookup/GetStatusCodes`,
                method: GET,
            }),
        }),
        getCategories: builder.query<BaseResponse<BasicLookup[]>, void>({
            query: () => ({
                url: `Lookup/GetCategories`,
                method: GET,
            }),
        }),
        getSubCategories: builder.query<BaseResponse<BasicLookup[]>, SubCategoriesParams>({
            query: (params) => ({
                url: `Lookup/GetSubCategories`,
                method: GET,
                params,
            }),
        }),
        getSchools: builder.query<BaseResponse<BasicLookup[]>, SearchTextParams>({
            query: (params) => ({
                url: `Lookup/GetSchools`,
                method: GET,
                params,
            }),
        }),
        getCostItemRequirednessType: builder.query<BaseResponse<BasicLookup[]>, SearchTextParams>({
            query: (params) => ({
                url: `Lookup/GetCostItemRequirednessType`,
                method: GET,
                params,
            }),
        }),
        getEventResponseCostItemRequirednessType: builder.query<BaseResponse<BasicLookup[]>, SearchTextParams>({
            query: (params) => ({
                url: `Lookup/GetEventResponseCostItemRequirednessType`,
                method: GET,
                params,
            }),
        }),
        getModesOfTransport: builder.query<BaseResponse<BasicLookup[]>, SearchTextParams>({
            query: (params) => ({
                url: `Lookup/GetModesOfTransport`,
                method: GET,
                params,
            }),
        }),
        getSchoolActivities: builder.query<BaseResponse<BasicLookup[]>, SearchTextParams>({
            query: (params) => ({
                url: `Lookup/GetSchoolActivities`,
                method: GET,
                params,
            }),
        }),
        getPlaceAutocomplete: builder.query<BaseResponse<BasicLookup[]>, PlaceParams>({
            query: (params) => ({
                url: `Lookup/PlaceAutocomplete`,
                method: POST,
                params,
            }),
        }),
        getPlaceDetail: builder.query<BaseResponse<BasicLookup[]>, PlaceParams>({
            query: (params) => ({
                url: `Lookup/PlaceDetail`,
                method: POST,
                params,
            }),
        }),
        getStatusCodesForCostItems: builder.query<BaseResponse<BasicLookup[]>, SearchTextParams>({
            query: (params) => ({
                url: `Lookup/GetStatusCodesForCostItems`,
                method: 'GET',
                params,
            }),
        }),
        getEventResponseStatusCodes: builder.query<BaseResponse<BasicLookup[]>, SearchTextParams>({
            query: (params) => ({
                url: `Lookup/GetEventResponseStatusCodes`,
                method: 'GET',
                params,
            }),
        }),
        getCostItemGroupType: builder.query<BaseResponse<BasicLookup[]>, void>({
            query: () => ({
                url: `Lookup/GetCostItemGroupType `,
                method: 'GET',
            }),
        }),
        getPaymentMethods: builder.query<BaseResponse<BasicLookup[]>, void>({
            query: () => ({
                url: `Lookup/GetPaymentMethods `,
                method: 'GET',
            }),
        }),
        getSchoolsByGrade: builder.mutation<BaseResponse<BasicLookup[]>, GradeIdParam>({
            query: (params) => ({
                url: `Lookup/GetSchoolsByGrade`,
                method: 'GET',
                params,
            }),
        }),
        getActiveGuardiansForStudent: builder.query<BaseResponse<BasicLookup[]>, StudentIdParam>({
            query: (params) => ({
                url: `Lookup/GetActiveGuardiansForStudent`,
                method: 'GET',
                params,
            }),
        }),
        getPhoneCallStatusCodes: builder.query<BaseResponse<BasicLookup[]>, void>({
            query: () => ({
                url: `Lookup/GetPhoneCallStatusCodes`,
                method: 'GET',
            }),
        }),
        getPhoneCallPriorityTypes: builder.query<BaseResponse<BasicLookup[]>, void>({
            query: () => ({
                url: `Lookup/GetPhoneCallPriorityTypes`,
                method: 'GET',
            }),
        }),
        getAbsenceStatusCodes: builder.query<BaseResponse<BasicLookup[]>, void>({
            query: () => ({
                url: `Lookup/GetAbsenceStatusCodes`,
                method: 'GET',
            }),
        }),
        getAbsenceReasonCodes: builder.query<BaseResponse<BasicLookup[]>, void>({
            query: () => ({
                url: `Lookup/GetAbsenceReasonCodes`,
                method: 'GET',
            }),
        }),
        getAttendanceStatusCodes: builder.query<BaseResponse<BasicLookup[]>, void>({
            query: () => ({
                url: `Lookup/GetAttendanceStatusCodes`,
                method: 'GET',
            }),
        }),
        getPhoneCallActionTypes: builder.query<BaseResponse<BasicLookup[]>, void>({
            query: () => ({
                url: `Lookup/GetPhoneCallActionTypes`,
                method: 'GET',
            }),
        }),
        getPhoneCallTypes: builder.query<BaseResponse<BasicLookup[]>, void>({
            query: () => ({
                url: `Lookup/GetPhoneCallTypes`,
                method: 'GET',
            }),
        }),
        getStudentActivityStatusCodes: builder.query<BaseResponse<BasicLookup[]>, void>({
            query: () => ({
                url: `Lookup/GetStudentActivityStatusCodes`,
                method: 'GET',
            }),
        }),
        getPhoneCallAbsenceReasonTypes: builder.query<BaseResponse<BasicLookup[]>, void>({
            query: () => ({
                url: `Lookup/GetPhoneCallAbsenceReasonTypes`,
                method: 'GET',
            }),
        }),
        getAbsenceReasonById: builder.query<BaseResponse<BasicLookup[]>, SearchTextParams>({
            query: (params) => ({
                url: `Lookup/GetAbsenceReasonById`,
                method: 'GET',
                params,
            }),
        }),
        getSystemUsers: builder.mutation<BaseResponse<BasicLookup[]>, GetSystemUsersParams>({
            query: (body) => ({
                url: `Lookup/GetSystemUsers`,
                method: 'POST',
                body,
            }),
        }),
        getStudentsFromSchool: builder.mutation<BaseResponse<BasicLookup[]>, StudentsFromSchoolParam>({
            query: (body) => ({
                url: `Lookup/GetStudents`,
                method: 'POST',
                body,
            }),
        }),
        getStatusCodesForPaymentTransactions: builder.query<BaseResponse<BasicLookup[]>, SearchTextParams>({
            query: (params) => ({
                url: `Lookup/GetStatusCodesForPaymentTransactions`,
                method: 'GET',
                params,
            }),
        }),
        getAttendanceStatusReason: builder.query<BaseResponse<BasicLookup[]>, void>({
            query: () => ({
                url: `Lookup/GetStatusCodesForAttendanceTaken`,
                method: 'GET',
            }),
        }),
        getAttendanceFollowUpStatus: builder.query<BaseResponse<BasicLookup[]>, void>({
            query: () => ({
                url: `Lookup/GetFollowUpStatusForAttendanceTaken`,
                method: 'GET',
            }),
        }),
        getReportsStatus: builder.query<BaseResponse<BasicLookup[]>, void>({
            query: () => ({
                url: `Lookup/GetReportsStatusCodes`,
                method: 'GET',
            }),
        }),
        getStatusForStudentRegistrationRequest: builder.query<BaseResponse<BasicLookup[]>, SearchTextParams>({
            query: (params) => ({
                url: `Lookup/GetStatusForStudentRegistrationRequest`,
                method: 'GET',
                params,
            }),
        }),
        getStatusForStudentLinkingsRequest: builder.query<BaseResponse<BasicLookup[]>, SearchTextParams>({
            query: (params) => ({
                url: `Lookup/GetStatusForStudentLinkingsRequest`,
                method: 'GET',
                params,
            }),
        }),
        getCitiesRequest: builder.query<BaseResponse<BasicLookup[]>, SearchTextParams>({
            query: (params) => ({
                url: `mfr/Lookup/GetCities`,
                method: 'GET',
                params,
            }),
        }),
        getProvincesRequest: builder.query<BaseResponse<BasicLookup[]>, SearchTextParams>({
            query: (params) => ({
                url: `mfr/Lookup/GetProvinces`,
                method: 'GET',
                params,
            }),
        }),
        getCountriesRequest: builder.query<BaseResponse<BasicLookup[]>, SearchTextParams>({
            query: (params) => ({
                url: `mfr/Lookup/GetCountries`,
                method: 'GET',
                params,
            }),
        }),
        getLanguagesRequest: builder.query<BaseResponse<BasicLookup[]>, SearchTextParams>({
            query: (params) => ({
                url: `mfr/Lookup/GetLanguages`,
                method: 'GET',
                params,
            }),
        }),
        getAboriginalSelfRequest: builder.query<BaseResponse<BasicLookup[]>, SearchTextParams>({
            query: (params) => ({
                url: `mfr/Lookup/GetAboriginalSelf`,
                method: 'GET',
                params,
            }),
        }),
        getRelationshipsRequest: builder.query<BaseResponse<BasicLookup[]>, SearchTextParams>({
            query: (params) => ({
                url: `mfr/Lookup/GetRelationships`,
                method: 'GET',
                params,
            }),
        }),
        getLanguageTypesRequest: builder.query<BaseResponse<BasicLookup[]>, SearchTextParams>({
            query: (params) => ({
                url: `mfr/Lookup/GetLanguageTypes`,
                method: 'GET',
                params,
            }),
        }),
        getGradesRequest: builder.query<BaseResponse<BasicLookup[]>, SearchTextParams>({
            query: (params) => ({
                url: `mfr/Lookup/GetGrades`,
                method: 'GET',
                params,
            }),
        }),
        getGendersRequest: builder.query<BaseResponse<BasicLookup[]>, SearchTextParams>({
            query: (params) => ({
                url: `mfr/Lookup/GetGenders`,
                method: 'GET',
                params,
            }),
        }),
        getPreferredGendersRequest: builder.query<BaseResponse<BasicLookup[]>, SearchTextParams>({
            query: (params) => ({
                url: `mfr/Lookup/GetPreferredGenders`,
                method: 'GET',
                params,
            }),
        }),
        getAdmissionReasonTypesRequest: builder.query<BaseResponse<BasicLookup[]>, SearchTextParams>({
            query: (params) => ({
                url: `Lookup/GetAdmissionReasonTypes`,
                method: 'GET',
                params,
            }),
        }),
        getSourceDocumentTypesRequest: builder.query<BaseResponse<BasicLookup[]>, SearchTextParams>({
            query: (params) => ({
                url: `Lookup/GetSourceDocumentTypes`,
                method: 'GET',
                params,
            }),
        }),
        getBeforeOrAfterSchoolCareOptions: builder.query<BaseResponse<BasicLookup[]>, SearchTextParams>({
            query: (params) => ({
                url: `Lookup/GetBeforeOrAfterSchoolCareOptions`,
                method: 'GET',
                params,
            }),
        }),
        getBoardResidenceStatusCodesRequest: builder.query<BaseResponse<BasicLookup[]>, SearchTextParams>({
            query: (params) => ({
                url: `Lookup/GetBoardResidenceStatusCodes`,
                method: 'GET',
                params,
            }),
        }),
        getResidenceStatusCodesRequest: builder.query<BaseResponse<BasicLookup[]>, SearchTextParams>({
            query: (params) => ({
                url: `Lookup/GetResidenceStatusCodes`,
                method: 'GET',
                params,
            }),
        }),
        getFeatures: builder.query<BaseResponse<Record<string, boolean>>, void>({
            query: () => ({
                url: `Lookup/GetFeatures`,
                method: 'GET',
            }),
        }),
    }),
});

export const {
    useGetFeaturesQuery,
    useGetFieldTripTypesQuery,
    useGetGenderTypesQuery,
    useGetInvitedGradesQuery,
    useGetOrganizedByQuery,
    useGetActivityTypesQuery,
    useGetParticipantTypesQuery,
    useGetStatusCodesQuery,
    useGetCategoriesQuery,
    useGetSubCategoriesQuery,
    useGetSchoolsQuery,
    useGetCostItemRequirednessTypeQuery,
    useGetEventResponseCostItemRequirednessTypeQuery,
    useGetModesOfTransportQuery,
    useGetSchoolActivitiesQuery,
    useGetPlaceAutocompleteQuery,
    useGetPlaceDetailQuery,
    useGetStatusCodesForCostItemsQuery,
    useGetEventResponseStatusCodesQuery,
    useGetCostItemGroupTypeQuery,
    useGetPaymentMethodsQuery,
    useGetSchoolsByGradeMutation,
    useGetActiveGuardiansForStudentQuery,
    useLazyGetActiveGuardiansForStudentQuery,
    useGetPhoneCallPriorityTypesQuery,
    useGetPhoneCallStatusCodesQuery,
    useGetAbsenceStatusCodesQuery,
    useGetAbsenceReasonCodesQuery,
    useGetAttendanceStatusCodesQuery,
    useGetPhoneCallActionTypesQuery,
    useGetPhoneCallTypesQuery,
    useGetStudentActivityStatusCodesQuery,
    useGetAbsenceReasonByIdQuery,
    useGetPhoneCallAbsenceReasonTypesQuery,
    useGetSystemUsersMutation,
    useGetStudentsFromSchoolMutation,
    useGetStatusCodesForPaymentTransactionsQuery,
    useGetAttendanceStatusReasonQuery,
    useGetAttendanceFollowUpStatusQuery,
    useGetReportsStatusQuery,
    useGetStatusForStudentRegistrationRequestQuery,
    useGetStatusForStudentLinkingsRequestQuery,
    useGetCitiesRequestQuery,
    useGetProvincesRequestQuery,
    useGetCountriesRequestQuery,
    useGetLanguagesRequestQuery,
    useGetAboriginalSelfRequestQuery,
    useGetRelationshipsRequestQuery,
    useGetLanguageTypesRequestQuery,
    useGetGradesRequestQuery,
    useGetGendersRequestQuery,
    useGetPreferredGendersRequestQuery,
    useGetAdmissionReasonTypesRequestQuery,
    useGetSourceDocumentTypesRequestQuery,
    useGetBeforeOrAfterSchoolCareOptionsQuery,
    useGetBoardResidenceStatusCodesRequestQuery,
    useGetResidenceStatusCodesRequestQuery,
} = LookupApi;

export default LookupApi;
