import { theme as originalTheme, extendTheme } from '@chakra-ui/react';
import colors from './foundations/colors';
import fontSizes from './foundations/fontSizes';
import styles from './styles';
import '@fontsource/inter';
import Table from './components/Table';
import Badge from './components/Badge';
import Button from './components/Button';
import Input from './components/Input';
import Form from './components/Form';
import Switch from './components/Switch';
import Alert from './components/Alert';
import { Checkbox } from './components/Checkbox';
import semanticTokens from './tokens';
// import space from './space';
import radii from './radii';
import textStyles from './textStyles';
import Progress from './components/Progress';

const overrides = {
    ...styles,
    colors,
    fontSizes,
    textStyles,
    // space,
    radii,
    components: {
        Table,
        Badge,
        Button,
        Input,
        Form,
        Switch,
        Progress,
        Alert: {
            ...Alert,
            variants: {
                ...Alert.variants,
                solid: (props) => {
                    const { colorScheme } = props;
                    if (colorScheme !== 'orange') {
                        return originalTheme.components.Alert.variants.solid(props);
                    }
                    return {
                        container: {
                            bg: '#9B5800',
                        },
                    };
                },
            },
        },
        Checkbox,
        Popover: {
            variants: {
                small: {
                    content: {
                        width: '15rem',
                    },
                },
            },
        },
        Drawer: {
            sizes: {
                xl: {
                    dialog: {
                        maxW: '90%',
                    },
                },
            },
        },
        Select: {
            baseStyle: {
                field: {
                    paddingRight: '60px',
                },
            },
        },
    },
    semanticTokens,
};

const theme = extendTheme(overrides);

export default theme;
