import { UpdateStatus } from 'src/constants';

export const links = ['all', 'submitted', 'approved', 'deleted', 'rejected'] as const;

export type UpdateStatusParam = (typeof links)[number];

export type SortByProps = { id: string; desc: boolean };

export const getStatusCode = (e: UpdateStatusParam) => {
    switch (e) {
        case 'all':
            return [];
        case 'submitted':
            return [UpdateStatus.Submitted];
        case 'approved':
            return [UpdateStatus.Approved];
        case 'deleted':
            return [UpdateStatus.Deleted];
        case 'rejected':
            return [UpdateStatus.Rejected];
    }
};

export const getStatusByCode = (e: number) => {
    switch (e) {
        case UpdateStatus.Submitted:
            return 'submitted';
        case UpdateStatus.Approved:
            return 'approved';
        case UpdateStatus.Deleted:
            return 'deleted';
        case UpdateStatus.Rejected:
            return 'rejected';
    }
};

export const getSortByField = (s: SortByProps) => {
    let sBy = s;
    switch (s?.id) {
        case 'UpdateStatus':
            sBy = { ...s, id: 'status' };
            break;
        case 'StudentName':
            sBy = { ...s, id: 'StudentName' };
            break;
        case 'GuardianName':
            sBy = { ...s, id: 'GuardianName' };
            break;
        case 'GuardianPhoneNumber':
            sBy = { ...s, id: 'GuardianPhoneNumber' };
            break;
        case 'GuardianEmail':
            sBy = { ...s, id: 'GuardianEmail' };
            break;
        case 'CreatedOn':
            sBy = { ...s, id: 'CreatedOn' };
            break;
    }
    return sBy;
};
