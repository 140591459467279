import { Box, Grid } from '@chakra-ui/react';
import FormHeader from '@Components/FormHeader';
import TextInput from '@Components/TextInput';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { StepProps } from '@Pages/EventDetails/types';
import Switch from '@Components/Switch';
import {
    useGetActivityTypesQuery,
    useGetFieldTripTypesQuery,
    useGetModesOfTransportQuery,
    useGetSchoolActivitiesQuery,
} from '@Redux/services/LookupApi';
import SelectInput from '@Components/SelectInput';
import TypeaheadInput from '@Components/TypeaheadInput';
import DatePicker from '@Components/DateTimeInput';
import { ACTIVITY_TYPE_FIELD_TRIP } from 'src/constants';
import { ManageDatePickerProps, ManageSwitchInputProps, ManageTextProps } from '@Hooks';

const Activity: React.FC<StepProps> = ({
    id,
    manageFieldPropFactory,
    textFieldPropFactory,
    selectFieldPropFactory,
    typeaheadPropFactory,
    switchFieldPropFactory,
    eventDetails,
}) => {
    const { t } = useTranslation();

    const { data: { data: fieldTripTypes = [] } = {} } = useGetFieldTripTypesQuery();
    const { data: { data: activityTypes = [] } = {} } = useGetActivityTypesQuery();
    const { data: { data: schoolActivities = [] } = {} } = useGetSchoolActivitiesQuery({});

    const isFieldTrip = useMemo(
        () => eventDetails?.activityTypeId?.toString() === `${ACTIVITY_TYPE_FIELD_TRIP}`,
        [eventDetails]
    );
    const { data: { data: modeOfTransport = [] } = {} } = useGetModesOfTransportQuery({});
    return (
        <FormHeader id={id} label={t('ActivityType.headerLabel')}>
            <Box pb={4}>
                <SelectInput
                    {...selectFieldPropFactory(t('ActivityType.type'), ['activityTypeId'])}
                    {...manageFieldPropFactory<ManageTextProps>(['activityTypeId'], 'onChangeValue', 'value')}
                    options={activityTypes}
                    col={1}
                />
            </Box>
            {isFieldTrip && (
                <>
                    <Grid
                        column={{ md: 2, sm: 1 }}
                        columnGap={10}
                        gridAutoFlow='dense'
                        templateColumns={{
                            base: 'repeat(1, 1fr)',
                            md: 'repeat(2, 1fr)',
                        }}
                        rowGap={4}
                    >
                        <TypeaheadInput
                            {...typeaheadPropFactory(t('ActivityType.name'), ['activity', 'activityNameId'])}
                            {...manageFieldPropFactory<ManageTextProps>(
                                ['activity', 'activityNameId'],
                                'onSelection',
                                'value'
                            )}
                            options={schoolActivities}
                        />

                        <TextInput
                            {...textFieldPropFactory(t('ActivityType.injuries'), [
                                'activity',
                                'activityNamePossibleInjuries',
                            ])}
                            {...manageFieldPropFactory<ManageTextProps>(
                                ['activity', 'activityNamePossibleInjuries'],
                                'onChangeText',
                                'value'
                            )}
                        />

                        <TypeaheadInput
                            {...typeaheadPropFactory(t('ActivityType.modeOfTransportation'), [
                                'activity',
                                'modeOfTransportId',
                            ])}
                            {...manageFieldPropFactory<ManageTextProps>(
                                ['activity', 'modeOfTransportId'],
                                'onSelection',
                                'value'
                            )}
                            options={modeOfTransport}
                        />

                        <TextInput
                            {...textFieldPropFactory(t('ActivityType.injuries'), [
                                'activity',
                                'modeOfTransportPossibleInjuries',
                            ])}
                            {...manageFieldPropFactory<ManageTextProps>(
                                ['activity', 'modeOfTransportPossibleInjuries'],
                                'onChangeText',
                                'value'
                            )}
                        />

                        <TextInput
                            {...textFieldPropFactory(t('ActivityType.location'), ['activity', 'location'])}
                            {...manageFieldPropFactory<ManageTextProps>(
                                ['activity', 'location'],
                                'onChangeText',
                                'value'
                            )}
                        />

                        <TextInput
                            {...textFieldPropFactory(t('ActivityType.teacherInCharge'), [
                                'activity',
                                'teacherInCharge',
                            ])}
                            {...manageFieldPropFactory<ManageTextProps>(
                                ['activity', 'teacherInCharge'],
                                'onChangeText',
                                'value'
                            )}
                        />

                        <TextInput
                            {...textFieldPropFactory(t('ActivityType.emergencyContact'), [
                                'activity',
                                'emergencyContact',
                            ])}
                            {...manageFieldPropFactory<ManageTextProps>(
                                ['activity', 'emergencyContact'],
                                'onChangeText',
                                'value'
                            )}
                        />

                        {isFieldTrip && (
                            <SelectInput
                                {...selectFieldPropFactory(t('ActivityType.fieldtripType'), [
                                    'activity',
                                    'fieldTripType',
                                ])}
                                {...manageFieldPropFactory<ManageTextProps>(
                                    ['activity', 'fieldTripType'],
                                    'onChangeValue',
                                    'value'
                                )}
                                options={fieldTripTypes}
                            />
                        )}

                        <Box pt={5} pb={5}>
                            <Switch
                                {...switchFieldPropFactory(t('ActivityType.approvalChecked'), [
                                    'activity',
                                    'isApproved',
                                ])}
                                {...manageFieldPropFactory<ManageSwitchInputProps>(
                                    ['activity', 'isApproved'],
                                    'onChangeSwitch',
                                    'isChecked'
                                )}
                            />
                        </Box>
                    </Grid>
                    <DatePicker
                        {...textFieldPropFactory(t('ActivityType.approvalOn'), ['activity', 'approvedOnDate'])}
                        {...manageFieldPropFactory<ManageDatePickerProps>(
                            ['activity', 'approvedOnDate'],
                            'onDateChange',
                            'date'
                        )}
                        col={1}
                        size='md'
                    />
                </>
            )}
        </FormHeader>
    );
};

export default Activity;
