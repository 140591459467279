import FormHeader from '@Components/FormHeader';
import { Avatars } from '@Icon';
import Header from '@Pages/EventDetails/components/Summary/components/Header';
import { PhoneCallStepProps } from '../../types';
import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const GuardianCard = ({ label, name, homePhoneNumber, mobilePhoneNumber, workPhoneNumber }) => {
    const { t } = useTranslation();
    if (!name && !homePhoneNumber && !mobilePhoneNumber && !workPhoneNumber) {
        return;
    }
    return (
        <Box flexBasis={'48%'}>
            <FormHeader label={label} contentPadding={1}>
                <Flex direction={'column'}>
                    <Flex direction={'row'}>
                        <Text textStyle={'sm-normal'} flexBasis={'40%'} mr={3}>
                            {t('PhoneCall.name')}
                        </Text>
                        <Text textStyle={'sm-medium'} width={'100%'} pl={4} borderLeft={'1px solid transparent'}>
                            {name}
                        </Text>
                    </Flex>
                    <Flex direction={'row'}>
                        <Text textStyle={'sm-normal'} flexBasis={'40%'} mr={3}>
                            {t('PhoneCall.mobile')}
                        </Text>
                        <Text textStyle={'sm-medium'} width={'100%'} pl={4} borderLeft={'1px solid transparent'}>
                            {mobilePhoneNumber}
                        </Text>
                    </Flex>
                    <Flex direction={'row'}>
                        <Text textStyle={'sm-normal'} flexBasis={'40%'} mr={3}>
                            {t('PhoneCall.work')}
                        </Text>
                        <Text textStyle={'sm-medium'} width={'100%'} pl={4} borderLeft={'1px solid transparent'}>
                            {workPhoneNumber}
                        </Text>
                    </Flex>
                    <Flex direction={'row'}>
                        <Text textStyle={'sm-normal'} flexBasis={'40%'} mr={3}>
                            {t('PhoneCall.home')}
                        </Text>
                        <Text textStyle={'sm-medium'} width={'100%'} pl={4} borderLeft={'1px solid transparent'}>
                            {homePhoneNumber}
                        </Text>
                    </Flex>
                </Flex>
            </FormHeader>
        </Box>
    );
};

const Guardians = React.forwardRef<HTMLDivElement, PhoneCallStepProps>(({ id, phoneCallDetails }) => {
    const { t } = useTranslation();
    return (
        <Box id={id} my={6}>
            <Header mb={6} title={t('PhoneCall.subheader.guardians')} icon={<Avatars />} />
            <Flex direction={'row'} flexWrap={'wrap'} gap={4}>
                <GuardianCard
                    label={t('PhoneCall.primary')}
                    name={phoneCallDetails?.primaryGuardian?.name}
                    homePhoneNumber={phoneCallDetails?.primaryGuardian?.homePhoneNumber}
                    mobilePhoneNumber={phoneCallDetails?.primaryGuardian?.mobilePhoneNumber}
                    workPhoneNumber={phoneCallDetails?.primaryGuardian?.workPhoneNumber}
                />
                <GuardianCard
                    label={t('PhoneCall.secondaryGuardian')}
                    name={phoneCallDetails?.secondaryGuardian?.name}
                    homePhoneNumber={phoneCallDetails?.secondaryGuardian?.homePhoneNumber}
                    mobilePhoneNumber={phoneCallDetails?.secondaryGuardian?.mobilePhoneNumber}
                    workPhoneNumber={phoneCallDetails?.secondaryGuardian?.workPhoneNumber}
                />
                <GuardianCard
                    label={t('PhoneCall.emergencyContact1')}
                    name={phoneCallDetails?.emergencyContact1?.name}
                    homePhoneNumber={phoneCallDetails?.emergencyContact1?.homePhoneNumber}
                    mobilePhoneNumber={phoneCallDetails?.emergencyContact1?.mobilePhoneNumber}
                    workPhoneNumber={phoneCallDetails?.emergencyContact1?.workPhoneNumber}
                />
                <GuardianCard
                    label={t('PhoneCall.emergencyContact2')}
                    name={phoneCallDetails?.emergencyContact2?.name}
                    homePhoneNumber={phoneCallDetails?.emergencyContact2?.homePhoneNumber}
                    mobilePhoneNumber={phoneCallDetails?.emergencyContact2?.mobilePhoneNumber}
                    workPhoneNumber={phoneCallDetails?.emergencyContact2?.workPhoneNumber}
                />
            </Flex>
        </Box>
    );
});

export default Guardians;
