import {
    Textarea as ChakraTextarea,
    InputGroup,
    FormControl,
    FormHelperText,
    FormLabel,
    InputLeftElement,
    InputRightElement,
    Box,
    TextareaProps as ChakraTextareaProps,
} from '@chakra-ui/react';
import { Lock } from '@Icon';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface TextareaProps extends ChakraTextareaProps {
    isLocked: boolean;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    label: string;
    error?: string;
    stacked?: boolean;
    onChangeText?: (value: string) => void;
    col?: number;
}

const Textarea: FC<TextareaProps> = ({
    isLocked,
    leftIcon,
    rightIcon,
    label,
    error,
    isInvalid,
    stacked,
    onChangeText,
    value,
    col,
    ...rest
}) => {
    const { t } = useTranslation();
    return (
        <FormControl
            isInvalid={isInvalid}
            display={{ base: 'block', lg: 'flex' }}
            alignItems={stacked ? 'start' : 'baseline'}
            flexDirection={stacked ? 'column' : 'row'}
        >
            {label && (
                <FormLabel
                    color={(rest.isDisabled || isLocked) && 'text-disabled'}
                    flexBasis={col === 1 ? '12.5%' : '30%'}
                    textStyle={'sm-medium'}
                >
                    {label}
                    {rest.isRequired && '*'}
                </FormLabel>
            )}
            <InputGroup>
                {leftIcon && <InputLeftElement>{leftIcon}</InputLeftElement>}
                <Box w='100%' as='span'>
                    <ChakraTextarea
                        background='surface-primary'
                        onChange={(event) => onChangeText(event.target.value)}
                        isDisabled={isLocked}
                        title={(rest.isRequired && t('InputFields.requiredTitle')) || ''}
                        isInvalid={isInvalid}
                        value={value ?? ''}
                        _focusVisible={{ borderColor: 'surface-brand-primary' }}
                        {...rest}
                    />
                    {error && (
                        <FormHelperText p={2} textStyle='sm-normal' color={isInvalid && 'text-error'}>
                            {error}
                        </FormHelperText>
                    )}
                </Box>
                {(rightIcon || isLocked) && <InputRightElement>{isLocked ? <Lock /> : rightIcon}</InputRightElement>}
            </InputGroup>
        </FormControl>
    );
};

export default Textarea;
