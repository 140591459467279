export enum invitedGrades {
    JK = 951030000,
    K = 951030001,
    Grade1 = 951030002,
    Grade2 = 951030003,
    Grade3 = 951030004,
    Grade4 = 951030005,
    Grade5 = 951030006,
    Grade6 = 951030007,
    Grade7 = 951030008,
    Grade8 = 951030009,
    Grade9 = 951030010,
    Grade10 = 951030011,
    Grade11 = 951030012,
    Grade12 = 951030013,
}

export type PhoneCallFormData = {
    action?: string;
    activityId: string;
    absenceDuration: string | number;
    absenceReason: string | number;
};
