import { Grid } from '@chakra-ui/react';
import FormHeader from '@Components/FormHeader';
import { StepProps } from '@Pages/EventResponses/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Textarea from '@Components/Textarea';
import { ManageSwitchInputProps, TextareaPropFactory } from '@Hooks';
import Switch from '@Components/Switch';

const Notes: React.FC<StepProps> = ({ id, switchFieldPropFactory, manageFieldPropFactory, textAreaPropFactory }) => {
    const { t } = useTranslation();

    return (
        <FormHeader id={id} label={t('EventResponseDetail.Notes.headerLabel')}>
            <Grid
                column={{ md: 1, sm: 1 }}
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(1, 1fr)',
                }}
                rowGap={4}
            >
                <Switch
                    {...switchFieldPropFactory(t('EventResponseDetail.Notes.questionsAnswered'), [
                        'detail',
                        'questionsAnswered',
                    ])}
                    {...manageFieldPropFactory<ManageSwitchInputProps>(
                        ['detail', 'questionsAnswered'],
                        'onChangeSwitch',
                        'isChecked'
                    )}
                />
                <Textarea
                    {...textAreaPropFactory(t('EventResponseDetail.Notes.administrationComment'), [
                        'detail',
                        'administrationComment',
                    ])}
                    {...manageFieldPropFactory<TextareaPropFactory>(
                        ['detail', 'administrationComment'],
                        'onChangeText',
                        'value'
                    )}
                />
            </Grid>
        </FormHeader>
    );
};

export default Notes;
