import { Flex, Stack } from '@chakra-ui/react';
import AvatarLabel from '@Components/AvatarLabel';
import Sidebar from '@Components/Sidebar';

import { Accessor } from '@Utilities';
import { useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useForms } from '@Hooks';
import { ReactIcon } from '@Icon';
import LatestActivities from './components/LatestActivities';
import ContactInfo from './components/ContactInfo';
import Address from './components/Address';
import Transportation from './components/Transportation';
import StudentDetails from './components/StudentDetails';
import { useGetParticipantDetailsQuery } from '@Redux/services/Student';
import { IStudentRecord } from '@Redux/services/Student/types';
import { editableFieldsForStudents } from './types';
import { isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';
import { DetailPageSkeleton } from '@Components/DetailPageSkeleton';
import { useGetParticipantPhotoQuery } from '@Redux/services/Participants';

type IRouteParams = {
    id: string;
};

const StudentDetailsPage = () => {
    const containerRef = useRef<HTMLDivElement>(null);
    const params = useParams<IRouteParams>();
    const {
        refetch: getStudentDetails,
        data: { data: studentDetailsData } = {},
        isLoading,
    } = useGetParticipantDetailsQuery({ id: params.id }, { skip: !params.id });

    const { t } = useTranslation();

    const getCommonProps = (accessorPath: Accessor[]) => {
        const accesor = accessorPath.join('.');
        const item = editableFieldsForStudents[accesor];
        const hasStatus = !isUndefined(item) ? item.editableStatus : false;
        const isLocked = isUndefined(item) ? true : !hasStatus;
        const isRequired = hasStatus && item.required;
        return {
            isLocked,
            isRequired,
        };
    };

    const {
        formData: studentData,
        errors,
        manageFieldPropFactory,
        selectFieldPropFactory,
        textAreaPropFactory,
        textFieldPropFactory,
        typeaheadPropFactory,
        setFormData: setStudentDetail,
    } = useForms<IStudentRecord>(studentDetailsData, getCommonProps);

    const { refetch: getStudentPhoto, data: { data: studentPhoto } = {} } = useGetParticipantPhotoQuery({
        id: params.id,
    });

    useEffect(() => {
        getStudentDetails();
        getStudentPhoto();
    }, [params.id]);

    useEffect(() => {
        setStudentDetail(studentDetailsData);
    }, [studentDetailsData]);

    const sideBarItems = useMemo(
        () => ({
            header: (
                <AvatarLabel src={studentPhoto} name={studentDetailsData?.fullName} subTitle={t('Sidebar.Student')} />
            ),
            groups: [
                {
                    items: [
                        {
                            label: t('ContactInfo.details'),
                            id: '#contact-information',
                            icon: <ReactIcon />,
                            active: false,
                        },
                        {
                            label: 'Guardians',
                            id: '#guardians',
                            icon: <ReactIcon />,
                            active: false,
                        }, // for future sprint
                        {
                            label: t('Student.address'),
                            id: '#address',
                            icon: <ReactIcon />,
                            active: false,
                        },
                        {
                            label: 'Emergency Contacts',
                            id: '#emergency-contacts',
                            icon: <ReactIcon />,
                            active: false,
                        }, // for future use
                        {
                            label: t('Student.studentDetails'),
                            id: '#student-details',
                            icon: <ReactIcon />,
                            active: false,
                        },
                        {
                            label: t('Student.transportationDetails'),
                            id: '#transportation-details',
                            icon: <ReactIcon />,
                            active: false,
                        },
                    ].filter((f) => f.id !== '#emergency-contacts' && f.id !== '#guardians'), // Remove this code when guradians and emergency contacts has been included in future
                },
            ],
        }),
        [studentData, studentPhoto]
    );
    if (isLoading || !studentData) {
        <DetailPageSkeleton />;
    }

    return (
        studentData && (
            <Flex>
                <Sidebar
                    {...sideBarItems}
                    footerDetails={{
                        createdOn: studentData?.createdOn,
                        createdByFullName: studentData?.createdByFullName,
                        modifiedByFullName: studentData?.modifiedByFullName,
                        modifiedOn: studentData?.modifiedOn,
                    }}
                />

                <Flex
                    align={'flex-start'}
                    direction={'column'}
                    ref={containerRef}
                    overflow={'hidden'}
                    width='100%'
                    bg='surface-primary'
                    p={3}
                    gap={8}
                    pl={2}
                >
                    <LatestActivities
                        errors={errors}
                        manageFieldPropFactory={manageFieldPropFactory}
                        setStudentDetail={setStudentDetail}
                        studentDetail={studentData}
                        textFieldPropFactory={textFieldPropFactory}
                        selectFieldPropFactory={selectFieldPropFactory}
                        typeaheadPropFactory={typeaheadPropFactory}
                    />

                    <Stack
                        borderRadius='20px 0 0 0'
                        borderWidth='2px 0px 2px 2px'
                        borderStyle='solid'
                        borderColor='border-primary'
                        w={'full'}
                        p={8}
                        bg={'surface-secondary'}
                        gap={8}
                    >
                        <ContactInfo
                            id='contact-information'
                            errors={errors}
                            manageFieldPropFactory={manageFieldPropFactory}
                            setStudentDetail={setStudentDetail}
                            studentDetail={studentData}
                            textFieldPropFactory={textFieldPropFactory}
                            typeaheadPropFactory={typeaheadPropFactory}
                        />
                        {/* <Guardians
                        id='guardians'
                        ref={(el) => (sectionRefs.current[5] = el)}
                        errors={errors}
                        manageFieldPropFactory={manageFieldPropFactory}
                        setStudentDetail={setStudentDetail}
                        studentDetail={studentData}
                        textFieldPropFactory={textFieldPropFactory}
                        typeaheadPropFactory={typeaheadPropFactory}
                    /> */}
                        <Address
                            id='address'
                            errors={errors}
                            manageFieldPropFactory={manageFieldPropFactory}
                            setStudentDetail={setStudentDetail}
                            studentDetail={studentData}
                            textFieldPropFactory={textFieldPropFactory}
                            textAreaPropFactory={textAreaPropFactory}
                            typeaheadPropFactory={typeaheadPropFactory}
                            selectFieldPropFactory={selectFieldPropFactory}
                        />
                        <StudentDetails
                            id='student-details'
                            errors={errors}
                            manageFieldPropFactory={manageFieldPropFactory}
                            setStudentDetail={setStudentDetail}
                            studentDetail={studentData}
                            textFieldPropFactory={textFieldPropFactory}
                            textAreaPropFactory={textAreaPropFactory}
                            typeaheadPropFactory={typeaheadPropFactory}
                            selectFieldPropFactory={selectFieldPropFactory}
                        />
                        <Transportation
                            id='transportation-details'
                            errors={errors}
                            manageFieldPropFactory={manageFieldPropFactory}
                            setStudentDetail={setStudentDetail}
                            studentDetail={studentData}
                            textFieldPropFactory={textFieldPropFactory}
                            textAreaPropFactory={textAreaPropFactory}
                            typeaheadPropFactory={typeaheadPropFactory}
                            selectFieldPropFactory={selectFieldPropFactory}
                        />
                    </Stack>
                </Flex>
            </Flex>
        )
    );
};

export default StudentDetailsPage;
