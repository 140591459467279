import { EventResponse } from '@Redux/services/Event/types';
import { SearchIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { CellProps, Row } from 'react-table';

const ActionCell: React.FC<CellProps<EventResponse> & { onOpen: (eventResponse: Row<EventResponse>) => void }> = ({
    row,
    onOpen,
}) => {
    const { t } = useTranslation();

    const handleOnOpen = (e) => {
        e.stopPropagation();
        onOpen(row);
    };

    return <SearchIcon aria-label={t('OpenInNew')} onClick={handleOnOpen} />;
};

export default ActionCell;
