import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Stack, useDisclosure } from '@chakra-ui/react';
import AvatarLabel from '@Components/AvatarLabel';
import Sidebar from '@Components/Sidebar';
import Steps from '@Components/Steps';
import { Activity as TypeActivity } from '@Redux/services/commonTypes';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Activity from './components/Activity';
import Schedules from './components/Schedules';
import Summary from './components/Summary';
import { EventStatuses } from './types';
import RescheduleTable from './components/RescheduleTable';
import Participants from './components/Participants';
import ChartOfAccounts from './components/ChartOfAccounts';
import CostItems from './components/CostItems';
import Questions from './components/Questions';
import { EventStatus as EventStatusComponent } from './components/EventStatus';
import _ from 'lodash';
import SvgSummary from '@Icon/Summary';
import SvgCostItems from '@Icon/CostItems';
import SvgQuestions from '@Icon/Questions';
import SvgResponses from '@Icon/Responses';
import Spinner from '@Components/Spinner';
import Notes from '@Components/Notes';
import { EventStatus } from 'src/constants';
import { Prompt } from '@Components/Prompt';
import { Copy, Cycle, KebabMenu, ScanDelete, Trash } from '@Icon';
import { AlertDialog } from '@Components';
import TextInput from '@Components/TextInput';
import { Menu, MenuItem } from '@Components/Menu';
import { CancelAlert, CancelEvent } from './components/CancelAlert';
import { PreviewButton } from './components/PreviewButton';
import { useEventDetail } from '@Hooks/useEventDetail';
import { DetailPageSkeleton } from '@Components/DetailPageSkeleton';
import { PagedResult, Note } from '@Redux/services/commonTypes';
import { Print } from '@Components/Icons';
import { MFR_BASE_URL } from '@Redux/services/base';
import EventResponses from './components/EventStatus/components/EventResponses';

const EventDetailsPage = () => {
    const { t } = useTranslation();
    const {
        eventDetails,
        onGoingConsent,
        isClosedOrCanceled,
        isCancelled,
        isProposed,
        isPublished,
        isValidated,
        showValidateAlert,
        showPublishAlert,
        setShowPublishAlert,
        setShowValidateAlert,
        setShowCancelAlert,
        setShowDeleteAlert,
        handleNext,
        handleAddNote,
        handleClone,
        handleCloseClone,
        showCloneAlert,
        showCancelAlert,
        showCloseAlert,
        setShowCloseAlert,
        cloneEventLoading,
        newEventName,
        setNewEventName,
        setCloneError,
        cloneError,
        showDeleteAlert,
        handleDelete,
        handleCloseDelete,
        deleteEventLoading,
        setEventDetails,
        setIsCancelling,
        isLoading,
        containerRef,
        hasPendingChange,
        handleManualSave,
        steps,
        nextButtonLoading,
        getNextStatus,
        handleDeleteNote,
        handleUpdateNote,
        isClosedOrPreviouslyPublished,
        isDirty,
        setIsDirty,
        setShowCloneAlert,
        stepsProps,
        getEventData,
        handleNotePageChange,
        isNotesLoading,
    } = useEventDetail();

    const sideBarItems = useMemo(
        () => ({
            header: <AvatarLabel name={eventDetails?.name} subTitle={eventDetails?.name} />,
            groups: [
                {
                    items: _.remove(
                        [
                            {
                                label: t('EventDetails.summary'),
                                id: '#summary',
                                icon: <SvgSummary />,
                                active: false,
                            },
                            {
                                label: t('EventDetails.costItems'),
                                id: '#cost-items',
                                icon: <SvgCostItems />,
                                active: false,
                            },
                            {
                                label: t('EventDetails.questions'),
                                id: '#questions',
                                icon: <SvgQuestions />,
                                active: false,
                            },
                            {
                                label: t('EventDetails.responses'),
                                id: '#responses',
                                icon: <SvgResponses />,
                                active: false,
                            },
                        ],
                        (i) => {
                            const exclude: string[] = [];
                            if (onGoingConsent) {
                                exclude.push('#cost-items');
                            }

                            return !exclude.includes(i.id);
                        }
                    ),
                },
            ],
        }),
        [eventDetails]
    );

    const actionMenuItems = useMemo<MenuItem[]>(
        () => [
            {
                label: t('EventDetails.print'),
                menuItemProps: {
                    icon: <Print width={'16px'} height={'16px'} />,
                    onClick: () => window.open(`${MFR_BASE_URL}/EventOffer/EventPrint/${eventDetails?.eventCode}`),
                    isDisabled: true, // !isPublished,
                },
            },
            {
                label: t('EventDetails.cancel'),
                menuItemProps: {
                    icon: <ScanDelete width={'16px'} height={'16px'} />,
                    onClick: () => setShowCancelAlert(true),
                    isDisabled: isClosedOrCanceled,
                },
            },
            {
                label: t('EventDetails.delete'),
                menuItemProps: {
                    icon: <Trash width={'16px'} height={'16px'} />,
                    onClick: () => setShowDeleteAlert(true),
                    isDisabled: ![EventStatus.Proposed, EventStatus.Validated].includes(eventDetails?.status),
                },
            },
        ],
        [eventDetails?.status, isClosedOrCanceled]
    );

    const disclosureProps = useDisclosure();

    if (isLoading || !eventDetails) {
        return <DetailPageSkeleton />;
    }
    return (
        <>
            {showValidateAlert && (
                <AlertDialog
                    isOpen={showValidateAlert}
                    title={t('EventDetails.proposeAlertTitle')}
                    message={t('EventDetails.proposeAlertSubTitle')}
                    submitHandle={handleNext}
                    alertType='error'
                    onClose={() => setShowValidateAlert(false)}
                    submitButtonProps={{
                        variant: 'primary',
                    }}
                    cancelButtonProps={{
                        variant: 'outline',
                        onClick: () => setShowValidateAlert(false),
                    }}
                    submitLabel={t('EventDetails.proposeAlertSubmitLabel')}
                    cancelButtonLabel={t('EventDetails.proposeAlertCancelLabel')}
                />
            )}
            {showPublishAlert && (
                <AlertDialog
                    isOpen={showPublishAlert}
                    title={t('EventDetails.publishAlertTitle')}
                    message={t('EventDetails.publishAlertSubTitle', {
                        studentsCount: eventDetails?.participants.memberCount,
                    })}
                    submitHandle={handleNext}
                    alertType='error'
                    onClose={() => setShowPublishAlert(false)}
                    submitButtonProps={{
                        variant: 'primary',
                    }}
                    cancelButtonProps={{
                        variant: 'outline',
                        onClick: () => setShowPublishAlert(false),
                    }}
                    submitLabel={t('EventDetails.publishAlertSubmitLabel')}
                    cancelButtonLabel={t('EventDetails.publishAlertCancelLabel')}
                />
            )}
            {showCloseAlert && (
                <AlertDialog
                    isOpen={showCloseAlert}
                    title={t('EventDetails.closeAlertTitle')}
                    message={t('EventDetails.closeAlertSubTitle')}
                    submitHandle={handleNext}
                    alertType='error'
                    onClose={() => setShowCloseAlert(false)}
                    submitButtonProps={{
                        variant: 'primary',
                    }}
                    cancelButtonProps={{
                        variant: 'outline',
                        onClick: () => setShowCloseAlert(false),
                    }}
                    submitLabel={t('EventDetails.closeAlertSubmitLabel')}
                    cancelButtonLabel={t('EventDetails.closeAlertCancelLabel')}
                />
            )}
            <AlertDialog
                title={t('EventDetails.cloneTitle')}
                isOpen={showCloneAlert}
                alertType='brand'
                message={t('EventDetails.cloneMessage')}
                submitLabel={t('EventDetails.cloneSubmit')}
                cancelButtonLabel={t('EventDetails.cloneCancel')}
                submitHandle={handleClone}
                submitLeftIcon={
                    <div style={{ marginRight: 4 }}>
                        <Copy fill='white' width={20} height={20} />
                    </div>
                }
                onClose={handleCloseClone}
                cancelButtonProps={{
                    variant: 'outline',
                }}
                disabled={cloneEventLoading}
            >
                <TextInput
                    isLocked={false}
                    mt={2}
                    value={newEventName}
                    onChangeText={(value) => {
                        setNewEventName(value);
                        setCloneError('');
                    }}
                    error={cloneError}
                    isInvalid={Boolean(cloneError)}
                />
            </AlertDialog>
            <AlertDialog
                title={t('EventDetails.deleteTitle')}
                isOpen={showDeleteAlert}
                alertType='error'
                message={t('EventDetails.deleteMessage')}
                submitLabel={t('EventDetails.deleteSubmit')}
                submitHandle={handleDelete}
                submitLeftIcon={
                    <div style={{ marginRight: 4 }}>
                        <Trash fill='white' width={20} height={20} />
                    </div>
                }
                onClose={handleCloseDelete}
                disabled={deleteEventLoading}
            />
            {eventDetails?.status === EventStatus.Published && (
                <CancelAlert
                    setEventDetails={setEventDetails}
                    show={showCancelAlert}
                    event={eventDetails}
                    onClose={() => setShowCancelAlert(false)}
                    onCancel={(value) => setIsCancelling(value)}
                />
            )}
            {(eventDetails?.status === EventStatus.Proposed || eventDetails?.status === EventStatus.Validated) && (
                <CancelEvent
                    setEventDetails={setEventDetails}
                    show={showCancelAlert}
                    event={eventDetails}
                    onClose={() => setShowCancelAlert(false)}
                    onCancel={(value) => setIsCancelling(value)}
                />
            )}
            {!eventDetails && isLoading && <Spinner />}
            {eventDetails && (
                <Flex>
                    <Sidebar
                        {...sideBarItems}
                        footerDetails={{
                            cancelledOn: eventDetails?.cancelDate,
                            closedOn: eventDetails?.closeDate,
                            createdOn: eventDetails?.createdOn,
                            createdByFullName: eventDetails?.createdByFullName,
                            modifiedByFullName: eventDetails?.modifiedByFullName,
                            modifiedOn: eventDetails?.modifiedOn,
                            disabled: !hasPendingChange,
                            onSave: !isClosedOrCanceled ? handleManualSave : null,
                        }}
                    />

                    <Flex ref={containerRef} overflow={'hidden'} width='100%'>
                        <form style={{ width: '100%' }}>
                            <Box pt={8} maxWidth='100%'>
                                <Box pr={4}>
                                    <Flex justifyContent='space-between' direction={{ base: 'column', md: 'row' }}>
                                        <Steps
                                            steps={steps}
                                            disabled={eventDetails?.status === EventStatus.Cancelled}
                                        />
                                        <div>
                                            {(isPublished || isValidated) && (
                                                <PreviewButton eventCode={eventDetails?.eventCode} />
                                            )}
                                            <Button
                                                onClick={() => setShowCloneAlert(true)}
                                                textStyle={'md-semibold'}
                                                isDisabled={hasPendingChange}
                                                mr={3}
                                                title={t('PreviewButton.cloneTitle')}
                                            >
                                                {t('PreviewButton.cloneTitle')}
                                            </Button>

                                            <Button
                                                onClick={handleNext}
                                                textStyle={'md-semibold'}
                                                variant={nextButtonLoading ? 'brand-inverted' : 'primary'}
                                                rightIcon={
                                                    nextButtonLoading ? (
                                                        <Cycle className='spin-loading' width='20px' height='20px' />
                                                    ) : (
                                                        <ArrowForwardIcon width='20px' height='20px' />
                                                    )
                                                }
                                                isDisabled={hasPendingChange || isClosedOrCanceled}
                                                mr={2}
                                            >
                                                <Trans
                                                    i18nKey={
                                                        nextButtonLoading
                                                            ? 'EventDetails.nextStepLoading'
                                                            : 'EventDetails.nextStep'
                                                    }
                                                    values={{
                                                        seperator: !isCancelled ? ':' : '',
                                                        stepLabel: EventStatuses[getNextStatus(eventDetails?.status)],
                                                    }}
                                                />
                                            </Button>
                                            <Menu
                                                items={actionMenuItems}
                                                buttonIcon={<KebabMenu width={24} height={24} />}
                                            />
                                        </div>
                                    </Flex>
                                </Box>
                                <Stack bg='surface-secondary' p={3} mt={5} spacing={8} borderLeftRadius={15}>
                                    <Summary id='summary' {...stepsProps} />
                                    <Activity {...stepsProps} />
                                    <Schedules {...stepsProps} />
                                    <RescheduleTable eventDetails={eventDetails} onSubmit={() => getEventData()} />
                                    <Participants {...stepsProps} handleManualSave={handleManualSave} />
                                    <ChartOfAccounts {...stepsProps} />
                                    <Notes
                                        {...stepsProps}
                                        notes={eventDetails?.eventNotes?.eventNotesInfo as Note[]}
                                        activities={eventDetails?.eventEmails?.emails as TypeActivity[]}
                                        onAddNote={async (noteText: string) => {
                                            handleAddNote(noteText);
                                        }}
                                        notePagination={eventDetails?.eventNotes?.pagedResult as PagedResult}
                                        onDeleteNote={(annotationId: string) => handleDeleteNote(annotationId)}
                                        onUpdateNote={(noteText: string, annotationId: string) =>
                                            handleUpdateNote(noteText, annotationId)
                                        }
                                        isNotesLoading={isNotesLoading}
                                        onNextPage={handleNotePageChange}
                                        isNoteEditable={isValidated || isProposed}
                                    />
                                    {!onGoingConsent && <CostItems id='cost-items' {...stepsProps} />}
                                    <Questions
                                        id='questions'
                                        {...stepsProps}
                                        setEventDetails={setEventDetails}
                                        eventDetails={eventDetails}
                                    />
                                    {(isPublished || isClosedOrPreviouslyPublished) && (
                                        <>
                                            {onGoingConsent ? (
                                                <EventResponses
                                                    {...disclosureProps}
                                                    eventDetails={eventDetails}
                                                    {...stepsProps}
                                                />
                                            ) : (
                                                <EventStatusComponent id='responses' {...stepsProps} />
                                            )}
                                        </>
                                    )}
                                    {isDirty && (
                                        <Prompt
                                            title={t('EventDetails.title')}
                                            message={t('EventDetails.message')}
                                            submitLabel={t('EventDetails.leave')}
                                            cancelLabel={t('EventDetails.cancelButtonLabel')}
                                            dirty={isDirty}
                                            onLeave={() => setIsDirty(false)}
                                        />
                                    )}
                                </Stack>
                            </Box>
                        </form>
                    </Flex>
                </Flex>
            )}
        </>
    );
};

export default EventDetailsPage;
