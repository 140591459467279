import { EventResponseDetail } from '@Redux/services/EventResponse/types';
import { ValidatorType } from '@Utilities';
import { TFunction } from 'react-i18next';
import { isBoolean } from 'lodash';
import { EVENT_RESPONSE_CONFIRMED } from 'src/constants';
import type { PartialDeep } from 'type-fest';
import { editableFieldsByStatus } from './types';

export function Validator(
    eventResponseDetail: PartialDeep<EventResponseDetail>,
    t: TFunction,
    autoSave: boolean
): ValidatorType[] {
    return [
        {
            accessorPath: ['detail', 'teamId'],
            validator: Boolean,
            message: t('EventDetails.genericRequired'),
            ...editableFieldsByStatus['detail.teamId'],
        },
        {
            accessorPath: ['detail', 'acceptingGuardianId'],
            validator: (value) => {
                if (eventResponseDetail.detail.guardianNotFoundInList || autoSave) return true;
                return Boolean(value);
            },
            message: t('EventDetails.genericRequired'),
            ...editableFieldsByStatus['detail.acceptingGuardianId'],
        },
        {
            accessorPath: ['detail', 'guardianNotFoundInList'],
            validator: isBoolean,
            message: t('EventDetails.genericRequired'),
            ...editableFieldsByStatus['detail.guardianNotFoundInList'],
        },
        {
            accessorPath: ['detail', 'student'],
            validator: Boolean,
            message: t('EventDetails.genericRequired'),
            ...editableFieldsByStatus['detail.student'],
        },
        {
            accessorPath: ['payment', 'methodOfPayment'],
            validator: (value) => (autoSave ? true : Boolean(value)),
            message: t('EventDetails.genericRequired'),
            ...editableFieldsByStatus['payment.methodOfPayment'],
        },
        {
            accessorPath: ['payment', 'amountPaid'],
            validator: (value) => (autoSave ? true : value >= 0),
            message: t('EventDetails.genericRequired'),
            ...editableFieldsByStatus['payment.amountPaid'],
        },
        {
            accessorPath: ['payment', 'totalAmount'],
            validator: Boolean,
            message: t('EventDetails.genericRequired'),
            ...editableFieldsByStatus['payment.totalAmount'],
        },
        {
            accessorPath: ['cancellation', 'refundPercentage'],
            validator: (value) => {
                if (autoSave) return true;
                if (eventResponseDetail?.event?.isRefundable) return value >= 0;
            },
            message: t('EventDetails.genericRequired'),
            ...editableFieldsByStatus['cancellation.refundPercentage'],
            required:
                eventResponseDetail.status === EVENT_RESPONSE_CONFIRMED &&
                eventResponseDetail?.event?.isRefundable === true,
        },
        {
            accessorPath: ['cancellation', 'reason'],
            validator: (value) => {
                if (autoSave) return true;
                return Boolean(value);
            },
            message: t('EventDetails.genericRequired'),
            ...editableFieldsByStatus['cancellation.reason'],
            required: eventResponseDetail.status === EVENT_RESPONSE_CONFIRMED,
        },
        {
            accessorPath: ['subsidy', 'confirmedOn'],
            validator: Boolean,
            message: t('EventDetails.genericRequired'),
            ...editableFieldsByStatus['subsidy.confirmedOn'],
        },
        {
            accessorPath: ['subsidy', 'maxSubsidyAmount'],
            validator: Boolean,
            message: t('EventDetails.genericRequired'),
            ...editableFieldsByStatus['subsidy.maxSubsidyAmount'],
        },
        {
            accessorPath: ['subsidy', 'subsidyAmount'],
            validator: Boolean,
            message: t('EventDetails.genericRequired'),
            ...editableFieldsByStatus['subsidy.subsidyAmount'],
        },
        {
            accessorPath: ['detail', 'administrationComment'],
            validator: (value) => {
                if (autoSave) return true;
                return Boolean(value);
            },
            message: t('EventDetails.genericRequired'),
            ...editableFieldsByStatus['detail.administrationComment'],
        },
        {
            accessorPath: ['detail', 'questionsAnswered'],
            validator: isBoolean,
            message: t('EventDetails.genericRequired'),
            ...editableFieldsByStatus['detail.questionsAnswered'],
        },
    ];
}
