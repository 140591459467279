import * as React from 'react';
function SvgLock(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M4.048 14.79a1.46 1.46 0 01-1.071-.445 1.46 1.46 0 01-.446-1.071V6.703c0-.417.149-.775.446-1.072a1.46 1.46 0 011.07-.445h.56V3.968c0-.943.33-1.747.99-2.41A3.265 3.265 0 018 .563c.94 0 1.742.332 2.402.995.66.663.991 1.467.991 2.41v1.218h.56c.416 0 .773.148 1.07.445.297.297.446.655.446 1.072v6.57c0 .418-.149.775-.446 1.072a1.46 1.46 0 01-1.07.445H4.047zm0-1.516h7.904V6.703H4.048v6.57zM8 11.322c.367 0 .68-.131.942-.392.26-.261.391-.575.391-.942s-.13-.68-.391-.942A1.284 1.284 0 008 8.655c-.367 0-.68.13-.942.391a1.284 1.284 0 00-.391.942c0 .367.13.68.391.942.262.26.575.392.942.392zM6.124 5.186h3.753V3.968c0-.524-.182-.97-.546-1.337a1.802 1.802 0 00-1.33-.551c-.525 0-.968.183-1.332.55a1.83 1.83 0 00-.545 1.338v1.218z'
                fill='currentColor'
            />
        </svg>
    );
}
export default SvgLock;
