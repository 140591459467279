import { format, parseISO } from 'date-fns';
import { isNull, isUndefined } from 'lodash';

interface IProps {
    value?: string;
}

const DateCell =
    (dateFormat: string) =>
    ({ value }: IProps) => {
        return <>{!isNull(value) && !isUndefined(value) && format(parseISO(value), dateFormat)}</>;
    };

export default DateCell;
