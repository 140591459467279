import { Badge, Box } from '@chakra-ui/react';
import { ReactElement } from 'react';

interface IconWithBadgeProps {
    children: ReactElement;
    badgeText: string;
}

export const IconWithBadge = ({ children, badgeText }: IconWithBadgeProps) => {
    return (
        <Box pos={'relative'}>
            {children}
            {badgeText && (
                <Badge pos={'absolute'} right={-1} top={-1} bgColor={'Red'} p={'4px'} paddingY={0} textColor={'white'}>
                    {badgeText}
                </Badge>
            )}
        </Box>
    );
};
