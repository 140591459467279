import { useForms } from '@Hooks';
import { RegistrationInformation } from '@Redux/services/Registration/types';
import { ValidatorType } from '@Utilities';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RegistrationStatus } from 'src/constants';
import { PartialDeep } from 'type-fest';
import { getMutableForAccessor } from '@Utilities';
import { useToast } from './useToast';

export const useRegistrationDetail = () => {
    const isPageReadOnly = () =>
        registrationInformation?.status === undefined ||
        registrationInformation?.status === RegistrationStatus.Deleted ||
        registrationInformation?.status === RegistrationStatus.Rejected ||
        registrationInformation?.status === RegistrationStatus.Expired ||
        registrationInformation?.status === RegistrationStatus.Approved ||
        registrationInformation?.status === RegistrationStatus.Archived;

    const getCommonProps = () => {
        return {
            isLocked: isPageReadOnly(),
        };
    };

    const {
        formData: registrationInformation,
        errors,
        textFieldPropFactory,
        selectFieldPropFactory,
        setErrors,
        manageFieldPropFactory,
        switchFieldPropFactory,
        setFormData,
        setIsDirty,
        isDirty,
        setDirtyData,
        dirtyAccessors,
        setDirtyAccessor,
    } = useForms<PartialDeep<RegistrationInformation>>({}, getCommonProps);

    const [t] = useTranslation();
    const toast = useToast();
    const [saveValidationError, setSaveValidationError] = useState(false);

    const steps = useMemo(() => {
        return [
            {
                active: registrationInformation?.status === RegistrationStatus.Submitted,
                header: t('RegistrationDetails.step1'),
                subHeader: t('RegistrationDetails.step1Subheader'),
            },
            {
                active: registrationInformation?.status === RegistrationStatus.DocumentsRequired,
                header: t('RegistrationDetails.step2'),
                subHeader: t('RegistrationDetails.step2Subheader'),
            },
            {
                active:
                    registrationInformation?.status === RegistrationStatus.Approved ||
                    registrationInformation?.status === RegistrationStatus.Archived,
                header: t('RegistrationDetails.step3'),
                subHeader: t('RegistrationDetails.step3Subheader'),
            },
        ];
    }, [registrationInformation]);

    const validate = (validationRules: ValidatorType[], status: number) => {
        const newErrors = { ...errors };
        const nextErrors: Record<string, string> = {};

        for (const rule of validationRules) {
            const { accessorPath, validator, message, required } = rule;

            const finalMutable = getMutableForAccessor(registrationInformation, accessorPath);
            const finalProperty = accessorPath[accessorPath.length - 1];

            if (
                required &&
                (rule.editableStatus == null || rule.editableStatus?.indexOf(status) > -1) &&
                !validator(finalMutable[finalProperty])
            ) {
                nextErrors[accessorPath.join('.')] = message;
            } else {
                newErrors[accessorPath.join('.')] && delete newErrors[accessorPath.join('.')];
            }
        }
        setErrors({ ...newErrors, ...nextErrors });
        if (Object.keys(nextErrors).length) {
            toast({
                status: 'error',
                description: t('RegistrationDetails.toastErrorDescription'),
            });
            return false;
        }
        setSaveValidationError(false);
        return true;
    };

    const registrationRequiredFields = (rules: ValidatorType[]) => {
        if (!registrationInformation) return;
        const newEventRequiredFields = {};
        rules.map((validaton) => {
            newEventRequiredFields[validaton.accessorPath.join('.')] = validaton;
        });
        return newEventRequiredFields;
    };

    return {
        registrationInformation,
        errors,
        textFieldPropFactory,
        selectFieldPropFactory,
        setErrors,
        manageFieldPropFactory,
        switchFieldPropFactory,
        setFormData,
        setIsDirty,
        setDirtyData,
        dirtyAccessors,
        setDirtyAccessor,
        steps,
        saveValidationError,
        validate,
        isDirty,
        registrationRequiredFields,
        isPageReadOnly,
    };
};
