import { Box, Text, useToken } from '@chakra-ui/react';
import { Trans } from 'react-i18next';
import { FC, useMemo, useState } from 'react';
import { StepProps } from '@Pages/EventDetails/types';
import { Bar, BarChart, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import AnswersTable from './components/AnswersTable';
import { Pagination } from '@Components';
import { useGetEventAnswersMutation } from '@Redux/services/Event';
import { EVENT_ANSWERS_MEASUREMENTS, DEFAULT_PAGE_SIZE } from 'src/constants';
import { Answer } from '@Redux/services/Event/types';
import { groupBy } from 'lodash';
import { SortingRule } from 'react-table';
import { parseError, parseWarning } from '@Utilities';
import { useToast } from '@Hooks/useToast';
import { TimeoutId } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types';
import { ResponsesFilters } from '../TableFilters';

const Answers: FC<StepProps> = ({ eventDetails, setEventDetails }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [sortFields, setSortFields] = useState<SortingRule<Answer>[]>();
    const [filters, setFilters] = useState({
        grade: undefined,
        homeRoom: undefined,
        homeRoomTeacher: undefined,
        searchText: '',
        status: undefined,
    });
    const [cyan500, orange500, purple500, teal500, green500, blue500, gray500, white] = useToken('colors', [
        'cyan.500',
        'orange.500',
        'purple.500',
        'teal.500',
        'green.500',
        'blue.500',
        'gray.500',
        'surface-primary',
    ]);
    const {
        legands,
        barData: barchartData,
        isNotEmpty,
    } = useMemo(() => {
        const statisticsGrouped = groupBy(eventDetails.eventAnswers.statistics, 'measurement');
        const barData = [];
        const legands = {};
        let isNotEmpty = false;
        Object.keys(statisticsGrouped).map((key) => {
            const data = {};
            data['name'] = EVENT_ANSWERS_MEASUREMENTS[key];
            statisticsGrouped[key].map((item) => {
                isNotEmpty = !isNotEmpty ? item.value > 0 : isNotEmpty;
                legands[item.title] = true;
                data[item.title] = item.value;
            });
            barData.push(data);
        });
        return { legands, barData, isNotEmpty };
    }, [eventDetails]);
    const toast = useToast();
    const [getEventAnswers, { isLoading }] = useGetEventAnswersMutation();

    const handlePageChange = async (
        page: number,
        newPageSize: number = pageSize,
        newFilters: ResponsesFilters = filters,
        sortBy: SortingRule<Answer>[] = sortFields
    ) => {
        setCurrentPage(page);

        await getEventAnswers({
            eventId: eventDetails.eventId,
            currentPage: page,
            pageSize: newPageSize,
            sortBy: sortBy?.length ? sortBy[0].id : undefined,
            isDesc: sortBy?.length ? sortBy[0].desc : undefined,
            ...newFilters,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                setEventDetails({
                    ...eventDetails,
                    eventAnswers: {
                        answers: response.data,
                        statistics: eventDetails.eventAnswers.statistics,
                        pagedResult: response.pagedResult,
                    },
                });
            })
            .catch((e) => parseError(toast, e));
    };

    const [debounce, setDebounce] = useState<TimeoutId>();
    const handleFilterChange = (key: string) => (value) => {
        setCurrentPage(1);
        const newFilters = { ...filters, [key]: value };
        setFilters(newFilters);
        clearTimeout(debounce);
        setDebounce(setTimeout(() => handlePageChange(1, pageSize, newFilters), 800));
    };

    const handleSortChange = (sortBy: SortingRule<Answer>[]) => {
        setCurrentPage(1);
        setSortFields(sortBy);
        handlePageChange(1, pageSize, filters, sortBy);
    };
    const colors = useMemo(() => [cyan500, orange500, purple500, teal500, green500, blue500, gray500], []);

    const handlePageSizeChange = (pageSize: number) => {
        setPageSize(pageSize);
        setCurrentPage(1);
        handlePageChange(1, pageSize);
    };
    return (
        <>
            <Box mt={8}>
                <Text size='md' fontWeight='semibold'>
                    <Trans i18nKey={'Answers.header'} />
                </Text>
                <Text size='sm'>
                    <Trans i18nKey={'Answers.subHeader'} />
                </Text>
                <Box mt={4}>
                    {!isNotEmpty ? (
                        <Text textAlign='center' textStyle={'sm-medium'}>
                            <Trans i18nKey={'Answers.emptyChart'} />
                        </Text>
                    ) : (
                        <ResponsiveContainer width={'100%'} height={300} debounce={300}>
                            <BarChart width={730} height={250} data={barchartData} barSize={30}>
                                <XAxis dataKey='name' />
                                <YAxis axisLine={false} />
                                <Tooltip />

                                {barchartData.length > 0 &&
                                    Object.keys(legands).map(
                                        (key, index) =>
                                            key !== 'name' && (
                                                <Bar isAnimationActive={false} dataKey={key} fill={colors[index]}>
                                                    <LabelList
                                                        dataKey={key}
                                                        fill={white}
                                                        style={{ fontWeight: 'bold', fontSize: '12px' }}
                                                    />
                                                </Bar>
                                            )
                                    )}
                                <Legend iconType='circle' layout='vertical' verticalAlign='bottom' />
                            </BarChart>
                        </ResponsiveContainer>
                    )}
                </Box>
            </Box>
            <AnswersTable
                handleFilterChange={handleFilterChange}
                filters={filters}
                sortBy={sortFields}
                answers={(eventDetails.eventAnswers?.answers as Answer[]) ?? []}
                eventId={eventDetails.eventId}
                isLoading={isLoading}
                onSortChange={handleSortChange}
            />
            <Box
                bg='surface-primary'
                borderTop='none'
                borderTopStyle='none'
                borderLeftWidth={2}
                borderRightWidth={2}
                borderBottomWidth={2}
                borderBottomRadius={5}
                borderStyle='solid'
                borderColor='border-primary'
            >
                <Pagination
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    pageSize={pageSize}
                    totalPages={eventDetails.eventAnswers.pagedResult?.pageCount}
                    totalResults={eventDetails.eventAnswers.pagedResult?.rowCount}
                    viewMode={'TextButton'}
                />
            </Box>
        </>
    );
};

export default Answers;
