import { ValidatorType } from '@Utilities';
import { TFunction } from 'react-i18next';
import type { PartialDeep } from 'type-fest';
import { RegistrationInformation } from '@Redux/services/Registration/types';
import { RegistrationStatus } from 'src/constants';

export function Validator(
    registrationInformation: PartialDeep<RegistrationInformation>,
    t: TFunction
): ValidatorType[] {
    return [
        {
            accessorPath: ['childInformation', 'legalFirstName'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['childInformation', 'legalSurname'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['childInformation', 'preferredFirstName'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [RegistrationStatus.DocumentsRequired, RegistrationStatus.Approved],
        },
        {
            accessorPath: ['childInformation', 'preferredSurname'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [RegistrationStatus.DocumentsRequired, RegistrationStatus.Approved],
        },
        {
            accessorPath: ['childInformation', 'preferredGender'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['childInformation', 'dateOfBirth'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['childInformation', 'phoneNumber'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['childInformation', 'grade'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['childInformation', 'schoolStartDate'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [RegistrationStatus.Approved],
        },
        {
            accessorPath: ['addressInformation', 'childAddress', 'postalCode'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['addressInformation', 'childAddress', 'city'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['addressInformation', 'parentAddress', 'postalCode'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['addressInformation', 'parentAddress', 'cityFreeForm'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['citizenshipInformation', 'firstLanguage'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['citizenshipInformation', 'languageAtHome'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },

        {
            accessorPath: ['citizenshipInformation', 'boardResidenceStatus'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [RegistrationStatus.Approved],
        },
        {
            accessorPath: ['citizenshipInformation', 'residenceStatus'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [RegistrationStatus.Approved],
        },
        {
            accessorPath: ['citizenshipInformation', 'countryOfCitizenship'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [RegistrationStatus.Approved],
        },

        {
            accessorPath: ['schoolInformation', 'id'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },

        {
            accessorPath: ['emergencyContactInformation', 'parent', 'relationshipToStudent'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['emergencyContactInformation', 'parent', 'surname'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['emergencyContactInformation', 'parent', 'language'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['emergencyContactInformation', 'parent', 'primaryPhoneNumber'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['emergencyContactInformation', 'parent', 'firstName'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },

        {
            accessorPath: ['emergencyContactInformation', 'emergencyContact1', 'relationshipToStudent'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [RegistrationStatus.DocumentsRequired, RegistrationStatus.Approved],
        },
        {
            accessorPath: ['emergencyContactInformation', 'emergencyContact1', 'firstName'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [RegistrationStatus.DocumentsRequired, RegistrationStatus.Approved],
        },
        {
            accessorPath: ['emergencyContactInformation', 'emergencyContact1', 'surname'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [RegistrationStatus.DocumentsRequired, RegistrationStatus.Approved],
        },
        {
            accessorPath: ['emergencyContactInformation', 'emergencyContact1', 'primaryPhoneNumber'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [RegistrationStatus.DocumentsRequired, RegistrationStatus.Approved],
        },
        {
            accessorPath: ['emergencyContactInformation', 'emergencyContact1', 'language'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [RegistrationStatus.DocumentsRequired, RegistrationStatus.Approved],
        },
        {
            accessorPath: ['emergencyContactInformation', 'emergencyContact1', 'canPickUpChild'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [RegistrationStatus.DocumentsRequired, RegistrationStatus.Approved],
        },

        {
            accessorPath: ['sourceDocument'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [RegistrationStatus.Approved],
        },
        {
            accessorPath: ['admissionReason'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [RegistrationStatus.Approved],
        },
    ];
}
