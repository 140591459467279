import { Box, Button, ButtonProps, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { format, parseISO } from 'date-fns';
import { Save } from '@Icon';

interface IProps {
    modifiedBy: string;
    modifiedOn: string;
    createdBy: string;
    createdOn: string;
    saveButtonProps?: ButtonProps;
}
const DATE_FORMAT = 'yyyy-MM-dd h:mm:aaa';
export const AuditInfo: FC<IProps> = ({ modifiedBy, modifiedOn, createdBy, createdOn, saveButtonProps }) => {
    const { t } = useTranslation();
    return (
        <Box w='100%' mt='16px' bg='surface-tertiary' p='16px' borderRadius='8px'>
            <Flex w='100%' justifyContent='space-between'>
                <Box width={'100%'}>
                    <Text textStyle='sm-semibold'>{t('AuditInfo.lastModified')}</Text>
                    <Text textStyle='sm-normal' color={'text-secondary'}>
                        {modifiedBy}
                    </Text>
                    <Text textStyle='sm-normal' color={'text-secondary'}>
                        {modifiedOn && format(parseISO(modifiedOn), DATE_FORMAT)}
                    </Text>
                </Box>
                <Box width={'100%'}>
                    <Text textStyle='sm-semibold'>{t('AuditInfo.created')}</Text>
                    <Text textStyle='sm-normal' color={'text-secondary'}>
                        {createdBy}
                    </Text>
                    <Text textStyle='sm-normal' color={'text-secondary'}>
                        {createdOn && format(parseISO(createdOn), DATE_FORMAT)}
                    </Text>
                </Box>
            </Flex>
            {saveButtonProps && (
                <Button
                    mt={'16px'}
                    size='sm'
                    colorScheme='teal'
                    rounded={'md'}
                    leftIcon={<Save color='text-secondary' />}
                    {...saveButtonProps}
                >
                    {t('Sidebar.save')}
                </Button>
            )}
        </Box>
    );
};
