import { parseError } from '@Utilities';
import { Center, Stack, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { DATE_TIME_FORMAT, DEFAULT_PAGE_SIZE } from 'src/constants';
import { useToast } from '@Hooks/useToast';
import { useTranslation } from 'react-i18next';
import { useGetSchoolReportingPeriodsMutation } from '@Redux/services/Reports';
import { PagedResult } from '@Redux/services/commonTypes';
import ListItem from './components/ListItem';
import ListPanel, { ListProps } from '@Components/ListPanel';
import { useNavigate } from 'react-router-dom';
import { ReportingPeriodDetailsProps } from '@Redux/services/Reports/types';

export const ReportPeriods = () => {
    const { t } = useTranslation();
    const toast = useToast();
    const navigate = useNavigate();

    const [lastRefreshedListPanel, setLastRefreshedListPanel] = useState<Date>(new Date());

    const [reportingPeriods, setReportingPeriods] = useState([]);
    const [pagedResult, setPagedResult] = useState<PagedResult>();

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

    const [getReportingPeriods, { isLoading }] = useGetSchoolReportingPeriodsMutation();

    useEffect(() => {
        handleRefresh();
    }, [currentPage, pageSize]);

    const handleRefresh = () => {
        setLastRefreshedListPanel(new Date());
        getReportingPeriods({ currentPage, pageSize })
            .unwrap()
            .then((response) => {
                setReportingPeriods(response.data);
                setPagedResult(response.pagedResult);
            })
            .catch((error) => {
                parseError(toast, error);
            });
    };

    const openDetails = (propsData: ReportingPeriodDetailsProps) => {
        navigate(`${propsData.reportingPeriodId}/${propsData.schoolId}/${propsData.schoolYearId}`);
    };

    const listPanelItems = useMemo(() => {
        return {
            headerItems: {
                title: t('ReportPeriods.title'),
                subTitle: lastRefreshedListPanel
                    ? `${t('ReportPeriods.lastUpdatedOn')} ${format(lastRefreshedListPanel, DATE_TIME_FORMAT)}`
                    : '',
            },
            pagedListItems: {
                listItems: isLoading
                    ? []
                    : reportingPeriods?.map((item, i) => (
                          <ListItem
                              isFirst={i === 0 && pagedResult.currentPage === 1}
                              key={i}
                              onView={openDetails}
                              schoolId={item.schoolId}
                              schoolYearId={item.schoolYearId}
                              period={`${item.schoolYearStart && new Date(item.schoolYearStart).getFullYear()}-${
                                  item.schoolYearEnd && new Date(item.schoolYearEnd).getFullYear()
                              }`}
                              schoolName={item.school}
                              reportingPeriods={item.periods}
                          />
                      )),
                pagedResult: pagedResult,
            },
            paginated: true,
            emptyScreen: (
                <Center
                    flexDirection={'column'}
                    h={'full'}
                    bg={'surface-brand-invert'}
                    borderRadius='0px 0px 16px 16px'
                    px={6}
                >
                    <Text w={'full'} my={6} textStyle={'lg-semibold'} whiteSpace='pre-line'>
                        {t('ReportPeriods.emptyText')}
                    </Text>
                </Center>
            ),
            onPageChange: (page) => setCurrentPage(page),
            enablePageSizeChange: true,
            onPageSizeChange: (size) => setPageSize(size),
        } as ListProps;
    }, [reportingPeriods]);

    return (
        <Stack marginLeft={'32px'}>
            <ListPanel {...listPanelItems} isLoading={isLoading} pageSize={pageSize} />
        </Stack>
    );
};
