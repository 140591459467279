import { CheckIcon, MinusIcon } from '@chakra-ui/icons';
import { isBoolean } from 'lodash';
import React from 'react';

interface IProps {
    value: boolean;
}

const BooleanCell: React.FC<IProps> = ({ value }) => {
    if (!isBoolean(value)) {
        return;
    }
    return <>{value ? <CheckIcon color='surface-brand-primary' /> : <MinusIcon />}</>;
};

export default BooleanCell;
