import * as React from 'react';
function SvgPaypal(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M15.665 5.9c-.015.1-.033.2-.052.304-.684 3.508-3.02 4.72-6.006 4.72h-1.52a.738.738 0 00-.729.624l-.778 4.935-.22 1.4a.389.389 0 00.383.449H9.44c.32 0 .59-.232.64-.547l.027-.137.508-3.22.032-.178a.648.648 0 01.64-.547h.404c2.611 0 4.656-1.06 5.253-4.129.25-1.282.12-2.352-.54-3.105a2.578 2.578 0 00-.737-.568z'
                fill='#179BD7'
            />
            <path
                d='M14.951 5.616a5.396 5.396 0 00-.664-.148 8.44 8.44 0 00-1.34-.097h-4.06a.645.645 0 00-.64.547l-.864 5.47-.025.16a.738.738 0 01.73-.625h1.52c2.984 0 5.322-1.212 6.005-4.719.02-.103.037-.204.053-.303a3.643 3.643 0 00-.715-.285z'
                fill='#222D65'
            />
            <path
                d='M8.247 5.918a.645.645 0 01.64-.547h4.06c.48 0 .93.032 1.34.098a5.404 5.404 0 01.818.195c.201.067.388.146.561.237.203-1.296-.002-2.179-.702-2.978-.773-.88-2.167-1.256-3.952-1.256h-5.18a.74.74 0 00-.731.625L2.943 15.97a.445.445 0 00.439.514H6.58l.803-5.094.864-5.47z'
                fill='#253B80'
            />
        </svg>
    );
}
export default SvgPaypal;
