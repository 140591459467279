import { Divider, Flex, HStack } from '@chakra-ui/react';
import SelectInput from '@Components/SelectInput';
import TextInput from '@Components/TextInput';
import { useGetEventResponseStatusCodesQuery, useGetInvitedGradesQuery } from '@Redux/services/LookupApi';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EVENT_RESPONSE_CANCELLED, EVENT_RESPONSE_CONFIRMED, EVENT_RESPONSE_PENDING } from 'src/constants';

export interface ResponsesFilters {
    status: number;
    grade: number;
    homeRoom: string;
    homeRoomTeacher: string;
    searchText: string;
}

interface IProps {
    onChange: (key: string) => (value: string | number) => void;
    filters: ResponsesFilters;
}

export const TableFilters: FC<IProps> = ({ filters, onChange }) => {
    const { data: { data: grades = [] } = {} } = useGetInvitedGradesQuery();
    const { data: { data: responseStatus = [] } = {} } = useGetEventResponseStatusCodesQuery({});
    const { t } = useTranslation();

    return (
        <>
            <Flex p={'8px'} pt={'24px'} pb={'16px'} justifyContent='space-between' maxW={'70%'}>
                <HStack spacing='9px'>
                    <SelectInput
                        onChangeValue={onChange('status')}
                        value={filters.status}
                        size='sm'
                        options={responseStatus?.filter((status) =>
                            [EVENT_RESPONSE_CANCELLED, EVENT_RESPONSE_PENDING, EVENT_RESPONSE_CONFIRMED].includes(
                                parseInt(status.key.toString(), 10)
                            )
                        )}
                        bg='surface-primary'
                        placeholder={t('EventResponses.filters.statusPlaceHolder')}
                        defaultOption={false}
                    />
                    <SelectInput
                        onChangeValue={onChange('grade')}
                        value={filters.grade}
                        size='sm'
                        options={grades}
                        bg='surface-primary'
                        placeholder={t('EventResponses.filters.gradePlaceHolder')}
                        defaultOption={false}
                    />
                    <TextInput
                        onChangeText={onChange('homeRoom')}
                        isLocked={false}
                        size='sm'
                        bg='surface-primary'
                        value={filters.homeRoom}
                        placeholder={t('EventResponses.filters.homeRoomPlaceHolder')}
                    />
                    <TextInput
                        onChangeText={onChange('homeRoomTeacher')}
                        isLocked={false}
                        size='sm'
                        bg='surface-primary'
                        value={filters.homeRoomTeacher}
                        placeholder={t('EventResponses.filters.homeRoomTeacherPlaceHolder')}
                    />
                </HStack>
            </Flex>
            <Divider />
        </>
    );
};
