import { Box, Divider, Flex, HStack, Text, IconButton } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import Table from '@Components/Table';
import { Column } from 'react-table';
import { Search } from '@Components/Search';
import Sidebar from '@Components/Sidebar';
import { Pagination } from '@Components';
import { useTranslation } from 'react-i18next';
import { tableData } from './components/TableHeader';
import { sideBarData } from './components/Sidebar';
import { PagedResult } from '@Redux/services/commonTypes';
import { Registration } from '@Redux/services/Registration/types';
import { RegistrationStatusParam, SortByProps, getStatusCode, getSortByField } from './typesHelpers';
import { useGetRegistrationsMutation, useExportRegistrationRequestsMutation } from '@Redux/services/Registration';
import { useDebounce } from '@Hooks';
import { Export } from '@Icon';
import { downloadFile, parseError, parseWarning } from '@Utilities';
import { useToast } from '@Hooks/useToast';
import { EXCEL_FILE_CONTENT_TYPE } from 'src/constants';
import { useNavigate } from 'react-router-dom';

const Registrations: React.FC<{ status?: RegistrationStatusParam }> = ({ status = 'submitted' }) => {
    // #region states and constants

    const defaultSortOrder = { id: 'createdOn', desc: true };
    const { t } = useTranslation();
    const { header } = tableData(t);
    const toast = useToast();

    const [selectedRow, setSelectedRow] = useState<Registration | null>(null);
    const [headers, setHeaders] = useState(header);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [sortBy, setSortBy] = useState<SortByProps>(defaultSortOrder);
    const [pageSize, setPageSize] = useState(10);
    const navigate = useNavigate();

    const columns = useMemo<Column[]>(() => headers[status], [status, selectedRow, headers, sortBy]);
    const sideBarItems = useMemo(() => sideBarData(status, t), [status]);

    // #endregion

    // #region effects and rtk

    useEffect(() => {
        const { header: h } = tableData(t);
        setSortBy(defaultSortOrder);
        setCurrentPage(1);
        setSearchQuery('');
        setHeaders(h);
    }, [status]);

    const [
        getRegistrationsMutation,
        { isLoading, data: { data: registrations = [], pagedResult = {} as PagedResult } = {} },
    ] = useGetRegistrationsMutation();

    const [exportRegistrationRequests] = useExportRegistrationRequestsMutation();

    const getRegistrations = (sortBy, currentPage, searchQuery, registrationStatus) => {
        const obj = getRegistrationsParams(sortBy, currentPage, searchQuery, registrationStatus);
        getRegistrationsMutation(obj);
    };

    const getRegistrationsParams = (sortBy, currentPage, searchQuery, registrationStatus) => {
        const sBy = getSortByField(sortBy);
        const statusCode = getStatusCode(registrationStatus);

        const obj = {
            sortBy: sBy?.id,
            isDesc: sBy?.desc,
            currentPage: currentPage,
            pageSize: pageSize,
            searchText: searchQuery,
            statuses: statusCode,
        };

        return obj;
    };

    const getRegistrationsDebounced = useDebounce(() => {
        getRegistrations(sortBy, currentPage, searchQuery, status);
    });

    useEffect(() => {
        getRegistrationsDebounced();
    }, [searchQuery, currentPage, sortBy, pageSize]);

    // #endregion

    // #region handlers

    const onSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const onSortChange = (sortBy) => {
        if (sortBy[0]) {
            setSortBy(sortBy[0]);
        }
    };

    const onEditHandler = (row) => {
        setSelectedRow(row.original);
        navigate(`/registrations/${row.original.id}`);
    };

    const onPageSizeChange = (newPageSize: number) => {
        setCurrentPage(1);
        setPageSize(newPageSize);
    };

    const onExportRegistrations = async () => {
        await exportRegistrationRequests(getRegistrationsParams(sortBy, currentPage, searchQuery, status))
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                downloadFile(EXCEL_FILE_CONTENT_TYPE, response.data.fileContents, response.data.fileName);
            })
            .catch((err) => parseError(toast, err));
    };

    // #endregion

    return (
        <>
            <Flex h='inherit'>
                <Sidebar {...sideBarItems} />
                <Flex w='100%' py={0} pl={2} pr={4} overflowX={'hidden'} gap={4} h='inherit'>
                    <Flex
                        overflowX={'hidden'}
                        borderRadius='20px 0 0 0'
                        borderWidth='2px 2px 2px 2px'
                        borderStyle='solid'
                        borderColor='border-primary'
                        height={'full'}
                        align={'stretch'}
                        direction={'column'}
                        w='100%'
                    >
                        <Box>
                            <Flex
                                justify={'space-between'}
                                align={'center'}
                                p={6}
                                flexDirection={{ base: 'column', md: 'row' }}
                            >
                                <Text textStyle={'sm-medium'} pb={{ base: 2, md: 0 }}>
                                    {t(`RegistrationsList.${status}`)} {t('RegistrationsList.registrations')}
                                </Text>
                                <HStack spacing={5}>
                                    <Box maxW={224} minW={224}>
                                        <Search iconPosition='left' query={searchQuery} onChange={onSearchChange} />
                                    </Box>
                                    <Box maxW={10} minW={10}>
                                        <IconButton
                                            onClick={onExportRegistrations}
                                            aria-label={t('RegistrationsList.ExportIcon.ariaLabel')}
                                            icon={<Export />}
                                            bgColor={'surface-primary'}
                                            title={t('RegistrationsList.ExportIcon.title')}
                                        />
                                    </Box>
                                </HStack>
                            </Flex>

                            <Divider h='2px' bg='lineColor' />
                        </Box>

                        <Flex position='relative' flex='auto'>
                            <Table
                                manualPagination={true}
                                manualSortBy={true}
                                columns={columns}
                                rawData={isLoading ? [] : registrations}
                                emptyMessage={t('EventsList.noData')}
                                isLoading={isLoading}
                                onSortChange={onSortChange}
                                initialSortBy={sortBy ? [sortBy] : []}
                                onEditHandler={onEditHandler}
                                tableWidth={{ 'table-layout': 'auto', width: '100%' }}
                                containerStyle={{ borderLeftWidth: 0, borderRightWidth: 0, borderRadius: 0 }}
                                stickyHeader
                            />
                        </Flex>

                        {!pagedResult?.pageCount ? null : (
                            <Box p={2}>
                                <Pagination
                                    currentPage={currentPage}
                                    onPageChange={setCurrentPage}
                                    totalPages={pagedResult?.pageCount}
                                    totalResults={pagedResult?.rowCount}
                                    onPageSizeChange={onPageSizeChange}
                                    pageSize={pageSize}
                                />
                            </Box>
                        )}
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
};

export { Registrations, type RegistrationStatusParam };
