import TypeaheadInput from '@Components/TypeaheadInput';
import { ManageTextProps } from '@Hooks';
import { LateAbsenceStepProps } from '@Pages/LateAbsenceDetails/types';
import { useGetAttendanceStatusCodesQuery } from '@Redux/services/LookupApi';
import { Grid, GridItem } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Info = React.forwardRef<HTMLDivElement, LateAbsenceStepProps>(
    ({ manageFieldPropFactory, typeaheadPropFactory, lateAbsenceDetails }) => {
        const { t } = useTranslation();

        const { data: { data: statuses = [] } = {} } = useGetAttendanceStatusCodesQuery();
        return (
            <Grid gridRowGap={4}>
                <GridItem>
                    <TypeaheadInput
                        {...typeaheadPropFactory(t('LateAbsence.status'), ['statusCode'])}
                        {...manageFieldPropFactory<ManageTextProps>(['statusCode'], 'onSelection', 'value')}
                        options={statuses}
                    />
                </GridItem>

                <GridItem>
                    <TypeaheadInput
                        {...typeaheadPropFactory(t('LateAbsence.school'), ['ownerId'])}
                        {...manageFieldPropFactory<ManageTextProps>(['ownerId'], 'onSelection', 'value')}
                        options={[
                            {
                                key: lateAbsenceDetails.ownerId,
                                value: lateAbsenceDetails.owner,
                            },
                        ]}
                    />
                </GridItem>
            </Grid>
        );
    }
);

export default Info;
