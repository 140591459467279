import { useDebounce } from '@Hooks';
import { Box, Flex, HStack, Text, useDisclosure } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_TIME_FORMAT, DEFAULT_PAGE_SIZE } from 'src/constants';
import { GhostIcon } from '@Components/Button';
import { Export, Plus, Refresh } from '@Icon';
import Table, { Column } from '@Components/Table';
import { format } from 'date-fns';
import { useGetAllAbsenceReasonsMutation } from '@Redux/services/Absence';
import { PagedResult } from '@Redux/services/commonTypes';
import { Pagination } from '@Components';
import BooleanCell from '@Components/BooleanCell';
import DateCell from '@Components/DateCell';
import AbsenceReasonDetailsDrawer from './components/AbsenceReasonDetailsDrawer';
import { parseError } from '@Utilities';
import { useToast } from '@Hooks/useToast';
import Sidebar from '@Components/Sidebar';

export type SortByProps = { id: string; desc: boolean };

export const AbsenceReasons = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedId, setSelectedId] = useState<string>();
    const { t } = useTranslation();
    const mainCotainerRef = useRef<HTMLDivElement>(null);
    const toast = useToast();

    const defaultSortOrder = { id: 'createdOn', desc: true };

    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortBy, setSortBy] = useState<SortByProps>(defaultSortOrder);

    const [initialized, setInitialized] = useState(false);

    const [lastRefreshed, setLastRefreshed] = useState<Date>();

    const [
        getAllAbsenceReasons,
        { isLoading, data: { data: absenceReasons = [], pagedResult = {} as PagedResult } = {} },
    ] = useGetAllAbsenceReasonsMutation();

    const handleGetAllAbsenceReasons = () => {
        getAllAbsenceReasons({
            pageSize: pageSize,
            currentPage: currentPage,
            sortBy: sortBy.id,
            isDesc: sortBy.desc,
        })
            .unwrap()
            .catch((e) => parseError(toast, e))
            .finally(() => {
                setLastRefreshed(new Date());
            });
    };

    const debouncedResults = useDebounce(() => {
        handleGetAllAbsenceReasons();
    });

    useEffect(() => {
        if (!initialized) return;

        handleGetAllAbsenceReasons();
    }, [currentPage, sortBy, pageSize]);

    useEffect(() => {
        handleGetAllAbsenceReasons();
        setTimeout(() => {
            setInitialized(true);
        }, 500);
    }, []);

    const onSortChange = (sortBy) => {
        if (sortBy[0]) {
            setSortBy(sortBy[0]);
        }
    };

    const handlePageSizeChange = (newPageSize: number) => {
        setCurrentPage(1);
        setPageSize(newPageSize);
    };

    const handleRefresh = () => {
        debouncedResults();
    };

    const defaultHeaders = [
        {
            Header: t('AbsenceReasons.displayOrder'),
            accessor: 'displayOrder',
            headerAlign: 'left',
        },
        {
            Header: t('AbsenceReasons.name'),
            accessor: 'name',
            headerAlign: 'left',
        },
        {
            Header: t('AbsenceReasons.code'),
            accessor: 'code',
            headerAlign: 'left',
        },
        {
            Header: t('AbsenceReasons.isLateReason'),
            accessor: 'isLateReason',
            Cell: BooleanCell,
            headerAlign: 'left',
        },
        {
            Header: t('AbsenceReasons.createdOn'),
            accessor: 'createdOn',
            Cell: DateCell(DATE_TIME_FORMAT),
            headerAlign: 'left',
        },
    ] as Column[];

    return (
        <Flex id='st' align={'stretch'} h='inherit'>
            <Sidebar
                groups={[
                    {
                        items: [
                            {
                                label: t('System.sideBar.absenceReasons'),
                                id: '/system/configurations/absence-reasons',
                                active: true,
                            },
                        ],
                    },
                ]}
            />
            <Flex ref={mainCotainerRef} py={0} pl={2} pr={2} overflowX={'hidden'} gap={4} w={'full'} h='inherit'>
                <Flex
                    overflowX={'hidden'}
                    borderRadius='20px 0 0 0'
                    borderWidth='2px 2px 2px 2px'
                    borderStyle='solid'
                    borderColor='border-primary'
                    height={'full'}
                    w={'full'}
                    align={'stretch'}
                    direction={'column'}
                >
                    <Box>
                        <Flex
                            justify={'space-between'}
                            align={'center'}
                            p={6}
                            flexDirection={{ base: 'column', md: 'row' }}
                        >
                            <Flex flexDirection={'column'}>
                                <Text textStyle={'md-bold'} pb={{ base: 2, md: 0 }}>
                                    {t('AbsenceReasons.title')}
                                </Text>
                                {lastRefreshed && (
                                    <Text textStyle={'sm-medium'} pb={{ base: 2, md: 0 }}>
                                        {t('Table.lastUpdatedOn') + format(lastRefreshed, DATE_TIME_FORMAT)}
                                    </Text>
                                )}
                            </Flex>
                            <HStack spacing={5}>
                                <GhostIcon
                                    aria-label='add'
                                    size={'sm'}
                                    borderRadius='sm'
                                    title={t('AbsenceReasons.addAbsenceReason')}
                                    icon={<Plus width={26} height={26} />}
                                    onClick={onOpen}
                                />
                                <GhostIcon
                                    aria-label='refresh'
                                    size={'sm'}
                                    borderRadius='sm'
                                    title={t('AbsenceReasons.refreshTable')}
                                    icon={<Refresh width={20} height={20} fill='text-primary' />}
                                    onClick={handleRefresh}
                                />
                                <GhostIcon
                                    aria-label='export'
                                    size={'sm'}
                                    borderRadius='sm'
                                    title={t('AbsenceReasons.exportTable')}
                                    icon={<Export width={20} height={20} />}
                                    onClick={() => {
                                        console.log('dsa');
                                    }}
                                />
                            </HStack>
                        </Flex>
                    </Box>
                    <Flex position='relative' flex='auto'>
                        <Table
                            manualPagination={true}
                            manualSortBy={true}
                            columns={defaultHeaders}
                            rawData={isLoading ? [] : absenceReasons}
                            isLoading={isLoading}
                            emptyMessage={t('StudentActivityRecords.noData')}
                            onSortChange={onSortChange}
                            onEditHandler={(row) => {
                                onOpen();
                                setSelectedId(row.original.id);
                            }}
                            initialSortBy={sortBy ? [sortBy] : []}
                            tableWidth={{ 'table-layout': 'auto', width: '100%' }}
                            containerStyle={{ borderLeftWidth: 0, borderRightWidth: 0, borderRadius: 0 }}
                            stickyHeader
                        />
                    </Flex>

                    {!pagedResult?.pageCount ? null : (
                        <Box p={2}>
                            <Pagination
                                currentPage={currentPage}
                                onPageChange={setCurrentPage}
                                totalPages={pagedResult?.pageCount}
                                totalResults={pagedResult?.rowCount}
                                onPageSizeChange={handlePageSizeChange}
                                pageSize={pageSize}
                            />
                        </Box>
                    )}
                    {isOpen && (
                        <AbsenceReasonDetailsDrawer
                            id={selectedId}
                            onSubmit={() => handleGetAllAbsenceReasons()}
                            isOpen={isOpen}
                            onClose={() => {
                                setSelectedId(undefined);
                                onClose();
                            }}
                            isEditable={true}
                        />
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};
