import Table from '@Components/Table';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableActions from '@Components/TableActions';
import { Button, Pagination } from '@Components';
import ActionCell from './components/ActionCell';
import { Box, Checkbox, Flex } from '@chakra-ui/react';
import { MinusIcon } from '@chakra-ui/icons';
import AddMembers from './components/AddMembers';
import { useRemoveMemberFromListMutation } from '@Redux/services/Participants';
import { parseError, parseWarning } from '@Utilities';
import { useToast } from '@Hooks/useToast';
import { CommonMembersResponse, Member } from '@Redux/services/Participants/types';
import { SortByProps } from '@Pages/Students';
import { useGetInvitedGradesQuery } from '@Redux/services/LookupApi';
import { IStudentListRecord } from '@Redux/services/Student/types';
import { BaseResponse, PagedResult } from '@Redux/services/commonTypes';
import { DEFAULT_PAGE_SIZE } from 'src/constants';
import type { PartialDeep } from 'type-fest';
import { isBoolean } from 'lodash';
import { useDebounce } from '@Hooks';

interface IProps {
    isOpen: boolean;
    getMembers: any;
    onChange: (response: BaseResponse<CommonMembersResponse>) => void;
    participantsData: PartialDeep<CommonMembersResponse>;
    pagedResult: PagedResult;
    isLoading: boolean;
    isDrawer?: boolean;
    listId: string;
    onAddMemberOpen: () => void;
    onAddMemberClose: () => void;
    onCloseAll: () => void;
    eventName?: string;
    isEditable?: boolean;
}

const defaultSortBy = { id: 'fullName', desc: false };

export const Members: FC<IProps> = ({
    isOpen,
    getMembers: mutate,
    eventName,
    isEditable,
    participantsData,
    pagedResult,
    isLoading,
    isDrawer = false,
    listId,
    onAddMemberOpen,
    onAddMemberClose,
    onCloseAll,
}) => {
    const members = (participantsData as CommonMembersResponse)?.memberListModels?.members ?? [];
    const { data: { data: invitedGrades = [] } = {} } = useGetInvitedGradesQuery();

    const { t } = useTranslation();

    const toast = useToast();

    const [removeMembersApi, { isLoading: removingMembers }] = useRemoveMemberFromListMutation();

    const [selectedRows, setSelectedRows] = useState<IStudentListRecord[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [sortBy, setSortBy] = useState<SortByProps>(defaultSortBy);
    const [searchQuery, setSearchQuery] = useState('');
    const [canQuery, setCanQuery] = useState(false);

    useEffect(() => {
        if (!pagedResult) return;
        setCurrentPage(pagedResult?.currentPage);
        setPageSize(pagedResult?.pageSize);
    }, [pagedResult]);

    const getMembers = async (
        sortBy,
        currentPage,
        newPageSize: number = pageSize,
        searchText = searchQuery,
        resetControl = false
    ) => {
        const obj = {
            listId,
            sortBy: sortBy?.id,
            isDesc: sortBy?.desc,
            currentPage: currentPage,
            pageSize: newPageSize,
            searchText,
        };
        try {
            const response = await mutate(obj).unwrap();
            if (response && resetControl) {
                setCurrentPage(1);
                setSortBy(defaultSortBy);
            }
        } catch (e) {
            parseError(toast, e);
        }
    };

    const debouncedResults = useDebounce(() => getMembers(sortBy, currentPage, pageSize, searchQuery));

    useEffect(() => {
        // Prevents double loading on page load
        if (!canQuery) return;
        debouncedResults();
    }, [searchQuery, canQuery]);

    const handlePageChange = (newPage: number) => {
        if (isLoading) return;
        setCurrentPage(newPage);
        getMembers(sortBy, newPage);
    };

    const handleSort = (newSort) => {
        if (isLoading || !newSort?.[0]) return;
        setSortBy(newSort[0]);
        getMembers(newSort[0], currentPage);
    };

    const selectedRowsIds = selectedRows.map((i) => i.studentId);
    const handleSelectAll = (table) => {
        const studentIds = table?.data?.map((i) => i.studentId);
        const isAllRowsSelected = table.data.every((r) => selectedRowsIds.includes(r.studentId));
        if (isAllRowsSelected) {
            setSelectedRows((rows) => rows.filter((r) => !studentIds.includes(r.studentId)));
        } else {
            setSelectedRows((rows) => [...rows, ...table.data.filter((r) => !selectedRowsIds.includes(r.studentId))]);
        }
    };

    const handleRowSelection = (dataRow) => {
        const isSelected = selectedRowsIds.includes(dataRow?.row?.original?.studentId);
        setSelectedRows((rows) =>
            !isSelected
                ? [...rows, dataRow?.original]
                : rows?.filter((r) => r?.studentId !== dataRow?.row?.original?.studentId)
        );
    };

    const handleDelete = async (contactId: string) => {
        await removeMembersApi({
            listId,
            studentList: [contactId],
        })
            .unwrap()
            .then((response) => {
                if (response?.warningResult?.showAlert) {
                    parseWarning(toast, response);
                } else {
                    toast({
                        status: 'success',
                        description: t('Members.toastSuccessRemoveStudent', { count: 1 }),
                        isClosable: true,
                    });
                }
                if (members.length === 1 && currentPage > 1) {
                    setCurrentPage(currentPage - 1);
                    getMembers(sortBy, currentPage - 1);
                } else {
                    getMembers(sortBy, currentPage);
                }
                setSelectedRows((rows) => rows.filter((i) => i.contactId !== contactId));
                // onChange(response);
            })
            .catch((e) => {
                parseError(toast, e);
            });
    };

    const columns = useMemo(() => {
        const newColumns: any[] = [
            {
                Header: (table) => {
                    return (
                        <Checkbox
                            colorScheme={'teal'}
                            isChecked={
                                table?.data?.length > 0
                                    ? table.data.every((r) => selectedRowsIds.includes(r.studentId))
                                    : false
                            }
                            onChange={() => handleSelectAll(table)}
                        />
                    );
                },
                accessor: 'select',
                disableSortBy: true,
                headerAlign: 'center',
                Cell: (row) => {
                    return (
                        <Checkbox
                            colorScheme={'teal'}
                            isChecked={selectedRowsIds.includes(row.row.original.studentId)}
                            onChange={() => handleRowSelection(row)}
                        />
                    );
                },
            },
            {
                Header: t('Members.preferredFullName'),
                accessor: 'fullName',
                headerAlign: 'left',
            },
            {
                Header: t('Members.grade'),
                accessor: (r: Member) => invitedGrades.find((g) => g.key === r.grade)?.value || '',
                id: 'grade',
                headerAlign: 'left',
            },
            {
                Header: t('Members.studentId'),
                accessor: 'studentId',
                headerAlign: 'left',
            },
        ];
        if (isBoolean(isEditable) && isEditable) {
            newColumns.push({
                Header: () => <>&nbsp;</>,
                headerAlign: 'center',
                accessor: 'actions',
                disableSortBy: true,
                Cell: (props) => <ActionCell {...props} onDelete={handleDelete} />,
            });
        } else {
            newColumns.shift();
        }
        return newColumns;
    }, [selectedRows, participantsData, isEditable]);

    const handleAddMembers = (newList: BaseResponse<CommonMembersResponse>) => {
        setSelectedRows([]);
        getMembers(sortBy, currentPage);
        // onChange(newList);
    };

    const handleRemoveMembers = async () => {
        await removeMembersApi({
            listId,
            studentList: selectedRows.map((s) => s?.contactId),
        })
            .unwrap()
            .then((response) => {
                if (response?.warningResult?.showAlert) {
                    parseWarning(toast, response);
                } else {
                    toast({
                        status: 'success',
                        description: t('Members.toastSuccessRemoveStudent', { count: selectedRows.length }),
                        isClosable: true,
                    });
                }
                setSelectedRows([]);
                const currentLastRow = pageSize * (currentPage - 1) + members.length;
                const newLastRow = currentLastRow > selectedRows.length ? currentLastRow - selectedRows.length : 0;
                const newCurrentPage = newLastRow === 0 ? 1 : Math.ceil(newLastRow / pageSize);
                setCurrentPage(newCurrentPage);
                getMembers(sortBy, newCurrentPage);
                // onChange(response);
            })
            .catch((e) => {
                parseError(toast, e);
            });
    };

    const shouldDisable = selectedRows.map((s) => s?.contactId).length <= 0 || removingMembers;

    const handlePageSizeChange = (newPageSize: number) => {
        if (isLoading) return;
        setCurrentPage(1);
        setPageSize(newPageSize);
        getMembers(sortBy, 1, newPageSize);
    };

    const handleSearch = (val: string) => {
        if (!canQuery) {
            setCanQuery(true);
        }
        setSearchQuery(val);
    };

    return (
        <Box mt={2}>
            <Table
                stickyColumns={2}
                action={
                    <TableActions
                        label={t('Members.header')}
                        headerBg={isDrawer && 'white'}
                        onSearch={(val) => handleSearch(val)}
                        searchText={searchQuery}
                    />
                }
                filter={<Box p={'16px'} />}
                isLoading={removingMembers || isLoading}
                manualPagination={true}
                manualSortBy={true}
                columns={columns}
                rawData={members as Member[]}
                emptyMessage={t('Members.emptyTable')}
                enableRowSelection={true}
                addRowButton={
                    isBoolean(isEditable) &&
                    isEditable && (
                        <Flex alignItems={'center'} gap={2}>
                            <AddMembers
                                onOpen={onAddMemberOpen}
                                onClose={onAddMemberClose}
                                onCloseAll={onCloseAll}
                                isOpen={isOpen}
                                listId={listId}
                                onSubmit={handleAddMembers}
                                disableAdd={!listId || removingMembers}
                                eventName={eventName}
                            />
                            <Button
                                variant={'light'}
                                gap={2}
                                px={4}
                                py={0}
                                pl={3}
                                onClick={() => handleRemoveMembers()}
                                isDisabled={shouldDisable}
                            >
                                <MinusIcon /> {t('Members.removeMembers')}
                            </Button>
                        </Flex>
                    )
                }
                onSortChange={handleSort}
                pagination={
                    !pagedResult?.pageCount ? null : (
                        <Box p={2} bg='surface-primary'>
                            <Pagination
                                currentPage={pagedResult.currentPage}
                                onPageChange={handlePageChange}
                                pageSize={pageSize}
                                onPageSizeChange={handlePageSizeChange}
                                totalPages={pagedResult?.pageCount}
                                totalResults={pagedResult?.rowCount}
                            />
                        </Box>
                    )
                }
            />
        </Box>
    );
};
