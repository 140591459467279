import { Box } from '@chakra-ui/react';

import { FC, useMemo } from 'react';
import { StepProps } from '@Pages/EventDetails/types';

import { useTranslation } from 'react-i18next';
import Table from '@Components/Table';
import { Column } from 'react-table';
import TableActions from '@Components/TableActions';
import { DATE_TIME_FORMAT, PaymentTransactionTypes } from 'src/constants';
import { useGetStatusCodesForPaymentTransactionsQuery, useGetSubCategoriesQuery } from '@Redux/services/LookupApi';
import { BasicLookup } from '@Redux/services/LookupApi/types';
import { createLookupMap } from '@Utilities';
import DateCell from '@Components/DateCell';

const Payment: FC<StepProps> = ({ eventDetails, organizedBy, categories }) => {
    const { t } = useTranslation();
    const { data: { data: statusReasons = [] } = {} } = useGetStatusCodesForPaymentTransactionsQuery(undefined);
    const { data: { data: subCategories = [] } = {} } = useGetSubCategoriesQuery(
        { parentCategoryId: eventDetails.categoryId, searchText: '' },
        { skip: !eventDetails.categoryId }
    );

    const columns = useMemo<Column[]>(
        () => [
            {
                Header: t('Payment.transactionCode'),
                accessor: 'transactionCode',
                headerAlign: 'left',
            },
            {
                Header: t('Payment.transactionType'),
                accessor: 'transactionType',
                headerAlign: 'left',
            },
            {
                Header: t('Payment.guardian.source'),
                accessor: 'guardianSrc',
                headerAlign: 'left',
            },
            {
                Header: t('Payment.guardian.destination'),
                accessor: 'guardianDest',
                headerAlign: 'left',
            },
            {
                Header: t('Payment.amount'),
                accessor: 'amount',
                headerAlign: 'right',
            },
            {
                Header: t('Payment.fee'),
                accessor: 'fee',
                headerAlign: 'right',
            },
            {
                Header: t('Payment.totalAmount'),
                accessor: 'total',
                headerAlign: 'right',
            },
            {
                Header: t('Payment.statusReason'),
                accessor: 'statusReason',
                headerAlign: 'left',
            },
            {
                Header: t('Payment.modifiedOn'),
                accessor: 'modifiedOn',
                headerAlign: 'left',
                Cell: DateCell(DATE_TIME_FORMAT),
                sortType: 'datetime',
            },
            {
                Header: t('Payment.createdOn'),
                accessor: 'createdOn',
                headerAlign: 'left',
                Cell: DateCell(DATE_TIME_FORMAT),
                sortType: 'datetime',
            },
            {
                Header: t('Payment.organizedBy'),
                accessor: 'organizedBy',
                headerAlign: 'left',
            },
            {
                Header: t('Payment.Category'),
                accessor: 'category',
                headerAlign: 'left',
            },
            {
                Header: t('Payment.subCategory'),
                accessor: 'subCategory',
                headerAlign: 'left',
            },
        ],
        []
    );

    const getTransactionType = (e: number) => {
        switch (e) {
            case PaymentTransactionTypes.PayPalEventPayment:
                return 'PayPal Event Payment';
            case PaymentTransactionTypes.PayPalEventRefund:
                return 'PayPal Event Refund';
            case PaymentTransactionTypes.PayPalLoadFunds:
                return 'PayPal Load Funds';
            case PaymentTransactionTypes.ChequeCashOut:
                return 'Cheque Cash Out';
            case PaymentTransactionTypes.DonationCashOut:
                return 'Donation Cash Out';
            case PaymentTransactionTypes.PayPalCashOut:
                return 'PayPal Cash Out';
            case PaymentTransactionTypes.EventPaymentFee:
                return 'Event Payment Fee';
            case PaymentTransactionTypes.PayPalCashOutFee:
                return 'PayPal Cash Out Fee';
            case PaymentTransactionTypes.DonationCashOutFee:
                return 'Donation Cash Out Fee';
            case PaymentTransactionTypes.CashEventPayment:
                return 'Cash Event Payment';
            case PaymentTransactionTypes.CashEventRefund:
                return 'Cash Event Refund';
            case PaymentTransactionTypes.ChequeEventRefund:
                return 'Cheque Event Refund';
            case PaymentTransactionTypes.ChequeEventPayment:
                return 'Cheque Event Payment';
            case PaymentTransactionTypes.PayPalGroupTransaction:
                return 'PayPal Group Transaction';
        }
    };

    const statusReasonMap = useMemo(() => createLookupMap<BasicLookup>(statusReasons), [statusReasons]);

    const eventPayments = useMemo(() => {
        return eventDetails?.eventPayments?.map((p) => ({
            ...p,
            statusReason: statusReasonMap[p?.statusCode]?.value,
            transactionType: getTransactionType(p?.transactionTypeCode),
            organizedBy: organizedBy.find((e) => e.key === eventDetails?.organizedById)?.value,
            category: categories.find((e) => e.key === eventDetails?.categoryId)?.value,
            subCategory: subCategories.find((e) => e.key === eventDetails?.subCategoryId)?.value,
        }));
    }, [eventDetails]);

    return (
        <Box mt={8}>
            <Table
                action={
                    <TableActions
                        onAddClick={() => {
                            console.log('add');
                        }}
                        onExportClick={() => {
                            console.log('export');
                        }}
                    />
                }
                manualSortBy={false}
                columns={columns}
                rawData={eventPayments ?? []}
                emptyMessage={t('Payment.emptyTable')}
            />
        </Box>
    );
};

export default Payment;
