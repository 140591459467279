import { useDebounce } from '@Hooks';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_TIME_FORMAT, DEFAULT_PAGE_SIZE, FIVE_MINUTES_MS } from 'src/constants';
import { PowerSchoolAbsencesFilters, TableFilters } from './TableFilters';
import {
    useGetNewConfirmedAbsenceRecordsAmountMutation,
    useGetPowerSchoolAbsencesMutation,
} from '@Redux/services/Absence';
import { PagedResult } from '@Redux/services/commonTypes';
import { Search } from '@Components/Search';
import { GhostIcon } from '@Components/Button';
import { Export, Refresh } from '@Icon';
import Table, { Column } from '@Components/Table';
import { Pagination } from '@Components';
import { format } from 'date-fns';
import DateCell from '@Components/DateCell';
import { parseError } from '@Utilities';
import { useToast } from '@Hooks/useToast';
import { useNavigate } from 'react-router-dom';
import { IconWithBadge } from '@Components/IconWithBadge';
import StudentsSidebar from '@Components/StudentsSidebar';

export type SortByProps = { id: string; desc: boolean };

const defaultFilters = {
    dateRange: [new Date(), new Date()],
    statusReason: null,
    reasonCode: null,
};

export const PowerSchoolAbsences = () => {
    const { t } = useTranslation();
    const mainCotainerRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    const toast = useToast();

    const defaultSortOrder = { id: 'absenceDate', desc: true };

    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [sortBy, setSortBy] = useState<SortByProps>(defaultSortOrder);

    const [initialized, setInitialized] = useState(false);

    const [filters, setFilters] = useState<PowerSchoolAbsencesFilters>(defaultFilters);

    const [
        GetPowerschoolAbsences,
        { isLoading, data: { data: phoneCallsRes = [], pagedResult = {} as PagedResult } = {} },
    ] = useGetPowerSchoolAbsencesMutation();

    const [lastRefreshed, setLastRefreshed] = useState<Date>();

    const getPhoneCalls = (
        sortBy: SortByProps,
        currentPage: number,
        searchQuery: string,
        filters: PowerSchoolAbsencesFilters
    ) => {
        const obj = {
            sortBy: sortBy?.id,
            isDesc: sortBy?.desc,
            currentPage: currentPage,
            pageSize: pageSize,
            searchText: searchQuery,
            startDate: filters?.dateRange?.[0] ?? null,
            endDate: filters?.dateRange?.[1] ?? null,
            statusReason: filters.statusReason == '' ? null : filters.statusReason,
            reasonCode: filters.reasonCode == '' ? null : filters.reasonCode,
        };

        const now = new Date();

        GetPowerschoolAbsences(obj)
            .unwrap()
            .catch((e) => parseError(toast, e))
            .finally(() => {
                reset();
                setLastRefreshed(now);
            });
    };

    const [checkNew, { data: { data: newEntries = undefined } = {}, reset }] =
        useGetNewConfirmedAbsenceRecordsAmountMutation();

    useEffect(() => {
        const checkNewEntriesInterval = setInterval(
            () => {
                checkNew({ lastUpdatedDate: lastRefreshed ?? new Date() });
            },
            FIVE_MINUTES_MS,
            [lastRefreshed]
        );

        return () => clearInterval(checkNewEntriesInterval);
    }, [lastRefreshed]);

    const debouncedResults = useDebounce(() => {
        getPhoneCalls(sortBy, currentPage, searchQuery, filters);
    });

    useEffect(() => {
        debouncedResults();
    }, [searchQuery, filters]);

    useEffect(() => {
        if (!initialized) return;

        getPhoneCalls(sortBy, currentPage, searchQuery, filters);
    }, [currentPage, sortBy, pageSize]);

    useEffect(() => {
        setTimeout(() => {
            setInitialized(true);
        }, 500);
    }, []);

    const onSortChange = (sortBy) => {
        if (sortBy[0]) {
            setSortBy(sortBy[0]);
        }
    };

    // Resetting pagination and filtering when searching
    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const handlePageSizeChange = (newPageSize: number) => {
        setCurrentPage(1);
        setPageSize(newPageSize);
    };

    const handleFilterChange = (key: string) => (value) => {
        setCurrentPage(1);
        const newFilters = { ...filters, [key]: value };
        setFilters(newFilters);
    };

    const handleResetField = (key: string, value: []) => {
        const newFilters = { ...filters, [key]: value };
        setFilters(newFilters);
    };

    const handleReset = () => {
        setCurrentPage(1);
        setFilters({ dateRange: [], statusReason: null, reasonCode: null });
    };

    const handleRefresh = () => {
        debouncedResults();
    };

    const defaultHeaders = [
        {
            Header: t('PowerSchoolAbsences.absenceId'),
            accessor: 'psAbsenceId',
            headerAlign: 'left',
        },
        {
            Header: t('PowerSchoolAbsences.absenceDate'),
            accessor: 'absenceDate',
            Cell: DateCell(DATE_TIME_FORMAT),
            sortType: 'datetime',
            headerAlign: 'left',
        },
        {
            Header: t('PowerSchoolAbsences.studentName'),
            accessor: 'studentName',
            headerAlign: 'left',
        },
        {
            Header: t('PowerSchoolAbsences.statusReason'),
            accessor: 'statusReason',
            headerAlign: 'left',
        },
        {
            Header: t('PowerSchoolAbsences.reasonCode'),
            accessor: 'reasonCode',
            headerAlign: 'left',
        },
        {
            Header: t('PowerSchoolAbsences.reason'),
            accessor: 'reason',
            headerAlign: 'left',
        },
    ] as Column[];

    return (
        <Flex id='st' align={'stretch'} h='inherit'>
            <StudentsSidebar activeItem='powerschool-absences' />
            <Flex ref={mainCotainerRef} py={0} pl={2} pr={2} overflowX={'hidden'} gap={4} w={'full'} h='inherit'>
                <Flex
                    overflowX={'hidden'}
                    borderRadius='20px 0 0 0'
                    borderWidth='2px 2px 2px 2px'
                    borderStyle='solid'
                    borderColor='border-primary'
                    height={'full'}
                    w={'full'}
                    align={'stretch'}
                    direction={'column'}
                >
                    <Box>
                        <Flex
                            justify={'space-between'}
                            align={'center'}
                            p={6}
                            flexDirection={{ base: 'column', md: 'row' }}
                        >
                            <Flex flexDirection={'column'}>
                                <Text textStyle={'md-bold'} pb={{ base: 2, md: 0 }}>
                                    {t('PowerSchoolAbsences.title')}
                                </Text>
                                {lastRefreshed && (
                                    <Text textStyle={'sm-medium'} pb={{ base: 2, md: 0 }}>
                                        {t('Table.lastUpdatedOn') + format(lastRefreshed, DATE_TIME_FORMAT)}
                                    </Text>
                                )}
                            </Flex>
                            <HStack spacing={5}>
                                <Box maxW={224} minW={224}>
                                    <Search iconPosition='left' query={searchQuery} onChange={handleSearch} />
                                </Box>

                                <IconWithBadge badgeText={newEntries?.amount > 0 ? newEntries.amount.toString() : ''}>
                                    <GhostIcon
                                        aria-label='refresh'
                                        size={'sm'}
                                        borderRadius='sm'
                                        title={t('AbsencesList.refreshTable')}
                                        icon={<Refresh width={20} height={20} fill='text-primary' />}
                                        onClick={handleRefresh}
                                    />
                                </IconWithBadge>
                                <GhostIcon
                                    aria-label='export'
                                    size={'sm'}
                                    borderRadius='sm'
                                    title={t('AbsencesList.exportTable')}
                                    icon={<Export width={20} height={20} />}
                                    onClick={() => {
                                        console.log('dsa');
                                    }}
                                />
                            </HStack>
                        </Flex>
                    </Box>
                    <Flex position='relative' flex='auto'>
                        <Table
                            manualPagination={true}
                            manualSortBy={true}
                            columns={defaultHeaders}
                            rawData={isLoading ? [] : phoneCallsRes}
                            emptyMessage={t('AbsencesList.noData')}
                            isLoading={isLoading}
                            onSortChange={onSortChange}
                            onEditHandler={(row) =>
                                navigate(`/records/students/powerschool-absences/${row.original.ucdsbAbsenceId}`)
                            }
                            filter={
                                <TableFilters
                                    onChange={handleFilterChange}
                                    onReset={handleReset}
                                    onResetField={handleResetField}
                                    filters={filters}
                                />
                            }
                            initialSortBy={sortBy ? [sortBy] : []}
                            tableWidth={{ 'table-layout': 'auto', width: '100%' }}
                            containerStyle={{ borderLeftWidth: 0, borderRightWidth: 0, borderRadius: 0 }}
                            stickyHeader
                        />
                    </Flex>

                    {!pagedResult?.pageCount ? null : (
                        <Box p={2}>
                            <Pagination
                                currentPage={currentPage}
                                onPageChange={setCurrentPage}
                                totalPages={pagedResult?.pageCount}
                                totalResults={pagedResult?.rowCount}
                                onPageSizeChange={handlePageSizeChange}
                                pageSize={pageSize}
                            />
                        </Box>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};
