import { Box } from '@chakra-ui/react';
import { CostItemDetail } from '@Redux/services/Event/types';
import { CellProps } from 'react-table';
import { DeleteButtonPopover } from '@Components/DeleteButton';
import { useTranslation } from 'react-i18next';
import { useToast } from '@Hooks/useToast';
import { parseError, parseWarning } from '@Utilities';
import { useDeleteCostItemMutation } from '@Redux/services/Event';
import EditIcon from '@Components/EditIcon';

const ActionCell: React.FC<
    CellProps<CostItemDetail> & {
        onEdit: (costItem: CostItemDetail) => void;
        onSubmit: (costItems: CostItemDetail[]) => void;
        isEditable: boolean;
    }
> = ({ row, onEdit, onSubmit, isEditable }) => {
    const { t } = useTranslation();
    const [deleteCostItem] = useDeleteCostItemMutation();
    const toast = useToast();

    const handleDelete = async () => {
        await deleteCostItem({
            eventId: row.original.eventId,
            costItemId: row.original.costItemId,
        })
            .unwrap()
            .then((response) => {
                if (response?.warningResult?.showAlert) {
                    parseWarning(toast, response);
                } else {
                    toast({
                        status: 'success',
                        description: t('CostItemForm.toastDeleteSuccess'),
                    });
                }
                onSubmit(response.data);
            })
            .catch((e) => {
                parseError(toast, e);
            });
    };
    return (
        <Box display={'flex'} textAlign={'center'} placeContent={'center'}>
            {isEditable && (
                <DeleteButtonPopover
                    aria-label='delete'
                    cursor={'pointer'}
                    onDelete={handleDelete}
                    message={t('CostItemForm.deleteMsg')}
                />
            )}
            <EditIcon aria-label={t('EditIcon.title')} onClick={() => onEdit(row.original)} />
        </Box>
    );
};

export default ActionCell;
