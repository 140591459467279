import * as React from 'react';
function SvgAvatars(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 24 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M.815 14.29c0-.604.156-1.159.468-1.665a3.116 3.116 0 011.245-1.159c1.046-.52 2.11-.912 3.192-1.174a14.108 14.108 0 016.608-.003c1.082.26 2.142.65 3.18 1.171.517.266.932.651 1.245 1.156.312.505.468 1.063.468 1.674v.74c0 .63-.221 1.166-.665 1.61-.444.443-.98.665-1.61.665H3.09c-.63 0-1.167-.222-1.61-.665a2.192 2.192 0 01-.665-1.61v-.74zm20.095 3.015h-2.364c.227-.336.396-.698.508-1.085.111-.388.167-.784.167-1.19v-.893c0-.76-.204-1.506-.612-2.235-.409-.728-1.016-1.352-1.822-1.872.823.104 1.614.282 2.374.534.76.252 1.463.55 2.109.896.616.334 1.089.727 1.42 1.18.33.454.495.949.495 1.486v.904c0 .63-.221 1.166-.665 1.61-.443.443-.98.665-1.61.665zM9.018 8.898c-1.132 0-2.098-.4-2.9-1.202-.801-.8-1.202-1.767-1.202-2.9 0-1.131.401-2.098 1.202-2.899.802-.801 1.768-1.202 2.9-1.202s2.098.4 2.9 1.202c.801.801 1.202 1.768 1.202 2.9s-.401 2.098-1.202 2.9c-.802.8-1.768 1.201-2.9 1.201zm10.293-4.101c0 1.128-.402 2.093-1.205 2.896-.803.804-1.768 1.205-2.896 1.205-.188 0-.421-.021-.7-.065a5.828 5.828 0 01-.719-.153 5.98 5.98 0 001.418-3.883A5.987 5.987 0 0013.791.914c.238-.087.473-.145.706-.174.234-.03.47-.044.71-.044 1.128 0 2.094.402 2.898 1.205.804.803 1.206 1.769 1.206 2.897zM3.09 15.03h11.856v-.71a.905.905 0 00-.137-.49.964.964 0 00-.363-.342 12.9 12.9 0 00-2.671-.983 11.577 11.577 0 00-2.757-.332c-.925 0-1.844.11-2.757.332-.913.22-1.803.548-2.671.982a.965.965 0 00-.363.343.906.906 0 00-.137.49v.71zm5.927-8.317c.527 0 .978-.188 1.354-.563.375-.375.563-.826.563-1.352 0-.527-.187-.978-.562-1.354a1.843 1.843 0 00-1.353-.564c-.527 0-.978.188-1.354.563a1.843 1.843 0 00-.563 1.353c0 .526.187.978.562 1.353.375.376.826.564 1.353.564z'
                fill='#171923'
            />
        </svg>
    );
}
export default SvgAvatars;
