import { FC } from 'react';
import { Box, Divider, Flex, Text, FlexProps } from '@chakra-ui/react';

interface IProps extends FlexProps {
    title: string;
    icon: React.ReactNode;
    divider?: boolean;
}

const Header: FC<IProps> = ({ title, icon, divider, ...rest }) => (
    <>
        <Flex alignItems={'center'} {...rest}>
            <Box style={{ marginRight: 16 }}>{icon}</Box>
            <Text textStyle={'lg-medium'} fontWeight='medium'>
                {title}
            </Text>
        </Flex>
        {divider && <Divider mb={4} />}
    </>
);

export default Header;
