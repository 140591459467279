import * as React from 'react';
function SvgSendToMobile(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <mask
                id='send-to-mobile_svg__a'
                style={{
                    maskType: 'alpha',
                }}
                maskUnits='userSpaceOnUse'
                x={0}
                y={0}
                width={24}
                height={24}
            >
                <path fill='#D9D9D9' d='M0 0h24v24H0z' />
            </mask>
            <g mask='url(#send-to-mobile_svg__a)'>
                <path
                    d='M18 15.6l-1.274-1.275L18.15 12.9H13.2v-1.8h4.95l-1.425-1.425L18 8.4l3.6 3.6-3.6 3.6zM6.69 22.985c-.57 0-1.059-.203-1.465-.61a1.998 1.998 0 01-.61-1.465V3.09c0-.57.203-1.06.61-1.466.406-.406.895-.61 1.465-.61h10.62c.571 0 1.06.204 1.466.61.407.407.61.895.61 1.466V7.2H17.31V6.09H6.69v11.82h10.62V16.8h2.075v4.11c0 .57-.203 1.06-.61 1.466-.406.406-.895.61-1.465.61H6.69zm0-3.275v1.2h10.62v-1.2H6.69zm0-15.42h10.62v-1.2H6.69v1.2z'
                    fill='#171923'
                />
            </g>
        </svg>
    );
}
export default SvgSendToMobile;
