import { useGetInvitedGradesQuery } from '@Redux/services/LookupApi';
import { BasicLookup } from '@Redux/services/LookupApi/types';
import { createLookupMap } from '@Utilities';
import React, { useMemo } from 'react';
interface IProps {
    value: number;
}

const GradeCell: React.FC<IProps> = ({ value }) => {
    const { data: { data: grades = [] } = {} } = useGetInvitedGradesQuery();
    const gradesMap = useMemo(() => createLookupMap<BasicLookup>(grades), [grades]);
    return <>{gradesMap[value] ? gradesMap[value].value : '--'}</>;
};

export default GradeCell;
