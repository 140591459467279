import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import React from 'react';

interface IProps {
    icon: React.ReactNode;
    title: string;
    subtitle?: string | React.ReactNode;
    showDivider?: boolean;
}

export const SectionHeader: React.FC<IProps> = ({ icon, title, subtitle, showDivider = true }) => {
    return (
        <Box mb='16px'>
            <Flex alignItems={'center'}>
                <Box style={{ marginRight: 16 }}>{icon}</Box>
                <Box>
                    <Text textStyle={'lg-medium'} fontWeight='medium'>
                        {title}
                    </Text>

                    {subtitle && (
                        <Text color='gray.600' whiteSpace='pre' textStyle={'sm-normal'} fontWeight='medium'>
                            {subtitle}
                        </Text>
                    )}
                </Box>
            </Flex>
            {showDivider && <Divider mt={4} h={'1px'} bgColor={'border-primary'} />}
        </Box>
    );
};
