import { Box, Flex, Stack, useDisclosure } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useGetInvitedGradesQuery, useGetPhoneCallAbsenceReasonTypesQuery } from '@Redux/services/LookupApi';
import { PendingCalls } from './components/PendingCalls';
import PhoneCallDetailsDrawer from '@Components/PhoneCallDetailsDrawer';
import { Stats } from './components/Stats';
import { PhoneCallFormData } from './types';
import { PhoneCall } from '@Redux/services/Absence/types';
import { PagedResult } from '@Redux/services/commonTypes';
import { parseError } from '@Utilities';
import { useToast } from '@Hooks/useToast';
import { useGetPendingPhoneCallsMutation } from '@Redux/services/Absence';

export type SortByProps = { id: string; desc: boolean };

export const PhoneCalls = () => {
    const mainCotainerRef = useRef<HTMLDivElement>(null);
    const toast = useToast();
    const [selectedPs, setSelectedPs] = useState<PhoneCall>();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { data: { data: grades = [] } = {} } = useGetInvitedGradesQuery();
    const { data: { data: absenceReasonCodes = [] } = {} } = useGetPhoneCallAbsenceReasonTypesQuery();
    const [newPendingCalls, setNewPendingCalls] = useState<number>(null);
    const [formData, setFormData] = useState<PhoneCallFormData[]>([]);

    const [searchText, setSearchText] = useState('');

    const [
        getPendingPhoneCalls,
        {
            data: { data: phoneCallsListPanel = [], pagedResult: pagedResult = {} as PagedResult } = {},
            isLoading: isPendingPhoneCallsLoading,
        },
    ] = useGetPendingPhoneCallsMutation();

    useEffect(() => {
        if (isPendingPhoneCallsLoading === false) {
            setLastRefreshedListPanel(new Date());
        }
    }, [isPendingPhoneCallsLoading]);

    const [lastRefreshedListPanel, setLastRefreshedListPanel] = useState<Date>(new Date());

    useEffect(() => {
        handleRefresh();
    }, []);

    const handleGetPendingPhoneCalls = () => {
        const now = new Date();
        getPendingPhoneCalls()
            .unwrap()
            .catch((e) => {
                parseError(toast, e);
            })
            .finally(() => {
                setLastRefreshedListPanel(now);
                setNewPendingCalls(null);
            });
    };

    const handleRefresh = () => {
        handleGetPendingPhoneCalls();
    };

    const handleRowClick = (phoneCall: PhoneCall) => {
        setSelectedPs(phoneCall);
        onOpen();
    };

    return (
        <Box>
            <Flex id='st' align={'stretch'} h='inherit'>
                <Stack
                    ref={mainCotainerRef}
                    py={0}
                    pl={2}
                    pr={0}
                    overflowX={'hidden'}
                    overflowY={'auto'}
                    gap={4}
                    w={'full'}
                    h='inherit'
                >
                    <Stats />
                    <PendingCalls
                        searchText={searchText}
                        phoneCallsListPanel={phoneCallsListPanel}
                        pagedResult={pagedResult}
                        isPendingPhoneCallsLoading={isPendingPhoneCallsLoading}
                        setNewPendingCalls={setNewPendingCalls}
                        handleRefresh={handleRefresh}
                        lastRefreshedListPanel={lastRefreshedListPanel}
                        setSearchText={setSearchText}
                        newPendingCalls={newPendingCalls}
                        getPendingPhoneCalls={handleGetPendingPhoneCalls}
                        grades={grades}
                        absenceReasonCodes={absenceReasonCodes}
                        onRowClick={handleRowClick}
                        formState={[formData, setFormData]}
                    />
                </Stack>
                {isOpen && selectedPs && (
                    <PhoneCallDetailsDrawer
                        id={selectedPs.activityId}
                        isOpen={isOpen}
                        onClose={(refresh?: boolean) => {
                            setSelectedPs(null);
                            onClose();
                            if (refresh) {
                                handleRefresh();
                            }
                        }}
                        absenceReasonCodes={absenceReasonCodes}
                        formState={[formData, setFormData]}
                    />
                )}
            </Flex>
        </Box>
    );
};
