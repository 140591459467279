import { DetailPageSkeleton } from '@Components/DetailPageSkeleton';
import { useForms } from '@Hooks';
import { useGetLateAbsenceNoteDetailsQuery } from '@Redux/services/Absence';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isUndefined } from 'lodash';
import { Accessor } from '@Utilities';
import { ILateAbsenceNoteDetails } from '@Redux/services/Absence/types';
import { Stack } from '@chakra-ui/react';
import { AuditInfo } from '@Components/AuditInfo';
import { editableFieldsForLateAbsence } from '@Pages/LateAbsenceDetails/types';
import Drawer from '@Components/Drawer';
import Info from './components/Info';
import GeneralInfo from './components/GeneralInfo';
import Note from './components/Note';
import ActivityLog from './components/ActivityLog';

type IParams = {
    isOpen: boolean;
    onClose: () => void;
    absenceId: string;
};

export const MfrDetailsDrawer: FC<IParams> = ({ absenceId, isOpen, onClose }) => {
    const {
        refetch: getLateAbsenceDetails,
        data: { data: lateAbsenceDetailsData } = {},
        isLoading,
    } = useGetLateAbsenceNoteDetailsQuery({ attendanceId: absenceId });

    const { t } = useTranslation();

    const getCommonProps = (accessorPath: Accessor[]) => {
        const accesor = accessorPath.join('.');
        const item = editableFieldsForLateAbsence[accesor];
        const hasStatus = !isUndefined(item) ? item.editableStatus : false;
        const isLocked = isUndefined(item) ? true : !hasStatus;
        const isRequired = hasStatus && item.required;
        return {
            isLocked,
            isRequired,
        };
    };

    const {
        formData: lateAbsenceNoteData,
        manageFieldPropFactory,
        selectFieldPropFactory,
        textFieldPropFactory,
        typeaheadPropFactory,
        dateTimePickerPropFactory,
        switchFieldPropFactory,
        textAreaPropFactory,
        setFormData: setLateAbsenceDetail,
    } = useForms<ILateAbsenceNoteDetails>(lateAbsenceDetailsData, getCommonProps);

    useEffect(() => {
        getLateAbsenceDetails();
    }, [absenceId]);

    useEffect(() => {
        setLateAbsenceDetail(lateAbsenceDetailsData);
    }, [lateAbsenceDetailsData]);

    if (isLoading || !lateAbsenceNoteData) {
        return <DetailPageSkeleton />;
    }

    return (
        <Drawer
            isOpen={isOpen}
            onClose={onClose}
            title={t('MfrDetailsDrawer.title', { studentName: lateAbsenceNoteData.student })}
        >
            <Stack gap={4}>
                <Info
                    manageFieldPropFactory={manageFieldPropFactory}
                    lateAbsenceDetails={lateAbsenceDetailsData}
                    setLateAbsenceDetails={setLateAbsenceDetail}
                    typeaheadPropFactory={typeaheadPropFactory}
                />
                <GeneralInfo
                    manageFieldPropFactory={manageFieldPropFactory}
                    textFieldPropFactory={textFieldPropFactory}
                    selectFieldPropFactory={selectFieldPropFactory}
                    lateAbsenceDetails={lateAbsenceDetailsData}
                    setLateAbsenceDetails={setLateAbsenceDetail}
                    dateTimePickerPropFactory={dateTimePickerPropFactory}
                    switchFieldPropFactory={switchFieldPropFactory}
                />
                <Note
                    textAreaPropFactory={textAreaPropFactory}
                    manageFieldPropFactory={manageFieldPropFactory}
                    lateAbsenceDetails={lateAbsenceDetailsData}
                    setLateAbsenceDetails={setLateAbsenceDetail}
                    dateTimePickerPropFactory={dateTimePickerPropFactory}
                />
                <ActivityLog
                    lateAbsenceDetails={lateAbsenceDetailsData}
                    manageFieldPropFactory={manageFieldPropFactory}
                    setLateAbsenceDetails={setLateAbsenceDetail}
                />
                <AuditInfo
                    modifiedBy={lateAbsenceDetailsData?.modifiedBy}
                    modifiedOn={lateAbsenceDetailsData?.dateAbsenceSubmitted?.toString()}
                    createdBy={lateAbsenceDetailsData?.createdBy}
                    createdOn={lateAbsenceDetailsData?.createdOn?.toString()}
                />
            </Stack>
        </Drawer>
    );
};

export default MfrDetailsDrawer;
