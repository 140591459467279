import * as React from 'react';
function SvgSchool(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <mask
                id='school_svg__a'
                style={{
                    maskType: 'alpha',
                }}
                maskUnits='userSpaceOnUse'
                x={0}
                y={0}
                width={20}
                height={20}
            >
                <path fill='#D9D9D9' d='M0 0h20v20H0z' />
            </mask>
            <g mask='url(#school_svg__a)'>
                <path
                    d='M5.096 14.917a1.88 1.88 0 01-1-1.683v-3.86l-1.89-1.04a.92.92 0 01-.378-.355.956.956 0 01-.119-.473.905.905 0 01.497-.835L9.1 2.918A1.79 1.79 0 0110 2.684a1.79 1.79 0 01.9.233l7.913 4.319c.158.08.28.196.367.347.087.152.13.314.13.487v5.263a.879.879 0 01-.26.647.879.879 0 01-.648.261.888.888 0 01-.648-.26.873.873 0 01-.265-.648V8.501l-1.587.872v3.86a1.88 1.88 0 01-1 1.684l-4.007 2.156a1.79 1.79 0 01-.896.233 1.79 1.79 0 01-.896-.233l-4.008-2.156zM10 10.484L15.524 7.5 10 4.516 4.476 7.5 10 10.484zm0 5.015l4.082-2.205v-2.956l-3.165 1.744c-.142.08-.29.139-.445.177a1.98 1.98 0 01-.943 0 1.764 1.764 0 01-.446-.177l-3.165-1.744v2.956L10 15.5z'
                    fill='#171923'
                />
            </g>
        </svg>
    );
}
export default SvgSchool;
