import { VStack } from '@chakra-ui/react';
import { AuditInfo } from '@Components/AuditInfo';
import Drawer from '@Components/Drawer';
import Switch from '@Components/Switch';
import TextInput from '@Components/TextInput';
import {
    useAddAbsenceReasonMutation,
    useDeleteAbsenceReasonMutation,
    useGetAbsenceReasonQuery,
    useUpdateAbsenceReasonMutation,
} from '@Redux/services/Absence';
import { useEffect, FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ManageSwitchInputProps, ManageTextProps, useForms } from '@Hooks';
import { AbsneceReasonDetails } from '@Redux/services/Absence/types';
import { getMutableForAccessor, parseError } from '@Utilities';
import { DrawerFooterActions } from '@Components/DrawerFooterActions';
import { useToast } from '@Hooks/useToast';

type IProps = {
    id: string;
    isOpen: boolean;
    onClose: () => void;
    isEditable: boolean;
    onSubmit: () => void;
};

const AbsenceReasonDetailsDrawer: FC<IProps> = ({ isOpen, onClose, id, isEditable, onSubmit }) => {
    const { t } = useTranslation();
    const { data: { data: absenceReasonData } = {}, refetch, isLoading } = useGetAbsenceReasonQuery({ id });
    const [addAbsenceReason] = useAddAbsenceReasonMutation();
    const [updateAbsenceReason] = useUpdateAbsenceReasonMutation();
    const [deleteAbsenceReason] = useDeleteAbsenceReasonMutation();

    const toast = useToast();

    useEffect(() => {
        refetch();
    }, [id]);

    const {
        formData: absenceReason,
        textFieldPropFactory,
        manageFieldPropFactory,
        switchFieldPropFactory,
        setErrors,
        errors,
    } = useForms<Partial<AbsneceReasonDetails>>(absenceReasonData ?? {}, () => ({
        isLocked: !isEditable,
    }));

    const handleDeleteAbsenceReason = () => {
        deleteAbsenceReason({ absenceReasonId: absenceReason.absenceReasonId })
            .unwrap()
            .then(() => {
                toast({
                    status: 'success',
                    description: t('AbsenceReasonModal.toastDeleteSuccess'),
                });
                onSubmit();
                onClose();
            })
            .catch((err) => parseError(toast, err));
    };

    const handleSaveAbsenceReason = () => {
        if (absenceReason.absenceReasonId) {
            handleUpdateAbsenceReason();
        } else {
            handleAddAbsenceReason();
        }
    };

    const validate = () => {
        const newErrors = { ...errors };
        const nextErrors: Record<string, string> = {};
        const validationRules = [
            {
                accessorPath: ['code'],
                validator: Boolean,
                message: t('PhoneCallDetails.fieldRequired'),
                required: true,
                validateSave: true,
            },
            {
                accessorPath: ['code'],
                validator: (code: string) => code?.length < 4,
                message: t('PhoneCallDetails.maxLength'),
                required: true,
                validateSave: true,
            },
            {
                accessorPath: ['name'],
                validator: Boolean,
                message: t('PhoneCallDetails.fieldRequired'),
                required: true,
                validateSave: true,
            },
            {
                accessorPath: ['displayOrder'],
                validator: Boolean,
                message: t('PhoneCallDetails.fieldRequired'),
                required: true,
                validateSave: true,
            },
        ];

        for (const rule of validationRules) {
            const { accessorPath, validator, message, required } = rule;

            const finalMutable = getMutableForAccessor(absenceReason, accessorPath);
            const finalProperty = accessorPath[accessorPath.length - 1];

            if (required && !validator(finalMutable[finalProperty])) {
                nextErrors[accessorPath.join('.')] = message;
            } else {
                newErrors[accessorPath.join('.')] && delete newErrors[accessorPath.join('.')];
            }
        }
        setErrors({ ...newErrors, ...nextErrors });
        if (Object.keys(nextErrors).length) {
            toast({
                status: 'error',
                description: t('AbsenceReasonDetailsDrawer.toastErrorDescription'),
            });
            return false;
        }
        return true;
    };

    const handleUpdateAbsenceReason = () => {
        if (!validate()) {
            return;
        }

        updateAbsenceReason({
            absenceReasonId: absenceReason.absenceReasonId,
            name: absenceReason.name,
            code: absenceReason.code,
            displayOrder: absenceReason.displayOrder,
            isLateReason: absenceReason.isLateReason,
            status: absenceReason.isActive,
        })
            .unwrap()
            .then(() => {
                toast({
                    status: 'success',
                    description: t('AbsenceReasonModal.toastUpdateSuccess'),
                });
                onSubmit();
                onClose();
            })
            .catch((err) => parseError(toast, err));
    };

    const handleAddAbsenceReason = () => {
        if (!validate()) {
            return;
        }

        addAbsenceReason({
            name: absenceReason.name,
            code: absenceReason.code,
            displayOrder: absenceReason.displayOrder,
            isLateReason: absenceReason.isLateReason,
            status: absenceReason.isActive,
        })
            .unwrap()
            .then(() => {
                toast({
                    status: 'success',
                    description: t('AbsenceReasonModal.toastAddSuccess'),
                });
                onSubmit();
                onClose();
            })
            .catch((err) => parseError(toast, err));
    };

    const actionMenuItems = useMemo(
        () =>
            absenceReason?.absenceReasonId
                ? [
                      {
                          label: t('QuestionForm.delete'),
                          message: t('QuestionForm.confirmationMessage'),
                          showConfirmation: true,
                          menuItemProps: {
                              onClick: async () => await handleDeleteAbsenceReason(),
                              isDisabled: isLoading,
                          },
                      },
                  ]
                : [],
        [absenceReason]
    );

    return (
        <Drawer
            isOpen={isOpen}
            onClose={onClose}
            title={absenceReason?.name}
            subtitle={t('AbsenceReasonDetailsDrawer.subtitle')}
            footer={
                <DrawerFooterActions
                    isEditable={isEditable}
                    closeButtonProps={{
                        onClick: onClose,
                        label: t('AbsenceReasonDetailsDrawer.closeDrawer'),
                        isDisabled: isLoading,
                    }}
                    saveButtonProps={{
                        onClick: handleSaveAbsenceReason,
                        label: t('AbsenceReasonDetailsDrawer.save'),
                        isDisabled: isLoading,
                    }}
                    actionMenuItems={isEditable && absenceReason?.absenceReasonId && actionMenuItems}
                />
            }
        >
            <VStack width='100%' gap={4} p={3}>
                <TextInput
                    {...textFieldPropFactory(t(`AbsenceReasonDetailsDrawer.name`), ['name'])}
                    {...manageFieldPropFactory<ManageTextProps>(['name'], 'onChangeText', 'value')}
                />
                <TextInput
                    {...textFieldPropFactory(t(`AbsenceReasonDetailsDrawer.code`), ['code'])}
                    {...manageFieldPropFactory<ManageTextProps>(['code'], 'onChangeText', 'value')}
                />
                <Switch
                    {...switchFieldPropFactory(t('AbsenceReasonDetailsDrawer.isLateReason'), ['isLateReason'])}
                    {...manageFieldPropFactory<ManageSwitchInputProps>(['isLateReason'], 'onChangeSwitch', 'isChecked')}
                />
                <TextInput
                    {...textFieldPropFactory(t(`AbsenceReasonDetailsDrawer.displayOrder`), ['displayOrder'])}
                    {...manageFieldPropFactory<ManageTextProps>(['displayOrder'], 'onChangeText', 'value')}
                />
                <Switch
                    checkedText={t('Switch.active')}
                    uncheckedText={t('Switch.inactive')}
                    {...switchFieldPropFactory(t(`AbsenceReasonDetailsDrawer.status`), ['isActive'])}
                    {...manageFieldPropFactory<ManageSwitchInputProps>(['isActive'], 'onChangeSwitch', 'isChecked')}
                />
                {id && (
                    <TextInput
                        isDisabled
                        {...textFieldPropFactory(t(`AbsenceReasonDetailsDrawer.translationKey`), ['translationKey'])}
                        {...manageFieldPropFactory<ManageTextProps>(['translationKey'], 'onChangeText', 'value')}
                    />
                )}
            </VStack>
            {(absenceReason?.modifiedBy ||
                absenceReason?.modifiedOn ||
                absenceReason?.createdOn ||
                absenceReason?.createdBy) && (
                <AuditInfo
                    modifiedBy={absenceReason?.modifiedBy}
                    modifiedOn={absenceReason?.modifiedOn}
                    createdOn={absenceReason?.createdOn}
                    createdBy={absenceReason?.createdBy}
                />
            )}
        </Drawer>
    );
};

export default AbsenceReasonDetailsDrawer;
