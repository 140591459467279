import { baseApi, GET, POST } from '../base';
import {
    MemberToListParams,
    CreateListParams,
    GetMemberListsParams,
    CommonMembersResponse,
    EventIdParam,
    Member,
    UpdateListParams,
} from './types';
import type { PartialDeep } from 'type-fest';
import { BaseResponse, MemberPhoto } from '../commonTypes';

export const participantsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        createList: builder.mutation<BaseResponse<CommonMembersResponse>, PartialDeep<CreateListParams>>({
            query: (body) => ({
                url: `Participants/CreateList`,
                method: POST,
                body,
            }),
        }),
        updateList: builder.mutation<BaseResponse<CommonMembersResponse>, PartialDeep<UpdateListParams>>({
            query: (body) => ({
                url: `Participants/UpdateList`,
                method: 'PATCH',
                body,
            }),
        }),
        addMembersToList: builder.mutation<BaseResponse<CommonMembersResponse>, PartialDeep<MemberToListParams>>({
            query: (body) => ({
                url: `Participants/AddMembersToList`,
                method: POST,
                body,
            }),
        }),
        addAllParticipantsToList: builder.mutation<
            BaseResponse<CommonMembersResponse>,
            PartialDeep<MemberToListParams>
        >({
            query: (body) => ({
                url: `Participants/AddAllParticipantsToList`,
                method: POST,
                body,
            }),
        }),
        removeMemberFromList: builder.mutation<BaseResponse<CommonMembersResponse>, PartialDeep<MemberToListParams>>({
            query: (body) => ({
                url: `Participants/RemoveMembersFromList`,
                method: POST,
                body,
            }),
        }),
        getMembersList: builder.mutation<BaseResponse<CommonMembersResponse>, PartialDeep<GetMemberListsParams>>({
            query: (body) => ({
                url: `Participants/GetMembersList`,
                method: POST,
                body,
            }),
        }),
        getList: builder.query<BaseResponse<CommonMembersResponse[]>, void>({
            query: () => ({
                url: `Participants/GetLists`,
                method: GET,
            }),
        }),
        getAdditionalParticipants: builder.query<BaseResponse<Member[]>, EventIdParam>({
            query: (params) => ({
                url: `Participants/GetAdditionalParticipants/${params.id}`,
                method: GET,
            }),
        }),
        getParticipantPhoto: builder.query<BaseResponse<MemberPhoto>, EventIdParam>({
            query: (params) => ({
                url: `Participants/GetParticipantPhoto/${params.id}`,
                method: GET,
            }),
        }),
    }),
});

export const {
    useGetMembersListMutation,
    useCreateListMutation,
    useUpdateListMutation,
    useAddMembersToListMutation,
    useAddAllParticipantsToListMutation,
    useGetListQuery,
    useRemoveMemberFromListMutation,
    useLazyGetAdditionalParticipantsQuery,
    useGetParticipantPhotoQuery,
} = participantsApi;
