import * as React from 'react';
function SvgAlertError(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g clipPath='url(#alert-error_svg__clip0_3002_68083)'>
                <mask
                    id='alert-error_svg__a'
                    style={{
                        maskType: 'alpha',
                    }}
                    maskUnits='userSpaceOnUse'
                    x={0}
                    y={0}
                    width={24}
                    height={24}
                >
                    <path fill='#D9D9D9' d='M0 0h24v24H0z' />
                </mask>
                <g mask='url(#alert-error_svg__a)'>
                    <path
                        d='M11.83 17.244c.332 0 .611-.112.836-.337.226-.224.339-.503.339-.835 0-.332-.113-.612-.337-.84a1.127 1.127 0 00-.835-.34c-.332 0-.611.113-.837.34a1.146 1.146 0 00-.338.84c0 .332.112.61.337.835.225.225.503.337.835.337zm.001-4.191c.32 0 .589-.11.809-.33.22-.219.329-.488.329-.808V8.053c0-.32-.11-.589-.33-.808-.219-.22-.488-.33-.808-.33-.319 0-.588.11-.808.33-.22.22-.33.489-.33.808v3.862c0 .32.11.59.33.809.22.22.49.329.808.329zm0 9.191a9.946 9.946 0 01-3.983-.803 10.296 10.296 0 01-3.237-2.18 10.296 10.296 0 01-2.18-3.236 9.946 9.946 0 01-.803-3.984c0-1.415.268-2.743.803-3.984a10.297 10.297 0 012.18-3.237 10.297 10.297 0 013.237-2.18 9.946 9.946 0 013.983-.802c1.416 0 2.744.267 3.984.803 1.24.535 2.32 1.261 3.237 2.18a10.296 10.296 0 012.18 3.236 9.946 9.946 0 01.803 3.984 9.946 9.946 0 01-.803 3.984 10.295 10.295 0 01-2.18 3.237 10.295 10.295 0 01-3.237 2.18 9.946 9.946 0 01-3.984.802zm0-2.275c2.218 0 4.094-.767 5.628-2.3 1.534-1.535 2.3-3.41 2.3-5.628 0-2.217-.766-4.093-2.3-5.627-1.534-1.534-3.41-2.301-5.628-2.301-2.217 0-4.093.767-5.627 2.3-1.534 1.535-2.3 3.41-2.3 5.628 0 2.217.766 4.093 2.3 5.627 1.534 1.534 3.41 2.301 5.627 2.301z'
                        fill='#C91D25'
                    />
                </g>
            </g>
            <defs>
                <clipPath id='alert-error_svg__clip0_3002_68083'>
                    <path fill='#fff' d='M0 0h24v24H0z' />
                </clipPath>
            </defs>
        </svg>
    );
}
export default SvgAlertError;
