import { ComponentProps } from 'react';
import {
    Modal as ChakraModal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
} from '@chakra-ui/react';

interface IModalProps extends ComponentProps<typeof ChakraModal> {
    title?: string;
    subTitle?: string;
    noCloseButton?: boolean;
    contentProps?: ComponentProps<typeof ModalContent>;
    footer?: React.ReactNode;
    modalBodyProps?: ComponentProps<typeof ModalBody>;
}

const Modal = ({
    title,
    subTitle,
    noCloseButton,
    children,
    contentProps,
    modalBodyProps,
    footer,
    ...rest
}: IModalProps) => {
    return (
        <ChakraModal closeOnOverlayClick={false} {...rest}>
            <ModalOverlay />
            <ModalContent {...contentProps}>
                {title && (
                    <ModalHeader textStyle='lg-medium' pt={4} pl={3} pr={3}>
                        {title}
                        {subTitle && (
                            <Text textStyle={'md-normal'} fontWeight='normal' color='secondary'>
                                {subTitle}
                            </Text>
                        )}
                    </ModalHeader>
                )}
                {!noCloseButton && (
                    <ModalCloseButton
                        color={'text-primary'}
                        _focus={{
                            borderColor: 'text-primary',
                        }}
                    />
                )}
                <ModalBody pl={4} pr={4} {...modalBodyProps}>
                    {children}
                </ModalBody>
                <ModalFooter>{footer}</ModalFooter>
            </ModalContent>
        </ChakraModal>
    );
};

export default Modal;
