import React, { FC } from 'react';
import { SystemStyleObject, Text } from '@chakra-ui/react';

import { Box } from '@chakra-ui/react';

interface IProps {
    children: React.ReactNode;
    legendText: string;
    sx?: SystemStyleObject;
    legendTextStyle?: string;
}

const Fieldset: FC<IProps> = function ({ children, legendTextStyle = 'md-semibold', legendText, sx }) {
    return (
        <Box
            as='fieldset'
            sx={{
                borderColor: 'var(--chakra-colors-border-primary)',
                borderRadius: 5,
                borderStyle: 'solid',
                borderWidth: 2,
                paddingLeft: 2,
                ...sx,
            }}
        >
            <legend>
                <Text textStyle={legendTextStyle}>{legendText}</Text>
            </legend>
            {children}
        </Box>
    );
};

export default Fieldset;
