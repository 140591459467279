import { BasicLookup } from '@Redux/services/LookupApi/types';
import { createLookupMap } from '@Utilities';
import React, { useMemo } from 'react';
import { Text } from '@chakra-ui/react';
import { report_status } from 'src/constants';
import { CellProps } from 'react-table';
import { StudentRecord } from '@Redux/services/Reports/types';

interface IProps extends CellProps<object> {
    statusCodes?: BasicLookup[];
}

const StatusCell: React.FC<IProps> = ({ value, row, statusCodes }) => {
    const rowData = row.original as StudentRecord;
    const statusCodesMap = useMemo(() => createLookupMap<BasicLookup>(statusCodes), [statusCodes]);
    const color =
        value === report_status.Pending
            ? 'text-secondary'
            : value === report_status.Published
            ? 'text-brand-primary'
            : 'text-placeholder';
    return (
        <Text textColor={color} width={'100%'}>
            {statusCodesMap[value]
                ? statusCodesMap[value].value + (rowData.reportComment ? `: ${rowData.reportComment}` : '')
                : '--'}
        </Text>
    );
};

export default StatusCell;
