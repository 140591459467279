import FormHeader from '@Components/FormHeader';
import React from 'react';
import InnerFormHeader from '../InnerFormHeader';
import { FormComponentProps, ManageTextProps } from '@Hooks';
import { useTranslation } from 'react-i18next';
import { Grid } from '@chakra-ui/react';
import SelectInput from '@Components/SelectInput';
import TextInput from '@Components/TextInput';
import { useGetLanguagesRequestQuery } from '@Redux/services/LookupApi';
import SvgSchool from '@Icon/School';

const PreviousSchool: React.FC<FormComponentProps> = ({
    id,
    manageFieldPropFactory,
    selectFieldPropFactory,
    textFieldPropFactory,
}) => {
    const [t] = useTranslation();

    //get languages from redux
    const { data: { data: languages = [] } = {} } = useGetLanguagesRequestQuery({ searchText: '' });

    return (
        <FormHeader
            id={id}
            header={
                <InnerFormHeader divider={true} title={t('RegistrationDetails.previousSchool')} icon={<SvgSchool />} />
            }
            label={''}
        >
            <Grid
                column={{ md: 2, sm: 1 }}
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                }}
                rowGap={4}
                templateRows='auto'
            >
                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.previousSchool.schoolDistrict'), [
                        'admissionInformation',
                        'previousDistrictName',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['admissionInformation', 'previousDistrictName'],
                        'onChangeText',
                        'value'
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.previousSchool.schoolName'), [
                        'admissionInformation',
                        'previousSchool',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['admissionInformation', 'previousSchool'],
                        'onChangeText',
                        'value'
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.previousSchool.schoolAddress'), [
                        'admissionInformation',
                        'address',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['admissionInformation', 'address'],
                        'onChangeText',
                        'value'
                    )}
                />

                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.previousSchool.schoolLanguage'), [
                        'admissionInformation',
                        'previousLanguageType',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['admissionInformation', 'previousLanguageType'],
                        'onChangeValue',
                        'value'
                    )}
                    options={languages}
                />
            </Grid>
        </FormHeader>
    );
};

export default PreviousSchool;
