import { TFunction } from 'react-i18next';
import { UpdateStatusParam } from '../index';

const sideBarData = (updateStatus: UpdateStatusParam, t: TFunction) => ({
    groups: [
        {
            items: [
                {
                    label: t('Update.all'),
                    id: '/updates/all',
                    active: updateStatus === 'all',
                },
                {
                    label: t('Update.submitted'),
                    id: '/updates/submitted',
                    active: updateStatus === 'submitted',
                },
                {
                    label: t('Update.approved'),
                    id: '/updates/approved',
                    active: updateStatus === 'approved',
                },
                {
                    label: t('Update.deleted'),
                    id: '/updates/deleted',
                    active: updateStatus === 'deleted',
                },
                {
                    label: t('Update.rejected'),
                    id: '/updates/rejected',
                    active: updateStatus === 'rejected',
                },
            ],
        },
    ],
});

export { sideBarData };
