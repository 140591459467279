import { useCallback, useEffect, useMemo, useState } from 'react';
import { Bus } from 'baconjs';
import { isEmpty } from 'lodash';

interface IDebounceProps<T> {
    data: T;
    onChange: (data: T, hasChange: boolean) => Promise<void>;
}

const useAutoSave = <T extends object>({ data, onChange }: IDebounceProps<T>) => {
    const [hasPendingChange, setHasPendingChange] = useState(false);
    const [lastChangeTimestamp, setLastChangeTimestamp] = useState(-Infinity);

    const busyBus = useMemo(() => new Bus<boolean>(), []);
    const primaryBus = useMemo(() => new Bus(), []);
    const onChangeBus = useMemo(() => new Bus(), []);
    const onChangeBufferBus = useMemo(() => new Bus(), []);

    useEffect(() => {
        if (isEmpty(data)) return;
        const timestamp = Date.now();
        setHasPendingChange(true);
        setLastChangeTimestamp(timestamp);
        onChangeBus.push([timestamp, data]);
    }, [data]);

    const pushToOnChangeBufferBus = useCallback(
        (value: T) => {
            onChangeBufferBus.push(value);
        },
        [onChangeBufferBus]
    );

    const primaryOnValue = useCallback(
        async ([timestamp, changedData]) => {
            if (timestamp < lastChangeTimestamp) {
                return;
            }
            busyBus.push(true);
            await onChange(changedData, hasPendingChange);
            busyBus.push(false);
        },
        [busyBus, lastChangeTimestamp, hasPendingChange]
    );

    const handleManualSave = useCallback(() => {
        const timestamp = Date.now();
        setLastChangeTimestamp(timestamp);
        primaryBus.push([timestamp, data]);
    }, [primaryBus, setLastChangeTimestamp, data]);

    useEffect(
        () => primaryBus.holdWhen(busyBus.toProperty()).onValue(primaryOnValue),
        [primaryBus, primaryOnValue, busyBus]
    );

    useEffect(() => onChangeBus.onValue(pushToOnChangeBufferBus), [onChangeBus, pushToOnChangeBufferBus]);

    useEffect(
        () =>
            onChangeBufferBus
                .debounce(parseInt(process.env.REACT_APP_AUTO_SAVE_INTERVAL ?? '60000', 10))
                .onValue((value) => {
                    primaryBus.push(value);
                }),
        [onChangeBufferBus, primaryBus]
    );

    return {
        hasPendingChange,
        handleManualSave,
        setHasPendingChange,
    };
};

export default useAutoSave;
