import {
    AlertDialog as ChakraAlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    Flex,
    UseDisclosureProps,
} from '@chakra-ui/react';
import { FC, useRef, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

type AlertProps = UseDisclosureProps & {
    title?: string;
    message?: string;
    alertType?: 'success' | 'error' | 'info' | 'brand';
    submitHandle: () => void;
    submitLabel?: string;
    submitLeftIcon?: React.ReactNode;
    children?: React.ReactNode;
    disabled?: boolean;
    alertIcon?: React.ReactNode;
    submitButtonProps?: ComponentProps<typeof Button>;
    cancelButtonLabel?: string;
    cancelButtonProps?: ComponentProps<typeof Button>;
};

const AlertDialog: FC<AlertProps> = ({
    onClose,
    isOpen,
    alertType = 'info',
    title,
    message,
    submitHandle,
    submitLabel,
    submitLeftIcon,
    children,
    disabled,
    alertIcon,
    submitButtonProps,
    cancelButtonLabel,
    cancelButtonProps,
}) => {
    const cancelRef = useRef<HTMLButtonElement>();

    const { t } = useTranslation();

    return (
        <ChakraAlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <Flex flexDirection='row' width='100%'>
                        {alertIcon && (
                            <Flex
                                bgColor='surface-brand-primary'
                                borderLeftRadius='8px'
                                borderBottomLeftRadius='8px'
                                padding='24px 16px'
                            >
                                {alertIcon}
                            </Flex>
                        )}
                        <Box w='100%'>
                            <AlertDialogHeader
                                w='100%'
                                textStyle={'sm-semibold'}
                                fontSize={'sm'}
                                color={'text-primary'}
                                pb={1}
                            >
                                {title}
                            </AlertDialogHeader>
                            <AlertDialogBody
                                textStyle='sm-normal'
                                color='text-secondary'
                                width='100%'
                                whiteSpace='pre-wrap'
                            >
                                {message ? parse(message) : t('Alert.message')}
                                {children}
                            </AlertDialogBody>

                            <AlertDialogFooter display='flex' justifyContent='end'>
                                <Button
                                    variant='secondary'
                                    ref={cancelRef}
                                    onClick={onClose}
                                    mr={3}
                                    isDisabled={disabled}
                                    {...cancelButtonProps}
                                >
                                    {cancelButtonLabel ? cancelButtonLabel : t('Alert.cancel')}
                                </Button>
                                <Button
                                    variant={alertType}
                                    onClick={submitHandle}
                                    isDisabled={disabled}
                                    {...submitButtonProps}
                                >
                                    {submitLeftIcon}
                                    {submitLabel}
                                </Button>
                            </AlertDialogFooter>
                        </Box>
                    </Flex>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </ChakraAlertDialog>
    );
};

export { AlertDialog };
