import { ValidatorType } from '@Utilities';
import { TFunction } from 'react-i18next';

export function Validator(t: TFunction): ValidatorType[] {
    return [
        {
            accessorPath: ['newDueDate'],
            validator: Boolean,
            message: t('EventDetails.nameRequired'),
            required: true,
        },
        {
            accessorPath: ['newStartDate'],
            validator: Boolean,
            message: t('EventDetails.teamIdRequired'),
            required: true,
        },
        {
            accessorPath: ['newEndDate'],
            validator: Boolean,
            message: t('EventDetails.organizedByIdRequired'),
            required: true,
        },
    ];
}
