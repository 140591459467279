import { Box, Checkbox, HStack, useDisclosure } from '@chakra-ui/react';
import Table, { Column } from '@Components/Table';
import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Filters, TableFilters } from '../TableFilters';
import { Button, Pagination } from '@Components';
import { ReportingPeriodDetails, StudentRecord } from '@Redux/services/Reports/types';
import { useGetInvitedGradesQuery, useGetReportsStatusQuery } from '@Redux/services/LookupApi';
import FullnameCell from '../FullnameCell';
import StatusCell from '../StatusCell';
import GradeCell from '../GradeCell';
import { BasicLookup } from '@Redux/services/LookupApi/types';
import { SortingRule } from 'react-table';
import { report_analytics_types, report_status } from 'src/constants';
import { PagedResult } from '@Redux/services/commonTypes';
import DiscrepancyCell from '../DiscrepancyCell';
import { Plus } from '@Icon';
import AddStudents from '@Pages/ReportPeriodDetails/components/AddStudents';
import { format } from 'date-fns';
import { useToast } from '@Hooks/useToast';
import RemoveStudentCell from '../RemoveStudentCell';

interface IStudentTableProps {
    details: ReportingPeriodDetails;
    isAnalytics: boolean;
    selectedRows: string[];
    onSortChange: (sortBy: SortingRule<StudentRecord>[]) => void;
    handlePageSizeChange: (pageSize: number) => void;
    handlePageChange: (page: number) => void;
    handleFilterChange: (key: string) => (value: number | string) => void;
    handleSelect: (checked: boolean, id: string) => void;
    handleSelectAll: (e: ChangeEvent<HTMLInputElement>) => void;
    handleRefresh: () => void;
    handleExport?: () => void;
    handleViewPdf?: (studentId: string, reportId: string) => void;
    isLoading: boolean;
    allSelected: boolean;
    currentPage: number;
    filters: Filters;
    pageSize: number;
    studentsList: StudentRecord[];
    teachersList: BasicLookup[];
    pagedResult: PagedResult;
    allStudentIds: string[];
}

export const StudentTable: FC<IStudentTableProps> = ({
    details,
    isAnalytics,
    selectedRows,
    allSelected,
    onSortChange,
    handlePageSizeChange,
    handlePageChange,
    handleFilterChange,
    handleSelect,
    handleSelectAll,
    handleRefresh,
    handleExport,
    handleViewPdf,
    isLoading,
    currentPage,
    filters,
    pageSize,
    studentsList,
    teachersList,
    pagedResult,
    allStudentIds,
}) => {
    const { t } = useTranslation();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { data: { data: grades = [] } = {} } = useGetInvitedGradesQuery();
    const toast = useToast();
    const { data: { data: statusCodes = [] } = {} } = useGetReportsStatusQuery();
    const analyticsStatusCodes = [
        { key: report_analytics_types.MissingReportCards, value: t('Records.students.filters.missing') },
        { key: report_analytics_types.MismatchedStudentInformation, value: t('Records.students.filters.mismatched') },
        { key: report_analytics_types.UnknownOENs, value: t('Records.students.filters.unknownOENs') },
        { key: report_analytics_types.OpenRatesOfReportCards, value: t('Records.students.filters.reportCardsViewed') },
    ] as BasicLookup[];
    const [columnWidth, setColumnWidth] = useState(0);
    const tableRef = useRef<HTMLDivElement>(null);

    const columns = useMemo<Column[]>(() => {
        const columns: Column[] = [
            {
                Header: (
                    <Checkbox
                        colorScheme={'teal'}
                        disabled={allStudentIds.length === 0}
                        isChecked={
                            selectedRows.length !== 0 &&
                            selectedRows.length ===
                                studentsList.filter(
                                    (x) =>
                                        x.reportStatus !== report_status.Removed &&
                                        x.reportStatus !== report_status.Missing
                                ).length
                        }
                        onChange={handleSelectAll}
                        bg='white'
                    />
                ),
                accessor: 'select',
                Cell: (cell) => {
                    const studentRecord = useMemo(() => cell.row.original as StudentRecord, [cell]);
                    return (
                        <Checkbox
                            colorScheme={'teal'}
                            disabled={
                                report_status.Missing === studentRecord.reportStatus ||
                                report_status.Removed === studentRecord.reportStatus
                            }
                            isChecked={selectedRows.includes(studentRecord.reportingStudentId)}
                            onChange={(event) => handleSelect(event.target.checked, studentRecord.reportingStudentId)}
                            bg='white'
                        />
                    );
                },
                disableSortBy: true,
            },
            {
                Header: t('Records.students.fullName'),
                accessor: 'fullName',
                headerAlign: 'left',
                Cell: FullnameCell,
            },
            {
                Header: t('Records.students.status'),
                accessor: 'reportStatus',
                headerAlign: 'left',
                Cell: StatusCell,
                width: columnWidth,
            },
            {
                Header: t('Records.students.oen'),
                accessor: 'oen',
                headerAlign: 'left',
            },
            {
                Header: t('Records.students.discrepancy'),
                accessor: 'function',
                headerAlign: 'left',
                show: isAnalytics && filters.status === report_analytics_types.MismatchedStudentInformation,
                Cell: DiscrepancyCell,
            },
            {
                Header: t('Records.students.associations'),
                accessor: 'reportSplitterExecutionCount',
                headerAlign: 'left',
                show: false,
            },
            {
                Header: t('Records.students.grade'),
                accessor: 'grade',
                headerAlign: 'left',
                Cell: GradeCell,
            },
            {
                Header: t('Records.students.homeroom'),
                accessor: 'homeRoom',
                headerAlign: 'left',
            },
            {
                Header: t('Records.students.homeroomTeacher'),
                accessor: 'homeTeacher',
                headerAlign: 'left',
            },
        ];
        if (!isAnalytics) {
            columns.push({
                Header: <>&nbsp;</>,
                accessor: 'action',
                headerAlign: 'center',
                Cell: RemoveStudentCell,
                disableSortBy: true,
            });
        }
        return columns;
    }, [
        allSelected,
        isAnalytics,
        filters.status,
        selectedRows,
        allStudentIds,
        handleSelectAll,
        handleSelect,
        columnWidth,
    ]);

    useEffect(() => {
        setColumnWidth(tableRef.current?.clientWidth / 8);
    }, []);

    return (
        <Box
            ref={tableRef}
            bg='surface-primary'
            borderWidth={2}
            borderRadius={8}
            borderStyle='solid'
            borderColor='border-primary'
            overflowX={'auto'}
        >
            <Table
                manualSortBy={true}
                isDragDisabled={true}
                containerStyle={{ borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderRadius: 0 }}
                emptyMessage={t('Records.students.emptyTable')}
                rawData={studentsList}
                filter={
                    <TableFilters
                        onChange={handleFilterChange}
                        handleRefresh={handleRefresh}
                        handleExport={handleExport}
                        filters={filters}
                        statusCodes={isAnalytics ? analyticsStatusCodes : statusCodes}
                        grades={grades}
                        teachers={teachersList}
                        isAnalytics={isAnalytics}
                        rowCount={pagedResult.rowCount}
                    />
                }
                columns={columns}
                userProps={{
                    handleSelect,
                    handleViewPdf,
                    selectedRows,
                    statusCodes,
                    grades,
                    isAnalytics,
                    filterStatus: filters.status,
                    schoolId: details.schoolId,
                    reportingPeriodId: details.reportingPeriodId,
                    schoolYearId: details.schoolYearId,
                    onDelete: handleRefresh,
                }}
                isLoading={isLoading}
                onSortChange={onSortChange}
                manualPagination={true}
                addRowButton={
                    <HStack gap='8px'>
                        <Button variant='light' leftIcon={<Plus />} onClick={onOpen}>
                            <Trans i18nKey='StudentTable.addStudentsButtonLabel' />
                        </Button>
                    </HStack>
                }
            />
            <Pagination
                currentPage={currentPage}
                onPageChange={handlePageChange}
                pageSize={pageSize}
                onPageSizeChange={handlePageSizeChange}
                totalPages={pagedResult.pageCount}
                totalResults={pagedResult.rowCount}
                viewMode={'Numbered'}
            />
            {isOpen && (
                <AddStudents
                    isOpen={isOpen}
                    onClose={onClose}
                    onAddStudents={handleRefresh}
                    schoolId={details.schoolId}
                    schoolYearId={details.schoolYearId}
                    reportingPeriodId={details.reportingPeriodId}
                    onOpen={onOpen}
                    schoolName={details.school}
                    reportingPeriod={details.reportingPeriod}
                    schoolYear={`${format(new Date(details.schoolYearStart), 'yyyy')} - ${format(
                        new Date(details.schoolYearEnd),
                        'yyyy'
                    )}`}
                />
            )}
        </Box>
    );
};
