import {
    Box,
    Flex,
    Text,
    Thead,
    Table as ChakraTable,
    Tr,
    Td,
    Tbody,
    Divider,
    Skeleton,
    Th,
    IconButton,
    SystemStyleObject,
    BoxProps,
    useDimensions,
    useMergeRefs,
} from '@chakra-ui/react';
import React, { useMemo, useEffect, useState, useRef } from 'react';
import { SortingRule, useSortBy, useTable, useRowSelect } from 'react-table';
import TableCell from './components/TableCell';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { DragHandle } from '@Icon';
import { isString, times } from 'lodash';
import { DEFAULT_PAGE_SIZE } from 'src/constants';
import { ExpandableText } from '@Components/ExpandableText';
import { Column as ReactTableColumn } from 'react-table';
import { HeaderColumn } from './components/HeaderColumn';
import { useMeasure } from 'react-use';

export type Column = {
    headerAlign?: 'left' | 'right' | 'center';
    show?: boolean;
} & ReactTableColumn;

interface ITableProps<T extends object> {
    manualSortBy: boolean;
    manualPagination?: boolean;
    onSortChange?: (sortBy: SortingRule<T>[]) => void;
    columns: Column[];
    action?: React.ReactNode;
    rawData: T[];
    initialSortBy?: SortingRule<T>[];
    userProps?: object;
    addRowButton?: React.ReactNode;
    emptyMessage: string;
    isLoading?: boolean;
    isDragDisabled?: boolean;
    stickyColumns?: 1 | 2;
    onDragEnd?: OnDragEndResponder;
    onEditHandler?: (row) => void;
    tableWidth?: SystemStyleObject;
    enableRowSelection?: boolean;
    pagination?: React.ReactNode;
    filter?: React.ReactNode;
    stickyHeader?: boolean;
    containerStyle?: BoxProps;
    wrapperStyle?: SystemStyleObject;
}

const Table = <T extends object>({
    columns,
    manualSortBy,
    rawData,
    initialSortBy,
    onSortChange,
    onDragEnd,
    userProps,
    addRowButton,
    action,
    emptyMessage,
    isDragDisabled = true,
    isLoading = false,
    manualPagination = false,
    enableRowSelection = false,
    onEditHandler,
    tableWidth,
    pagination,
    stickyColumns = 1,
    filter,
    stickyHeader,
    containerStyle,
    wrapperStyle,
}: ITableProps<T>) => {
    const data = useMemo(() => rawData, [rawData]);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy },
        setSortBy,
    } = useTable(
        {
            columns,
            data,
            manualSortBy,
            manualPagination,
            initialState: {
                sortBy: initialSortBy || [],
                hiddenColumns: columns.map((column) => {
                    if (column?.show === false)
                        return typeof column?.accessor === 'function' ? column?.Header.toString() : column?.accessor;
                }),
            },
            disableSortRemove: true,
            enableRowSelection,
            autoResetSortBy: false,
        },
        useSortBy,
        enableRowSelection && useRowSelect
    );
    const [firstColumnWidth, setFirstColumnWidth] = useState(0);
    const [firstColumnId] = useState<string | null>(stickyColumns == 2 ? Date.now().toString() : null);

    useMemo(() => {
        if (initialSortBy && initialSortBy !== sortBy) {
            setSortBy(initialSortBy);
        }
    }, [initialSortBy]);

    useEffect(() => {
        onSortChange && onSortChange(sortBy);
    }, [sortBy]);

    const getItemStyle = (isDragging, draggableStyle) => {
        const style = isDragging
            ? {
                  bg: 'surface-tertiary',
                  display: 'inline-table',
                  ...draggableStyle,
              }
            : {
                  ...draggableStyle,
              };
        return style;
    };

    const itemRef = useRef();
    const [measureRef, measure] = useMeasure();
    const dimension = useDimensions(itemRef, true);
    const ref = useMergeRefs(measureRef, itemRef);

    useEffect(() => {
        let val = measure.width;
        if (dimension) {
            val =
                val + dimension.padding.left + dimension.padding.right + dimension.border.left + dimension.border.right;
        }

        setFirstColumnWidth(val);
    }, [measure.width]);

    //TODO: Make different components and more readable
    return (
        <Box overflowX={'hidden'} w={'full'} sx={wrapperStyle}>
            <Flex
                h={'full'}
                position='relative'
                flexDirection={'column'}
                borderRadius={5}
                borderWidth={2}
                borderStyle='solid'
                borderColor='border-primary'
                mt={action && 4}
                justifyContent='center'
                {...containerStyle}
            >
                {action}
                {filter}
                <Box
                    h={'full'}
                    maxWidth='100%'
                    width='100%'
                    overflowX='auto'
                    style={{
                        scrollbarColor: '#319795 white',
                    }}
                    css={{
                        '&::-webkit-scrollbar': {
                            width: '16px',
                            height: '16px',
                            background: 'surface-primary',
                        },
                        '&::-webkit-scrollbar-track': {
                            width: '16px',
                            height: '16px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: '#319795',
                            borderRadius: '24px',
                        },
                    }}
                >
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId='droppable'>
                            {(provided) => (
                                <ChakraTable
                                    background='surface-primary'
                                    {...getTableProps()}
                                    sx={!tableWidth && { width: isLoading ? '100%' : '100%' }}
                                    __css={tableWidth}
                                >
                                    <Thead zIndex={2} position={stickyHeader ? 'sticky' : 'initial'} top={0}>
                                        {headerGroups.map((headerGroup, idx) => (
                                            <Tr {...headerGroup.getHeaderGroupProps()} key={idx}>
                                                {!isDragDisabled && (
                                                    <Th
                                                        position={'sticky'}
                                                        left={0}
                                                        zIndex={1}
                                                        backgroundColor={'var(--chakra-colors-surface-secondary)'}
                                                        maxW={'30px'}
                                                    >
                                                        &nbsp;
                                                        <Box
                                                            as='span'
                                                            position='absolute'
                                                            right={0}
                                                            w='100%'
                                                            height='33%'
                                                            borderRightWidth={'1px'}
                                                            borderRightStyle={'solid'}
                                                            bottom={0}
                                                            borderColor={'border-primary'}
                                                            borderBottomWidth={'1px'}
                                                            borderBottomStyle={'solid'}
                                                        ></Box>
                                                    </Th>
                                                )}
                                                {headerGroup.headers.map((column, i) => (
                                                    <TableCell
                                                        position='relative'
                                                        {...(stickyColumns &&
                                                            i < stickyColumns && {
                                                                position: 'sticky',
                                                                left:
                                                                    i === 0
                                                                        ? !isDragDisabled
                                                                            ? rows.length === 0 && !isLoading
                                                                                ? '48px'
                                                                                : '88px'
                                                                            : 0
                                                                        : i === 1
                                                                        ? `${firstColumnWidth}px`
                                                                        : 0,
                                                                zIndex: 1,
                                                                backgroundColor:
                                                                    'var(--chakra-colors-surface-secondary)',
                                                            })}
                                                        key={i}
                                                        isSorted={column.isSorted}
                                                        isSortedDesc={column.isSortedDesc}
                                                        disableSortBy={column.disableSortBy}
                                                        width={columns[i].width ? Number(columns[i].width) : undefined}
                                                        sortBy={sortBy?.[0]}
                                                        containerProps={{
                                                            justifyContent: isString(column.sortType)
                                                                ? columns[i].headerAlign
                                                                : 'left',
                                                        }}
                                                        {...column.getHeaderProps(
                                                            column.getSortByToggleProps(
                                                                column.canSort && {
                                                                    title: column.render('Header').toString(),
                                                                }
                                                            )
                                                        )}
                                                        ref={stickyColumns === 2 && i === 0 ? ref : null}
                                                        id={stickyColumns === 2 && i === 0 ? firstColumnId : column.id}
                                                    >
                                                        <HeaderColumn rows={rows} i={i} column={column} />

                                                        {
                                                            <Box
                                                                as='span'
                                                                position='absolute'
                                                                right={0}
                                                                w='100%'
                                                                height='33%'
                                                                {...(i < headerGroup.headers.length - 1 && {
                                                                    style: {
                                                                        borderRightWidth: '1px',
                                                                        borderRightStyle: 'solid',
                                                                    },
                                                                })}
                                                                borderColor={'border-primary'}
                                                                borderBottomWidth={'1px'}
                                                                borderBottomStyle={'solid'}
                                                                bottom={0}
                                                            ></Box>
                                                        }
                                                    </TableCell>
                                                ))}
                                            </Tr>
                                        ))}
                                    </Thead>

                                    <Tbody
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        {...getTableBodyProps()}
                                    >
                                        {rows.length === 0 && !isLoading && (
                                            <Tr>
                                                <Td colSpan={columns.length}>
                                                    <Text textStyle='sm-normal' color='grey.600'>
                                                        {emptyMessage}
                                                    </Text>
                                                </Td>
                                            </Tr>
                                        )}
                                        {isLoading &&
                                            times(DEFAULT_PAGE_SIZE, () => (
                                                <Tr>
                                                    {[...Array(headerGroups[0]?.headers?.length).keys()].map((d) => (
                                                        <Td textAlign='center' key={d}>
                                                            <Skeleton height={'20px'} isLoaded={false} />
                                                        </Td>
                                                    ))}
                                                </Tr>
                                            ))}
                                        {rows.length > 0 &&
                                            !isLoading &&
                                            rows.map((row, index) => {
                                                prepareRow(row);
                                                return (
                                                    <Draggable
                                                        isDragDisabled={isDragDisabled}
                                                        key={index}
                                                        draggableId={index.toString()}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <>
                                                                <Tr
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}
                                                                    key={index}
                                                                    {...row.getRowProps()}
                                                                    cursor={onEditHandler && 'pointer'}
                                                                    onClick={() => onEditHandler && onEditHandler(row)}
                                                                >
                                                                    {!isDragDisabled && (
                                                                        <Td
                                                                            borderBottom={'0px'}
                                                                            position={'sticky'}
                                                                            left={0}
                                                                            zIndex={1}
                                                                            textAlign='center'
                                                                            backgroundColor='white'
                                                                        >
                                                                            <IconButton
                                                                                bg={'surface-primary'}
                                                                                ref={provided.innerRef}
                                                                                {...provided.dragHandleProps}
                                                                                aria-label='drag-handle'
                                                                                _hover={{ bg: 'none' }}
                                                                                icon={
                                                                                    <DragHandle
                                                                                        width='24px'
                                                                                        height='24px'
                                                                                    />
                                                                                }
                                                                            />
                                                                            <Box
                                                                                as='span'
                                                                                position='absolute'
                                                                                right={0}
                                                                                w='100%'
                                                                                height='33%'
                                                                                borderRightWidth={'1px'}
                                                                                borderRightStyle={'solid'}
                                                                                borderColor={'border-primary'}
                                                                                borderBottomWidth={'1px'}
                                                                                borderBottomStyle={'solid'}
                                                                                bottom={'1px'}
                                                                            ></Box>
                                                                        </Td>
                                                                    )}
                                                                    {row.cells.map((cell, i) => (
                                                                        <Td
                                                                            borderTop={'0px'}
                                                                            borderBottom={'0px'}
                                                                            position='relative'
                                                                            {...cell.getCellProps()}
                                                                            key={i}
                                                                            {...(stickyColumns &&
                                                                                i < stickyColumns && {
                                                                                    style: {
                                                                                        position: 'sticky',
                                                                                        left:
                                                                                            i === 0
                                                                                                ? !isDragDisabled
                                                                                                    ? '88px'
                                                                                                    : 0
                                                                                                : i === 1
                                                                                                ? `${firstColumnWidth}px`
                                                                                                : 0,
                                                                                        zIndex: 1,
                                                                                        backgroundColor: 'white',
                                                                                    },
                                                                                })}
                                                                        >
                                                                            <ExpandableText
                                                                                noOfLines={1}
                                                                                textAlign={columns[i].headerAlign}
                                                                                expandableWidth={
                                                                                    columns[i].width
                                                                                        ? columns[i].width.toString()
                                                                                        : undefined
                                                                                }
                                                                            >
                                                                                {cell.render('Cell', {
                                                                                    original: cell.row.original,
                                                                                    ...userProps,
                                                                                })}
                                                                            </ExpandableText>

                                                                            {
                                                                                <Box
                                                                                    as='span'
                                                                                    position='absolute'
                                                                                    right={0}
                                                                                    w='100%'
                                                                                    height='33%'
                                                                                    {...(i < row.cells.length - 1 && {
                                                                                        style: {
                                                                                            borderRightWidth: '1px',
                                                                                            borderRightStyle: 'solid',
                                                                                        },
                                                                                    })}
                                                                                    borderColor={'border-primary'}
                                                                                    borderBottomWidth={'1px'}
                                                                                    borderBottomStyle={'solid'}
                                                                                    bottom={'1px'}
                                                                                    pointerEvents={'none'}
                                                                                ></Box>
                                                                            }
                                                                        </Td>
                                                                    ))}
                                                                </Tr>
                                                            </>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                        {provided.placeholder}
                                    </Tbody>
                                </ChakraTable>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Box>
                {addRowButton && (
                    <Box bg='surface-primary' position='relative' pt={8} pb={8}>
                        <Divider />
                        <Flex width={'100%'} position={'absolute'} bottom='12px' justifyContent='center'>
                            {addRowButton}
                        </Flex>
                    </Box>
                )}
                {pagination}
            </Flex>
        </Box>
    );
};

export default Table;
