import FormHeader from '@Components/FormHeader';
import React from 'react';
import InnerFormHeader from '../InnerFormHeader';
import { FormComponentProps } from '@Hooks';
import { useTranslation } from 'react-i18next';
import HomeAddressDetails from './components/HomeAddress';
import ParentAddressDetails from './components/ParentAddress';
import ChildMailingAddressDetails from './components/ChildMailingAddress';
import {
    useGetCitiesRequestQuery,
    useGetCountriesRequestQuery,
    useGetProvincesRequestQuery,
} from '@Redux/services/LookupApi';
import SvgAddress from '@Icon/Address';
import { RegistrationInformation } from '@Redux/services/Registration/types';

interface HomeAddressProps extends FormComponentProps {
    registrationInformation: RegistrationInformation;
}

const HomeAddress: React.FC<HomeAddressProps> = ({
    id,
    manageFieldPropFactory,
    selectFieldPropFactory,
    textFieldPropFactory,
    switchFieldPropFactory,
    registrationInformation,
}) => {
    const [t] = useTranslation();
    const componentProps = {
        textFieldPropFactory,
        selectFieldPropFactory,
        manageFieldPropFactory,
        switchFieldPropFactory,
        registrationInformation,
    };

    const { data: { data: cities = [] } = {} } = useGetCitiesRequestQuery({ searchText: '' });
    const { data: { data: countries = [] } = {} } = useGetCountriesRequestQuery({ searchText: '' });
    const { data: { data: provinces = [] } = {} } = useGetProvincesRequestQuery({ searchText: '' });

    return (
        <>
            <FormHeader
                id={id}
                header={
                    <InnerFormHeader
                        divider={true}
                        title={t('RegistrationDetails.homeAddress')}
                        icon={<SvgAddress />}
                    />
                }
                label={t('RegistrationDetails.homeAddress.homeAddress')}
            >
                <HomeAddressDetails
                    id={'homeAddressSection'}
                    cities={cities}
                    provinces={provinces}
                    countries={countries}
                    {...componentProps}
                />
            </FormHeader>
            <FormHeader id={id} label={t('RegistrationDetails.homeAddress.parentsAddress')}>
                <ParentAddressDetails
                    id={'parentAddressSection'}
                    provinces={provinces}
                    countries={countries}
                    {...{ ...componentProps, registrationInformation: registrationInformation }}
                />
            </FormHeader>
            <FormHeader id={id} label={t('RegistrationDetails.homeAddress.childMailingAddress')}>
                <ChildMailingAddressDetails
                    id={'childAddressSection'}
                    cities={cities}
                    provinces={provinces}
                    countries={countries}
                    {...componentProps}
                />
            </FormHeader>
        </>
    );
};

export default HomeAddress;
