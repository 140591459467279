import { Flex, Text } from '@chakra-ui/react';

interface IProps {
    label: string;
    text: string;
}

export const TextField = ({ label, text }: IProps) => {
    return (
        <Flex p={'2px'}>
            <Text textStyle={'sm-normal'} my={'auto'} flexBasis={'30%'}>
                {label}
            </Text>
            <Text textStyle={'md-medium'} flexGrow={1}>
                {text}
            </Text>
        </Flex>
    );
};
