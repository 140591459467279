import { FC } from 'react';
import { Avatar, Box, Flex, Text } from '@chakra-ui/react';
import { MemberPhoto } from '@Redux/services/commonTypes';

interface IProps {
    name: string;
    icon?: JSX.Element;
    src?: MemberPhoto;
    subTitle?: string;
}
const AvatarLabel: FC<IProps> = ({ name, subTitle, icon, src }) => (
    <Flex>
        <Box mr={4}>
            <Avatar icon={icon} name={icon ? '' : name} src={src && `data:${src.mimeType};base64,${src.base64}`} />
        </Box>
        <Box>
            <Text
                textOverflow='ellipsis'
                maxWidth={{ base: '120px', lg: '210px' }}
                whiteSpace={icon ? 'normal' : 'nowrap'}
                overflow='hidden'
                minHeight='30px'
                textStyle={'md-bold'}
                wordBreak={'break-word'}
            >
                {name}
            </Text>
            <Text
                textOverflow='ellipsis'
                maxWidth={{ base: '120px', lg: '210px' }}
                whiteSpace='nowrap'
                overflow='hidden'
                color='text-secondary'
                textStyle={'sm-normal'}
            >
                {subTitle}
            </Text>
        </Box>
    </Flex>
);

export default AvatarLabel;
