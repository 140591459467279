import * as React from 'react';
function SvgAboutStudent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <mask
                id='about-student_svg__a'
                style={{
                    maskType: 'alpha',
                }}
                maskUnits='userSpaceOnUse'
                x={0}
                y={0}
                width={20}
                height={20}
            >
                <path fill='#D9D9D9' d='M0 0h20v20H0z' />
            </mask>
            <g mask='url(#about-student_svg__a)'>
                <path
                    d='M10.002 11.634c.49 0 .91-.176 1.259-.528.348-.351.522-.773.522-1.264 0-.49-.175-.91-.524-1.258a1.723 1.723 0 00-1.26-.521c-.492 0-.911.174-1.26.524-.348.35-.522.77-.522 1.26 0 .491.175.912.524 1.262.35.35.77.525 1.26.525zM6.55 15.116h6.9v-.595c0-.352-.099-.676-.296-.974a1.908 1.908 0 00-.795-.68 6.227 6.227 0 00-1.136-.358 5.855 5.855 0 00-1.225-.125c-.426 0-.834.041-1.224.125-.39.083-.77.203-1.137.359-.33.156-.593.383-.79.68a1.723 1.723 0 00-.297.973v.595zm8.307 3.387H5.143a1.908 1.908 0 01-1.4-.58 1.907 1.907 0 01-.58-1.4V3.478c0-.548.194-1.015.58-1.4a1.908 1.908 0 011.4-.58h5.79c.268 0 .525.052.77.156.247.104.461.249.645.435l3.897 3.898c.186.183.331.398.435.643.104.246.156.503.156.771v9.124c0 .547-.193 1.014-.579 1.4a1.907 1.907 0 01-1.4.579zm0-1.98V7.443l-3.965-3.966H5.143v13.048h9.714z'
                    fill='#171923'
                />
            </g>
        </svg>
    );
}
export default SvgAboutStudent;
