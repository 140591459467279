import { Box, Flex, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { FC, useMemo, useState } from 'react';
import { StepProps } from '@Pages/EventDetails/types';
import CostItemsTable from './components/CostItemsTable';
import { Pagination } from '@Components';
import { DEFAULT_PAGE_SIZE } from 'src/constants';
import { useGetEventCostItemResponsesMutation } from '@Redux/services/Event';
import { PieChart } from '@Components/Charts';
import { BarChart } from '@Components/Charts/components/BarChart';
import { SortingRule } from 'react-table';
import { CostItemResponse } from '@Redux/services/Event/types';
import { useToast } from '@Hooks/useToast';
import { parseError, parseWarning } from '@Utilities';
import { TimeoutId } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types';
import { ResponsesFilters } from '../TableFilters';

const CostItems: FC<StepProps> = ({ eventDetails, setEventDetails }) => {
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const toast = useToast();
    const [sortFields, setSortFields] = useState<SortingRule<CostItemResponse>[]>();
    const [filters, setFilters] = useState({
        grade: undefined,
        homeRoom: undefined,
        homeRoomTeacher: undefined,
        searchText: '',
        status: undefined,
    });
    const pieData = useMemo(
        () =>
            eventDetails.costItemResponses.costItemStatistics.sumByQuantity?.map((item) => ({
                name: item.measurement?.toString(),
                value: item.value,
            })),
        []
    );

    const barchartData = useMemo(
        () =>
            eventDetails.costItemResponses.costItemStatistics.sumAmountPaidPerCostItem?.map((item) => ({
                name: item.measurement?.toString(),
                value: item.value,
            })),
        [eventDetails]
    );

    const [getEventCostItemResponses, { isLoading }] = useGetEventCostItemResponsesMutation();
    const handlePageChange = async (
        page: number,
        newPageSize: number = pageSize,
        newFilters: ResponsesFilters = filters,
        sortBy: SortingRule<CostItemResponse>[] = sortFields
    ) => {
        setCurrentPage(page);

        await getEventCostItemResponses({
            eventId: eventDetails.eventId,
            currentPage: page,
            pageSize: newPageSize,
            sortBy: sortBy?.length ? sortBy[0].id : undefined,
            isDesc: sortBy?.length ? sortBy[0].desc : undefined,
            ...newFilters,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                setEventDetails({
                    ...eventDetails,
                    costItemResponses: {
                        costItemStatistics: eventDetails.costItemResponses.costItemStatistics,
                        costItems: response.data,
                        pagedResult: response.pagedResult,
                    },
                });
            })
            .catch((e) => parseError(toast, e));
    };

    const [debounce, setDebounce] = useState<TimeoutId>();
    const handleFilterChange = (key: string) => (value) => {
        setCurrentPage(1);
        const newFilters = { ...filters, [key]: value };
        setFilters(newFilters);
        clearTimeout(debounce);
        setDebounce(setTimeout(() => handlePageChange(1, pageSize, newFilters), 800));
    };

    const handleSortChange = (sortBy: SortingRule<CostItemResponse>[]) => {
        setCurrentPage(1);
        setSortFields(sortBy);
        handlePageChange(1, pageSize, filters, sortBy);
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPageSize(pageSize);
        setCurrentPage(1);
        handlePageChange(1, pageSize);
    };

    return (
        <>
            <Flex direction={{ base: 'column', md: 'row' }}>
                <Box p='7' pb='0' flexBasis={'50%'}>
                    <Text size='md' fontWeight='semibold'>
                        <Trans i18nKey={'CostItems.pieHeader'} />
                    </Text>
                    <Text size='sm'>
                        <Trans i18nKey={'CostItems.subHeader'} />
                    </Text>
                    <Box mt={4}>
                        <PieChart data={pieData} emptyMessage={t('CostItems.pieEmpty')} />
                    </Box>
                </Box>
                <Box p='7' flexBasis='50%'>
                    <Text size='md' fontWeight='semibold'>
                        <Trans i18nKey={'CostItems.barHeader'} />
                    </Text>
                    <Text size='sm'>
                        <Trans i18nKey={'CostItems.subHeader'} />
                    </Text>
                    <Box mt={4}>
                        <BarChart
                            labelDataKey='value'
                            barDataKey='value'
                            data={barchartData}
                            emptyMessage={t('CostItems.barEmpty')}
                            yAxisProps={{
                                type: 'number',
                                dataKey: 'value',
                                axisLine: false,
                            }}
                            xAxisProps={{
                                type: 'category',
                                dataKey: 'name',
                                width: 88,
                            }}
                        />
                    </Box>
                </Box>
            </Flex>

            <CostItemsTable
                handleFilterChange={handleFilterChange}
                filters={filters}
                sortBy={sortFields}
                isLoading={isLoading}
                eventDetails={eventDetails}
                onSortChange={handleSortChange}
            />
            <Box
                bg='surface-primary'
                borderTop='none'
                borderTopStyle='none'
                borderLeftWidth={2}
                borderRightWidth={2}
                borderBottomWidth={2}
                borderBottomRadius={5}
                borderStyle='solid'
                borderColor='border-primary'
            >
                <Pagination
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                    pageSize={pageSize}
                    onPageSizeChange={handlePageSizeChange}
                    totalPages={eventDetails.costItemResponses.pagedResult?.pageCount}
                    totalResults={eventDetails.costItemResponses.pagedResult?.rowCount}
                    viewMode={'TextButton'}
                />
            </Box>
        </>
    );
};

export default CostItems;
