import { Box, Flex, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import Header from '../../../../../Summary/components/Header';
import React, { useMemo } from 'react';
import SvgResponses from '@Icon/Responses';
import { useGetEventResponseStatusCodesQuery, useGetPaymentMethodsQuery } from '@Redux/services/LookupApi';
import { createLookupMap } from '@Utilities';
import { BasicLookup } from '@Redux/services/LookupApi/types';
import { PieChart } from '@Components/Charts';
import { BarChart } from '@Components/Charts/components/BarChart';
import { StepProps } from '@Pages/EventDetails/types';

type ResponsesProps = {
    showHeader?: boolean;
} & StepProps;

const Responses = React.forwardRef<HTMLDivElement, ResponsesProps>(({ id, eventDetails, showHeader = true }, ref) => {
    const { data: { data: responseStatus = [] } = {} } = useGetEventResponseStatusCodesQuery({ searchText: '' });
    const { data: { data: paymentMethods = [] } = {} } = useGetPaymentMethodsQuery();
    const responseStatusMap = useMemo(() => createLookupMap<BasicLookup>(responseStatus), [responseStatus]);
    const paymentMethodsMap = useMemo(() => createLookupMap<BasicLookup>(paymentMethods), [paymentMethods]);

    const { t } = useTranslation();
    const pieData = useMemo(() => {
        return responseStatusMap
            ? eventDetails.eventResponses.eventStatistics?.countByStatus?.map((stat) => ({
                  name: responseStatusMap[stat.measurement]?.value,
                  value: stat.value,
              }))
            : [];
    }, [eventDetails, responseStatusMap]);

    const barchartData = useMemo(
        () =>
            paymentMethodsMap
                ? eventDetails.eventResponses.eventStatistics.sumAmountPaidByPaymentMethod?.map((item) => ({
                      name:
                          item.measurement === 0
                              ? t('EventParticipantsPage.unpaidChartLabel')
                              : paymentMethodsMap[item.measurement]?.value,
                      value: item.value,
                  }))
                : [],
        [eventDetails, paymentMethodsMap]
    );

    return (
        <Box id={id} ref={ref}>
            {showHeader && <Header divider={true} mb={4} title={t('Header.responses')} icon={<SvgResponses />} />}
            <Flex direction={{ base: 'column', md: 'row' }}>
                <Box p='7' flexBasis='50%'>
                    <Text size='md' fontWeight='semibold'>
                        <Trans i18nKey={'Responses.barHeader'} />
                    </Text>
                    <Text size='sm'>
                        <Trans i18nKey={'Responses.subHeader'} />
                    </Text>
                    <Box mt={4}>
                        <BarChart
                            labelDataKey='value'
                            barDataKey='value'
                            xAxisProps={{
                                type: 'number',
                                dataKey: 'value',
                                axisLine: false,
                                tickFormatter: (value) => `$${value}`,
                            }}
                            yAxisProps={{
                                type: 'category',
                                dataKey: 'name',
                                width: 88,
                                axisLine: false,
                            }}
                            data={barchartData}
                            emptyMessage={t('Responses.pieEmpty')}
                            layout='vertical'
                        />
                    </Box>
                </Box>
                <Box p='7' flexBasis={'50%'}>
                    <Text size='md' fontWeight='semibold'>
                        <Trans i18nKey={'Responses.pieHeader'} />
                    </Text>
                    <Text size='sm'>
                        <Trans i18nKey={'Responses.subHeader'} />
                    </Text>
                    <Box mt={4}>
                        <PieChart data={pieData} emptyMessage={t('Responses.barEmpty')} />
                    </Box>
                </Box>
            </Flex>
        </Box>
    );
});

export default Responses;
