import * as React from 'react';
function SvgPencil(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M19.727 3.171a1.828 1.828 0 00-1.293.536L5.12 17.019l-.97 3.556 3.556-.97L21.02 6.293a1.828 1.828 0 00-1.293-3.122zm-1.465-1.708a3.828 3.828 0 014.172 6.244l-13.5 13.5a1 1 0 01-.444.258l-5.5 1.5a1 1 0 01-1.228-1.228l1.5-5.5a1 1 0 01.257-.444l13.5-13.5a3.828 3.828 0 011.243-.83z'
                fill='currentColor'
            />
        </svg>
    );
}
export default SvgPencil;
