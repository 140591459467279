import { Box, Stack, Text } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface IProps {
    header?: React.ReactNode;
    description: string | ReactNode;
}
const ListItem: FC<IProps> = ({ header, description }) => {
    return (
        <Box bg='bg.surface' boxShadow='sm'>
            <Box px='4' py={{ base: '4', md: '3.5' }}>
                <Stack
                    spacing='4'
                    justify={{ base: 'start', md: 'space-between' }}
                    direction={{ base: 'column', md: 'row' }}
                >
                    {header}
                </Stack>

                <Text textStyle={'sm-normal'}>{description}</Text>
            </Box>
        </Box>
    );
};

export default ListItem;
