import Table from '@Components/Table';
import TableActions from '@Components/TableActions';
import CurrencyCell from '@Components/CurrencyCell';
import { useToast } from '@Hooks/useToast';
import PaymentMethodCell from '@Pages/EventDetails/components/PaymentMethodCell';
import { useExportCostItemResponsesMutation } from '@Redux/services/Event';
import { CostItemResponse, Event } from '@Redux/services/Event/types';
import { downloadFile, parseError, parseWarning } from '@Utilities';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, SortingRule } from 'react-table';
import { EXCEL_FILE_CONTENT_TYPE } from 'src/constants';
import type { PartialDeep } from 'type-fest';
import { TableFilters } from '../../../TableFilters';

interface IProps {
    eventDetails: PartialDeep<Event>;
    isLoading: boolean;
    sortBy?: SortingRule<CostItemResponse>[];
    onSortChange: (sortBy: SortingRule<CostItemResponse>[]) => void;
    handleFilterChange: (key: string) => (value: any) => void;
    filters: any;
}

const CostItemsTable: FC<IProps> = ({ eventDetails, sortBy, isLoading, onSortChange, handleFilterChange, filters }) => {
    const [exportCostItemResponses] = useExportCostItemResponsesMutation();
    const { t } = useTranslation();
    const toast = useToast();
    const columns = useMemo<Column[]>(
        () => [
            {
                Header: t('CostItemsTable.fullName'),
                accessor: 'fullName',
                headerAlign: 'left',
            },
            {
                Header: t('CostItemsTable.homeRoom'),
                accessor: 'homeRoomStudent',
                headerAlign: 'left',
            },
            {
                Header: t('CostItemsTable.homeRoomTeacher'),
                accessor: 'homeRoomTeacher',
                headerAlign: 'left',
            },
            {
                Header: t('CostItemsTable.grade'),
                accessor: 'gradeDescription',
                headerAlign: 'left',
            },
            {
                Header: t('CostItemsTable.costItem'),
                accessor: 'name',
                headerAlign: 'left',
            },
            {
                Header: t('CostItemsTable.methodOfPayment'),
                accessor: 'methodOfPayment',
                Cell: PaymentMethodCell,
                headerAlign: 'left',
            },
            {
                Header: t('CostItemsTable.quantity'),
                accessor: 'quantity',
                headerAlign: 'right',
            },
            {
                Header: t('CostItemsTable.unitCostLabel'),
                accessor: 'unitCost',
                Cell: CurrencyCell,
                headerAlign: 'right',
            },
            {
                Header: t('CostItemsTable.subsidyAmount'),
                accessor: 'subsidyAmount',
                Cell: CurrencyCell,
                headerAlign: 'right',
            },
            {
                Header: t('CostItemsTable.totalAmount'),
                accessor: 'totalCost',
                Cell: CurrencyCell,
                headerAlign: 'right',
            },
            {
                Header: t('CostItemsTable.selectedOptions'),
                accessor: 'selectedOptions',
                headerAlign: 'left',
            },
        ],
        []
    );

    const handleExport = async () => {
        await exportCostItemResponses({
            eventId: eventDetails.eventId,
            sortBy: sortBy?.length ? sortBy[0].id : undefined,
            isDesc: sortBy?.length ? sortBy[0].desc : undefined,
            ...filters,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                downloadFile(EXCEL_FILE_CONTENT_TYPE, response.data.fileContents, response.data.fileName);
            })
            .catch((err) => parseError(toast, err));
    };

    return (
        <Table
            action={
                <TableActions
                    onExportClick={handleExport}
                    onSearch={handleFilterChange('searchText')}
                    searchText={filters.searchText}
                />
            }
            manualSortBy={true}
            isLoading={isLoading}
            filter={<TableFilters onChange={handleFilterChange} filters={filters} />}
            columns={columns}
            rawData={eventDetails?.costItemResponses.costItems || []}
            emptyMessage={t('CostItemsTable.emptyTable')}
            onSortChange={onSortChange}
        />
    );
};

export default CostItemsTable;
