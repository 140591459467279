import { useToast as chakraUseToast } from '@chakra-ui/toast';

const useToast = () => {
    const toast = chakraUseToast({
        position: 'top-right',
        isClosable: true,
        duration: 15000,
    });

    return toast;
};

export { useToast };
