import { Box, VStack, Text } from '@chakra-ui/react';
import Textarea from '@Components/Textarea';
import { DrawerFooterActions } from '@Components/DrawerFooterActions';
import Modal from '@Components/Modal';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (statusComment: string) => void;
    status: string;
}

const StatusChangesDialog: FC<IProps> = ({ isOpen, onClose, status, onSave }) => {
    const { t } = useTranslation();
    const [statusComment, setStatusComment] = useState('');
    const [statusCommentError, setStatusCommentError] = useState('');

    return (
        <Modal
            title=''
            isOpen={isOpen}
            onClose={onClose}
            footer={
                <DrawerFooterActions
                    isEditable={true}
                    closeButtonProps={{
                        label: t('LinkingRequestDetails.statusChangeDialog.noCancelButtonLabel'),
                        onClick: onClose,
                    }}
                    saveButtonProps={{
                        onClick: () => {
                            if (!statusComment) {
                                setStatusCommentError(t('RegistrationDetails.statusChangeDialog.pleaseComplete'));
                                return;
                            }
                            onSave(statusComment);
                            onClose();
                        },
                        label: t('LinkingRequestDetails.statusChangeDialog.yesButtonLabel'),
                    }}
                />
            }
        >
            <Box>
                <VStack gap={'16px'} mt={'8px'}>
                    <Text id='areyousurequestion' textStyle='md-bold'>
                        {t('LinkingRequestDetails.statusChangeDialog.areYouSureQuestion') + status}
                    </Text>
                    <Text textStyle='md-normal'>{t('LinkingRequestDetails.statusChangeDialog.instructions')}</Text>
                    <Text textStyle='md-bold' textAlign='left'>
                        {t('LinkingRequestDetails.statusChangeDialog.commentToParent')}
                    </Text>
                    <Textarea
                        label=''
                        isLocked={false}
                        placeholder={t('LinkingRequestDetails.statusChangeDialog.enterComment')}
                        onChange={(e) => {
                            setStatusComment(e.target.value);
                            setStatusCommentError('');
                        }}
                        value={statusComment}
                        error={statusCommentError}
                        isInvalid={Boolean(statusCommentError)}
                    />
                </VStack>
            </Box>
        </Modal>
    );
};

export default StatusChangesDialog;
