import { Address, EmergencyContactInformation } from '@Redux/services/Registration/types';
import { RegistrationStatus } from 'src/constants';

export const getNextStatus = (status: number) => {
    switch (status) {
        case RegistrationStatus.Submitted:
            return RegistrationStatus.DocumentsRequired;
        case RegistrationStatus.DocumentsRequired:
            return RegistrationStatus.Approved;
        default:
            return RegistrationStatus.Approved;
    }
};

export function isAnyAddressPropertySet(addressInfo: Address): boolean {
    return Object.keys(addressInfo).some((key) => addressInfo[key as keyof Address] !== null);
}

export function populateEmergencyContacts(
    emergencyContactInfo: EmergencyContactInformation
): EmergencyContactInformation {
    const { emergencyContacts } = emergencyContactInfo;
    if (emergencyContacts && emergencyContacts.length > 0) {
        const [contact1, contact2, contact3] = emergencyContacts;
        return {
            ...emergencyContactInfo,
            emergencyContact1: contact1,
            emergencyContact2: contact2,
            emergencyContact3: contact3,
        };
    }
    return {
        ...emergencyContactInfo,
        emergencyContact1: null,
        emergencyContact2: null,
        emergencyContact3: null,
    };
}

export function populateEmergencyContactsArray(
    emergencyContactInfo: EmergencyContactInformation
): EmergencyContactInformation {
    const { emergencyContact1, emergencyContact2, emergencyContact3 } = emergencyContactInfo;
    return {
        ...emergencyContactInfo,
        emergencyContacts: [emergencyContact1, emergencyContact2, emergencyContact3].filter((contact) => contact),
    };
}
