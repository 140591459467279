import {
    Input as ChakraInput,
    BackgroundProps,
    Box,
    InputProps,
    CloseButton,
    Text,
    InputRightElement,
    InputGroup,
} from '@chakra-ui/react';
import { FC, useState } from 'react';

interface FilterTextInputProps extends InputProps {
    isLocked: boolean;
    leftIcon?: React.ReactNode;
    onChangeText?: (value: string) => void;
    bg?: BackgroundProps['bg'];
    containerWidth?: string | number;
}

const FilterTextInput: FC<FilterTextInputProps> = ({
    isLocked,
    leftIcon,
    onChangeText,
    bg = 'transparent',
    value,
    containerWidth = 200,
    ...rest
}) => {
    const [edittable, setEdittable] = useState(true);

    const handleBlur = () => {
        if (value) {
            setEdittable(false);
        }
    };
    const handleClose = () => {
        onChangeText('');
        setEdittable(true);
    };

    return edittable ? (
        <Box bg={bg} w={containerWidth}>
            <ChakraInput
                textStyle={'md-normal'}
                onChange={(event) => onChangeText && onChangeText(event.target.value)}
                disabled={isLocked || !edittable}
                _disabled={{
                    color: 'text-disabled',
                }}
                sx={
                    leftIcon && {
                        pl: 5,
                    }
                }
                value={value ?? ''}
                _focusVisible={{ borderColor: 'surface-brand-primary' }}
                onBlur={handleBlur}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.currentTarget.blur();
                    }
                }}
                autoComplete='123'
                {...rest}
            />
        </Box>
    ) : (
        <InputGroup
            bg={bg}
            w={containerWidth}
            display='flex'
            border={1}
            borderColor='border-primary'
            borderStyle='solid'
            flexDirection='row'
            alignItems='center'
            pl={4}
            h={10}
            gap={2}
        >
            <Box>
                <Text textStyle='md-normal'>{value}</Text>
            </Box>
            <InputRightElement>
                <CloseButton onClick={handleClose} mr={2} />
            </InputRightElement>
        </InputGroup>
    );
};

export default FilterTextInput;
