import { Event } from '@Redux/services/Event/types';
import { ValidatorType } from '@Utilities';
import { TFunction } from 'react-i18next';
import { isBoolean, isNull } from 'lodash';
import {
    ACTIVITY_TYPE_FIELD_TRIP,
    ACTIVITY_TYPE_ONGOING_CONSENT,
    EventStatus,
    TARGET_TYPE_CLASS,
    TARGET_TYPE_CUSTOM_LIST,
} from 'src/constants';
import type { PartialDeep } from 'type-fest';
import { editableFieldsByStatus } from './types';

export function Validator(eventDetails: PartialDeep<Event>, t: TFunction, autoSave: boolean): ValidatorType[] {
    return [
        {
            accessorPath: ['name'],
            validator: Boolean,
            message: t('EventDetails.nameRequired'),
            ...editableFieldsByStatus.name,
        },
        {
            accessorPath: ['teamId'],
            validator: Boolean,
            message: t('EventDetails.teamIdRequired'),
            ...editableFieldsByStatus.teamId,
        },
        {
            accessorPath: ['organizedById'],
            validator: Boolean,
            message: t('EventDetails.organizedByIdRequired'),
            ...editableFieldsByStatus.organizedById,
        },
        {
            accessorPath: ['organizingTeachers'],
            validator: () => true,
            message: '',
            ...editableFieldsByStatus.organizingTeachers,
        },
        {
            accessorPath: ['volunteers'],
            validator: () => true,
            message: '',
            ...editableFieldsByStatus.volunteers,
        },
        {
            accessorPath: ['readyToPublish'],
            validator: (readyToPublish: boolean) =>
                autoSave
                    ? autoSave
                    : isBoolean(readyToPublish) &&
                      readyToPublish === true &&
                      eventDetails.status === EventStatus.Validated,
            message: t('EventDetails.readyToPublishRequired'),
            ...editableFieldsByStatus.readyToPublish,
        },
        {
            accessorPath: ['readyToClose'],
            validator: (readyToClose: boolean) =>
                autoSave
                    ? autoSave
                    : isBoolean(readyToClose) && readyToClose === true && eventDetails.status === EventStatus.Published,
            message: t('EventDetails.readyToCloseRequired'),
            ...editableFieldsByStatus.readyToClose,
        },
        {
            accessorPath: ['offerDescription'],
            validator: () => true,
            message: t('EventDetails.descriptionRequired'),
            ...editableFieldsByStatus.offerDescription,
        },
        {
            accessorPath: ['activityTypeId'],
            validator: Boolean,
            message: t('EventDetails.activityTypeId'),
            ...editableFieldsByStatus.activityTypeId,
        },
        {
            accessorPath: ['activity', 'activityNameId'],
            validator: (value) => (autoSave ? true : Boolean(value)),
            message: t('EventDetails.activityNameRequired'),
            ...editableFieldsByStatus['activity.activityNameId'],
            required:
                editableFieldsByStatus['activity.activityNameId'].required &&
                eventDetails.activityTypeId === ACTIVITY_TYPE_FIELD_TRIP &&
                eventDetails.status === EventStatus.Validated,
        },
        {
            accessorPath: ['activity', 'modeOfTransportId'],
            validator: (value) => (autoSave ? true : Boolean(value)),
            message: t('EventDetails.modeOfTransportRequired'),
            ...editableFieldsByStatus['activity.modeOfTransportId'],
            required:
                editableFieldsByStatus['activity.modeOfTransportId'].required &&
                eventDetails.activityTypeId === ACTIVITY_TYPE_FIELD_TRIP &&
                eventDetails.status === EventStatus.Validated,
        },
        {
            accessorPath: ['activity', 'location'],
            validator: (value) => (autoSave ? true : Boolean(value)),
            message: t('EventDetails.locationRequired'),
            ...editableFieldsByStatus['activity.location'],
            required:
                editableFieldsByStatus['activity.location'].required &&
                eventDetails.activityTypeId === ACTIVITY_TYPE_FIELD_TRIP &&
                eventDetails.status === EventStatus.Proposed,
        },
        {
            accessorPath: ['activity', 'teacherInCharge'],
            validator: (value) => (autoSave ? true : Boolean(value)),
            message: t('EventDetails.teacherInChargeRequired'),
            ...editableFieldsByStatus['activity.teacherInCharge'],
            required:
                editableFieldsByStatus['activity.teacherInCharge'].required &&
                eventDetails.activityTypeId === ACTIVITY_TYPE_FIELD_TRIP &&
                eventDetails.status === EventStatus.Proposed,
        },
        {
            accessorPath: ['activity', 'emergencyContact'],
            validator: () => true,
            message: '',
            ...editableFieldsByStatus['activity.emergencyContact'],
            required:
                editableFieldsByStatus['activity.emergencyContact'].required &&
                eventDetails.activityTypeId === ACTIVITY_TYPE_FIELD_TRIP,
        },
        {
            accessorPath: ['activity', 'fieldTripType'],
            validator: () => true,
            message: '',
            ...editableFieldsByStatus['activity.fieldTripType'],
            required:
                editableFieldsByStatus['activity.fieldTripType'].required &&
                eventDetails.activityTypeId === ACTIVITY_TYPE_FIELD_TRIP,
        },
        {
            accessorPath: ['activity', 'isApproved'],
            validator: (value) =>
                autoSave
                    ? autoSave
                    : isBoolean(value) && value === true && eventDetails.status === EventStatus.Validated,
            message: t('EventDetails.isapprovalcheckedRequired'),
            ...editableFieldsByStatus['activity.isApproved'],
            required:
                editableFieldsByStatus['activity.isApproved'].required &&
                eventDetails.activityTypeId === ACTIVITY_TYPE_FIELD_TRIP &&
                eventDetails.status === EventStatus.Validated,
        },
        {
            accessorPath: ['activity', 'approvedOnDate'],
            validator: (value) =>
                autoSave ? autoSave : Boolean(value) && eventDetails.status === EventStatus.Validated,
            message: t('EventDetails.approvedonRequired'),
            ...editableFieldsByStatus['activity.approvedOnDate'],
            required:
                editableFieldsByStatus['activity.approvedOnDate'].required &&
                eventDetails.activityTypeId === ACTIVITY_TYPE_FIELD_TRIP &&
                eventDetails.status === EventStatus.Validated,
        },
        {
            accessorPath: ['followUpDate'],
            validator: (followUpDate: Date) => {
                if (autoSave && (!eventDetails?.followUpDate || !eventDetails?.dueDate)) return true;
                if (!autoSave && !followUpDate) return true;
                return new Date(eventDetails?.dueDate) > new Date(followUpDate);
            },
            message: t('EventDetails.followUpDateMessage'),
            ...editableFieldsByStatus.followUpDate,
            editableStatus:
                eventDetails.activityTypeId === ACTIVITY_TYPE_ONGOING_CONSENT
                    ? []
                    : editableFieldsByStatus.followUpDate.editableStatus,
        },
        {
            dependantPath: ['followUpDate'],
            accessorPath: ['dueDate'],
            validator: () => {
                if (autoSave && (!eventDetails?.followUpDate || !eventDetails?.dueDate)) return true;
                if (!autoSave && !eventDetails?.followUpDate) return true;
                return new Date(eventDetails?.dueDate) > new Date(eventDetails?.followUpDate);
            },
            message: t('EventDetails.followUpDateMessage'),
            ...editableFieldsByStatus.followUpDate,
            editableStatus:
                eventDetails.activityTypeId === ACTIVITY_TYPE_ONGOING_CONSENT
                    ? []
                    : editableFieldsByStatus.followUpDate.editableStatus,
        },
        {
            accessorPath: ['dueDate'],
            validator: (dueDate: Date) => {
                if (autoSave && (!eventDetails?.dueDate || !eventDetails?.startDate)) return true;
                if (!autoSave && !dueDate) return false;
                return new Date(eventDetails?.startDate) >= new Date(dueDate);
            },
            message: t('EventDetails.dueDateMessage'),
            ...editableFieldsByStatus.dueDate,
            required:
                editableFieldsByStatus.dueDate.required &&
                (eventDetails.status === EventStatus.Proposed || eventDetails.status === EventStatus.Validated),
            editableStatus:
                eventDetails.activityTypeId === ACTIVITY_TYPE_ONGOING_CONSENT
                    ? []
                    : editableFieldsByStatus.dueDate.editableStatus,
        },
        {
            dependantPath: ['dueDate'],
            accessorPath: ['startDate'],
            validator: () => {
                if (autoSave && (!eventDetails?.dueDate || !eventDetails?.startDate)) return true;
                if (!autoSave && !eventDetails?.dueDate) return false;
                return new Date(eventDetails?.startDate) >= new Date(eventDetails?.dueDate);
            },
            message: t('EventDetails.dueDateMessage'),
            ...editableFieldsByStatus.dueDate,
            required:
                editableFieldsByStatus.dueDate.required &&
                (eventDetails.status === EventStatus.Proposed || eventDetails.status === EventStatus.Validated),
            editableStatus:
                eventDetails.activityTypeId === ACTIVITY_TYPE_ONGOING_CONSENT
                    ? []
                    : editableFieldsByStatus.dueDate.editableStatus,
        },
        {
            accessorPath: ['startDate'],
            validator: () => {
                if (autoSave && isNull(eventDetails?.startDate)) return true;
                return Boolean(eventDetails?.startDate);
            },
            message: t('EventDetails.startDateMessage'),
            ...editableFieldsByStatus.startDate,
            required:
                editableFieldsByStatus.startDate.required &&
                (eventDetails.status === EventStatus.Proposed || eventDetails.status === EventStatus.Validated),
            editableStatus:
                eventDetails.activityTypeId === ACTIVITY_TYPE_ONGOING_CONSENT
                    ? []
                    : editableFieldsByStatus.startDate.editableStatus,
        },
        {
            dependantPath: ['startDate'],
            accessorPath: ['endDate'],
            validator: () => {
                if (!autoSave && (!eventDetails?.startDate || !eventDetails?.endDate)) return true;
                if (autoSave && isNull(eventDetails?.startDate) && isNull(eventDetails?.endDate)) return true;
                return new Date(eventDetails?.endDate) >= new Date(eventDetails?.startDate);
            },
            message: t('EventDetails.startDateMessage'),
            ...editableFieldsByStatus.startDate,
            required:
                editableFieldsByStatus.startDate.required &&
                (eventDetails.status === EventStatus.Proposed || eventDetails.status === EventStatus.Validated),
            editableStatus:
                eventDetails.activityTypeId === ACTIVITY_TYPE_ONGOING_CONSENT
                    ? []
                    : editableFieldsByStatus.startDate.editableStatus,
        },
        {
            accessorPath: ['endDate'],
            validator: () => {
                if (autoSave && isNull(eventDetails?.endDate)) return true;
                return Boolean(eventDetails?.endDate);
            },
            message: t('EventDetails.endDateMessage'),
            ...editableFieldsByStatus.endDate,
            required:
                editableFieldsByStatus.endDate.required &&
                (eventDetails.status === EventStatus.Proposed || eventDetails.status === EventStatus.Validated),
            editableStatus:
                eventDetails.activityTypeId === ACTIVITY_TYPE_ONGOING_CONSENT
                    ? []
                    : editableFieldsByStatus.endDate.editableStatus,
        },
        {
            accessorPath: ['formsDistributedOnDate'],
            validator: () => {
                if (autoSave && isNull(eventDetails?.formsDistributedOnDate)) return true;
                return Boolean(eventDetails?.formsDistributedOnDate);
            },
            message: '',
            ...editableFieldsByStatus.formsDistributedOnDate,
        },
        {
            accessorPath: ['participants', 'targetType'],
            validator: Boolean,
            message: t('EventDetails.participantsTargetTypeRequired'),
            ...editableFieldsByStatus['participants.targetType'],
        },
        {
            dependantPath: ['participants', 'schoolValidation'],
            accessorPath: ['participants', 'listId'],
            validator: () => true,
            message: t('EventDetails.participantsPreviewId'),
            ...editableFieldsByStatus['participants.listId'],
        },
        {
            accessorPath: ['participants', 'schoolValidation'],
            validator: (schoolValidation: boolean) => {
                if (eventDetails?.participants?.targetType === TARGET_TYPE_CUSTOM_LIST) {
                    return isBoolean(schoolValidation);
                }
                return true;
            },
            message: t('EventDetails.participantsSchoolValidationRequired'),
            ...editableFieldsByStatus['participants.schoolValidation'],
        },
        {
            accessorPath: ['participants', 'invitedGrade'],
            validator: (invitedGrade: string) => {
                if (eventDetails?.participants?.targetType === ACTIVITY_TYPE_FIELD_TRIP) {
                    return Boolean(invitedGrade);
                }
                return true;
            },
            message: t('EventDetails.participantsInitialGradeRequired'),
            ...editableFieldsByStatus['participants.invitedGrade'],
        },
        {
            accessorPath: ['participants', 'invitedClass'],
            validator: (invitedClass: string) => {
                if (eventDetails?.participants?.targetType === TARGET_TYPE_CLASS) {
                    return Boolean(invitedClass);
                }
                return true;
            },
            message: t('EventDetails.participantsInvitedClassroomRequired'),
            ...editableFieldsByStatus['participants.invitedClass'],
        },
        {
            accessorPath: ['categoryId'],
            validator: Boolean,
            message: t('EventDetails.categoryIdRequired'),
            ...editableFieldsByStatus.categoryId,
        },
        {
            accessorPath: ['subCategoryId'],
            validator: Boolean,
            message: t('EventDetails.subCategoryIdRequired'),
            ...editableFieldsByStatus.subCategoryId,
        },
        {
            accessorPath: ['isRefundable'],
            validator: isBoolean,
            message: '',
            ...editableFieldsByStatus['isRefundable'],
        },
    ];
}
