import { useEffect, useMemo, useState } from 'react';
import {
    Box,
    Flex,
    Avatar,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    useDisclosure,
    useColorModeValue,
    Divider,
    MenuList,
    MenuItem,
    Button,
    Badge,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';
import { TFunction } from 'react-i18next';
import { SubHeader } from './components/SubHeader';
import { store } from '@Redux';
import { logout } from '@Redux/slices/auth';
import { useMsal } from '@azure/msal-react';
import SvgSchoolTodoLogo from '@Icon/SchoolTodoLogo';
import { checkIfValidUUID } from '@Utilities';
import { useGetPhoneCallsMutation } from '@Redux/services/Absence';
import { useGetRegistrationsMutation } from '@Redux/services/Registration';
import { PagedResult } from '@Redux/services/commonTypes';
import { FIVE_MINUTES_MS, RegistrationStatus, UpdateStatus } from 'src/constants';
import { phoneCallStatusCodeEnum } from 'src/constants';
import SvgHelp from '@Components/Icons/Help';
import { useGetUpdatesMutation } from '@Redux/services/Update';
import { useGetFeaturesQuery } from '@Redux/services/LookupApi';

export interface Navigation {
    label: string;
    href: string;
    subPath?: string;
    disabled?: boolean;
    badgeCount?: number;
}
export interface NavigationItem extends Navigation {
    subNavigations: Navigation[];
}

export const getNavigations = (
    t: TFunction,
    pendingPhoneCallsCount: number,
    registrationsCount: number,
    submitedUpdatesCount: number
) => {
    const { data: { data: enableFeatures } = {} } = useGetFeaturesQuery();
    let navigation = [
        {
            label: t('Header.absences'),
            href: 'absences',
            badgeCount: pendingPhoneCallsCount,
            disabled: false,
            subNavigations: [
                { label: t('SubHeader.phoneCall'), subPath: 'phone-call', href: 'phone-call', disabled: false },
                {
                    label: t('SubHeader.lateAbsenceReports'),
                    subPath: 'late-absences',
                    href: 'late-absences',
                    disabled: false,
                },
            ],
        },
        {
            label: t('Header.registrations'),
            href: 'registrations',
            disabled: false,
            badgeCount: registrationsCount,
            subNavigations: [],
        },
        {
            label: t('Header.updates'),
            href: 'updates',
            disabled: false,
            badgeCount: submitedUpdatesCount,
            subNavigations: [],
        },
        {
            label: t('Header.events'),
            href: 'events',
            subNavigations: [
                { label: t('SubHeader.planning'), subPath: 'planning', href: 'planning/published', disabled: false },
                { label: t('SubHeader.eventResponses'), href: 'event-responses', disabled: true },
                { label: t('SubHeader.eventPayments'), href: 'event-payments', disabled: true },
                {
                    label: t('SubHeader.eventReference'),
                    href: 'event-reference-tables',
                    disabled: true,
                },
                { label: t('SubHeader.eventLogs'), href: 'event-logs', disabled: true },
            ],
        },
        {
            label: t('Header.records'),
            href: 'records',
            subNavigations: [
                { label: t('SubHeader.students'), href: 'students', subPath: 'students', disabled: false },
                { label: t('SubHeader.schools'), href: 'schools', disabled: true },
                { label: t('SubHeader.guardians'), href: 'guardians', disabled: true },
                { label: t('SubHeader.reports'), href: 'reports-cards', subPath: 'reports', disabled: false },
                //{ label: t('SubHeader.system'), href: 'system', disabled: true },
            ],
        },
    ];

    navigation = navigation.map((x) => {
        x.disabled = enableFeatures ? enableFeatures[x.href] : false;
        x.subNavigations.map((y) => {
            y.disabled = enableFeatures ? enableFeatures[`${x.href}.${y.href}`] : false;
        });
        return x;
    });

    return navigation;
};

export const Header = () => {
    const { instance, accounts } = useMsal();
    //TODO: Chip: Fix badge and count for absences and registrations
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { t } = useTranslation();

    const location = useLocation();

    const [getPendingPhoneCalls, { data: { pagedResult = {} as PagedResult } = {} }] = useGetPhoneCallsMutation({
        fixedCacheKey: 'NavBarCount',
    });

    const [getUpdatesMutation, { data: { pagedResult: submittedRegistrationsPagedResult = {} as PagedResult } = {} }] =
        useGetUpdatesMutation();

    const [getRegistrationsCount, { data: { pagedResult: regPagedResult = {} as PagedResult } = {} }] =
        useGetRegistrationsMutation();
    const { data: { data: enableFeatures } = {}, refetch: forceFetchFeatures } = useGetFeaturesQuery();
    const [activeState, setActiveState] = useState<NavigationItem>();
    const navigate = useNavigate();
    const navigations = getNavigations(
        t,
        pagedResult?.rowCount,
        regPagedResult?.rowCount,
        submittedRegistrationsPagedResult?.rowCount
    );
    const parentNavBaseUrl = useMemo(() => location.pathname.split('/')[1], [location.pathname]);

    const subNavBaseUrl = useMemo(
        () =>
            location.pathname.split('/').length > 2 && !checkIfValidUUID(location.pathname.split('/')[3])
                ? location.pathname.split('/')[2]
                : '',
        [location.pathname]
    );

    const systemNavigation = {
        label: t('Header.system'),
        href: 'system',
        disabled: enableFeatures ? enableFeatures['system'] : true,
        subNavigations: [
            {
                label: t('SubHeader.configurations'),
                href: 'configurations/absence-reasons',
                subPath: 'configurations',
                disabled: enableFeatures ? enableFeatures['system.configurations.absence-reasons'] : true,
            },
        ],
    };

    const systemNavigationUrl = `${systemNavigation.href}${
        systemNavigation.subNavigations.length > 0 ? '/' + systemNavigation.subNavigations[0].href : ''
    }`;

    useEffect(() => {
        const activeNavs = [systemNavigation, ...navigations].find(
            (navigation) =>
                navigation.href === parentNavBaseUrl &&
                navigation.subNavigations.some(
                    (subNav) => subNav.href.split('/').length > 0 && subNav.href.split('/')[0] === subNavBaseUrl
                )
        );
        setActiveState(activeNavs);
        if (location.pathname === '/' && enableFeatures && !enableFeatures['absences']) {
            navigate('/absences/phone-call');
        } else if (location.pathname === '/') {
            navigate('/events/planning/published');
        }
    }, [location.pathname, parentNavBaseUrl, subNavBaseUrl, enableFeatures]);

    useEffect(() => {
        forceFetchFeatures();
        enableFeatures && !enableFeatures['absences'] && handleGetPhoneCalls();

        const getPendingCallsInterval =
            enableFeatures && !enableFeatures['absences'] && setInterval(() => handleGetPhoneCalls(), FIVE_MINUTES_MS);

        enableFeatures && !enableFeatures['updates'] && handleGetSubmittedUpdates();

        const getSubmittedUpdatesInterval =
            enableFeatures &&
            !enableFeatures['updates'] &&
            setInterval(() => handleGetSubmittedUpdates(), FIVE_MINUTES_MS);

        enableFeatures && !enableFeatures['registrations'] && handleGetRegistrationsCount();

        const getRegistrationsCountInterval =
            enableFeatures &&
            !enableFeatures['registrations'] &&
            setInterval(() => handleGetRegistrationsCount(), FIVE_MINUTES_MS);

        return () => {
            clearInterval(getPendingCallsInterval);
            clearInterval(getSubmittedUpdatesInterval);
            clearInterval(getRegistrationsCountInterval);
        };
    }, [enableFeatures]);

    const handleGetPhoneCalls = () => {
        getPendingPhoneCalls({
            sortBy: null,
            isDesc: false,
            searchText: null,
            currentPage: 1,
            pageSize: 1,
            statusCode: phoneCallStatusCodeEnum.Open,
        });
    };

    const handleGetSubmittedUpdates = () => {
        getUpdatesMutation({
            sortBy: 'createdOn',
            isDesc: true,
            searchText: '',
            currentPage: 1,
            pageSize: 1,
            statuses: [UpdateStatus.Submitted],
        });
    };

    const handleGetRegistrationsCount = () => {
        getRegistrationsCount({
            sortBy: 'createdOn',
            isDesc: true,
            searchText: null,
            currentPage: 1,
            pageSize: 1,
            statuses: [RegistrationStatus.Submitted, RegistrationStatus.DocumentsRequired],
        });
    };

    const handleLogout = () => {
        store.dispatch(logout());
        instance.logout({ account: accounts[0] }).catch((e) => {
            console.error(e);
        });
    };

    const handleHelp = () => {
        window.open('https://askuc.ucdsb.ca/SelfService/ViewKnownError.aspx?knownErrorId=1458', '_blank');
    };

    return (
        <>
            <Box as='header' top={0} zIndex={100} bg='surface-primary' position='fixed' w='100%'>
                <Box bg={useColorModeValue('surface-primary', 'surface-primary')} px={8} py='1'>
                    <Flex alignItems={'center'} justifyContent={'space-between'}>
                        <IconButton
                            size={'md'}
                            aria-label={'Open Menu'}
                            display={{ md: 'none' }}
                            onClick={isOpen ? onClose : onOpen}
                            icon={<HamburgerIcon />}
                        />
                        <HStack spacing={'24px'} alignItems={'center'}>
                            <Box padding='10px 0px 10px 0px'>
                                <SvgSchoolTodoLogo width='200px' height='40px' />
                            </Box>
                            <HStack as={'nav'} spacing={'24px'} display={{ base: 'none', md: 'flex' }}>
                                {navigations.map((navigation, i) => {
                                    const url = `${navigation.href}${
                                        navigation.subNavigations.length > 0
                                            ? '/' + navigation.subNavigations[0].href
                                            : ''
                                    }`;
                                    return (
                                        <Link to={url}>
                                            <Flex>
                                                <Button
                                                    variant={'ghost'}
                                                    key={i}
                                                    id={navigation.href}
                                                    isActive={parentNavBaseUrl === navigation.href}
                                                    isDisabled={navigation.disabled}
                                                >
                                                    {navigation.label}
                                                    {navigation.badgeCount > 0 && (
                                                        <Badge
                                                            ml={'6px'}
                                                            variant={'error'}
                                                            maxHeight={'fit-content'}
                                                            height={'fit-content'}
                                                            my={'auto'}
                                                        >
                                                            {navigation.badgeCount}
                                                        </Badge>
                                                    )}
                                                </Button>
                                            </Flex>
                                        </Link>
                                    );
                                })}
                            </HStack>
                        </HStack>
                        <Flex alignItems={'center'} gap={3}>
                            {/*
                            TODO: For Later Release
                            <Print width='24px' height='24px' />
                            <Settings width='24px' height='24px' />
                            <Help width='24px' height='24px' /> */}
                            <Link to={systemNavigationUrl}>
                                <Button
                                    variant={'ghost'}
                                    id={systemNavigation.href}
                                    isActive={parentNavBaseUrl === systemNavigation.href}
                                    isDisabled={systemNavigation.disabled}
                                >
                                    {systemNavigation.label}
                                </Button>
                            </Link>
                            <IconButton
                                onClick={handleHelp}
                                aria-label={t('HelpIcon.ariaLabel')}
                                icon={<SvgHelp />}
                                bgColor={'surface-primary'}
                                title={t('HelpIcon.title')}
                            />
                            <Box position={'relative'}>
                                <Menu>
                                    <MenuButton as={Button} rounded={'full'} variant={'link'} cursor={'pointer'}>
                                        <Avatar size={'sm'} name={accounts[0].name} />
                                    </MenuButton>
                                    <MenuList
                                        sx={{
                                            position: 'absolute',
                                            top: 10,
                                            right: -10,
                                        }}
                                    >
                                        <MenuItem isDisabled>{t('Header.profile')}</MenuItem>
                                        <MenuItem onClick={handleLogout}>{t('Header.signOut')}</MenuItem>
                                    </MenuList>
                                </Menu>
                            </Box>
                        </Flex>
                    </Flex>

                    {/* {isOpen ? (
                        <Box pb={4} display={{ md: 'none' }}>
                            <Stack as={'nav'} spacing={4}>
                                {Links.map((link) => (
                                    <NavLink key={link.name}>{link.name}</NavLink>
                                ))}
                            </Stack>
                        </Box>
                    ) : null} */}
                </Box>
                <Divider h='1px' bg='lineColor' />
                <SubHeader navigation={activeState} />
            </Box>
            <Box paddingTop={{ base: 70, md: activeState ? 134 : 70 }} height='100vh' overflow='clip'>
                <Box
                    height={{
                        base: 'calc(100vh - 70px)',
                        md: activeState ? 'calc(100vh - 134px)' : 'calc(100vh - 70px)',
                    }}
                    //overflow='scroll' since our content is always just the size of the screen/window, I believe there is no need for this to be scrollable. Just leaving the comment here in case some bugs arise from this
                    overflowX='hidden'
                    position='relative'
                >
                    <Outlet />
                </Box>
            </Box>
        </>
    );
};
