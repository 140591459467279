import { BasicLookup } from '@Redux/services/LookupApi/types';
import { Select as ChakraSelect, SelectProps as ChakraSelectProps } from '@chakra-ui/react';
import { FC } from 'react';

interface SelectProps extends ChakraSelectProps {
    options?: BasicLookup[];
}

export const Select: FC<SelectProps> = ({ options, value, ...rest }) => {
    return (
        <ChakraSelect value={value ?? ''} {...rest}>
            {options.map((item) => (
                <option value={item.key}>{item.value}</option>
            ))}
        </ChakraSelect>
    );
};
