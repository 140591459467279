import { Grid } from '@chakra-ui/react';
import FormHeader from '@Components/FormHeader';
import { StepProps } from '@Pages/EventResponses/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetPaymentMethodsQuery } from '@Redux/services/LookupApi';
import NumberInput from '@Components/NumberInput/Index';
import SelectInput from '@Components/SelectInput';
import { PAYMENT_METHOD_EWALLET } from 'src/constants';
import { ManageTextProps } from '@Hooks';

type PaymentProps = {
    isDrawer?: boolean;
} & StepProps;

const Payment: React.FC<PaymentProps> = ({
    id,
    manageFieldPropFactory,
    textFieldPropFactory,
    selectFieldPropFactory,
    isDrawer = false,
}) => {
    const { t } = useTranslation();
    const { data: { data: paymentMethods = [] } = {} } = useGetPaymentMethodsQuery();

    return (
        <FormHeader id={id} label={t('EventResponseDetail.Payment.headerLabel')}>
            <Grid
                column={{ md: 2, sm: 1 }}
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={
                    isDrawer
                        ? {
                              base: 'repeat(1, 1fr)',
                          }
                        : {
                              base: 'repeat(1, 1fr)',
                              md: 'repeat(3, 1fr)',
                          }
                }
                rowGap={4}
            >
                <SelectInput
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['payment', 'methodOfPayment'],
                        'onChangeValue',
                        'value'
                    )}
                    {...selectFieldPropFactory(t('EventResponseDetail.Payment.methodOfPayment'), [
                        'payment',
                        'methodOfPayment',
                    ])}
                    stacked={!isDrawer}
                    col={isDrawer ? 0 : 1}
                    readOnlyOptions={[PAYMENT_METHOD_EWALLET]}
                    options={paymentMethods}
                />

                <NumberInput
                    {...manageFieldPropFactory(['payment', 'amountPaid'], 'onChangeValue', 'value')}
                    {...textFieldPropFactory(t('EventResponseDetail.Payment.amountPaid'), ['payment', 'amountPaid'])}
                    leftIcon={'$'}
                    stacked={!isDrawer}
                    precision={2}
                    step={0.5}
                    min={0}
                />

                <NumberInput
                    {...manageFieldPropFactory(['payment', 'totalAmount'], 'onChangeValue', 'value')}
                    {...textFieldPropFactory(t('EventResponseDetail.Payment.totalAmount'), ['payment', 'totalAmount'])}
                    leftIcon={'$'}
                    stacked={!isDrawer}
                    precision={2}
                    step={0.5}
                    min={0}
                />
            </Grid>
        </FormHeader>
    );
};

export default Payment;
