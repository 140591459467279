import { Flex, Grid, IconButton } from '@chakra-ui/react';
import FormHeader from '@Components/FormHeader';
import TextInput from '@Components/TextInput';
import { StepProps } from '@Pages/EventResponses/types';
import Header from './components/Header';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Switch from '@Components/Switch';
import { useGetActiveGuardiansForStudentQuery } from '@Redux/services/LookupApi';
import SvgSummary from '@Icon/Summary';
import TypeaheadInput from '@Components/TypeaheadInput';
import { ManageSwitchInputProps, ManageTextProps } from '@Hooks';
import { NewTab } from '@Icon';

type DetailsProps = {
    isDrawer?: boolean;
} & StepProps;

const Details: React.FC<DetailsProps> = ({
    id,
    eventResponse,
    setEventResponse,
    manageFieldPropFactory,
    textFieldPropFactory,
    typeaheadPropFactory,
    switchFieldPropFactory,
    setDirtyAccessor,
    setDirtyData,
    dirtyData,
    dirtyAccessors,
    isDrawer = false,
}) => {
    const mounting = useRef(false);
    const { t } = useTranslation();
    const { data: { data: activeGuardians = [] } = {} } = useGetActiveGuardiansForStudentQuery({
        studentId: eventResponse.detail.studentId,
    });

    useEffect(() => {
        if (!mounting.current) {
            mounting.current = true;
            return;
        }
        if (eventResponse.detail.guardianNotFoundInList && eventResponse.detail.acceptingGuardianId) {
            setEventResponse({
                ...eventResponse,
                detail: {
                    ...eventResponse.detail,
                    acceptingGuardianId: undefined,
                },
            });
            setDirtyAccessor([...(dirtyAccessors ?? []), ['detail', 'acceptingGuardianId']]);
            if (dirtyData && 'detail' in dirtyData) {
                dirtyData['detail']['acceptingGuardianId'] = null;
                setDirtyData({ ...dirtyData });
            } else setDirtyData({ ...dirtyData, detail: { acceptingGuardianId: undefined } });
        }
    }, [eventResponse]);

    return (
        <FormHeader
            id={id}
            header={<Header title={t('Header.summary')} icon={<SvgSummary />} />}
            label={t('EventResponseDetail.Details.headerLabel')}
        >
            <Grid
                column={{ md: 2, sm: 1 }}
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={
                    isDrawer
                        ? { base: 'repeat(1, 1fr)' }
                        : {
                              base: 'repeat(1, 1fr)',
                              md: 'repeat(2, 1fr)',
                          }
                }
                rowGap={4}
            >
                <Flex alignItems='end' w={'100%'}>
                    <TextInput
                        {...manageFieldPropFactory<ManageTextProps>(['detail', 'fullName'], 'onChangeText', 'value')}
                        {...textFieldPropFactory(t('EventResponseDetail.Details.student'), ['detail', 'fullName'])}
                    />
                    <IconButton
                        aria-label='arrow forward'
                        ml={'1'}
                        variant={'outline'}
                        borderRadius={'var(--chakra-radii-sm)'}
                        border={'1px solid'}
                        borderColor='border-primary'
                        icon={<NewTab width={18} height={18} />}
                        onClick={() => window.open(`/records/student/${eventResponse?.detail?.studentId}`, '_blank')}
                    />
                </Flex>

                <TypeaheadInput
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['detail', 'acceptingGuardianId'],
                        'onSelection',
                        'value'
                    )}
                    {...typeaheadPropFactory(t('EventResponseDetail.Details.acceptingGuardian'), [
                        'detail',
                        'acceptingGuardianId',
                    ])}
                    options={activeGuardians}
                />

                <Switch
                    {...switchFieldPropFactory(t('EventResponseDetail.Details.guardianNotFoundInList'), [
                        'detail',
                        'guardianNotFoundInList',
                    ])}
                    {...manageFieldPropFactory<ManageSwitchInputProps>(
                        ['detail', 'guardianNotFoundInList'],
                        'onChangeSwitch',
                        'isChecked'
                    )}
                />
            </Grid>
        </FormHeader>
    );
};

export default Details;
