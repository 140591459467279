import * as React from 'react';
function SvgTrash(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g fillRule='evenodd' clipRule='evenodd' fill='currentColor'>
                <path d='M2.571 6a1 1 0 011-1h18a1 1 0 110 2h-18a1 1 0 01-1-1z' />
                <path d='M10.571 3a1 1 0 00-1 1v2a1 1 0 01-2 0V4a3 3 0 013-3h4a3 3 0 013 3v2a1 1 0 11-2 0V4a1 1 0 00-1-1h-4zm-5 2a1 1 0 011 1v14a1 1 0 001 1h10a1 1 0 001-1V6a1 1 0 112 0v14a3 3 0 01-3 3h-10a3 3 0 01-3-3V6a1 1 0 011-1z' />
                <path d='M10.571 10a1 1 0 011 1v6a1 1 0 11-2 0v-6a1 1 0 011-1zM14.571 10a1 1 0 011 1v6a1 1 0 11-2 0v-6a1 1 0 011-1z' />
            </g>
        </svg>
    );
}
export default SvgTrash;
