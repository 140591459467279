import { useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { DATE_TIME_FORMAT, DEFAULT_PAGE_SIZE } from 'src/constants';
import DateCell from '@Components/DateCell';
import StudentsSidebar from '@Components/StudentsSidebar';
import Table, { Column } from '@Components/Table';
import { GhostIcon } from '@Components/Button';
import { Pagination } from '@Components';
import { Search } from '@Components/Search';

import { useToast } from '@Hooks/useToast';
import { useDebounce } from '@Hooks';

import { Export, Refresh } from '@Icon';
import { PagedResult } from '@Redux/services/commonTypes';
import { useGetStudentActivitiesMutation } from '@Redux/services/Absence';
import { parseError } from '@Utilities';

import { StudentActivityRecordFilters, TableFilters } from './TableFilters';

export type SortByProps = { id: string; desc: boolean };

const defaultFilters = {
    dateRange: [new Date(), new Date()],
    statusReason: null,
    priority: null,
};

export const StudentActivityRecords = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const mainCotainerRef = useRef<HTMLDivElement>(null);

    const defaultSortOrder = { id: 'createdOn', desc: true };

    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [sortBy, setSortBy] = useState<SortByProps>(defaultSortOrder);

    const [initialized, setInitialized] = useState(false);

    const toast = useToast();

    const [lastRefreshed, setLastRefreshed] = useState<Date>();

    const [filters, setFilters] = useState<StudentActivityRecordFilters>(defaultFilters);

    const [
        GetStudentActivityRecords,
        { isLoading, data: { data: studentActivityRecords = [], pagedResult = {} as PagedResult } = {} },
    ] = useGetStudentActivitiesMutation();

    const getStudentActivityRecords = (
        sortBy: SortByProps,
        currentPage: number,
        searchQuery: string,
        filters: StudentActivityRecordFilters
    ) => {
        const obj = {
            sortBy: sortBy?.id,
            isDesc: sortBy?.desc,
            currentPage: currentPage,
            pageSize: pageSize,
            searchText: searchQuery,
            startDate: filters?.dateRange?.[0] ?? null,
            endDate: filters?.dateRange?.[1] ?? null,
            statusReason: filters.statusReason === '' ? null : filters.statusReason,
            priority: filters.priority === '' ? null : filters.priority,
            // Type check required as the placeholder has value of empty string which deemed same as 0 (Low priority)
        };

        GetStudentActivityRecords(obj)
            .unwrap()
            .catch((e) => parseError(toast, e))
            .finally(() => {
                setLastRefreshed(new Date());
            });
    };

    const debouncedResults = useDebounce(() => {
        getStudentActivityRecords(sortBy, currentPage, searchQuery, filters);
    });

    useEffect(() => {
        debouncedResults();
    }, [searchQuery, filters]);

    useEffect(() => {
        if (!initialized) return;

        getStudentActivityRecords(sortBy, currentPage, searchQuery, filters);
    }, [currentPage, sortBy, pageSize]);

    useEffect(() => {
        setTimeout(() => {
            setInitialized(true);
        }, 500);
    }, []);

    const onSortChange = (sortBy) => {
        if (sortBy[0]) {
            setSortBy(sortBy[0]);
        }
    };

    // Resetting pagination and filtering when searching
    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const handlePageSizeChange = (newPageSize: number) => {
        setCurrentPage(1);
        setPageSize(newPageSize);
    };

    const handleFilterChange = (key: string) => (value) => {
        setCurrentPage(1);
        const newFilters = { ...filters, [key]: value };
        setFilters(newFilters);
    };

    const handleResetField = (key: string, value: []) => {
        const newFilters = { ...filters, [key]: value };
        setFilters(newFilters);
    };

    const handleReset = () => {
        setFilters({
            dateRange: [],
            statusReason: null,
            priority: null,
        });
    };

    const handleRefresh = () => {
        debouncedResults();
    };

    const defaultHeaders = [
        {
            Header: t('StudentActivityRecords.type'),
            accessor: 'type',
            headerAlign: 'left',
        },
        {
            Header: t('StudentActivityRecords.priority'),
            accessor: 'priority',
            sortType: 'datetime',
            headerAlign: 'left',
        },
        {
            Header: t('StudentActivityRecords.statusReason'),
            accessor: 'statusReason',
            headerAlign: 'left',
        },
        {
            Header: t('StudentActivityRecords.student'),
            accessor: 'student',
            headerAlign: 'left',
        },
        {
            Header: t('StudentActivityRecords.relatedAbsence'),
            accessor: 'relatedAbsence',
            headerAlign: 'left',
        },
        {
            Header: t('StudentActivityRecords.createdOn'),
            accessor: 'createdOn',
            Cell: DateCell(DATE_TIME_FORMAT),
            headerAlign: 'left',
        },
    ] as Column[];

    return (
        <Flex id='st' align={'stretch'} h='inherit'>
            <StudentsSidebar activeItem='student-activities' />
            <Flex ref={mainCotainerRef} py={0} pl={2} pr={2} overflowX={'hidden'} gap={4} w={'full'} h='inherit'>
                <Flex
                    overflowX={'hidden'}
                    borderRadius='20px 0 0 0'
                    borderWidth='2px 2px 2px 2px'
                    borderStyle='solid'
                    borderColor='border-primary'
                    height={'full'}
                    w={'full'}
                    align={'stretch'}
                    direction={'column'}
                >
                    <Box>
                        <Flex
                            justify={'space-between'}
                            align={'center'}
                            p={6}
                            flexDirection={{ base: 'column', md: 'row' }}
                        >
                            <Flex flexDirection={'column'}>
                                <Text textStyle={'md-bold'} pb={{ base: 2, md: 0 }}>
                                    {t('StudentActivityRecords.title')}
                                </Text>
                                {lastRefreshed && (
                                    <Text textStyle={'sm-medium'} pb={{ base: 2, md: 0 }}>
                                        {t('Table.lastUpdatedOn') + format(lastRefreshed, DATE_TIME_FORMAT)}
                                    </Text>
                                )}
                            </Flex>
                            <HStack spacing={5}>
                                <Box maxW={224} minW={224}>
                                    <Search iconPosition='left' query={searchQuery} onChange={handleSearch} />
                                </Box>
                                <GhostIcon
                                    aria-label='refresh'
                                    size={'sm'}
                                    borderRadius='sm'
                                    title={t('StudentActivityRecords.refreshTable')}
                                    icon={<Refresh width={20} height={20} fill='text-primary' />}
                                    onClick={handleRefresh}
                                />
                                <GhostIcon
                                    aria-label='export'
                                    size={'sm'}
                                    borderRadius='sm'
                                    title={t('StudentActivityRecords.exportTable')}
                                    icon={<Export width={20} height={20} />}
                                    onClick={() => {
                                        console.log('dsa');
                                    }}
                                />
                            </HStack>
                        </Flex>
                    </Box>
                    <Flex position='relative' flex='auto'>
                        <Table
                            manualPagination={true}
                            manualSortBy={true}
                            columns={defaultHeaders}
                            rawData={isLoading ? [] : studentActivityRecords}
                            emptyMessage={t('StudentActivityRecords.noData')}
                            isLoading={isLoading}
                            onSortChange={onSortChange}
                            onEditHandler={(row) => navigate(`/records/students/activities/${row.original.activityId}`)}
                            filter={
                                <TableFilters
                                    onChange={handleFilterChange}
                                    onReset={handleReset}
                                    onResetField={handleResetField}
                                    filters={filters}
                                />
                            }
                            initialSortBy={sortBy ? [sortBy] : []}
                            tableWidth={{ 'table-layout': 'auto', width: '100%' }}
                            containerStyle={{ borderLeftWidth: 0, borderRightWidth: 0, borderRadius: 0 }}
                            stickyHeader
                        />
                    </Flex>

                    {!pagedResult?.pageCount ? null : (
                        <Box p={2}>
                            <Pagination
                                currentPage={currentPage}
                                onPageChange={setCurrentPage}
                                totalPages={pagedResult?.pageCount}
                                totalResults={pagedResult?.rowCount}
                                onPageSizeChange={handlePageSizeChange}
                                pageSize={pageSize}
                            />
                        </Box>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};
