import { FormEvent } from 'react';
import {
    Box,
    Drawer as ChakraDrawer,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerOverlay,
    Stack,
    Text,
    DrawerProps,
} from '@chakra-ui/react';
import Spinner from '@Components/Spinner';

interface IModalProps extends DrawerProps {
    title: string | React.ReactNode;
    subtitle?: string;
    isForm?: boolean;
    isLoading?: boolean;
    footer?: React.ReactNode;
    onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
}

const Drawer = ({
    title,
    subtitle,
    footer,
    isForm,
    children,
    onSubmit,
    isLoading,
    size = 'lg',
    ...rest
}: IModalProps) => {
    const drawerBody = (
        <>
            <DrawerOverlay />
            <DrawerContent p={5}>
                <Stack>
                    <Text textStyle={'xl-medium'} fontWeight='medium'>
                        {title}
                    </Text>
                    {Boolean(subtitle) && (
                        <Text textStyle={'md-normal'} fontWeight='normal' color='secondary'>
                            {subtitle}
                        </Text>
                    )}
                </Stack>
                <DrawerCloseButton />
                {isLoading ? (
                    <Spinner />
                ) : (
                    <>
                        <Box mt={4} overflowY='auto' overflowX='hidden' height={'100%'}>
                            {children}
                        </Box>

                        {footer && <DrawerFooter padding='16px 0px 0px 0px'>{footer}</DrawerFooter>}
                    </>
                )}
            </DrawerContent>
        </>
    );

    return (
        <ChakraDrawer closeOnOverlayClick={true} size={size} {...rest}>
            {isForm ? (
                <form noValidate onSubmit={onSubmit}>
                    {drawerBody}
                </form>
            ) : (
                drawerBody
            )}
        </ChakraDrawer>
    );
};

export default Drawer;
