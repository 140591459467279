import { Grid, GridItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import React from 'react';
import TypeaheadInput from '@Components/TypeaheadInput';
import { StudentStepProps } from '@Pages/StudentDetails/types';
import { useGetSchoolsQuery } from '@Redux/services/LookupApi';
import { ManageDatePickerProps, ManageTextProps } from '@Hooks';
import DatePicker from '@Components/DateTimeInput';

const LatestActivities = React.forwardRef<HTMLDivElement, StudentStepProps>(
    ({ studentDetail, manageFieldPropFactory, textFieldPropFactory, typeaheadPropFactory }) => {
        const { t } = useTranslation();

        const { data: { data: schoolOptions = [] } = {} } = useGetSchoolsQuery({});

        return (
            <Grid
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(3, 1fr)',
                }}
                rowGap={4}
                w={'full'}
            >
                <GridItem>
                    <TypeaheadInput
                        {...typeaheadPropFactory(t('LatestActivities.lastConsent'), ['lastOngoingConsent'])}
                        {...manageFieldPropFactory<ManageTextProps>(['lastOngoingConsent'], 'onSelection', 'value')}
                        stacked
                        options={
                            studentDetail.lastOngoingConsent
                                ? [
                                      {
                                          key: studentDetail.lastOngoingConsent,
                                          value: studentDetail.fullName,
                                      },
                                  ]
                                : []
                        }
                        baseUrl={`/events/${studentDetail.eventId}/response`}
                    />
                </GridItem>

                <GridItem>
                    <DatePicker
                        {...textFieldPropFactory(t('LatestActivities.consentForm'), ['ongoingConsentFormExpiryDate'])}
                        {...manageFieldPropFactory<ManageDatePickerProps>(
                            ['ongoingConsentFormExpiryDate'],
                            'onDateChange',
                            'date'
                        )}
                        size='md'
                        stacked
                    />
                </GridItem>

                <GridItem>
                    <TypeaheadInput
                        {...typeaheadPropFactory(t('LatestActivities.homeSchool'), ['ownerId'])}
                        {...manageFieldPropFactory<ManageTextProps>(['ownerId'], 'onSelection', 'value')}
                        options={schoolOptions}
                        stacked
                    />
                </GridItem>
            </Grid>
        );
    }
);

export default LatestActivities;
