import type { PartialDeep } from 'type-fest';
import { EventResponseDetail } from '@Redux/services/EventResponse/types';
import { Dispatch, SetStateAction } from 'react';
import { FormComponentProps } from '@Hooks/useForms';
import { Accessor } from '@Utilities';
import {
    EVENT_RESPONSE_CANCELLED,
    EVENT_RESPONSE_CANCELLED_AND_CLOSED,
    EVENT_RESPONSE_CLOSED,
    EVENT_RESPONSE_CONFIRMED,
    EVENT_RESPONSE_CONFIRMED_AND_CLOSED,
    EVENT_RESPONSE_DRAFT,
    EVENT_RESPONSE_PENDING,
} from 'src/constants';
import { Activity, Note } from '@Redux/services/commonTypes';

export interface StepProps extends FormComponentProps {
    eventResponse?: PartialDeep<EventResponseDetail>;
    setEventResponse?: Dispatch<SetStateAction<PartialDeep<EventResponseDetail>>>;
    onSubmit?: () => void;
    setDirtyData?: Dispatch<SetStateAction<PartialDeep<EventResponseDetail>>>;
    setDirtyAccessor?: Dispatch<SetStateAction<Accessor[]>>;
    dirtyData?: PartialDeep<EventResponseDetail>;
    dirtyAccessors?: Accessor[];
}

export interface NotesProps extends FormComponentProps {
    notes: Note[];
    activities: Activity[];
    onAddNote?: (noteText: string) => Promise<void>;
    onDeleteNote?: (annotationId: string) => Promise<void>;
    onUpdateNote?: (noteText: string, annotationId: string) => Promise<void>;
}

export const EVENT_RESPONSE_STATUSES = {
    [EVENT_RESPONSE_CANCELLED]: 'Cancelled',
    [EVENT_RESPONSE_CANCELLED_AND_CLOSED]: 'Cancelled and closed',
    [EVENT_RESPONSE_CLOSED]: 'Closed',
    [EVENT_RESPONSE_CONFIRMED]: 'Confirm',
    [EVENT_RESPONSE_CONFIRMED_AND_CLOSED]: 'Confirmed and closed',
    [EVENT_RESPONSE_DRAFT]: 'Draft',
    [EVENT_RESPONSE_PENDING]: 'Pending',
};

const PendingAndDraft = [EVENT_RESPONSE_PENDING, EVENT_RESPONSE_DRAFT];

export const editableFieldsByStatus = {
    'detail.teamId': {
        required: true,
        editableStatus: [],
        validateSave: true,
    },
    'detail.student': {
        required: true,
        editableStatus: [],
        validateSave: false,
    },
    'detail.acceptingGuardianId': {
        required: false,
        editableStatus: PendingAndDraft,
        validateSave: false,
    },
    'detail.guardianNotFoundInList': {
        required: false,
        editableStatus: PendingAndDraft,
        validateSave: true,
    },
    'payment.methodOfPayment': {
        required: false,
        editableStatus: PendingAndDraft,
        validateSave: false,
    },
    'payment.amountPaid': {
        required: true,
        editableStatus: PendingAndDraft,
        validateSave: true,
    },
    'payment.totalAmount': {
        required: true,
        editableStatus: [],
        validateSave: false,
    },
    'cancellation.refundPercentage': {
        required: false,
        editableStatus: [...PendingAndDraft, EVENT_RESPONSE_CONFIRMED],
        validateSave: false,
    },
    'cancellation.reason': {
        required: false,
        editableStatus: [...PendingAndDraft, EVENT_RESPONSE_CONFIRMED],
        validateSave: false,
    },
    'detail.administrationComment': {
        required: false,
        editableStatus: [...PendingAndDraft, EVENT_RESPONSE_CONFIRMED],
        validateSave: false,
    },
    'subsidy.confirmedOn': {
        required: false,
        editableStatus: [...PendingAndDraft],
        validateSave: false,
    },
    'subsidy.maxSubsidyAmount': {
        required: false,
        editableStatus: [...PendingAndDraft],
        validateSave: false,
    },
    'subsidy.subsidyAmount': {
        required: false,
        editableStatus: [...PendingAndDraft],
        validateSave: false,
    },
    'detail.questionsAnswered': {
        required: false,
        editableStatus: [...PendingAndDraft],
        validateSave: false,
    },
};
