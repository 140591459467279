import { Grid, GridItem } from '@chakra-ui/react';
import TextInput from '@Components/TextInput';
import { useTranslation } from 'react-i18next';
import React from 'react';
import TypeaheadInput from '@Components/TypeaheadInput';
import { ParticipantStepProps } from '@Pages/EventParticipants/types';
import { ManageTextProps } from '@Hooks';

const ParticipantActivities = React.forwardRef<HTMLDivElement, ParticipantStepProps>(
    ({ manageFieldPropFactory, textFieldPropFactory, typeaheadPropFactory }) => {
        const { t } = useTranslation();

        return (
            <Grid
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(3, 1fr)',
                }}
                rowGap={4}
                w={'full'}
            >
                <GridItem>
                    <TextInput
                        {...typeaheadPropFactory(t('ParticipantActivities.locked'), ['locked'])}
                        {...manageFieldPropFactory<ManageTextProps>(['locked'], 'onSelection', 'value')}
                        stacked
                    />
                </GridItem>

                <GridItem>
                    <TextInput
                        {...textFieldPropFactory(t('ParticipantActivities.lastUsedOn'), ['lastUsedOn'])}
                        {...manageFieldPropFactory<ManageTextProps>(['lastUsedOn'], 'onChangeText', 'value')}
                        stacked
                    />
                </GridItem>

                <GridItem>
                    <TypeaheadInput
                        {...typeaheadPropFactory(t('ParticipantActivities.owner'), ['owner'])}
                        {...manageFieldPropFactory<ManageTextProps>(['owner'], 'onSelection', 'value')}
                        options={[]}
                        stacked
                        placeholder='--'
                    />
                </GridItem>
            </Grid>
        );
    }
);

export default ParticipantActivities;
