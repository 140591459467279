import Notes from '@Components/Notes';
import { useToast } from '@Hooks/useToast';
import { History } from '@Icon';
import Header from '@Pages/EventDetails/components/Summary/components/Header';
import { LateAbsenceStepProps } from '@Pages/LateAbsenceDetails/types';
import { useGetEventNotesMutation } from '@Redux/services/Event';
import { parseError, parseWarning } from '@Utilities';
import { Box } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ActivityLog = React.forwardRef<HTMLDivElement, LateAbsenceStepProps>(
    ({ id, lateAbsenceDetails, manageFieldPropFactory, setLateAbsenceDetails }) => {
        const { t } = useTranslation();

        const toast = useToast();
        const [getEventNotes, { isLoading: isNotesLoading }] = useGetEventNotesMutation();
        const handleNotePageChange = async (pageNumber: number, pageSize: number) => {
            await getEventNotes({
                objectId: lateAbsenceDetails?.attendanceId,
                pageSize,
                currentPage: pageNumber,
            })
                .unwrap()
                .then((response) => {
                    parseWarning(toast, response);
                    setLateAbsenceDetails({
                        ...lateAbsenceDetails,
                        lateAndAbsenceNotesInfo: { notesInfo: response.data, pagedResult: response.pagedResult },
                    });
                })
                .catch((e) => parseError(toast, e));
        };

        return (
            <Box id={id}>
                <Header divider={true} mb={4} title={t('LateAbsence.subheader.activityLog')} icon={<History />} />
                <Notes
                    hidePagination={true}
                    notes={lateAbsenceDetails.lateAndAbsenceNotesInfo.notesInfo}
                    activities={lateAbsenceDetails.lateAndAbsenceActivitiesInfo}
                    notePagination={lateAbsenceDetails.lateAndAbsenceNotesInfo.pagedResult}
                    onNextPage={handleNotePageChange}
                    isNotesLoading={isNotesLoading}
                    manageFieldPropFactory={manageFieldPropFactory}
                    hideFormHeader={true}
                />
            </Box>
        );
    }
);

export default ActivityLog;
