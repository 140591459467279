import { VStack } from '@chakra-ui/react';
import { AuditInfo } from '@Components/AuditInfo';
import Drawer from '@Components/Drawer';
import { DrawerFooterActions } from '@Components/DrawerFooterActions';
import FormHeader from '@Components/FormHeader';
import SelectInput from '@Components/SelectInput';
import Switch from '@Components/Switch';
import TextInput from '@Components/TextInput';
import TypeaheadInput from '@Components/TypeaheadInput';
import { useToast } from '@Hooks/useToast';
import { CostItemDetail, CostItemResponseAnswerDetail } from '@Redux/services/Event/types';
import { useUpdateEventResponseCostItemMutation } from '@Redux/services/EventResponse';
import { EventResponseDetail } from '@Redux/services/EventResponse/types';
import { useGetCostItemGroupTypeQuery } from '@Redux/services/LookupApi';
import { createLookupMap, parseError, parseWarning } from '@Utilities';
import { cloneDeep, isArray, isUndefined, times } from 'lodash';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    COST_ITEM_GROUP1,
    COST_ITEM_GROUP2,
    COST_ITEM_GROUP3,
    COST_ITEM_REQUIRED,
    MAX_COST_ITEM_OPTIONS,
} from 'src/constants';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    onCostItemClick: () => void;
    onCancel: () => void;
    costItemResponse: CostItemResponseAnswerDetail;
    costItems: CostItemDetail[];
    onUpdate: (responseData: EventResponseDetail) => void;
    isEditable: boolean;
    label?: string | ReactNode;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
}
export const CostItemResponseForm: FC<IProps> = ({
    costItemResponse,
    costItems,
    isOpen,
    isEditable,
    label,
    size,
    onCostItemClick,
    onClose,
    onUpdate,
    onCancel,
}) => {
    const defaultOptionsGrouping = { [COST_ITEM_GROUP1]: [], [COST_ITEM_GROUP2]: [], [COST_ITEM_GROUP3]: [] };
    const [costItem, setCostItem] = useState<CostItemDetail>();
    const [options, setOptions] = useState([]);
    const [quantity, setQuantity] = useState(0);
    const [group1Selection, setGroup1Selection] = useState<Record<string, boolean>>({});
    const [group2Selection, setGroup2Selection] = useState<Record<string, boolean>>({});
    const [group1SelectedOption, setGroup1SelectedOption] = useState<string>('');
    const [group2SelectedOption, setGroup2SelectedOption] = useState<string>('');
    const [ungroupedSelection, setUngroupedSelection] = useState<Record<string, boolean>>({});
    const [quantityError, setQuantityError] = useState('');
    const { t } = useTranslation();

    const [updateEventResponseCostItem, { isLoading: isUpdating }] = useUpdateEventResponseCostItemMutation();
    const { data: { data: groupTypes = [] } = {} } = useGetCostItemGroupTypeQuery();
    const groupTypesMap = useMemo(() => createLookupMap(groupTypes), [groupTypes]);
    const toast = useToast();

    useEffect(() => {
        (async () => {
            if (costItemResponse && costItems && isOpen) {
                const items = costItems.filter((x) => x.costItemId == costItemResponse.costItemId);
                if (items.length) {
                    const item = items[0];
                    setQuantity(costItemResponse.quantity);
                    setCostItem(item);
                    const newOptions = cloneDeep(defaultOptionsGrouping);
                    let group1Items = {};
                    let group2Items = {};
                    let ungroupedItems = {};
                    for (const value of times(MAX_COST_ITEM_OPTIONS)) {
                        const groupKey = `option${value + 1}Group`;
                        const optionKey = `option${value + 1}`;

                        if (item[groupKey] in newOptions && item[optionKey]) {
                            const optionSelectedKey = `option${value + 1}Selected`;
                            if (item[groupKey] === COST_ITEM_GROUP1) {
                                if (optionSelectedKey in costItemResponse)
                                    group1Items = {
                                        ...group1Items,
                                        [optionSelectedKey]: costItemResponse[optionSelectedKey],
                                    };
                                if (costItemResponse[optionSelectedKey]) {
                                    setGroup1SelectedOption(optionSelectedKey);
                                }
                            }

                            if (item[groupKey] === COST_ITEM_GROUP2) {
                                if (optionSelectedKey in costItemResponse)
                                    group2Items = {
                                        ...group2Items,
                                        [optionSelectedKey]: costItemResponse[optionSelectedKey],
                                    };
                                if (costItemResponse[optionSelectedKey]) {
                                    setGroup2SelectedOption(optionSelectedKey);
                                }
                            }
                            if (item[groupKey] === COST_ITEM_GROUP3) {
                                ungroupedItems = {
                                    ...ungroupedItems,
                                    [optionSelectedKey]: costItemResponse[optionSelectedKey],
                                };
                            }

                            newOptions[item[groupKey]].push({
                                key: item[optionKey],
                                index: value + 1,
                                group: item[groupKey],
                            });
                        }
                    }
                    setGroup1Selection(group1Items);
                    setGroup2Selection(group2Items);
                    setUngroupedSelection(ungroupedItems);
                    //order the list
                    const orderedOptions = [];
                    for (const value of times(MAX_COST_ITEM_OPTIONS)) {
                        let item = hasItemInList(newOptions[COST_ITEM_GROUP1], value + 1);
                        if (item) {
                            orderedOptions.push(newOptions[COST_ITEM_GROUP1]);
                            newOptions[COST_ITEM_GROUP1] = [];
                            continue;
                        }
                        item = hasItemInList(newOptions[COST_ITEM_GROUP2], value + 1);
                        if (item) {
                            orderedOptions.push(newOptions[COST_ITEM_GROUP2]);
                            newOptions[COST_ITEM_GROUP2] = [];
                            continue;
                        }
                        item = hasItemInList(newOptions[COST_ITEM_GROUP3], value + 1);
                        if (item) {
                            orderedOptions.push(item);
                            continue;
                        }
                    }

                    setOptions(orderedOptions);
                }
            }
        })();
    }, [costItemResponse, costItems, isOpen]);

    const hasItemInList = (items, index) => {
        for (const item of items) {
            if (item.index === index) {
                return item;
            }
        }
        return false;
    };

    const handleGroupSelection = (isGroup1: boolean) => (value: string) => {
        let groupSelection = group1Selection;
        let setGroupSelection = setGroup1Selection;
        if (!isGroup1) {
            groupSelection = group2Selection;
            setGroupSelection = setGroup2Selection;
            setGroup2SelectedOption(value);
        } else {
            setGroup1SelectedOption(value);
        }
        Object.keys(groupSelection).map((key) => {
            groupSelection[key] = key === value;
        });
        setGroupSelection(groupSelection);
    };

    const handleSubmit = async () => {
        if (costItem?.required === COST_ITEM_REQUIRED && (isUndefined(quantity) || quantity === 0)) {
            setQuantityError(t('CostItemResponseForm.quantityError'));
            return;
        }
        setQuantityError('');

        await updateEventResponseCostItem({
            eventResponseCostItemId: costItemResponse?.eventResponseCostItemId,
            quantity,
            ...group1Selection,
            ...group2Selection,
            ...ungroupedSelection,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                onUpdate(response.data);
                onCancel();
            })
            .catch((e) => parseError(toast, e));
    };

    return (
        <Drawer
            size={size}
            isOpen={isOpen}
            title={label ?? t('CostItemResponseForm.title')}
            subtitle={t('CostItemResponseForm.subTitle')}
            onClose={onClose}
            footer={
                <DrawerFooterActions
                    isEditable={isEditable}
                    closeButtonProps={{
                        isDisabled: isUpdating,
                        label: t('CostItemResponseForm.closeDrawer'),
                        onClick: onCancel,
                    }}
                    saveButtonProps={{
                        onClick: handleSubmit,
                        label: t(
                            isUpdating ? 'CostItemResponseForm.buttonLoading' : 'CostItemResponseForm.saveButtonLabel'
                        ),
                        isDisabled: isUpdating,
                    }}
                />
            }
        >
            <FormHeader label={t('CostItemResponseForm.general')}>
                <VStack gap={'8px'}>
                    <TypeaheadInput
                        label={t('CostItemResponseForm.costItem')}
                        options={
                            costItem
                                ? [
                                      {
                                          key: costItem?.costItemId,
                                          value: costItem?.title,
                                      },
                                  ]
                                : []
                        }
                        value={costItemResponse?.costItemId}
                        isLocked={true}
                        onLinkClick={onCostItemClick}
                    />
                    <SelectInput
                        label={t('CostItemResponseForm.quantity')}
                        value={quantity}
                        options={times(costItem?.maxQuantityAllowed + 1).map((value) => ({
                            key: value,
                            value: value.toString(),
                        }))}
                        onChangeValue={(value: string) => setQuantity(parseInt(value, 10))}
                        isInvalid={Boolean(quantityError)}
                        defaultValue={0}
                        error={quantityError}
                        isLocked={!isEditable}
                    />
                    <TextInput label={t('CostItemResponseForm.unitCost')} value={costItem?.unitCost} isLocked={true} />
                </VStack>
            </FormHeader>

            {options.length > 0 && (
                <FormHeader label={t('CostItemResponseForm.options')}>
                    <VStack gap='8px'>
                        {options.map((item, index) => {
                            if (isArray(item) && item.length > 0) {
                                const selectedValue =
                                    item[0].group === COST_ITEM_GROUP1 ? group1SelectedOption : group2SelectedOption;
                                return (
                                    <SelectInput
                                        key={index}
                                        label={groupTypesMap[item[0].group]?.value}
                                        options={item.map((item) => ({
                                            key: `option${item.index}Selected`,
                                            value: item.key,
                                        }))}
                                        onChangeValue={handleGroupSelection(item[0].group === COST_ITEM_GROUP1)}
                                        value={selectedValue}
                                        isLocked={!isEditable}
                                    />
                                );
                            } else {
                                return (
                                    <Switch
                                        key={`${item.key}-${item.index}`}
                                        label={item.key}
                                        onChangeSwitch={(value) =>
                                            setUngroupedSelection({
                                                ...ungroupedSelection,
                                                [`option${item.index}Selected`]: value,
                                            })
                                        }
                                        isChecked={ungroupedSelection[`option${item.index}Selected`]}
                                        isLocked={!isEditable}
                                    />
                                );
                            }
                        })}
                    </VStack>
                </FormHeader>
            )}

            <AuditInfo
                createdBy={costItemResponse?.createdByFullName}
                modifiedBy={costItemResponse?.modifiedByFullName}
                modifiedOn={costItemResponse?.modifiedOn}
                createdOn={costItemResponse?.createdOn}
            />
        </Drawer>
    );
};
