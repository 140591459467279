import { FC, FormEvent, ReactNode, useEffect, useMemo } from 'react';

import { Box, Grid, GridItem } from '@chakra-ui/react';

import Drawer from '@Components/Drawer';
import TextInput from '@Components/TextInput';
import { CostItemDetail } from '@Redux/services/Event/types';
import { useTranslation } from 'react-i18next';
import { getMutableForAccessor, parseError, parseWarning } from '@Utilities';
import { CostItems, Save } from '@Icon';
import FormHeader from '@Components/FormHeader';
import { useGetCostItemGroupTypeQuery, useGetCostItemRequirednessTypeQuery } from '@Redux/services/LookupApi';
import SelectInput from '@Components/SelectInput';
import { ManageTextProps, useForms } from '@Hooks';
import { Validator } from './validator';
import {
    useCreateCostItemMutation,
    useDeleteCostItemMutation,
    useGetCostItemMutation,
    useUpdateCostItemMutation,
} from '@Redux/services/Event';
import { useToast } from '@Hooks/useToast';
import SectionHeader from '@Components/SectionHeader';
import NumberInput from '@Components/NumberInput/Index';
import { AuditInfo } from '@Components/AuditInfo';
import { DrawerFooterActions } from '@Components/DrawerFooterActions';
import { MenuItem } from '@Components/Menu';

interface IProps {
    id?: string;
    costItemId?: string;
    eventId: string;
    onSubmit?: (closeModal: boolean, costItems?: CostItemDetail[]) => void;
    eventStatus: number;
    onClose: () => void;
    onCancel: () => void;
    isOpen: boolean;
    isEditable: boolean;
    label?: string | ReactNode;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
}

const costItemOptions = Array(15).fill({
    option: '',
    group: '',
    isInvalidOption: false,
    isInvalidGroup: false,
    errorOption: '',
    errorGroup: '',
});

const CostItemForm: FC<IProps> = ({
    eventId,
    id,
    costItemId,
    label,
    onSubmit,
    isEditable,
    isOpen,
    eventStatus,
    size,
    onClose,
    onCancel,
}) => {
    const { data: { data: requiredOptions = [] } = {} } = useGetCostItemRequirednessTypeQuery({ searchText: '' });
    const [getCostItem, { isLoading }] = useGetCostItemMutation({});
    const [createCostItem, { isLoading: creating }] = useCreateCostItemMutation();
    const [updateCostItem, { isLoading: updating }] = useUpdateCostItemMutation();
    const [deleteCostItem, { isLoading: deleting }] = useDeleteCostItemMutation();

    const { data: { data: costItemGroups = [] } = {} } = useGetCostItemGroupTypeQuery();

    const { t } = useTranslation();

    const toast = useToast();

    const {
        formData: costItem,
        manageFieldPropFactory,
        textFieldPropFactory,
        selectFieldPropFactory,
        setErrors,
        setFormData: setCostItems,
        dirtyData,
        setDirtyData,
    } = useForms<CostItemDetail>({}, () => ({
        isRequired: false,
        isLocked: !isEditable,
        disabled: !isEditable,
    }));

    const isDirty = useMemo(() => dirtyData && Object.keys(dirtyData).length > 0, [dirtyData, setDirtyData]);

    const getCostItemData = async () => {
        if (costItemId) {
            await getCostItem({
                costItemId,
            })
                .unwrap()
                .then((response) => {
                    parseWarning(toast, response);
                    setCostItems(response.data);
                });
        }
    };

    useEffect(() => {
        getCostItemData();
    }, [costItemId, eventId]);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const nextErrors: Record<string, string> = {};
        const validationRules = Validator(t);

        validationRules.forEach(({ accessorPath, validator, required, editableStatus, message }) => {
            const finalMutable = getMutableForAccessor(costItem, accessorPath);
            const finalProperty = accessorPath[accessorPath.length - 1];
            if (
                (required || finalMutable[finalProperty]) &&
                editableStatus?.indexOf(eventStatus) > -1 &&
                !validator(finalMutable[finalProperty])
            ) {
                nextErrors[accessorPath.join('.')] = message;
            }
        });

        if (Object.keys(nextErrors).length) {
            setErrors(nextErrors);
            return false;
        }
        let saveFunction = createCostItem;
        let successDescription = t('CostItemForm.toastCreateSuccess');
        let formData = { ...costItem, eventId };
        if (costItemId) {
            saveFunction = updateCostItem;
            formData = { ...dirtyData, costItemId: costItem.costItemId, eventId };
            successDescription = t('CostItemForm.toastUpdateSuccess');
        }

        await saveFunction({ ...formData } as CostItemDetail)
            .unwrap()
            .then((response) => {
                if (response?.warningResult?.showAlert) {
                    parseWarning(toast, response);
                } else {
                    toast({
                        status: 'success',
                        description: successDescription,
                    });
                }
                onSubmit(false, response.data);
                onClose();
            })
            .catch((error) => {
                setErrors(error.data.errors);
                parseError(toast, error);
            });
    };

    const handleDelete = async () => {
        await deleteCostItem({
            eventId: eventId,
            costItemId: costItem?.costItemId,
        })
            .unwrap()
            .then((response) => {
                if (response?.warningResult?.showAlert) {
                    parseWarning(toast, response);
                } else {
                    toast({
                        status: 'success',
                        description: t('CostItemForm.toastDeleteSuccess'),
                    });
                }
                onSubmit(true, response.data);
                setDirtyData({});
            })
            .catch((e) => {
                parseError(toast, e);
            });
    };

    const actionMenuItems = useMemo<MenuItem[]>(
        () =>
            costItemId
                ? [
                      {
                          label: t('CostItemForm.delete'),
                          message: t('CostItemForm.confirmationMessage'),
                          showConfirmation: true,
                          menuItemProps: {
                              onClick: async () => await handleDelete(),
                          },
                      },
                  ]
                : [],
        [costItemId, costItem]
    );

    const loading = (creating || updating || deleting) as boolean;

    return (
        <>
            <Drawer
                size={size}
                isOpen={isOpen}
                onClose={onClose}
                onOverlayClick={onClose}
                onSubmit={handleSubmit}
                isForm={true}
                isLoading={isLoading}
                title={label ?? <SectionHeader icon={<CostItems />} title={t('CostItemForm.headerTitle')} />}
                footer={
                    <DrawerFooterActions
                        isEditable={isEditable}
                        closeButtonProps={{
                            onClick: onCancel,
                            isDisabled: loading,
                            label: t('CostItemForm.closeDrawer'),
                        }}
                        saveButtonProps={{
                            type: 'submit',
                            isDisabled: loading || !isDirty,
                            leftIcon: <Save width={20} height={20} />,
                            label: t('CostItemForm.save'),
                        }}
                        actionMenuItems={isEditable && costItemId && actionMenuItems}
                    />
                }
            >
                <>
                    <FormHeader id={id} label={t('CostItemForm.general')}>
                        <Box pb={4}>
                            <TextInput
                                {...textFieldPropFactory(t(`CostItemForm.title`), ['title'])}
                                {...manageFieldPropFactory<ManageTextProps>(['title'], 'onChangeText', 'value')}
                                stacked
                            />
                        </Box>
                        <Grid
                            columnGap={10}
                            gridAutoFlow='dense'
                            templateColumns={{
                                base: 'repeat(1, 1fr)',
                                md: 'repeat(2, 1fr)',
                            }}
                            rowGap={4}
                            mb={4}
                        >
                            <GridItem>
                                <SelectInput
                                    {...selectFieldPropFactory(t('CostItemForm.required'), [`required`])}
                                    {...manageFieldPropFactory([`required`], 'onChangeValue', 'value')}
                                    stacked
                                    options={requiredOptions}
                                />
                            </GridItem>

                            <GridItem>
                                <NumberInput
                                    {...textFieldPropFactory(t(`CostItemForm.unitCost`), [`unitCost`])}
                                    {...manageFieldPropFactory([`unitCost`], 'onChangeValue', 'value')}
                                    leftIcon={'$'}
                                    stacked
                                    precision={2}
                                    step={0.5}
                                    min={0}
                                />
                            </GridItem>
                        </Grid>
                        <Grid
                            columnGap={10}
                            gridAutoFlow='dense'
                            templateColumns={{
                                base: 'repeat(1, 1fr)',
                                md: 'repeat(2, 1fr)',
                            }}
                            rowGap={4}
                            mb={4}
                        >
                            <GridItem>
                                <NumberInput
                                    {...textFieldPropFactory(t(`CostItemForm.maxQuantityAllowed`), [
                                        `maxQuantityAllowed`,
                                    ])}
                                    {...manageFieldPropFactory([`maxQuantityAllowed`], 'onChangeValue', 'value')}
                                    stacked
                                    min={0}
                                    step={1}
                                    precision={0}
                                />
                            </GridItem>
                        </Grid>
                    </FormHeader>

                    <Box mt={6}>
                        <FormHeader id={id} label={t('CostItemForm.availableOptions')}>
                            {costItemOptions.map((u, index) => (
                                <Grid
                                    key={index}
                                    columnGap={10}
                                    gridAutoFlow='dense'
                                    templateColumns={{
                                        base: 'repeat(1, 1fr)',
                                        md: 'repeat(2, 1fr)',
                                    }}
                                    rowGap={4}
                                    mb={4}
                                >
                                    <TextInput
                                        {...textFieldPropFactory(t(`CostItemForm.option${index + 1}`), [
                                            `option${index + 1}`,
                                        ])}
                                        {...manageFieldPropFactory([`option${index + 1}`], 'onChangeText', 'value')}
                                        stacked
                                    />

                                    <SelectInput
                                        {...selectFieldPropFactory(t('CostItemForm.group'), [
                                            `option${index + 1}Group`,
                                        ])}
                                        {...manageFieldPropFactory(
                                            [`option${index + 1}Group`],
                                            'onChangeValue',
                                            'value'
                                        )}
                                        value={costItem ? costItem[`option${index + 1}Group`] : ''}
                                        defaultValue={0}
                                        stacked
                                        options={costItemGroups}
                                    />
                                </Grid>
                            ))}
                        </FormHeader>
                    </Box>

                    {costItemId && (
                        <AuditInfo
                            createdOn={costItem.createdOn}
                            createdBy={costItem.createdByFullName}
                            modifiedBy={costItem.modifiedByFullName}
                            modifiedOn={costItem.modifiedOn}
                        />
                    )}
                </>
            </Drawer>
        </>
    );
};

export default CostItemForm;
