import Switch from '@Components/Switch';
import TextInput from '@Components/TextInput';
import { ManageSwitchInputProps, ManageTextProps } from '@Hooks';
import { SyncBoard } from '@Icon';
import Header from '@Pages/EventDetails/components/Summary/components/Header';
import { PhoneCallStepProps } from '../../types';
import { Box, Collapse, Flex, Grid, GridItem } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

const Synchronization = React.forwardRef<HTMLDivElement, PhoneCallStepProps>(
    ({ id, manageFieldPropFactory, textFieldPropFactory, switchFieldPropFactory }) => {
        const { t } = useTranslation();
        const [isOpen, setIsOpen] = useState(false);

        return (
            <Box id={id} my={6}>
                <Flex alignContent={'center'} mb={6} onClick={() => setIsOpen(!isOpen)} cursor={'pointer'}>
                    <Header title={t('PhoneCall.subheader.synchronization')} icon={<SyncBoard />} />
                    <Box ml={'auto'}>{isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}</Box>
                </Flex>
                <Collapse in={isOpen}>
                    <Flex gap={4} direction={'column'}>
                        <Switch
                            {...switchFieldPropFactory(t('PhoneCall.syncedWithPowerschool'), [
                                'absenceSynchonizedWithPowerSchool',
                            ])}
                            {...manageFieldPropFactory<ManageSwitchInputProps>(
                                ['absenceSynchonizedWithPowerSchool'],
                                'onChangeSwitch',
                                'isChecked'
                            )}
                        />
                        <Grid
                            columnGap={10}
                            gridAutoFlow='dense'
                            templateColumns={{
                                base: 'repeat(1, 1fr)',
                                md: 'repeat(2, 1fr)',
                            }}
                            rowGap={4}
                            w={'full'}
                        >
                            <GridItem>
                                <TextInput
                                    bg='surface-primary'
                                    {...textFieldPropFactory(t('PhoneCall.psAbsenceId'), ['ucdsbPowerschoolabsenceId'])}
                                    {...manageFieldPropFactory<ManageTextProps>(
                                        ['ucdsbPowerschoolabsenceId'],
                                        'onChangeText',
                                        'value'
                                    )}
                                    stacked
                                />
                            </GridItem>
                            <GridItem>
                                <TextInput
                                    bg='surface-primary'
                                    {...textFieldPropFactory(t('PhoneCall.studentNumber'), ['studentNumber'])}
                                    {...manageFieldPropFactory<ManageTextProps>(
                                        ['studentNumber'],
                                        'onChangeText',
                                        'value'
                                    )}
                                    stacked
                                />
                            </GridItem>
                        </Grid>
                    </Flex>
                </Collapse>
            </Box>
        );
    }
);

export default Synchronization;
