import { Stack } from '@chakra-ui/react';
import FormHeader from '@Components/FormHeader';
import Switch from '@Components/Switch';
import TypeaheadInput from '@Components/TypeaheadInput';
import { ManageSwitchInputProps, ManageTextProps } from '@Hooks';
import { StepProps } from '@Pages/EventDetails/types';
import { useGetSubCategoriesQuery } from '@Redux/services/LookupApi';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ChartOfAccounts: FC<StepProps> = ({
    typeaheadPropFactory,
    manageFieldPropFactory,
    eventDetails,
    setEventDetails,
    switchFieldPropFactory,
    setDirtyData,
    setDirtyAccessor,
    dirtyData,
    dirtyAccessors,
    categories,
}) => {
    const { t } = useTranslation();
    const [prevCategoryId, setPrevCategoryId] = useState<string>();

    const { data: { data: subCategories = [] } = {} } = useGetSubCategoriesQuery(
        { parentCategoryId: eventDetails.categoryId, searchText: '' },
        { skip: !eventDetails.categoryId }
    );

    useEffect(() => {
        if (eventDetails.categoryId) {
            setPrevCategoryId(eventDetails.categoryId);
        }
        if (prevCategoryId && prevCategoryId !== eventDetails.categoryId) {
            setEventDetails({
                ...eventDetails,
                subCategoryId: undefined,
            });
            setDirtyData({ ...dirtyData, subCategoryId: undefined });
            setDirtyAccessor([...dirtyAccessors, ['subCategoryId']]);
        }
    }, [eventDetails.categoryId]);
    return (
        <FormHeader label={t('ChartOfAccounts.label')}>
            <Stack spacing={4}>
                <TypeaheadInput
                    {...typeaheadPropFactory(t('ChartOfAccounts.category'), ['categoryId'])}
                    {...manageFieldPropFactory<ManageTextProps>(['categoryId'], 'onSelection', 'value')}
                    options={categories}
                    col={1}
                />

                <TypeaheadInput
                    {...typeaheadPropFactory(t('ChartOfAccounts.subCategory'), ['subCategoryId'])}
                    {...manageFieldPropFactory<ManageTextProps>(['subCategoryId'], 'onSelection', 'value')}
                    options={subCategories}
                    col={1}
                />

                <Switch
                    {...switchFieldPropFactory(t('ChartOfAccounts.refundable'), ['isRefundable'])}
                    {...manageFieldPropFactory<ManageSwitchInputProps>(['isRefundable'], 'onChangeSwitch', 'isChecked')}
                    col={1}
                />
            </Stack>
        </FormHeader>
    );
};

export default ChartOfAccounts;
