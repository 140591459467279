import Table, { Column } from '@Components/Table';
import { useEffect, useMemo, useState } from 'react';
import { CellProps } from 'react-table';
import { useTranslation } from 'react-i18next';
import { Button, Pagination } from '@Components';
import { Box, Checkbox, Flex, HStack } from '@chakra-ui/react';
import { Export, Plus } from '@Icon';
import { useGetParticipantsMutation } from '@Redux/services/Student';
import { SortByProps } from '@Pages/Students';
import { useDebounce } from '@Hooks';
import Drawer from '@Components/Drawer';
import SelectInput from '@Components/SelectInput';
import { Search } from '@Components/Search';
import { useToast } from '@Hooks/useToast';
import { parseError, parseWarning } from '@Utilities';
import { IStudentListRecord } from '@Redux/services/Student/types';
import { useAddAllParticipantsToListMutation, useAddMembersToListMutation } from '@Redux/services/Participants';
import { useGetGenderTypesQuery, useGetInvitedGradesQuery, useGetSchoolsQuery } from '@Redux/services/LookupApi';
import FilterTextInput from '@Components/FilterTextInput';
import FilterSelectInput from '@Components/FilterSelectInput';
import ListPopover from '@Components/ListPopover';
import { BaseResponse, PagedResult } from '@Redux/services/commonTypes';
import { PAGE_SIZE_50 } from 'src/constants';
import { CommonMembersResponse } from '@Redux/services/Participants/types';
import { Breadcrumb } from '@Components/Breadcrumb';

type Props = {
    onSubmit: (newList: BaseResponse<CommonMembersResponse>) => void;
    onOpen: () => void;
    onClose: () => void;
    onCloseAll: () => void;
    isOpen: boolean;
    disableAdd?: boolean;
    listId: string;
    eventName?: string;
};

const activeGuardianOptions = [
    { key: 1, value: 'Yes' },
    { key: 2, value: 'No' },
];

const getActiveGuardianValue = (val) => {
    switch (val) {
        case '1':
            return true;
        case '2':
            return false;
        default:
            return undefined;
    }
};

const defaultSortBy = { id: 'fullName', desc: false };

const AddMembers: React.FC<Props> = ({
    isOpen,
    onOpen,
    onClose,
    onCloseAll,
    listId,
    eventName,
    onSubmit,
    disableAdd = false,
}) => {
    const { t } = useTranslation();

    const toast = useToast();

    const filterOptions = [
        { key: 'gender', value: t('AddMembers.Filters.gender') },
        { key: 'activeGuardian', value: t('AddMembers.Filters.activeGuardian') },
        { key: 'homeMunicipality', value: t('AddMembers.Filters.homeMunicipality') },
        { key: 'mailingMunicipality', value: t('AddMembers.Filters.mailingMunicipality') },
        { key: 'dropOffMunicipality', value: t('AddMembers.Filters.dropOffMunicipality') },
        { key: 'pickupMunicipality', value: t('AddMembers.Filters.pickupMunicipality') },
    ];
    const additionalColumnsOptions = [
        { key: 'age', value: t('AddMembers.age') },
        { key: 'gender', value: t('AddMembers.gender') },
        { key: 'grade', value: t('AddMembers.grade') },
        { key: 'homeRoom', value: t('AddMembers.homeRoom') },
        { key: 'homeRoomTeacher', value: t('AddMembers.homeRoomTeacher') },
        { key: 'studentId', value: t('AddMembers.studentId') },
    ];

    // const { isOpen, onOpen, onClose } = useDisclosure();

    const [
        mutate,
        {
            isLoading: isParticipantsLoading,
            data: { data: sData = [], pagedResult: pagedResult = {} as PagedResult } = {},
        },
    ] = useGetParticipantsMutation();
    const [createMembers, { isLoading: creatingMembers }] = useAddMembersToListMutation();
    const [createMembersAll, { isLoading: creatingMembersAll }] = useAddAllParticipantsToListMutation();
    const { data: { data: invitedGrades = [] } = {} } = useGetInvitedGradesQuery();
    const { data: { data: schools = [] } = {} } = useGetSchoolsQuery({});
    const { data: { data: genderOptions = [] } = {} } = useGetGenderTypesQuery();

    const [grade, setGrade] = useState('');
    const [age, setAge] = useState('');
    const [school, setSchool] = useState('');
    const [homeRoom, setHomeRoom] = useState('');
    const [homeRoomTeacher, setHomeRoomTeacher] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [gender, setGender] = useState('');
    const [activeGuardian, setActiveGuardian] = useState('');
    const [homeMunicipality, setHomeMunicipality] = useState('');
    const [mailingMunicipality, setMailingMunicipality] = useState('');
    const [dropOffMunicipality, setDropOffMunicipality] = useState('');
    const [pickupMunicipality, setPickupMunicipality] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(PAGE_SIZE_50);
    const [sortBy, setSortBy] = useState<SortByProps>(defaultSortBy);

    const [selectedRows, setSelectedRows] = useState<IStudentListRecord[]>([]);

    const [addMoreFilters, setAddFilters] = useState([]);
    const [additionalColumns, setAdditionalColumns] = useState([]);

    const getStudents = async (
        sortBy,
        currentPage,
        newPageSize,
        searchQuery,
        grade,
        age,
        school,
        homeRoom,
        homeRoomTeacher,
        gender,
        activeGuardian,
        homeMunicipality,
        mailingMunicipality,
        dropOffMunicipality,
        pickupMunicipality,
        resetControl,
        listId
    ) => {
        const obj = {
            sortBy: sortBy?.id,
            isDesc: sortBy?.desc,
            currentPage: currentPage,
            pageSize: newPageSize,
            searchText: searchQuery,
            grade: Number(grade) || undefined,
            age: Number.parseInt(age) || undefined,
            homeSchoolId: school || undefined,
            homeRoom: homeRoom,
            homeRoomTeacher: homeRoomTeacher,
            gender: Number(gender) || undefined,
            activeGuardianship: getActiveGuardianValue(activeGuardian),
            homeMunicipality,
            mailingMunicipality,
            dropOffMunicipality,
            pickupMunicipality,
            listId,
        };
        try {
            const response = await mutate(obj).unwrap();
            if (response && resetControl) {
                setCurrentPage(1);
                setSortBy(defaultSortBy);
            }
        } catch (e) {
            parseError(toast, e);
        }
    };

    const debouncedResults = useDebounce(() =>
        getStudents(
            defaultSortBy,
            1,
            pageSize,
            searchQuery,
            grade,
            age,
            school,
            homeRoom,
            homeRoomTeacher,
            gender,
            activeGuardian,
            homeMunicipality,
            mailingMunicipality,
            dropOffMunicipality,
            pickupMunicipality,
            true,
            listId
        )
    );

    useEffect(() => {
        if (!isOpen) return;
        debouncedResults();
    }, [
        searchQuery,
        grade,
        age,
        school,
        homeRoom,
        homeRoomTeacher,
        gender,
        activeGuardian,
        homeMunicipality,
        mailingMunicipality,
        dropOffMunicipality,
        pickupMunicipality,
        isOpen,
    ]);

    const handlePageChange = (newPage: number) => {
        if (isParticipantsLoading) return;
        setCurrentPage(newPage);
        getStudents(
            sortBy,
            newPage,
            pageSize,
            searchQuery,
            grade,
            age,
            school,
            homeRoom,
            homeRoomTeacher,
            gender,
            activeGuardian,
            homeMunicipality,
            mailingMunicipality,
            dropOffMunicipality,
            pickupMunicipality,
            false,
            listId
        );
    };

    const handleSort = (newSort) => {
        if (isParticipantsLoading || !newSort?.[0]) return;
        setSortBy(newSort[0]);
        getStudents(
            newSort[0],
            currentPage,
            pageSize,
            searchQuery,
            grade,
            age,
            school,
            homeRoom,
            homeRoomTeacher,
            gender,
            activeGuardian,
            homeMunicipality,
            mailingMunicipality,
            dropOffMunicipality,
            pickupMunicipality,
            false,
            listId
        );
    };

    const selectedRowsIds = selectedRows.map((i) => i.studentId);
    const handleSelectAll = (table) => {
        const studentIds = table?.data?.map((i) => i.studentId);
        const isAllRowsSelected = table.data.every((r) => selectedRowsIds.includes(r.studentId));
        if (isAllRowsSelected) {
            setSelectedRows((rows) => rows.filter((r) => !studentIds.includes(r.studentId)));
        } else {
            setSelectedRows((rows) => [...rows, ...table.data.filter((r) => !selectedRowsIds.includes(r.studentId))]);
        }
    };

    const handleRowSelection = (dataRow) => {
        const isSelected = selectedRowsIds.includes(dataRow?.row?.original?.studentId);
        setSelectedRows((rows) =>
            !isSelected
                ? [...rows, dataRow?.original]
                : rows?.filter((r) => r?.studentId !== dataRow?.row?.original?.studentId)
        );
    };

    const columns = useMemo<Column[]>(
        () =>
            [
                {
                    Header: (table) => {
                        return (
                            <Checkbox
                                colorScheme={'teal'}
                                isChecked={
                                    table?.data?.length > 0
                                        ? table.data.every((r) => selectedRowsIds.includes(r.studentId))
                                        : false
                                }
                                onChange={() => handleSelectAll(table)}
                            />
                        );
                    },
                    accessor: 'actions',
                    disableSortBy: true,
                    Cell: (row: CellProps<IStudentListRecord>) => {
                        return (
                            <Checkbox
                                colorScheme={'teal'}
                                isChecked={selectedRowsIds.includes(row.row.original.studentId)}
                                onChange={() => handleRowSelection(row)}
                            />
                        );
                    },
                },
                {
                    Header: t('AddMembers.preferredFullName'),
                    accessor: 'fullName',
                    headerAlign: 'left',
                },
                {
                    Header: t('AddMembers.legalFirstName'),
                    accessor: 'legalFirstName',
                    headerAlign: 'left',
                },
                {
                    Header: t('AddMembers.legalLastName'),
                    accessor: 'legalLastName',
                    headerAlign: 'left',
                },
                {
                    Header: t('AddMembers.email'),
                    accessor: 'emailAddress',
                    headerAlign: 'left',
                },
                {
                    Header: t('AddMembers.homeSchool'),
                    accessor: 'homeSchool',
                    headerAlign: 'left',
                },
                // {
                //     Header: t('AddMembers.activeGuardian'),
                //     accessor: 'activeGuardianship',
                //     headerAlign: 'left',
                //     Cell: (row) => <>{row?.activeGuardianship === true ? 'Yes' : 'No'}</>,
                // }, // Hidden as per UCR3D-1260
                {
                    Header: t('AddMembers.gender'),
                    accessor: 'gender',
                    headerAlign: 'left',
                    Cell: (r) => <>{genderOptions.find((g) => g.key === r.value)?.value || '--'}</>,
                    show: additionalColumns.includes(t('gender')),
                },
                {
                    Header: t('AddMembers.grade'),
                    accessor: 'grade',
                    headerAlign: 'left',
                    Cell: (r) => <>{invitedGrades.find((g) => g.key === r.value)?.value || ''}</>,
                    show: additionalColumns.includes(t('grade')),
                },
                {
                    Header: t('AddMembers.studentId'),
                    accessor: 'studentId',
                    show: additionalColumns.includes(t('studentId')),
                    headerAlign: 'left',
                },
                {
                    Header: t('AddMembers.age'),
                    accessor: 'age',
                    show: additionalColumns.includes(t('age')),
                    headerAlign: 'left',
                },
                {
                    Header: t('AddMembers.homeRoom'),
                    accessor: 'homeRoom',
                    show: additionalColumns.includes(t('homeRoom')),
                    headerAlign: 'left',
                },
                {
                    Header: t('AddMembers.homeRoomTeacher'),
                    accessor: 'homeRoomTeacher',
                    show: additionalColumns.includes(t('homeRoomTeacher')),
                    headerAlign: 'left',
                },
            ] as Column[],
        [selectedRows, additionalColumns, invitedGrades, genderOptions]
    );

    const handleSubmitAll = async () => {
        const obj = {
            listId,
            sortBy: sortBy?.id,
            isDesc: sortBy?.desc,
            currentPage: currentPage,
            pageSize: pagedResult?.rowCount || pageSize,
            searchText: searchQuery,
            grade: Number(grade) || undefined,
            age: Number.parseInt(age) || undefined,
            homeSchoolId: school || undefined,
            homeRoom: homeRoom,
            homeRoomTeacher: homeRoomTeacher,
            gender: Number(gender) || undefined,
            activeGuardianship: getActiveGuardianValue(activeGuardian),
            homeMunicipality,
            mailingMunicipality,
            dropOffMunicipality,
            pickupMunicipality,
        };

        await createMembersAll(obj)
            .unwrap()
            .then((response) => {
                toast({
                    status: 'success',
                    description: t('AddMembers.listCreated'),
                    isClosable: true,
                });
                setSelectedRows([]);
                onSubmit(response);
                onClose();
            })
            .catch((e) => parseError(toast, e));
    };

    const handleSubmit = async () => {
        const studentList = selectedRows.map((s) => s?.contactId);
        await createMembers({
            listId,
            studentList,
        })
            .unwrap()
            .then((response) => {
                if (response?.warningResult?.showAlert) {
                    parseWarning(toast, response);
                } else {
                    toast({
                        status: 'success',
                        description: t('AddMembers.listCreated'),
                        isClosable: true,
                    });
                }
                setSelectedRows([]);
                onSubmit(response);
                onClose();
            })
            .catch((e) => {
                parseError(toast, e);
            });
    };

    const renderFilters = (filters: string[]) => {
        const filterList = [];
        filters.forEach((f) => {
            switch (true) {
                case f === 'gender':
                    filterList.push(
                        <FilterSelectInput
                            placeholder={t('AddMembers.Filters.gender')}
                            options={genderOptions}
                            value={gender}
                            onChangeValue={(e) => setGender(e.toString())}
                            defaultOption={false}
                            containerWidth='120px'
                        />
                    );
                    break;
                case f === 'activeGuardian':
                    filterList.push(
                        <FilterSelectInput
                            placeholder={t('AddMembers.Filters.activeGuardian')}
                            options={activeGuardianOptions}
                            value={activeGuardian}
                            onChangeValue={(e) => setActiveGuardian(e.toString())}
                            defaultOption={false}
                        />
                    );
                    break;
                case f === 'homeMunicipality':
                    filterList.push(
                        <FilterTextInput
                            isLocked={false}
                            placeholder={t('AddMembers.Filters.homeMunicipality')}
                            value={homeMunicipality}
                            onChangeText={setHomeMunicipality}
                        />
                    );
                    break;
                case f === 'mailingMunicipality':
                    filterList.push(
                        <FilterTextInput
                            isLocked={false}
                            placeholder={t('AddMembers.Filters.mailingMunicipality')}
                            value={mailingMunicipality}
                            onChangeText={setMailingMunicipality}
                        />
                    );
                    break;
                case f === 'dropOffMunicipality':
                    filterList.push(
                        <FilterTextInput
                            isLocked={false}
                            placeholder={t('AddMembers.Filters.dropOffMunicipality')}
                            value={dropOffMunicipality}
                            onChangeText={setDropOffMunicipality}
                        />
                    );
                    break;
                case f === 'pickupMunicipality':
                    filterList.push(
                        <FilterTextInput
                            isLocked={false}
                            placeholder={t('AddMembers.Filters.pickupMunicipality')}
                            value={pickupMunicipality}
                            onChangeText={setPickupMunicipality}
                        />
                    );
                    break;
            }
        });
        return filterList;
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPageSize(pageSize);
        setCurrentPage(1);
        getStudents(
            sortBy,
            currentPage,
            pageSize,
            searchQuery,
            grade,
            age,
            school,
            homeRoom,
            homeRoomTeacher,
            gender,
            activeGuardian,
            homeMunicipality,
            mailingMunicipality,
            dropOffMunicipality,
            pickupMunicipality,
            false,
            listId
        );
    };

    const breadcrumbs = useMemo(
        () => [
            {
                label: '',
                inactiveLable: t('AddMembers.breadcrumb.label'),
                onClick: onClose,
                isCurrentPage: !isOpen,
            },
            {
                label: t('AddMembers.eventNameTeamMembers', { name: eventName }),
                inactiveLable: '',
                onClick: () => '',
                isCurrentPage: isOpen,
            },
        ],
        [isOpen]
    );

    const loading = isParticipantsLoading || creatingMembers || creatingMembersAll;

    return (
        <>
            <Button variant='light' gap={2} px={4} py={0} pl={3} onClick={() => onOpen()} isDisabled={disableAdd}>
                <Plus width={20} height={20} /> {t('Members.addMembers')}
            </Button>
            <Drawer
                size={'xl'}
                isOpen={isOpen}
                onClose={onCloseAll}
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSubmit();
                }}
                isForm={true}
                title={eventName ? <Breadcrumb items={breadcrumbs} /> : t('AddMembers.headerTitle')}
                subtitle={t('AddMembers.subTitle')}
                footer={
                    <Flex w='100%' justifyContent={'space-between'}>
                        <Button isDisabled={loading} leftIcon={<Plus />} variant='primary' onClick={handleSubmitAll}>
                            {t('AddMembers.addAll', { count: pagedResult?.rowCount })}
                        </Button>
                        <HStack>
                            {selectedRows?.length > 0 && (
                                <Button isDisabled={loading} type='submit' leftIcon={<Plus />} variant='primary'>
                                    {t('AddMembers.addSelected', { count: selectedRows?.length })}
                                </Button>
                            )}
                            <Button variant='light' isDisabled={loading} onClick={onClose}>
                                {t('AddMembers.finish')}
                            </Button>
                        </HStack>
                    </Flex>
                }
            >
                <Box
                    overflow={'hidden'}
                    borderRadius={10}
                    borderWidth={2}
                    borderStyle='solid'
                    borderColor='border-primary'
                    p={4}
                    h='inherit'
                    display='flex'
                    flexDirection='column'
                >
                    <Box display='flex' mb={4} columnGap={6}>
                        <Box display='flex' flexDirection='row' flexWrap='wrap' rowGap={2} columnGap={4}>
                            <Search
                                size={'md'}
                                query={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                iconPosition={'left'}
                            />
                            <FilterSelectInput
                                placeholder={t('AddMembers.Filters.homeSchool')}
                                options={schools}
                                value={school}
                                onChangeValue={(e) => setSchool(e.toString())}
                                defaultOption={false}
                            />
                            <FilterSelectInput
                                placeholder={t('AddMembers.Filters.grade')}
                                options={invitedGrades}
                                value={grade}
                                onChangeValue={(e) => setGrade(e.toString())}
                                defaultOption={false}
                            />
                            <FilterTextInput
                                isLocked={false}
                                placeholder={t('AddMembers.Filters.age')}
                                value={age}
                                onChangeText={setAge}
                                containerWidth={'80px'}
                            />
                            <FilterTextInput
                                placeholder={t('AddMembers.Filters.homeRoom')}
                                value={homeRoom}
                                onChangeText={setHomeRoom}
                                isLocked={false}
                            />
                            <FilterTextInput
                                placeholder={t('AddMembers.Filters.homeRoomTeacher')}
                                value={homeRoomTeacher}
                                onChangeText={setHomeRoomTeacher}
                                isLocked={false}
                            />
                            {renderFilters(addMoreFilters)}
                            <ListPopover
                                aria-label={t('AddMembers.more')}
                                options={filterOptions.filter((o) => !(addMoreFilters.indexOf(o.key) > -1))}
                                onSelectOption={(value) => {
                                    setAddFilters((filters) => [...filters, value]);
                                }}
                            />
                        </Box>
                        <Box
                            display='flex'
                            flex='1 0 auto'
                            height='fit-content'
                            columnGap={2}
                            alignItems={'center'}
                            justifyContent={'flex-end'}
                        >
                            <Box>
                                <SelectInput
                                    label=''
                                    placeholder={t('AddMembers.Filters.columns')}
                                    onChangeValue={(value) => {
                                        setAdditionalColumns((columns) => [...columns, value]);
                                    }}
                                    value={undefined}
                                    options={additionalColumnsOptions.filter(
                                        (o) => !(additionalColumns.indexOf(o.key) > -1)
                                    )}
                                    defaultOption={false}
                                />
                            </Box>
                            <Export width={24} height={24} onClick={() => console.log()} />
                        </Box>
                    </Box>
                    <Table
                        manualPagination={true}
                        manualSortBy={true}
                        enableRowSelection
                        columns={columns}
                        rawData={sData ?? []}
                        isLoading={isParticipantsLoading}
                        emptyMessage={t('AddMembers.emptyTable')}
                        onSortChange={handleSort}
                        stickyHeader
                        stickyColumns={2}
                    />
                    {!pagedResult?.pageCount ? null : (
                        <Box p={2}>
                            <Pagination
                                currentPage={currentPage}
                                onPageChange={handlePageChange}
                                pageSize={pageSize}
                                onPageSizeChange={handlePageSizeChange}
                                totalPages={pagedResult?.pageCount}
                                totalResults={pagedResult?.rowCount}
                            />
                        </Box>
                    )}
                </Box>
            </Drawer>
        </>
    );
};

export default AddMembers;
