import { Box, Flex, Grid } from '@chakra-ui/react';
import FormHeader from '@Components/FormHeader';
import TextInput from '@Components/TextInput';
import { StepProps } from '@Pages/EventDetails/types';
import Header from './components/Header';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Switch from '@Components/Switch';
import Textarea from '@Components/Textarea';
import { useGetSchoolsQuery } from '@Redux/services/LookupApi';
import SelectInput from '@Components/SelectInput';
import SvgSummary from '@Icon/Summary';
import TypeaheadInput from '@Components/TypeaheadInput';
import { ManageSwitchInputProps, ManageTextProps } from '@Hooks';

const Summary: React.FC<StepProps> = ({
    id,
    manageFieldPropFactory,
    textFieldPropFactory,
    textAreaPropFactory,
    typeaheadPropFactory,
    selectFieldPropFactory,
    switchFieldPropFactory,
    organizedBy,
}) => {
    const { t } = useTranslation();
    const { data: { data: schools = [] } = {} } = useGetSchoolsQuery({});

    return (
        <FormHeader
            id={id}
            header={<Header title={t('Header.summary')} icon={<SvgSummary />} />}
            label={t('Summary.headerLabel')}
        >
            <Box pb={4}>
                <TextInput
                    {...textFieldPropFactory(t('Summary.name'), ['name'])}
                    {...manageFieldPropFactory<ManageTextProps>(['name'], 'onChangeText', 'value')}
                    col={1}
                />
            </Box>
            <Grid
                column={{ md: 2, sm: 1 }}
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                }}
                rowGap={4}
            >
                <TextInput
                    {...textFieldPropFactory(t('Summary.eventCode'), ['eventCode'])}
                    {...manageFieldPropFactory<ManageTextProps>(['eventCode'], 'onChangeText', 'value')}
                />

                <TypeaheadInput
                    {...manageFieldPropFactory<ManageTextProps>(['teamId'], 'onSelection', 'value')}
                    {...typeaheadPropFactory(t('Summary.school'), ['teamId'])}
                    options={schools}
                />

                <SelectInput
                    {...selectFieldPropFactory(t('Summary.organizedBy'), ['organizedById'])}
                    {...manageFieldPropFactory<ManageTextProps>(['organizedById'], 'onChangeValue', 'value')}
                    options={organizedBy}
                />

                <TextInput
                    {...manageFieldPropFactory<ManageTextProps>(['organizingTeachers'], 'onChangeText', 'value')}
                    {...textFieldPropFactory(t('Summary.organizingTeacher'), ['organizingTeachers'])}
                />

                <TextInput
                    {...textFieldPropFactory(t('Summary.volunteers'), ['volunteers'])}
                    {...manageFieldPropFactory<ManageTextProps>(['volunteers'], 'onChangeText', 'value')}
                />

                <Flex>
                    <Switch
                        {...switchFieldPropFactory(t('Summary.readyToPublish'), ['readyToPublish'])}
                        {...manageFieldPropFactory<ManageSwitchInputProps>(
                            ['readyToPublish'],
                            'onChangeSwitch',
                            'isChecked'
                        )}
                    />
                    <Switch
                        {...switchFieldPropFactory(t('Summary.readyToClose'), ['readyToClose'])}
                        {...manageFieldPropFactory<ManageSwitchInputProps>(
                            ['readyToClose'],
                            'onChangeSwitch',
                            'isChecked'
                        )}
                    />
                </Flex>
            </Grid>
            <Box pt={4}>
                <Textarea
                    {...textAreaPropFactory(t('Offers.Description'), ['offerDescription'])}
                    {...manageFieldPropFactory<ManageTextProps>(['offerDescription'], 'onChangeText', 'value')}
                    col={1}
                />
            </Box>
        </FormHeader>
    );
};

export default Summary;
