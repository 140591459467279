import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';

interface Step {
    header: string;
    subHeader: string;
    active: boolean;
}
interface IProps {
    steps: Step[];
    disabled?: boolean;
}

const Steps: FC<IProps> = ({ steps, disabled }) => {
    return (
        <Flex>
            {steps.map((step) => (
                <HStack
                    key={step?.header}
                    mr={4}
                    minH={42}
                    minW={{ base: `${100 / steps.length - 3}%` }}
                    borderTopColor={
                        disabled ? 'surface-tertiary' : step.active ? 'surface-brand-primary' : 'text-invert'
                    }
                    borderTopWidth={step.active ? '8px' : '2px'}
                    minWidth='100px'
                    justifyContent='start'
                >
                    <Box mt={!step.active ? '6px' : '0px'}>
                        <Text
                            textStyle={step.active ? 'sm-semibold' : 'sm-medium'}
                            mt={2}
                            mb={0}
                            color={disabled && 'text-disabled'}
                        >
                            {step.header}
                        </Text>
                        <Text textStyle='sm-normal' color={disabled ? 'text-disabled' : 'text-secondary'}>
                            {step.subHeader}
                        </Text>
                    </Box>
                </HStack>
            ))}
        </Flex>
    );
};

export default Steps;
