import Table, { Column } from '@Components/Table';
import { Dispatch, FC, SetStateAction, useMemo, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import RequiredCell from '@Components/RequiredCell';
import TableActions from '@Components/TableActions';
import { EventResponseDetail } from '@Redux/services/EventResponse/types';
import ActionCell from './components/ActionCell';
import { Box } from '@chakra-ui/react';
import { CostItemResponseForm } from './components/CostItemResponseForm';
import { CostItemResponseAnswerDetail } from '@Redux/services/Event/types';
import CostItemForm from '@Components/CostItemForm';
import CurrencyCell from '@Components/CurrencyCell';
import { cloneDeep, groupBy } from 'lodash';

interface IProps {
    id: string;
    isDrawer: boolean;
    eventResponse: EventResponseDetail;
    setEventResponse: Dispatch<SetStateAction<EventResponseDetail>>;
    isEditable: boolean;
    costItemResponseLabel?: string | React.ReactNode;
    costItemLabel?: string | React.ReactNode;
    isOpenCostItemForm: boolean;
    onCloseCostItemForm: () => void;
    onOpenCostItemForm: () => void;
    isOpenCostItemResponseForm: boolean;
    onCloseCostItemResponseForm: () => void;
    onOpenCostItemResponseForm: () => void;
    onCloseAllResponseDrawers: () => void;
}

const CostItemTable: FC<IProps> = ({
    id,
    isDrawer,
    eventResponse,
    costItemResponseLabel,
    costItemLabel,
    isEditable,
    setEventResponse,
    isOpenCostItemForm,
    onCloseCostItemForm,
    onOpenCostItemForm,
    isOpenCostItemResponseForm,
    onCloseCostItemResponseForm,
    onOpenCostItemResponseForm,
    onCloseAllResponseDrawers,
}) => {
    const [costItemResponses, setCostItemResponses] = useState<Partial<CostItemResponseAnswerDetail>[]>([]);

    const { t } = useTranslation();
    // const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedCostItem, setSelectedCostItem] = useState<CostItemResponseAnswerDetail>();
    // const { isOpen: isOpenCostItemForm, onOpen: onOpenCostItemForm, onClose: onCloseCostItemForm } = useDisclosure();

    const columns = useMemo<Column[]>(
        () => [
            {
                Header: t('EventResponseDetail.CostItemTable.name'),
                accessor: 'name',
                headerAlign: 'left',
            },
            {
                Header: t('EventResponseDetail.CostItemTable.quantity'),
                accessor: 'quantity',
                headerAlign: 'right',
            },
            {
                Header: t('EventResponseDetail.CostItemTable.unitCost'),
                accessor: 'unitCost',
                Cell: CurrencyCell,
                headerAlign: 'right',
            },
            {
                Header: t('EventResponseDetail.CostItemTable.selectedOption'),
                accessor: 'selectedOptions',
                headerAlign: 'left',
            },
            {
                Header: t('EventResponseDetail.CostItemTable.required'),
                accessor: 'required',
                Cell: RequiredCell,
                headerAlign: 'left',
            },
            {
                Header: <>&nbsp;</>,
                accessor: 'actions',
                headerAlign: 'center',
                disableSortBy: true,
                Cell: ActionCell,
            },
        ],
        []
    );

    useEffect(() => {
        if (!eventResponse?.costItemResponses?.costItems) return;
        const costItemsGroupedByRequiredness = groupBy(
            cloneDeep(eventResponse?.costItemResponses?.costItems),
            'required'
        );
        const newCostItems = [];
        Object.keys(costItemsGroupedByRequiredness).map((key, groupIndex) => {
            newCostItems.push(
                ...costItemsGroupedByRequiredness[key].map((item, index) => {
                    item.displayOrder = parseFloat(`${groupIndex + 1}.${index + 1}`);
                    return item;
                })
            );
        });
        setCostItemResponses(newCostItems);
    }, [eventResponse]);

    const handleUpdate = (responseData: EventResponseDetail) => {
        setEventResponse({
            ...eventResponse,
            status: responseData.status,
            payment: {
                ...eventResponse.payment,
                totalAmount: responseData.payment.totalAmount,
            },
            costItemResponses: {
                ...responseData.costItemResponses,
            },
        });
    };

    const handleCostItemFormOpen = () => {
        onOpenCostItemResponseForm();
        onOpenCostItemForm();
    };
    return (
        <Box id={id}>
            <Table
                action={<TableActions label={t('EventResponseDetail.CostItemTable.header')} />}
                manualSortBy={false}
                columns={columns}
                rawData={costItemResponses ?? []}
                emptyMessage={t('EventResponseDetail.CostItemTable.emptyTable')}
                onEditHandler={(costItem) => {
                    setSelectedCostItem(costItem.original);
                    onOpenCostItemResponseForm();
                }}
                userProps={{
                    onEdit: (costItem) => {
                        setSelectedCostItem(costItem);
                        onOpenCostItemResponseForm();
                    },
                    isEditable: true,
                }}
            />
            {isOpenCostItemResponseForm && (
                <CostItemResponseForm
                    size={isDrawer ? 'xl' : 'lg'}
                    label={costItemResponseLabel}
                    onCostItemClick={handleCostItemFormOpen}
                    onUpdate={handleUpdate}
                    costItemResponse={selectedCostItem}
                    isOpen={isOpenCostItemResponseForm}
                    costItems={eventResponse?.costItems?.costItemsInfo}
                    onClose={onCloseAllResponseDrawers}
                    onCancel={onCloseCostItemResponseForm}
                    isEditable={isEditable}
                />
            )}
            {isOpenCostItemForm && (
                <CostItemForm
                    size={isDrawer ? 'xl' : 'lg'}
                    label={costItemLabel}
                    isEditable={false}
                    costItemId={selectedCostItem?.costItemId}
                    isOpen={isOpenCostItemForm}
                    onClose={onCloseAllResponseDrawers}
                    onCancel={onCloseCostItemForm}
                    eventStatus={eventResponse.event?.status}
                    eventId={eventResponse.event.eventId}
                />
            )}
        </Box>
    );
};

export default CostItemTable;
