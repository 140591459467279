import { Box } from '@chakra-ui/react';
import { CellProps } from 'react-table';
import { useTranslation } from 'react-i18next';
import EditIcon from '@Components/EditIcon';
import { CostItemResponse } from '@Redux/services/Event/types';

const ActionCell: React.FC<
    CellProps<CostItemResponse> & {
        isEditable: boolean;
    }
> = ({ row, onEdit }) => {
    const { t } = useTranslation();

    return (
        <Box>
            <EditIcon aria-label={t('EditIcon.title')} onClick={() => onEdit(row.original)} />
        </Box>
    );
};

export default ActionCell;
