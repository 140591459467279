import * as React from 'react';
function SvgForum(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <mask
                id='forum_svg__a'
                style={{
                    maskType: 'alpha',
                }}
                maskUnits='userSpaceOnUse'
                x={0}
                y={0}
                width={24}
                height={24}
            >
                <path fill='#D9D9D9' d='M0 0h24v24H0z' />
            </mask>
            <g mask='url(#forum_svg__a)'>
                <path
                    d='M7.03 18.31a1.107 1.107 0 01-1.137-1.137v-2.137h13.173V5.874h2.137a1.1 1.1 0 01.81.327 1.1 1.1 0 01.328.81v12.6c0 .508-.233.861-.7 1.059-.466.197-.879.116-1.24-.245l-2.114-2.114H7.03zm-1.275-5.274l-2.163 2.156c-.36.36-.773.442-1.237.245-.464-.198-.696-.551-.696-1.06V2.774a1.1 1.1 0 01.327-.81 1.1 1.1 0 01.81-.328h13.132a1.1 1.1 0 01.81.327 1.1 1.1 0 01.328.81v9.126a1.1 1.1 0 01-.327.81 1.1 1.1 0 01-.81.328H5.754zm9.036-2.275V3.91H3.934v6.85h10.857z'
                    fill='#171923'
                />
            </g>
        </svg>
    );
}
export default SvgForum;
