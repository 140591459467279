import { FC, useEffect, useRef, useState } from 'react';
import { Button, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface IProps {
    noteText: string;
    isExpanded: boolean;
    toggleExpand?: () => void;
}

const NotesText: FC<IProps> = ({ noteText, isExpanded, toggleExpand }) => {
    const { t } = useTranslation();
    const textRef = useRef(null);
    const [isClamped, setIsClamped] = useState(false);
    const [noOfLines, setNoOfLines] = useState(1);

    const checkClamped = () => {
        if (textRef.current) {
            const { scrollHeight, clientHeight } = textRef.current;
            setIsClamped(scrollHeight > clientHeight);
            setNoOfLines(isExpanded ? undefined : 1);
        }
    };

    useEffect(() => {
        setNoOfLines(1);
        const timeoutId = setTimeout(checkClamped, 0);
        return () => clearTimeout(timeoutId);
    }, [noteText, isExpanded]);

    return (
        <>
            <Text ref={textRef} noOfLines={noOfLines} whiteSpace='pre-line'>
                {noteText}
            </Text>
            {isClamped && (
                <Button display='block' size='sm' variant='link' onClick={toggleExpand}>
                    <Text textStyle='xs-normal'>
                        {isExpanded ? t('ExpandableText.collapse') : t('ExpandableText.expand')}
                    </Text>
                </Button>
            )}
        </>
    );
};

export default NotesText;
