import { AuditInfo } from '@Components/AuditInfo';
import Drawer from '@Components/Drawer';
import {
    useLazyGetPhoneCallDetailsQuery,
    useUpdatePhoneCallMutation,
    useMarkCallAsCompletedMutation,
} from '@Redux/services/Absence';
import { useEffect, FC, useMemo, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useForms } from '@Hooks';
import { IPhoneCallDetails } from '@Redux/services/Absence/types';
import { Accessor, getErrorsAssociatedWithFields, getMutableForAccessor, parseError, parseWarning } from '@Utilities';
import { DrawerFooterActions } from '@Components/DrawerFooterActions';
import { BasicLookup } from '@Redux/services/LookupApi/types';
import { isUndefined } from 'lodash';
import { useToast } from '@Hooks/useToast';
import { editableFieldsForPhoneCall, PhoneCallStatusTypes } from './types';
import { Validator } from './validator';
import PhoneCallStatus from './components/PhoneCallStatus';
import AbsenceRecord from './components/AbsenceRecord';
import ContactLog from './components/ContactLog';
import Guardians from './components/Guardians';
import Synchronization from './components/Synchronization';
import Header from './components/Header';
import { PhoneCallFormData } from '@Pages/PhoneCalls/types';
import { useGetPhoneCallActionTypesQuery } from '@Redux/services/LookupApi';

type IProps = {
    id: string;
    isOpen: boolean;
    onClose: (refresh?: boolean) => void;
    absenceReasonCodes: BasicLookup[];
    formState?: [PhoneCallFormData[], Dispatch<SetStateAction<PhoneCallFormData[]>>];
};

const PhoneCallDetailsDrawer: FC<IProps> = ({ isOpen, onClose, id, absenceReasonCodes, formState }) => {
    const [getPhoneCallDetails, { isLoading }] = useLazyGetPhoneCallDetailsQuery();
    const [updatePhoneCall, { isLoading: isSaving }] = useUpdatePhoneCallMutation();
    const [markCallCompleted, { isLoading: markCompleteLoading }] = useMarkCallAsCompletedMutation();
    const [formDataList, setFormDataList] = formState ?? [];

    const { data: { data: actionTypes = [] } = {} } = useGetPhoneCallActionTypesQuery();

    const { t } = useTranslation();
    const toast = useToast();

    const getCommonProps = (accessorPath: Accessor[]) => {
        const accesor = accessorPath.join('.');
        const item = editableFieldsForPhoneCall[accesor];
        const hasStatus = !isUndefined(item) && item.editableStatus?.indexOf(phoneCallData?.statusCode) > -1;
        const isLocked = isUndefined(item) ? true : !hasStatus;
        const isRequired = hasStatus && item.required;
        return {
            isLocked,
            isRequired,
        };
    };
    const {
        formData: phoneCallData,
        manageFieldPropFactory,
        selectFieldPropFactory,
        textFieldPropFactory,
        typeaheadPropFactory,
        switchFieldPropFactory,
        setFormData: setPhoneCallDetail,
        errors,
        isDirty,
        setIsDirty,
        setErrors,
    } = useForms<IPhoneCallDetails>(undefined, getCommonProps);
    const validate = () => {
        const newErrors = { ...errors };
        const nextErrors: Record<string, string> = {};
        const validationRules = Validator(t);

        for (const rule of validationRules) {
            const { accessorPath, validator, message, required } = rule;

            const finalMutable = getMutableForAccessor(phoneCallData, accessorPath);
            const finalProperty = accessorPath[accessorPath.length - 1];

            if (required && !validator(finalMutable[finalProperty])) {
                nextErrors[accessorPath.join('.')] = message;
            } else {
                newErrors[accessorPath.join('.')] && delete newErrors[accessorPath.join('.')];
            }
        }
        setErrors({ ...newErrors, ...nextErrors });
        if (Object.keys(nextErrors).length) {
            toast({
                status: 'error',
                description: t('PhoneCallDetails.toastErrorDescription'),
            });
            return false;
        }
        return true;
    };

    const phoneCallRequiredFields = useMemo(() => {
        if (!phoneCallData) return;
        const newPhoneCallRequiredFields = {};
        const validatons = Validator(t);
        validatons.map((validaton) => {
            newPhoneCallRequiredFields[validaton.accessorPath.join('.')] = validaton;
        });
        return newPhoneCallRequiredFields;
    }, [phoneCallData]);

    const handleGetPhoneCallDetails = async () => {
        if (!id) return;
        await getPhoneCallDetails({ phoneCallId: id })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                const singleFormData = formDataList?.find((i) => i.activityId === response.data.activityId);
                const absenceReason = singleFormData?.absenceReason
                    ? Number(singleFormData.absenceReason)
                    : response?.data?.absenceReason ?? 0;
                const absenceDuration = singleFormData?.absenceDuration
                    ? Number(singleFormData.absenceDuration)
                    : response?.data?.duration < 480
                    ? 0
                    : 480;
                console.log(absenceDuration);
                const data = {
                    ...response.data,
                    absenceReason: absenceReason,
                    duration: absenceDuration,
                };
                setPhoneCallDetail(data);
            })
            .catch((error) => {
                parseError(toast, error);
            });
    };
    const handleUpdatePhoneCall = () => {
        if (!validate()) return;

        updatePhoneCall({
            phoneCallId: phoneCallData.activityId,
            actionType: phoneCallData.action,
            duration: phoneCallData.duration,
            callFrom: phoneCallData.fromId === '' ? null : phoneCallData.fromId,
            callTo: phoneCallData.toId === '' ? null : phoneCallData.toId,
            absenceReason: phoneCallData.absenceReason,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                setPhoneCallDetail(response.data);
                setIsDirty(false);
                if (phoneCallData.absenceReason) {
                    handleMarkAsComplete();
                } else {
                    handleClose();
                }
            })
            .catch((err) => parseError(toast, err));
    };
    const handleMarkAsComplete = () => {
        markCallCompleted({
            phoneCallId: phoneCallData.activityId,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                setPhoneCallDetail({ ...response.data });
                handleClose(true);
            })
            .catch((err) => {
                if (err?.data?.errors) {
                    setErrors(getErrorsAssociatedWithFields(err.data.errors, Object.keys(phoneCallRequiredFields)));
                }

                parseError(toast, err);
            });
    };

    useEffect(() => {
        handleGetPhoneCallDetails();
    }, [id]);

    const handleClose = (refresh?: boolean) => {
        const newData = formDataList?.map((i) => {
            if (i.activityId === id) {
                return {
                    ...i,
                    action: phoneCallData.action ? actionTypes.find((x) => x.key === phoneCallData.action).value : null,
                    absenceReason: phoneCallData.absenceReason,
                    absenceDuration: phoneCallData.duration,
                };
            }
            return i;
        });
        if (newData) {
            setFormDataList([...newData]);
        }
        onClose(refresh);
    };

    return (
        <Drawer
            isLoading={isLoading || !phoneCallData}
            isOpen={isOpen}
            onClose={handleClose}
            title={
                <Header
                    guardianHasMfrAccount={phoneCallData?.guardianHasMfrAccount}
                    id='phone-call-header'
                    phoneCallDetails={phoneCallData}
                />
            }
            footer={
                <DrawerFooterActions
                    isEditable={PhoneCallStatusTypes.Open === phoneCallData?.statusCode}
                    closeButtonProps={{
                        onClick: () => handleClose(),
                        label: t('PhoneCallDetails.closeDrawer'),
                        isDisabled: isLoading,
                    }}
                    saveButtonProps={{
                        onClick: handleUpdatePhoneCall,
                        label: t('PhoneCallDetails.save'),
                        isDisabled: isLoading || !isDirty || markCompleteLoading || isSaving,
                    }}
                />
            }
        >
            <PhoneCallStatus
                id='phone-call-status'
                manageFieldPropFactory={manageFieldPropFactory}
                phoneCallDetails={phoneCallData}
                setPhoneCallDetails={setPhoneCallDetail}
                selectFieldPropFactory={selectFieldPropFactory}
                typeaheadPropFactory={typeaheadPropFactory}
                absenceReasonCodes={absenceReasonCodes}
                actionTypes={actionTypes}
            />
            <AbsenceRecord
                id='absence-record'
                phoneCallDetails={phoneCallData}
                setPhoneCallDetails={setPhoneCallDetail}
                manageFieldPropFactory={manageFieldPropFactory}
                selectFieldPropFactory={selectFieldPropFactory}
                typeaheadPropFactory={typeaheadPropFactory}
            />
            <Guardians
                id='guardians'
                textFieldPropFactory={textFieldPropFactory}
                manageFieldPropFactory={manageFieldPropFactory}
                phoneCallDetails={phoneCallData}
                setPhoneCallDetails={setPhoneCallDetail}
            />
            <ContactLog
                id='contact-log'
                phoneCallDetails={phoneCallData}
                setPhoneCallDetails={setPhoneCallDetail}
                manageFieldPropFactory={manageFieldPropFactory}
            />
            <Synchronization
                id='synchronization'
                textFieldPropFactory={textFieldPropFactory}
                switchFieldPropFactory={switchFieldPropFactory}
                manageFieldPropFactory={manageFieldPropFactory}
                phoneCallDetails={phoneCallData}
                setPhoneCallDetails={setPhoneCallDetail}
            />
            {(phoneCallData?.lastModifiedByFullName ||
                phoneCallData?.lastModifiedDate ||
                phoneCallData?.createdOn ||
                phoneCallData?.createdByFullName) && (
                <AuditInfo
                    modifiedBy={phoneCallData?.lastModifiedByFullName}
                    modifiedOn={phoneCallData?.lastModifiedDate}
                    createdOn={phoneCallData?.createdOn}
                    createdBy={phoneCallData?.createdByFullName}
                />
            )}
        </Drawer>
    );
};

export default PhoneCallDetailsDrawer;
