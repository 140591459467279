import * as React from 'react';
function SvgDocument(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 18 22' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M6.048 17.018h5.91c.295 0 .543-.1.742-.301.2-.201.3-.45.3-.747 0-.295-.1-.543-.3-.742-.2-.2-.447-.3-.742-.3H6.042c-.295 0-.543.1-.743.3-.2.2-.3.447-.3.742s.101.544.302.745c.201.202.45.303.747.303zm0-4h5.91c.295 0 .543-.1.742-.301.2-.201.3-.45.3-.747 0-.295-.1-.543-.3-.742-.2-.2-.447-.3-.742-.3H6.042c-.295 0-.543.1-.743.3-.2.2-.3.447-.3.742s.101.544.302.745c.201.202.45.303.747.303zm-2.976 8.185c-.63 0-1.167-.221-1.61-.665a2.193 2.193 0 01-.665-1.61V3.072c0-.63.221-1.167.665-1.61.443-.444.98-.665 1.61-.665h7.073a2.246 2.246 0 011.597.658l4.802 4.803a2.23 2.23 0 01.66 1.597v11.073c0 .63-.223 1.167-.666 1.61-.444.444-.98.665-1.61.665H3.072zM9.928 6.934V3.072H3.072v15.856h11.856V8.072h-3.862c-.32 0-.589-.11-.809-.33-.22-.22-.329-.489-.329-.808z'
                fill='#171923'
            />
        </svg>
    );
}
export default SvgDocument;
