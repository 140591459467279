import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
const lang = 'en';

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: en,
        },
        lng: lang,
        keySeparator: false,
    },
    lng: lang,
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
