import { createSlice } from '@reduxjs/toolkit';

const initialKillSwitchState = {
    isServerUnavailable: false,
};

const killSwitchSlice = createSlice({
    name: 'killSwitch',
    initialState: initialKillSwitchState,
    reducers: {
        setKillSwitch: (state, action) => {
            state.isServerUnavailable = action.payload;
        },
    },
});

export const { setKillSwitch } = killSwitchSlice.actions;

export default killSwitchSlice.reducer;
