import { Button as BaseButton, ButtonProps, IconButton, IconButtonProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
export type IButton = ButtonProps;

export const Button: React.FC<IButton> = ({ ...rest }) => {
    return <BaseButton {...rest} />;
};

export type GhostButton = IconButtonProps &
    ButtonProps & {
        label: string;
        badge?: ReactNode;
        onClickWithId?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => void;
    };

export type GhostIconProps = IconButtonProps & {
    icon: ReactNode;
};

export const Ghost: React.FC<GhostButton> = ({
    label,
    badge,
    onClick,
    onClickWithId,
    disabled,
    isActive,
    id,
    icon,
}) => {
    return (
        <BaseButton
            variant={'ghost'}
            px={4}
            rounded={'md'}
            sx={{
                fontWeight: 600,
            }}
            gap={badge && '2'}
            isActive={isActive}
            cursor={disabled && 'not-allowed'}
            isDisabled={disabled}
            bg='ghost.bg.base'
            color='ghost.text.base'
            _hover={
                !disabled &&
                !isActive && {
                    color: 'ghost.text.hover',
                    bg: 'ghost.bg.hover',
                }
            }
            _focus={
                !disabled &&
                isActive && {
                    border: 'none',
                    outline: 'none',
                    color: 'ghost.text.active',
                    bg: 'ghost.bg.active',
                }
            }
            _active={
                !disabled &&
                isActive && {
                    border: 'none',
                    outline: 'none',
                    color: 'ghost.text.active',
                    bg: 'ghost.bg.active',
                }
            }
            _disabled={{
                color: 'ghost.text.disabled',
                bg: 'ghost.bg.disabled',
            }}
            onClick={(e) => {
                onClick && onClick(e);
                onClickWithId && onClickWithId(e, id);
            }}
            id={id}
        >
            {icon && <GhostIcon aria-label={label} icon={icon} />}
            <span>{label}</span>
            <span>{badge && badge}</span>
        </BaseButton>
    );
};

export const GhostIcon: React.FC<GhostIconProps> = ({ icon, onClick, size = 'md', disabled, ...rest }) => {
    return (
        <IconButton
            {...rest}
            size={size}
            icon={icon}
            isDisabled={disabled}
            cursor={disabled && 'not-allowed'}
            onClick={onClick}
            bg='ghost.icon.bg.base'
            color='ghost.icon.text.base'
            _hover={
                !disabled && {
                    color: 'ghost.icon.text.hover',
                    bg: 'ghost.icon.bg.hover',
                }
            }
            _focus={
                !disabled && {
                    border: 'none',
                    outline: 'none',
                    color: 'ghost.icon.text.active',
                    bg: 'ghost.icon.bg.active',
                }
            }
            _active={
                !disabled && {
                    border: 'none',
                    outline: 'none',
                    color: 'ghost.icon.text.active',
                    bg: 'ghost.icon.bg.active',
                }
            }
            _disabled={{
                color: 'ghost.icon.text.disabled',
                bg: 'ghost.icon.bg.disabled',
            }}
        />
    );
};
