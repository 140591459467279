import {
    Input as ChakraInput,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    FormControl,
    FormHelperText,
    FormLabel,
    BackgroundProps,
    Box,
    InputProps,
} from '@chakra-ui/react';
import { Lock } from '@Icon';
import { ComponentProps, FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface TextInputProps extends InputProps {
    isLocked: boolean;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    label?: string;
    error?: string;
    onChangeText?: (value: string) => void;
    bg?: BackgroundProps['bg'];
    stacked?: boolean;
    col?: number;
    labelProps?: ComponentProps<typeof FormLabel>;
}

const TextInput: FC<TextInputProps> = ({
    label,
    error,
    isLocked,
    rightIcon,
    leftIcon,
    onChangeText,
    bg = 'transparent',
    stacked = false,
    value,
    isRequired,
    col,
    labelProps,
    ...rest
}) => {
    const { t } = useTranslation();

    return (
        <FormControl
            isInvalid={rest.isInvalid}
            display={{ base: 'block', lg: 'flex' }}
            alignItems={stacked ? 'start' : 'baseline'}
            flexDirection={stacked ? 'column' : 'row'}
        >
            {label && (
                <FormLabel
                    color={(rest.isDisabled || isLocked) && 'text-disabled'}
                    flexBasis={col === 1 ? '12.5%' : '30%'}
                    textStyle={'sm-medium'}
                    {...labelProps}
                >
                    {label}
                    {isRequired && '*'}
                </FormLabel>
            )}
            <InputGroup bg={bg}>
                {leftIcon && <InputLeftElement left={-2}>{leftIcon}</InputLeftElement>}
                <Box as='span' w='100%'>
                    <ChakraInput
                        textStyle={'md-normal'}
                        onChange={(event) => onChangeText && onChangeText(event.target.value)}
                        isDisabled={isLocked}
                        _disabled={{
                            color: 'text-disabled',
                        }}
                        title={(isRequired && t('InputFields.requiredTitle')) || ''}
                        sx={
                            leftIcon && {
                                pl: 5,
                            }
                        }
                        value={value ?? ''}
                        _focusVisible={{ borderColor: 'surface-brand-primary' }}
                        autoComplete='123'
                        {...rest}
                    />
                    {error && (
                        <FormHelperText p={2} textStyle='sm-normal' color={rest.isInvalid && 'text-error'}>
                            {error}
                        </FormHelperText>
                    )}
                </Box>
                {(rightIcon || isLocked) && <InputRightElement>{isLocked ? <Lock /> : rightIcon}</InputRightElement>}
            </InputGroup>
        </FormControl>
    );
};

export default TextInput;
