import { TFunction } from 'react-i18next';
import { LinkRequestSidebarItem } from '../types';
import SvgAboutStudent from '@Icon/AboutStudent';

const sideBarData = (item: LinkRequestSidebarItem, t: TFunction) => ({
    groups: [
        {
            items: [
                {
                    label: t('LinkingRequestDetails.linkingrequest'),
                    id: '#linkingrequest',
                    icon: <SvgAboutStudent />,
                    active: item === LinkRequestSidebarItem.LinkingRequest,
                },
            ],
        },
    ],
});

export { sideBarData };
