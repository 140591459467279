import DateTimeInput from '@Components/DateTimeInput';
import Textarea from '@Components/Textarea';
import { ManageDatePickerProps, ManageTextProps } from '@Hooks';
import { Forum } from '@Icon';
import Header from '@Pages/EventDetails/components/Summary/components/Header';
import { LateAbsenceStepProps } from '@Pages/LateAbsenceDetails/types';
import { Box, Grid } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Note = React.forwardRef<HTMLDivElement, LateAbsenceStepProps>(
    ({ id, manageFieldPropFactory, textAreaPropFactory, dateTimePickerPropFactory }) => {
        const { t } = useTranslation();

        return (
            <Box id={id}>
                <Header divider={true} mb={4} title={t('LateAbsence.subheader.note')} icon={<Forum />} />

                <Grid
                    pb={4}
                    columnGap={10}
                    gridAutoFlow='dense'
                    templateColumns={{
                        base: 'repeat(1, 1fr)',
                        md: 'repeat(2, 1fr)',
                    }}
                    rowGap={4}
                    w={'full'}
                >
                    <DateTimeInput
                        hasTimeComponent
                        bg='surface-primary'
                        {...dateTimePickerPropFactory(t('LateAbsence.noteStartDate'), ['absenceStart'])}
                        {...manageFieldPropFactory<ManageDatePickerProps>(['absenceStart'], 'onChange', 'date')}
                        isLocked={true}
                        size='md'
                    />

                    <DateTimeInput
                        hasTimeComponent
                        bg='surface-primary'
                        {...dateTimePickerPropFactory(t('LateAbsence.noteEndDate'), ['absenceEnd'])}
                        {...manageFieldPropFactory<ManageDatePickerProps>(['absenceEnd'], 'onChange', 'date')}
                        isLocked={true}
                        size='md'
                    />
                </Grid>
                <Box pb={4}>
                    <Textarea
                        bg='surface-primary'
                        {...textAreaPropFactory(t('LateAbsence.note'), ['note'])}
                        {...manageFieldPropFactory<ManageTextProps>(['note'], 'onChangeText', 'value')}
                        col={1}
                    />
                </Box>
            </Box>
        );
    }
);

export default Note;
