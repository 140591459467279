import { HeaderWithSectionRouter } from '@Components/SectionRouter';
import EventDetailsPage from '@Pages/EventDetails';
import EventResponsesPage from '@Pages/EventResponses';
import { Events } from '@Pages/Events';
import { Guardians } from '@Pages/Guardians';
import EventParticipantsPage from '@Pages/EventParticipants';
import { Schools } from '@Pages/Schools';
import StudentDetailsPage from '@Pages/StudentDetails';
import { Students } from '@Pages/Students';
import { System } from '@Pages/System';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import { PhoneCalls } from '@Pages/PhoneCalls';
import { PhoneCallRecords } from '@Pages/PhoneCallRecords';
import { PowerSchoolAbsences } from '@Pages/PowerSchoolAbsences';
import { LateAbsence } from '@Pages/LateAbsence';
import { StudentActivityRecords } from '@Pages/StudentActivities';
import { LateAbsenceDetails } from '@Pages/LateAbsenceDetails';
import StudentActivityDetails from '@Pages/StudentActivityDetails';
import PowerSchoolAbsenceDetails from '@Pages/PowerSchoolAbsenceDetails';
import { AbsenceReasons } from '@Pages/AbsenceReasons';
import { ReportPeriods } from '@Pages/ReportPeriods';
import ReportPeriodDetails from '@Pages/ReportPeriodDetails';
import { Updates } from '@Pages/Updates';
import { Registrations } from '@Pages/Registrations';
import RegistrationDetails from '@Pages/RegistrationDetails';
import UpdateDetails from '@Pages/UpdateDetails';
import { useCallback } from 'react';
import { getNavigations } from '@Components/Header';
import { useTranslation } from 'react-i18next';
import { useGetFeaturesQuery } from '@Redux/services/LookupApi';

//TODO: Rewrite this using json object and for loop
const EnabledRoute = ({ children, path, subpath = undefined }) => {
    const { t } = useTranslation();
    const { data: { data: enableFeatures } = {} } = useGetFeaturesQuery();
    const navigations = getNavigations(t, 0, 0, 0);
    const isDisabledRoute = useCallback(
        (path: string, subPath?: string) => {
            if (enableFeatures) {
                return enableFeatures[`${path}${subpath ? '.' + subPath : ''}`];
            }
            return false;
        },
        [navigations, enableFeatures]
    );
    return !isDisabledRoute(path, subpath) && children;
};

export const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path='/' Component={HeaderWithSectionRouter}>
                <Route path='events'>
                    <Route path='planning'>
                        <Route
                            path='all'
                            element={
                                <EnabledRoute path={'events'} subpath='planning'>
                                    <Events status='all' />
                                </EnabledRoute>
                            }
                        />
                        <Route
                            path='proposed'
                            element={
                                <EnabledRoute path={'events'} subpath='planning'>
                                    <Events status='proposed' />
                                </EnabledRoute>
                            }
                        />
                        <Route
                            path='validated'
                            element={
                                <EnabledRoute path={'events'} subpath='planning'>
                                    <Events status='validated' />
                                </EnabledRoute>
                            }
                        />
                        <Route
                            path='published'
                            element={
                                <EnabledRoute path={'events'} subpath='planning'>
                                    <Events status='published' />
                                </EnabledRoute>
                            }
                        />
                        <Route
                            path='cancelled'
                            element={
                                <EnabledRoute path={'events'} subpath='planning'>
                                    <Events status='cancelled' />
                                </EnabledRoute>
                            }
                        />
                        <Route
                            path='closed'
                            element={
                                <EnabledRoute path={'events'} subpath='planning'>
                                    <Events status='closed' />
                                </EnabledRoute>
                            }
                        />
                    </Route>

                    <Route
                        path=':id'
                        element={
                            <EnabledRoute path={'events'} subpath='planning'>
                                <EventDetailsPage />
                            </EnabledRoute>
                        }
                    />

                    <Route
                        path=':eventId/response/:id'
                        element={
                            <EnabledRoute path={'events'} subpath='planning'>
                                <EventResponsesPage />
                            </EnabledRoute>
                        }
                    />
                    <Route path=':id/custom-list'>
                        <Route
                            path='new'
                            element={
                                <EnabledRoute path={'events'} subpath='planning'>
                                    <EventParticipantsPage />
                                </EnabledRoute>
                            }
                        />
                        <Route
                            path=':listId'
                            element={
                                <EnabledRoute path={'events'} subpath='planning'>
                                    <EventParticipantsPage />
                                </EnabledRoute>
                            }
                        />
                    </Route>
                </Route>

                <Route path='registrations'>
                    <Route
                        index
                        element={
                            <EnabledRoute path={'registrations'}>
                                <Registrations status='submittedAndDocumentsRequired' />
                            </EnabledRoute>
                        }
                    />
                    <Route
                        path='all'
                        element={
                            <EnabledRoute path={'registrations'}>
                                <Registrations status='all' />
                            </EnabledRoute>
                        }
                    />
                    <Route
                        path='submittedAndDocumentsRequired'
                        element={
                            <EnabledRoute path={'registrations'}>
                                <Registrations status='submittedAndDocumentsRequired' />
                            </EnabledRoute>
                        }
                    />
                    <Route
                        path='submitted'
                        element={
                            <EnabledRoute path={'registrations'}>
                                <Registrations status='submitted' />
                            </EnabledRoute>
                        }
                    />
                    <Route
                        path='documentsRequired'
                        element={
                            <EnabledRoute path={'registrations'}>
                                <Registrations status='documentsRequired' />
                            </EnabledRoute>
                        }
                    />
                    <Route
                        path='approvedAndArchived'
                        element={
                            <EnabledRoute path={'registrations'}>
                                <Registrations status='approvedAndArchived' />
                            </EnabledRoute>
                        }
                    />
                    <Route
                        path='rejected'
                        element={
                            <EnabledRoute path={'registrations'}>
                                <Registrations status='rejected' />
                            </EnabledRoute>
                        }
                    />
                    <Route
                        path='deleted'
                        element={
                            <EnabledRoute path={'registrations'}>
                                <Registrations status='deleted' />
                            </EnabledRoute>
                        }
                    />
                    <Route
                        path=':id'
                        element={
                            <EnabledRoute path={'registrations'}>
                                <RegistrationDetails />
                            </EnabledRoute>
                        }
                    />
                    <Route
                        path='submittedAndDocumentsRequired'
                        element={
                            <EnabledRoute path={'registrations'}>
                                <Registrations status='submittedAndDocumentsRequired' />
                            </EnabledRoute>
                        }
                    />
                    <Route
                        path='submitted'
                        element={
                            <EnabledRoute path={'registrations'}>
                                <Registrations status='submitted' />
                            </EnabledRoute>
                        }
                    />
                    <Route
                        path='documentsRequired'
                        element={
                            <EnabledRoute path={'registrations'}>
                                <Registrations status='documentsRequired' />
                            </EnabledRoute>
                        }
                    />
                    <Route
                        path='approvedAndArchived'
                        element={
                            <EnabledRoute path={'registrations'}>
                                <Registrations status='approvedAndArchived' />
                            </EnabledRoute>
                        }
                    />
                    <Route
                        path='rejected'
                        element={
                            <EnabledRoute path={'registrations'}>
                                <Registrations status='rejected' />
                            </EnabledRoute>
                        }
                    />
                    <Route
                        path=':id'
                        element={
                            <EnabledRoute path={'registrations'}>
                                <RegistrationDetails />
                            </EnabledRoute>
                        }
                    />
                </Route>

                <Route path='updates'>
                    <Route
                        path=':id'
                        element={
                            <EnabledRoute path={'updates'}>
                                <UpdateDetails />
                            </EnabledRoute>
                        }
                    />
                    <Route
                        path='all'
                        element={
                            <EnabledRoute path={'updates'}>
                                <Updates status='all' />
                            </EnabledRoute>
                        }
                    />
                    <Route
                        index
                        element={
                            <EnabledRoute path={'updates'}>
                                <Updates status='submitted' />
                            </EnabledRoute>
                        }
                    />
                    <Route
                        path='submitted'
                        element={
                            <EnabledRoute path={'updates'}>
                                <Updates status='submitted' />
                            </EnabledRoute>
                        }
                    />
                    <Route
                        path='approved'
                        element={
                            <EnabledRoute path={'updates'}>
                                <Updates status='approved' />
                            </EnabledRoute>
                        }
                    />
                    <Route
                        path='deleted'
                        element={
                            <EnabledRoute path={'updates'}>
                                <Updates status='deleted' />
                            </EnabledRoute>
                        }
                    />
                    <Route
                        path='rejected'
                        element={
                            <EnabledRoute path={'updates'}>
                                <Updates status='rejected' />
                            </EnabledRoute>
                        }
                    />
                </Route>

                <Route path='absences'>
                    <Route path='phone-call'>
                        <Route
                            path=''
                            element={
                                <EnabledRoute path={'absences'}>
                                    <PhoneCalls />
                                </EnabledRoute>
                            }
                        />
                    </Route>
                    <Route path='late-absences'>
                        <Route
                            path=''
                            element={
                                <EnabledRoute path={'absences'}>
                                    <LateAbsence />
                                </EnabledRoute>
                            }
                        />
                        <Route
                            path=':id'
                            element={
                                <EnabledRoute path={'absences'}>
                                    <LateAbsenceDetails />
                                </EnabledRoute>
                            }
                        />
                    </Route>
                </Route>

                <Route path='records'>
                    <Route
                        path='schools'
                        element={
                            <EnabledRoute path={'records'} subpath='schools'>
                                <Schools />
                            </EnabledRoute>
                        }
                    />
                    <Route path='students'>
                        <Route
                            path=''
                            element={
                                <EnabledRoute path={'records'} subpath='students'>
                                    <Students />
                                </EnabledRoute>
                            }
                        />
                        <Route path='powerschool-absences'>
                            <Route
                                path=''
                                element={
                                    <EnabledRoute path={'records'} subpath='students.powerschool-absences'>
                                        <PowerSchoolAbsences />
                                    </EnabledRoute>
                                }
                            />
                            <Route
                                path=':id'
                                element={
                                    <EnabledRoute path={'records'} subpath='students.powerschool-absences'>
                                        <PowerSchoolAbsenceDetails />
                                    </EnabledRoute>
                                }
                            />
                        </Route>

                        <Route path='activities'>
                            <Route
                                path=''
                                element={
                                    <EnabledRoute path={'records'} subpath='activities'>
                                        <StudentActivityRecords />
                                    </EnabledRoute>
                                }
                            />
                            <Route
                                path=':id'
                                element={
                                    <EnabledRoute path={'records'} subpath='activities'>
                                        <StudentActivityDetails />
                                    </EnabledRoute>
                                }
                            />
                        </Route>

                        <Route
                            path='phone-calls'
                            element={
                                <EnabledRoute path={'records'} subpath='phone-calls'>
                                    <PhoneCallRecords />
                                </EnabledRoute>
                            }
                        />
                    </Route>
                    <Route
                        path='student/:id'
                        element={
                            <EnabledRoute path={'records'} subpath='students'>
                                <StudentDetailsPage />
                            </EnabledRoute>
                        }
                    />
                    <Route
                        path='guardians'
                        element={
                            <EnabledRoute path={'records'} subpath='guardians'>
                                <Guardians />
                            </EnabledRoute>
                        }
                    />

                    <Route path='reports-cards'>
                        <Route
                            path=''
                            element={
                                <EnabledRoute path={'records'} subpath={'reports-cards'}>
                                    <ReportPeriods />
                                </EnabledRoute>
                            }
                        />
                        <Route
                            path=':id/:schoolId/:schoolYearId'
                            element={
                                <EnabledRoute path={'records'} subpath={'reports-cards'}>
                                    <ReportPeriodDetails />
                                </EnabledRoute>
                            }
                        />
                    </Route>

                    <Route
                        path='system'
                        element={
                            <EnabledRoute path={'system'}>
                                <System />
                            </EnabledRoute>
                        }
                    />
                </Route>

                <Route path='system'>
                    <Route path='configurations'>
                        <Route path='absence-reasons'>
                            <Route
                                path=''
                                element={
                                    <EnabledRoute path='system' subpath='configurations'>
                                        <AbsenceReasons />
                                    </EnabledRoute>
                                }
                            ></Route>
                        </Route>
                    </Route>
                </Route>
            </Route>
        </>
    )
);
