import { Button, ButtonProps, HStack, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface NavButtonProps extends ButtonProps {
    icon?: ReactNode;
    label: ReactNode | string;
    id: string;
    onItemClick: (id: string) => void;
    active: boolean;
}

const NavButton = (props: NavButtonProps) => {
    const { icon, label, onItemClick, active, id, ...buttonProps } = props;

    const { t } = useTranslation();

    return (
        <Button
            variant='ghost'
            justifyContent='start'
            alignItems={'center'}
            onClick={() => onItemClick(id)}
            backgroundColor={active && 'lineColor'}
            {...buttonProps}
        >
            <HStack spacing='3'>
                {icon && icon}
                <Text textStyle='sm-semibold'>{typeof label === 'string' ? t(label) : label}</Text>
            </HStack>
        </Button>
    );
};

export default NavButton;
