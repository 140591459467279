import { UpDownIcon } from '@chakra-ui/icons';
import { Box, TableColumnHeaderProps, Text, Th } from '@chakra-ui/react';
import { ArrowDown, ArrowUp } from '@Icon';
import { ReactNode, ComponentProps, forwardRef } from 'react';
import { SortingRule } from 'react-table';

interface IProps<T extends object> extends TableColumnHeaderProps {
    isSorted: boolean;
    isSortedDesc: boolean | undefined;
    disableSortBy?: boolean;
    children?: ReactNode;
    needSorting?: boolean;
    title?: string; // For the tooltip
    sortBy?: SortingRule<T>;
    id: string;
    containerProps?: ComponentProps<typeof Text>;
}

const TableCell = forwardRef(
    <T extends object>(
        { disableSortBy, title, children, isSorted, isSortedDesc, containerProps, ...rest }: IProps<T>,
        ref
    ) => {
        return (
            <Th ref={ref} {...rest} title={title} maxW='-webkit-max-content'>
                <Box display='flex' justifyContent={'space-between'} {...containerProps}>
                    <Text as='span' textStyle='md-medium' pt={1}>
                        {children}
                    </Text>
                    {!disableSortBy && (
                        <Box display={'flex'} as='span' ml={2} placeItems={'center'}>
                            {!isSorted ? (
                                <UpDownIcon
                                    sx={{
                                        width: '1rem',
                                    }}
                                />
                            ) : (
                                <>
                                    {isSorted && !isSortedDesc && <ArrowUp width={20} height={20} />}
                                    {isSorted && isSortedDesc && <ArrowDown width={20} height={20} />}
                                </>
                            )}
                        </Box>
                    )}
                </Box>
            </Th>
        );
    }
);

export default TableCell;
