import * as React from 'react';
function SvgCallLog(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M17.956 21.203c-2.108 0-4.19-.459-6.247-1.377-2.057-.919-3.931-2.222-5.622-3.91-1.69-1.69-2.995-3.563-3.913-5.621C1.256 8.237.797 6.153.797 4.044a1.212 1.212 0 011.241-1.247h4.044c.325 0 .603.096.834.288.231.192.376.438.433.739l.644 3.374c.05.327.042.604-.022.83a1.276 1.276 0 01-.368.595l-2.46 2.402c.317.573.693 1.131 1.127 1.674.434.543.919 1.074 1.453 1.591.496.497 1.008.952 1.535 1.366.527.414 1.079.788 1.657 1.123l2.391-2.374c.194-.19.432-.326.714-.41.281-.082.568-.1.861-.05l3.289.682c.317.09.567.244.751.461.184.217.276.476.276.776v4.092c0 .356-.119.653-.357.89a1.202 1.202 0 01-.884.357zM4.055 8.904l1.644-1.596-.413-2.236H3.103c.075.655.185 1.302.328 1.94.144.64.352 1.27.624 1.892zm8.974 8.98c.626.271 1.265.488 1.918.651a12.75 12.75 0 001.975.331v-2.17l-2.23-.463-1.663 1.651zm-.993-14.872a1.07 1.07 0 01-.787-.32 1.07 1.07 0 01-.32-.788c0-.311.106-.573.32-.787a1.07 1.07 0 01.787-.32h8.06c.31 0 .573.106.787.32.213.214.32.476.32.787 0 .311-.107.574-.32.787a1.07 1.07 0 01-.787.32h-8.06zm0 4.066a1.07 1.07 0 01-.787-.32 1.07 1.07 0 01-.32-.788c0-.311.106-.574.32-.787a1.07 1.07 0 01.787-.32h8.06c.31 0 .573.106.787.32.213.213.32.476.32.787 0 .311-.107.574-.32.787a1.07 1.07 0 01-.787.32h-8.06zm0 4.065a1.07 1.07 0 01-.787-.32 1.07 1.07 0 01-.32-.787c0-.311.106-.574.32-.787a1.07 1.07 0 01.787-.32h8.06c.31 0 .573.106.787.32.213.213.32.476.32.787 0 .311-.107.573-.32.787a1.07 1.07 0 01-.787.32h-8.06z'
                fill='#171923'
            />
        </svg>
    );
}
export default SvgCallLog;
