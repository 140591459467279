import { Box, Button, IconButtonProps, Popover, PopoverContent, Portal, useDisclosure } from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';
import { FC, useRef } from 'react';
import { PopoverTrigger } from '@Components/PopoverTrigger';
import { Plus } from '@Icon';
import { BasicLookup } from '@Redux/services/LookupApi/types';

interface IProps extends IconButtonProps {
    options: BasicLookup[];
    onSelectOption: (value: string | number) => void;
    disabled?: boolean;
}

const ListPopover: FC<IProps> = ({ options, onSelectOption, disabled }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { t } = useTranslation();

    const initialFocusRef = useRef();

    const handleClick = (val: string | number) => {
        onSelectOption(val);
        onClose();
    };

    return options.length > 0 ? (
        <Popover isOpen={isOpen} initialFocusRef={initialFocusRef} placement='bottom-end' onClose={onClose}>
            <PopoverTrigger>
                <Button
                    isDisabled={disabled}
                    bg={'surface-primary'}
                    onClick={onOpen}
                    aria-label={t('AddMembers.more')}
                    leftIcon={<Plus />}
                >
                    {t('AddMembers.more')}
                </Button>
            </PopoverTrigger>

            <Portal>
                <PopoverContent p={5} textAlign='left'>
                    <Box bottom={4} display='flex' flexDirection='column' rowGap={2}>
                        {options.map((option) => (
                            <Button
                                onClick={() => handleClick(option.key)}
                                bg='whiteAlpha'
                                border='1px'
                                borderColor='lineColor'
                            >
                                {option.value}
                            </Button>
                        ))}
                    </Box>
                </PopoverContent>
            </Portal>
        </Popover>
    ) : (
        <></>
    );
};

export default ListPopover;
