import { Text, Stack, TabPanel, TabPanels, Flex, Spacer, useDisclosure, Divider, Checkbox } from '@chakra-ui/react';
import { AlertDialog, Button } from '@Components';
import { SectionHeader } from '../SectionHeader';
import Tabs from '@Components/Tabs';
import { Avatars } from '@Icon';
import { ReportingPeriodDetails, SortByProps, StudentRecord } from '@Redux/services/Reports/types';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StudentTable } from './components/StudentTable';
import {
    useExportReportingPeriodReportCardsMutation,
    useGetReportingStudentTeachersMutation,
    useGetSchoolReportingPeriodReportCardsMutation,
    useGetSchoolReportingPeriodStudentsMutation,
    usePublishReportCardsMutation,
    useUnpublishReportCardsMutation,
} from '@Redux/services/Reports';
import { DEFAULT_PAGE_SIZE, EXCEL_FILE_CONTENT_TYPE, report_analytics_types, report_status } from 'src/constants';
import { downloadFile, parseError, parseWarning } from '@Utilities';
import { BasicLookup } from '@Redux/services/LookupApi/types';
import { useToast } from '@Hooks/useToast';
import { Filters } from './components/TableFilters';
import Textarea from '@Components/Textarea';

interface IProps {
    id: string;
    details: ReportingPeriodDetails;
    onRefresh: () => void;
}

export const Students = ({ id, details, onRefresh }: IProps) => {
    const { t } = useTranslation();
    const toast = useToast();
    const [initialized, setInitialized] = useState(false);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [showUnpublishAlert, setShowUnpublishAlert] = useState(false);
    const [suppressNotifications, setSuppressNotifications] = useState(false);

    const defaultSortOrder = { id: 'fullName', desc: true };
    const emptyFilters = {
        status: undefined,
        grade: undefined,
        homeRoomTeacher: undefined,
        searchText: '',
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [sortBy, setSortBy] = useState<SortByProps>(defaultSortOrder);
    const [allSelected, setAllSelected] = useState(false);
    const [tab, setTab] = useState(0);
    const [pagedResult, setPagedResult] = useState(details.studentSnapshot.pagedResult);

    useEffect(() => {
        setStudentsList(details?.studentSnapshot?.students);
    }, [details]);
    const [reportId, setReportId] = useState(null);
    const { isOpen: isPublishAlertOpen, onOpen: onPublishAlertOpen, onClose: onPublishAlertClose } = useDisclosure();
    const [publishReportCardsMutation, { isLoading }] = usePublishReportCardsMutation();

    const [studentsList, setStudentsList] = useState(details.studentSnapshot.students);
    const allStudentIds = useMemo(
        () =>
            studentsList
                .filter((student) => student.reportingStudentId && student.reportStatus !== report_status.Missing)
                .map((student) => student.reportingStudentId),
        [studentsList]
    );
    const [filters, setFilters] = useState(emptyFilters);

    const [teachersList, setTeachersList] = useState<BasicLookup[]>([]);
    const [getReportingStudentTeachers] = useGetReportingStudentTeachersMutation();

    const [getSchoolReportingPeriodReportCards, { isLoading: isLoadingAnalytics }] =
        useGetSchoolReportingPeriodReportCardsMutation();
    const loadAnalyticsData = async (currentPage: number, pageSize: number, sortBy: SortByProps, filters: Filters) => {
        await getSchoolReportingPeriodReportCards({
            currentPage: currentPage,
            pageSize: pageSize,
            sortBy: sortBy?.id,
            isDesc: sortBy?.desc,
            schoolReportingDayId: details.schoolReportingDayId,
            reportCardAnalyticsType: filters.status,
            searchText: filters.searchText,
            reportingPeriodId: details.reportingPeriodId,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                setStudentsList(
                    response.data.reportCards.map(
                        (x) =>
                            ({
                                oen: x.oen,
                                fullName: x.fullName.schoolToDo,
                                fullNameRC: x.fullName.reportCard,
                                reportStatus: x.reportStatus,
                                reportComment: x.reportComment,
                                grade: x.grade.schoolToDo,
                                gradeRC: x.grade.reportCard,
                                homeRoom: x.homeRoom,
                                homeTeacher: x.homeTeacher,
                                reportSplitterExecutionCount: x.reportSplitterExecutionCount,
                                reportingStudentId: x.reportingStudentId,
                                studentPhoto: x.studentPhoto,
                            } as StudentRecord)
                    )
                );
                setPagedResult(response.pagedResult);
            })
            .catch((err) => parseError(toast, err));
    };

    const [getStudents, { isLoading: isLoadingView }] = useGetSchoolReportingPeriodStudentsMutation();
    const [unpublishReason, setUnpublishReason] = useState('');

    const loadStudentsData = async (currentPage: number, pageSize: number, sortBy: SortByProps, filters: any) => {
        await getStudents({
            currentPage: currentPage,
            pageSize: pageSize,
            sortBy: sortBy?.id,
            isDesc: sortBy?.desc,
            reportingPeriodId: details.reportingPeriodId,
            schoolYearId: details.schoolYearId,
            schoolId: details.schoolId,
            reportStatus: filters.status,
            grade: filters.grade,
            homeRoomTeacher: filters.homeRoomTeacher,
            searchText: filters.searchText,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                setStudentsList(response.data);
                setPagedResult(response.pagedResult);
                setSelectedRows([]);
            })
            .catch((err) => parseError(toast, err));
    };

    const loadTeachersData = async () => {
        await getReportingStudentTeachers({
            schoolYearId: details.schoolYearId,
            schoolId: details.schoolId,
            reportingPeriodId: details.reportingPeriodId,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                setTeachersList(response.data);
            })
            .catch((err) => parseError(toast, err));
    };

    const [exportReportingPeriodReportCards] = useExportReportingPeriodReportCardsMutation();
    const handleExport = async () => {
        await exportReportingPeriodReportCards({
            schoolReportingDayId: details.schoolReportingDayId,
            reportCardAnalyticsType: filters.status,
            reportingPeriodId: details.reportingPeriodId,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                downloadFile(EXCEL_FILE_CONTENT_TYPE, response.data.fileContents, response.data.fileName);
            })
            .catch((err) => parseError(toast, err));
    };

    useEffect(() => {
        if (!initialized) return;
        loadTeachersData();
    }, [initialized]);

    const onTabChanged = (id: number) => {
        setCurrentPage(1);
        setSortBy(defaultSortOrder);
        setPageSize(DEFAULT_PAGE_SIZE);
        setTab(id);

        const newFilters =
            id === 1 ? { ...emptyFilters, status: report_analytics_types.MissingReportCards } : emptyFilters;

        setFilters(newFilters);
        setSelectedRows([]);
        setAllSelected(false);
        if (id === 1) {
            loadAnalyticsData(1, DEFAULT_PAGE_SIZE, defaultSortOrder, newFilters);
        } else {
            loadStudentsData(1, DEFAULT_PAGE_SIZE, defaultSortOrder, newFilters);
        }
    };

    const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
        setAllSelected(e.target.checked);

        const rowsToSelect = studentsList
            .filter(
                (x) =>
                    x.reportId && x.reportStatus !== report_status.Missing && x.reportStatus !== report_status.Removed
            )
            .map((x) => x.reportingStudentId);

        setSelectedRows(e.target.checked ? [...rowsToSelect] : []);
    };
    const handleSelect = (checked: boolean, id: string) => {
        if (checked) {
            if (!selectedRows.includes(id)) {
                setSelectedRows([...selectedRows, id]);
            }
            if (selectedRows.length === allStudentIds.length - 1) {
                setAllSelected(true);
            }
        } else {
            setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
            setAllSelected(false);
        }
    };
    const handleFilterChange = (key: string) => (value: number | string) => {
        setCurrentPage(1);

        const newFilters = { ...filters, [key]: Number.isNaN(value) ? undefined : value };

        setFilters(newFilters);
        if (tab === 1) {
            loadAnalyticsData(1, pageSize, sortBy, newFilters);
        } else {
            loadStudentsData(1, pageSize, sortBy, newFilters);
        }
    };
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        if (tab === 1) {
            loadAnalyticsData(page, pageSize, sortBy, filters);
        } else {
            loadStudentsData(page, pageSize, sortBy, filters);
        }
    };
    const handlePageSizeChange = (pageSize: number) => {
        setPageSize(pageSize);
        setCurrentPage(1);
        if (tab === 1) {
            loadAnalyticsData(1, pageSize, sortBy, filters);
        } else {
            loadStudentsData(1, pageSize, sortBy, filters);
        }
    };
    const onSortChange = (sortBy) => {
        if (sortBy[0]) {
            setSortBy(sortBy[0]);
            if (tab === 1) {
                loadAnalyticsData(1, pageSize, sortBy[0], filters);
            } else {
                loadStudentsData(1, pageSize, sortBy[0], filters);
            }
        }
    };

    const [unpublishReportCards, { isLoading: isUnPublishing }] = useUnpublishReportCardsMutation();

    const tabs = useMemo(() => [t('Records.students.views'), t('Records.students.analytics')], []);

    useEffect(() => {
        setTimeout(() => {
            setInitialized(true);
        }, 500);
    }, []);

    const onUnpublish = () => {
        setShowUnpublishAlert(true);
    };

    const handleRefresh = () => {
        setCurrentPage(1);
        if (tab === 1) {
            loadAnalyticsData(1, pageSize, sortBy, filters);
        } else {
            loadStudentsData(1, pageSize, sortBy, filters);
        }
    };

    const handleUnpublish = () => {
        const unpublishReportIds = studentsList
            .filter(
                (x) =>
                    x.reportId &&
                    x.reportStatus == report_status.Published &&
                    selectedRows.includes(x.reportingStudentId)
            )
            .map((x) => x.reportId);

        unpublishReportCards({
            schoolReportingDayId: details.schoolReportingDayId,
            ReportingPeriodId: details.reportingPeriodId,
            reportCardIds: unpublishReportIds,
            reason: unpublishReason,
            suppressNotifications,
        })
            .unwrap()
            .then(() => {
                handleRefresh();
                setSelectedRows([]);
            })
            .catch((x) => {
                parseError(toast, x);
            })
            .finally(() => {
                setSuppressNotifications(false);
            });
        onCloseAlert();
    };

    const reasonChanged = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (e?.currentTarget) {
            setUnpublishReason(e.currentTarget.value);
        }
    };

    const pendingReportIdsCount = useMemo(() => {
        return studentsList.filter(
            (x) => x.reportStatus === report_status.Pending && selectedRows.includes(x.reportingStudentId)
        ).length;
    }, [selectedRows, studentsList]);

    const publishedReportIdsCount = useMemo(
        () =>
            studentsList.filter(
                (x) => x.reportStatus === report_status.Published && selectedRows.includes(x.reportingStudentId)
            ).length,
        [selectedRows, studentsList]
    );

    const onCloseAlert = () => {
        setUnpublishReason('');
        setShowUnpublishAlert(false);
    };

    const handlePublishClick = async () => {
        await publishReportCardsMutation({
            schoolReportingDayId: details.schoolReportingDayId,
            ReportingPeriodId: details.reportingPeriodId,
            reportCardIds: studentsList
                .filter(
                    (x) =>
                        x.reportId &&
                        x.reportStatus == report_status.Pending &&
                        selectedRows.includes(x.reportingStudentId)
                )
                .map((x) => x.reportId),
            suppressNotifications,
        })
            .unwrap()
            .then((response) => {
                toast({
                    status: 'success',
                    description: t('ImportItem.toastPublishSuccess', {
                        count: response.data.publishedReportCardsCount,
                    }),
                });
                onPublishAlertClose();
                setSelectedRows([]);
                handleRefresh();
            })
            .catch((e) => parseError(toast, e))
            .finally(() => {
                setSuppressNotifications(false);
            });
    };

    return (
        <Stack id={id}>
            <AlertDialog
                isOpen={showUnpublishAlert || isPublishAlertOpen}
                title={t(isPublishAlertOpen ? 'ImportItem.AlertDialog.titlePublish' : 'Records.Unpublish.ConfirmTitle')}
                alertType='error'
                message={t(isPublishAlertOpen ? 'ImportItem.AlertDialog.messagePublish' : 'Records.Unpublish.Message', {
                    selected: selectedRows?.length ? pendingReportIdsCount : details.pendingReports,
                    school: details.school,
                    reportingPeriod: details.reportingPeriod,
                })}
                submitLabel={t(isPublishAlertOpen ? 'ImportItem.AlertDialog.submit' : 'Records.Unpublish.Submit')}
                cancelButtonLabel={t('Records.Unpublish.Cancel')}
                submitHandle={isPublishAlertOpen ? handlePublishClick : handleUnpublish}
                onClose={() => {
                    if (isPublishAlertOpen) {
                        onPublishAlertClose();
                    } else {
                        onCloseAlert();
                    }
                    setSuppressNotifications(false);
                }}
                cancelButtonProps={{
                    variant: 'outline',
                    disabled: isLoading || isUnPublishing,
                }}
                submitButtonProps={{
                    variant: 'primary',
                    disabled: isLoading || isUnPublishing || (!isPublishAlertOpen && !unpublishReason),
                }}
            >
                <Stack>
                    {!isPublishAlertOpen && (
                        <Stack mt={4}>
                            <Text textStyle={'sm-medium'}>{t('Records.unpublish.reasonTitle')}</Text>
                            <Textarea
                                label=''
                                isLocked={false}
                                value={unpublishReason}
                                placeholder={t('Records.unpublish.resonPlaceholder')}
                                onChange={reasonChanged}
                            />
                        </Stack>
                    )}
                    <Checkbox
                        mt='16px !important'
                        onChange={(e) => setSuppressNotifications(e.target.checked)}
                        isChecked={suppressNotifications}
                    >
                        <Text textStyle='sm-normal'>{t('ImportItem.AlertDialog.supressNotification')}</Text>
                    </Checkbox>
                </Stack>
            </AlertDialog>
            <Flex alignItems='center' gap='2' mt='16px'>
                <SectionHeader
                    icon={<Avatars />}
                    title={t('Students.header')}
                    subtitle={<Trans i18nKey='Records.students.subtitle' components={[<b />]} />}
                    showDivider={false}
                />
                <Spacer />
                <Button
                    onClick={onUnpublish}
                    textStyle={'md-semibold'}
                    variant={publishedReportIdsCount ? 'error' : 'disabled'}
                    disabled={publishedReportIdsCount === 0}
                    mr={2}
                >
                    <Trans
                        i18nKey='Records.Unpublish.Button'
                        values={{
                            count: publishedReportIdsCount > 0 ? ` (${publishedReportIdsCount})` : '',
                        }}
                    />
                </Button>
                <Button
                    onClick={onPublishAlertOpen}
                    variant={'primary'}
                    disabled={pendingReportIdsCount === 0 && details.pendingReports === 0}
                    mr={2}
                >
                    <Trans
                        i18nKey='Students.publishButtonLabel'
                        values={{
                            count: pendingReportIdsCount > 0 ? ` (${pendingReportIdsCount})` : '',
                        }}
                    />
                </Button>
            </Flex>
            <Divider mt={4} h={'1px'} bgColor={'border-primary'} />
            <Tabs tabs={tabs} onChange={onTabChanged}>
                <TabPanels>
                    <TabPanel px={1}>
                        <StudentTable
                            {...{
                                details,
                                onSortChange,
                                handlePageSizeChange,
                                handlePageChange,
                                handleFilterChange,
                                handleSelect,
                                handleSelectAll,
                                handleRefresh,
                                isLoading: isLoadingView,
                                currentPage,
                                pageSize,
                                filters,
                                studentsList,
                                allSelected,
                                teachersList,
                                pagedResult,
                                allStudentIds,
                            }}
                            selectedRows={selectedRows}
                            isAnalytics={false}
                        />
                    </TabPanel>
                    <TabPanel px={1}>
                        <StudentTable
                            {...{
                                details,
                                onSortChange,
                                handlePageSizeChange,
                                handlePageChange,
                                handleFilterChange,
                                handleSelect,
                                handleSelectAll,
                                handleRefresh,
                                handleExport,
                                isLoading: isLoadingAnalytics,
                                currentPage,
                                pageSize,
                                filters,
                                studentsList,
                                allSelected,
                                teachersList,
                                pagedResult,
                                allStudentIds,
                            }}
                            selectedRows={selectedRows}
                            isAnalytics={true}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Stack>
    );
};
