import { FC, useEffect, useState } from 'react';
import { Box, Collapse, Flex, Stack, Text } from '@chakra-ui/react';
import { Button } from '@Components';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { ReportingPeriod, ReportingPeriodDetailsProps } from '@Redux/services/Reports/types';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from 'src/constants';
import { format } from 'date-fns';
import { convertUTCDateToLocalDate } from '@Utilities';

interface ListItemProps {
    period: string;
    schoolName: string;
    reportingPeriods: ReportingPeriod[];
    schoolId: string;
    schoolYearId: string;
    onView: (propsData: ReportingPeriodDetailsProps) => void;
    isFirst: boolean;
}

const ListItem: FC<ListItemProps> = ({
    period,
    schoolName,
    reportingPeriods,
    schoolId,
    schoolYearId,
    onView,
    isFirst = false,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (isOpen !== isFirst) setIsOpen(isFirst);
    }, [isFirst]);

    const [isOpen, setIsOpen] = useState(isFirst);

    const ReviewPeriod = ({ reportingPeriodId, studentSnapshotCreationDate, isActive }: ReportingPeriod) => {
        if (new Date(studentSnapshotCreationDate) > new Date())
            return (
                <Flex>
                    <Flex
                        gap={8}
                        padding={'6px'}
                        w={'100%'}
                        bg={'surface-tertiary'}
                        justifyContent={'space-between'}
                        borderRadius={'md'}
                    >
                        <Text textStyle={'sm-bold'} textColor={'text-disabled'} my={1.5} mx={1}>
                            {t('ReportPeriods.ListItem.PdfNotReady', {
                                readyDate: format(convertUTCDateToLocalDate(studentSnapshotCreationDate), DATE_FORMAT),
                            })}
                        </Text>
                    </Flex>
                </Flex>
            );
        return (
            <Flex>
                {isActive ? (
                    <Flex
                        gap={8}
                        padding={'6px'}
                        w={'100%'}
                        bg={'surface-brand-primary'}
                        justifyContent={'space-between'}
                        borderRadius={'md'}
                    >
                        <Text textStyle={'sm-bold'} textColor={'text-invert'} my={'auto'} ml={'4px'}>
                            {t('ReportPeriods.ListItem.PdfReady')}
                        </Text>
                        <Button
                            size={'sm'}
                            variant={'light'}
                            onClick={() =>
                                onView({
                                    reportingPeriodId: reportingPeriodId,
                                    schoolId,
                                    schoolYearId,
                                })
                            }
                        >
                            {t('ReportPeriods.ListItem.Yes')}
                        </Button>
                    </Flex>
                ) : (
                    <Button
                        ml={'auto'}
                        variant='light'
                        size={'sm'}
                        bgColor={'surface-secondary'}
                        border={'none'}
                        onClick={() =>
                            onView({
                                reportingPeriodId: reportingPeriodId,
                                schoolId,
                                schoolYearId,
                            })
                        }
                        mb={2}
                        h={8}
                    >
                        {t('ReportPeriods.ListItem.Review')}
                    </Button>
                )}
            </Flex>
        );
    };

    return (
        <Box p={4} _even={{ backgroundColor: isFirst ? '' : 'surface-secondary' }} borderRadius={'8px'}>
            <Flex flexDirection={'row'} gap={'4px'} padding={'8px'} alignItems={'center'}>
                <Flex flexDirection={isFirst ? 'column' : 'row'} gap={'4px'} padding={'8px'} flexGrow={1}>
                    <Text textStyle={isFirst ? '2xl-semibold' : 'lg-semibold'} pb={{ base: 2, md: 0 }}>
                        {period}
                    </Text>
                    <Text textStyle={'lg-normal'} pb={{ base: 2, md: 0 }}>
                        {schoolName}
                    </Text>
                </Flex>
                {!isFirst && (
                    <Button
                        leftIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        variant={'ghost'}
                        color={'text-primary'}
                        onClick={() => setIsOpen(!isOpen)}
                        h={8}
                    >
                        {isOpen ? t('ReportPeriods.ListItem.Close') : t('ReportPeriods.ListItem.Open')}
                    </Button>
                )}
            </Flex>
            <Collapse in={isOpen}>
                <Flex overflowX={'auto'} gap={2}>
                    {reportingPeriods.map((x) => {
                        return (
                            <Stack
                                minW={'fit-content'}
                                borderRadius={'8px'}
                                backgroundColor={'white'}
                                borderColor={'border-primary'}
                                borderWidth={'1px'}
                                padding={'16px 12px'}
                            >
                                <Text textStyle={x.isActive ? 'lg-bold' : 'lg-medium'}>{x.reportingPeriod}</Text>
                                <Flex gap={1}>
                                    <Text textStyle={'sm-normal'}>{t('ReportPeriods.ListItem.SendHome')}</Text>
                                    <Text textStyle={x.isActive ? 'sm-bold' : 'sm-normal'}>
                                        {format(convertUTCDateToLocalDate(x.sendHomeDate), DATE_FORMAT)}
                                    </Text>
                                </Flex>
                                <ReviewPeriod {...x} />
                            </Stack>
                        );
                    })}
                </Flex>
            </Collapse>
            {isFirst && <Box height={'1px'} backgroundColor={'border-primary'} width={'100%'} mt={'20px'} />}
        </Box>
    );
};

export default ListItem;
