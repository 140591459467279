import DateTimeInput from '@Components/DateTimeInput';
import Textarea from '@Components/Textarea';
import { ManageDatePickerProps, ManageTextProps } from '@Hooks';
import { Forum } from '@Icon';
import Header from '@Pages/EventDetails/components/Summary/components/Header';
import { LateAbsenceStepProps } from '@Pages/LateAbsenceDetails/types';
import { Box, Grid } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const Note = React.forwardRef<HTMLDivElement, LateAbsenceStepProps>(
    ({ id, lateAbsenceDetails, manageFieldPropFactory, textAreaPropFactory, dateTimePickerPropFactory }) => {
        const { t } = useTranslation();

        const showTimeComponent = useMemo(
            () => !(lateAbsenceDetails.lateReasonId && lateAbsenceDetails.absenceReasonId),
            [lateAbsenceDetails]
        );

        return (
            <Box id={id}>
                <Header divider={true} mb={4} title={t('LateAbsence.subheader.note')} icon={<Forum />} />

                <Grid rowGap={4}>
                    <DateTimeInput
                        hasTimeComponent={showTimeComponent}
                        bg='surface-primary'
                        {...dateTimePickerPropFactory(t('LateAbsence.noteStartDate'), ['absenceStart'])}
                        {...manageFieldPropFactory<ManageDatePickerProps>(['absenceStart'], 'onChange', 'date')}
                        isLocked={true}
                        size='md'
                    />

                    <DateTimeInput
                        hasTimeComponent={showTimeComponent}
                        bg='surface-primary'
                        {...dateTimePickerPropFactory(t('LateAbsence.noteEndDate'), ['absenceEnd'])}
                        {...manageFieldPropFactory<ManageDatePickerProps>(['absenceEnd'], 'onChange', 'date')}
                        isLocked={true}
                        size='md'
                    />

                    <Textarea
                        bg='surface-primary'
                        {...textAreaPropFactory(t('LateAbsence.note'), ['note'])}
                        {...manageFieldPropFactory<ManageTextProps>(['note'], 'onChangeText', 'value')}
                    />
                </Grid>
            </Box>
        );
    }
);

export default Note;
