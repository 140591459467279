import { Box, Flex, Grid } from '@chakra-ui/react';
import AvatarLabel from '@Components/AvatarLabel';
import DateTimeInput from '@Components/DateTimeInput';
import { DetailPageSkeleton } from '@Components/DetailPageSkeleton';
import FormHeader from '@Components/FormHeader';
import Notes from '@Components/Notes';
import Sidebar from '@Components/Sidebar';
import Switch from '@Components/Switch';
import TextInput from '@Components/TextInput';
import TypeaheadInput from '@Components/TypeaheadInput';
import { useGetPowerSchoolAbsenceQuery } from '@Redux/services/Absence';
import {
    useGetAbsenceReasonByIdQuery,
    useGetAbsenceStatusCodesQuery,
    useGetSchoolsQuery,
} from '@Redux/services/LookupApi';
import { round } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE } from 'src/constants';

type IRouteParams = {
    id: string;
};

const PowerSchoolAbsenceDetails = () => {
    const { id } = useParams<IRouteParams>();
    const containerRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();
    const { data: { data: absence } = {}, refetch, isLoading } = useGetPowerSchoolAbsenceQuery({ id });
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const { data: { data: absenceStatusCodes } = {} } = useGetAbsenceStatusCodesQuery();
    const { data: { data: absenceReason } = {} } = useGetAbsenceReasonByIdQuery(
        {
            searchText: absence?.submittedAbsenceReasonId,
        },
        { skip: !absence?.submittedAbsenceReasonId }
    );
    const { data: { data: schoolOptions = [] } = {} } = useGetSchoolsQuery({});

    const statusCodes = useMemo(
        () => absenceStatusCodes?.map((code) => ({ key: code.key.toString(), value: code.value })),
        [absenceStatusCodes]
    );

    useEffect(() => {
        refetch();
    }, [id]);

    const sideBarItems = useMemo(
        () => ({
            header: (
                <AvatarLabel
                    name={absence?.powerSchoolAbsenceId}
                    subTitle={t('PowerSchoolAbsenceDetails.sideBar.subtitle')}
                />
            ),
            groups: [
                {
                    items: [],
                },
            ],
        }),
        [absence]
    );

    const handleNotesNextPage = async (currentPage: number, pageSize: number) => {
        setPage(currentPage);
        setPageSize(pageSize);
    };

    if (isLoading) {
        return <DetailPageSkeleton />;
    }

    return (
        <Flex>
            <Sidebar
                {...sideBarItems}
                footerDetails={{
                    modifiedByFullName: absence?.modifiedBy,
                    modifiedOn: absence?.modifiedOn,
                    createdByFullName: absence?.createdBy,
                    createdOn: absence?.createdOn,
                }}
            />
            <Box w='100%' pt={8} pr={4}>
                <Flex gap={3}>
                    <TypeaheadInput
                        label={t('PowerSchoolAbsenceDetails.status')}
                        options={statusCodes}
                        value={absence?.status?.toString()}
                        isLocked
                    />
                    <TypeaheadInput
                        label={t('PowerSchoolAbsenceDetails.student')}
                        value={absence?.studentId}
                        options={[
                            {
                                key: absence?.studentId,
                                value: absence?.studentName,
                            },
                        ]}
                        baseUrl='/records/student'
                        isLocked
                    />
                </Flex>
                <Flex
                    align={'flex-start'}
                    direction={'column'}
                    overflow={'hidden'}
                    width='100%'
                    ref={containerRef}
                    gap={8}
                    p={3}
                    borderLeftRadius={15}
                    mt={5}
                    bg='surface-secondary'
                >
                    <FormHeader label={t('PowerSchoolAbsenceDetails.formHeader.summary')}>
                        <Grid
                            column={{ md: 2, sm: 1 }}
                            columnGap={10}
                            gridAutoFlow='dense'
                            templateColumns={{
                                base: 'repeat(1, 1fr)',
                                md: 'repeat(2, 1fr)',
                            }}
                            rowGap={4}
                        >
                            <TextInput
                                label={t('PowerSchoolAbsenceDetails.powerSchoolAbsenceId')}
                                value={absence?.powerSchoolAbsenceId}
                                isLocked
                            />

                            <TextInput
                                label={t('PowerSchoolAbsenceDetails.period')}
                                value={absence?.absencePeriod}
                                isLocked
                            />

                            <DateTimeInput
                                hasTimeComponent
                                label={t('PowerSchoolAbsenceDetails.absenceDate')}
                                date={absence?.absenceDate ? new Date(absence?.absenceDate) : null}
                                size='md'
                                isLocked
                            />
                            <TextInput
                                label={t('PowerSchoolAbsenceDetails.reasonCode')}
                                value={absence?.reasonCode}
                                isLocked
                            />
                            <TextInput label={t('PowerSchoolAbsenceDetails.reason')} value={absence?.reason} isLocked />
                            <DateTimeInput
                                hasTimeComponent
                                label={t('PowerSchoolAbsenceDetails.notificationSent')}
                                date={absence?.notificationSent ? new Date(absence?.notificationSent) : null}
                                size='md'
                                isLocked
                            />
                            <TextInput
                                label={t('PowerSchoolAbsenceDetails.classStartTime')}
                                value={absence?.classStartTime}
                                isLocked
                            />
                            <TextInput
                                label={t('PowerSchoolAbsenceDetails.classEndTime')}
                                value={absence?.classEndTime}
                                isLocked
                            />
                            <TextInput label={t('PowerSchoolAbsenceDetails.class')} value={absence?.class} isLocked />
                            <TypeaheadInput
                                label={t('PowerSchoolAbsenceDetails.school')}
                                value={absence?.ownerId}
                                options={schoolOptions}
                                isLocked
                            />
                            <DateTimeInput
                                hasTimeComponent
                                label={t('PowerSchoolAbsenceDetails.approvedOn')}
                                date={absence?.approvedOn ? new Date(absence?.approvedOn) : null}
                                size='md'
                                isLocked
                            />
                            <TextInput
                                label={t('PowerSchoolAbsenceDetails.approvedBy')}
                                value={absence?.approvedBy}
                                isLocked
                            />
                            <Switch
                                label={t('PowerSchoolAbsenceDetails.plannedAbsence')}
                                isChecked={absence?.plannedAbsence}
                                onChangeSwitch={null}
                                isLocked
                            />
                        </Grid>
                    </FormHeader>
                    <FormHeader label={t('PowerSchoolAbsenceDetails.formHeader.portal')}>
                        <Grid
                            column={{ md: 2, sm: 1 }}
                            columnGap={10}
                            gridAutoFlow='dense'
                            templateColumns={{
                                base: 'repeat(1, 1fr)',
                                md: 'repeat(2, 1fr)',
                            }}
                            rowGap={4}
                        >
                            <TextInput
                                label={t('PowerSchoolAbsenceDetails.submittedAbsenceReason')}
                                value={absenceReason?.length > 0 ? absenceReason[0].value : ''}
                                isLocked
                            />

                            <TypeaheadInput
                                label={t('PowerSchoolAbsenceDetails.matchedAttendanceSubmission')}
                                value={absence?.matchedAttendanceSubmissionId}
                                options={
                                    absence?.matchedAttendanceSubmissionId
                                        ? [
                                              {
                                                  key: absence?.matchedAttendanceSubmissionId,
                                                  value: absence?.matchedAttendanceSubmissionName,
                                              },
                                          ]
                                        : []
                                }
                                isLocked
                            />
                        </Grid>
                    </FormHeader>
                    <Notes
                        notes={absence?.notes?.slice((page - 1) * pageSize, page * pageSize)}
                        activities={absence?.activities}
                        onNextPage={handleNotesNextPage}
                        notePagination={{
                            currentPage: page,
                            pageCount:
                                absence?.notes?.length > pageSize ? round(absence?.notes?.length / pageSize, 1) : 1,
                            pageSize: pageSize,
                            rowCount: absence?.notes?.length,
                        }}
                    />
                </Flex>
            </Box>
        </Flex>
    );
};

export default PowerSchoolAbsenceDetails;
