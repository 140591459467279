import * as React from 'react';
function SvgArrowUp(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M12 14.106l-3.804 3.785c-.211.212-.474.32-.786.327a1.057 1.057 0 01-.806-.333 1.079 1.079 0 01-.316-.795c0-.32.105-.585.316-.796l4.594-4.594a1.05 1.05 0 01.373-.245c.137-.052.28-.078.43-.078.148 0 .291.026.428.078.136.051.26.133.373.245l4.594 4.594c.211.211.32.472.326.783.006.311-.102.58-.326.808a1.069 1.069 0 01-.796.323c-.32 0-.586-.107-.802-.323L12 14.106zm0-6.185l-3.804 3.785a1.1 1.1 0 01-.786.326 1.065 1.065 0 01-.806-.326 1.08 1.08 0 01-.316-.796c0-.319.105-.584.316-.795l4.594-4.6a1.05 1.05 0 01.373-.246c.137-.051.28-.077.43-.077.148 0 .291.026.428.077.136.052.26.134.373.246l4.594 4.6a1.1 1.1 0 01.326.786c.006.313-.102.581-.326.805a1.079 1.079 0 01-.796.317 1.08 1.08 0 01-.796-.317L12 7.921z'
                fill='#171923'
            />
        </svg>
    );
}
export default SvgArrowUp;
