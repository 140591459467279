import { Box } from '@chakra-ui/react';
import { Question } from '@Redux/services/Event/types';
import { CellProps } from 'react-table';
import { DeleteButtonPopover } from '@Components/DeleteButton';
import { useTranslation } from 'react-i18next';
import { useDeleteQuestionMutation } from '@Redux/services/Event';
import { parseError } from '@Utilities';
import EditIcon from '@Components/EditIcon';
import { useToast } from '@Hooks/useToast';

const ActionCell: React.FC<
    CellProps<Question> & {
        onEdit: (question: Question) => void;
        onSubmit: (questions: Question[]) => void;
        isEditable: boolean;
    }
> = ({ row, onEdit, onSubmit, isEditable }) => {
    const { t } = useTranslation();
    const [deleteQuestion] = useDeleteQuestionMutation();
    const toast = useToast();

    const handleDelete = async () => {
        await deleteQuestion({
            eventId: row.original.eventId,
            eventQuestionId: row.original.eventQuestionId,
        })
            .unwrap()
            .then((response) => {
                toast({
                    status: 'success',
                    description: t('QuestionForm.toastDeleteSuccess'),
                });
                onSubmit(response.data);
            })
            .catch((e) => {
                parseError(toast, e);
            });
    };
    return (
        <Box display={'flex'} textAlign={'center'} placeContent={'center'}>
            {isEditable && (
                <DeleteButtonPopover aria-label='delete' onDelete={handleDelete} message={t('EventsList.deleteMsg')} />
            )}
            <EditIcon aria-label={t('EditIcon.title')} onClick={() => onEdit(row.original)} />
        </Box>
    );
};

export default ActionCell;
