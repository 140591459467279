import { FormComponentProps } from '@Hooks/useForms';
import { studentDetail } from '@Mocks/handlers/get/studentDetails/response';
import { IStudentRecord } from '@Redux/services/Student/types';
import { Dispatch, SetStateAction } from 'react';
import type { PartialDeep } from 'type-fest';

export type Guardian = {
    name: string;
    relationship: string;
    optedIn: boolean;
    homePhone: string;
    workPhone: string;
    cellPhone: string;
    email: string;
    alternateEmail: string;
    status: 'active' | 'inactive';
};

export type StudentDetail = typeof studentDetail;

export interface StudentStepProps extends FormComponentProps {
    studentDetail: PartialDeep<IStudentRecord>;
    setStudentDetail: Dispatch<SetStateAction<PartialDeep<IStudentRecord>>>;
}

export const editableFieldsForStudents = {
    lastOngoingConsent: {
        required: true,
        editableStatus: false,
    },
    ongoingConsentFormExpiryDate: {
        required: true,
        editableStatus: false,
    },
    'homeAddress.street': {
        required: false,
        editableStatus: false,
    },
    'homeAddress.ruralRoute': {
        required: true,
        editableStatus: false,
    },
    'homeAddress.apartment': {
        required: false,
        editableStatus: false,
    },
    'homeAddress.municipality': {
        required: true,
        editableStatus: false,
    },
    'homeAddress.province': {
        required: true,
        editableStatus: false,
    },
    'homeAddress.country': {
        required: true,
        editableStatus: false,
    },
    'homeAddress.postalCode': {
        required: false,
        editableStatus: false,
    },
    'mailingAddress.street': {
        required: false,
        editableStatus: false,
    },
    'mailingAddress.ruralRoute': {
        required: true,
        editableStatus: false,
    },
    'mailingAddress.apartment': {
        required: true,
        editableStatus: false,
    },
    'mailingAddress.municipality': {
        required: true,
        editableStatus: false,
    },
    'mailingAddress.province': {
        required: true,
        editableStatus: false,
    },
    'mailingAddress.country': {
        required: false,
        editableStatus: false,
    },
    'mailingAddress.postalCode': {
        required: false,
        editableStatus: false,
    },
    dropOffStreet: {
        required: true,
        editableStatus: false,
    },
    dropOffApartment: {
        required: true,
        editableStatus: false,
    },
    dropOffMunicipality: {
        required: true,
        editableStatus: false,
    },
    dropOffContactName: {
        required: true,
        editableStatus: false,
    },
    dropOffContactPhone: {
        required: true,
        editableStatus: false,
    },
    pickupStreet: {
        required: true,
        editableStatus: false,
    },
    pickupApartment: {
        required: false,
        editableStatus: false,
    },
    pickupMunicipality: {
        required: true,
        editableStatus: false,
    },
    pickupContactName: {
        required: true,
        editableStatus: false,
    },
    pickupContactPhone: {
        required: true,
        editableStatus: false,
    },
    studentId: {
        required: true,
        editableStatus: false,
    },
    fullName: {
        required: false,
        editableStatus: false,
    },
    legalFirstName: {
        required: true,
        editableStatus: false,
    },
    middleName: {
        required: true,
        editableStatus: false,
    },
    legalLastName: {
        required: true,
        editableStatus: false,
    },
    homePhone: {
        required: true,
        editableStatus: false,
    },
    mobilePhone: {
        required: true,
        editableStatus: false,
    },
    mailingPhone: {
        required: true,
        editableStatus: false,
    },
    emailAddress: {
        required: true,
        editableStatus: false,
    },
    busRoute: {
        required: true,
        editableStatus: false,
    },
    busCompany: {
        required: true,
        editableStatus: false,
    },
    gender: {
        required: true,
        editableStatus: false,
    },
    birthDate: {
        required: true,
        editableStatus: false,
    },
    age: {
        required: true,
        editableStatus: false,
    },
    grade: {
        required: true,
        editableStatus: false,
    },
    homeSchool: {
        required: true,
        editableStatus: false,
    },
    homeRoom: {
        required: true,
        editableStatus: false,
    },
    homeRoomTeacher: {
        required: true,
        editableStatus: false,
    },
    crossEnrolledSchool: {
        required: true,
        editableStatus: false,
    },
    doctorName: {
        required: true,
        editableStatus: false,
    },
    doctorPhone: {
        required: true,
        editableStatus: false,
    },
    healthAlert: {
        required: true,
        editableStatus: false,
    },
    noThreatMedDetails: {
        required: true,
        editableStatus: false,
    },
    allergies: {
        required: true,
        editableStatus: false,
    },
};
