import FormHeader from '@Components/FormHeader';
import React from 'react';
import InnerFormHeader from '../InnerFormHeader';
import { FormComponentProps, ManageDatePickerProps, ManageSwitchInputProps, ManageTextProps } from '@Hooks';
import { useTranslation } from 'react-i18next';
import { Grid } from '@chakra-ui/react';
import SelectInput from '@Components/SelectInput';
import TextInput from '@Components/TextInput';
import {
    useGetAboriginalSelfRequestQuery,
    useGetBoardResidenceStatusCodesRequestQuery,
    useGetCountriesRequestQuery,
    useGetLanguagesRequestQuery,
    useGetProvincesRequestQuery,
    useGetResidenceStatusCodesRequestQuery,
} from '@Redux/services/LookupApi';
import SvgCitizenship from '@Icon/Citizenship';
import DateTimeInput from '@Components/DateTimeInput';
import Switch from '@Components/Switch';

const Citizenship: React.FC<FormComponentProps> = ({
    id,
    manageFieldPropFactory,
    selectFieldPropFactory,
    textFieldPropFactory,
    switchFieldPropFactory,
}) => {
    const [t] = useTranslation();

    const { data: { data: countries = [] } = {} } = useGetCountriesRequestQuery({ searchText: '' });
    const { data: { data: provinces = [] } = {} } = useGetProvincesRequestQuery({ searchText: '' });
    const { data: { data: languages = [] } = {} } = useGetLanguagesRequestQuery({ searchText: '' });
    const { data: { data: aboriginalSelfIdentifications = [] } = {} } = useGetAboriginalSelfRequestQuery({
        searchText: '',
    });
    const { data: { data: boardResidenceStatusCodes = [] } = {} } = useGetBoardResidenceStatusCodesRequestQuery({
        searchText: '',
    });
    const { data: { data: residenceStatusCodes = [] } = {} } = useGetResidenceStatusCodesRequestQuery({
        searchText: '',
    });

    return (
        <FormHeader
            id={id}
            header={
                <InnerFormHeader
                    divider={true}
                    title={t('RegistrationDetails.citizenship')}
                    icon={<SvgCitizenship />}
                />
            }
            label={''}
        >
            <Grid
                column={{ md: 2, sm: 1 }}
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                }}
                rowGap={4}
                templateRows='auto'
                mb={6}
            >
                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.citizenship.countryOfBirth'), [
                        'citizenshipInformation',
                        'countryOfBirth',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['citizenshipInformation', 'countryOfBirth'],
                        'onChangeValue',
                        'value'
                    )}
                    options={countries}
                />

                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.citizenship.provinceOfBirth'), [
                        'citizenshipInformation',
                        'provinceOfBirth',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['citizenshipInformation', 'provinceOfBirth'],
                        'onChangeValue',
                        'value'
                    )}
                    options={provinces}
                />

                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.citizenship.countryOfCitizenship'), [
                        'citizenshipInformation',
                        'countryOfCitizenship',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['citizenshipInformation', 'countryOfCitizenship'],
                        'onChangeValue',
                        'value'
                    )}
                    options={countries}
                />

                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.citizenship.studentFirstLanguage'), [
                        'citizenshipInformation',
                        'firstLanguage',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['citizenshipInformation', 'firstLanguage'],
                        'onChangeValue',
                        'value'
                    )}
                    options={languages}
                />

                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.citizenship.languageSpokenAtHome'), [
                        'citizenshipInformation',
                        'languageAtHome',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['citizenshipInformation', 'languageAtHome'],
                        'onChangeValue',
                        'value'
                    )}
                    options={languages}
                />

                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.citizenship.aboriginalSelfIdentification'), [
                        'citizenshipInformation',
                        'aboriginalSelfId',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['citizenshipInformation', 'aboriginalSelfId'],
                        'onChangeValue',
                        'value'
                    )}
                    options={aboriginalSelfIdentifications}
                />

                <DateTimeInput
                    {...textFieldPropFactory(t('RegistrationDetails.citizenship.expirationDate'), [
                        'citizenshipInformation',
                        'expirationDate',
                    ])}
                    {...manageFieldPropFactory<ManageDatePickerProps>(
                        ['citizenshipInformation', 'expirationDate'],
                        'onDateChange',
                        'date'
                    )}
                    size='md'
                />

                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.citizenship.boardResidenceStatus'), [
                        'citizenshipInformation',
                        'boardResidenceStatus',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['citizenshipInformation', 'boardResidenceStatus'],
                        'onChangeValue',
                        'value'
                    )}
                    options={boardResidenceStatusCodes}
                />

                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.citizenship.residenceStatus'), [
                        'citizenshipInformation',
                        'residenceStatus',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['citizenshipInformation', 'residenceStatus'],
                        'onChangeValue',
                        'value'
                    )}
                    options={residenceStatusCodes}
                />

                <Switch
                    {...switchFieldPropFactory(t('RegistrationDetails.citizenship.moreInfo'), [
                        'citizenshipInformation',
                        'extraInfoAboriginalEducation',
                    ])}
                    {...manageFieldPropFactory<ManageSwitchInputProps>(
                        ['citizenshipInformation', 'extraInfoAboriginalEducation'],
                        'onChangeSwitch',
                        'isChecked'
                    )}
                />
            </Grid>
        </FormHeader>
    );
};

export default Citizenship;
