import { Box } from '@chakra-ui/react';
import Table from '@Components/Table';
import TableActions from '@Components/TableActions';
import { useToast } from '@Hooks/useToast';
import { useExportAnswerResponsesMutation } from '@Redux/services/Event';
import { Answer } from '@Redux/services/Event/types';
import { downloadFile, parseError, parseWarning } from '@Utilities';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, SortingRule } from 'react-table';
import { EXCEL_FILE_CONTENT_TYPE } from 'src/constants';
import YesNoCell from '../YesNoCell';
import { TableFilters } from '../../../TableFilters';

interface IProps {
    answers?: Answer[];
    eventId: string;
    isLoading: boolean;
    sortBy?: SortingRule<Answer>[];
    onSortChange: (sortBy: SortingRule<Answer>[]) => void;
    handleFilterChange: (key: string) => (value: any) => void;
    filters: any;
}

const AnswersTable: FC<IProps> = ({
    eventId,
    isLoading,
    sortBy,
    onSortChange,
    answers = [],
    handleFilterChange,
    filters,
}) => {
    const { t } = useTranslation();
    const [exportEventAnswers] = useExportAnswerResponsesMutation();
    const toast = useToast();
    const columns = useMemo<Column[]>(
        () => [
            {
                Header: t('AnswersTable.studentName'),
                accessor: 'fullName',
                headerAlign: 'left',
            },
            {
                Header: t('AnswersTable.eventQuestion'),
                accessor: 'name',
                headerAlign: 'left',
            },
            {
                Header: t('AnswersTable.text'),
                accessor: 'text',
                headerAlign: 'left',
            },
            {
                Header: t('AnswersTable.response'),
                accessor: 'answer',
                Cell: YesNoCell,
            },
            {
                Header: t('AnswersTable.comment'),
                accessor: 'comment',
                headerAlign: 'left',
            },
        ],
        []
    );

    const handleExport = async () => {
        await exportEventAnswers({
            eventId,
            sortBy: sortBy?.length ? sortBy[0].id : undefined,
            isDesc: sortBy?.length ? sortBy[0].desc : undefined,
            ...filters,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                downloadFile(EXCEL_FILE_CONTENT_TYPE, response.data.fileContents, response.data.fileName);
            })
            .catch((err) => parseError(toast, err));
    };
    return (
        <Box mt={8}>
            <Table
                action={
                    <TableActions
                        onExportClick={handleExport}
                        onSearch={handleFilterChange('searchText')}
                        searchText={filters.searchText}
                    />
                }
                manualSortBy={true}
                columns={columns}
                rawData={answers}
                filter={<TableFilters onChange={handleFilterChange} filters={filters} />}
                emptyMessage={t('AnswersTable.emptyTable')}
                isLoading={isLoading}
                onSortChange={onSortChange}
            />
        </Box>
    );
};

export default AnswersTable;
