import { defineStyle } from '@chakra-ui/styled-system';
const commonStyle = defineStyle({
    fontFamily: 'Inter',
});

const fontStyles = {
    '7xl': defineStyle({
        fontSize: '7xl',
        lineHeight: '92px',
        ...commonStyle,
    }),
    '6xl': defineStyle({
        fontSize: '6xl',
        lineHeight: '72px',
        ...commonStyle,
    }),
    '5xl': defineStyle({
        fontSize: '5xl',
        lineHeight: '60px',
        ...commonStyle,
    }),
    '4xl': defineStyle({
        fontSize: '4xl',
        lineHeight: '44px',
        ...commonStyle,
    }),
    '3xl': defineStyle({
        fontSize: '3xl',
        lineHeight: '38px',
        ...commonStyle,
    }),
    '2xl': defineStyle({
        fontSize: '2xl',
        lineHeight: '32px',
        ...commonStyle,
    }),
    xl: defineStyle({
        fontSize: 'xl',
        lineHeight: '30px',
        ...commonStyle,
    }),
    lg: defineStyle({
        fontSize: 'lg',
        lineHeight: '28px',
        ...commonStyle,
    }),
    md: defineStyle({
        fontSize: 'md',
        lineHeight: '24px',
        ...commonStyle,
    }),
    sm: defineStyle({
        fontSize: 'sm',
        lineHeight: '20px',
        ...commonStyle,
    }),
    xs: defineStyle({
        fontSize: 'xs',
        lineHeight: '18px',
        ...commonStyle,
    }),
};

const fontWeights = ['normal', 'medium', 'semibold', 'bold'];

const textStyles = (function () {
    const sizes = {};
    for (const size of Object.keys(fontStyles)) {
        for (const weight of fontWeights) {
            sizes[`${size}-${weight}`] = defineStyle({ fontWeight: weight, ...fontStyles[size] });
        }
    }
    return sizes;
})();

export default textStyles;
