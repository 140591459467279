import { VStack } from '@chakra-ui/layout';
import Drawer from '@Components/Drawer';
import Switch from '@Components/Switch';
import Textarea from '@Components/Textarea';
import TextInput from '@Components/TextInput';
import { useForms } from '@Hooks';
import {
    useCreateQuestionMutation,
    useDeleteQuestionMutation,
    useGetQuestionMutation,
    useUpdateQuestionMutation,
} from '@Redux/services/Event';
import { Question } from '@Redux/services/Event/types';
import { getMutableForAccessor, parseError, parseWarning } from '@Utilities';
import React, { FC, FormEvent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Validator } from './validator';
import type { PartialDeep } from 'type-fest';
import { Spinner } from '@chakra-ui/react';
import { useToast } from '@Hooks/useToast';
import { AuditInfo } from '@Components/AuditInfo';
import { DrawerFooterActions } from '@Components/DrawerFooterActions';
import { MenuItem } from '@Components/Menu';

interface IProps {
    questionId?: string;
    eventId?: string;
    isOpen: boolean;
    onSubmit?: (questions: Question[]) => void;
    onClose: () => void;
    onCancel: () => void;
    isEditable: boolean;
    label?: string | React.ReactNode;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
}

const QuestionForm: FC<IProps> = ({
    isEditable,
    size,
    label,
    eventId,
    questionId,
    isOpen,
    onCancel,
    onSubmit,
    onClose,
}) => {
    const defaultFormData = useMemo<PartialDeep<Question>>(
        () => ({
            yesNoTypeOfQuestion: false,
            isCommentRequired: false,
            isCommentRequiredNo: false,
            isRequired: false,
        }),
        []
    );
    const {
        formData: question,
        manageFieldPropFactory,
        textFieldPropFactory,
        textAreaPropFactory,
        setErrors,
        setFormData: setQuestion,
        dirtyData,
        setDirtyData,
    } = useForms<PartialDeep<Question>>(!questionId ? defaultFormData : {}, () => ({ isLocked: !isEditable }));
    const { t } = useTranslation();
    const [deleteQuestion, { isLoading: deleting }] = useDeleteQuestionMutation();
    const toast = useToast();
    const [createQuestion, { isLoading: creating }] = useCreateQuestionMutation();
    const [updateQuestion, { isLoading: updating }] = useUpdateQuestionMutation();
    const [getQuestion, { isLoading }] = useGetQuestionMutation();
    const isDirty = useMemo(() => dirtyData && Object.keys(dirtyData).length > 0, [dirtyData, setDirtyData]);
    useEffect(() => {
        (async () => {
            if (questionId) {
                await getQuestion({
                    eventId: eventId,
                    eventQuestionId: questionId,
                })
                    .unwrap()
                    .then((response) => {
                        parseWarning(toast, response);
                        setQuestion(response.data);
                    });
            }
        })();
        if (!questionId) {
            const newQuestion = { ...question };
            newQuestion.eventId = eventId;
            setQuestion(newQuestion);
        }
    }, [questionId]);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const nextErrors: Record<string, string> = {};
        const validationRules = Validator(question, t);

        validationRules.forEach(({ accessorPath, validator, message }) => {
            const finalMutable = getMutableForAccessor(question, accessorPath);
            const finalProperty = accessorPath[accessorPath.length - 1];
            if (!validator(finalMutable[finalProperty])) {
                nextErrors[accessorPath.join('.')] = message;
            }
        });

        if (Object.keys(nextErrors).length) {
            setErrors(nextErrors);
            return false;
        }
        let saveFunction = createQuestion;
        let successDescription = t('QuestionForm.toastCreateSuccess');
        let formData = { ...question, eventId };
        if (questionId) {
            saveFunction = updateQuestion;
            formData = { ...dirtyData, eventQuestionId: question.eventQuestionId, eventId };
            successDescription = t('QuestionForm.toastUpdateSuccess');
        }

        await saveFunction(formData)
            .unwrap()
            .then((response) => {
                if (response?.warningResult?.showAlert) {
                    parseWarning(toast, response);
                } else {
                    toast({
                        status: 'success',
                        description: successDescription,
                    });
                }
                onSubmit(response.data);
                setDirtyData({});
            })
            .catch((e) => {
                setErrors(e.data.errors);
                parseError(toast, e);
            });
    };

    if (!isOpen) {
        return;
    }

    const handleDelete = async () => {
        await deleteQuestion({
            eventId,
            eventQuestionId: questionId,
        })
            .unwrap()
            .then((response) => {
                if (response?.warningResult?.showAlert) {
                    parseWarning(toast, response);
                } else {
                    toast({
                        status: 'success',
                        description: t('QuestionForm.toastDeleteSuccess'),
                    });
                }
                onSubmit(response.data);
            })
            .catch((e) => {
                parseError(toast, e);
            });
    };

    const actionMenuItems = useMemo<MenuItem[]>(
        () =>
            questionId
                ? [
                      {
                          label: t('QuestionForm.delete'),
                          message: t('QuestionForm.confirmationMessage'),
                          showConfirmation: true,
                          menuItemProps: {
                              onClick: async () => await handleDelete(),
                              isDisabled: creating || updating || deleting,
                          },
                      },
                  ]
                : [],
        [questionId, creating, updating, deleting, question]
    );

    return (
        <>
            {isOpen && (
                <Drawer
                    size={size}
                    onOverlayClick={onClose}
                    isOpen={isOpen}
                    onClose={onClose}
                    onSubmit={handleSubmit}
                    isForm={true}
                    isLoading={isLoading}
                    title={label ?? t('QuestionForm.title')}
                    subtitle={t('QuestionForm.subTitle')}
                    footer={
                        <DrawerFooterActions
                            isEditable={isEditable}
                            closeButtonProps={{
                                onClick: onCancel,
                                isDisabled: creating || updating || deleting,
                                label: t('QuestionForm.closeDrawer'),
                            }}
                            saveButtonProps={{
                                type: 'submit',
                                label: t('QuestionForm.save'),
                                isDisabled: creating || updating || deleting || !isDirty,
                            }}
                            actionMenuItems={isEditable && questionId && actionMenuItems}
                        />
                    }
                >
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <VStack spacing={5}>
                            <TextInput
                                {...textFieldPropFactory(t('QuestionForm.titleLabel'), ['title'])}
                                {...manageFieldPropFactory(['title'], 'onChangeText', 'value')}
                                isRequired={true}
                            />

                            <Textarea
                                {...textAreaPropFactory(t('QuestionForm.text'), ['text'])}
                                {...manageFieldPropFactory(['text'], 'onChangeText', 'value')}
                                isRequired={true}
                            />
                            <Switch
                                label={t('QuestionForm.required')}
                                {...manageFieldPropFactory(['isRequired'], 'onChangeSwitch', 'isChecked')}
                                isDisabled={!isEditable}
                            />
                            <Switch
                                label={t('QuestionForm.yesNoQuestion')}
                                {...manageFieldPropFactory(['yesNoTypeOfQuestion'], 'onChangeSwitch', 'isChecked')}
                                isDisabled={!isEditable}
                            />
                            {question.yesNoTypeOfQuestion && (
                                <>
                                    <Switch
                                        label={t('QuestionForm.commentRequiredIfYes')}
                                        {...manageFieldPropFactory(
                                            ['isCommentRequired'],
                                            'onChangeSwitch',
                                            'isChecked'
                                        )}
                                        isDisabled={!isEditable}
                                    />
                                    <Switch
                                        label={t('QuestionForm.commentRequiredIfNo')}
                                        {...manageFieldPropFactory(
                                            ['isCommentRequiredNo'],
                                            'onChangeSwitch',
                                            'isChecked'
                                        )}
                                        isDisabled={!isEditable}
                                    />
                                </>
                            )}
                            {questionId && (
                                <AuditInfo
                                    createdOn={question.createdOn}
                                    createdBy={question.createdByFullName}
                                    modifiedBy={question.modifiedByFullName}
                                    modifiedOn={question.modifiedOn}
                                />
                            )}
                        </VStack>
                    )}
                </Drawer>
            )}
        </>
    );
};

export default QuestionForm;
