import { Box, Flex, Grid, Stack, Text } from '@chakra-ui/react';
import SectionHeader from '@Components/SectionHeader';
import { Document } from '@Icon';
import { ReportingPeriodDetails } from '@Redux/services/Reports/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '../TextField';
import { format, parseISO } from 'date-fns';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'src/constants';

interface IProps {
    id: string;
    details: ReportingPeriodDetails;
}

export const Summary: FC<IProps> = ({ id, details }) => {
    const { t } = useTranslation();

    return (
        <Stack id={id}>
            <SectionHeader icon={<Document />} title={t('ReportPeriod.Title')} />
            <Flex
                borderColor='border-primary'
                borderStyle='solid'
                borderWidth='2px'
                flexGrow={1}
                overflow={'hidden'}
                position={'relative'}
                borderRadius={6}
                backgroundColor={'white'}
            >
                <Box w='50%' borderRightColor='border-primary' borderRightStyle='solid' borderRightWidth='2px' px={6}>
                    <Text pt={4} textStyle={'md-medium'}>
                        {t('ReportPeriod.Stats.StudentSnapshot.title')}
                    </Text>
                    <Flex direction={'row'} alignItems={'baseline'} justifyContent={'space-between'} mt={3}>
                        <Text textStyle='sm-normal'>{t('ReportPeriod.Stats.StudentSnapshot.createDate')}</Text>
                        <Text textStyle='md-semibold'>
                            {details.reportTypes.length > 0
                                ? format(parseISO(details.reportTypes[0].studentSnapshotCreationDate), DATE_TIME_FORMAT)
                                : '-'}
                        </Text>
                    </Flex>
                    <Flex direction={'row'} alignItems={'baseline'} justifyContent={'space-between'} mb={4}>
                        <Text textStyle='sm-normal'>{t('ReportPeriod.Stats.StudentSnapshot.studentCount')}</Text>
                        <Text textStyle='md-semibold'>{details.studentSnapshot.pagedResult.rowCount}</Text>
                    </Flex>
                </Box>
                <Box w='50%' px={6}>
                    <Text pt={4} textStyle={'md-medium'}>
                        {t('ReportPeriod.Stats.Review.title')}
                    </Text>
                    <Flex direction={'row'} alignItems={'baseline'} justifyContent={'space-between'} mt={3}>
                        <Text textStyle='sm-normal'>{t('ReportPeriod.Stats.Review.publishReadyCount')}</Text>
                        <Text textStyle='md-semibold'>{details.pendingReports}</Text>
                    </Flex>
                    <Flex direction={'row'} alignItems={'baseline'} justifyContent={'space-between'} mb={4}>
                        <Text textStyle='sm-normal'>{t('ReportPeriod.Stats.Review.publishedCount')}</Text>
                        <Text textStyle='md-semibold'>{details.publishedReports}</Text>
                    </Flex>
                </Box>
            </Flex>
            <Grid
                column={{ md: 2, sm: 1 }}
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                }}
                rowGap={4}
            >
                <TextField label={t('ReportPeriod.School')} text={details.school} />
                <TextField
                    label={t('ReportPeriod.SchoolYear')}
                    text={`${format(parseISO(details?.schoolYearStart), 'yyyy')}-${format(
                        parseISO(details?.schoolYearEnd),
                        'yyyy'
                    )}`}
                />
                <TextField label={t('ReportPeriod.reportingPeriod')} text={details.reportingPeriod} />
                <TextField label={t('ReportPeriod.Principal')} text={details.principal} />
                <TextField
                    label={t('ReportPeriod.takeHomeDate')}
                    text={
                        details.reportTypes.length > 0
                            ? format(parseISO(details.reportTypes[0].sendHomeDate), DATE_FORMAT)
                            : '-'
                    }
                />
                <TextField label={t('ReportPeriod.vicePrincipal')} text={details.vicePrincipal} />
            </Grid>
        </Stack>
    );
};
