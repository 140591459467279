import { baseApi, PATCH, POST } from '../base';
import { EventResponseDetail, EventResponseIdParams } from './types';
import type { PartialDeep } from 'type-fest';
import { Answer, CostItemResponseAnswer } from '../Event/types';
import { BaseResponse } from '../commonTypes';

const eventResponseApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getEventResponseDetail: builder.mutation<BaseResponse<EventResponseDetail>, EventResponseIdParams>({
            query: (body) => ({
                url: 'EventResponse/GetEventResponse',
                method: POST,
                body,
            }),
        }),
        updateEventResponse: builder.mutation<BaseResponse<EventResponseDetail>, PartialDeep<EventResponseDetail>>({
            query: (body) => ({
                url: 'EventResponse/UpdateEventResponse',
                method: PATCH,
                body,
            }),
        }),
        updateEventResponseCostItem: builder.mutation<
            BaseResponse<EventResponseDetail>,
            PartialDeep<CostItemResponseAnswer>
        >({
            query: (body) => ({
                url: 'EventResponse/UpdateEventResponseCostItem',
                method: PATCH,
                body,
            }),
        }),
        updateEventResponseAnswer: builder.mutation<BaseResponse<Answer[]>, PartialDeep<Answer>>({
            query: (body) => ({
                url: 'EventResponse/UpdateEventResponseAnswer',
                method: PATCH,
                body,
            }),
        }),
    }),
});

export const {
    useGetEventResponseDetailMutation,
    useUpdateEventResponseMutation,
    useUpdateEventResponseCostItemMutation,
    useUpdateEventResponseAnswerMutation,
} = eventResponseApi;

export default eventResponseApi;
