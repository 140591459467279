import { DetailPageSkeleton } from '@Components/DetailPageSkeleton';
import { useForms } from '@Hooks';
import { useGetLateAbsenceNoteDetailsQuery } from '@Redux/services/Absence';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { editableFieldsForLateAbsence } from './types';
import { isUndefined } from 'lodash';
import { Accessor } from '@Utilities';
import { ILateAbsenceNoteDetails } from '@Redux/services/Absence/types';
import AvatarLabel from '@Components/AvatarLabel';
import Sidebar from '@Components/Sidebar';
import { Flex, Stack } from '@chakra-ui/react';
import Info from './components/Info';
import GeneralInfo from './components/GeneralInfo';
import { Alarm, Document, Forum, History } from '@Icon';
import ActivityLog from './components/ActivityLog';
import Note from './components/Note';

type IRouteParams = {
    id: string;
};

export const LateAbsenceDetails = () => {
    const containerRef = useRef<HTMLDivElement>(null);
    const params = useParams<IRouteParams>();
    const {
        refetch: getLateAbsenceDetails,
        data: { data: lateAbsenceDetailsData } = {},
        isLoading,
    } = useGetLateAbsenceNoteDetailsQuery({ attendanceId: params.id }, { skip: !params.id });

    const { t } = useTranslation();

    const getCommonProps = (accessorPath: Accessor[]) => {
        const accesor = accessorPath.join('.');
        const item = editableFieldsForLateAbsence[accesor];
        const hasStatus = !isUndefined(item) ? item.editableStatus : false;
        const isLocked = isUndefined(item) ? true : !hasStatus;
        const isRequired = hasStatus && item.required;
        return {
            isLocked,
            isRequired,
        };
    };

    const {
        formData: lateAbsenceNoteData,
        manageFieldPropFactory,
        selectFieldPropFactory,
        textFieldPropFactory,
        typeaheadPropFactory,
        dateTimePickerPropFactory,
        switchFieldPropFactory,
        textAreaPropFactory,
        setFormData: setLateAbsenceDetail,
    } = useForms<ILateAbsenceNoteDetails>(lateAbsenceDetailsData, getCommonProps);

    useEffect(() => {
        getLateAbsenceDetails();
    }, [params.id]);

    useEffect(() => {
        setLateAbsenceDetail(lateAbsenceDetailsData);
    }, [lateAbsenceDetailsData]);

    const sideBarItems = useMemo(
        () => ({
            header: (
                <AvatarLabel icon={<Alarm />} name={lateAbsenceDetailsData?.student} subTitle={t('Sidebar.Student')} />
            ),
            groups: [
                {
                    items: [
                        {
                            label: t('LateAbsence.subheader.general'),
                            id: '#general',
                            icon: <Document />,
                            active: false,
                        },
                        {
                            label: t('LateAbsence.subheader.note'),
                            id: '#note',
                            icon: <Forum />,
                            active: false,
                        },
                        {
                            label: t('LateAbsence.subheader.activityLog'),
                            id: '#activity-log',
                            icon: <History />,
                            active: false,
                        },
                    ],
                },
            ],
        }),
        [lateAbsenceNoteData]
    );

    if (isLoading || !lateAbsenceNoteData) {
        return <DetailPageSkeleton />;
    }

    return (
        <Flex>
            <Sidebar
                {...sideBarItems}
                footerDetails={{
                    modifiedByFullName: lateAbsenceDetailsData?.modifiedBy,
                    modifiedOn: lateAbsenceDetailsData?.dateAbsenceSubmitted.toString(),
                    createdByFullName: lateAbsenceDetailsData?.createdBy,
                    createdOn: lateAbsenceDetailsData?.createdOn?.toString(),
                }}
            />
            <Flex
                align={'flex-start'}
                direction={'column'}
                overflow={'hidden'}
                width='100%'
                ref={containerRef}
                bg='surface-primary'
                gap={8}
                pl={2}
            >
                <Info
                    manageFieldPropFactory={manageFieldPropFactory}
                    lateAbsenceDetails={lateAbsenceDetailsData}
                    setLateAbsenceDetails={setLateAbsenceDetail}
                    typeaheadPropFactory={typeaheadPropFactory}
                />
                <Stack
                    borderRadius='20px 0 0 0'
                    borderWidth='2px 0px 2px 2px'
                    borderStyle='solid'
                    borderColor='border-primary'
                    w={'full'}
                    p={8}
                    bg={'surface-secondary'}
                    gap={8}
                >
                    <GeneralInfo
                        id='general'
                        manageFieldPropFactory={manageFieldPropFactory}
                        textFieldPropFactory={textFieldPropFactory}
                        selectFieldPropFactory={selectFieldPropFactory}
                        lateAbsenceDetails={lateAbsenceDetailsData}
                        setLateAbsenceDetails={setLateAbsenceDetail}
                        dateTimePickerPropFactory={dateTimePickerPropFactory}
                        switchFieldPropFactory={switchFieldPropFactory}
                    />
                    <Note
                        id='note'
                        textAreaPropFactory={textAreaPropFactory}
                        manageFieldPropFactory={manageFieldPropFactory}
                        lateAbsenceDetails={lateAbsenceDetailsData}
                        setLateAbsenceDetails={setLateAbsenceDetail}
                        dateTimePickerPropFactory={dateTimePickerPropFactory}
                    />
                    <ActivityLog
                        id='activity-log'
                        lateAbsenceDetails={lateAbsenceDetailsData}
                        manageFieldPropFactory={manageFieldPropFactory}
                        setLateAbsenceDetails={setLateAbsenceDetail}
                    />
                </Stack>
            </Flex>
        </Flex>
    );
};

export default LateAbsenceDetails;
