import { Box, Flex, Progress, Text, useDisclosure } from '@chakra-ui/react';
import { AlertDialog, Button } from '@Components';
import Dropzone from '@Components/DropZone';
import { useToast } from '@Hooks/useToast';
import { useUploadReportCardMutation } from '@Redux/services/Reports';
import { jobs_processing_status, UploadedJob } from '@Redux/services/Reports/types';
import { primitives } from '@Theme/foundations/colors';
import { parseError } from '@Utilities';
import { isNull, isUndefined } from 'lodash';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface IProps {
    reportingPeriodId: string;
    schoolReportingDayId: string;
    latestJob: UploadedJob;
    pendingReports: number;
    school: string;
    onRefresh: () => Promise<void>;
}

export const ImportItem: React.FC<IProps> = ({
    reportingPeriodId,
    schoolReportingDayId,
    latestJob,
    pendingReports,
    school,
    onRefresh,
}) => {
    const [ignorePrintDateValidation, setIgnorePrintDateValidation] = useState(false);
    const [ignorePrintDateValidationMessage, setIgnorePrintDateValidationMessage] = useState();
    const [file, setFile] = useState<File>();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isEmptyContent, setIsEmptyContent] = useState<boolean>(false);
    const [uploaded, setUploaded] = useState<boolean>(false);
    const { t } = useTranslation();
    const toast = useToast();
    const navigate = useNavigate();
    const [uploadReportCardMutation, { isLoading: isImporting }] = useUploadReportCardMutation();

    const handleImport = async () => {
        const formdata = new FormData();
        formdata.append('file', file);
        formdata.append('ReportingPeriodId', reportingPeriodId);
        formdata.append('SchoolReportingDayId', schoolReportingDayId);
        formdata.append('IgnorePrintDateValidation', `${ignorePrintDateValidation}`);
        await uploadReportCardMutation(formdata)
            .unwrap()
            .then(() => {
                toast({ description: t('ImportItem.toastUploadSuccessful'), status: 'success' });
                onRefresh();
                setIsEmptyContent(true);
                setIgnorePrintDateValidation(false);
                setIgnorePrintDateValidationMessage(undefined);
                setUploaded(true);
                onClose();
            })
            .catch((e) => {
                if (
                    'printDate' in e.data.errors &&
                    !isNull(e.data.errors['printDate']) &&
                    !isUndefined(e.data.errors['printDate'])
                ) {
                    setIgnorePrintDateValidation(true);
                    setIgnorePrintDateValidationMessage(e.data.errors['printDate']);
                    onClose();
                } else {
                    const newData = { ...e.data, title: t('ImportItem.AlertDialog.errorTitle') };
                    const newEvent = { ...e, data: newData };
                    parseError(toast, newEvent);
                    onClose();
                    setIgnorePrintDateValidation(false);
                }
            });
    };

    const getPbProgress = (jobProcessingStatus?: number) => {
        switch (jobProcessingStatus) {
            case jobs_processing_status.Uploaded:
            default:
                return 0;
            case jobs_processing_status.Validating:
                return 25;
            case jobs_processing_status.ValidatedSuccessfuly:
                return 50;
            case jobs_processing_status.Splitting:
                return 75;
            case jobs_processing_status.Completed:
            case jobs_processing_status.ValidationFailed:
            case jobs_processing_status.Failed:
                return 100;
        }
    };

    const getPbVariant = (jobProcessingStatus?: number) => {
        switch (jobProcessingStatus) {
            case jobs_processing_status.ValidationFailed:
            case jobs_processing_status.Failed:
            default:
                return 'error';
            case jobs_processing_status.Validating:
            case jobs_processing_status.ValidatedSuccessfuly:
            case jobs_processing_status.Splitting:
            case jobs_processing_status.Uploaded:
            case jobs_processing_status.Completed:
                return 'success';
        }
    };

    const progressLabels = () => {
        switch (latestJob?.jobProcessingStatus) {
            case jobs_processing_status.Uploaded:
                return t('ImportItem.jobStatus.uploaded');
            case jobs_processing_status.Validating:
                return t('ImportItem.jobStatus.validating');
            case jobs_processing_status.ValidatedSuccessfuly:
                return t('ImportItem.jobStatus.validated');
            case jobs_processing_status.ValidationFailed:
                return t('ImportItem.jobStatus.failed');
            case jobs_processing_status.Splitting:
                return t('ImportItem.jobStatus.splitting');
            case jobs_processing_status.Completed:
                return uploaded || pendingReports > 0
                    ? t('ImportItem.jobStatus.completed', { count: pendingReports })
                    : '';
            case jobs_processing_status.Failed:
                return t('ImportItem.jobStatus.failed');
            default:
                return '';
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Box borderRadius={'8px'}>
            <Flex
                borderColor={'border-primary'}
                borderWidth='1px'
                borderRadius='8px'
                borderStyle='dashed'
                justifyContent='space-between'
                w='100%'
            >
                <Dropzone
                    flexBasis='50%'
                    width='50%'
                    onSelect={(newFiles) => {
                        setFile(newFiles[0]);
                        setIsEmptyContent(false);
                    }}
                    file={file}
                    onImport={onOpen}
                    border={'none'}
                    inputProps={{ accept: 'application/pdf' }}
                    buttonProps={{
                        display: isEmptyContent ? 'none' : 'inline-flex',
                        disabled: isImporting,
                    }}
                />
                <Flex
                    justifyContent='space-between'
                    alignItems='center'
                    flexBasis='50%'
                    borderLeft={'1px'}
                    borderColor={'border-primary'}
                    p='16px'
                    bg={
                        (!latestJob || jobs_processing_status.Failed === latestJob?.jobProcessingStatus) &&
                        'surface-secondary'
                    }
                >
                    <Box gap={0} w='100%'>
                        <Text
                            textStyle='sm-normal'
                            p={0}
                            color={
                                !latestJob ||
                                pendingReports == 0 ||
                                latestJob?.jobProcessingStatus !== jobs_processing_status.Completed
                                    ? 'text-secondary'
                                    : 'surface-brand-primary'
                            }
                        >
                            {isEmptyContent && latestJob && progressLabels() && (
                                <Flex justifyContent='space-between' w='100%' alignItems='center'>
                                    <Flex direction={'column'}>
                                        <Text
                                            color={
                                                latestJob?.jobProcessingStatus === jobs_processing_status.Completed
                                                    ? primitives.teal[600]
                                                    : ''
                                            }
                                        >
                                            {progressLabels()}
                                        </Text>
                                        <Flex justifyContent='space-between' w='100%' alignItems='center'>
                                            <Progress
                                                size={'xs'}
                                                w={'100%'}
                                                value={getPbProgress(latestJob?.jobProcessingStatus)}
                                                variant={getPbVariant(latestJob?.jobProcessingStatus)}
                                            />
                                            <Text ml={2} textStyle='xs-normal' color='text-secondary'>
                                                {getPbProgress(latestJob?.jobProcessingStatus)}%
                                            </Text>
                                        </Flex>
                                    </Flex>
                                    {latestJob?.jobProcessingStatus === jobs_processing_status.Completed ? (
                                        <Button ml={4} variant='primary' onClick={() => navigate('#students')}>
                                            <Trans i18nKey='ImportItem.jobStatus.step2ButtonLabel' />
                                        </Button>
                                    ) : (
                                        latestJob?.jobProcessingStatus !== jobs_processing_status.ValidationFailed &&
                                        latestJob?.jobProcessingStatus !== jobs_processing_status.Failed && (
                                            <Button ml={4} variant='primary' onClick={onRefresh}>
                                                <Trans i18nKey='ImportItem.jobStatus.refreshButtonLabel' />
                                            </Button>
                                        )
                                    )}
                                </Flex>
                            )}
                        </Text>
                    </Box>
                </Flex>
            </Flex>
            <AlertDialog
                isOpen={isOpen}
                submitHandle={handleImport}
                alertType='brand'
                onClose={handleClose}
                title={t('ImportItem.AlertDialog.title')}
                cancelButtonLabel={t('ImportItem.AlertDialog.cancel')}
                submitLabel={t('ImportItem.AlertDialog.submit')}
                message={t('ImportItem.AlertDialog.message', { name: school })}
                submitButtonProps={{
                    variant: 'primary',
                    disabled: isImporting,
                }}
                cancelButtonProps={{
                    variant: 'outline',
                    disabled: isImporting,
                }}
            >
                <Text mt={3}>
                    <Trans i18nKey='ImportItem.AlertDialog.note' components={[<b />]} values={{ name: school }} />
                </Text>
            </AlertDialog>
            <AlertDialog
                isOpen={ignorePrintDateValidation}
                submitHandle={handleImport}
                alertType='brand'
                onClose={() => setIgnorePrintDateValidation(false)}
                title={t('ImportItem.IgnorePrintValidatinAlertDialog.title')}
                cancelButtonLabel={t('ImportItem.IgnorePrintValidatinAlertDialog.cancel')}
                submitLabel={t('ImportItem.IgnorePrintValidatinAlertDialog.submit')}
                message={ignorePrintDateValidationMessage}
                submitButtonProps={{
                    variant: 'error',
                    disabled: isImporting,
                }}
                cancelButtonProps={{
                    variant: 'outline',
                    disabled: isImporting,
                }}
            ></AlertDialog>
        </Box>
    );
};
