import { SearchIcon } from '@chakra-ui/icons';
import { Box, Flex, Button, Text, IconButton } from '@chakra-ui/react';
import TextInput from '@Components/TextInput';
import { Plus } from '@Icon';
import { isString } from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
    label?: React.ReactNode;
    onSearch?: (value: string) => void;
    searchText?: string;
    onAddClick?: () => void;
    onExportClick?: () => void;
    headerBg?: string;
}

const TableActions: FC<IProps> = ({ searchText, label, headerBg, onSearch, onAddClick, onExportClick }) => {
    const { t } = useTranslation();
    return (
        <Box
            mb={(label || onSearch || onAddClick || onExportClick) && 4} // creating a gap in table so commenting for now
        >
            <Box width={'100%'} position={'absolute'} top={'-13px'} pl={2} pr={2}>
                <Flex justifyContent='space-between' alignItems='flex-start'>
                    <Box>
                        {isString(label) ? (
                            <Text
                                as='span'
                                textStyle={'md-semibold'}
                                bg={headerBg ?? 'surface-secondary'}
                                h={4}
                                fontWeight='semibold'
                            >
                                {label}
                            </Text>
                        ) : (
                            label
                        )}
                    </Box>

                    <Flex justifyContent={'space-between'} alignItems='center'>
                        {onSearch && (
                            <Box mr={4}>
                                <TextInput
                                    maxWidth='100%'
                                    placeholder={t('Table.searchLabel')}
                                    isLocked={false}
                                    bg='surface-primary'
                                    rightIcon={<SearchIcon />}
                                    value={searchText}
                                    onChangeText={onSearch}
                                ></TextInput>
                            </Box>
                        )}
                        {onAddClick && (
                            <IconButton
                                title={t('TableActions.addButtonTitle')}
                                aria-label={t('TableActions.addButtonTitle')}
                                size={'xs'}
                                style={{ marginRight: 16 }}
                                icon={<Plus />}
                                onClick={onAddClick}
                            />
                        )}
                        {onExportClick && (
                            <Button
                                onClick={onExportClick}
                                bg='white'
                                borderColor='surface-primary'
                                justifyContent='center'
                                alignItems='center'
                                aria-label={t('TableActions.exportButtonTitle')}
                                size={'sm'}
                                title={t('TableActions.exportButtonTitle')}
                            >
                                {t('TableActions.exportButtonTitle')}
                            </Button>
                        )}
                    </Flex>
                </Flex>
            </Box>
        </Box>
    );
};

export default TableActions;
