import { Box, Text, VStack } from '@chakra-ui/react';
import Modal from '@Components/Modal';
import SelectInput from '@Components/SelectInput';
import TextInput from '@Components/TextInput';
import TypeaheadInput from '@Components/TypeaheadInput';
import { ManageTextProps, useForms } from '@Hooks';
import { useToast } from '@Hooks/useToast';
import { Plus } from '@Icon';
import { useCreateEventMutation } from '@Redux/services/Event';
import { Event } from '@Redux/services/Event/types';
import {
    useGetActivityTypesQuery,
    useGetCategoriesQuery,
    useGetOrganizedByQuery,
    useGetSchoolsQuery,
    useGetSubCategoriesQuery,
} from '@Redux/services/LookupApi';
import { getMutableForAccessor, parseError, parseWarning } from '@Utilities';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ACTIVITY_TYPE_SPORTS_EVENT, EXCLUDE_DEFAULT_SCHOOL_NAME } from 'src/constants';
import type { PartialDeep } from 'type-fest';
import { Validator } from './validator';
import { DrawerFooterActions } from '@Components/DrawerFooterActions';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
}

export const CreateEvent: FC<IProps> = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const { data: { data: schools = [] } = {} } = useGetSchoolsQuery({});
    const { data: { data: organizedBy = [] } = {} } = useGetOrganizedByQuery();
    const { data: { data: activityTypes = [] } = {} } = useGetActivityTypesQuery();
    const { data: { data: categories = [] } = {} } = useGetCategoriesQuery();
    const [createEvent, { isLoading }] = useCreateEventMutation();
    const {
        formData: eventData,
        textFieldPropFactory,
        manageFieldPropFactory,
        typeaheadPropFactory,
        selectFieldPropFactory,
        setErrors,
        setFormData: setEventData,
    } = useForms<PartialDeep<Event>>({}, () => ({ isLocked: false }));

    useEffect(() => {
        if (eventData.categoryId) {
            setEventData({
                ...eventData,
                subCategoryId: null,
            });
        }
    }, [eventData.categoryId]);

    useEffect(() => {
        if (schools?.length <= 2) {
            const school = schools.filter((school) => school.value !== EXCLUDE_DEFAULT_SCHOOL_NAME);
            setEventData({
                ...eventData,
                teamId: school.length > 0 && school[0].key.toString(),
            });
        }
    }, [eventData, schools]);

    const toast = useToast();
    const navigate = useNavigate();

    const { data: { data: subCategories = [] } = {} } = useGetSubCategoriesQuery(
        { parentCategoryId: eventData.categoryId, searchText: '' },
        { skip: !eventData.categoryId }
    );

    const handleSubmit = async () => {
        const nextErrors: Record<string, string> = {};
        const validationRules = Validator(t);

        validationRules.forEach(({ accessorPath, validator, message }) => {
            const finalMutable = getMutableForAccessor(eventData, accessorPath);
            const finalProperty = accessorPath[accessorPath.length - 1];
            if (!validator(finalMutable[finalProperty])) {
                nextErrors[accessorPath.join('.')] = message;
            }
        });

        if (Object.keys(nextErrors).length) {
            setErrors(nextErrors);
            return false;
        }

        await createEvent(eventData)
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                navigate(`/events/${response.data.eventId}`);
            })
            .catch((err) => {
                setErrors(err.data.errors);
                parseError(toast, err);
            });
    };

    return (
        <Modal
            title={t('CreateEvent.header')}
            isOpen={isOpen}
            onClose={onClose}
            scrollBehavior={'inside'}
            modalBodyProps={{ overflowY: 'scroll' }}
            footer={
                <DrawerFooterActions
                    isEditable={true}
                    closeButtonProps={{
                        isDisabled: isLoading,
                        label: t('CreateEvent.cancelButtonLabel'),
                        onClick: onClose,
                    }}
                    saveButtonProps={{
                        onClick: handleSubmit,
                        label: t('CreateEvent.createButtonLabel'),
                        isDisabled: isLoading,
                        leftIcon: <Plus width={20} height={20} />,
                    }}
                />
            }
        >
            <Box>
                <Text mb={'12px'} textStyle={'md-normal'} fontWeight='normal' color='secondary'>
                    {t('CreateEvent.subHeader')}
                </Text>
                <VStack gap={'16px'} mt={'8px'}>
                    <TextInput
                        {...textFieldPropFactory(t('CreateEvent.name'), ['name'])}
                        {...manageFieldPropFactory(['name'], 'onChangeText', 'value')}
                    />
                    <TypeaheadInput
                        {...manageFieldPropFactory<ManageTextProps>(['teamId'], 'onSelection', 'value')}
                        {...typeaheadPropFactory(t('CreateEvent.school'), ['teamId'])}
                        options={schools}
                    />

                    <SelectInput
                        {...selectFieldPropFactory(t('CreateEvent.organizedBy'), ['organizedById'])}
                        {...manageFieldPropFactory<ManageTextProps>(['organizedById'], 'onChangeValue', 'value')}
                        options={organizedBy}
                    />
                    <SelectInput
                        {...selectFieldPropFactory(t('CreateEvent.type'), ['activityTypeId'])}
                        {...manageFieldPropFactory<ManageTextProps>(['activityTypeId'], 'onChangeValue', 'value')}
                        options={activityTypes?.filter((a) => a.key !== ACTIVITY_TYPE_SPORTS_EVENT)}
                    />
                    <TypeaheadInput
                        {...typeaheadPropFactory(t('CreateEvent.category'), ['categoryId'])}
                        {...manageFieldPropFactory<ManageTextProps>(['categoryId'], 'onSelection', 'value')}
                        options={categories}
                        scrolltoView={true}
                        col={2}
                    />
                    <TypeaheadInput
                        {...typeaheadPropFactory(t('CreateEvent.subCategory'), ['subCategoryId'])}
                        {...manageFieldPropFactory<ManageTextProps>(['subCategoryId'], 'onSelection', 'value')}
                        scrolltoView={true}
                        options={subCategories}
                    />
                </VStack>
            </Box>
        </Modal>
    );
};
