import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormComponentProps, ManageSwitchInputProps, ManageTextProps } from '@Hooks';
import { Grid } from '@chakra-ui/react';
import TextInput from '@Components/TextInput';
import Switch from '@Components/Switch';

const MedicalDetails: React.FC<FormComponentProps> = ({
    manageFieldPropFactory,
    switchFieldPropFactory,
    textFieldPropFactory,
}) => {
    const [t] = useTranslation();

    return (
        <>
            <Grid
                column={{ md: 2, sm: 1 }}
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                }}
                rowGap={4}
                templateRows='auto'
                mb={6}
            >
                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.aboutStudent.medicalDetails.allergies'), [
                        'childInformation',
                        'allergies',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['childInformation', 'allergies'],
                        'onChangeText',
                        'value'
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.aboutStudent.medicalDetails.nonLife'), [
                        'childInformation',
                        'nonLifeThreateningMedicalDetails',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['childInformation', 'nonLifeThreateningMedicalDetails'],
                        'onChangeText',
                        'value'
                    )}
                />

                <Switch
                    {...switchFieldPropFactory(
                        t('RegistrationDetails.aboutStudent.medicalDetails.lifeThreatingMedical'),
                        ['childInformation', 'isLifeThreateningMedical']
                    )}
                    {...manageFieldPropFactory<ManageSwitchInputProps>(
                        ['childInformation', 'isLifeThreateningMedical'],
                        'onChangeSwitch',
                        'isChecked'
                    )}
                />
                <TextInput
                    {...textFieldPropFactory(
                        t('RegistrationDetails.aboutStudent.medicalDetails.lifeThreatingMedicalDetails'),
                        ['childInformation', 'lifeThreateningMedicalDetails']
                    )}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['childInformation', 'lifeThreateningMedicalDetails'],
                        'onChangeText',
                        'value'
                    )}
                />

                <Switch
                    {...switchFieldPropFactory(t('RegistrationDetails.aboutStudent.medicalDetails.accessability'), [
                        'childInformation',
                        'isChildHasAccessibilityNeeds',
                    ])}
                    {...manageFieldPropFactory<ManageSwitchInputProps>(
                        ['childInformation', 'isChildHasAccessibilityNeeds'],
                        'onChangeSwitch',
                        'isChecked'
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(
                        t('RegistrationDetails.aboutStudent.medicalDetails.accessabilityDetails'),
                        ['childInformation', 'accessibilityChallengesDetails']
                    )}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['childInformation', 'accessibilityChallengesDetails'],
                        'onChangeText',
                        'value'
                    )}
                />
            </Grid>
        </>
    );
};

export default MedicalDetails;
