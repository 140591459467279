import DateCell from '@Components/DateCell';
import { TFunction } from 'react-i18next';
import { Row } from 'react-table';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'src/constants';

const tableData = (t: TFunction, onEditHandler: (row: Row) => void, onDeleteHandler: (row: Row) => void) => {
    const commonHeaders = [
        { Header: t('EventsList.eventName'), accessor: 'name', headerAlign: 'left' },
        { Header: t('EventsList.activityType'), accessor: 'activityType', headerAlign: 'left' },
        {
            Header: t('EventsList.startDate'),
            accessor: 'startDate',
            Cell: DateCell(DATE_TIME_FORMAT),
            sortType: 'datetime',
            headerAlign: 'left',
        },
        {
            Header: t('EventsList.endDate'),
            accessor: 'endDate',
            Cell: DateCell(DATE_TIME_FORMAT),
            sortType: 'datetime',
            headerAlign: 'left',
        },
        { Header: t('EventsList.school'), accessor: 'team', headerAlign: 'left' },
    ];

    const header = {
        all: [
            commonHeaders[0],
            { Header: t('EventsList.status'), accessor: 'status' },

            {
                Header: t('EventsList.createdOn'),
                accessor: 'createdOn',
                Cell: DateCell(DATE_TIME_FORMAT),
                sortType: 'datetime',
                headerAlign: 'left',
            },
            {
                Header: t('EventsList.targetParticipantType'),
                accessor: 'targetParticipantType',
                headerAlign: 'left',
            },
            {
                Header: t('EventsList.dueDate'),
                accessor: 'dueDate',
                Cell: DateCell(DATE_FORMAT),
                sortType: 'datetime',
                headerAlign: 'left',
            },
            ...commonHeaders.slice(1),
        ],
        proposed: [
            ...commonHeaders,
            {
                Header: t('EventsList.dueDate'),
                accessor: 'dueDate',
                Cell: DateCell(DATE_FORMAT),
                sortType: 'datetime',
                headerAlign: 'left',
            },
            {
                Header: t('EventsList.targetParticipantType'),
                accessor: 'targetParticipantType',
                headerAlign: 'left',
            },
        ],
        validated: [
            ...commonHeaders,
            {
                Header: t('EventsList.dueDate'),
                accessor: 'dueDate',
                Cell: DateCell(DATE_FORMAT),
                sortType: 'datetime',
                headerAlign: 'left',
            },
            {
                Header: t('EventsList.targetParticipantType'),
                accessor: 'targetParticipantType',
                headerAlign: 'left',
            },
        ],
        published: [
            commonHeaders[0],
            {
                Header: t('EventsList.publishDate'),
                accessor: 'publishDate',
                Cell: DateCell(DATE_TIME_FORMAT),
                sortType: 'datetime',
                headerAlign: 'left',
            },
            {
                Header: t('EventsList.targetParticipantType'),
                accessor: 'targetParticipantType',
                headerAlign: 'left',
            },
            ...commonHeaders.slice(1),
            {
                Header: t('EventsList.dueDate'),
                accessor: 'dueDate',
                Cell: DateCell(DATE_FORMAT),
                sortType: 'datetime',
                headerAlign: 'left',
            },
            {
                Header: t('EventsList.pendingResponses'),
                accessor: 'responsesPending',
                headerAlign: 'right',
            },
            {
                Header: t('EventsList.acceptedResponses'),
                accessor: 'responsesAccepted',
                headerAlign: 'right',
            },
            {
                Header: t('EventsList.cancelledResponses'),
                accessor: 'responsesCancelled',
                headerAlign: 'right',
            },
        ],
        closed: [
            commonHeaders[0],
            {
                Header: t('EventsList.closeDate'),
                accessor: 'closeDate',
                Cell: DateCell(DATE_TIME_FORMAT),
                sortType: 'datetime',
                headerAlign: 'left',
            },
            ...commonHeaders.slice(1),
            {
                Header: t('EventsList.pendingResponses'),
                accessor: 'responsesPending',
                headerAlign: 'right',
            },
            {
                Header: t('EventsList.acceptedResponses'),
                accessor: 'responsesAccepted',
                headerAlign: 'right',
            },
            {
                Header: t('EventsList.cancelledResponses'),
                accessor: 'responsesCancelled',
                headerAlign: 'right',
            },
        ],
        cancelled: [
            commonHeaders[0],
            {
                Header: t('EventsList.cancelledDate'),
                accessor: 'cancelDate',
                Cell: DateCell(DATE_TIME_FORMAT),
                sortType: 'datetime',
                headerAlign: 'left',
            },
            ...commonHeaders.slice(1),
            {
                Header: t('EventsList.dueDate'),
                accessor: 'dueDate',
                Cell: DateCell(DATE_FORMAT),
                sortType: 'datetime',
                headerAlign: 'left',
            },
            {
                Header: t('EventsList.targetParticipantType'),
                accessor: 'targetParticipantType',
                headerAlign: 'left',
            },
            {
                Header: t('EventsList.pendingResponses'),
                accessor: 'responsesPending',
                headerAlign: 'right',
            },
            {
                Header: t('EventsList.acceptedResponses'),
                accessor: 'responsesAccepted',
                headerAlign: 'right',
            },
            {
                Header: t('EventsList.cancelledResponses'),
                accessor: 'responsesCancelled',
                headerAlign: 'right',
            },
        ],
    };

    return {
        header,
    };
};

export { tableData };
