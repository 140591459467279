import { Box, Flex, Stack, useDisclosure } from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

import { EventResponseDetail } from '@Redux/services/EventResponse/types';
import { AlertDialog } from '@Components';
import { DetailPageSkeleton } from '@Components/DetailPageSkeleton';
import { Prompt } from '@Components/Prompt';
import Details from '@Pages/EventResponses/components/Details';
import Payment from '@Pages/EventResponses/components/Payment';
import Notes from '@Pages/EventResponses/components/Notes';
import CostItemTable from '@Pages/EventResponses/components/CostItem';
import QuestionsTable from '@Pages/EventResponses/components/Questions';
import CancellationAndSubsidy from '@Pages/EventResponses/components/CancellationAndSubsidy';
import { useMemo } from 'react';
import { Breadcrumb } from '@Components/Breadcrumb';

const EventResponseDetailForm = ({
    eventResponse,
    showCancelAlert,
    showResetAlert,
    alertProps,
    containerRef,
    handleCloseAlert,
    handleStatusUpdate,
    stepsProps,
    isEventPublished,
    isResponseDraftOrPending,
    isLoading,
    isDirty,
    setIsDirty,
    onClose = undefined,
    isDrawer = false,
}) => {
    const { t } = useTranslation();

    const { isOpen: isOpenQuestionForm, onClose: onCloseQuestionForm, onOpen: onOpenQuestionForm } = useDisclosure();
    const {
        isOpen: isOpenQuestionResponseForm,
        onClose: onCloseQuestionResponseForm,
        onOpen: onOpenQuestionResponseForm,
    } = useDisclosure();

    const { isOpen: isOpenCostItemForm, onClose: onCloseCostItemForm, onOpen: onOpenCostItemForm } = useDisclosure();
    const {
        isOpen: isOpenCostItemResponseForm,
        onClose: onCloseCostItemResponseForm,
        onOpen: onOpenCostItemResponseForm,
    } = useDisclosure();

    if (isLoading || !eventResponse) {
        return <DetailPageSkeleton />;
    }

    const handleCloseAllCostItemResponsesDrawers = () => {
        onCloseCostItemForm();
        onCloseCostItemResponseForm();
        onClose && onClose();
    };

    const handleCloseAllQuestionResponsesDrawers = () => {
        onCloseQuestionResponseForm();
        onCloseQuestionForm();
        onClose && onClose();
    };

    const costItemResponseBreadcrumbs = useMemo(
        () => [
            {
                label: '',
                inactiveLable: t('EventResponseDetailForm.breadcrumb.label'),
                onClick: onCloseCostItemResponseForm,
                isCurrentPage: !isOpenCostItemResponseForm,
            },
            {
                label: t('EventResponseDetailForm.breadcrumb.costItemResponseLabel'),
                inactiveLable: '',
                onClick: undefined,
                isCurrentPage: isOpenCostItemResponseForm,
            },
        ],
        [isOpenCostItemResponseForm]
    );

    const costItemBreadcrumbs = useMemo(
        () => [
            {
                label: undefined,
                inactiveLable: t('EventResponseDetailForm.breadcrumb.label'),
                onClick: () => {
                    onCloseCostItemResponseForm();
                    onCloseCostItemForm();
                },
                isCurrentPage: !isOpenCostItemForm,
            },
            {
                label: undefined,
                inactiveLable: t('EventResponseDetailForm.breadcrumb.costItemResponseLabel'),
                onClick: onCloseCostItemForm,
                isCurrentPage: !isOpenCostItemForm,
            },
            {
                label: t('EventResponseDetailForm.breadcrumb.costItemLabel'),
                inactiveLable: '',
                onClick: undefined,
                isCurrentPage: isOpenCostItemForm,
            },
        ],
        [isOpenCostItemForm]
    );

    const questionResponseBreadcrumbs = useMemo(
        () => [
            {
                label: '',
                inactiveLable: t('EventResponseDetailForm.breadcrumb.label'),
                onClick: onCloseQuestionResponseForm,
                isCurrentPage: !isOpenQuestionResponseForm,
            },
            {
                label: t('EventResponseDetailForm.breadcrumb.questionResponselabel'),
                inactiveLable: '',
                onClick: undefined,
                isCurrentPage: isOpenQuestionResponseForm,
            },
        ],
        [isOpenQuestionResponseForm]
    );

    const questionBreadcrumbs = useMemo(
        () => [
            {
                label: '',
                inactiveLable: t('EventResponseDetailForm.breadcrumb.label'),
                onClick: () => {
                    onCloseQuestionResponseForm();
                    onCloseQuestionForm();
                },
                isCurrentPage: !isOpenQuestionForm,
            },
            {
                label: undefined,
                inactiveLable: t('EventResponseDetailForm.breadcrumb.questionResponselabel'),
                onClick: onCloseQuestionForm,
                isCurrentPage: !isOpenQuestionForm,
            },
            {
                label: t('EventResponseDetailForm.breadcrumb.questionlabel'),
                inactiveLable: '',
                onClick: undefined,
                isCurrentPage: isOpenQuestionForm,
            },
        ],
        [isOpenQuestionForm]
    );

    return (
        eventResponse && (
            <>
                <AlertDialog
                    isOpen={showCancelAlert || showResetAlert}
                    submitHandle={() => handleStatusUpdate(showCancelAlert)}
                    alertType='error'
                    onClose={handleCloseAlert}
                    submitButtonProps={{
                        variant: 'error',
                    }}
                    cancelButtonProps={{
                        variant: 'outline',
                        onClick: handleCloseAlert,
                    }}
                    {...alertProps}
                />

                <Flex>
                    <Flex ref={containerRef} overflow={'hidden'} width='100%'>
                        <form style={{ width: '100%' }}>
                            <Box maxWidth='100%'>
                                <Stack
                                    bg={isDrawer ? 'surface-primary' : 'surface-secondary'}
                                    p={3}
                                    mt={5}
                                    spacing={8}
                                    borderLeftRadius={15}
                                >
                                    <Details isDrawer id='summary' {...stepsProps} />
                                    <Payment isDrawer id='payment' {...stepsProps} />
                                    <CostItemTable
                                        id='cost-items'
                                        isDrawer={isDrawer}
                                        eventResponse={eventResponse as EventResponseDetail}
                                        setEventResponse={stepsProps.setEventResponse}
                                        isEditable={isEventPublished && isResponseDraftOrPending}
                                        costItemLabel={isDrawer ? <Breadcrumb items={costItemBreadcrumbs} /> : null}
                                        costItemResponseLabel={
                                            isDrawer ? <Breadcrumb items={costItemResponseBreadcrumbs} /> : null
                                        }
                                        isOpenCostItemForm={isOpenCostItemForm}
                                        onCloseCostItemForm={onCloseCostItemForm}
                                        onOpenCostItemForm={onOpenCostItemForm}
                                        isOpenCostItemResponseForm={isOpenCostItemResponseForm}
                                        onCloseCostItemResponseForm={onCloseCostItemResponseForm}
                                        onOpenCostItemResponseForm={onOpenCostItemResponseForm}
                                        onCloseAllResponseDrawers={handleCloseAllCostItemResponsesDrawers}
                                    />
                                    <Notes id='notes' {...stepsProps} />
                                    <QuestionsTable
                                        id='answers'
                                        isDrawer={isDrawer}
                                        {...stepsProps}
                                        setEventResponse={stepsProps.setEventResponse}
                                        eventResponse={eventResponse as EventResponseDetail}
                                        isEditable={isEventPublished && isResponseDraftOrPending}
                                        questionResponseLabel={
                                            isDrawer ? <Breadcrumb items={questionResponseBreadcrumbs} /> : null
                                        }
                                        questionLabel={isDrawer ? <Breadcrumb items={questionBreadcrumbs} /> : null}
                                        isOpenQuestionForm={isOpenQuestionForm}
                                        onCloseQuestionForm={onCloseQuestionForm}
                                        onOpenQuestionForm={onOpenQuestionForm}
                                        isOpenQuestionResponseForm={isOpenQuestionResponseForm}
                                        onCloseQuestionResponseForm={onCloseQuestionResponseForm}
                                        onOpenQuestionResponseForm={onOpenQuestionResponseForm}
                                        onCloseAllResponseDrawers={handleCloseAllQuestionResponsesDrawers}
                                    />
                                    <CancellationAndSubsidy isDrawer id='cancellation-and-subsidy' {...stepsProps} />
                                </Stack>
                            </Box>
                        </form>
                    </Flex>
                </Flex>
                {isDirty && (
                    <Prompt
                        title={t('EventResponsesPage.title')}
                        message={t('EventResponsesPage.message')}
                        submitLabel={t('EventResponsesPage.leave')}
                        cancelLabel={t('EventResponsesPage.cancelButtonLabel')}
                        dirty={isDirty}
                        onLeave={() => setIsDirty(false)}
                    />
                )}
            </>
        )
    );
};

export default EventResponseDetailForm;
