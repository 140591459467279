import { FormComponentProps } from '@Hooks';
import { IPhoneCallDetails } from '@Redux/services/Absence/types';
import { Dispatch, SetStateAction } from 'react';
import type { PartialDeep } from 'type-fest';

export interface PhoneCallStepProps extends FormComponentProps {
    phoneCallDetails: PartialDeep<IPhoneCallDetails>;
    setPhoneCallDetails: Dispatch<SetStateAction<IPhoneCallDetails>>;
}

export enum PhoneCallStatusTypes {
    Open = 1,
    Made = 2,
    Canceled = 3,
    Received = 4,
    AutomaticallyCanceled = 951030000,
}

const PhoneCallStatusOpen = [PhoneCallStatusTypes.Open];

export const editableFieldsForPhoneCall = {
    absenceReason: {
        required: false,
        editableStatus: PhoneCallStatusOpen,
        validateSave: true,
    },
    action: {
        required: true,
        editableStatus: PhoneCallStatusOpen,
        validateSave: true,
    },
    duration: {
        required: false,
        editableStatus: PhoneCallStatusOpen,
        validateSave: true,
    },
    from: {
        editableStatus: PhoneCallStatusOpen,
        required: false,
        validateSave: true,
    },
    to: {
        editableStatus: PhoneCallStatusOpen,
        required: false,
        validateSave: true,
    },
};
