import {
    Box,
    Button,
    IconButton,
    IconButtonProps,
    Popover,
    PopoverContent,
    Portal,
    Text,
    useDisclosure,
} from '@chakra-ui/react';

import Trash from '@Icon/Trash';
import { useTranslation } from 'react-i18next';
import { FC, useRef } from 'react';
import { PopoverTrigger } from '@Components/PopoverTrigger';
import { Delete } from '@Icon';

interface IProps extends IconButtonProps {
    onDelete: () => Promise<void>;
    message: string;
    usePortal?: boolean;
}

const WithPortal = ({ usePortal, children }) => (usePortal ? <Portal>{children}</Portal> : children);

const DeleteButtonPopover: FC<IProps> = ({ onDelete, message, disabled, usePortal = true }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { t } = useTranslation();

    const initialFocusRef = useRef();

    const handleDelete = async () => {
        await onDelete();
        onClose();
    };
    return (
        <>
            <Popover isLazy isOpen={isOpen} initialFocusRef={initialFocusRef} placement='bottom-end' onClose={onClose}>
                <PopoverTrigger>
                    <IconButton
                        isDisabled={disabled}
                        bg={'surface-primary'}
                        onClick={onOpen}
                        aria-label={t('DeleteButton.ariaLabel')}
                        icon={<Trash />}
                        title={t('DeleteIcon.title')}
                    />
                </PopoverTrigger>
                {/* Fix for table added space to the right of the icon */}
                <WithPortal usePortal={usePortal}>
                    {isOpen && (
                        <PopoverContent p={5} textAlign='left'>
                            <Text textStyle={'sm-bold'} mb={4}>
                                {message}
                            </Text>
                            <Box bottom={4}>
                                <Button colorScheme='red' mr={2} leftIcon={<Delete />} onClick={handleDelete}>
                                    {t('EventsList.deleteLabel')}
                                </Button>
                                <Button onClick={onClose} bg='whiteAlpha' border={'1px'} borderColor={'lineColor'}>
                                    {t('EventsList.cancelLabel')}
                                </Button>
                            </Box>
                        </PopoverContent>
                    )}
                </WithPortal>
            </Popover>
        </>
    );
};

export default DeleteButtonPopover;
