import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormComponentProps, ManageTextProps } from '@Hooks';
import { Box, Grid } from '@chakra-ui/react';
import SelectInput from '@Components/SelectInput';
import TextInput from '@Components/TextInput';
import { BasicLookup } from '@Redux/services/LookupApi/types';

interface HomeAddressDetailsProps extends FormComponentProps {
    cities: BasicLookup[];
    provinces: BasicLookup[];
    countries: BasicLookup[];
}

const HomeAddressDetails: React.FC<HomeAddressDetailsProps> = ({
    manageFieldPropFactory,
    selectFieldPropFactory,
    textFieldPropFactory,
    cities,
    provinces,
    countries,
}) => {
    const [t] = useTranslation();

    return (
        <>
            <Box pb={4}>
                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.homeAddress.homeAddress.streetNumber'), [
                        'addressInformation',
                        'childAddress',
                        'streetName',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'childAddress', 'streetName'],
                        'onChangeText',
                        'value'
                    )}
                    col={1}
                />
            </Box>
            <Grid
                column={{ md: 2, sm: 1 }}
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                }}
                rowGap={4}
                templateRows='auto'
                mb={6}
            >
                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.homeAddress.homeAddress.apartmentNumber'), [
                        'addressInformation',
                        'childAddress',
                        'apartmentNumber',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'childAddress', 'apartmentNumber'],
                        'onChangeText',
                        'value'
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.homeAddress.homeAddress.routeNumber'), [
                        'addressInformation',
                        'childAddress',
                        'rrNumber',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'childAddress', 'rrNumber'],
                        'onChangeText',
                        'value'
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.homeAddress.homeAddress.postalCode'), [
                        'addressInformation',
                        'childAddress',
                        'postalCode',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'childAddress', 'postalCode'],
                        'onChangeText',
                        'value'
                    )}
                />

                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.homeAddress.homeAddress.city'), [
                        'addressInformation',
                        'childAddress',
                        'city',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'childAddress', 'city'],
                        'onChangeValue',
                        'value'
                    )}
                    options={cities}
                />
                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.homeAddress.homeAddress.province'), [
                        'addressInformation',
                        'childAddress',
                        'province',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'childAddress', 'province'],
                        'onChangeValue',
                        'value'
                    )}
                    isLocked={true}
                    options={provinces}
                />

                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.homeAddress.homeAddress.country'), [
                        'addressInformation',
                        'childAddress',
                        'country',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'childAddress', 'country'],
                        'onChangeValue',
                        'value'
                    )}
                    isLocked={true}
                    options={countries}
                />
            </Grid>
        </>
    );
};

export default HomeAddressDetails;
