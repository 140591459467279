import {
    Box,
    FormControl,
    FormHelperText,
    FormLabel,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput as ChakraNumberInput,
    NumberInputField,
    NumberInputStepper,
} from '@chakra-ui/react';
import { Lock } from '@Icon';
import { isNull, isNumber, isString } from 'lodash';
import { FC } from 'react';
export interface NumberInputProps {
    isLocked: boolean;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    label?: string;
    error?: string;
    onChangeValue?: (value: string) => void;
    stacked?: boolean;
    col?: number;
    min?: number;
    max?: string | number;
    step?: number;
    precision?: number;
    isRequired?: boolean;
    value?: string | number | readonly string[];
    isInvalid?: boolean;
    isDisabled?: boolean;
}

const NumberInput: FC<NumberInputProps> = ({
    isLocked,
    stacked,
    label,
    isRequired,
    error,
    leftIcon,
    max,
    rightIcon,
    onChangeValue,
    col,
    value,
    ...rest
}) => {
    return (
        <FormControl
            isInvalid={rest.isInvalid}
            display={{ base: 'block', lg: 'flex' }}
            alignItems={stacked ? 'start' : 'baseline'}
            flexDirection={stacked ? 'column' : 'row'}
        >
            {label && (
                <FormLabel
                    color={(rest.isDisabled || isLocked) && 'text-disabled'}
                    flexBasis={col === 1 ? '12.5%' : '30%'}
                    textStyle={'sm-medium'}
                >
                    {label}
                    {isRequired && '*'}
                </FormLabel>
            )}
            <InputGroup>
                {leftIcon && (
                    <InputLeftElement left={-2} color={isLocked && 'text-disabled'}>
                        {leftIcon}
                    </InputLeftElement>
                )}
                <Box as='span' w='100%'>
                    <ChakraNumberInput
                        onChange={(value: string) => {
                            onChangeValue(value);
                        }}
                        clampValueOnBlur={true}
                        value={(isString(value) && !isNull(value)) || isNumber(value) ? value : undefined}
                        {...rest}
                    >
                        <NumberInputField disabled={isLocked} />
                        <NumberInputStepper display={isLocked ? 'none' : 'flex'}>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </ChakraNumberInput>
                    {error && (
                        <FormHelperText p={2} textStyle='sm-normal' color={rest.isInvalid && 'text-error'}>
                            {error}
                        </FormHelperText>
                    )}
                </Box>
                {(rightIcon || isLocked) && (
                    <InputRightElement>
                        {isLocked ? <Lock width={18} height={18} color='text-secondary' /> : rightIcon}
                    </InputRightElement>
                )}
            </InputGroup>
        </FormControl>
    );
};

export default NumberInput;
