import { useTranslation } from 'react-i18next';
import { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { useToast } from './useToast';
import { useNavigate, useParams } from 'react-router-dom';
import {
    useCloneEventMutation,
    useCreateEventNoteMutation,
    useDeleteEventMutation,
    useDeleteEventNoteMutation,
    useGetEventNotesMutation,
    useLazyGetEventDetailsQuery,
    useUpdateEventMutation,
    useUpdateEventNoteMutation,
} from '@Redux/services/Event';
import { Accessor, getErrorsAssociatedWithFields, getMutableForAccessor, parseError, parseWarning } from '@Utilities';
import { isUndefined } from 'lodash';
import { useForms } from './useForms';
import { ACTIVITY_TYPE_ONGOING_CONSENT, EventStatus } from 'src/constants';
import { Validator } from '@Pages/EventDetails/validator';
import { useGetListQuery } from '@Redux/services/Participants';
import { EventStatuses } from '@Pages/EventDetails/types';
import { PartialDeep } from 'type-fest';
import { Event } from '@Redux/services/Event/types';
import useAutoSave from './useAutoSave';
import { useGetCategoriesQuery, useGetOrganizedByQuery } from '@Redux/services/LookupApi';

type IRouteParams = {
    id: string;
};

export const useEventDetail = () => {
    const { t } = useTranslation();
    const [saveValidationError, setSaveValidationError] = useState(false);
    const toast = useToast();
    const [showCloneAlert, setShowCloneAlert] = useState(false);
    const [showCloseAlert, setShowCloseAlert] = useState(false);
    const [showPublishAlert, setShowPublishAlert] = useState(false);
    const [showValidateAlert, setShowValidateAlert] = useState(false);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [showCancelAlert, setShowCancelAlert] = useState(false);
    const [newEventName, setNewEventName] = useState('');
    const [cloneError, setCloneError] = useState('');
    const [isCancelling, setIsCancelling] = useState(false);
    const navigate = useNavigate();
    const [createEventNote] = useCreateEventNoteMutation();
    const [deleteEventNote] = useDeleteEventNoteMutation();
    const [updateEventNote] = useUpdateEventNoteMutation();
    const [getEventNotes, { isLoading: isNotesLoading }] = useGetEventNotesMutation();

    const getCommonProps = (accessorPath: Accessor[]) => {
        const accesor = accessorPath.join('.');

        const item = eventRequiredFields[accesor];
        const hasStatus = !isUndefined(item) && item.editableStatus?.indexOf(eventDetails?.status) > -1;
        const isLocked = isUndefined(item) ? true : !hasStatus;
        const isRequired = item && item.required && item.validateSave;
        return {
            isLocked,
            isRequired,
        };
    };

    const {
        formData: eventDetails,
        errors,
        manageFieldPropFactory,
        selectFieldPropFactory,
        setErrors,
        textAreaPropFactory,
        textFieldPropFactory,
        typeaheadPropFactory,
        switchFieldPropFactory,
        dateTimePickerPropFactory,
        setFormData: setEventDetails,
        setIsDirty,
        dirtyData,
        setDirtyData,
        dirtyAccessors,
        setDirtyAccessor,
        isDirty,
    } = useForms<PartialDeep<Event>>(undefined, getCommonProps);

    const { data: { data: organizedBy = [] } = {} } = useGetOrganizedByQuery();
    const { data: { data: categories = [] } = {} } = useGetCategoriesQuery();

    const isClosedOrCanceled = useMemo(
        () => [EventStatus.Cancelled, EventStatus.Closed].includes(eventDetails?.status),
        [eventDetails?.status]
    );

    const eventRequiredFields = useMemo(() => {
        if (!eventDetails) return;
        const newEventRequiredFields = {};
        const validatons = Validator(eventDetails, t, false);
        validatons.map((validaton) => {
            newEventRequiredFields[validaton.accessorPath.join('.')] = validaton;
        });
        return newEventRequiredFields;
    }, [eventDetails]);

    const { refetch: getCustomList } = useGetListQuery();
    const containerRef = useRef<HTMLDivElement>(null);
    const params = useParams<IRouteParams>();
    const [getEventDetails, { isLoading }] = useLazyGetEventDetailsQuery();

    const [cloneEvent, { isLoading: cloneEventLoading }] = useCloneEventMutation();
    const [deleteEvent, { isLoading: deleteEventLoading }] = useDeleteEventMutation();
    const [updateEvent] = useUpdateEventMutation();
    const [isNextLoading, setIsNextLoading] = useState(false);

    const eventStatus = useMemo(
        () =>
            EventStatuses[
                eventDetails?.status === EventStatus.Cancelled
                    ? eventDetails?.statusPriorToCancellation
                    : eventDetails?.status
            ],
        [eventDetails]
    );

    useEffect(() => {
        if (dirtyAccessors && Object.keys(dirtyAccessors).length > 0) {
            validate(true);
        }
    }, [dirtyAccessors, setDirtyAccessor, eventDetails, setEventDetails]);

    const validate = (isAutoSave: boolean) => {
        const newErrors = { ...errors };
        const nextErrors: Record<string, string> = {};
        const validationRules = Validator(eventDetails, t, isAutoSave);
        const dirtyAccessorsString = {};
        if (isAutoSave) {
            for (const dirtyAccessor of dirtyAccessors) {
                dirtyAccessorsString[dirtyAccessor.join('.')] = true;
            }
        }

        for (const rule of validationRules) {
            const { accessorPath, validator, message, required, editableStatus, dependantPath } = rule;
            if (
                isAutoSave &&
                !(
                    dirtyAccessorsString[accessorPath.join('.')] ||
                    (dependantPath && dirtyAccessorsString[dependantPath.join('.')])
                )
            ) {
                continue;
            }
            const finalMutable = getMutableForAccessor(eventDetails, accessorPath);
            const finalProperty = accessorPath[accessorPath.length - 1];

            if (
                (required || isAutoSave) &&
                editableStatus?.indexOf(eventDetails?.status) > -1 &&
                !validator(finalMutable[finalProperty])
            ) {
                nextErrors[accessorPath.join('.')] = message;
            } else {
                newErrors[accessorPath.join('.')] && delete newErrors[accessorPath.join('.')];
            }
        }
        setErrors({ ...newErrors, ...nextErrors });
        if (Object.keys(nextErrors).length) {
            !isAutoSave &&
                toast({
                    status: 'error',
                    description: t('EventDetails.toastErrorDescription'),
                });
            if (isAutoSave) {
                setSaveValidationError(true);
            }
            return false;
        }
        setSaveValidationError(false);
        return true;
    };

    const handleFormChange = async (data: Event) => {
        if (saveValidationError || !data || Object.keys(data).length === 0 || isClosedOrCanceled || !eventDetails)
            return;
        return await updateEvent({
            eventId: eventDetails.eventId,
            ...data,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                setEventDetails(response.data);
                setDirtyData({});
                setIsDirty(false);
                setDirtyAccessor([]);
                setHasPendingChange(false);
            })
            .catch((error) => {
                const nextErrors: Record<string, string> = {};
                for (const dirtyAccessor of dirtyAccessors) {
                    nextErrors[dirtyAccessor.join('.')] = t('EventDetails.fieldNotSaved');
                }
                setErrors(getErrorsAssociatedWithFields(error.data.errors, Object.keys(eventRequiredFields)));
                parseError(toast, error);
                setHasPendingChange(true);
            });
    };

    const { hasPendingChange, handleManualSave, setHasPendingChange } = useAutoSave({
        data: dirtyData,
        onChange: handleFormChange,
    });
    const onGoingConsent = eventDetails?.activityTypeId === ACTIVITY_TYPE_ONGOING_CONSENT;

    const getEventData = async () => {
        if (!params.id) return;
        await getEventDetails({ id: params.id }, false)
            .unwrap()
            .then((response) => {
                //TODO: remove console log
                console.log(response);
                parseWarning(toast, response);
                setEventDetails(response.data);
            })
            .catch((error) => {
                //TODO: remove console log
                console.log(error);
                parseError(toast, error);
            });
    };

    useEffect(() => {
        getEventData();
        getCustomList();
    }, [params.id]);

    const steps = useMemo(() => {
        return [
            {
                active: EventStatuses[EventStatus.Proposed] === eventStatus,
                header: t('EventDetails.step1'),
                subHeader: t('EventDetails.step1Subheader'),
            },
            {
                active: EventStatuses[EventStatus.Validated] === eventStatus,
                header: t('EventDetails.step2'),
                subHeader: t('EventDetails.step2Subheader'),
            },
            {
                active: EventStatuses[EventStatus.Published] === eventStatus,
                header: t('EventDetails.step3'),
                subHeader: t('EventDetails.step3Subheader'),
            },
            {
                active: EventStatuses[EventStatus.Closed] === eventStatus,
                header: t('EventDetails.step4'),
                subHeader: t('EventDetails.step4Subheader'),
            },
        ];
    }, [eventDetails]);

    const getNextStatus = (status: number) => {
        switch (status) {
            case EventStatus.Proposed:
                return EventStatus.Validated;
            case EventStatus.Validated:
                return EventStatus.Published;
            case EventStatus.Published:
                return EventStatus.Closed;
        }
    };

    const handleNext = useCallback(async () => {
        if (isNextLoading) return;
        if (!validate(false)) return;

        if (eventDetails?.status === EventStatus.Published && !showCloseAlert) {
            setShowCloseAlert(true);
            return;
        }
        if (eventDetails?.status === EventStatus.Validated && !showPublishAlert) {
            setShowPublishAlert(true);
            return;
        }
        if (eventDetails?.status === EventStatus.Proposed && !showValidateAlert) {
            setShowValidateAlert(true);
            return;
        }

        setIsNextLoading(true);
        setShowValidateAlert(false);
        setShowCloseAlert(false);
        setShowPublishAlert(false);
        await updateEvent({
            eventId: eventDetails.eventId,
            status: getNextStatus(eventDetails.status),
        })
            .unwrap()
            .then(async (response) => {
                if (getNextStatus(eventDetails.status) === EventStatus.Validated) {
                    await getCustomList();
                }
                setEventDetails(response.data);
                let message = t('EventDetails.toastCloseSuccess');
                if (response.data.status === EventStatus.Published) {
                    message = t('EventDetails.toastPublishSuccess');
                } else if (response.data.status === EventStatus.Validated) {
                    message = t('EventDetails.toastValidateSuccess');
                } else if (response.data.status === EventStatus.Closed) {
                    message = t('EventDetails.toastCloseSuccess');
                }
                if (response?.warningResult?.showAlert) {
                    parseWarning(toast, response);
                } else {
                    toast({
                        status: 'success',
                        description: message,
                    });
                }
            })
            .catch((error) => {
                setErrors(getErrorsAssociatedWithFields(error.data.errors, Object.keys(eventRequiredFields)));
                parseError(toast, error);
            })
            .finally(() => {
                setIsNextLoading(false);
            });
    }, [eventDetails, setErrors, showCloseAlert, showPublishAlert, showValidateAlert, isNextLoading]);

    const handleCloseClone = () => {
        setNewEventName('');
        setCloneError('');
        setShowCloneAlert(false);
    };

    const handleClone = async () => {
        if (!newEventName) {
            setCloneError(t('EventDetails.cloneRequiredError'));
            return;
        }
        await cloneEvent({
            eventId: eventDetails.eventId,
            name: newEventName,
        })
            .unwrap()
            .then((response) => {
                if (response?.warningResult?.showAlert) {
                    parseWarning(toast, response);
                } else {
                    toast({
                        status: 'success',
                        description: t('EventDetails.cloneSuccess'),
                        isClosable: true,
                    });
                }
                handleCloseClone();
                setErrors({});
                setDirtyData({});
                setDirtyAccessor([]);
                navigate(`/events/${response.data.eventId}`);
            })
            .catch((error) => {
                parseError(toast, error);
            });
    };

    const handleCloseDelete = () => {
        setShowDeleteAlert(false);
    };

    const handleDelete = async () => {
        await deleteEvent({
            eventId: eventDetails.eventId,
        })
            .unwrap()
            .then(() => {
                toast({
                    status: 'success',
                    description: t('EventDetails.deleteSuccess'),
                    isClosable: true,
                });
                handleCloseDelete();
                navigate('/events/planning/published');
            })
            .catch((error) => {
                parseError(toast, error);
            });
    };

    const isPublished = useMemo(() => eventDetails?.status === EventStatus.Published, [eventDetails]);
    const isValidated = useMemo(() => eventDetails?.status === EventStatus.Validated, [eventDetails]);
    const isProposed = useMemo(() => eventDetails?.status === EventStatus.Proposed, [eventDetails]);
    const isClosedOrPreviouslyPublished = useMemo(
        () =>
            eventDetails?.status === EventStatus.Closed ||
            eventDetails?.statusPriorToCancellation === EventStatus.Published,
        [eventDetails]
    );
    const isCancelled = useMemo(() => eventDetails?.status === EventStatus.Cancelled, [eventDetails]);

    const nextButtonLoading = useMemo(() => isNextLoading || isCancelling, [isNextLoading, isCancelling]);

    const handleDeleteNote = async (annotationId: string) => {
        await deleteEventNote({ annotationId })
            .unwrap()
            .then((response) => {
                setEventDetails({
                    ...eventDetails,
                    eventNotes: { eventNotesInfo: response.data, pagedResult: response.pagedResult },
                });
                if (response?.warningResult?.showAlert) {
                    parseWarning(toast, response);
                } else {
                    toast({
                        status: 'success',
                        description: t('Eventdetails.toastSuccessNoteDelete'),
                    });
                }
            })
            .catch((e) => parseError(toast, e));
    };

    const handleUpdateNote = async (noteText: string, annotationId: string) => {
        await updateEventNote({
            annotationId,
            noteText,
        })
            .unwrap()
            .then((response) => {
                setEventDetails({
                    ...eventDetails,
                    eventNotes: { eventNotesInfo: response.data, pagedResult: response.pagedResult },
                });
                if (response?.warningResult?.showAlert) {
                    parseWarning(toast, response);
                } else {
                    toast({
                        status: 'success',
                        description: t('Eventdetails.toastSuccessNoteUpdate'),
                    });
                }
            })
            .catch((e) => parseError(toast, e));
    };

    const handleNotePageChange = async (pageNumber: number, pageSize: number) => {
        await getEventNotes({
            objectId: eventDetails?.eventId,
            pageSize,
            currentPage: pageNumber,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                setEventDetails({
                    ...eventDetails,
                    eventNotes: { eventNotesInfo: response.data, pagedResult: response.pagedResult },
                });
            })
            .catch((e) => parseError(toast, e));
    };

    const handleAddNote = async (notes: string) => {
        await createEventNote({
            eventId: eventDetails?.eventId,
            notes,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                setEventDetails({
                    ...eventDetails,
                    eventNotes: {
                        eventNotesInfo: response.data,
                        pagedResult: response.pagedResult,
                    },
                });
            })
            .catch((e) => parseError(toast, e));
    };

    const stepsProps = {
        errors,
        manageFieldPropFactory,
        setEventDetails,
        eventDetails,
        textAreaPropFactory,
        dateTimePickerPropFactory,
        textFieldPropFactory,
        selectFieldPropFactory,
        typeaheadPropFactory,
        switchFieldPropFactory,
        setErrors,
        setDirtyData,
        setDirtyAccessor,
        dirtyData,
        dirtyAccessors,
        organizedBy,
        categories,
    };

    return {
        eventDetails,
        onGoingConsent,
        isClosedOrCanceled,
        isCancelled,
        isCancelling,
        isProposed,
        isPublished,
        isValidated,
        showValidateAlert,
        showPublishAlert,
        showCloneAlert,
        showCloseAlert,
        showCancelAlert,
        cloneEventLoading,
        newEventName,
        cloneError,
        showDeleteAlert,
        deleteEventLoading,
        setEventDetails,
        handleDelete,
        handleCloseDelete,
        setNewEventName,
        setCloneError,
        setShowCloneAlert,
        setShowCloseAlert,
        setShowCancelAlert,
        setShowPublishAlert,
        setShowValidateAlert,
        setShowDeleteAlert,
        handleNext,
        handleAddNote,
        handleClone,
        handleCloseClone,
        setIsCancelling,
        isLoading,
        containerRef,
        hasPendingChange,
        handleManualSave,
        steps,
        nextButtonLoading,
        getNextStatus,
        handleDeleteNote,
        handleUpdateNote,
        isClosedOrPreviouslyPublished,
        isDirty,
        setIsDirty,
        stepsProps,
        getEventData,
        handleNotePageChange,
        isNotesLoading,
    };
};
