import { isBoolean } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
    value: boolean;
}

const YesNoCell: React.FC<IProps> = ({ value }) => {
    const { t } = useTranslation();

    if (!isBoolean(value)) {
        return <>{t('YesNoCell.na')}</>;
    }
    return <>{value ? t('YesNoCell.yes') : t('YesNoCell.no')}</>;
};

export default YesNoCell;
