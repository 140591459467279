import * as React from 'react';
function SvgExport(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M6.072 23.24c-.63 0-1.166-.223-1.61-.666a2.193 2.193 0 01-.665-1.61V10.371c0-.63.222-1.167.665-1.61.444-.444.98-.665 1.61-.665h1.653c.32 0 .589.11.808.329.22.22.33.489.33.808 0 .32-.11.589-.33.808-.22.22-.489.33-.808.33H6.072v10.593h11.856V10.371h-1.653c-.32 0-.588-.11-.808-.33-.22-.22-.33-.489-.33-.808 0-.319.11-.588.33-.808.22-.22.489-.33.808-.33h1.653c.63 0 1.167.222 1.61.666.444.443.665.98.665 1.61v10.593c0 .63-.221 1.167-.665 1.61-.443.443-.98.665-1.61.665H6.072zm4.79-18.134l-.804.804c-.224.224-.489.333-.795.327a1.122 1.122 0 01-.796-.346 1.157 1.157 0 01-.326-.795 1.055 1.055 0 01.326-.796l2.731-2.737c.228-.228.496-.342.802-.342.307 0 .574.114.802.342L15.533 4.3c.212.211.317.473.317.786 0 .313-.105.581-.317.805a1.098 1.098 0 01-.805.336c-.313 0-.581-.112-.805-.336l-.785-.785v9.966c0 .319-.11.588-.33.808-.22.22-.489.33-.808.33-.32 0-.588-.11-.808-.33-.22-.22-.33-.489-.33-.808V5.106z'
                fill='#171923'
            />
        </svg>
    );
}
export default SvgExport;
