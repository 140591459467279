import { useRef } from 'react';
import { ArrowUpward, Attach } from '@Icon';
import {
    Box,
    Button,
    ButtonProps,
    Center,
    CenterProps,
    Flex,
    IconButton,
    Input,
    InputProps,
    Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { byteConverter } from '@Utilities';

interface IProps {
    onSelect: (fl: FileList) => void;
    file?: File;
    onImport: () => void;
    inputProps?: InputProps;
    buttonProps?: ButtonProps;
    isError?: boolean;
}
export default function Dropzone({
    onSelect,
    onImport,
    file,
    inputProps,
    buttonProps,
    isError,
    ...props
}: CenterProps & IProps) {
    const fileInputRef = useRef(null);
    const { t } = useTranslation();

    const handleClick = () => {
        fileInputRef.current?.click();
    };

    return (
        <Flex
            as='div'
            gap='16px'
            borderWidth='1px'
            borderLeftRadius='lg'
            p='16px'
            w='100%'
            onDrop={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onSelect(e.dataTransfer.files);
            }}
            onDragOver={(e) => {
                e.preventDefault();
            }}
            bg={isError ? 'surface-error-invert' : 'surface-primary'}
            {...props}
        >
            <Center>
                <IconButton aria-label='icon' icon={!file ? <ArrowUpward /> : <Attach />} onClick={handleClick} />
            </Center>
            <Box as='span' w='100%' display='flex' alignItems='center' onClick={handleClick}>
                <Input
                    id='fileUpload'
                    type='file'
                    name='fileContent'
                    ref={fileInputRef}
                    onChange={async (event) => {
                        onSelect(event.target.files);
                        event.target.value = '';
                    }}
                    hidden
                    {...inputProps}
                />
                <Text
                    textStyle='md-semibold'
                    color='text-primary'
                    overflowWrap='anywhere'
                    textDecoration='underline'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                >
                    {!file ? t('Dropzone.primaryPrompt.header') : file.name}
                </Text>
                <Text ml={2} textStyle='sm-normal' color='text-secondary'>
                    {file && file.size != 0 && byteConverter(file.size, 0, '')}
                </Text>
            </Box>
            {file && (
                <Center justifyContent={'space-between'}>
                    <Button
                        variant={'light'}
                        onClick={handleClick}
                        bg={isError ? 'surface-error-invert' : 'surface-primary'}
                    >
                        {t('DropZone.retry')}
                    </Button>
                    <Button ml={2} variant={'primary'} onClick={onImport} {...buttonProps}>
                        {t('Dropzone.primaryPrompt.buttonLabel')}
                    </Button>
                </Center>
            )}
        </Flex>
    );
}
