import { Avatar, Flex, Text } from '@chakra-ui/react';
import DateCell from '@Components/DateCell';
import { TFunction } from 'react-i18next';
import { DATE_TIME_FORMAT } from 'src/constants';
import StatusCell from '../StatusCell/StatusCell';

const tableData = (t: TFunction) => {
    const StudentCell = ({ row }) => (
        <Flex align='center'>
            <Avatar size='sm' name={row.original.studentName} mr={2} />
            <Text>{row.original.studentName}</Text>
        </Flex>
    );

    const commonHeaders = [
        { Header: t('RegistrationsList.studentName'), accessor: 'studentName', headerAlign: 'left', Cell: StudentCell },
        { Header: t('RegistrationsList.guardianName'), accessor: 'guardianName', headerAlign: 'left' },
        { Header: t('RegistrationsList.guardianPhone'), accessor: 'guardianPhoneNumber', headerAlign: 'left' },
        { Header: t('RegistrationsList.guardianEmail'), accessor: 'guardianEmail', headerAlign: 'left' },
        {
            Header: t('RegistrationsList.createdOn'),
            accessor: 'createdOn',
            Cell: DateCell(DATE_TIME_FORMAT),
            sortType: 'datetime',
            headerAlign: 'left',
        },
    ];

    const header = {
        all: [
            ...commonHeaders,
            {
                Header: t('RegistrationsList.registrationStatus'),
                accessor: 'status',
                headerAlign: 'center',
                Cell: ({ row }) => <StatusCell value={row.original.Status} />,
            },
        ],
        submittedAndDocumentsRequired: [
            ...commonHeaders,
            {
                Header: t('RegistrationsList.registrationStatus'),
                accessor: 'status',
                headerAlign: 'center',
                Cell: ({ row }) => <StatusCell value={row.original.Status} />,
            },
        ],
        submitted: [...commonHeaders],
        documentsRequired: [...commonHeaders],
        approvedAndArchived: [...commonHeaders],
        rejected: [...commonHeaders],
        deleted: [...commonHeaders],
    };

    return {
        header,
    };
};

export { tableData };
