import { EventResponse } from '@Redux/services/Event/types';
import { useGetPaymentMethodsQuery } from '@Redux/services/LookupApi';
import { createLookupMap } from '@Utilities';
import { CellProps } from 'react-table';
import { useMemo } from 'react';

const PaymentMethodCell: React.FC<CellProps<EventResponse>> = ({ value }) => {
    const { data: { data: paymentMethods = [] } = {} } = useGetPaymentMethodsQuery();
    const paymentMethodsMap = useMemo(() => createLookupMap(paymentMethods), [paymentMethods]);

    return <>{paymentMethodsMap[value]?.value}</>;
};

export default PaymentMethodCell;
