import { Stack } from '@chakra-ui/react';
import { SectionHeader } from '../SectionHeader';
import { SendToMobile } from '@Icon';
import { useTranslation } from 'react-i18next';
import { UploadedJob } from '@Redux/services/Reports/types';
import { ImportItem } from './components/ImportItem';

interface IProps {
    reportingPeriodId: string;
    schoolReportingDayId: string;
    latestJob: UploadedJob;
    pendingReports: number;
    school: string;
    onRefresh: () => Promise<void>;
}

export const Upload: React.FC<IProps> = ({ ...props }) => {
    const { t } = useTranslation();
    return (
        <Stack>
            <SectionHeader
                icon={<SendToMobile />}
                title={t('ReportPeriodDetails.sectionTitle')}
                subtitle={t('ReportPeriodDetails.uploadSubHeader')}
            />
            <Stack gap='8px'>
                <ImportItem {...props} />
            </Stack>
        </Stack>
    );
};
