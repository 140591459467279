import * as React from 'react';
function SvgCycle(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M4.33 12.04c0 1.32.32 2.55.95 3.68.63 1.14 1.5 2.07 2.6 2.78.26.18.43.42.52.71.09.29.06.57-.09.84-.15.28-.38.47-.69.55-.31.09-.6.05-.87-.11a9.923 9.923 0 01-3.47-3.61 9.738 9.738 0 01-1.26-4.84c0-.39.02-.77.06-1.14.04-.37.11-.74.19-1.12l-.23.13c-.25.15-.5.17-.78.06-.27-.1-.48-.28-.63-.54-.15-.27-.19-.56-.11-.86.08-.31.26-.54.53-.69l3.03-1.76c.27-.15.56-.19.87-.11.31.08.54.26.69.53l1.74 3.01c.15.27.19.56.11.87-.08.31-.26.54-.53.69-.27.15-.56.19-.87.11-.31-.08-.54-.26-.69-.53l-.65-1.13c-.15.4-.26.81-.33 1.22-.07.42-.11.84-.11 1.26h.02zm7.71-7.73c-.61 0-1.21.07-1.8.22-.59.15-1.15.36-1.68.63-.29.13-.6.17-.9.11a.954.954 0 01-.69-.49c-.17-.29-.21-.6-.11-.91.09-.31.29-.54.59-.69a8.96 8.96 0 012.22-.86c.77-.19 1.56-.28 2.37-.28 1.27 0 2.5.23 3.67.7 1.17.46 2.23 1.13 3.16 2.01v-.29c0-.3.11-.53.34-.72.23-.18.49-.28.8-.28.31 0 .59.11.81.33.22.22.33.49.33.81v3.5c0 .32-.11.59-.33.81-.22.22-.49.33-.81.33h-3.5c-.32 0-.59-.11-.81-.33-.22-.22-.33-.49-.33-.81 0-.32.11-.59.33-.81.22-.22.49-.33.81-.33h1.32c-.73-.84-1.59-1.5-2.59-1.96-1-.46-2.05-.69-3.17-.69h-.03zm5.93 12.63c.54-.64.96-1.34 1.26-2.11.3-.76.46-1.56.48-2.4.02-.32.14-.6.35-.84.21-.24.47-.36.79-.36.32 0 .59.12.81.36.22.24.33.52.31.83-.04 1-.22 1.96-.53 2.89-.32.93-.77 1.8-1.36 2.6-.63.86-1.37 1.6-2.23 2.21-.86.62-1.8 1.09-2.82 1.4l.19.11c.25.15.4.37.45.67.05.3 0 .58-.15.83-.16.27-.39.44-.69.52-.3.07-.59.04-.85-.12l-3.04-1.75c-.27-.15-.45-.39-.53-.7a1.1 1.1 0 01.11-.87l1.75-3.02c.15-.27.38-.44.68-.52.3-.08.59-.04.86.12.27.15.44.38.53.69.09.31.05.59-.1.86l-.7 1.2c.87-.16 1.69-.47 2.45-.92.76-.45 1.43-1.02 2-1.72l-.02.04z'
                fill='#2C7A7B'
            />
        </svg>
    );
}
export default SvgCycle;
