import { LateAbsenceNote } from '@Redux/services/Absence/types';
import { isEmpty, isNull, isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';

export const NoteTypeCell: React.FC<CellProps<LateAbsenceNote>> = ({
    row: {
        original: { lateReason },
    },
}) => {
    const { t } = useTranslation();
    return !isEmpty(lateReason) && !isUndefined(lateReason) && !isNull(lateReason)
        ? t('LateAbsenceNotes.Cell.late')
        : t('LateAbsenceNotes.Cell.absence');
};
