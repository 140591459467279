import * as React from 'react';
function SvgArrowUpRight(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M12.91 4.669L2.184 15.396a1.079 1.079 0 01-.796.316A1.079 1.079 0 01.276 14.6c0-.318.105-.583.316-.795L11.32 3.078H6.048c-.32 0-.589-.11-.808-.33-.22-.22-.33-.489-.33-.808 0-.319.11-.588.33-.808.22-.22.489-.33.808-.33h8c.32 0 .588.11.808.33.22.22.33.489.33.808v8c0 .32-.11.589-.33.808a1.1 1.1 0 01-.808.33c-.32 0-.589-.11-.808-.33-.22-.22-.33-.489-.33-.808V4.67z'
                fill='#171923'
            />
        </svg>
    );
}
export default SvgArrowUpRight;
