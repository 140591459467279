import * as React from 'react';
function SvgBusNeeds(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <mask
                id='bus-needs_svg__a'
                style={{
                    maskType: 'alpha',
                }}
                maskUnits='userSpaceOnUse'
                x={0}
                y={0}
                width={20}
                height={20}
            >
                <path fill='#D9D9D9' d='M0 0h20v20H0z' />
            </mask>
            <g mask='url(#bus-needs_svg__a)'>
                <path
                    d='M5.342 17.774c-.37 0-.687-.13-.95-.39a1.286 1.286 0 01-.395-.95v-1.306a3.707 3.707 0 01-.604-.963 2.85 2.85 0 01-.23-1.144V5.045c0-1.21.55-2.096 1.647-2.658 1.098-.563 2.829-.845 5.192-.845 2.449 0 4.2.27 5.254.81 1.053.54 1.58 1.438 1.58 2.693v7.976c0 .41-.076.791-.23 1.144a3.708 3.708 0 01-.603.963v1.306c0 .372-.132.689-.395.95-.263.26-.58.39-.95.39-.38 0-.699-.13-.955-.39a1.284 1.284 0 01-.385-.935v-.342H6.682v.342c0 .364-.129.676-.385.935-.256.26-.575.39-.955.39zM5.06 8.438h9.88v-2.5H5.06v2.5zm2.053 4.97c.347 0 .643-.121.886-.364.243-.244.364-.539.364-.886s-.121-.642-.364-.885a1.206 1.206 0 00-.886-.365c-.347 0-.642.122-.885.365a1.206 1.206 0 00-.365.885c0 .347.122.642.365.886.243.242.538.364.885.364zm5.774 0c.347 0 .642-.121.885-.364.243-.244.365-.539.365-.886s-.122-.642-.365-.885a1.206 1.206 0 00-.885-.365c-.347 0-.643.122-.886.365a1.205 1.205 0 00-.364.885c0 .347.121.642.364.886.243.242.539.364.886.364zM5.375 4.271h9.333c-.208-.236-.656-.434-1.343-.593-.688-.16-1.796-.24-3.323-.24-1.486 0-2.573.087-3.26.26-.688.174-1.157.365-1.407.573zm1.351 9.94h6.548c.458 0 .85-.163 1.177-.49.326-.325.49-.718.49-1.176v-2.44H5.06v2.44c0 .458.163.85.49 1.177.326.326.718.49 1.176.49z'
                    fill='#171923'
                />
            </g>
        </svg>
    );
}
export default SvgBusNeeds;
