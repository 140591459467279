import { Pagination } from '@Components';
import ListHeader from '@Components/ListHeader';
import ListItem from '@Components/ListItem';
import { CallLog, Schedule } from '@Icon';
import Header from '@Pages/EventDetails/components/Summary/components/Header';
import { PhoneCallStepProps } from '../../types';
import { callLogEnum } from '@Redux/services/Absence/types';
import { Box, Flex, Text } from '@chakra-ui/react';
import { formatRelative } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_PAGE_SIZE } from 'src/constants';

export type callLogDataType = {
    type: callLogEnum;
    date: Date;
    note: string;
};

const ContactLog = React.forwardRef<HTMLDivElement, PhoneCallStepProps>(({ id, phoneCallDetails }) => {
    const { t } = useTranslation();

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

    const [callLogs, setCallLogs] = useState<callLogDataType[]>([]);

    useEffect(() => {
        let logs = [] as callLogDataType[];

        if (phoneCallDetails.description) {
            const descriptionSplitted = phoneCallDetails.description.split('\n');

            if (!descriptionSplitted || descriptionSplitted.length < 1) return;

            const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:[\d:.]+/;

            logs = [
                ...descriptionSplitted
                    .filter((x) => x.match(regex))
                    .map((val) => {
                        const test = val.match(regex);

                        return {
                            type: callLogEnum.phoneCall,
                            date: new Date(test[0]),
                            note: val.substring(test[0].length),
                        };
                    }),
            ];
        }

        if (phoneCallDetails.notes) {
            logs = [
                ...logs,
                ...phoneCallDetails.notes.map((x) => {
                    return {
                        type: x.noteType,
                        date: new Date(x.noteDate),
                        note: x.noteText,
                    };
                }),
            ];
        }

        setCallLogs(logs.sort((a, b) => b.date.getTime() - a.date.getTime()));
    }, [phoneCallDetails]);

    const getTitle = (type: callLogEnum) => {
        switch (type) {
            case callLogEnum.phoneCall:
                return t('PhoneCall.type.phoneCall');
            case callLogEnum.mfr:
                return t('PhoneCall.type.mfr');
            case callLogEnum.mir3:
                return t('PhoneCall.type.mir3');
            default:
                '';
        }
    };

    const handlePageChange = (pageNum: number) => {
        setPage(pageNum);
    };

    const handlePageSizeChange = (pageSizeNum: number) => {
        setPageSize(pageSizeNum);
    };

    return (
        <Box id={id} my={6}>
            <Header mb={6} title={t('PhoneCall.subheader.contactLog')} icon={<CallLog />} />
            <Flex
                borderRadius='8px 8px 8px 8px'
                borderWidth='2px 2px 2px 2px'
                borderStyle='solid'
                borderColor='border-primary'
                height={'auto'}
                w={'full'}
                align={'stretch'}
                direction={'column'}
                position={'relative'}
            >
                {callLogs.slice((page - 1) * pageSize, page * pageSize).map((log) => {
                    return (
                        <ListItem
                            header={
                                <ListHeader
                                    subtitle={
                                        <Flex alignItems='center'>
                                            <Schedule />
                                            <Text ml={'8px'}>{formatRelative(new Date(log.date), new Date())}</Text>
                                        </Flex>
                                    }
                                    title={getTitle(log.type)}
                                />
                            }
                            description={log.note}
                        />
                    );
                })}
                <Pagination
                    currentPage={page}
                    onPageChange={handlePageChange}
                    pageSize={pageSize}
                    onPageSizeChange={handlePageSizeChange}
                    totalPages={Math.ceil(callLogs?.length / pageSize)}
                    totalResults={callLogs?.length}
                    viewMode={'TextButton'}
                />
            </Flex>
        </Box>
    );
});

export default ContactLog;
