import { FC, ReactNode, useMemo, useState } from 'react';
import { Box, Flex, Stack } from '@chakra-ui/react';
import { Header, HeaderProps } from './components/Header';
import { Pagination } from '@Components/Pagination';
import { DEFAULT_PAGE_SIZE } from 'src/constants';
import { PagedResult } from '@Redux/services/commonTypes';

interface PagedListItems {
    listItems?: JSX.Element[];
    pagedResult?: PagedResult;
}

export interface ListProps {
    headerItems: HeaderProps;
    pagedListItems?: PagedListItems;
    paginated: boolean;
    emptyScreen?: ReactNode;
    onPageChange?: (page: number) => void;
    isLoading?: boolean;
    enablePageSizeChange?: boolean;
    onPageSizeChange?: (newSize: number) => void;
    pageSize?: number;
}

const ListPanel: FC<ListProps> = ({
    headerItems,
    pagedListItems: { listItems, pagedResult },
    paginated,
    emptyScreen,
    onPageChange,
    enablePageSizeChange = false,
    onPageSizeChange,
    pageSize = DEFAULT_PAGE_SIZE,
}) => {
    const [currentPage, setCurrentPage] = useState(1);

    const paginationProps = useMemo(() => {
        if (!pagedResult) {
            const newPageSize = listItems?.length > pageSize ? Math.ceil(listItems?.length / pageSize) : 1;
            return {
                currentPage: currentPage,
                totalPages: newPageSize,
                totalResults: listItems?.length,
                resultsPerPage: newPageSize,
            };
        } else {
            return {
                currentPage: pagedResult.currentPage,
                totalPages: pagedResult.pageCount,
                totalResults: pagedResult.rowCount,
                resultsPerPage: pagedResult.pageSize,
            };
        }
    }, [pagedResult]);

    return (
        <Flex
            flex='1'
            bg='bg.surface'
            borderColor={'border-primary'}
            borderRadius='16px'
            borderWidth='2px'
            borderStyle='solid'
            position='relative'
            h={'full'}
        >
            <Stack justify='space-between' spacing='0' width='full' h={'full'} maxH='100%'>
                <Header {...headerItems} />
                {listItems?.length > 0 && (
                    <Stack shouldWrapChildren id='red' h={'full'} overflowY='auto' overflowX='hidden' px={3}>
                        <Box>{listItems}</Box>
                    </Stack>
                )}
                {listItems?.length > 0 || emptyScreen}
                {paginated && listItems?.length > 0 && (
                    <Stack p={2} borderStyle='solid' borderWidth='2px 0 0 0'>
                        <Pagination
                            onPageChange={!pagedResult ? setCurrentPage : onPageChange}
                            onPageSizeChange={onPageSizeChange}
                            disablePageSizeChange={!enablePageSizeChange}
                            viewMode={'Numbered'}
                            py={0}
                            {...paginationProps}
                            pageSize={pageSize}
                        />
                    </Stack>
                )}
            </Stack>
        </Flex>
    );
};

export default ListPanel;
