import React, { PropsWithChildren } from 'react';
import { Box, SystemStyleObject } from '@chakra-ui/react';
import Fieldset from '@Components/Fieldset';

interface IProps extends PropsWithChildren {
    label: string;
    header?: React.ReactNode;
    headerTextStyle?: string;
    id?: string;
    children?: React.ReactNode;
    contentPadding?: number | string;
    sx?: SystemStyleObject;
}

const FormHeader: React.FC<IProps> = ({
    label,
    header,
    headerTextStyle = 'md-semibold',
    children,
    id,
    contentPadding = 4,
    sx,
}) => {
    return (
        <Box width='100%' id={id}>
            {header}
            <Fieldset legendText={label} legendTextStyle={headerTextStyle} sx={{ background: 'white', ...sx }}>
                <Box mt={1} padding={contentPadding}>
                    {children}
                </Box>
            </Fieldset>
        </Box>
    );
};
export default FormHeader;
