import { AlertDialog } from '@Components/AlertDialog';
import { MenuItem, MenuItemProps, useDisclosure } from '@chakra-ui/react';
import { FC } from 'react';

interface IProps {
    label: string;
    message?: string;
    menuItemProps?: MenuItemProps & { onClick: () => void };
}
export const MenuItemAlert: FC<IProps> = ({ label, message, menuItemProps }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <>
            <MenuItem textStyle={'md-semibold'} {...menuItemProps} onClick={onOpen}>
                {label}
            </MenuItem>
            <AlertDialog
                isOpen={isOpen}
                onClose={onClose}
                onOpen={onOpen}
                submitLabel='Yes'
                cancelButtonLabel='No'
                submitButtonProps={{
                    variant: 'error',
                }}
                cancelButtonProps={{
                    variant: 'outline',
                }}
                submitHandle={() => menuItemProps.onClick()}
                message={message}
            />
        </>
    );
};
