import { baseApi, POST, GET } from '../base';
import {
    CloneEventParams,
    QuestionParams,
    Event,
    EventIdParams,
    EventReschedule,
    EventsParams,
    Question,
    Questions,
    ParticipantParams,
    AssignEventParams,
    ShuffleQuestionsParams,
    EventPaginationParams,
    CostItemParams,
    AnnotationParams,
    ShuffleOrderCostItem,
    DeleteEventParams,
    EventRefundStatistics,
    CreateEventResponseParams,
    CreateEventResponseData,
    CostItemDetail,
    EventNoteParam,
    AnnotationIdParams,
    EventResponse,
    Answer,
    CostItemResponse,
    NoteParams,
} from './types';
import type { PartialDeep } from 'type-fest';
import { FileContent } from '@Utilities';
import { BaseResponse, Note } from '../commonTypes';

const eventDetailsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getEventDetails: builder.query<BaseResponse<Event>, EventIdParams>({
            query: (data) => ({
                url: `Event/GetEvent/${data.id}`,
                method: GET,
            }),
        }),
        createEventReschedule: builder.mutation<BaseResponse<any>, EventReschedule>({
            query: (body) => ({
                body,
                method: POST,
                url: 'Event/CreateReschedule',
            }),
        }),
        getEvents: builder.mutation<BaseResponse<Event[]>, EventsParams>({
            query: (body) => ({
                body,
                method: POST,
                url: 'Event/GetEvents',
            }),
        }),
        updateEvent: builder.mutation<BaseResponse<Event>, PartialDeep<Event>>({
            query: (body) => ({
                body,
                method: 'PATCH',
                url: 'Event/UpdateEvent',
            }),
        }),
        cloneEvent: builder.mutation<BaseResponse<Event>, CloneEventParams>({
            query: (body) => ({
                body,
                method: POST,
                url: 'Event/CloneEvent',
            }),
        }),
        deleteEvent: builder.mutation<BaseResponse<Event>, DeleteEventParams>({
            query: (body) => ({
                body,
                method: POST,
                url: 'Event/DeleteEvent',
            }),
        }),
        createQuestion: builder.mutation<BaseResponse<Question[]>, PartialDeep<Question>>({
            query: (body) => ({
                body,
                method: POST,
                url: 'Event/CreateQuestion',
            }),
        }),
        updateQuestion: builder.mutation<BaseResponse<Question[]>, PartialDeep<Question>>({
            query: (body) => ({
                method: 'PATCH',
                url: 'Event/UpdateQuestion',
                body,
            }),
        }),
        deleteQuestion: builder.mutation<BaseResponse<Question[]>, QuestionParams>({
            query: (body) => ({
                method: POST,
                url: 'Event/DeleteQuestion',
                body,
            }),
        }),
        getQuestion: builder.mutation<BaseResponse<Question>, Partial<QuestionParams>>({
            query: (body) => ({
                method: POST,
                url: 'Event/GetQuestion',
                body,
            }),
        }),
        createEventParticipantsList: builder.mutation<BaseResponse<Questions>, Partial<ParticipantParams>>({
            query: (body) => ({
                method: POST,
                url: 'Event/CreateEventParticipantsList',
                body,
            }),
        }),
        assignEventInvitees: builder.mutation<BaseResponse<Event>, Partial<AssignEventParams>>({
            query: (body) => ({
                method: POST,
                url: 'Event/AssignEventInvitees',
                body,
            }),
        }),
        getCostItem: builder.mutation<BaseResponse<CostItemDetail>, Partial<CostItemParams>>({
            query: (body) => ({
                method: 'POST',
                url: 'Event/GetCostItem',
                body,
            }),
        }),
        createCostItem: builder.mutation<BaseResponse<CostItemDetail[]>, PartialDeep<CostItemDetail>>({
            query: (body) => ({
                body,
                method: 'POST',
                url: 'Event/CreateCostItem',
            }),
        }),
        updateCostItem: builder.mutation<BaseResponse<CostItemDetail[]>, PartialDeep<CostItemDetail>>({
            query: (body) => ({
                method: 'PATCH',
                url: 'Event/UpdateCostItem',
                body,
            }),
        }),
        deleteCostItem: builder.mutation<BaseResponse<CostItemDetail[]>, PartialDeep<CostItemParams>>({
            query: (body) => ({
                method: 'POST',
                url: 'Event/DeleteCostItem',
                body,
            }),
        }),
        shuffleOrderCostItem: builder.mutation<BaseResponse<any>, ShuffleOrderCostItem>({
            query: (body) => ({
                method: 'POST',
                url: 'Event/ShuffleOrderCostItem',
                body,
            }),
        }),
        shuffleOrderQuestion: builder.mutation<BaseResponse<any>, ShuffleQuestionsParams>({
            query: (body) => ({
                method: 'POST',
                url: 'Event/ShuffleOrderQuestion',
                body,
            }),
        }),
        getEventResponses: builder.mutation<BaseResponse<EventResponse[]>, EventPaginationParams>({
            query: (body) => ({
                method: 'POST',
                url: 'Event/GetEventResponses',
                body,
            }),
        }),
        getEventAnswers: builder.mutation<BaseResponse<Answer[]>, EventPaginationParams>({
            query: (body) => ({
                method: 'POST',
                url: 'Event/GetEventAnswers',
                body,
            }),
        }),
        createEvent: builder.mutation<BaseResponse<Event>, PartialDeep<Event>>({
            query: (body) => ({
                method: 'POST',
                url: 'Event/CreateEvent',
                body,
            }),
        }),
        getEventRefundStatistics: builder.query<BaseResponse<EventRefundStatistics>, EventIdParams>({
            query: (param) => ({
                method: GET,
                url: `Event/GetEventRefundStatistics/${param.id}`,
            }),
        }),
        exportAnswerResponses: builder.mutation<BaseResponse<FileContent>, PartialDeep<EventPaginationParams>>({
            query: (body) => ({
                method: POST,
                url: 'Event/ExportAnswerResponses',
                body,
            }),
        }),
        exportCostItemResponses: builder.mutation<BaseResponse<FileContent>, PartialDeep<EventPaginationParams>>({
            query: (body) => ({
                method: POST,
                url: 'Event/ExportCostItemResponses',
                body,
            }),
        }),
        exportEventResponses: builder.mutation<BaseResponse<FileContent>, PartialDeep<EventPaginationParams>>({
            query: (body) => ({
                method: POST,
                url: 'Event/ExportEventResponses',
                body,
            }),
        }),
        getEventCostItemResponses: builder.mutation<BaseResponse<CostItemResponse[]>, EventPaginationParams>({
            query: (body) => ({
                method: POST,
                url: 'Event/GetEventCostItemResponses',
                body,
            }),
        }),
        CreateEventResponse: builder.mutation<BaseResponse<CreateEventResponseData>, CreateEventResponseParams>({
            query: (body) => ({
                method: POST,
                url: 'Event/CreateEventResponse',
                body,
            }),
        }),
        getEventNotes: builder.mutation<BaseResponse<Note[]>, NoteParams>({
            query: (body) => ({
                method: POST,
                url: 'Event/GetEventNotes',
                body,
            }),
        }),
        createEventNote: builder.mutation<BaseResponse<Note[]>, EventNoteParam>({
            query: (body) => ({
                method: POST,
                url: 'Event/CreateEventNote',
                body,
            }),
        }),
        deleteEventNote: builder.mutation<BaseResponse<Note[]>, AnnotationIdParams>({
            query: (body) => ({
                method: POST,
                url: 'Event/DeleteEventNote',
                body,
            }),
        }),
        updateEventNote: builder.mutation<BaseResponse<Note[]>, AnnotationParams>({
            query: (body) => ({
                method: POST,
                url: 'Event/UpdateEventNote',
                body,
            }),
        }),
    }),
});

export const {
    useGetEventDetailsQuery,
    useLazyGetEventDetailsQuery,
    useCreateEventRescheduleMutation,
    useGetEventsMutation,
    useCloneEventMutation,
    useDeleteEventMutation,
    useUpdateEventMutation,
    useCreateQuestionMutation,
    useDeleteQuestionMutation,
    useUpdateQuestionMutation,
    useGetCostItemMutation,
    useCreateCostItemMutation,
    useUpdateCostItemMutation,
    useDeleteCostItemMutation,
    useShuffleOrderCostItemMutation,
    useGetQuestionMutation,
    useCreateEventParticipantsListMutation,
    useShuffleOrderQuestionMutation,
    useGetEventResponsesMutation,
    useGetEventAnswersMutation,
    useCreateEventMutation,
    useGetEventRefundStatisticsQuery,
    useExportAnswerResponsesMutation,
    useExportCostItemResponsesMutation,
    useExportEventResponsesMutation,
    useGetEventCostItemResponsesMutation,
    useCreateEventResponseMutation,
    useGetEventNotesMutation,
    useCreateEventNoteMutation,
    useDeleteEventNoteMutation,
    useUpdateEventNoteMutation,
} = eventDetailsApi;

export default eventDetailsApi;
