import { Box, Flex, Text } from '@chakra-ui/react';
import { isString } from 'lodash';
import { FC } from 'react';

export interface ListHeaderProps {
    icon?: React.ReactNode;
    avatar?: React.ReactNode;
    title: string | React.ReactNode;
    subtitle: string | React.ReactNode;
    rightLabel?: string;
    content?: string;
    contentNode?: React.ReactNode;
}
const ListHeader: FC<ListHeaderProps> = ({ icon, avatar, title, subtitle, rightLabel, content, contentNode }) => {
    return (
        <Box width='100%'>
            <Flex justifyContent={'space-between'} width='100%'>
                <Flex width='100%' align={'start'} gap={4}>
                    {icon && <Box mr={3}>{icon}</Box>}
                    {avatar}
                    <Box w={'full'}>
                        <Text
                            textAlign={'left'}
                            textStyle='sm-medium'
                            sx={{
                                _first: {
                                    textTransform: 'Capitalize',
                                },
                            }}
                        >
                            {title}
                        </Text>
                        {isString(subtitle) ? (
                            <Text textTransform='capitalize' color='text-secondary' textStyle={'sm-normal'}>
                                {subtitle}
                            </Text>
                        ) : (
                            subtitle
                        )}
                    </Box>
                </Flex>
                {rightLabel && (
                    <Text
                        width={'100%'}
                        display='flex'
                        justifyContent='flex-end'
                        color='text-secondary'
                        textStyle={'sm-normal'}
                    >
                        {rightLabel}
                    </Text>
                )}
            </Flex>
            {content && (
                <Text textAlign='left' mt={'8px'} color='text-secondary' noOfLines={1} textStyle={'sm-normal'}>
                    {content}
                </Text>
            )}
            {contentNode && !content && (
                <Text textAlign='left' mt={'8px'} color='text-secondary' noOfLines={1} textStyle={'sm-normal'}>
                    {contentNode}
                </Text>
            )}
        </Box>
    );
};

export default ListHeader;
