import { Box, Divider, Flex, HStack, Text, useDisclosure } from '@chakra-ui/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import Table from '@Components/Table';
import { Column } from 'react-table';
import { Search } from '@Components/Search';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import Sidebar from '@Components/Sidebar';
import { Pagination, RangeDatepicker } from '@Components';
import { useGetEventsMutation } from '@Redux/services/Event';
import { useTranslation } from 'react-i18next';
import { Button } from '@Components/Button';
import { Save, Plus } from '@Icon';
import { createLookupMap, getErrorDetails } from '@Utilities';
import { tableData } from './components/TableHeader';
import { sideBarData } from './components/Sidebar';
import { useGetActivityTypesQuery, useGetParticipantTypesQuery } from '@Redux/services/LookupApi';
import { useDebounce } from '@Hooks';
import { Event } from '@Redux/services/Event/types';
import { CustomError, PagedResult } from '@Redux/services/commonTypes';
import { useToast } from '@Hooks/useToast';
import { BasicLookup } from '@Redux/services/LookupApi/types';
import { DEFAULT_PAGE_SIZE, EventStatus } from 'src/constants';
import { CreateEvent } from './components/CreateEvent';

const links = ['all', 'proposed', 'validated', 'published', 'closed', 'cancelled'] as const;

type EventStatusParam = (typeof links)[number];

type SortByProps = { id: string; desc: boolean };

const getStatusCode = (e: EventStatusParam) => {
    switch (e) {
        case 'proposed':
            return EventStatus.Proposed;
        case 'validated':
            return EventStatus.Validated;
        case 'published':
            return EventStatus.Published;
        case 'closed':
            return EventStatus.Closed;
        case 'cancelled':
            return EventStatus.Cancelled;
    }
};

const getStatusByCode = (e: number) => {
    switch (e) {
        case EventStatus.Proposed:
            return 'Proposed';
        case EventStatus.Validated:
            return 'Validated';
        case EventStatus.Published:
            return 'Published';
        case EventStatus.Closed:
            return 'Closed';
        case EventStatus.Cancelled:
            return 'Cancelled';
    }
};

const getSortByField = (s: SortByProps) => {
    let sBy = s;
    switch (s?.id) {
        case 'name':
            sBy = { ...s, id: 'Name' };
            break;
        case 'publishDate':
            sBy = { ...s, id: 'PublishDate' };
            break;
        case 'eventCode':
            sBy = { ...s, id: 'EventCode' };
            break;
        case 'category':
            sBy = { ...s, id: 'CategoryId' };
            break;
        case 'subCategory':
            sBy = { ...s, id: 'SubCategoryId' };
            break;
        case 'startDate':
            sBy = { ...s, id: 'StartDate' };
            break;
        case 'endDate':
            sBy = { ...s, id: 'EndDate' };
            break;
        case 'closeDate':
            sBy = { ...s, id: 'CloseDate' };
            break;
        case 'dueDate':
            sBy = { ...s, id: 'DueDate' };
            break;
        case 'createdOn':
            sBy = { ...s, id: 'CreatedOn' };
            break;
        case 'activityType':
            sBy = { ...s, id: 'ActivityTypeId' };
            break;
        case 'team':
            sBy = { ...s, id: 'School' };
            break;
        case 'targetParticipantType':
            sBy = { ...s, id: 'TargetType' };
            break;
        case 'status':
            sBy = { ...s, id: 'StatusCode' };
            break;
        case 'responsesPending':
            sBy = { ...s, id: 'ResponsesPending' };
            break;
        case 'responsesAccepted':
            sBy = { ...s, id: 'ResponsesAccepted' };
            break;
        case 'responsesCancelled':
            sBy = { ...s, id: 'ResponsesCancelled' };
            break;
        case 'cancelDate':
            sBy = { ...s, id: 'CancelledOn' };
            break;
    }
    return sBy;
};

const Events: React.FC<{ status?: EventStatusParam }> = ({ status = 'published' }) => {
    const [selectedRow, setSelectedRow] = useState<Event | null>(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

    const { t } = useTranslation();

    const toast = useToast();

    const { data: { data: pTypes = [] } = {} } = useGetParticipantTypesQuery(undefined);

    const { data: { data: aTypes = [] } = {} } = useGetActivityTypesQuery(undefined);

    const [mutate, { isLoading, data: { data: eventsRes = [], pagedResult = {} as PagedResult } = {}, error }] =
        useGetEventsMutation();

    const participantsMap = useMemo(() => createLookupMap<BasicLookup>(pTypes), [pTypes]);
    const activityTypesMap = useMemo(() => createLookupMap<BasicLookup>(aTypes), [aTypes]);

    const events = useMemo(() => {
        return eventsRes?.map((d) => ({
            ...d,
            targetParticipantType: participantsMap[d?.participants?.targetType]?.value,
            activityType: activityTypesMap[d?.activityTypeId]?.value,
            status: getStatusByCode(d?.status),
        }));
    }, [eventsRes, participantsMap, activityTypesMap]);

    useEffect(() => {
        if (error) {
            const { title, description } = getErrorDetails(t, error as CustomError);
            toast({
                title,
                description,
                position: 'top-right',
                status: 'error',
            });
        }
    }, [error]);

    const mainContainerRef = useRef<HTMLDivElement>(null);

    const onEditHandler = (row) => {
        setSelectedRow(row.original);
        navigate(`/events/${row.original.eventId}`);
    };

    const onDeleteHandler = (row) => {
        setSelectedRow(row.original);
    };

    const { header } = tableData(t, onEditHandler, onDeleteHandler);

    const [headers, setHeaders] = useState(header);

    const sideBarItems = useMemo(() => sideBarData(status, t), [status]);

    const navigate = useNavigate();

    const defaultSortOrder = { id: 'createdOn', desc: true };

    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [sortBy, setSortBy] = useState<SortByProps>(defaultSortOrder);
    const [dateRange, setDateRange] = useState<Date[]>([]);

    useEffect(() => {
        const { header: h } = tableData(t, onEditHandler, onDeleteHandler);
        setSortBy(defaultSortOrder);
        setCurrentPage(1);
        setSearchQuery('');
        setDateRange([]);
        setHeaders(h);
    }, [status]);

    const columns = useMemo<Column[]>(() => headers[status], [status, selectedRow, headers, sortBy]);

    const [initialized, setInitialized] = useState(false);

    const getEvents = (sortBy, currentPage, searchQuery, eventStatus, dateRange) => {
        const sBy = getSortByField(sortBy);

        // fixDate and fixDateEnd is used to enable search to be date inclusive
        const pubObj =
            (eventStatus === 'all' ||
                eventStatus === 'published' ||
                eventStatus === 'closed' ||
                eventStatus === 'cancelled') &&
            dateRange?.length > 1
                ? {
                      StartDate: dateRange[0],
                      EndDate: dateRange[1],
                  }
                : {};
        const obj = _.merge(
            {
                sortBy: sBy?.id,
                isDesc: sBy?.desc,
                currentPage: currentPage,
                pageSize: pageSize,
                searchText: searchQuery,
                statusCode: getStatusCode(eventStatus),
            },
            pubObj
        );
        mutate(eventStatus === 'all' ? _.omit(obj, ['statusCode']) : obj);
    };

    const debouncedResults = useDebounce(() => {
        getEvents(sortBy, currentPage, searchQuery, status, dateRange);
    });

    useEffect(() => {
        debouncedResults();
    }, [searchQuery]);

    // TODO: First click of selecting daterange triggers redundant API call
    useEffect(() => {
        if (!initialized) return;
        let dateRangeFilter = [];
        if (dateRange?.length > 1 || dateRange?.length === 0) {
            dateRangeFilter = dateRange;
        }
        getEvents(sortBy, currentPage, searchQuery, status, dateRangeFilter);
    }, [currentPage, sortBy, dateRange, pageSize]);

    useEffect(() => {
        setTimeout(() => {
            setInitialized(true);
        }, 500);
    }, []);

    const onSortChange = (sortBy) => {
        if (sortBy[0]) {
            setSortBy(sortBy[0]);
        }
    };

    // Resetting pagination and filtering when searching
    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const handlePageSizeChange = (newPageSize: number) => {
        setCurrentPage(1);
        setPageSize(newPageSize);
    };

    return (
        <>
            <Flex h='inherit'>
                <Sidebar {...sideBarItems} />
                <Flex w='100%' ref={mainContainerRef} py={0} pl={2} pr={4} overflowX={'hidden'} gap={4} h='inherit'>
                    <Flex
                        overflowX={'hidden'}
                        borderRadius='20px 0 0 0'
                        borderWidth='2px 2px 2px 2px'
                        borderStyle='solid'
                        borderColor='border-primary'
                        height={'full'}
                        align={'stretch'}
                        direction={'column'}
                        w='100%'
                    >
                        <Box>
                            <Flex
                                justify={'space-between'}
                                align={'center'}
                                p={6}
                                flexDirection={{ base: 'column', md: 'row' }}
                            >
                                <Text textStyle={'sm-medium'} pb={{ base: 2, md: 0 }}>
                                    {t(`EventsList.${status}`)} {t('EventsList.events')}
                                </Text>
                                <HStack spacing={5}>
                                    <Box maxW={224} minW={224}>
                                        <Search iconPosition='left' query={searchQuery} onChange={handleSearch} />
                                    </Box>
                                    <Button
                                        mr={2}
                                        variant={'primary'}
                                        leftIcon={<Plus width={20} height={20} />}
                                        onClick={onOpen}
                                    >
                                        {t('Events.createEventButtonTitle')}
                                    </Button>
                                </HStack>
                            </Flex>

                            <Divider h='2px' bg='lineColor' />

                            {(status === 'all' ||
                                status === 'published' ||
                                status === 'closed' ||
                                status === 'cancelled') && (
                                <Flex justifyContent={{ base: 'center', md: 'start' }}>
                                    <Box p={6} py={3} pl={{ base: 0, md: 6 }} maxW={300}>
                                        <RangeDatepicker
                                            onDateChange={setDateRange}
                                            isLocked={false}
                                            selectedDates={dateRange}
                                            onResetClick={() => setDateRange([])}
                                            placeHolder={t('Events.dateRangeplaceholder')}
                                        />
                                    </Box>
                                </Flex>
                            )}
                        </Box>

                        <Flex position='relative' flex='auto' overflow='hidden'>
                            <Table
                                manualPagination={true}
                                manualSortBy={true}
                                columns={columns}
                                rawData={isLoading ? [] : events}
                                emptyMessage={t('EventsList.noData')}
                                isLoading={isLoading}
                                onSortChange={onSortChange}
                                initialSortBy={sortBy ? [sortBy] : []}
                                onEditHandler={onEditHandler}
                                //tableWidth={{ 'table-layout': 'auto', width: '100%' }}
                                //containerStyle={{ borderLeftWidth: 0, borderRightWidth: 0, borderRadius: 0 }}
                                stickyHeader
                            />
                        </Flex>

                        {!pagedResult?.pageCount ? null : (
                            <Box p={2}>
                                <Pagination
                                    currentPage={currentPage}
                                    onPageChange={setCurrentPage}
                                    totalPages={pagedResult?.pageCount}
                                    totalResults={pagedResult?.rowCount}
                                    onPageSizeChange={handlePageSizeChange}
                                    pageSize={pageSize}
                                />
                            </Box>
                        )}
                    </Flex>
                </Flex>
            </Flex>
            {isOpen && <CreateEvent isOpen={isOpen} onClose={onClose} />}
        </>
    );
};

export { Events, type EventStatusParam };
