import { Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StepProps } from '@Pages/EventDetails/types';
import TypeaheadInput from '@Components/TypeaheadInput';

interface IProps extends StepProps {
    status: string;
    isCancelled: boolean;
    eventId: string;
    eventName: string;
}

const Status: FC<IProps> = ({ eventId, eventName, manageFieldPropFactory, textFieldPropFactory }) => {
    const { t } = useTranslation();
    return (
        <>
            <Flex mt={4} width='100%' justifyContent={'space-between'}>
                <TypeaheadInput
                    {...textFieldPropFactory(t('EventResponseDetail.Status.event'), ['event', 'name'])}
                    {...manageFieldPropFactory(['event', 'eventId'], 'onSelection', 'value')}
                    options={[{ key: eventId, value: eventName }]}
                    isLocked={true}
                    baseUrl='/events'
                />
            </Flex>
        </>
    );
};

export default Status;
