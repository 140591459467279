import { RegistrationStatus } from 'src/constants';
import { FormComponentProps } from '@Hooks';
import { Note, PagedResult } from '@Redux/services/commonTypes';
import { BasicLookup } from '@Redux/services/ucdsbApi';

export interface NotesProps extends FormComponentProps {
    notes: Note[];
    notePagination?: PagedResult;
    isNotesLoading?: boolean;
    hideActivities?: boolean;
    onAddNote?: (noteText: string) => Promise<void>;
    onDeleteNote?: (objectId: string) => Promise<void>;
    onUpdateNote?: (noteText: string, objectId: string) => Promise<void>;
    onNextPage?: (currentPage: number, pageSize: number) => Promise<void>;
    isNoteEditable?: boolean;
    hideFormHeader?: boolean;
}

export interface NotesProps extends FormComponentProps {
    notes: Note[];
    notePagination?: PagedResult;
    isNotesLoading?: boolean;
    hideActivities?: boolean;
    onAddNote?: (noteText: string) => Promise<void>;
    onDeleteNote?: (annotationId: string) => Promise<void>;
    onUpdateNote?: (noteText: string, annotationId: string) => Promise<void>;
    onNextPage?: (currentPage: number, pageSize: number) => Promise<void>;
    isNoteEditable?: boolean;
    hideFormHeader?: boolean;
}

export const enum RegistrationSidebarItem {
    AboutStudent = 0,
    BusNeeds,
    Citizenship,
    GuardianContactDetails,
    HomeAddress,
    Notes,
    OfficeAdministration,
    PreviousSchool,
    School,
    None,
}

export const RegistrationStatuses = {
    [RegistrationStatus.Submitted]: 'Submitted',
    [RegistrationStatus.Approved]: 'Approved',
    [RegistrationStatus.Rejected]: 'Rejected',
    [RegistrationStatus.Archived]: 'Archived',
    [RegistrationStatus.Deleted]: 'Deleted',
    [RegistrationStatus.DocumentsRequired]: 'Documents Required',
};

export interface AboutStudentProps extends FormComponentProps {
    onGradeChange?: (objectId: number) => Promise<void>;
}

export interface AcademicDetailsProps extends FormComponentProps {
    onGradeChange?: (objectId: number) => Promise<void>;
}

export interface SchoolsProps extends FormComponentProps {
    schools: BasicLookup[];
}
