import { Flex, Text } from '@chakra-ui/layout';
import { useTranslation } from 'react-i18next';
import { Refresh } from '@Icon';
import { GhostIcon } from '@Components/Button';
import { ReactNode } from 'react';
import { Stack } from '@chakra-ui/react';
import { IconWithBadge } from '@Components/IconWithBadge';

export type HeaderProps = {
    title: string;
    subTitle?: string;
    handleRefresh?: () => void;
    extra?: ReactNode;
    newRecordAmount?: number;
};

const Header: React.FC<HeaderProps> = ({ title, subTitle, handleRefresh, extra, newRecordAmount }) => {
    const { t } = useTranslation();

    return (
        <Stack p={6} borderColor={'border-primary'} borderWidth='0 0 2px 0' borderStyle='solid'>
            <Flex justify={'space-between'} align={'center'} flexDirection={{ base: 'column', md: 'row' }}>
                <Flex flexDirection={'column'}>
                    <Text textStyle={'md-bold'} pb={{ base: 2, md: 0 }}>
                        {title}
                    </Text>
                    {subTitle && (
                        <Text textStyle={'sm-medium'} pb={{ base: 2, md: 0 }}>
                            {subTitle}
                        </Text>
                    )}
                </Flex>
                {handleRefresh && (
                    <IconWithBadge badgeText={newRecordAmount > 0 ? newRecordAmount.toString() : ''}>
                        <GhostIcon
                            aria-label='refresh'
                            size={'sm'}
                            borderRadius='sm'
                            title={t('ListPanel.refresh')}
                            icon={<Refresh width={20} height={20} fill='text-primary' />}
                            onClick={handleRefresh}
                        />
                    </IconWithBadge>
                )}
            </Flex>
            {extra && <Flex mt={2}>{extra}</Flex>}
        </Stack>
    );
};

export { Header };
