import { ProposedAndValidated } from '@Pages/EventDetails/types';

export const costItemEditableFieldsByStatus = {
    title: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    required: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    unitCost: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    maxQuantityAllowed: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option1: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option1Group: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option2: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option2Group: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option3: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option3Group: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option4: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option4Group: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option5: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option5Group: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option6: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option6Group: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option7: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option7Group: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option8: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option8Group: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option9: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option9Group: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option10: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option10Group: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option11: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option11Group: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option12: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option12Group: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option13: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option13Group: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option14: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option14Group: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option15: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
    option15Group: {
        required: true,
        editableStatus: ProposedAndValidated,
    },
};
