import React from 'react';
import { FormComponentProps, ManageTextProps, ManageSwitchInputProps } from '@Hooks';
import { useTranslation } from 'react-i18next';
import { Grid, Box } from '@chakra-ui/react';
import SelectInput from '@Components/SelectInput';
import TextInput from '@Components/TextInput';
import Switch from '@Components/Switch';
import { BasicLookup } from '@Redux/services/LookupApi/types';

interface Contact2DetailsProps extends FormComponentProps {
    relationships: BasicLookup[];
    languages: BasicLookup[];
}

const Contact2: React.FC<Contact2DetailsProps> = ({
    manageFieldPropFactory,
    selectFieldPropFactory,
    textFieldPropFactory,
    switchFieldPropFactory,
    relationships,
    languages,
}) => {
    const [t] = useTranslation();

    return (
        <>
            <Grid
                column={{ md: 2, sm: 1 }}
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                }}
                rowGap={4}
                templateRows='auto'
                mb={6}
            >
                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.contact.contact2.relationshipToStudent'), [
                        'emergencyContactInformation',
                        'emergencyContact2',
                        'relationshipToStudent',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['emergencyContactInformation', 'emergencyContact2', 'relationshipToStudent'],
                        'onChangeValue',
                        'value'
                    )}
                    options={relationships}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.contact.contact2.primaryPhone'), [
                        'emergencyContactInformation',
                        'emergencyContact2',
                        'primaryPhoneNumber',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['emergencyContactInformation', 'emergencyContact2', 'primaryPhoneNumber'],
                        'onChangeText',
                        'value'
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.contact.contact2.workPhone'), [
                        'emergencyContactInformation',
                        'emergencyContact2',
                        'workPhoneNumber',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['emergencyContactInformation', 'emergencyContact2', 'workPhoneNumber'],
                        'onChangeText',
                        'value'
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.contact.contact2.workExtension'), [
                        'emergencyContactInformation',
                        'emergencyContact2',
                        'workExtension',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['emergencyContactInformation', 'emergencyContact2', 'workExtension'],
                        'onChangeText',
                        'value'
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.contact.contact2.mobilePhone'), [
                        'emergencyContactInformation',
                        'emergencyContact2',
                        'mobilePhoneNumber',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['emergencyContactInformation', 'emergencyContact2', 'mobilePhoneNumber'],
                        'onChangeText',
                        'value'
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.contact.contact2.firstName'), [
                        'emergencyContactInformation',
                        'emergencyContact2',
                        'firstName',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['emergencyContactInformation', 'emergencyContact2', 'firstName'],
                        'onChangeText',
                        'value'
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.contact.contact2.lastName'), [
                        'emergencyContactInformation',
                        'emergencyContact2',
                        'surname',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['emergencyContactInformation', 'emergencyContact2', 'surname'],
                        'onChangeText',
                        'value'
                    )}
                />

                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.contact.contact2.languageSpoken'), [
                        'emergencyContactInformation',
                        'emergencyContact2',
                        'language',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['emergencyContactInformation', 'emergencyContact2', 'language'],
                        'onChangeValue',
                        'value'
                    )}
                    options={languages}
                />

                <Box pb={4}>
                    <Switch
                        {...switchFieldPropFactory(t('RegistrationDetails.contact.contact2.canPickUpChild'), [
                            'emergencyContactInformation',
                            'emergencyContact2',
                            'canPickUpChild',
                        ])}
                        {...manageFieldPropFactory<ManageSwitchInputProps>(
                            ['emergencyContactInformation', 'emergencyContact2', 'canPickUpChild'],
                            'onChangeSwitch',
                            'isChecked'
                        )}
                    />
                </Box>
            </Grid>
        </>
    );
};

export default Contact2;
