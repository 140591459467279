import {
    Box,
    FormControl,
    FormLabel,
    Switch as ChakraSwitch,
    Text,
    SwitchProps as ChakraSwitchProps,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface SwitchProps extends ChakraSwitchProps {
    label: string;
    onChangeSwitch: (value: boolean) => void;
    isInvalid?: boolean;
    isLocked?: boolean;
    col?: number;
    checkedText?: string;
    uncheckedText?: string;
}

const Switch: FC<SwitchProps> = ({
    label,
    onChangeSwitch,
    isInvalid,
    isLocked,
    col,
    checkedText,
    uncheckedText,
    ...rest
}) => {
    const { t } = useTranslation();

    return (
        <FormControl display={{ base: 'block', lg: 'flex' }} alignItems='baseline'>
            <FormLabel
                color={(isLocked || rest.isDisabled) && 'text-disabled'}
                flexBasis={col === 1 ? '12.5%' : '30%'}
                textStyle={'sm-medium'}
                htmlFor={label}
            >
                {label}
            </FormLabel>
            <Box display='flex' alignItems={'center'} w='100%'>
                <ChakraSwitch
                    colorScheme={'teal'}
                    onChange={(event) => onChangeSwitch(event.target.checked)}
                    id={label}
                    isInvalid={isInvalid}
                    isDisabled={isLocked}
                    {...rest}
                />
                <Text as='span' textStyle='md-normal' ml={4} color={isInvalid && 'border-error'}>
                    {rest.isChecked ? checkedText ?? t('Switch.yes') : uncheckedText ?? t('Switch.no')}
                </Text>
            </Box>
        </FormControl>
    );
};

export default Switch;
