import { editableFieldsForParticipants } from '@Pages/EventParticipants/types';
import { PagedResult } from '@Redux/services/commonTypes';
import { useGetMembersListMutation, useUpdateListMutation } from '@Redux/services/Participants';
import { CommonMembersResponse, GetMemberListsParams } from '@Redux/services/Participants/types';
import { Accessor, parseError, parseWarning } from '@Utilities';
import { isUndefined } from 'lodash';
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_PAGE_SIZE } from 'src/constants';
import useAutoSave from './useAutoSave';
import { useForms } from './useForms';
import { useToast } from './useToast';

export const useCustomList = (listId: string) => {
    const { t } = useTranslation();
    const [getMembers, { data: { data: customList = {} as CommonMembersResponse } = {}, isLoading }] =
        useGetMembersListMutation();
    const [pagedResult, setPagedResult] = useState<PagedResult>();
    const [updateList, { isLoading: isUpdating }] = useUpdateListMutation();
    const toast = useToast();

    const getCustomList = useCallback(
        async (
            params: GetMemberListsParams = {
                listId,
                sortBy: 'fullName',
                isDesc: false,
                currentPage: 1,
                pageSize: DEFAULT_PAGE_SIZE,
                searchText: '',
            }
        ) => {
            await getMembers(params)
                .unwrap()
                .then((response) => {
                    setParticipantDetails(response.data);
                    setPagedResult(response.pagedResult);
                })
                .catch((e) => {
                    parseError(toast, e);
                });
        },
        [getMembers]
    );

    useEffect(() => {
        getCustomList();
    }, [listId]);

    const getCommonProps = (accessorPath: Accessor[]) => {
        const accesor = accessorPath.join('.');
        const item = editableFieldsForParticipants[accesor];
        const hasStatus = !isUndefined(item) ? item.editableStatus : false;
        const isLocked = (isUndefined(item) ? true : !hasStatus) || customList?.isPreviewlist;
        const isRequired = hasStatus && item.required;
        return {
            isLocked,
            isRequired,
        };
    };

    const {
        formData: participantDetails,
        errors,
        manageFieldPropFactory,
        selectFieldPropFactory,
        textFieldPropFactory,
        typeaheadPropFactory,
        textAreaPropFactory,
        setErrors,
        setIsDirty,
        setDirtyData,
        setFormData: setParticipantDetails,
        dirtyData,
        isDirty,
    } = useForms<CommonMembersResponse>(customList ?? {}, getCommonProps);

    const handleSave = async () => {
        await updateList({
            listId: listId,
            listName: participantDetails?.listName,
            description: participantDetails?.description,
        })
            .unwrap()
            .then((response) => {
                setHasPendingChange(false);
                setParticipantDetails(response.data);
                setIsDirty(false);
                setDirtyData({});
                if (response?.warningResult?.showAlert) {
                    parseWarning(toast, response);
                } else {
                    toast({
                        status: 'success',
                        description: t('EventParticipant.listUpdated'),
                        isClosable: true,
                    });
                }
            })
            .catch((e) => {
                setErrors(e.data.errors);
                parseError(toast, e);
                setHasPendingChange(true);
            });
    };
    const { hasPendingChange, handleManualSave, setHasPendingChange } = useAutoSave({
        data: dirtyData,
        onChange: handleSave,
    });

    return {
        participantDetails,
        errors,
        manageFieldPropFactory,
        selectFieldPropFactory,
        textFieldPropFactory,
        typeaheadPropFactory,
        textAreaPropFactory,
        setErrors,
        setFormData: setParticipantDetails,
        dirtyData,
        handleSave,
        handleManualSave,
        hasPendingChange,
        setHasPendingChange,
        isLoading,
        isUpdating,
        getCustomList,
        pagedResult,
        setPagedResult,
        isDirty,
    };
};
