import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { Trash } from '@Icon';
import { FC } from 'react';

type Props = {
    onClick?: () => void;
} & Partial<IconButtonProps>;

const TrashIconButton: FC<Props> = ({ onClick, ...rest }) => {
    return (
        <IconButton
            aria-label={'clear'}
            icon={<Trash />}
            background='transparent'
            alignSelf='center'
            onClick={onClick}
            position='absolute'
            right='10px'
            width='25px'
            height='25px'
            minWidth='25px'
            {...rest}
        />
    );
};

export default TrashIconButton;
