import FormHeader from '@Components/FormHeader';
import React from 'react';
import InnerFormHeader from '../InnerFormHeader';
import { useTranslation } from 'react-i18next';
import { FormComponentProps, ManageTextProps } from '@Hooks';
import { Grid } from '@chakra-ui/react';
import SelectInput from '@Components/SelectInput';
import {
    useGetAdmissionReasonTypesRequestQuery,
    useGetBeforeOrAfterSchoolCareOptionsQuery,
    useGetSourceDocumentTypesRequestQuery,
} from '@Redux/services/LookupApi';
import SvgOfficeAdministration from '@Icon/OfficeAdministration';
import TextInput from '@Components/TextInput';

const OfficeAdministration: React.FC<FormComponentProps> = ({
    id,
    manageFieldPropFactory,
    selectFieldPropFactory,
    textFieldPropFactory,
}) => {
    const [t] = useTranslation();

    const { data: { data: sourceDocuments = [] } = {} } = useGetSourceDocumentTypesRequestQuery({ searchText: '' });
    const { data: { data: reasons = [] } = {} } = useGetAdmissionReasonTypesRequestQuery({ searchText: '' });
    const { data: { data: beforeOrAfterSchoolCareOptions = [] } = {} } = useGetBeforeOrAfterSchoolCareOptionsQuery({
        searchText: '',
    });

    return (
        <FormHeader
            id={id}
            header={
                <InnerFormHeader
                    divider={true}
                    title={t('RegistrationDetails.officeAdministration')}
                    icon={<SvgOfficeAdministration />}
                />
            }
            label={''}
        >
            <Grid
                column={{ md: 2, sm: 1 }}
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                }}
                rowGap={4}
                templateRows='auto'
            >
                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.officeAdministration.sourceDocument'), [
                        'sourceDocument',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(['sourceDocument'], 'onChangeValue', 'value')}
                    options={sourceDocuments}
                />

                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.officeAdministration.reason'), [
                        'admissionReason',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(['admissionReason'], 'onChangeValue', 'value')}
                    options={reasons}
                />

                <SelectInput
                    {...selectFieldPropFactory(
                        t('RegistrationDetails.aboutStudent.medicalDetails.beforeAndOrAfterSchoolCare'),
                        ['childInformation', 'beforeAndOrAfterSchoolCare']
                    )}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['childInformation', 'beforeAndOrAfterSchoolCare'],
                        'onChangeValue',
                        'value'
                    )}
                    options={beforeOrAfterSchoolCareOptions}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.aboutStudent.medicalDetails.childCareComments'), [
                        'childInformation',
                        'childCareComments',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['childInformation', 'childCareComments'],
                        'onChangeText',
                        'value'
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.officeAdministration.studentNumber'), [
                        'studentNumber',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(['studentNumber'], 'onChangeText', 'value')}
                    isLocked={true}
                />
            </Grid>
        </FormHeader>
    );
};

export default OfficeAdministration;
