import * as React from 'react';
function SvgNewTab(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M3.072 19.203c-.63 0-1.167-.221-1.61-.665a2.193 2.193 0 01-.665-1.61V3.072c0-.63.221-1.166.665-1.61.443-.443.98-.665 1.61-.665h5.79c.32 0 .59.11.809.33.22.219.329.488.329.807 0 .32-.11.589-.33.809-.219.22-.488.329-.807.329H3.072v13.856h13.856v-5.79c0-.32.11-.589.33-.809.22-.22.489-.329.808-.329.319 0 .588.11.808.33.22.22.33.488.33.808v5.79c0 .63-.223 1.167-.666 1.61-.443.444-.98.665-1.61.665H3.072zM16.928 4.657l-8.253 8.254a1.062 1.062 0 01-.79.313 1.082 1.082 0 01-.79-.32 1.075 1.075 0 01-.316-.792c0-.317.105-.582.317-.793l8.247-8.247h-2.206c-.319 0-.588-.11-.808-.33-.22-.219-.329-.488-.329-.808 0-.319.11-.588.33-.808.219-.22.488-.33.807-.33h6.066v6.067c0 .319-.11.588-.33.808-.219.22-.488.33-.807.33-.32 0-.589-.11-.808-.33-.22-.22-.33-.49-.33-.808V4.657z'
                fill='#171923'
            />
        </svg>
    );
}
export default SvgNewTab;
