import { FormComponentProps } from '@Hooks';
import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IPhoneCallDetails } from '@Redux/services/Absence/types';
import type { PartialDeep } from 'type-fest';
import colors from '@Theme/foundations/colors';
import { TagItem } from '@Components/TagItem';
import { isNull, isUndefined } from 'lodash';
import { format } from 'date-fns';
import { DATE_FORMAT } from 'src/constants';

interface HeaderProps extends FormComponentProps {
    phoneCallDetails: PartialDeep<IPhoneCallDetails>;
    guardianHasMfrAccount: boolean;
}

const PhoneCallHeader = React.forwardRef<HTMLDivElement, HeaderProps>(
    ({ id, guardianHasMfrAccount, phoneCallDetails }) => {
        const { t } = useTranslation();

        return (
            <Box id={id}>
                <Flex gap={1} direction={'column'}>
                    <Flex direction={'row'} alignItems={'center'}>
                        <Text textStyle={'2xl-medium'} fontWeight='medium' mr={3}>
                            {phoneCallDetails?.regarding}
                        </Text>
                        {Boolean(phoneCallDetails?.studentNumber) && (
                            <Text textStyle={'lg-normal'}># {phoneCallDetails.studentNumber}</Text>
                        )}
                        {!isNull(guardianHasMfrAccount) &&
                            !isUndefined(guardianHasMfrAccount) &&
                            !guardianHasMfrAccount && (
                                <TagItem
                                    backgroundColor={colors.surface.errorInvert}
                                    color={colors.text.error}
                                    label={t('PhoneCall.noMfr')}
                                    tooltip={t('PhoneCall.noMfr.Tooltip')}
                                />
                            )}
                    </Flex>
                    <Text textStyle={'lg-semibold'} fontWeight='medium'>
                        {phoneCallDetails?.date && format(new Date(phoneCallDetails?.date), DATE_FORMAT)}
                    </Text>
                    {Boolean(phoneCallDetails?.contactName) && (
                        <Flex direction={'row'} alignItems={'flex-end'}>
                            <Text textStyle={'lg-semibold'} fontWeight='medium' mr={3}>
                                {t('PhoneCallDetails.primaryContact')}: {phoneCallDetails.contactName}
                            </Text>
                            <Text textStyle={'lg-normal'}>{phoneCallDetails?.contactPhoneNumber}</Text>
                        </Flex>
                    )}
                </Flex>
            </Box>
        );
    }
);

export default PhoneCallHeader;
