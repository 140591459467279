import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import React from 'react';
import TextInput from '@Components/TextInput';
import ReactIcon from '@Icon/ReactIcon';
import Header from '@Pages/EventDetails/components/Summary/components/Header';
import FormHeader from '@Components/FormHeader';
import { ParticipantStepProps } from '@Pages/EventParticipants/types';
import Textarea from '@Components/Textarea';
import { ManageTextProps } from '@Hooks';

export const Summary = React.forwardRef<HTMLDivElement, ParticipantStepProps>(
    ({ id, manageFieldPropFactory, textFieldPropFactory, textAreaPropFactory }, ref) => {
        const { t } = useTranslation();

        return (
            <Box id={id} ref={ref} w={'full'}>
                <Header mb={4} title={t('Information.summary')} icon={<ReactIcon />} />
                <FormHeader id={id} label={t('Information.info')}>
                    <Flex gap={4} direction={'column'}>
                        <TextInput
                            {...textFieldPropFactory(t('Information.name'), ['listName'])}
                            {...manageFieldPropFactory<ManageTextProps>(['listName'], 'onChangeText', 'value')}
                            // col={1}
                        />

                        <TextInput
                            {...textFieldPropFactory(t('Information.membersCount'), ['memberCount'])}
                            {...manageFieldPropFactory<ManageTextProps>(['memberCount'], 'onChangeText', 'value')}
                            type='number'
                        />

                        <Textarea
                            {...textAreaPropFactory(t('Information.description'), ['description'])}
                            {...manageFieldPropFactory<ManageTextProps>(['description'], 'onChangeText', 'value')}
                        />
                    </Flex>
                </FormHeader>
            </Box>
        );
    }
);
