import * as React from 'react';
function SvgSyncBoard(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 20 22' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M6.892 12.018l1.456-1.456a.916.916 0 00.284-.694 1.011 1.011 0 00-.993-1.012.938.938 0 00-.716.287l-2.074 2.073c-.228.23-.341.497-.341.803 0 .306.113.573.341.8l2.074 2.074c.2.2.437.296.712.287a1.02 1.02 0 00.713-.312c.183-.2.277-.434.281-.703a.93.93 0 00-.287-.697l-1.45-1.45zm6.216 0l-1.456 1.456a.916.916 0 00-.284.694 1.01 1.01 0 00.993 1.013.938.938 0 00.716-.288l2.073-2.074c.228-.229.342-.496.342-.802 0-.306-.114-.573-.342-.8l-2.073-2.074a.93.93 0 00-.712-.288 1.02 1.02 0 00-.713.313c-.183.2-.277.434-.281.703a.93.93 0 00.287.697l1.45 1.45zM3.072 21.203a2.19 2.19 0 01-1.607-.668 2.19 2.19 0 01-.668-1.607V5.072c0-.626.222-1.162.668-1.607a2.19 2.19 0 011.607-.668h4.002a3.151 3.151 0 011.16-1.456A3.05 3.05 0 0110 .797c.65 0 1.238.181 1.766.544.528.362.915.848 1.16 1.456h4.002a2.19 2.19 0 011.607.668c.445.445.668.981.668 1.607v13.856a2.19 2.19 0 01-.668 1.607 2.19 2.19 0 01-1.607.668H3.072zm0-2.275h13.856V5.072H3.072v13.856zM10 4.298a.775.775 0 00.798-.798.775.775 0 00-.798-.798.775.775 0 00-.798.798.775.775 0 00.798.798z'
                fill='#171923'
            />
        </svg>
    );
}
export default SvgSyncBoard;
