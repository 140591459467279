import { Box, Divider, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { GhostIcon } from '@Components/Button';
import { Search } from '@Components/Search';
import SelectInput from '@Components/SelectInput';
import { Export, Refresh } from '@Icon';
import { BasicLookup } from '@Redux/services/LookupApi/types';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface Filters {
    status: number;
    grade: number;
    homeRoomTeacher: string;
    searchText: string;
}

interface IFilterProps {
    onChange: (key: string) => (value: string | number) => void;
    handleRefresh: () => void;
    handleExport: () => void;
    filters: Filters;
    statusCodes: BasicLookup[];
    grades: BasicLookup[];
    teachers: BasicLookup[];
    isAnalytics: boolean;
    rowCount: number;
}

export const TableFilters: FC<IFilterProps> = ({
    filters,
    statusCodes,
    grades,
    teachers,
    isAnalytics,
    rowCount,
    handleRefresh,
    handleExport,
    onChange,
}) => {
    const [gridLabel, setGridLabel] = useState<string>('');
    const { t } = useTranslation();

    useEffect(() => {
        const setFilters = [];
        if (filters.status) setFilters.push(statusCodes.find((status) => status.key === filters.status)?.value);
        if (filters.grade)
            setFilters.push(
                `${t('Reports.Filters.grade')} ${grades.find((grade) => grade.key === filters.grade)?.value}`
            );
        if (filters.homeRoomTeacher) setFilters.push(filters.homeRoomTeacher);
        if (filters.searchText) setFilters.push(filters.searchText);
        setGridLabel(setFilters.join(', '));
    }, [filters]);

    return (
        <VStack align={'stretch'} spacing={4}>
            <Flex justify={'space-between'} px={3} pt={4}>
                <HStack>
                    <SelectInput
                        onChangeValue={onChange('status')}
                        value={filters.status}
                        size='sm'
                        options={statusCodes}
                        bg='surface-primary'
                        placeholder={isAnalytics ? null : t('Records.students.filters.statusPlaceHolder')}
                        defaultOption={false}
                    />
                    {isAnalytics || (
                        <>
                            <SelectInput
                                onChangeValue={onChange('grade')}
                                value={filters.grade}
                                size='sm'
                                options={grades}
                                bg='surface-primary'
                                placeholder={t('Records.students.filters.gradePlaceHolder')}
                                defaultOption={false}
                            />
                            <SelectInput
                                onChangeValue={onChange('homeRoomTeacher')}
                                value={filters.homeRoomTeacher}
                                size='sm'
                                options={teachers}
                                bg='surface-primary'
                                placeholder={t('Records.students.filters.teacherPlaceHolder')}
                                defaultOption={false}
                            />
                        </>
                    )}
                    <Box maxW={224} minW={224}>
                        <Search
                            iconPosition='left'
                            query={filters.searchText}
                            onChange={(e) => onChange('searchText')(e.target.value)}
                            placeholder={t('Records.students.filters.searchPlaceHolder')}
                        />
                    </Box>
                </HStack>
                <HStack pl={3}>
                    <GhostIcon
                        aria-label='refresh'
                        size={'sm'}
                        borderRadius='sm'
                        title={t('Reports.refreshTable')}
                        icon={<Refresh width={20} height={20} fill='text-primary' />}
                        onClick={handleRefresh}
                    />
                    {handleExport && (
                        <GhostIcon
                            aria-label='export'
                            size={'sm'}
                            borderRadius='sm'
                            title={t('Reports.exportTable')}
                            icon={<Export width={20} height={20} fill='text-primary' />}
                            onClick={handleExport}
                        />
                    )}
                </HStack>
            </Flex>
            {gridLabel && (
                <Text textStyle={'sm-normal'} px={3}>
                    {gridLabel}:{' '}
                    <Text as={'span'} textStyle={'sm-bold'}>
                        {rowCount}
                    </Text>
                </Text>
            )}
            <Divider />
        </VStack>
    );
};
