import { Box, Divider, Flex, Link, useDisclosure } from '@chakra-ui/react';
import { RangeDatepicker } from '@Components';
import { Alert } from '@Components/Alert';
import { CheckboxGroup } from '@Pages/LateAbsence/components/TableFilters/CheckboxGroup';
import DatePicker from '@Components/DateTimeInput';
import ListPopover from '@Components/ListPopover';
import { Select } from '@Components/Select';

import {
    useGetAttendanceStatusCodesQuery,
    useGetInvitedGradesQuery,
    useGetSchoolsQuery,
} from '@Redux/services/LookupApi';
import { format } from 'date-fns';
import { FC, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DATE_FORMAT_E_MMM_YY, MFRStatusCodeEnum } from 'src/constants';

export interface LateAbsenceFilters {
    ownerId?: string;
    statusReason?: number;
    grade?: string;
    dateRange: Date[];
    submittedDate: Date;
    isLate?: boolean;
    validToday?: boolean;
}

interface IProps {
    onChange: (key: string) => (value: string | number | Date[] | Date | null | boolean) => void;
    onResetField: (key: string, value: []) => void;
    onReset: () => void;
    filters: LateAbsenceFilters;
    showAlert: boolean;
}

export const TableFilters: FC<IProps> = ({ filters, showAlert, onChange, onReset, onResetField }) => {
    const { data: { data: grades = [] } = {} } = useGetInvitedGradesQuery();
    const { data: { data: schools = [] } = {} } = useGetSchoolsQuery({});
    const { data: { data: statusReasons = [] } = {} } = useGetAttendanceStatusCodesQuery();
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const { t } = useTranslation();

    const filterOptions = [
        { key: 'grades', value: t('LateAbsenceNotes.grades') },
        { key: 'schools', value: t('LateAbsenceNotes.schools') },
        { key: 'submittedDate', value: t('LateAbsenceNotes.submitted') },
    ];

    const [addMoreFilters, setAddFilters] = useState([]);

    const renderFilters = (filtersToShow: string[]) => {
        const filterList = [];
        filtersToShow.forEach((f) => {
            switch (true) {
                case f === 'grades':
                    filterList.push(
                        <Select
                            placeholder={t('LateAbsenceNotes.filters.grades')}
                            options={grades}
                            value={filters.grade}
                            bg='surface-primary'
                            size='sm'
                            onChange={handleChange('grade')}
                            maxW='180px'
                        />
                    );
                    break;
                case f === 'schools':
                    filterList.push(
                        <Select
                            placeholder={t('LateAbsenceNotes.filters.schools')}
                            options={schools}
                            value={filters.ownerId}
                            bg='surface-primary'
                            size='sm'
                            onChange={(event) => onChange('ownerId')(event.target.value)}
                            maxW='180px'
                        />
                    );
                    break;
                case f === 'submittedDate':
                    filterList.push(
                        <DatePicker
                            onDateChange={onChange('submittedDate')}
                            date={filters.submittedDate}
                            isLocked={false}
                            size='sm'
                            placeHolder={t('LateAbsenceNotes.filters.submitted')}
                        />
                    );
                    break;
            }
        });
        return filterList;
    };

    const handleChange = (key) => (event) => {
        onChange(key)(parseInt(event.target.value, 10));
    };

    const statusValue = useMemo(
        () => statusReasons?.find((status) => status.key == MFRStatusCodeEnum.New)?.value,
        [statusReasons, filters.statusReason]
    );

    return (
        <>
            {showAlert && (
                <>
                    <Alert
                        isOpen={isOpen}
                        onClose={onClose}
                        alertProps={{
                            status: 'warning',
                        }}
                        description={
                            <Trans
                                i18nKey={'LateAbsence.TableFilters.description'}
                                values={{
                                    startDate: format(new Date(), DATE_FORMAT_E_MMM_YY),
                                    endDate: format(new Date(), DATE_FORMAT_E_MMM_YY),
                                    status: statusValue,
                                }}
                                components={[
                                    <Link
                                        onClick={() => {
                                            onReset();
                                            onClose();
                                        }}
                                        color={'text-warning'}
                                    />,
                                ]}
                            />
                        }
                    />
                    <Divider />
                </>
            )}
            <Flex p={6} display={{ base: 'block', lg: 'flex' }} alignItems='center' gap='16px'>
                <Select
                    onChange={handleChange('statusReason')}
                    value={filters.statusReason?.toString()}
                    size='sm'
                    options={statusReasons}
                    bg='surface-primary'
                    placeholder={t('LateAbsenceNotes.filters.statusReason')}
                    maxW='180px'
                />
                <Box maxW={280}>
                    <RangeDatepicker
                        onDateChange={onChange('dateRange')}
                        isLocked={false}
                        size='sm'
                        selectedDates={filters.dateRange}
                        onResetClick={() => onResetField('dateRange', [])}
                        placeHolder={t('LateAbsenceNotes.filters.dateRange')}
                    />
                </Box>
                <Box maxW={280}>
                    <CheckboxGroup onChange={onChange('isLate')} value={filters.isLate} />
                </Box>
                {renderFilters(addMoreFilters)}
                <Box>
                    <ListPopover
                        aria-label={t('AddMembers.more')}
                        options={filterOptions.filter((o) => !(addMoreFilters.indexOf(o.key) > -1))}
                        onSelectOption={(value) => {
                            setAddFilters((filters) => [...filters, value]);
                        }}
                    />
                </Box>
            </Flex>
            <Divider />
        </>
    );
};
