import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Sidebar from '@Components/Sidebar';

interface IProps {
    activeItem: string;
}

const StudentsSidebar: FC<IProps> = function ({ activeItem }) {
    const { t } = useTranslation();
    const sideBarItems = {
        groups: [
            {
                items: [
                    {
                        label: t('Students.sideBar.students'),
                        id: '/records/students',
                        active: activeItem === 'student-listview',
                    },
                    {
                        label: t('Students.sideBar.powerSchoolAbsences'),
                        id: '/records/students/powerschool-absences',
                        active: activeItem === 'powerschool-absences',
                    },
                    {
                        label: t('Students.sideBar.studentActivity'),
                        id: '/records/students/activities',
                        active: activeItem === 'student-activities',
                    },
                    {
                        label: t('Students.sideBar.phoneCalls'),
                        id: '/records/students/phone-calls',
                        active: activeItem === 'phone-calls',
                    },
                ],
            },
        ],
    };
    return <Sidebar {...sideBarItems} />;
};

export default StudentsSidebar;
