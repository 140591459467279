import * as React from 'react';
function SvgPrint(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5 2a1 1 0 011-1h12a1 1 0 011 1v7a1 1 0 11-2 0V3H7v6a1 1 0 01-2 0V2z'
                fill='#2D3748'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4 10a1 1 0 00-1 1v5a1 1 0 001 1h2a1 1 0 110 2H4a3 3 0 01-3-3v-5a3 3 0 013-3h16a3 3 0 013 3v5a3 3 0 01-3 3h-2a1 1 0 110-2h2a1 1 0 001-1v-5a1 1 0 00-1-1H4z'
                fill='#2D3748'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5 14a1 1 0 011-1h12a1 1 0 011 1v8a1 1 0 01-1 1H6a1 1 0 01-1-1v-8zm2 1v6h10v-6H7z'
                fill='#171923'
            />
        </svg>
    );
}
export default SvgPrint;
