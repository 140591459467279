import { Flex } from '@chakra-ui/layout';
import { Spinner as ChakraSpinner } from '@chakra-ui/react';
const Spinner = () => {
    return (
        <Flex w='100%' justifyContent='center'>
            <ChakraSpinner size='lg' thickness='3px' color={'surface-brand-primary'} />
        </Flex>
    );
};

export default Spinner;
