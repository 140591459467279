import { FormComponentProps } from '@Hooks';
import { ILateAbsenceNoteDetails } from '@Redux/services/Absence/types';
import { Dispatch, SetStateAction } from 'react';

export interface LateAbsenceStepProps extends FormComponentProps {
    lateAbsenceDetails: ILateAbsenceNoteDetails;
    setLateAbsenceDetails: Dispatch<SetStateAction<ILateAbsenceNoteDetails>>;
}

export const editableFieldsForLateAbsence = {};
