import { Select as ChakraSelect, Box, SelectProps } from '@chakra-ui/react';
import TrashIconButton from '@Components/TrashIconButton';
import { BasicLookup } from '@Redux/services/LookupApi/types';
import { isNumber } from 'lodash';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface FilterSelectInputProps extends SelectProps {
    options?: BasicLookup[];
    isLocked?: boolean;
    onChangeValue?: (value: string | number) => void;
    defaultOption?: boolean;
    disabledOptions?: (string | number)[];
    containerWidth?: string | number;
}

const FilterSelectInput: FC<FilterSelectInputProps> = ({
    isLocked,
    options,
    onChangeValue,
    value,
    defaultOption = true,
    disabledOptions = [],
    containerWidth = 200,
    ...rest
}) => {
    const { t } = useTranslation();

    const isKeyNumeric = useMemo(() => options?.length > 0 && isNumber(options[0].key), [options]);
    return (
        <Box as='span' w={containerWidth} position='relative'>
            <ChakraSelect
                onChange={(event) =>
                    onChangeValue(isKeyNumeric ? parseInt(event.target.value, 10) : event.target.value)
                }
                disabled={isLocked || rest.isDisabled}
                _disabled={{
                    color: 'text-disabled',
                }}
                w={containerWidth}
                placeholder={''}
                value={value || ''}
                title={(rest.isRequired && t('InputFields.requiredTitle')) || ''}
                whiteSpace='nowrap'
                textOverflow='ellipsis'
                _focusVisible={{ borderColor: 'surface-brand-primary' }}
                {...rest}
            >
                {defaultOption && (
                    <option key={'defaultOption'} value='' disabled>
                        {t('SelectInput.defaultOption')}
                    </option>
                )}
                {options?.map((option) => (
                    <option value={option.key} key={option.key} disabled={disabledOptions.includes(option.key)}>
                        {option.value}
                    </option>
                ))}
            </ChakraSelect>
            {value && <TrashIconButton onClick={() => onChangeValue('')} right='35px' top='10px' />}
        </Box>
    );
};

export default FilterSelectInput;
