import { defineStyleConfig } from '@chakra-ui/styled-system';

const Alert = defineStyleConfig({
    baseStyle: {
        container: {
            borderRadius: '8px !important',
            alignItems: 'flex-start',
        },
        title: {
            textStyle: 'sm-semibold',
        },
        description: {
            textStyle: 'sm-normal',
        },
    },
    variants: {
        error: {
            bg: 'surface.error',
        },
        success: {
            bg: 'surface.success',
        },
        warning: {
            bg: 'surface.warning',
        },
        info: {
            bg: 'surface.info',
        },
    },
});

export default Alert;
