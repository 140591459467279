import { baseApi, POST, GET } from '../base';
import { BaseResponse } from '../commonTypes';
import { AnnotationParams } from './types';
import {
    RegistrationsParams,
    RegistrationInformation,
    RegistrationIdParam,
    RegistrationStatusDetails,
    NoteParams,
    RegistrationNoteParam,
    AnnotationIdParams,
    Registration,
    Note,
} from './types';
import { FileContent } from '@Utilities';
import type { PartialDeep } from 'type-fest';

const registrationApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getRegistrations: builder.mutation<BaseResponse<Registration[]>, RegistrationsParams>({
            query: (body) => ({
                body,
                method: POST,
                url: 'StudentRegistration/GetRegistrations',
            }),
        }),
        exportRegistrationRequests: builder.mutation<BaseResponse<FileContent>, RegistrationsParams>({
            query: (body) => ({
                method: POST,
                url: 'StudentRegistration/ExportRegistrations',
                body,
            }),
        }),
        getRegistrationDetails: builder.query<BaseResponse<RegistrationInformation>, RegistrationIdParam>({
            query: (data) => ({
                url: `StudentRegistration/GetRegistration?id=${data.id}`,
                method: GET,
            }),
        }),
        updateRegistrationStatus: builder.mutation<BaseResponse<string>, PartialDeep<RegistrationStatusDetails>>({
            query: (body) => ({
                body,
                method: POST,
                url: 'StudentRegistration/UpdateRegistrationStatus',
            }),
        }),
        updateRegistration: builder.mutation<BaseResponse<string>, PartialDeep<RegistrationInformation>>({
            query: (body) => ({
                body,
                method: POST,
                url: 'StudentRegistration/UpdateStudentRegistration',
            }),
        }),
        createRegistrationNote: builder.mutation<BaseResponse<Note[]>, RegistrationNoteParam>({
            query: (body) => ({
                body,
                method: POST,
                url: 'StudentRegistration/CreateRegistrationNote',
            }),
        }),
        updateRegistrationNote: builder.mutation<BaseResponse<Note[]>, AnnotationParams>({
            query: (body) => ({
                body,
                method: POST,
                url: 'StudentRegistration/UpdateStudentRegistrationNote',
            }),
        }),
        deleteRegistrationNote: builder.mutation<BaseResponse<Note[]>, AnnotationIdParams>({
            query: (body) => ({
                body,
                method: POST,
                url: 'StudentRegistration/DeleteStudentRegistrationNote',
            }),
        }),
        getRegistrationNotes: builder.mutation<BaseResponse<Note[]>, NoteParams>({
            query: (body) => ({
                body,
                method: POST,
                url: 'StudentRegistration/GetRegistrationNotes',
            }),
        }),
    }),
});

export const {
    useGetRegistrationsMutation,
    useExportRegistrationRequestsMutation,
    useLazyGetRegistrationDetailsQuery,
    useUpdateRegistrationMutation,
    useUpdateRegistrationStatusMutation,
    useCreateRegistrationNoteMutation,
    useGetRegistrationNotesMutation,
    useDeleteRegistrationNoteMutation,
    useUpdateRegistrationNoteMutation,
} = registrationApi;

export default registrationApi;
