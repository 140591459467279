import { Box, Flex, HStack, Tag, TagLabel, Text } from '@chakra-ui/react';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination } from '@Components';
import { GhostIcon } from '@Components/Button';
import { Refresh, Export, PriorityHigh } from '@Icon';
import { format, parseISO } from 'date-fns';
import { DATE_TIME_FORMAT, DATE_FORMAT, DEFAULT_PAGE_SIZE, phoneCallStatusCodeEnum } from 'src/constants';
import { AbsencesFilters, TableFilters } from '../TableFilters';
import { Search } from '@Components/Search';
import colors from '@Theme/foundations/colors';
import Table from '@Components/Table';
import { SortByProps } from '../../index';
import { BasicLookup } from '@Redux/services/LookupApi/types';
import { useDebounce } from '@Hooks';
import { useGetPhoneCallsMutation } from '@Redux/services/Absence';
import { PagedResult } from '@Redux/services/commonTypes';
import { Column } from '@Components/Table';
import { useGetPhoneCallStatusCodesQuery } from '@Redux/services/LookupApi';
import { PhoneCall } from '@Redux/services/Absence/types';
import { createLookupMap, parseError } from '@Utilities';
import { useToast } from '@Hooks/useToast';

interface IProps {
    grades: BasicLookup[];
    initialized: boolean;
    onRowClick: (phonecall: PhoneCall) => void;
    absenceReasonCodes: BasicLookup[];
}

export const PhoneCallTable: FC<IProps> = ({ grades, initialized, onRowClick, absenceReasonCodes }) => {
    const { data: { data: statusCodes = [] } = {} } = useGetPhoneCallStatusCodesQuery();

    const { t } = useTranslation();
    const toast = useToast();

    const defaultSortOrder = { id: 'date', desc: true };
    const defaultFilters = {
        grade: null,
        teamId: null,
        date: new Date(),
        statusCode: phoneCallStatusCodeEnum.Made,
        priorityCode: null,
    };
    const tableHeaders = [
        {
            Header: t('AbsencesList.status'),
            accessor: 'status',
            headerAlign: 'left',
        },
        {
            Header: t('AbsencesList.date'),
            accessor: 'date',
            sortType: 'datetime',
            headerAlign: 'left',
        },
        {
            Header: t('AbsencesList.periods'),
            accessor: 'periods',
            headerAlign: 'left',
        },
        {
            Header: t('AbsencesList.studentName'),
            accessor: 'studentName',
            headerAlign: 'left',
        },
        {
            Header: t('AbsencesList.contactName'),
            accessor: 'contactName',
            headerAlign: 'left',
        },
        {
            Header: t('AbsencesList.contactPhone'),
            accessor: 'contactPhoneNumber',
            headerAlign: 'right',
        },
        {
            Header: t('AbsencesList.absenceReason'),
            accessor: 'absenceReason',
            headerAlign: 'left',
        },
        {
            Header: t('AbsencesList.action'),
            accessor: 'action',
            headerAlign: 'left',
        },
        {
            Header: t('AbsencesList.grade'),
            accessor: 'grade',
            headerAlign: 'right',
        },
    ] as Column[];
    const getStatus = (status: phoneCallStatusCodeEnum) => {
        switch (status) {
            case phoneCallStatusCodeEnum.Open:
                return t('PhoneCall.status.pending');
            case phoneCallStatusCodeEnum.Made:
                return t('PhoneCall.status.completed');
            case phoneCallStatusCodeEnum.AutomaticallyCanceled:
                return t('PhoneCall.status.resolved');
            case phoneCallStatusCodeEnum.Canceled:
                return t('PhoneCall.status.canceled');
            case phoneCallStatusCodeEnum.Received:
                return t('PhoneCall.status.received');
        }
    };
    const statusOptions = statusCodes.map((s) => {
        return {
            key: s.key,
            value: getStatus(Number(s.key)),
            abbreviation: s?.abbreviation,
        } as BasicLookup;
    });

    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [sortBy, setSortBy] = useState<SortByProps>(defaultSortOrder);
    const [filters, setFilters] = useState<AbsencesFilters>(defaultFilters);
    const [lastRefreshed, setLastRefreshed] = useState<Date>();

    const [mutate, { isLoading, data: { data: phoneCallsRes = [], pagedResult = {} as PagedResult } = {} }] =
        useGetPhoneCallsMutation();

    const getPhoneCalls = (sortBy: SortByProps, currentPage: number, searchQuery: string, filters: AbsencesFilters) => {
        const obj = {
            sortBy: sortBy?.id,
            isDesc: sortBy?.desc,
            currentPage: currentPage,
            pageSize: pageSize,
            searchText: searchQuery,
            grade: filters.grade === '' ? null : filters.grade,
            teamId: filters.teamId === '' ? null : filters.teamId,
            statusCode: filters.statusCode?.toString() == '' ? null : filters.statusCode,
            priorityCode: filters.priorityCode === '' ? null : filters.priorityCode,
            date: filters.date,
        };

        const now = new Date();

        mutate(obj)
            .unwrap()
            .catch((e) => parseError(toast, e))
            .finally(() => {
                setLastRefreshed(now);
            });
    };

    useEffect(() => {
        debouncedResults();
    }, [searchQuery, filters]);

    const handleReset = () => {
        setCurrentPage(1);
        setFilters({ grade: null, teamId: null, date: null, statusCode: null, priorityCode: null });
    };

    useEffect(() => {
        if (!initialized) return;

        getPhoneCalls(sortBy, currentPage, searchQuery, filters);
    }, [currentPage, sortBy, pageSize]);

    const debouncedResults = useDebounce(() => {
        getPhoneCalls(sortBy, currentPage, searchQuery, filters);
    });

    const onSortChange = (sortBy) => {
        if (sortBy[0]) {
            setSortBy(sortBy[0]);
        }
    };

    // Resetting pagination and filtering when searching
    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const handlePageSizeChange = (newPageSize: number) => {
        setCurrentPage(1);
        setPageSize(newPageSize);
    };

    const handleFilterChange = (key: string) => (value) => {
        setCurrentPage(1);
        const newFilters = { ...filters, [key]: value };
        setFilters(newFilters);
    };

    const handleRefresh = () => {
        debouncedResults();
        setLastRefreshed(new Date());
    };

    const gradesMap = useMemo(() => createLookupMap<BasicLookup>(grades), [grades]);

    const phoneCalls = useMemo(
        () =>
            phoneCallsRes.map((r) => {
                return {
                    activityId: r.activityId,
                    status: getStatus(r.statusCode),
                    date: r.date ? format(parseISO(r.date), DATE_FORMAT) : '-',
                    periods: (
                        <Box>
                            {r.periods?.split(';').reduce((pills, periods) => {
                                if (periods) {
                                    return [
                                        ...pills,
                                        <Tag size={'sm'} borderRadius={'full'} m={1}>
                                            <TagLabel w={'max-content'}>{periods}</TagLabel>
                                        </Tag>,
                                    ];
                                }
                                return pills;
                            }, [])}
                        </Box>
                    ),
                    studentName: r.studentName,
                    contactName: (
                        <Flex>
                            {r.isHighPriority ? (
                                <Box pr={2}>
                                    <PriorityHigh height={20} width={20} fill={colors.surface.error} />
                                </Box>
                            ) : null}
                            <Box>
                                <Text
                                    fontWeight={r.isHighPriority ? 'semibold' : 'normal'}
                                    color={r.isHighPriority ? colors.text.error : colors.text.primary}
                                >
                                    {r.contactName}
                                </Text>
                            </Box>
                        </Flex>
                    ),
                    contactPhoneNumber: r.contactPhoneNumber,
                    absenceReason: r.absenceReason
                        ? absenceReasonCodes.find((option) => option.key == r.absenceReason).value
                        : '-',
                    action: r.action ?? '-',
                    grade: gradesMap[r.grade] ? gradesMap[r.grade].value : '-',
                };
            }),
        [phoneCallsRes]
    );

    return (
        <Flex
            borderRadius='20px 0 0 0'
            borderWidth='2px 2px 2px 2px'
            borderStyle='solid'
            borderColor='border-primary'
            w={'full'}
            align={'stretch'}
            direction={'column'}
        >
            <Box>
                <Flex justify={'space-between'} align={'center'} p={6} flexDirection={{ base: 'column', md: 'row' }}>
                    <Flex flexDirection={'column'}>
                        <Text textStyle={'md-bold'} pb={{ base: 2, md: 0 }}>
                            {t('AbsencesList.title.all')}
                        </Text>
                        {lastRefreshed && (
                            <Text textStyle={'sm-medium'} pb={{ base: 2, md: 0 }}>
                                {`${t('AbsencesList.lastUpdatedOn')} ${format(lastRefreshed, DATE_TIME_FORMAT)}`}
                            </Text>
                        )}
                    </Flex>
                    <HStack spacing={5}>
                        <Box maxW={224} minW={224}>
                            <Search iconPosition='left' query={searchQuery} onChange={handleSearch} />
                        </Box>
                        <GhostIcon
                            aria-label='refresh'
                            size={'sm'}
                            borderRadius='sm'
                            title={t('AbsencesList.refreshTable')}
                            icon={<Refresh width={20} height={20} fill='text-primary' />}
                            onClick={handleRefresh}
                        />

                        <GhostIcon
                            aria-label='export'
                            size={'sm'}
                            borderRadius='sm'
                            title={t('AbsencesList.exportTable')}
                            icon={<Export width={20} height={20} />}
                            onClick={() => {
                                console.log('dsa');
                            }}
                        />
                    </HStack>
                </Flex>
            </Box>
            <Flex position='relative' flex='auto'>
                <Table
                    manualPagination={true}
                    manualSortBy={true}
                    columns={tableHeaders}
                    rawData={isLoading ? [] : phoneCalls}
                    emptyMessage={t('AbsencesList.noData')}
                    isLoading={isLoading}
                    onEditHandler={(row) => {
                        onRowClick(row.original);
                    }}
                    onSortChange={onSortChange}
                    filter={
                        <TableFilters
                            onChange={handleFilterChange}
                            filters={filters}
                            onReset={handleReset}
                            lookups={{ grades: grades, statusCodes: statusOptions }}
                        />
                    }
                    initialSortBy={sortBy ? [sortBy] : []}
                    tableWidth={{ 'table-layout': 'auto', width: '100%' }}
                    containerStyle={{ borderLeftWidth: 0, borderRightWidth: 0, borderRadius: 0 }}
                    stickyHeader
                />
            </Flex>

            {!pagedResult?.pageCount ? null : (
                <Box p={2}>
                    <Pagination
                        currentPage={currentPage}
                        onPageChange={setCurrentPage}
                        totalPages={pagedResult?.pageCount}
                        totalResults={pagedResult?.rowCount}
                        onPageSizeChange={handlePageSizeChange}
                        pageSize={pageSize}
                    />
                </Box>
            )}
        </Flex>
    );
};
