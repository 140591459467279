import FormHeader from '@Components/FormHeader';
import React from 'react';
import InnerFormHeader from '../InnerFormHeader';
import { FormComponentProps } from '@Hooks';
import { useTranslation } from 'react-i18next';
import StudentDetails from './components/StudentDetails';
import AcademicDetails from './components/AcademicDetails';
import MedicalDetails from './components/MedicalDetails';
import SvgAboutStudent from '@Icon/AboutStudent';
import { AboutStudentProps } from '@Pages/RegistrationDetails/types';

const AboutStudent: React.FC<AboutStudentProps> = ({
    id,
    manageFieldPropFactory,
    selectFieldPropFactory,
    textFieldPropFactory,
    switchFieldPropFactory,
    onGradeChange,
}) => {
    const [t] = useTranslation();
    const componentProps = {
        textFieldPropFactory,
        selectFieldPropFactory,
        manageFieldPropFactory,
        switchFieldPropFactory,
    };

    return (
        <>
            <FormHeader
                header={
                    <InnerFormHeader
                        divider={true}
                        title={t('RegistrationDetails.aboutStudent')}
                        icon={<SvgAboutStudent />}
                    />
                }
                label={t('RegistrationDetails.aboutStudent.studentDetails')}
            >
                <StudentDetails id={'studentDetails'} {...componentProps} />
            </FormHeader>
            <FormHeader label={t('RegistrationDetails.aboutStudent.academicDetails')}>
                <AcademicDetails id={'academicDetails'} {...componentProps} onGradeChange={onGradeChange} />
            </FormHeader>
            <FormHeader label={t('RegistrationDetails.aboutStudent.medicalDetails')}>
                <MedicalDetails id={'medicalDetails'} {...componentProps} />
            </FormHeader>
        </>
    );
};

export default AboutStudent;
