import { Box } from '@chakra-ui/react';
import SelectInput from '@Components/SelectInput';
import { FormComponentProps, ManageTextProps } from '@Hooks';
import { Grid } from '@chakra-ui/react';
import React from 'react';
import TextInput from '@Components/TextInput';
import { useTranslation } from 'react-i18next';
import {
    useGetGradesRequestQuery,
    useGetRelationshipsRequestQuery,
    useGetSchoolsQuery,
} from '@Redux/services/LookupApi';
import { ParentPosition } from 'src/constants';

const LinkingRequest: React.FC<FormComponentProps> = ({
    manageFieldPropFactory,
    selectFieldPropFactory,
    textFieldPropFactory,
}) => {
    // Implement your component logic here
    const [t] = useTranslation();
    const { data: { data: grades = [] } = {} } = useGetGradesRequestQuery({ searchText: '' });
    const { data: { data: schools = [] } = {} } = useGetSchoolsQuery({ searchText: '' });
    const { data: { data: relationships = [] } = {} } = useGetRelationshipsRequestQuery({ searchText: '' });
    const positions = [
        { key: ParentPosition.Parent1, value: 'Parent1' },
        { key: ParentPosition.Parent2, value: 'Parent2' },
        { key: ParentPosition.Parent3, value: 'Parent3' },
        { key: ParentPosition.Parent4, value: 'Parent4' },
    ];

    return (
        <Grid
            column={{ md: 2, sm: 1 }}
            columnGap={10}
            gridAutoFlow='dense'
            templateColumns={{
                base: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
            }}
            rowGap={4}
            templateRows='auto'
            mb={6}
        >
            <Box gridColumn='1 / 2'>
                <TextInput
                    {...textFieldPropFactory(t('LinkingRequestDetails.firstName'), ['firstName'])}
                    {...manageFieldPropFactory<ManageTextProps>(['firstName'], 'onChangeText', 'value')}
                />
            </Box>
            <Box gridColumn='1 / 2'>
                <TextInput
                    {...textFieldPropFactory(t('LinkingRequestDetails.lastName'), ['lastName'])}
                    {...manageFieldPropFactory<ManageTextProps>(['lastName'], 'onChangeText', 'value')}
                />
            </Box>
            <Box gridColumn='1 / 2'>
                <SelectInput
                    {...selectFieldPropFactory(t('LinkingRequestDetails.grade'), ['grade'])}
                    {...manageFieldPropFactory<ManageTextProps>(['grade'], 'onChangeValue', 'value')}
                    options={grades}
                />
            </Box>

            <Box gridColumn='1 / 2'>
                <TextInput
                    isLocked={true}
                    {...textFieldPropFactory(t('LinkingRequestDetails.submittingGuardian'), ['submittingGuardianName'])}
                    {...manageFieldPropFactory<ManageTextProps>(['submittingGuardianName'], 'onChangeText', 'value')}
                />
            </Box>

            <Box gridColumn='1 / 2'>
                <SelectInput
                    {...selectFieldPropFactory(t('LinkingRequestDetails.school'), ['schoolId'])}
                    {...manageFieldPropFactory<ManageTextProps>(['schoolId'], 'onChangeValue', 'value')}
                    options={schools}
                />
            </Box>
            <Box gridColumn='1 / 2'>
                <TextInput
                    {...textFieldPropFactory(t('LinkingRequestDetails.studentNumber'), ['studentNumber'])}
                    {...manageFieldPropFactory<ManageTextProps>(['studentNumber'], 'onChangeText', 'value')}
                />
            </Box>
            <Box gridColumn='1 / 2'>
                <SelectInput
                    {...selectFieldPropFactory(t('LinkingRequestDetails.relationshipToStudent'), [
                        'relationshipToStudent',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(['relationshipToStudent'], 'onChangeValue', 'value')}
                    options={relationships}
                />
            </Box>
            <Box gridColumn='1 / 2'>
                <SelectInput
                    {...selectFieldPropFactory(t('LinkingRequestDetails.parentPosition'), ['parentPosition'])}
                    {...manageFieldPropFactory<ManageTextProps>(['parentPosition'], 'onChangeValue', 'value')}
                    options={positions}
                />
            </Box>
        </Grid>
    );
};

export default LinkingRequest;
