import { ButtonGroupProps, ButtonGroup, Button } from '@chakra-ui/react';
import React from 'react';

interface Option {
    label: string;
    value: string | number;
}

export const SelectButtonGroup: React.FC<{
    onChange: (value: string | number) => void;
    value: string | number;
    groupProps?: ButtonGroupProps;
    options: Option[];
}> = ({ onChange, value, options, groupProps }) => {
    return (
        <ButtonGroup
            isAttached={true}
            borderRadius='4px'
            borderWidth='2px'
            borderStyle='solid'
            borderColor='border-primary'
            {...groupProps}
        >
            {options.map((option) => {
                let buttonBorderStyle = {};
                if (options.indexOf(option) !== options.length - 1) {
                    buttonBorderStyle = {
                        borderRightWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'border-primary',
                    };
                }
                return (
                    <Button
                        bg='surface-primary'
                        isActive={value === option.value}
                        borderRadius={0}
                        {...buttonBorderStyle}
                        _active={{
                            bg: 'surface-tertiary',
                        }}
                        onClick={() => onChange(option.value)}
                    >
                        {option.label}
                    </Button>
                );
            })}
        </ButtonGroup>
    );
};
