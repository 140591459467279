import { FileContent } from '@Utilities';
import { baseApi, GET, PATCH, POST } from '../base';
import { BaseResponse } from '../commonTypes';
import {
    AbsenceParams,
    LateAbsenceNote,
    LateAbsenceNoteParams,
    PhoneCall,
    IPhoneCallDetails,
    PhoneCallDetailsParams,
    PowerSchoolAbsence,
    PowerSchoolAbsenceParams,
    StudentActivityRecord,
    StudentActivityRecordParams,
    LateAbsenceNoteDetailParams,
    ILateAbsenceNoteDetails,
    StudentActivityIdParam,
    StudentActivityDetails,
    PowerSchoolAbsenceIdParam,
    PowerSchoolAbsenceDetails,
    UpdatePhoneCallParams,
    AbsenceReasonIdParam,
    AbsneceReasonDetails,
    AbsenceReason,
    GetAllAbsenceReasonsParam,
    CreateAbsenceReasonParams,
    UpdateAbsenceReasonParams,
    DeleteAbsenceReasonParams,
    MarkCallCompleteParams,
    PhoneCallStats,
    AbsenceTaken,
    AttendanceTakenParams,
    CheckNewEntriesParams,
    NewRecordsCount,
    AttendanceTakenRecordIdParam,
    AttendanceTakenRecordDetails,
    UpdateAttendanceTakenRecordParams,
    ExportLateAndAbsenceNotesParam,
} from './types';

const absenceApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getPhoneCalls: builder.mutation<BaseResponse<PhoneCall[]>, AbsenceParams>({
            query: (body) => ({
                url: `Absence/GetPhoneCalls`,
                method: POST,
                body: body,
            }),
        }),
        getPendingPhoneCalls: builder.mutation<BaseResponse<PhoneCall[]>, void>({
            query: () => ({
                url: `Absence/GetPendingPhoneCalls`,
                method: POST,
                body: {},
            }),
        }),
        getPhoneCallStats: builder.query<BaseResponse<PhoneCallStats>, AbsenceParams>({
            query: () => ({
                url: `Absence/GetPhoneCallStats`,
                method: GET,
            }),
        }),
        getPhoneCallDetails: builder.query<BaseResponse<IPhoneCallDetails>, PhoneCallDetailsParams>({
            query: (data) => ({
                url: `Absence/GetPhoneCall/${data.phoneCallId}`,
                method: GET,
            }),
        }),
        getPowerSchoolAbsences: builder.mutation<BaseResponse<PowerSchoolAbsence[]>, PowerSchoolAbsenceParams>({
            query: (body) => ({
                url: `Absence/GetConfirmedAbsences`,
                method: POST,
                body: body,
            }),
        }),
        getLateAbsenceNotes: builder.mutation<BaseResponse<LateAbsenceNote[]>, LateAbsenceNoteParams>({
            query: (body) => ({
                url: `Absence/GetLateAndAbsenceNotes`,
                method: POST,
                body: body,
            }),
        }),
        getLateAbsenceNoteDetails: builder.query<BaseResponse<ILateAbsenceNoteDetails>, LateAbsenceNoteDetailParams>({
            query: (data) => ({
                url: `Absence/GetLateAndAbsenceNote/${data.attendanceId}`,
                method: GET,
            }),
        }),
        getStudentActivities: builder.mutation<BaseResponse<StudentActivityRecord[]>, StudentActivityRecordParams>({
            query: (body) => ({
                url: `Absence/GetStudentActivities`,
                method: POST,
                body: body,
            }),
        }),
        getStudentActivity: builder.query<BaseResponse<StudentActivityDetails>, StudentActivityIdParam>({
            query: (data) => ({
                url: `Absence/GetStudentActivity/${data.id}`,
                method: GET,
            }),
        }),
        getPowerSchoolAbsence: builder.query<BaseResponse<PowerSchoolAbsenceDetails>, PowerSchoolAbsenceIdParam>({
            query: (data) => ({
                url: `Absence/GetPowerSchoolAbsence/${data.id}`,
                method: GET,
            }),
        }),
        getAttendanceTakenRecord: builder.query<
            BaseResponse<AttendanceTakenRecordDetails>,
            AttendanceTakenRecordIdParam
        >({
            query: (data) => ({
                url: `Absence/GetAttendanceTakenRecords/${data.id}`,
                method: GET,
            }),
        }),
        updateAttendanceTakenRecord: builder.mutation<
            BaseResponse<AttendanceTakenRecordDetails>,
            UpdateAttendanceTakenRecordParams
        >({
            query: (data) => ({
                url: `Absence/UpdateAttendanceTakenRecord`,
                method: PATCH,
                body: data,
            }),
        }),
        getAllAbsenceReasons: builder.mutation<BaseResponse<AbsenceReason[]>, GetAllAbsenceReasonsParam>({
            query: (body) => ({
                url: `Absence/GetAllAbsenceReasons`,
                method: POST,
                body: body,
            }),
        }),
        getAbsenceReason: builder.query<BaseResponse<AbsneceReasonDetails>, AbsenceReasonIdParam>({
            query: (data) => ({
                url: `Absence/GetAbsenceReason/${data.id}`,
                method: GET,
            }),
        }),
        checkNewPendingCalls: builder.mutation<BaseResponse<NewRecordsCount>, CheckNewEntriesParams>({
            query: (data) => ({
                url: `Absence/CheckNewPendingCalls/`,
                method: POST,
                body: data,
            }),
        }),
        updatePhoneCall: builder.mutation<BaseResponse<IPhoneCallDetails>, UpdatePhoneCallParams>({
            query: (body) => ({
                url: `Absence/UpdatePhoneCall`,
                method: PATCH,
                body: body,
            }),
        }),
        addAbsenceReason: builder.mutation<BaseResponse<AbsneceReasonDetails>, CreateAbsenceReasonParams>({
            query: (data) => ({
                url: `Absence/AddAbsenceReason`,
                method: POST,
                body: data,
            }),
        }),
        updateAbsenceReason: builder.mutation<BaseResponse<AbsneceReasonDetails>, UpdateAbsenceReasonParams>({
            query: (data) => ({
                url: `Absence/UpdateAbsenceReason`,
                method: PATCH,
                body: data,
            }),
        }),
        deleteAbsenceReason: builder.mutation<BaseResponse<AbsneceReasonDetails>, DeleteAbsenceReasonParams>({
            query: (data) => ({
                url: `Absence/DeleteAbsenceReason`,
                method: POST,
                body: data,
            }),
        }),
        markCallAsCompleted: builder.mutation<BaseResponse<IPhoneCallDetails>, MarkCallCompleteParams>({
            query: (data) => ({
                url: `Absence/MarkCallCompleted`,
                method: POST,
                body: data,
            }),
        }),
        getAttendanceTakenRecords: builder.mutation<BaseResponse<AbsenceTaken[]>, AttendanceTakenParams>({
            query: (data) => ({
                url: `Absence/GetAttendanceTakenRecords`,
                method: POST,
                body: data,
            }),
        }),
        getNewConfirmedAbsenceRecordsAmount: builder.mutation<BaseResponse<NewRecordsCount>, CheckNewEntriesParams>({
            query: (data) => ({
                url: `Absence/GetNewConfirmedAbsenceRecordsAmount`,
                method: POST,
                body: data,
            }),
        }),
        getNewMFRRecordsAmount: builder.mutation<BaseResponse<NewRecordsCount>, CheckNewEntriesParams>({
            query: (data) => ({
                url: `Absence/GetNewMFRRecordsAmount`,
                method: POST,
                body: data,
            }),
        }),
        exportLateAndAbsenceNotes: builder.mutation<BaseResponse<FileContent>, ExportLateAndAbsenceNotesParam>({
            query: (body) => ({
                method: POST,
                url: 'Absence/ExportLateAndAbsenceNotes',
                body,
            }),
        }),
    }),
});

export const {
    useGetPhoneCallsMutation,
    useLazyGetPhoneCallDetailsQuery,
    useGetPowerSchoolAbsencesMutation,
    useGetLateAbsenceNotesMutation,
    useGetStudentActivitiesMutation,
    useGetPendingPhoneCallsMutation,
    useGetPhoneCallStatsQuery,
    useLazyGetPhoneCallStatsQuery,
    useGetPhoneCallDetailsQuery,
    useGetLateAbsenceNoteDetailsQuery,
    useGetStudentActivityQuery,
    useGetPowerSchoolAbsenceQuery,
    useGetAttendanceTakenRecordQuery,
    useCheckNewPendingCallsMutation,
    useGetAllAbsenceReasonsMutation,
    useGetAbsenceReasonQuery,
    useUpdatePhoneCallMutation,
    useAddAbsenceReasonMutation,
    useUpdateAbsenceReasonMutation,
    useDeleteAbsenceReasonMutation,
    useMarkCallAsCompletedMutation,
    useGetAttendanceTakenRecordsMutation,
    useUpdateAttendanceTakenRecordMutation,
    useGetNewConfirmedAbsenceRecordsAmountMutation,
    useGetNewMFRRecordsAmountMutation,
    useExportLateAndAbsenceNotesMutation,
} = absenceApi;

export default absenceApi;
