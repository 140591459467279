import * as React from 'react';
function SvgAlertWarning(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g clipPath='url(#alert-warning_svg__clip0_3002_68095)'>
                <mask
                    id='alert-warning_svg__a'
                    style={{
                        maskType: 'alpha',
                    }}
                    maskUnits='userSpaceOnUse'
                    x={0}
                    y={0}
                    width={24}
                    height={24}
                >
                    <path fill='#D9D9D9' d='M0 0h24v24H0z' />
                </mask>
                <g mask='url(#alert-warning_svg__a)'>
                    <path
                        d='M2.393 21.316a1.07 1.07 0 01-.572-.157 1.11 1.11 0 01-.398-.415 1.22 1.22 0 01-.155-.554c-.008-.2.043-.394.155-.583l9.185-15.893c.112-.19.258-.332.439-.426.18-.093.366-.14.556-.14.19 0 .375.047.556.14.18.094.326.236.438.426l9.184 15.893c.112.19.164.384.155.583-.008.2-.06.384-.155.554-.095.17-.228.308-.398.413-.17.106-.36.159-.572.159H2.393zm1.809-2.185h14.8l-7.4-12.75-7.4 12.75zm7.4-.935c.287 0 .531-.1.73-.3.2-.199.3-.442.3-.73 0-.287-.1-.53-.3-.727a1 1 0 00-.73-.297 1 1 0 00-.73.297c-.2.198-.3.44-.3.727 0 .288.1.531.3.73.2.2.443.3.73.3zm0-2.982c.284 0 .521-.096.713-.287a.968.968 0 00.287-.713V11.28a.968.968 0 00-.287-.713.968.968 0 00-.713-.287.968.968 0 00-.712.287.968.968 0 00-.288.713v2.934c0 .284.096.521.288.713.191.191.429.287.712.287z'
                        fill='#E8A530'
                    />
                </g>
            </g>
            <defs>
                <clipPath id='alert-warning_svg__clip0_3002_68095'>
                    <path fill='#fff' d='M0 0h24v24H0z' />
                </clipPath>
            </defs>
        </svg>
    );
}
export default SvgAlertWarning;
