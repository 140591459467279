import { TFunction } from 'react-i18next';

export const Validator = (t: TFunction) => {
    return [
        {
            accessorPath: ['name'],
            validator: Boolean,
            message: t('CreateEvent.nameRequired'),
        },
        {
            accessorPath: ['teamId'],
            validator: Boolean,
            message: t('CreateEvent.teamIdRequired'),
        },
        {
            accessorPath: ['organizedById'],
            validator: Boolean,
            message: t('CreateEvent.organizedByIdRequired'),
        },
        {
            accessorPath: ['activityTypeId'],
            validator: Boolean,
            message: t('CreateEvent.activityTypeIdRequired'),
        },
        {
            accessorPath: ['categoryId'],
            validator: Boolean,
            message: t('CreateEvent.categoryIdRequired'),
        },
        {
            accessorPath: ['subCategoryId'],
            validator: Boolean,
            message: t('CreateEvent.subCategoryIdRequired'),
        },
    ];
};
