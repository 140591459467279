import { Button, Flex, Text, useDisclosure, ButtonProps } from '@chakra-ui/react';

import Trash from '@Icon/Trash';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Cancel, Check } from '@Icon';
import { ButtonVariant } from '@Theme/components/Button';

interface IProps extends ButtonProps {
    variant?: ButtonVariant;
    onClick: () => Promise<void>;
    childern?: React.ReactNode;
    title?: string;
    isLoading?: boolean;
}

const DeleteButtonInline: FC<IProps> = ({ onClick, isLoading, children, title, variant = 'warning', ...rest }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { t } = useTranslation();

    const handleYesClick = async () => {
        await onClick();
        onClose();
    };
    return (
        <>
            {!isOpen ? (
                <Button onClick={onOpen} leftIcon={<Trash />} variant={variant}>
                    {children}
                </Button>
            ) : (
                <Flex alignItems='center'>
                    <Text mr={'8px'} textStyle='sm-medium'>
                        {title}
                    </Text>
                    <Button
                        isDisabled={isLoading}
                        variant='warning-secondary'
                        leftIcon={<Check width={20} height={20} />}
                        onClick={handleYesClick}
                        mr={'8px'}
                        {...rest}
                    >
                        {t('DeleteButtonInline.confirmationButtonYes')}
                    </Button>
                    <Button
                        isDisabled={isLoading}
                        variant={'tertiary'}
                        leftIcon={<Cancel width={20} height={20} />}
                        onClick={onClose}
                    >
                        {t('DeleteButtonInline.confirmationButtonCancel')}
                    </Button>
                </Flex>
            )}
        </>
    );
};

export default DeleteButtonInline;
