import { Box, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Header from '../Summary/components/Header';
import React, { useMemo, useState } from 'react';
import { ProposedAndValidated, StepProps } from '@Pages/EventDetails/types';
import Table, { Column } from '@Components/Table';

import ActionCell from './components/ActionCell';
import TableActions from '@Components/TableActions';
import CostItemForm from '@Components/CostItemForm';
import _, { cloneDeep, groupBy } from 'lodash';
import SvgCostItems from '@Icon/CostItems';
import { CostItemDetail } from '@Redux/services/Event/types';
import { Button } from '@Components';
import { DropResult } from 'react-beautiful-dnd';
import { useShuffleOrderCostItemMutation } from '@Redux/services/Event';
import RequiredCell from '@Components/RequiredCell';
import { useEffect } from 'react';
import { useGetEventResponseCostItemRequirednessTypeQuery } from '@Redux/services/LookupApi';
import { move, parseError, parseWarning } from '@Utilities';
import { useToast } from '@Hooks/useToast';
import { EventStatus } from 'src/constants';
import CurrencyCell from '@Components/CurrencyCell';

const CostItems = React.forwardRef<HTMLDivElement, StepProps>((props, ref) => {
    const { id, eventDetails, setEventDetails } = props;
    const [costItems, setCostItems] = useState<Partial<CostItemDetail>[]>([]);
    const { t } = useTranslation();
    const { data: { data: requirednessTypes = [] } = {} } = useGetEventResponseCostItemRequirednessTypeQuery({});
    const [shuffleCostItemOrder, { isLoading }] = useShuffleOrderCostItemMutation();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedCostItem, setSelectedCostItem] = useState<CostItemDetail>();
    const toast = useToast();
    const isEditable = useMemo(() => ProposedAndValidated.includes(eventDetails.status), [eventDetails]);

    const columns = useMemo<Column[]>(() => {
        const columns: Column[] = [
            {
                Header: t('CostItems.title'),
                accessor: 'title',
                disableSortBy: true,
                headerAlign: 'left',
            },
            {
                Header: t('CostItems.required'),
                accessor: 'required',
                Cell: RequiredCell,
                disableSortBy: true,
                headerAlign: 'left',
            },
            {
                Header: t('CostItems.unitCost'),
                accessor: 'unitCost',
                Cell: CurrencyCell,
                headerAlign: 'right',
                disableSortBy: true,
            },
            {
                Header: t('CostItems.maxQuantityAllowed'),
                accessor: 'maxQuantityAllowed',
                disableSortBy: true,
                headerAlign: 'right',
            },
            {
                Header: <>&nbsp;</>,
                accessor: 'actions',
                disableSortBy: true,
                headerAlign: 'center',
                Cell: ActionCell,
            },
        ];
        return columns;
    }, []);

    useEffect(() => {
        if (!eventDetails?.costItems?.costItemsInfo && !requirednessTypes) return;
        const costItemsGroupedByRequiredness = groupBy(cloneDeep(eventDetails.costItems.costItemsInfo), 'required');
        const newCostItems = [];
        Object.keys(costItemsGroupedByRequiredness).map((key, groupIndex) => {
            newCostItems.push(
                ...costItemsGroupedByRequiredness[key].map((item, index) => {
                    item.displayOrder = parseFloat(`${groupIndex + 1}.${index + 1}`);
                    return item;
                })
            );
        });
        setCostItems(newCostItems);
    }, [eventDetails, requirednessTypes]);

    const handleClose = () => {
        setSelectedCostItem(undefined);
        onClose();
    };

    const handleSubmit = (costItems?: CostItemDetail[]) => {
        if (!costItems) return;
        setEventDetails({
            ...eventDetails,
            costItems: {
                costItemsInfo: costItems,
            },
        });
    };

    const handleDragEnd = (result: DropResult) => {
        if (costItems.length <= 1 || result.source.index === result.destination.index) return;
        const costItemsInfo = _.cloneDeep(costItems);
        if (result?.source?.index?.toString() && result?.destination?.index?.toString()) {
            move(costItemsInfo, result?.source?.index, result?.destination?.index);
            const costItemsGroupedByRequiredness = groupBy(cloneDeep(costItemsInfo), 'required');
            const newCostItems = [];
            Object.keys(costItemsGroupedByRequiredness).map((key, groupIndex) => {
                newCostItems.push(
                    ...costItemsGroupedByRequiredness[key].map((item, index) => {
                        item.displayOrder = parseFloat(`${groupIndex + 1}.${index + 1}`);
                        return item;
                    })
                );
            });

            shuffleCostItemOrder({
                orderList: newCostItems.map((item, i) => ({
                    costItemId: item.costItemId,
                    displayOrder: i + 1,
                })),
            })
                .unwrap()
                .then((response) => {
                    setEventDetails({
                        ...eventDetails,
                        costItems: { costItemsInfo },
                    });
                    if (response?.warningResult?.showAlert) {
                        parseWarning(toast, response);
                    } else {
                        toast({
                            title: t('CostItems.toastShuffelSuccessTitle'),
                            description: t('CostItems.toastShuffelSuccessDescription'),
                            status: 'success',
                        });
                    }
                })
                .catch((error) => {
                    parseError(toast, error);
                });
        }
    };

    return (
        <Box id={id} ref={ref}>
            <Header divider={true} mb={4} title={t('Header.costItems')} icon={<SvgCostItems />} />

            <Table
                isLoading={isLoading}
                action={<TableActions onAddClick={isEditable && onOpen} />}
                manualSortBy={false}
                onDragEnd={handleDragEnd}
                isDragDisabled={![EventStatus.Proposed, EventStatus.Validated].includes(eventDetails?.status)}
                emptyMessage={t('CostItems.emptyTable')}
                rawData={costItems}
                columns={columns}
                userProps={{
                    onEdit: (costItem: CostItemDetail) => {
                        setSelectedCostItem(costItem);
                        onOpen();
                    },
                    onSubmit: handleSubmit,
                    isEditable: isEditable,
                }}
                addRowButton={isEditable && <Button onClick={onOpen}>{t('CostItem.label')}</Button>}
            />
            {isOpen && (
                <CostItemForm
                    isEditable={isEditable}
                    costItemId={selectedCostItem?.costItemId}
                    isOpen={isOpen}
                    onClose={handleClose}
                    onCancel={handleClose}
                    onSubmit={(closeModal: boolean, costItems?: CostItemDetail[]) => {
                        handleSubmit(costItems);
                        closeModal && handleClose();
                    }}
                    eventStatus={eventDetails?.status}
                    eventId={eventDetails?.eventId}
                />
            )}
        </Box>
    );
});

export default CostItems;
