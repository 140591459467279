import { TabPanel, TabPanels } from '@chakra-ui/react';
import FormHeader from '@Components/FormHeader';
import Tabs from '@Components/Tabs';
import { NotesProps } from '@Pages/EventDetails/types';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ActivitiesTab from './components/ActivitiesTab';
import NotesTab from './components/NotesTab';

const Notes: FC<NotesProps> = (props) => {
    const {
        id,
        notes,
        activities,
        isNotesLoading,
        hideActivities,
        onAddNote,
        onNextPage,
        notePagination,
        onDeleteNote,
        hidePagination,
        onUpdateNote,
        isNoteEditable,
        hideFormHeader,
        hideNoteAction,
        defaultPageSize,
    } = props;

    const { t } = useTranslation();

    const tabs = useMemo(
        () => (hideActivities ? [t('Notes.notesLabel')] : [t('Notes.notesLabel'), t('Notes.activityLabel')]),
        [hideActivities]
    );

    return (
        <FormHeader id={id} label={hideFormHeader ? '' : t('Notes.label')}>
            <Tabs variant='enclosed' tabs={tabs}>
                <TabPanels key={2}>
                    <TabPanel px={0}>
                        <NotesTab
                            notes={notes}
                            onAddNote={onAddNote}
                            onNextPage={onNextPage}
                            onDeleteNote={onDeleteNote}
                            onUpdateNote={onUpdateNote}
                            hidePagination={hidePagination}
                            pagedResult={notePagination}
                            isNoteEditable={isNoteEditable}
                            isNotesLoading={isNotesLoading}
                            defaultPageSize={defaultPageSize}
                            hideNoteAction={hideNoteAction}
                        />
                    </TabPanel>
                    {!hideActivities && (
                        <TabPanel>
                            <ActivitiesTab activities={activities} />
                        </TabPanel>
                    )}
                </TabPanels>
            </Tabs>
        </FormHeader>
    );
};

export default Notes;
