import * as React from 'react';
function SvgSignature(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M11.779 9.552c.977-.757 1.737-1.564 2.278-2.422.541-.858.812-1.704.812-2.54 0-.418-.072-.731-.214-.94a.663.663 0 00-.574-.312c-.616 0-1.166.527-1.65 1.582-.483 1.054-.724 2.24-.724 3.555 0 .198.005.386.015.565.01.178.03.349.057.512zm-9.438 8.117v-1.846h1.846v1.846H2.34zm3.373 0v-1.846H7.56v1.846H5.714zm3.368 0v-1.846h1.846v1.846H9.082zm3.373 0v-1.846h1.846v1.846h-1.846zm3.368 0v-1.846h1.846v1.846h-1.846zM2.79 14.271L1.497 12.98l1.334-1.333-1.334-1.334 1.292-1.29 1.333 1.332 1.333-1.333 1.291 1.291-1.333 1.334 1.333 1.333-1.29 1.291-1.334-1.333-1.333 1.333zm10.216-.748c-.434 0-.834-.09-1.201-.27a2.584 2.584 0 01-.96-.841c-.341.201-.694.385-1.059.55-.364.167-.74.331-1.125.494l-.733-1.682c.392-.162.767-.33 1.125-.505.357-.175.704-.36 1.04-.553a8.401 8.401 0 01-.188-1.087c-.043-.39-.064-.806-.064-1.25 0-1.986.403-3.63 1.21-4.933.806-1.303 1.817-1.954 3.031-1.954.769 0 1.396.282 1.883.845.487.563.73 1.333.73 2.311 0 1.172-.373 2.34-1.118 3.505-.745 1.165-1.787 2.232-3.124 3.203.107.11.218.195.332.254a.763.763 0 00.353.087c.364 0 .776-.225 1.235-.677.46-.452.877-1.058 1.253-1.818l1.59.749c-.09.246-.163.516-.216.811a2.231 2.231 0 00-.007.828 4.513 4.513 0 00.968-.97l1.452.932c-.378.61-.807 1.09-1.288 1.443-.48.352-.938.528-1.374.528-.299 0-.57-.085-.812-.253-.242-.169-.441-.422-.597-.76-.383.33-.77.582-1.16.754a2.897 2.897 0 01-1.176.259z'
                fill='#319795'
            />
        </svg>
    );
}
export default SvgSignature;
