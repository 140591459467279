import { useGetCostItemRequirednessTypeQuery } from '@Redux/services/LookupApi';
import { createLookupMap } from '@Utilities';
import React, { useMemo } from 'react';

interface IProps {
    value: number;
}

const RequiredCell: React.FC<IProps> = ({ value }) => {
    const { data: { data: requiredOptions = [] } = {} } = useGetCostItemRequirednessTypeQuery({ searchText: '' });
    const requiredOptionsMap = useMemo(() => createLookupMap(requiredOptions), []);
    return <>{value ? requiredOptionsMap[value]?.value : '--'}</>;
};

export default RequiredCell;
