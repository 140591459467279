import { LinkRequestInformation } from '@Redux/services/Update/types';
import { ValidatorType } from '@Utilities';
import { TFunction } from 'react-i18next';
import type { PartialDeep } from 'type-fest';

export function Validator(linkRequest: PartialDeep<LinkRequestInformation>, t: TFunction): ValidatorType[] {
    return [
        {
            accessorPath: ['firstName'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
        },
        {
            accessorPath: ['lastName'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
        },
        {
            accessorPath: ['schoolId'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
        },
        {
            accessorPath: ['relationshipToStudent'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
        },
    ];
}
