import Table from '@Components/Table';
import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import TableActions from '@Components/TableActions';
import { EventResponseDetail } from '@Redux/services/EventResponse/types';
import ActionCell from './components/ActionCell';
import { Box } from '@chakra-ui/react';
import { QuestionResponseForm } from './components/QuestionResponseForm';
import { Answer } from '@Redux/services/Event/types';
import QuestionForm from '@Components/QuestionForm';

interface IProps {
    id: string;
    isDrawer: boolean;
    eventResponse: EventResponseDetail;
    setEventResponse: Dispatch<SetStateAction<EventResponseDetail>>;
    isEditable: boolean;
    questionResponseLabel?: string | React.ReactNode;
    questionLabel?: string | React.ReactNode;
    isOpenQuestionForm: boolean;
    onCloseQuestionForm: () => void;
    onOpenQuestionForm: () => void;
    isOpenQuestionResponseForm: boolean;
    onCloseQuestionResponseForm: () => void;
    onOpenQuestionResponseForm: () => void;
    onCloseAllResponseDrawers: () => void;
}

const QuestionsTable: FC<IProps> = ({
    id,
    isDrawer,
    isOpenQuestionForm,
    onOpenQuestionForm,
    onCloseQuestionForm,
    eventResponse,
    isEditable,
    setEventResponse,
    questionResponseLabel,
    questionLabel,
    isOpenQuestionResponseForm,
    onCloseQuestionResponseForm,
    onOpenQuestionResponseForm,
    onCloseAllResponseDrawers,
}) => {
    const { t } = useTranslation();
    const [selectedAnswer, setSelectedAnswer] = useState<Answer>();
    const columns = useMemo<Column[]>(
        () => [
            {
                Header: t('EventResponseDetail.Questions.eventQuestion'),
                accessor: 'name',
            },
            {
                Header: t('EventResponseDetail.Questions.text'),
                accessor: 'text',
            },
            {
                Header: t('EventResponseDetail.Questions.answer'),
                accessor: 'answer',
                Cell: (row) => {
                    switch (row.value) {
                        case true:
                            return <>Yes</>;
                        case false:
                            return <>No</>;
                        default:
                            return null;
                    }
                },
            },
            {
                Header: t('EventResponseDetail.Questions.response'),
                accessor: 'comment',
            },
            {
                Header: <>&nbsp;</>,
                accessor: 'actions',
                disableSortBy: true,
                headerAlign: 'center',
                Cell: ActionCell,
            },
        ],
        []
    );

    const handleUpdate = (answers: Answer[]) => {
        setEventResponse({
            ...eventResponse,
            eventAnswers: {
                ...eventResponse.eventAnswers,
                answers: answers,
            },
        });
    };

    return (
        <Box id={id}>
            <Table
                action={<TableActions label={t('EventResponseDetail.Questions.header')} />}
                manualSortBy={false}
                columns={columns}
                rawData={eventResponse.eventAnswers.answers ?? []}
                emptyMessage={t('EventResponseDetail.Questions.emptyTable')}
                userProps={{
                    onEdit: (row) => {
                        setSelectedAnswer(row.original);
                        onOpenQuestionResponseForm();
                    },
                    isEditable: true,
                }}
                onEditHandler={(row) => {
                    setSelectedAnswer(row.original);
                    onOpenQuestionResponseForm();
                }}
            />
            {isOpenQuestionResponseForm && (
                <QuestionResponseForm
                    size={isDrawer ? 'xl' : 'lg'}
                    label={questionResponseLabel}
                    onUpdate={handleUpdate}
                    answer={selectedAnswer}
                    isOpen={isOpenQuestionResponseForm}
                    onQuestionClick={onOpenQuestionForm}
                    questions={eventResponse?.eventQuestions?.questions}
                    onClose={onCloseAllResponseDrawers}
                    onCancel={onCloseQuestionResponseForm}
                    isEditable={isEditable}
                />
            )}
            {isOpenQuestionForm && (
                <QuestionForm
                    size={isDrawer ? 'xl' : 'lg'}
                    label={questionLabel}
                    questionId={selectedAnswer?.eventQuestionId}
                    isOpen={isOpenQuestionForm}
                    onClose={onCloseAllResponseDrawers}
                    onCancel={onCloseQuestionForm}
                    eventId={eventResponse?.event.eventId}
                    isEditable={false}
                />
            )}
        </Box>
    );
};

export default QuestionsTable;
