import { FC, useMemo, useState } from 'react';
import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Avatar,
    Box,
    Button,
    Divider,
    Flex,
    Skeleton,
    Stack,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import ListItem from '@Components/ListItem';
import ListHeader from '@Components/ListHeader';
import { Pagination } from '@Components';
import { format } from 'date-fns';
import { Pencil, Plus } from '@Icon';
import { DeleteButtonInline } from '@Components/DeleteButton';
import { Trans, useTranslation } from 'react-i18next';
import Textarea from '@Components/Textarea';
import { DATE_TIME_FORMAT, DEFAULT_PAGE_SIZE } from 'src/constants';
import { PagedResult, Note } from '@Redux/services/commonTypes';
import { times } from 'lodash';
import { ExpandableText } from '@Components/ExpandableText';
import NotesText from '../NotesText';

interface IProps {
    notes: Note[] | string[];
    notePaginatin?: PagedResult;
    onAddNote?: (noteText: string) => Promise<void>;
    onDeleteNote?: (annotationId: string) => Promise<void>;
    onUpdateNote?: (noteText: string, annotationId: string) => Promise<void>;
    onNextPage?: (currentPage: number, pageSize: number) => void;
    pagedResult?: PagedResult;
    isNoteEditable?: boolean;
    isNotesLoading?: boolean;
    hidePagination?: boolean;
    defaultPageSize?: number;
    hideNoteAction?: boolean;
}

const NotesTab: FC<IProps> = ({
    notes,
    isNoteEditable,
    pagedResult,
    hidePagination = false,
    isNotesLoading,
    onNextPage,
    onAddNote,
    onDeleteNote,
    onUpdateNote,
    defaultPageSize,
    hideNoteAction = false,
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(defaultPageSize ?? DEFAULT_PAGE_SIZE);
    const { isOpen: isAddingNote, onOpen, onClose } = useDisclosure();
    const { isOpen: isUpdating, onOpen: setUpdating, onClose: unsetUpdating } = useDisclosure();
    const { isOpen: isDeleting, onOpen: setDeleting, onClose: unsetDeleting } = useDisclosure();
    const defaultNote = { annotationId: null, noteText: '' };
    const [note, setNote] = useState({ ...defaultNote });
    const [expandedIndex, setExpandedIndex] = useState(null);

    const { t } = useTranslation();

    const handleNoteSaving = async (note) => {
        setUpdating();
        if (note?.annotationId) {
            await onUpdateNote(note?.noteText, note?.annotationId)
                .then(() => {
                    setNote({ ...defaultNote });
                    onClose();
                })
                .finally(() => unsetUpdating());
        } else {
            await onAddNote(note?.noteText)
                .then(() => {
                    setNote({ ...defaultNote });
                    onClose();
                })
                .finally(() => unsetUpdating());
        }
    };

    const handleDelete = async (newNote: Note) => {
        setDeleting();
        await onDeleteNote(newNote?.annotationId)
            .then(() => onClose())
            .finally(() => {
                unsetDeleting();
            });
    };

    const paginationProps = useMemo(() => {
        if (!pagedResult) {
            const newPageSize = notes?.length > pageSize ? notes?.length / 2 : 1;
            return {
                currentPage: currentPage,
                totalPages: newPageSize,
                totalResults: notes?.length,
                resultsPerPage: newPageSize,
            };
        } else {
            return {
                currentPage: pagedResult.currentPage,
                totalPages: pagedResult.pageCount,
                totalResults: pagedResult.rowCount,
                resultsPerPage: pagedResult.pageSize,
            };
        }
    }, [pagedResult]);

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        onNextPage(pageNumber, pageSize);
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPageSize(pageSize);
        setCurrentPage(1);
        onNextPage(1, pageSize);
    };

    if (isNotesLoading) {
        return (
            <Stack padding={4} spacing={1}>
                {times(pageSize).map(() => (
                    <Skeleton height='70px' paddingBottom={'8px'} color='white' fadeDuration={1} />
                ))}
            </Stack>
        );
    }
    return (
        <>
            <Box borderRadius={{ base: '0', md: 'lg' }} borderWidth='1px' bg='surface-primary'>
                {!isAddingNote && isNoteEditable && (
                    <Box
                        bg='surface-primary'
                        position='relative'
                        py={5}
                        border='1px solid var(--chakra-colors-gray-200)'
                        onClick={onOpen}
                        borderRadius={{ base: '0', md: 'lg' }}
                        borderBottomLeftRadius={{ base: '0', md: '0' }}
                        borderEndEndRadius={{ base: '0', md: '0' }}
                    >
                        <Divider />
                        <Flex width={'100%'} position={'absolute'} bottom='10px' justifyContent='center'>
                            <Button variant='light' gap={2} px={4} py={0} pl={3} h={'--var(chakra-sizes-9)'}>
                                <Plus width={20} height={20} /> {t('Notes.addNote')}
                            </Button>
                        </Flex>
                    </Box>
                )}
                {isAddingNote && isNoteEditable && (
                    <Box p={4}>
                        <Textarea
                            isLocked={false}
                            label=''
                            placeholder={t('Notes.addNotePlaceholder')}
                            onChangeText={(value) => {
                                setNote((note) => ({ ...note, noteText: value }));
                            }}
                            value={note?.noteText}
                        />
                        <Flex justify={'end'} align={'center'} gap={2} pt={4}>
                            <Button
                                variant='light'
                                isDisabled={isUpdating}
                                onClick={() => {
                                    onClose();
                                    setNote({ ...defaultNote });
                                }}
                            >
                                {t('Notes.cancel')}
                            </Button>
                            <Button
                                isDisabled={!note?.noteText?.trim() || isUpdating}
                                variant='brand'
                                onClick={async () => await handleNoteSaving(note)}
                            >
                                {t('Notes.save')}
                            </Button>
                        </Flex>
                    </Box>
                )}
                {notes?.length > 0 ? (
                    <Accordion allowToggle allowMultiple={false} index={expandedIndex}>
                        {notes?.map((note, index) => (
                            <AccordionItem id={index.toString()} key={index.toString()}>
                                {({ isExpanded }) => (
                                    <ListItem
                                        key={index}
                                        header={
                                            <AccordionButton
                                                textAlign={'left'}
                                                m={0}
                                                p={0}
                                                _hover={{
                                                    bg: 'transparent',
                                                }}
                                                _active={{
                                                    border: 'none',
                                                }}
                                                _focus={{
                                                    border: 'none',
                                                }}
                                                onClick={() => setExpandedIndex(isExpanded ? null : index)}
                                            >
                                                <ListHeader
                                                    subtitle={note?.modifiedByEmailAddress}
                                                    title={note?.modifiedByFullName}
                                                    avatar={
                                                        <Avatar name={note?.modifiedByFullName} src={''} size='md' />
                                                    }
                                                    contentNode={
                                                        !isExpanded && (
                                                            <NotesText
                                                                noteText={note?.noteText}
                                                                isExpanded={isExpanded}
                                                                toggleExpand={() => setExpandedIndex(index)}
                                                            />
                                                        )
                                                    }
                                                    rightLabel={
                                                        note?.modifiedOn &&
                                                        format(new Date(note.modifiedOn), DATE_TIME_FORMAT)
                                                    }
                                                />
                                            </AccordionButton>
                                        }
                                        description={
                                            <AccordionPanel pb={2} pl={0}>
                                                <Box pt={2} width={'100%'}>
                                                    <NotesText
                                                        noteText={
                                                            note?.noteText || (typeof note === 'string' ? note : '')
                                                        }
                                                        isExpanded={isExpanded}
                                                        toggleExpand={() => setExpandedIndex(null)}
                                                    />
                                                </Box>
                                                {isNoteEditable && !hideNoteAction && (
                                                    <Flex gap={2} mt={4} ml={2}>
                                                        {note?.canEdit !== false && (
                                                            <Button
                                                                variant='light'
                                                                gap={2}
                                                                px={4}
                                                                py={0}
                                                                pl={3}
                                                                onClick={() => {
                                                                    setNote(note);
                                                                    onOpen();
                                                                }}
                                                            >
                                                                <Pencil width={20} height={20} /> {t('Notes.edit')}
                                                            </Button>
                                                        )}
                                                        {note?.canDelete !== false && (
                                                            <DeleteButtonInline
                                                                isLoading={isDeleting}
                                                                variant='light'
                                                                onClick={() => handleDelete(note)}
                                                                title={t('Notes.confirmationMessage')}
                                                            >
                                                                {t('Notes.delete')}
                                                            </DeleteButtonInline>
                                                        )}
                                                    </Flex>
                                                )}
                                            </AccordionPanel>
                                        }
                                    />
                                )}
                            </AccordionItem>
                        ))}
                        {notes?.length > 0 && !hidePagination && (
                            <Pagination
                                onPageChange={handlePageChange}
                                viewMode={'TextButton'}
                                pageSize={pageSize}
                                onPageSizeChange={handlePageSizeChange}
                                py={0}
                                {...paginationProps}
                            />
                        )}
                    </Accordion>
                ) : (
                    <Box p={2}>
                        <Trans i18nKey='NotesTab.emptyList' />
                    </Box>
                )}
            </Box>
        </>
    );
};

export default NotesTab;
