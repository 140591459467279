import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import React from 'react';
import TextInput from '@Components/TextInput';
import ReactIcon from '@Icon/ReactIcon';
import Header from '@Pages/EventDetails/components/Summary/components/Header';
import TypeaheadInput from '@Components/TypeaheadInput';
import { StudentStepProps } from '@Pages/StudentDetails/types';
import FormHeader from '@Components/FormHeader';
import { ManageTextProps } from '@Hooks';

const ContactInfo: React.FC<StudentStepProps> = ({
    id,
    manageFieldPropFactory,
    textFieldPropFactory,
    typeaheadPropFactory,
}) => {
    const { t } = useTranslation();

    return (
        <Box id={id} w={'full'}>
            <Header divider={true} mb={4} title={t('ContactInfo.details')} icon={<ReactIcon />} />
            <FormHeader id={id} label={t('')}>
                <Flex gap={4} direction={'column'}>
                    <Grid
                        columnGap={10}
                        gridAutoFlow='dense'
                        templateColumns={{
                            base: 'repeat(1, 1fr)',
                            md: 'repeat(2, 1fr)',
                        }}
                        rowGap={4}
                        w={'full'}
                    >
                        <GridItem>
                            <TextInput
                                {...textFieldPropFactory(t('ContactInfo.studentId'), ['studentId'])}
                                {...manageFieldPropFactory<ManageTextProps>(['studentId'], 'onChangeText', 'value')}
                                stacked
                            />
                        </GridItem>

                        <GridItem>
                            <TextInput
                                {...textFieldPropFactory(t('ContactInfo.name'), ['fullName'])}
                                {...manageFieldPropFactory<ManageTextProps>(['fullName'], 'onChangeText', 'value')}
                                stacked
                            />
                        </GridItem>
                    </Grid>

                    <Grid
                        columnGap={10}
                        gridAutoFlow='dense'
                        templateColumns={{
                            base: 'repeat(1, 1fr)',
                            md: 'repeat(3, 1fr)',
                        }}
                        rowGap={4}
                        w={'full'}
                    >
                        <GridItem>
                            <TextInput
                                {...textFieldPropFactory(t('ContactInfo.firstName'), ['legalFirstName'])}
                                {...manageFieldPropFactory<ManageTextProps>(
                                    ['legalFirstName'],
                                    'onChangeText',
                                    'value'
                                )}
                                stacked
                            />
                        </GridItem>

                        <GridItem>
                            <TextInput
                                {...textFieldPropFactory(t('ContactInfo.middleName'), ['middleName'])}
                                {...manageFieldPropFactory<ManageTextProps>(['middleName'], 'onChangeText', 'value')}
                                stacked
                            />
                        </GridItem>

                        <GridItem>
                            <TextInput
                                {...textFieldPropFactory(t('ContactInfo.lastName'), ['legalLastName'])}
                                {...manageFieldPropFactory<ManageTextProps>(['legalLastName'], 'onChangeText', 'value')}
                                stacked
                            />
                        </GridItem>
                    </Grid>

                    <Grid
                        columnGap={10}
                        gridAutoFlow='dense'
                        templateColumns={{
                            base: 'repeat(1, 1fr)',
                            md: 'repeat(3, 1fr)',
                        }}
                        rowGap={4}
                        w={'full'}
                    >
                        <GridItem>
                            <TextInput
                                {...typeaheadPropFactory(t('ContactInfo.homePhone'), ['homeAddress', 'phone'])}
                                {...manageFieldPropFactory<ManageTextProps>(
                                    ['homeAddress', 'phone'],
                                    'onChangeText',
                                    'value'
                                )}
                                stacked
                            />
                        </GridItem>

                        <GridItem>
                            <TextInput
                                {...typeaheadPropFactory(t('ContactInfo.mobilePhone'), ['mobilePhone'])}
                                {...manageFieldPropFactory<ManageTextProps>(['mobilePhone'], 'onChangeText', 'value')}
                                stacked
                            />
                        </GridItem>

                        <GridItem>
                            <TypeaheadInput
                                {...typeaheadPropFactory(t('ContactInfo.mailingPhone'), ['mailingPhone'])}
                                {...manageFieldPropFactory<ManageTextProps>(['mailingPhone'], 'onSelection', 'value')}
                                stacked
                            />
                        </GridItem>
                    </Grid>

                    <TextInput
                        {...typeaheadPropFactory(t('ContactInfo.Email'), ['emailAddress'])}
                        {...manageFieldPropFactory<ManageTextProps>(['emailAddress'], 'onChangeText', 'value')}
                    />

                    <Grid
                        columnGap={10}
                        gridAutoFlow='dense'
                        templateColumns={{
                            base: 'repeat(1, 1fr)',
                            md: 'repeat(3, 1fr)',
                        }}
                        rowGap={4}
                        w={'full'}
                    >
                        <GridItem>
                            <TextInput
                                {...textFieldPropFactory(t('ContactInfo.busRoute'), ['busRoute'])}
                                {...manageFieldPropFactory<ManageTextProps>(['busRoute'], 'onChangeText', 'value')}
                                stacked
                            />
                        </GridItem>

                        <GridItem>
                            <TextInput
                                {...textFieldPropFactory(t('ContactInfo.busCompany'), ['busCompany'])}
                                {...manageFieldPropFactory<ManageTextProps>(['busCompany'], 'onChangeText', 'value')}
                                stacked
                            />
                        </GridItem>
                    </Grid>
                </Flex>
            </FormHeader>
        </Box>
    );
};

export default ContactInfo;
