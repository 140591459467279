import Table, { Column } from '@Components/Table';
import { AcademicHat } from '@Icon';
import Header from '@Pages/EventDetails/components/Summary/components/Header';
import { PhoneCallStepProps } from '../../types';
import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Fieldset from '@Components/Fieldset';

export type SortByProps = { id: string; desc: boolean };

type absenceRecordType = {
    period: string;
    classInformation: string;
};

const AbsenceRecord = React.forwardRef<HTMLDivElement, PhoneCallStepProps>(({ id, phoneCallDetails }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (!phoneCallDetails?.classInformation) {
            return;
        }

        const periodsData = phoneCallDetails.classInformation.split(';');
        setData(
            periodsData.map((classInfo) => {
                const classInfoSplit = classInfo.split(' - ');
                return {
                    period: `${classInfoSplit[0]} - ${classInfoSplit[1]}`,
                    classInformation: classInfoSplit[2],
                };
            })
        );
    }, [phoneCallDetails]);

    const [data, setData] = useState<absenceRecordType[]>([]);

    const headers = [
        {
            Header: t('PhoneCall.periods'),
            accessor: 'period',
            headerAlign: 'left',
            disableSortBy: true,
        },
        {
            Header: t('PhoneCall.classInformation'),
            accessor: 'classInformation',
            headerAlign: 'left',
            disableSortBy: true,
        },
    ] as Column[];

    return (
        <Box id={id} my={6}>
            <Header mb={6} title={t('PhoneCall.subheader.records')} icon={<AcademicHat />} />
            <Box my={6}>
                <Fieldset
                    legendText={t('PhoneCall.subheader.absenceRecord')}
                    sx={{
                        backgroundColor: 'var(--chakra-colors-surface-secondary)',
                    }}
                >
                    <Table
                        manualPagination={true}
                        manualSortBy={false}
                        columns={headers}
                        rawData={data}
                        emptyMessage={t('PhoneCallAbsenceRecord.noData')}
                        tableWidth={{ 'table-layout': 'auto', width: '100%' }}
                        stickyHeader
                        containerStyle={{
                            borderRadius: 0,
                            borderWidth: 0,
                            borderStyle: 'none',
                            borderColor: 'transparent',
                        }}
                        wrapperStyle={{ ml: -2, width: 'auto' }}
                    />
                </Fieldset>
            </Box>
        </Box>
    );
});

export default AbsenceRecord;
