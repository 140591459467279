import { FormComponentProps } from '@Hooks/useForms';
import { CommonMembersResponse } from '@Redux/services/Participants/types';
import { Dispatch, SetStateAction } from 'react';
import type { PartialDeep } from 'type-fest';

export interface ParticipantStepProps extends FormComponentProps {
    participantDetails: PartialDeep<CommonMembersResponse>;
    setParticipantDetails: Dispatch<SetStateAction<PartialDeep<CommonMembersResponse>>>;
}

export const editableFieldsForParticipants = {
    locked: {
        required: true,
        editableStatus: false,
    },
    lastUsedOn: {
        required: true,
        editableStatus: false,
    },
    listName: {
        required: true,
        editableStatus: true,
    },
    description: {
        required: true,
        editableStatus: true,
    },
};
