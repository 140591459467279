import { Flex, Stack, useDisclosure } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useGetInvitedGradesQuery, useGetPhoneCallAbsenceReasonTypesQuery } from '@Redux/services/LookupApi';
import { PhoneCallTable } from './components/PhoneCallTable';
import PhoneCallDetailsDrawer from '@Components/PhoneCallDetailsDrawer';
import StudentsSidebar from '@Components/StudentsSidebar';
import { PhoneCall } from '@Redux/services/Absence/types';

export type SortByProps = { id: string; desc: boolean };

export const PhoneCallRecords = () => {
    const mainCotainerRef = useRef<HTMLDivElement>(null);
    const [initialized, setInitialized] = useState(false);
    const [selectedPs, setSelectedPs] = useState<PhoneCall>();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { data: { data: grades = [] } = {} } = useGetInvitedGradesQuery();
    const { data: { data: absenceReasonCodes = [] } = {} } = useGetPhoneCallAbsenceReasonTypesQuery();

    useEffect(() => {
        setTimeout(() => {
            setInitialized(true);
        }, 500);
    }, []);

    const handleRowClick = (phoneCall: PhoneCall) => {
        setSelectedPs(phoneCall);
        onOpen();
    };

    return (
        <Flex id='st' align={'stretch'} h='inherit'>
            <StudentsSidebar activeItem='phone-calls' />
            <Stack
                ref={mainCotainerRef}
                py={0}
                pl={2}
                pr={2}
                overflowX={'hidden'}
                overflowY={'auto'}
                gap={4}
                w={'full'}
                h='inherit'
            >
                <PhoneCallTable
                    grades={grades}
                    initialized={initialized}
                    onRowClick={handleRowClick}
                    absenceReasonCodes={absenceReasonCodes}
                />
            </Stack>
            {isOpen && selectedPs && (
                <PhoneCallDetailsDrawer
                    id={selectedPs.activityId}
                    isOpen={isOpen}
                    onClose={() => {
                        setSelectedPs(null);
                        onClose();
                    }}
                    absenceReasonCodes={absenceReasonCodes}
                />
            )}
        </Flex>
    );
};
