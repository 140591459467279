import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['container', 'thumb', 'track']);
const Switch = helpers.defineMultiStyleConfig({
    baseStyle: {
        container: {},
        thumb: {},
        track: {
            _checked: {
                bg: 'surface-brand-primary',
                'span.chakra-switch__track:not([data-checked])': { backgroundColor: 'text-secondary' },
            },
            _invalid: {
                backgroundColor: 'border-error',
            },
        },
    },
});

export default Switch;
