import { ManageTextProps } from '@Hooks';
import { ArrowUpRight } from '@Icon';
import Header from '@Pages/EventDetails/components/Summary/components/Header';
import { Box, Flex } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PhoneCallStepProps } from '../../types';
import SelectInput from '@Components/SelectInput';
import { BasicLookup } from '@Redux/services/LookupApi/types';
import FormHeader from '@Components/FormHeader';
import TypeaheadInput from '@Components/TypeaheadInput';
import { useGetActiveGuardiansForStudentQuery, useGetSystemUsersMutation } from '@Redux/services/LookupApi';

type IProps = {
    absenceReasonCodes: BasicLookup[];
    actionTypes: BasicLookup[];
} & PhoneCallStepProps;

const PhoneCallStatus = React.forwardRef<HTMLDivElement, IProps>(
    ({
        id,
        manageFieldPropFactory,
        selectFieldPropFactory,
        typeaheadPropFactory,
        absenceReasonCodes,
        phoneCallDetails,
        actionTypes,
    }) => {
        const { t } = useTranslation();
        const [getSystemUsers] = useGetSystemUsersMutation();
        const { refetch: getGuardians, data: { data: guardians = [] } = {} } = useGetActiveGuardiansForStudentQuery({
            studentId: phoneCallDetails.regardingId,
        });

        useEffect(() => {
            getGuardians();
        }, [phoneCallDetails.regardingId]);

        const durationValues = [
            {
                value: t('PhoneCall.duration.reportedPeriod'),
                key: 0,
            },
            {
                value: t('PhoneCall.duration.allDay'),
                key: 480,
            },
        ] as BasicLookup[];

        return (
            <Box id={id} my={6}>
                <Header mb={6} title={t('PhoneCall.subheader.status')} icon={<ArrowUpRight />} />
                <Flex gap={4} direction={'column'}>
                    <SelectInput
                        bg='surface-primary'
                        {...selectFieldPropFactory(t('PhoneCall.absenceReason'), ['absenceReason'])}
                        {...manageFieldPropFactory<ManageTextProps>(['absenceReason'], 'onChangeValue', 'value')}
                        options={absenceReasonCodes}
                        defaultOptionLabel={t('PhoneCall.absenceReason.defaultLabel')}
                    />
                    <SelectInput
                        bg='surface-primary'
                        {...selectFieldPropFactory(t('PhoneCall.duration'), ['duration'])}
                        {...manageFieldPropFactory<ManageTextProps>(['duration'], 'onChangeValue', 'value')}
                        options={durationValues}
                    />

                    <Box my={6}>
                        <FormHeader label={t('PhoneCall.subheader.call')}>
                            <Flex gap={4} direction={'column'}>
                                <SelectInput
                                    bg='surface-primary'
                                    {...selectFieldPropFactory(t('PhoneCall.action'), ['action'])}
                                    {...manageFieldPropFactory<ManageTextProps>(['action'], 'onChangeValue', 'value')}
                                    options={actionTypes}
                                    isRequired={false}
                                />
                                <TypeaheadInput
                                    online
                                    initialId={!phoneCallDetails.from && phoneCallDetails.currentUserId}
                                    initialValue={
                                        phoneCallDetails.from ? phoneCallDetails.from : phoneCallDetails.currentUserName
                                    }
                                    getData={getSystemUsers}
                                    getDataExtraParams={{ schoolId: phoneCallDetails.ownerId }}
                                    bg='surface-primary'
                                    {...typeaheadPropFactory(t('PhoneCall.from'), ['from'])}
                                    {...manageFieldPropFactory<ManageTextProps>(['fromId'], 'onSelection', 'value')}
                                />
                                <SelectInput
                                    defaultOption
                                    options={guardians}
                                    bg='surface-primary'
                                    {...selectFieldPropFactory(t('PhoneCall.to'), ['to'])}
                                    {...manageFieldPropFactory<ManageTextProps>(['toId'], 'onChangeValue', 'value')}
                                />
                            </Flex>
                        </FormHeader>
                    </Box>
                </Flex>
            </Box>
        );
    }
);

export default PhoneCallStatus;
