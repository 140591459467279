import * as React from 'react';
function SvgOfficeAdministration(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <mask
                id='office-administration_svg__a'
                style={{
                    maskType: 'alpha',
                }}
                maskUnits='userSpaceOnUse'
                x={0}
                y={0}
                width={20}
                height={20}
            >
                <path fill='#D9D9D9' d='M0 0h20v20H0z' />
            </mask>
            <g mask='url(#office-administration_svg__a)'>
                <path
                    d='M4.925 14.222a.882.882 0 01-.648-.265.882.882 0 01-.265-.649V9.276a.84.84 0 01.265-.635.906.906 0 01.648-.252c.256 0 .472.088.646.262.175.175.262.39.262.646v4.033a.852.852 0 01-.262.638.892.892 0 01-.646.255zm5.075 0a.873.873 0 01-.646-.265.886.886 0 01-.262-.649V9.276c0-.256.087-.468.262-.635A.897.897 0 0110 8.388c.256 0 .471.088.646.262.175.175.262.39.262.646v4.033a.852.852 0 01-.262.638.892.892 0 01-.646.255zM2.424 17.63a.882.882 0 01-.663-.275.934.934 0 01-.264-.673c0-.266.092-.49.275-.674a.916.916 0 01.673-.274h15.13c.267 0 .488.091.664.274a.935.935 0 01.264.674c0 .266-.092.49-.275.673a.916.916 0 01-.673.275H2.425zm12.65-3.408a.874.874 0 01-.645-.265.886.886 0 01-.262-.649V9.276c0-.256.087-.468.262-.635a.897.897 0 01.646-.252c.256 0 .472.088.648.262.177.175.265.39.265.646v4.033a.848.848 0 01-.265.638.9.9 0 01-.648.255zm-4.22-13.118l6.932 3.46a1.335 1.335 0 01.717 1.193c0 .315-.115.579-.345.793-.23.214-.503.321-.82.321H2.672c-.317 0-.593-.107-.826-.321a1.033 1.033 0 01-.35-.793c0-.255.062-.488.185-.7.124-.211.303-.37.537-.477l6.926-3.476c.266-.131.55-.196.852-.194.303.001.588.066.858.194zm-5.22 3.871h8.732L10 2.813 5.634 4.975z'
                    fill='#171923'
                />
            </g>
        </svg>
    );
}
export default SvgOfficeAdministration;
