import * as React from 'react';
function SvgKebabMenu(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M12.003 20.275a2.016 2.016 0 01-1.478-.614 2.012 2.012 0 01-.615-1.476c0-.576.205-1.07.614-1.48A2.01 2.01 0 0112 16.09c.578 0 1.07.205 1.478.614.408.41.612.903.612 1.479a2.02 2.02 0 01-.612 1.478c-.407.41-.899.614-1.475.614zm0-6.185a2.016 2.016 0 01-1.478-.614A2.011 2.011 0 019.91 12c0-.578.205-1.07.614-1.478.41-.408.901-.612 1.476-.612.578 0 1.07.204 1.478.612.408.407.612.9.612 1.475a2.02 2.02 0 01-.612 1.478c-.407.41-.899.615-1.475.615zm0-6.18a2.013 2.013 0 01-1.478-.616 2.022 2.022 0 01-.615-1.481c0-.577.205-1.069.614-1.477.41-.407.901-.611 1.476-.611.578 0 1.07.204 1.478.611.408.408.612.9.612 1.477 0 .577-.204 1.07-.612 1.481-.407.411-.899.616-1.475.616z'
                fill='#171923'
            />
        </svg>
    );
}
export default SvgKebabMenu;
