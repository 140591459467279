import { Activity, MemberPhoto, Note, PagedResult } from '../commonTypes';

export interface PhoneCall {
    activityId: string;
    isHighPriority: boolean;
    date: string | null;
    studentName: string;
    grade: number | null;
    contactPhoneNumber: string;
    contactName: string;
    action: string;
    periods: string;
    statusCode?: number;
    absenceReason?: number;
    duration?: number;
    avatar: MemberPhoto;
    hasLateOrAbsenceNote: boolean;
    guardianHasMfrAccount: boolean;
    studentNumber: string;
    staffName: string;
    guardianName: string;
    emergencyContact1Name: string;
    emergencyContact1PhoneNumber: string;
    emergencyContact2Name: string;
    emergencyContact2PhoneNumber: string;
    emergencyContact3Name: string;
    emergencyContact3PhoneNumber: string;
    emergencyContact4Name: string;
    emergencyContact4PhoneNumber: string;
}

interface AbsenceStat {
    completedCount: number;
    totalCount: number;
}
export interface PhoneCallStats {
    callProgress: AbsenceStat;
    attendanceTaken: AbsenceStat;
}

export interface AbsenceParams {
    sortBy: string;
    isDesc: boolean;
    currentPage: number;
    pageSize: number;
    searchText: string;
    statusCode?: number;
    StartDate?: string;
    EndDate?: string;
}

export interface IPhoneCallDetails {
    activityId: string;
    priorityCode?: number;
    statusCode?: number;
    actionDate?: Date;
    phoneCallType?: number;
    studentName: string;
    contactName: string;
    contactPhoneNumber: string;
    absenceReason?: number;
    description: string;
    ucdsbPowerschoolabsenceId: string;
    studentNumber: string;
    periods: string;
    classInformation: string;
    duration?: number;
    date?: string;
    dueDate?: string;
    action?: number;
    regarding: string;
    regardingId?: string;
    direction?: number;
    absenceSynchonizedWithPowerSchool?: boolean;
    lastModifiedDate?: string;
    lastModifiedByFullName: string;
    createdOn?: string;
    createdByFullName: string;
    primaryGuardian: UcdsbContact;
    secondaryGuardian: UcdsbContact;
    emergencyContact1: UcdsbContact;
    emergencyContact2: UcdsbContact;
    ownerId: string;
    subject: string;
    from?: string;
    fromId?: string;
    to?: string;
    toId?: string;
    notes: CallNote[];
    currentUserId: string;
    currentUserName: string;
    guardianHasMfrAccount: boolean;
}

export interface CallNote {
    noteType: callLogEnum;
    noteDate: string;
    noteText: string;
}

export enum callLogEnum {
    mfr,
    mir3,
    phoneCall,
}

export interface NewRecordsCount {
    amount: number;
}

export interface UcdsbContact {
    name: string;
    mobilePhoneNumber: string;
    workPhoneNumber: string;
    homePhoneNumber: string;
}

export interface PhoneCallDetailsParams {
    phoneCallId: string;
}

export interface PowerSchoolAbsence {
    ucdsbAbsenceId: string;
    psAbsenceId: string;
    absenceDate: Date;
    studentName: string;
    statusReason: string;
    reasonCode: string;
    reason: string;
}

export interface PowerSchoolAbsenceParams {
    sortBy: string;
    isDesc: boolean;
    currentPage: number;
    pageSize: number;
    searchText: string;
    statusReason?: string;
    reasonCode?: string;
    StartDate?: Date;
    EndDate?: Date;
}

export interface LateAbsenceNote {
    studentName: string;
    studentPsId: string;
    grade: string;
    absenceReason: string;
    lateReason: string;
    submittedBy: string;
    startDate: Date;
    endDate: Date;
    submittedDate: Date;
    homeSchool: string;
    note: string;
    status: string;
}

export interface LateAbsenceNoteParams {
    sortBy: string;
    isDesc: boolean;
    currentPage: number;
    pageSize: number;
    searchText: string;
    ownerId?: string;
    grade?: string;
    statusReason?: number;
    StartDate?: Date;
    EndDate?: Date;
    SubmittedDate?: Date;
    validToday?: boolean;
    isLate?: boolean;
}

export interface ILateAbsenceNoteDetails {
    attendanceId: string;
    student: string;
    statusCode?: number;
    statusReason: string;
    ownerId: string;
    owner: string;
    absenceReason: string;
    originatingSubmitter: string;
    originatingSubmitterId: string;
    absenceStart: Date;
    absenceEnd: Date;
    note: string;
    dateAbsenceSubmitted?: Date;
    sendEmailConfirmation?: boolean;
    modifiedBy: string;
    createdBy: string;
    createdOn: Date;
    lateAndAbsenceNotesInfo: LateAndAbsenceNotesInfo;
    lateAndAbsenceActivitiesInfo: Activity[];
    lateReasonId: string;
    absenceReasonId: string;
}

export interface LateAndAbsenceNotesInfo {
    notesInfo: Note[];
    pagedResult: PagedResult;
}

export interface ExportLateAndAbsenceNotesParam {
    sortBy: string;
    isDesc: boolean;
    currentPage: number;
    pageSize: number;
}

export interface LateAbsenceNoteDetailParams {
    attendanceId: string;
}

export interface StudentActivityRecord {
    type: string;
    priority: string;
    statusReason: string;
    student: string;
    relatedAbsence: string;
    createdOn: Date;
}

export interface StudentActivityRecordParams {
    sortBy: string;
    isDesc: boolean;
    currentPage: number;
    pageSize: number;
    searchText: string;
    priority?: string;
    statusReason?: string;
    startDate?: Date;
    endDate?: Date;
}

export interface StudentActivityIdParam {
    id: string;
}

export interface StudentActivityDetails {
    studentActivityId: string;
    type: string;
    priority: number;
    status: number;
    studentId: string;
    studentName: string;
    relatedAbsenceId: string;
    relatedAbsenceName: string;
    createdOn: string;
    createdBy: string;
    modifiedOn: string;
    modifiedBy: string;
    respondingGuardian: string;
    notes: Note[];
}

export interface PowerSchoolAbsenceIdParam {
    id: string;
}

export interface PowerSchoolAbsenceDetails {
    absenceId: string;
    status: number;
    studentId: string;
    studentName: string;
    powerSchoolAbsenceId: string;
    absenceDate: string;
    reasonCode: string;
    notificationSent: string;
    classStartTime: string;
    classEndTime: string;
    absencePeriod: string;
    reason: string;
    class: string;
    submittedAbsenceReasonId: string;
    matchedAttendanceSubmissionId: string;
    matchedAttendanceSubmissionName: string;
    plannedAbsence?: boolean;
    modifiedOn: string;
    modifiedBy: string;
    createdOn: string;
    createdBy: string;
    approvedOn: string;
    approvedBy: string;
    notes: Note[];
    activities: Activity[];
    ownerId: string;
}

export interface AttendanceTakenRecordIdParam {
    id: string;
}

export interface AttendanceTakenRecordDetails {
    attendanceTakenId: string;
    statusReason: number;
    followupStatus: number;
    ownerId: string;
    className: string;
    classCode: string;
    grade: number;
    period: string;
    roomNumber: string;
    teacherName: string;
    teacherEmail: string;
    bellDateTime: string;
    dueDateTime: string;
    followUpDateTime: string;
    completedDate: string;
    createdOn: string;
    createdBy: string;
    modifiedOn: string;
    modifiedBy: string;
    notes: Note[];
    activities: Activity[];
}

export interface UpdateAttendanceTakenRecordParams {
    attendanceTakenId: string;
    statusReason?: number;
    followupStatus?: number;
}

export interface AbsenceReason {
    id: string;
    displayOrder?: number;
    name: string;
    code: string;
    isLateReason?: boolean;
    createdOn?: string;
}

export interface GetAllAbsenceReasonsParam {
    sortBy: string;
    isDesc: boolean;
    currentPage: number;
    pageSize: number;
}

export interface AbsenceReasonIdParam {
    id: string;
}

export interface AbsneceReasonDetails {
    absenceReasonId: string;
    name: string;
    code: string;
    isLateReason: boolean;
    displayOrder: number;
    translationKey: string;
    modifiedOn: string;
    modifiedBy: string;
    createdOn: string;
    createdBy: string;
    isActive: boolean;
    status?: number;
}

export interface UpdatePhoneCallParams {
    phoneCallId: string;
    callFrom?: string;
    callTo?: string;
    absenceReason: number;
    actionType: number;
    duration: number;
}

export interface CheckNewEntriesParams {
    lastUpdatedDate: Date;
}

export interface CreateAbsenceReasonParams {
    name: string;
    code: string;
    isLateReason: boolean;
    displayOrder: number;
    status: boolean;
}

export interface UpdateAbsenceReasonParams extends CreateAbsenceReasonParams {
    absenceReasonId: string;
}

export interface DeleteAbsenceReasonParams {
    absenceReasonId: string;
}

export interface MarkCallCompleteParams {
    phoneCallId: string;
}

export interface AbsenceTaken {
    attendanceTakenId: string;
    statusReason?: number;
    className: string;
    classCode: string;
    grade?: number;
    period?: number;
    teacherName: string;
    roomNumber: string;
    followupStatus?: number;
    createdOn?: Date;
    schoolName: string;
}

export interface AttendanceTakenParams {
    sortBy: string;
    isDesc: boolean;
    currentPage: number;
    pageSize: number;
    statusReason?: number;
    createdOn?: Date;
    grade?: string;
    followupStatus?: number;
    searchText: string;
}

export enum ucdsb_attendancefollowupstatus {
    Outstanding = 951030000,
    AttemptingtoContactTeacher = 951030001,
    ContactedTeacher = 951030002,
    EscalatedtoPrincipal = 951030003,
}
