import { TFunction } from 'react-i18next';
import { RegistrationStatusParam } from '../index';

const sideBarData = (registrationStatus: RegistrationStatusParam, t: TFunction) => ({
    groups: [
        {
            items: [
                {
                    label: t('RegistrationsList.all'),
                    id: '/registrations/all',
                    active: registrationStatus === 'all',
                },
                {
                    label: t('RegistrationsList.submittedAndDocumentsRequired'),
                    id: '/registrations/submittedAndDocumentsRequired',
                    active: registrationStatus === 'submittedAndDocumentsRequired',
                },
                {
                    label: t('RegistrationsList.submitted'),
                    id: '/registrations/submitted',
                    active: registrationStatus === 'submitted',
                },
                {
                    label: t('RegistrationsList.documentsRequired'),
                    id: '/registrations/documentsRequired',
                    active: registrationStatus === 'documentsRequired',
                },
                {
                    label: t('RegistrationsList.approvedAndArchived'),
                    id: '/registrations/approvedAndArchived',
                    active: registrationStatus === 'approvedAndArchived',
                },
                {
                    label: t('RegistrationsList.rejected'),
                    id: '/registrations/rejected',
                    active: registrationStatus === 'rejected',
                },
                {
                    label: t('RegistrationsList.deleted'),
                    id: '/registrations/deleted',
                    active: registrationStatus === 'deleted',
                },
            ],
        },
    ],
});

export { sideBarData };
