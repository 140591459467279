import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormComponentProps, ManageSwitchInputProps, ManageTextProps } from '@Hooks';
import { Box, Grid } from '@chakra-ui/react';
import SelectInput from '@Components/SelectInput';
import TextInput from '@Components/TextInput';
import { BasicLookup } from '@Redux/services/LookupApi/types';
import Switch from '@Components/Switch';
import { RegistrationInformation } from '@Redux/services/Registration/types';

interface ChildMailingAddressDetailsProps extends FormComponentProps {
    cities: BasicLookup[];
    provinces: BasicLookup[];
    countries: BasicLookup[];
    registrationInformation: RegistrationInformation;
}

const ChildMailingAddressDetails: React.FC<ChildMailingAddressDetailsProps> = ({
    manageFieldPropFactory,
    selectFieldPropFactory,
    textFieldPropFactory,
    switchFieldPropFactory,
    provinces,
    countries,
    registrationInformation,
}) => {
    const [t] = useTranslation();
    const [provinceLock, setProvinceLock] = useState({});
    const canadaKey = useMemo(() => countries.find((country) => country.value === 'Canada')?.key, [countries]);

    const provinceParams = {
        ...manageFieldPropFactory<ManageTextProps>(
            ['addressInformation', 'childMailingAddress', 'province'],
            'onChangeValue',
            'value'
        ),
    };

    useEffect(() => {
        if (registrationInformation.addressInformation?.childMailingAddress?.country !== canadaKey) {
            provinceParams['onChangeValue'](0);
            setProvinceLock({ isLocked: true });
        } else {
            setProvinceLock({});
        }
    }, [registrationInformation.addressInformation?.childMailingAddress?.country]);

    return (
        <>
            <Box pb={4}>
                <Switch
                    {...switchFieldPropFactory(t('RegistrationDetails.homeAddress.childAddress.sameAsHomeAddress'), [
                        'addressInformation',
                        'isMailingAddressSameAsHomeAddress',
                    ])}
                    {...manageFieldPropFactory<ManageSwitchInputProps>(
                        ['addressInformation', 'isMailingAddressSameAsHomeAddress'],
                        'onChangeSwitch',
                        'isChecked'
                    )}
                    col={1}
                />
            </Box>
            <Box pb={4}>
                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.homeAddress.childAddress.streetNumber'), [
                        'addressInformation',
                        'childMailingAddress',
                        'streetName',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'childMailingAddress', 'streetName'],
                        'onChangeText',
                        'value'
                    )}
                    col={1}
                />
            </Box>
            <Grid
                column={{ md: 3, sm: 1 }}
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: '1fr 1fr 1fr',
                }}
                rowGap={4}
                templateRows='auto'
                mb={6}
            >
                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.homeAddress.childAddress.apartmentNumber'), [
                        'addressInformation',
                        'childMailingAddress',
                        'apartmentNumber',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'childMailingAddress', 'apartmentNumber'],
                        'onChangeText',
                        'value'
                    )}
                    labelProps={{ flexBasis: '55%' }}
                />
                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.homeAddress.childAddress.routeNumber'), [
                        'addressInformation',
                        'childMailingAddress',
                        'rrNumber',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'childMailingAddress', 'rrNumber'],
                        'onChangeText',
                        'value'
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.homeAddress.childAddress.poBox'), [
                        'addressInformation',
                        'childMailingAddress',
                        'poBox',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'childMailingAddress', 'poBox'],
                        'onChangeText',
                        'value'
                    )}
                />
            </Grid>

            <Grid
                column={{ md: 2, sm: 1 }}
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                }}
                rowGap={4}
                templateRows='auto'
                mb={6}
            >
                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.homeAddress.childAddress.postalCode'), [
                        'addressInformation',
                        'childMailingAddress',
                        'postalCode',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'childMailingAddress', 'postalCode'],
                        'onChangeText',
                        'value'
                    )}
                />
                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.homeAddress.parentAddress.city'), [
                        'addressInformation',
                        'childMailingAddress',
                        'cityFreeForm',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'childMailingAddress', 'cityFreeForm'],
                        'onChangeText',
                        'value'
                    )}
                />
                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.homeAddress.childAddress.province'), [
                        'addressInformation',
                        'childMailingAddress',
                        'province',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'childMailingAddress', 'province'],
                        'onChangeValue',
                        'value'
                    )}
                    options={provinces}
                    {...provinceLock}
                />

                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.homeAddress.childAddress.country'), [
                        'addressInformation',
                        'childMailingAddress',
                        'country',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'childMailingAddress', 'country'],
                        'onChangeValue',
                        'value'
                    )}
                    options={countries}
                />
            </Grid>
        </>
    );
};

export default ChildMailingAddressDetails;
