import Drawer from '@Components/Drawer';
import SectionHeader from '@Components/SectionHeader';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Cycle, ReactIcon, ScanDelete } from '@Icon';

import { FC, FormEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import EventResponseDetailForm from './components/EventResponseDetail';
import { useEventResponse } from '@Hooks/useEventResponse';
import { EVENT_RESPONSE_CONFIRMED, EVENT_RESPONSE_DRAFT } from 'src/constants';
import { MenuItem } from '@Components/Menu';
import { EVENT_RESPONSE_STATUSES } from '@Pages/EventResponses/types';
import { AuditInfo } from '@Components/AuditInfo';
import { DrawerFooterActions } from '@Components/DrawerFooterActions';

interface IProps {
    id: string;
    isOpen: boolean;
    onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
    onClose: () => void;
}

const EventResponseDetailDrawer: FC<IProps> = ({ id, isOpen, onClose, onSubmit }) => {
    const { t } = useTranslation();

    const eventResponseHookObject = useEventResponse({
        id: id,
        nextStageSuccess: () => onSubmit(null),
        onCloseDrawer: onClose,
    });

    const {
        eventResponse,
        isLoading,
        hasPendingChange,
        isClosedOrCanceled,
        handleManualSave,
        handleActionClick,
        handleNext,
        isSaving,
        isEventPublished,
        isResponseDraftOrPending,
        getNextStatus,
    } = eventResponseHookObject;

    const actionMenuItems = useMemo<MenuItem[]>(
        () => [
            {
                label: t('EventResponses.reset'),
                menuItemProps: {
                    icon: <ReactIcon width={'16px'} height={'16px'} />,
                    onClick: handleActionClick(false),
                    isDisabled: isSaving || eventResponse?.status !== EVENT_RESPONSE_DRAFT || !isEventPublished,
                },
            },
            {
                label: t('EventResponses.cancel'),
                menuItemProps: {
                    icon: <ScanDelete width={'16px'} height={'16px'} />,
                    onClick: handleActionClick(true),
                    isDisabled: isSaving || eventResponse?.status !== EVENT_RESPONSE_CONFIRMED || !isEventPublished,
                },
            },
        ],
        [eventResponse, isEventPublished, isSaving]
    );
    const onSave = () => {
        if (!isClosedOrCanceled) {
            handleManualSave();
        }
    };

    return (
        <Drawer
            size={'xl'}
            isOpen={isOpen}
            onClose={onClose}
            onOverlayClick={onClose}
            isForm={true}
            isLoading={isLoading}
            title={
                <SectionHeader
                    title={isLoading ? '' : `${eventResponse?.detail?.fullName}${t('EventResponseDrawer.title')}`}
                />
            }
            footer={
                <DrawerFooterActions
                    isEditable={true}
                    closeButtonProps={{
                        onClick: async () => await onClose(),
                        isDisabled: isSaving,
                        label: t('EventResponseDetailDrawer.closeDrawer'),
                    }}
                    saveButtonProps={{
                        type: 'submit',
                        isDisabled: !hasPendingChange || isSaving,
                        label: t('EventResponseDetailDrawer.save'),
                        onClick: onSave,
                    }}
                    nextButtonProps={{
                        variant: isSaving ? 'brand-inverted' : 'primary',
                        onClick: handleNext,
                        textStyle: 'md-semibold',

                        rightIcon: isSaving ? (
                            <Cycle className='spin-loading' width='20px' height='20px' />
                        ) : (
                            <ArrowForwardIcon width='20px' height='20px' />
                        ),
                        isDisabled:
                            hasPendingChange ||
                            isClosedOrCanceled ||
                            !isResponseDraftOrPending ||
                            !isEventPublished ||
                            isSaving,
                        mr: 2,
                        label: t(isSaving ? 'EventResponses.nextStepLoading' : 'EventResponses.nextStep', {
                            seperator: isResponseDraftOrPending ? ':' : '',
                            nextStep:
                                getNextStatus(eventResponse?.status) !== 0
                                    ? EVENT_RESPONSE_STATUSES[getNextStatus(eventResponse?.status)]
                                    : '',
                        }),
                    }}
                    actionMenuItems={actionMenuItems}
                />
            }
        >
            <EventResponseDetailForm isDrawer onClose={onClose} {...eventResponseHookObject} />

            <AuditInfo
                modifiedBy={eventResponse?.modifiedByFullName}
                modifiedOn={eventResponse?.modifiedOn}
                createdBy={eventResponse?.createdByFullName}
                createdOn={eventResponse?.createdOn}
            />
        </Drawer>
    );
};

export default EventResponseDetailDrawer;
