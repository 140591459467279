import { TFunction } from 'react-i18next';
import { RegistrationSidebarItem } from '../types';
import SvgOfficeAdministration from '@Icon/OfficeAdministration';
import SvgAboutStudent from '@Icon/AboutStudent';
import SvgBusNeeds from '@Icon/BusNeeds';
import SvgCitizenship from '@Icon/Citizenship';
import SvgSchool from '@Icon/School';
import SvgGuardianContact from '@Icon/GuardianContact';
import SvgNotes from '@Icon/Notes';
import SvgAddress from '@Icon/Address';
import SvgContacts from '@Icon/Contacts';

const sideBarData = (item: RegistrationSidebarItem, t: TFunction) => ({
    groups: [
        {
            items: [
                {
                    label: t('RegistrationDetails.officeAdministration'),
                    id: '#officeadministration',
                    icon: <SvgOfficeAdministration />,
                    active: item === RegistrationSidebarItem.OfficeAdministration,
                },
                {
                    label: t('RegistrationDetails.aboutStudent'),
                    id: '#aboutstudent',
                    active: item === RegistrationSidebarItem.AboutStudent,
                    icon: <SvgAboutStudent />,
                },
                {
                    label: t('RegistrationDetails.homeAddress'),
                    id: '#homeaddress',
                    icon: <SvgAddress />,
                    active: item === RegistrationSidebarItem.HomeAddress,
                },
                {
                    label: t('RegistrationDetails.busNeeds'),
                    id: '#busneeds',
                    icon: <SvgBusNeeds />,
                    active: item === RegistrationSidebarItem.BusNeeds,
                },
                {
                    label: t('RegistrationDetails.previousSchool'),
                    id: '#previousschool',
                    icon: <SvgSchool />,
                    active: item === RegistrationSidebarItem.PreviousSchool,
                },
                {
                    label: t('RegistrationDetails.citizenship'),
                    id: '#citizenship',
                    icon: <SvgCitizenship />,
                    active: item === RegistrationSidebarItem.Citizenship,
                },
                {
                    label: t('RegistrationDetails.school'),
                    id: '#school',
                    icon: <SvgSchool />,
                    active: item === RegistrationSidebarItem.School,
                },
                {
                    label: t('RegistrationDetails.guardianContactDetails'),
                    id: '#guardiancontactdetails',
                    icon: <SvgGuardianContact />,
                    active: item === RegistrationSidebarItem.GuardianContactDetails,
                },
                {
                    label: t('RegistrationDetails.contacts'),
                    id: '#contacts',
                    icon: <SvgContacts />,
                    active: item === RegistrationSidebarItem.GuardianContactDetails,
                },
                {
                    label: t('RegistrationDetails.notes'),
                    id: '#notes',
                    icon: <SvgNotes />,
                    active: item === RegistrationSidebarItem.Notes,
                },
            ],
        },
    ],
});

export { sideBarData };
