import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import React from 'react';
import TextInput from '@Components/TextInput';
import ReactIcon from '@Icon/ReactIcon';
import Header from '@Pages/EventDetails/components/Summary/components/Header';
import FormHeader from '@Components/FormHeader';
import { ParticipantStepProps } from '@Pages/EventParticipants/types';
import Textarea from '@Components/Textarea';
import { ManageTextProps } from '@Hooks';

const Information = React.forwardRef<HTMLDivElement, ParticipantStepProps>(
    ({ id, manageFieldPropFactory, textFieldPropFactory, typeaheadPropFactory, textAreaPropFactory }, ref) => {
        const { t } = useTranslation();

        return (
            <Box id={id} ref={ref} w={'full'}>
                <Header divider={true} mb={4} title={t('Information.summary')} icon={<ReactIcon />} />
                <FormHeader id={id} label={t('Information.info')}>
                    <Flex gap={4} direction={'column'}>
                        <TextInput
                            {...typeaheadPropFactory(t('Information.name'), ['listName'])}
                            {...manageFieldPropFactory<ManageTextProps>(['listName'], 'onChangeText', 'value')}
                            col={1}
                        />

                        <Grid
                            columnGap={10}
                            gridAutoFlow='dense'
                            templateColumns={{
                                base: 'repeat(1, 1fr)',
                                md: 'repeat(2, 1fr)',
                            }}
                            rowGap={4}
                            w={'full'}
                        >
                            <GridItem>
                                <TextInput
                                    {...textFieldPropFactory(t('Information.listType'), ['listType'])}
                                    {...manageFieldPropFactory<ManageTextProps>(['listType'], 'onChangeText', 'value')}
                                />
                            </GridItem>

                            <GridItem>
                                <TextInput
                                    {...textFieldPropFactory(t('Information.targetedAt'), [
                                        'information',
                                        'targetedAt',
                                    ])}
                                    {...manageFieldPropFactory<ManageTextProps>(
                                        ['targetedAt'],
                                        'onChangeText',
                                        'value'
                                    )}
                                />
                            </GridItem>
                        </Grid>

                        <Grid
                            columnGap={10}
                            gridAutoFlow='dense'
                            templateColumns={{
                                base: 'repeat(1, 1fr)',
                                md: 'repeat(2, 1fr)',
                            }}
                            rowGap={4}
                            w={'full'}
                        >
                            <GridItem>
                                <TextInput
                                    {...textFieldPropFactory(t('Information.membersCount'), ['memberCount'])}
                                    {...manageFieldPropFactory<ManageTextProps>(
                                        ['memberCount'],
                                        'onChangeText',
                                        'value'
                                    )}
                                    type='number'
                                />
                            </GridItem>

                            <GridItem>
                                <TextInput
                                    {...textFieldPropFactory(t('Information.guardiansList'), [
                                        'information',
                                        'guardiansList',
                                    ])}
                                    {...manageFieldPropFactory<ManageTextProps>(
                                        ['guardiansList'],
                                        'onChangeText',
                                        'value'
                                    )}
                                />
                            </GridItem>
                        </Grid>

                        <Grid
                            columnGap={10}
                            gridAutoFlow='dense'
                            templateColumns={{
                                base: 'repeat(1, 1fr)',
                                md: 'repeat(2, 1fr)',
                            }}
                            rowGap={4}
                            w={'full'}
                        >
                            <GridItem>
                                <TextInput
                                    {...typeaheadPropFactory(t('Information.previewList'), [
                                        'information',
                                        'previewList',
                                    ])}
                                    {...manageFieldPropFactory<ManageTextProps>(
                                        ['previewList'],
                                        'onChangeText',
                                        'value'
                                    )}
                                />
                            </GridItem>

                            <GridItem>
                                <TextInput
                                    {...typeaheadPropFactory(t('Information.previewFor'), [
                                        'information',
                                        'previewFor',
                                    ])}
                                    {...manageFieldPropFactory<ManageTextProps>(
                                        ['previewFor'],
                                        'onChangeText',
                                        'value'
                                    )}
                                />
                            </GridItem>
                        </Grid>

                        <Textarea
                            {...textAreaPropFactory(t('Information.description'), ['description'])}
                            {...manageFieldPropFactory<ManageTextProps>(['description'], 'onChangeText', 'value')}
                            col={1}
                        />
                    </Flex>
                </FormHeader>
            </Box>
        );
    }
);

export default Information;
