import { Box, TabPanel, TabPanels, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Header from '../Summary/components/Header';
import React, { useMemo } from 'react';
import { StepProps } from '@Pages/EventDetails/types';
import Answers from './components/Answers';
import CostItems from './components/CostItems';
import Payment from './components/Payment';
import Tabs from '@Components/Tabs';
import EventResponses from './components/EventResponses';
import SvgResponses from '@Icon/Responses';

export const EventStatus = React.forwardRef<HTMLDivElement, StepProps>(({ id, ...rest }, ref) => {
    const { t } = useTranslation();
    const tabs = useMemo(
        () => [
            t('EventStatus.responses'),
            t('EventStatus.costItemsLabel'),
            t('EventStatus.answersLabel'),
            t('EventStatus.paymentLabel'),
        ],
        []
    );

    const disclosureProps = useDisclosure();

    return (
        <Box id={id} ref={ref}>
            <Box display={'flex'} width='100%'>
                <Header w={'100%'} mb={4} title={t('EventDetails.responses')} icon={<SvgResponses />} />
                {/* <Button
                    leftIcon={<Plus fontSize={'20px'} />}
                    variant={'light'}
                    minW={'fit-content'}
                    onClick={() => disclosureProps.onOpen()}
                >
                    {t('EventResponses.addResponse')}
                </Button> */}
            </Box>
            <Tabs tabs={tabs}>
                <TabPanels>
                    <TabPanel>
                        <EventResponses {...rest} showHeader={false} {...disclosureProps} />
                    </TabPanel>
                    <TabPanel>
                        <CostItems {...rest} />
                    </TabPanel>
                    <TabPanel>
                        <Answers {...rest} />
                    </TabPanel>
                    <TabPanel>
                        <Payment {...rest} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
});
