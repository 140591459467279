import { Box, Flex, Text } from '@chakra-ui/react';
import { Trans } from 'react-i18next';
import { FitzEditatingAtHome } from '@Svg';
import { useSelector } from 'react-redux';
import { RootState } from '@Redux';

export const KillSwitchTakeOverScreen = () => {
    const { isServerUnavailable } = useSelector((state: RootState) => state.killSwitch);
    if (!isServerUnavailable) {
        return;
    }
    return (
        <Box position='fixed' top={0} bottom={0} h='100%' w='full' bg='white' zIndex={100}>
            <Flex alignItems={'center'} justifyContent='center' h='100%'>
                <Flex flexDirection='column' gap='24px' maxW='384px' alignItems='center'>
                    <FitzEditatingAtHome />
                    <Flex flexDirection='column' alignItems='center' w='100%' gap='16px' alignSelf='stretch'>
                        <Text textStyle='3xl-semibold' align='center'>
                            <Trans i18nKey={'KillSwitchTakeOverScreen.header'} />
                        </Text>
                        <Text noOfLines={2} textStyle='md-normal' textAlign='center'>
                            <Trans i18nKey='KillSwitchTakeOverScreen.subHeader' />
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    );
};
