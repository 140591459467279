import { Box, Divider, Flex, Link, useDisclosure } from '@chakra-ui/react';
import { RangeDatepicker } from '@Components';
import { Alert } from '@Components/Alert';
import { Select } from '@Components/Select';
import { useGetPhoneCallPriorityTypesQuery, useGetStudentActivityStatusCodesQuery } from '@Redux/services/LookupApi';
import { format } from 'date-fns';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DATE_FORMAT_E_MMM_YY } from 'src/constants';

export interface StudentActivityRecordFilters {
    dateRange: Date[];
    statusReason?: string;
    priority?: string;
}

interface IProps {
    onChange: (key: string) => (value: string | number | Date[]) => void;
    onResetField: (key: string, value: []) => void;
    onReset: () => void;
    filters: StudentActivityRecordFilters;
}

export const TableFilters: FC<IProps> = ({ filters, onChange, onReset, onResetField }) => {
    const { data: { data: statusReason = [] } = {} } = useGetStudentActivityStatusCodesQuery();
    const { data: { data: priority = [] } = {} } = useGetPhoneCallPriorityTypesQuery();
    const { t } = useTranslation();
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const handleChange = (key) => (event) => {
        onChange(key)(parseInt(event.target.value, 10));
    };

    return (
        <>
            <Alert
                isOpen={isOpen}
                onClose={onClose}
                alertProps={{
                    status: 'warning',
                }}
                description={
                    <Trans
                        i18nKey={'StudentActivityRecords.TableFilters.description'}
                        values={{
                            startDate: format(new Date(), DATE_FORMAT_E_MMM_YY),
                            endDate: format(new Date(), DATE_FORMAT_E_MMM_YY),
                        }}
                        components={[
                            <Link
                                onClick={() => {
                                    onReset();
                                    onClose();
                                }}
                                color={'text-warning'}
                            />,
                        ]}
                    />
                }
            />
            <Divider />
            <Flex p={6} display={{ base: 'block', md: 'flex' }} gap='16px'>
                <Box minW={300}>
                    <RangeDatepicker
                        onDateChange={onChange('dateRange')}
                        isLocked={false}
                        size='sm'
                        selectedDates={filters.dateRange}
                        onResetClick={() => onResetField('dateRange', [])}
                        placeHolder={t('StudentActivityRecords.filters.dateRange')}
                    />
                </Box>

                <Select
                    onChange={handleChange('statusReason')}
                    value={filters.statusReason}
                    size='sm'
                    options={statusReason}
                    bg='surface-primary'
                    placeholder={t('StudentActivityRecords.filters.statusReason')}
                    maxW='180px'
                />

                <Select
                    onChange={handleChange('priority')}
                    value={filters.priority?.toString()}
                    /*
                    The placeholder option has value of empty string.
                    Although StudentActivityRecordFilters.priority is string type,
                    onChange listener in SelectInput would make the filter value a number.
                    With the value of Low priority being 0, which deemed same as empty string (both as false),
                    clicking on Low priority would appear as selecting the placeholder, hence toString is needed here.
                    */
                    size='sm'
                    options={priority}
                    bg='surface-primary'
                    placeholder={t('StudentActivityRecords.filters.priority')}
                    maxW='180px'
                />
            </Flex>
            <Divider />
        </>
    );
};
