import { Breadcrumb as ChakraBreadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Text } from '@chakra-ui/react';
import { FC } from 'react';

export interface BreadCrumbItem {
    label: string;
    inactiveLable: string;
    onClick: () => void;
    isCurrentPage: boolean;
}

interface IProps {
    items: BreadCrumbItem[];
}
export const Breadcrumb: FC<IProps> = ({ items }) => {
    return (
        <ChakraBreadcrumb>
            {items.map((item) => (
                <BreadcrumbItem>
                    {!item.isCurrentPage ? (
                        <Button variant='brand-inverted' onClick={item.onClick} borderRadius='96px'>
                            {item.inactiveLable}
                        </Button>
                    ) : (
                        <Text textStyle='2xl-medium'>{item.label}</Text>
                    )}
                </BreadcrumbItem>
            ))}
        </ChakraBreadcrumb>
    );
};
