import { createContext, SetStateAction } from 'react';
import type { Dispatch } from 'react';

type Context = {
    showAlert: boolean;
    setShowAlert: Dispatch<SetStateAction<boolean>>;
    triggerAlert: boolean;
    setTriggerAlert: Dispatch<SetStateAction<boolean>>;
};

export const AlertContext = createContext<Context>({
    showAlert: false,
    setShowAlert: () => {
        ('');
    },
    triggerAlert: false,
    setTriggerAlert: () => {
        ('');
    },
});
