import * as React from 'react';
function SvgAttach(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g clipPath='url(#attach_svg__clip0_8412_76297)'>
                <mask
                    id='attach_svg__a'
                    style={{
                        maskType: 'alpha',
                    }}
                    maskUnits='userSpaceOnUse'
                    x={0}
                    y={0}
                    width={24}
                    height={24}
                >
                    <path fill='#D9D9D9' d='M0 0h24v24H0z' />
                </mask>
                <g mask='url(#attach_svg__a)'>
                    <path
                        d='M11.096 22.394c-1.597 0-2.95-.555-4.061-1.666-1.11-1.11-1.666-2.465-1.666-4.061V6.077c0-1.15.409-2.136 1.226-2.956.817-.82 1.801-1.23 2.953-1.23 1.15 0 2.136.41 2.956 1.23.82.82 1.23 1.805 1.23 2.956v9.542c0 .736-.256 1.36-.767 1.87a2.546 2.546 0 01-1.87.767c-.737 0-1.36-.255-1.871-.766a2.545 2.545 0 01-.767-1.871V6.917c0-.243.08-.443.242-.602a.827.827 0 01.603-.238.817.817 0 01.84.84v8.702a.93.93 0 00.274.683.918.918 0 00.678.275c.27 0 .496-.092.679-.275a.93.93 0 00.274-.683V6.077c0-.7-.242-1.292-.726-1.775-.483-.483-1.075-.725-1.775-.725s-1.291.242-1.775.725c-.483.484-.725 1.075-.725 1.775v10.59c0 1.116.396 2.07 1.187 2.86.792.792 1.745 1.188 2.861 1.188s2.07-.396 2.861-1.187c.791-.792 1.187-1.745 1.187-2.861v-9.75c0-.243.08-.443.238-.602a.816.816 0 01.602-.238c.242 0 .443.08.602.238.158.159.238.36.238.602v9.75c0 1.596-.556 2.95-1.667 4.06-1.11 1.112-2.464 1.667-4.061 1.667z'
                        fill='#171923'
                    />
                </g>
            </g>
            <defs>
                <clipPath id='attach_svg__clip0_8412_76297'>
                    <path fill='#fff' d='M0 0h24v24H0z' />
                </clipPath>
            </defs>
        </svg>
    );
}
export default SvgAttach;
