import { Avatar, Box, Flex, Text } from '@chakra-ui/react';

export const FullNameCell = ({ value, row }) => {
    return (
        <Flex alignItems={'center'} gap={4} pl={4}>
            <Avatar
                name={value}
                src={row.studentPhoto ? `data:${row.studentPhoto.mimeType};base64,${row.studentPhoto.base64}` : ''}
                size='md'
            />
            <Box w={'full'}>
                <Text
                    _disabled={{ textColor: '#FFFFFF' }}
                    textColor={'black'}
                    textAlign={'left'}
                    textStyle='sm-medium'
                    sx={{
                        _first: {
                            textTransform: 'Capitalize',
                        },
                    }}
                >
                    {value}
                </Text>
            </Box>
        </Flex>
    );
};
