import * as React from 'react';
function SvgArrowUpward(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g clipPath='url(#arrow-upward_svg__clip0_1923_10054)'>
                <mask
                    id='arrow-upward_svg__a'
                    style={{
                        maskType: 'alpha',
                    }}
                    maskUnits='userSpaceOnUse'
                    x={0}
                    y={0}
                    width={24}
                    height={24}
                >
                    <path fill='#D9D9D9' d='M0 0h24v24H0z' />
                </mask>
                <g mask='url(#arrow-upward_svg__a)'>
                    <path
                        d='M10.863 8.154L6.196 12.82a1.065 1.065 0 01-.802.332 1.133 1.133 0 01-.801-.351A1.18 1.18 0 014.266 12a1.064 1.064 0 01.332-.802l6.6-6.6a1.05 1.05 0 01.373-.245c.137-.052.28-.077.43-.077.148 0 .291.025.428.077.136.052.26.133.373.245l6.606 6.606c.215.215.323.479.323.79a1.138 1.138 0 01-1.134 1.15c-.313 0-.584-.114-.812-.342l-4.647-4.648v10.912c0 .319-.11.588-.33.808-.22.22-.489.33-.808.33-.32 0-.588-.11-.808-.33-.22-.22-.33-.49-.33-.808V8.154z'
                        fill='#1C1B1F'
                    />
                </g>
            </g>
            <defs>
                <clipPath id='arrow-upward_svg__clip0_1923_10054'>
                    <path fill='#fff' d='M0 0h24v24H0z' />
                </clipPath>
            </defs>
        </svg>
    );
}
export default SvgArrowUpward;
