import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Accessor, getMutableForAccessor } from '@Utilities';
import { cloneDeep } from 'lodash';
import type { PartialDeep } from 'type-fest';
import { TextareaProps } from '@Components/Textarea';
import { TypeaheadInputProps } from '@Components/TypeaheadInput';
import { SelectInputProps } from '@Components/SelectInput';
import { SwitchProps } from '@Components/Switch';
import { TextInputProps } from '@Components/TextInput';
import { DateTimePickerProps } from '@Components/DateTimePicker';

export interface ManageFieldPropFactory {
    <IProps>(
        accessor: Accessor,
        handlerName: 'onChangeSwitch' | 'onChangeValue' | 'onChangeText' | 'onDateChange' | 'onSelection' | 'onChange',
        valueName: 'value' | 'date' | 'isChecked' | 'defaultValue'
    ): IProps;
}

export interface ManageSelectInputProps {
    onChangeValue: (value: string | number) => void;
    value: number;
}
export interface ManageTextProps {
    onChangeText: (value: string) => void;
    value: string;
}
export interface ManageSwitchInputProps {
    onChangeSwitch: (value: boolean) => void;
    isChecked: boolean;
}
export interface ManageDatePickerProps {
    onDateChange: (value: Date) => void;
    date: Date;
    size?: 'sm' | 'md';
}
export type TextFieldPropFactory = (label: string, accesor: Accessor) => TextInputProps;
export type TextareaPropFactory = (label: string, accesor: Accessor) => TextareaProps;
export type TypeaheadPropFactory = (label: string, accesor: Accessor) => TypeaheadInputProps;
export type SelectFieldPropFactory = (label: string, accesor: Accessor) => SelectInputProps;
export type SwitchFieldPropFactory = (label: string, accesor: Accessor) => SwitchProps;
export type DateTimePickerPropFactory = (label: string, accesor: Accessor) => DateTimePickerProps;

interface FormComponentProps {
    id?: string;
    manageFieldPropFactory?: ManageFieldPropFactory;
    textFieldPropFactory?: TextFieldPropFactory;
    textAreaPropFactory?: TextareaPropFactory;
    typeaheadPropFactory?: TypeaheadPropFactory;
    selectFieldPropFactory?: SelectFieldPropFactory;
    switchFieldPropFactory?: SwitchFieldPropFactory;
    dateTimePickerPropFactory?: DateTimePickerPropFactory;
    errors?: Record<string, string>;
    setErrors?: Dispatch<SetStateAction<Record<string, string>>>;
}

function useForms<T extends object>(data, getAdditionalProp) {
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [formData, setFormData] = useState<PartialDeep<T>>(data);
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [dirtyData, setDirtyData] = useState<PartialDeep<T>>();
    const [dirtyAccessors, setDirtyAccessor] = useState<Accessor[]>([]);

    useEffect(() => {
        setFormData(data);
    }, [JSON.stringify(data)]);

    const constructJson = (
        jsonObject: any,
        accessorPath: Accessor,
        value: string | number | boolean | Date,
        index: number,
        length: number
    ) => {
        if (index === length - 1) {
            jsonObject[accessorPath[0]] = value;
            return jsonObject;
        }
        jsonObject[accessorPath[0]] = { ...jsonObject[accessorPath[0]] };
        constructJson(jsonObject[accessorPath[0]], accessorPath.slice(1), value, index + 1, length);
        return jsonObject;
    };

    const manageFieldPropFactory = useCallback<ManageFieldPropFactory>(
        <T>(accessorPath: Accessor, handlerName = 'onChange', valueName = 'value') => {
            const newFormData = cloneDeep(formData);
            const finalProperty = accessorPath[accessorPath.length - 1];
            const accessorString = accessorPath.join('.');
            const finalMutable = getMutableForAccessor(newFormData, accessorPath);
            const hasError = Object.keys(errors).findIndex((errorPath) => errorPath === accessorString);
            const nextErrors = { ...errors };
            const inputValue = Number.isSafeInteger(finalMutable[finalProperty])
                ? `${finalMutable[finalProperty]}`
                : finalMutable[finalProperty];

            return {
                [handlerName]: function handleSubpropertyChange(value: typeof valueName) {
                    if (hasError > -1) {
                        delete nextErrors[accessorString];
                        setErrors(nextErrors);
                    }

                    finalMutable[finalProperty] = value;
                    const newDirtyData = constructJson(
                        dirtyData ? cloneDeep(dirtyData) : {},
                        accessorPath,
                        value,
                        0,
                        accessorPath.length
                    );
                    setDirtyAccessor([...dirtyAccessors, accessorPath]);
                    setDirtyData(newDirtyData);
                    setFormData(newFormData);
                    setIsDirty(true);
                },
                [valueName]: inputValue,
                isInvalid: hasError > -1,
                error: nextErrors[accessorString],
            } as T;
        },
        [formData, setFormData, errors, setErrors]
    );

    const propFactory = (label, accessorPath) => ({
        accessibilityLabel: label,
        label: label,
        ...getAdditionalProp(accessorPath),
    });

    const textFieldPropFactory = useCallback<TextFieldPropFactory>(propFactory, [formData]);
    const textAreaPropFactory = useCallback<TextareaPropFactory>(propFactory, [formData]);
    const typeaheadPropFactory = useCallback<TypeaheadPropFactory>(propFactory, [formData]);
    const selectFieldPropFactory = useCallback<SelectFieldPropFactory>(propFactory, [formData]);
    const switchFieldPropFactory = useCallback<SwitchFieldPropFactory>(propFactory, [formData]);
    const dateTimePickerPropFactory = useCallback<DateTimePickerPropFactory>(propFactory, [formData]);

    return {
        formData,
        errors,
        manageFieldPropFactory,
        textFieldPropFactory,
        textAreaPropFactory,
        typeaheadPropFactory,
        selectFieldPropFactory,
        switchFieldPropFactory,
        setErrors,
        setFormData,
        isDirty,
        setIsDirty,
        dirtyData,
        setDirtyData,
        dirtyAccessors,
        setDirtyAccessor,
        dateTimePickerPropFactory,
    };
}

export { useForms, type FormComponentProps };
