import { Cell, LabelList, Legend, Pie, PieChart as ReachPieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { FC, useMemo } from 'react';
import { Box, Text, useToken } from '@chakra-ui/react';

interface Item {
    name: string;
    value: number;
}

interface IProps {
    data: Item[];
    emptyMessage: string;
}

export const PieChart: FC<IProps> = ({ data, emptyMessage }) => {
    const [cyan500, orange500, purple500, teal500, green500, blue500, gray500] = useToken('colors', [
        'cyan.500',
        'orange.500',
        'purple.500',
        'teal.500',
        'green.500',
        'blue.500',
        'gray.500',
    ]);
    const isEmpty = useMemo(() => data.every((item) => item.value === 0), [data]);
    const pieColors = useMemo(() => [cyan500, orange500, purple500, teal500, green500, blue500, gray500], []);

    if (isEmpty) {
        return (
            <Text textAlign='center' textStyle={'sm-medium'}>
                {emptyMessage}
            </Text>
        );
    }

    return (
        <ResponsiveContainer width={'100%'} height={300} debounce={300}>
            <ReachPieChart>
                <Pie data={data} legendType='circle' innerRadius={60} outerRadius={90} dataKey='value'>
                    <LabelList dataKey='value' position='right' style={{ fontSize: '12px' }} />
                    {data.map((_, index) => (
                        <Cell key={`cell-${index}`} fill={pieColors[index]} />
                    ))}
                </Pie>
                <Tooltip />
                <Legend
                    formatter={(value) => (
                        <Box as='span' color='text-primary' textStyle='sm-normal'>
                            {value}
                        </Box>
                    )}
                    layout='horizontal'
                />
            </ReachPieChart>
        </ResponsiveContainer>
    );
};
