import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import React from 'react';
import ReactIcon from '@Icon/ReactIcon';
import Header from '@Pages/EventDetails/components/Summary/components/Header';
import type { StudentStepProps } from '@Pages/StudentDetails/types';
import FormHeader from '@Components/FormHeader';
import TextInput from '@Components/TextInput';
import { ManageTextProps } from '@Hooks';

const Transportation: React.FC<StudentStepProps> = ({ id, manageFieldPropFactory, textFieldPropFactory }) => {
    const { t } = useTranslation();

    return (
        <Box id={id} w={'full'}>
            <Header divider={true} mb={4} title={t('Transportation')} icon={<ReactIcon />} />
            <Flex direction={'column'} gap={4}>
                <FormHeader id={id} label={t('Transportation.dropOff')}>
                    <Box pb={4}>
                        <TextInput
                            {...textFieldPropFactory(t('Transportation.streetName'), ['dropOffStreet'])}
                            {...manageFieldPropFactory<ManageTextProps>(['dropOffStreet'], 'onChangeText', 'value')}
                        />
                    </Box>
                    <Grid
                        columnGap={10}
                        gridAutoFlow='dense'
                        templateColumns={{
                            base: 'repeat(1, 1fr)',
                            md: 'repeat(2, 1fr)',
                        }}
                        rowGap={4}
                        w={'full'}
                        mb={4}
                    >
                        <GridItem>
                            <TextInput
                                {...textFieldPropFactory(t('Transportation.apartment'), ['dropOffApartment'])}
                                {...manageFieldPropFactory<ManageTextProps>(
                                    ['dropOffApartment'],
                                    'onChangeText',
                                    'value'
                                )}
                                stacked
                            />
                        </GridItem>

                        <GridItem>
                            <TextInput
                                {...manageFieldPropFactory<ManageTextProps>(
                                    ['dropOffMunicipality'],
                                    'onChangeText',
                                    'value'
                                )}
                                {...textFieldPropFactory(t('Transportation.municipality'), ['dropOffMunicipality'])}
                                stacked
                            />
                        </GridItem>
                    </Grid>
                    <Grid
                        columnGap={10}
                        gridAutoFlow='dense'
                        templateColumns={{
                            base: 'repeat(1, 1fr)',
                            md: 'repeat(2, 1fr)',
                        }}
                        rowGap={4}
                        w={'full'}
                    >
                        <GridItem>
                            <TextInput
                                {...textFieldPropFactory(t('Transportation.contactName'), ['dropOffContactName'])}
                                {...manageFieldPropFactory<ManageTextProps>(
                                    ['dropOffContactName'],
                                    'onChangeText',
                                    'value'
                                )}
                                stacked
                            />
                        </GridItem>

                        <GridItem>
                            <TextInput
                                {...textFieldPropFactory(t('Transportation.contactPhoneNumber'), [
                                    'dropOffContactPhone',
                                ])}
                                {...manageFieldPropFactory<ManageTextProps>(
                                    ['dropOffContactPhone'],
                                    'onChangeText',
                                    'value'
                                )}
                                stacked
                            />
                        </GridItem>
                    </Grid>
                </FormHeader>
                <FormHeader id={id} label={t('Transportation.pickUp')}>
                    <Box pb={4}>
                        <TextInput
                            {...textFieldPropFactory(t('Transportation.streetName'), ['pickupStreet'])}
                            {...manageFieldPropFactory<ManageTextProps>(['pickupStreet'], 'onChangeText', 'value')}
                        />
                    </Box>
                    <Grid
                        columnGap={10}
                        gridAutoFlow='dense'
                        templateColumns={{
                            base: 'repeat(1, 1fr)',
                            md: 'repeat(2, 1fr)',
                        }}
                        rowGap={4}
                        w={'full'}
                        mb={4}
                    >
                        <GridItem>
                            <TextInput
                                {...textFieldPropFactory(t('Transportation.apartment'), ['pickupApartment'])}
                                {...manageFieldPropFactory<ManageTextProps>(
                                    ['pickupApartment'],
                                    'onChangeText',
                                    'value'
                                )}
                                stacked
                            />
                        </GridItem>

                        <GridItem>
                            <TextInput
                                {...manageFieldPropFactory<ManageTextProps>(
                                    ['pickupMunicipality'],
                                    'onChangeText',
                                    'value'
                                )}
                                {...textFieldPropFactory(t('Transportation.municipality'), ['pickupMunicipality'])}
                                stacked
                            />
                        </GridItem>
                    </Grid>
                    <Grid
                        columnGap={10}
                        gridAutoFlow='dense'
                        templateColumns={{
                            base: 'repeat(1, 1fr)',
                            md: 'repeat(2, 1fr)',
                        }}
                        rowGap={4}
                        w={'full'}
                    >
                        <GridItem>
                            <TextInput
                                {...textFieldPropFactory(t('Transportation.contactName'), ['pickupContactName'])}
                                {...manageFieldPropFactory<ManageTextProps>(
                                    ['pickupContactName'],
                                    'onChangeText',
                                    'value'
                                )}
                                stacked
                            />
                        </GridItem>

                        <GridItem>
                            <TextInput
                                {...textFieldPropFactory(t('Transportation.contactPhoneNumber'), [
                                    'pickupContactPhone',
                                ])}
                                {...manageFieldPropFactory<ManageTextProps>(
                                    ['pickupContactPhone'],
                                    'onChangeText',
                                    'value'
                                )}
                                stacked
                            />
                        </GridItem>
                    </Grid>
                </FormHeader>
            </Flex>
        </Box>
    );
};

export default Transportation;
