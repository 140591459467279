import { Grid } from '@chakra-ui/react';
import FormHeader from '@Components/FormHeader';
import { ManageDatePickerProps } from '@Hooks';
import { StepProps } from '@Pages/EventDetails/types';
import { FC, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { differenceInDays } from 'date-fns';
import DateTimeInput from '@Components/DateTimeInput';
import { isNull } from 'lodash';

const Schedules: FC<StepProps> = ({
    eventDetails,
    setDirtyData,
    setDirtyAccessor,
    manageFieldPropFactory,
    textFieldPropFactory,
    setEventDetails,
    dirtyData,
    dirtyAccessors,
}) => {
    const { t } = useTranslation();
    const mounting = useRef(false);

    useEffect(() => {
        if (!mounting.current) {
            mounting.current = true;
            return;
        }
        if (
            (!eventDetails?.endDate && eventDetails?.startDate) ||
            new Date(eventDetails?.startDate?.toString()) > new Date(eventDetails?.endDate?.toString())
        ) {
            const newEndDate = new Date(eventDetails.startDate);
            if (!isNull(eventDetails.endDate)) {
                newEndDate.setHours(
                    new Date(eventDetails.endDate).getHours(),
                    new Date(eventDetails.endDate).getMinutes()
                );
            }
            setEventDetails({ ...eventDetails, endDate: newEndDate.toISOString() });
            setDirtyData({ ...dirtyData, endDate: newEndDate.toISOString() });
            setDirtyAccessor([...dirtyAccessors, ['endDate']]);
        }
    }, [eventDetails.startDate]);

    const error = useMemo(
        () =>
            differenceInDays(new Date(eventDetails.endDate), new Date(eventDetails.startDate)) > 0
                ? { error: t('Schedules.endDateHelperText') }
                : {},
        [eventDetails.endDate, eventDetails.startDate]
    );

    return (
        <FormHeader label={t('Schedules.headerLabel')}>
            <Grid
                column={{ md: 2, sm: 1 }}
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                }}
                rowGap={4}
            >
                <DateTimeInput
                    hasTimeComponent
                    {...textFieldPropFactory(t('Schedules.startDate'), ['startDate'])}
                    {...manageFieldPropFactory<ManageDatePickerProps>(['startDate'], 'onDateChange', 'date')}
                    size='md'
                />
                <DateTimeInput
                    hasTimeComponent
                    {...textFieldPropFactory(t('Schedules.endDate'), ['endDate'])}
                    {...manageFieldPropFactory<ManageDatePickerProps>(['endDate'], 'onDateChange', 'date')}
                    {...error}
                    size='md'
                />
                <DateTimeInput
                    {...textFieldPropFactory(t('Schedules.dueDate'), ['dueDate'])}
                    {...manageFieldPropFactory<ManageDatePickerProps>(['dueDate'], 'onDateChange', 'date')}
                    size='md'
                />
                <DateTimeInput
                    {...textFieldPropFactory(t('Schedules.followUpDate'), ['followUpDate'])}
                    {...manageFieldPropFactory<ManageDatePickerProps>(['followUpDate'], 'onDateChange', 'date')}
                    size='md'
                />

                <DateTimeInput
                    {...textFieldPropFactory(t('Schedules.publishedOn'), ['publishDate'])}
                    {...manageFieldPropFactory<ManageDatePickerProps>(['publishDate'], 'onDateChange', 'date')}
                    size='md'
                />

                <DateTimeInput
                    {...textFieldPropFactory(t('Schedules.formsDistributedOn'), ['formsDistributedOnDate'])}
                    {...manageFieldPropFactory<ManageDatePickerProps>(
                        ['formsDistributedOnDate'],
                        'onDateChange',
                        'date'
                    )}
                    size='md'
                />
            </Grid>
        </FormHeader>
    );
};

export default Schedules;
