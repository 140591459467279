import { FileContent } from '@Utilities';
import { baseApi, GET, PATCH, POST } from '../base';
import { BaseResponse, Note } from '../commonTypes';
import { BasicLookup } from '../LookupApi/types';
import {
    allJobsProps,
    CreateSchoolReportingMissingStudentRecordsParams,
    DeleteSchoolReportingPeriodStudentParams,
    ExportReportingPeriodReportCardsParam,
    Job,
    NoteParams,
    PublishReportCardParams,
    PublishReportCardResponse,
    ReportingPeriodBySchoolYear,
    ReportingPeriodDetails,
    ReportingPeriodDetailsProps,
    ReportingPeriodJobsProps,
    ReportingPeriodNoteParam,
    ReportingPeriodsProps,
    ReportingStudentTeachersProps,
    SchoolReportingPeriodMissingStudentsData,
    SchoolReportingPeriodMissingStudentsParams,
    SchoolReportingPeriodReportCardsData,
    SchoolReportingPeriodReportCardsProps,
    StudentRecord,
    UnpublishReportCardParams,
    UploadedJob,
    ViewReportCardParam,
} from './types';

const reportsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getJobs: builder.mutation<BaseResponse<Job[]>, allJobsProps>({
            query: (body) => ({
                url: `ReportCard/GetJobs`,
                method: POST,
                body: body,
            }),
        }),
        GetSchoolReportingPeriods: builder.mutation<BaseResponse<ReportingPeriodBySchoolYear[]>, ReportingPeriodsProps>(
            {
                query: (body) => ({
                    url: `ReportCard/GetSchoolReportingPeriods`,
                    method: POST,
                    body: body,
                }),
            }
        ),
        GetSchoolReportingPeriod: builder.mutation<BaseResponse<ReportingPeriodDetails>, ReportingPeriodDetailsProps>({
            query: (body) => ({
                url: `ReportCard/GetSchoolReportingPeriod`,
                method: POST,
                body: body,
            }),
        }),
        GetSchoolReportingPeriodStudents: builder.mutation<BaseResponse<StudentRecord[]>, ReportingPeriodDetailsProps>({
            query: (body) => ({
                url: `ReportCard/GetSchoolReportingPeriodStudents`,
                method: POST,
                body: body,
            }),
        }),
        GetSchoolReportingPeriodJobs: builder.mutation<BaseResponse<UploadedJob[]>, ReportingPeriodJobsProps>({
            query: (body) => ({
                url: `ReportCard/GetSchoolReportingPeriodJobs`,
                method: POST,
                body: body,
            }),
        }),
        uploadReportCard: builder.mutation<BaseResponse<UploadedJob[]>, FormData>({
            query: (body) => ({
                url: `ReportCard/UploadReportCard`,
                method: POST,
                body: body,
            }),
        }),
        publishReportCards: builder.mutation<BaseResponse<PublishReportCardResponse>, PublishReportCardParams>({
            query: (body) => ({
                url: `ReportCard/PublishReportCards`,
                method: PATCH,
                body: body,
            }),
        }),
        getReportingStudentTeachers: builder.mutation<BaseResponse<BasicLookup[]>, ReportingStudentTeachersProps>({
            query: (body) => ({
                url: `ReportCard/GetReportingStudentTeachers`,
                method: POST,
                body: body,
            }),
        }),
        unpublishReportCards: builder.mutation<BaseResponse<void>, UnpublishReportCardParams>({
            query: (body) => ({
                url: `ReportCard/UnpublishReportCards`,
                method: PATCH,
                body: body,
            }),
        }),
        getSchoolReportingPeriodReportCards: builder.mutation<
            BaseResponse<SchoolReportingPeriodReportCardsData>,
            SchoolReportingPeriodReportCardsProps
        >({
            query: (body) => ({
                url: `ReportCard/GetSchoolReportingPeriodReportCards`,
                method: POST,
                body: body,
            }),
        }),
        exportReportingPeriodReportCards: builder.mutation<
            BaseResponse<FileContent>,
            ExportReportingPeriodReportCardsParam
        >({
            query: (body) => ({
                method: POST,
                url: 'ReportCard/ExportReportingPeriodReportCards',
                body,
            }),
        }),
        getReportCard: builder.query<BaseResponse<FileContent>, ViewReportCardParam>({
            query: (data) => ({
                url: `ReportCard/ViewReportCard/${data.studentId}/${data.reportId}`,
                method: GET,
            }),
        }),
        getReportingPeriodNotes: builder.mutation<BaseResponse<Note[]>, NoteParams>({
            query: (body) => ({
                body,
                method: POST,
                url: 'ReportCard/GetReportingPeriodNotes',
            }),
        }),
        createReportingPeriodNote: builder.mutation<BaseResponse<Note[]>, ReportingPeriodNoteParam>({
            query: (body) => ({
                body,
                method: POST,
                url: 'ReportCard/CreateReportingPeriodNote',
            }),
        }),
        getSchoolReportingPeriodMissingStudents: builder.mutation<
            BaseResponse<SchoolReportingPeriodMissingStudentsData[]>,
            SchoolReportingPeriodMissingStudentsParams
        >({
            query: (body) => ({
                body,
                method: POST,
                url: 'ReportCard/GetSchoolReportingPeriodMissingStudents',
            }),
        }),
        CreateSchoolReportingMissingStudentRecords: builder.mutation<
            BaseResponse<SchoolReportingPeriodMissingStudentsData[]>,
            CreateSchoolReportingMissingStudentRecordsParams
        >({
            query: (body) => ({
                body,
                method: POST,
                url: 'ReportCard/CreateSchoolReportingMissingStudentRecords',
            }),
        }),
        DeleteSchoolReportingPeriodStudent: builder.mutation<
            BaseResponse<SchoolReportingPeriodMissingStudentsData[]>,
            DeleteSchoolReportingPeriodStudentParams
        >({
            query: (body) => ({
                body,
                method: POST,
                url: 'ReportCard/DeleteSchoolReportingPeriodStudent',
            }),
        }),
    }),
});

export const {
    useGetJobsMutation,
    useGetSchoolReportingPeriodsMutation,
    useGetSchoolReportingPeriodMutation,
    useGetSchoolReportingPeriodJobsMutation,
    useGetSchoolReportingPeriodStudentsMutation,
    useUploadReportCardMutation,
    usePublishReportCardsMutation,
    useGetReportingStudentTeachersMutation,
    useUnpublishReportCardsMutation,
    useGetSchoolReportingPeriodReportCardsMutation,
    useExportReportingPeriodReportCardsMutation,
    useLazyGetReportCardQuery,
    useGetReportingPeriodNotesMutation,
    useCreateReportingPeriodNoteMutation,
    useGetSchoolReportingPeriodMissingStudentsMutation,
    useCreateSchoolReportingMissingStudentRecordsMutation,
    useDeleteSchoolReportingPeriodStudentMutation,
} = reportsApi;

export default reportsApi;
